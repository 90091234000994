import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

function getIndexForLabels(inputNum: number): number[] {
  const result: number[] = [];
  result.push(1);

  const mid1 = Math.ceil(inputNum / 3);
  const mid2 = Math.ceil((2 * inputNum) / 3);

  result.push(mid1, mid2);
  result.push(inputNum);

  return result;
}

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

import React from 'react';
import cn from 'classnames';
import {
  LineChartTickFormat,
  LineChartTooltipFormat,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { customDateConvert, formatBytes } from '@utils/index';

type LineChartProps = {
  data: ChartData<'line'>;
  classNames?: string;
  options?: ChartOptions<'line'>;
  height?: number;
  animation?: boolean;
  showXTicks?: boolean;
  showYTicks?: boolean;
  isDotted?: boolean;
  xTickFormat?: LineChartTickFormat;
  yTickFormat?: LineChartTickFormat;
  tooltipLabelFormat?: LineChartTooltipFormat;
  dataSetLabel?: string;
  hasPositiveValues?: boolean;
  suggestedMin?: number | undefined;
  suggestedMax?: number | undefined;
  stepSize?: number | undefined;
};

const LineChart: React.FC<LineChartProps> = ({
  data,
  options,
  classNames = '',
  height = 200,
  animation = false,
  showXTicks = false,
  showYTicks = false,
  isDotted = false,
  xTickFormat,
  yTickFormat,
  tooltipLabelFormat,
  dataSetLabel,
  hasPositiveValues = false,
  suggestedMin = undefined,
  suggestedMax = undefined,
  stepSize = undefined,
  
}) => {
  const chartClassNames = cn(classNames);

  const defaultOptions: ChartOptions<'line'> = {
    interaction: {
      mode: 'point',
    },
    devicePixelRatio: 2,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#282B2F',
        titleFont: { weight: 'normal' },
        cornerRadius: 6,
        padding: 8,
        displayColors: false,
        bodyColor: '#F8F9FA',
        titleColor: '#A7A9AC',
        bodyAlign: 'left',
        caretPadding: 10,
        bodySpacing: 4,
        callbacks: {
          label: (arg) => {
            switch (tooltipLabelFormat) {
              case LineChartTooltipFormat.Storage: {
                if (arg.dataset.label === dataSetLabel) {
                  const formattedBytes = formatBytes(
                    Number(arg.formattedValue.replace(/,/g, '')),
                    true
                  );
                  return `${formattedBytes[0]} ${formattedBytes[1]} ${arg.dataset.label}`;
                } else {
                  return `${arg.formattedValue} ${arg.dataset.label}`;
                }
              }
              default:
                return `${arg.formattedValue} ${arg.dataset.label}`;
            }
          },
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    animation: {
      duration: !animation ? 0 : 1000,
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          display: showXTicks,
          callback: function (value, index, values) {
            const labelIndex = getIndexForLabels(values.length);
            switch (xTickFormat) {
              case LineChartTickFormat.Date:
                if (labelIndex.includes(index + 1)) {
                  return customDateConvert(this.getLabelForValue(+value));
                }
                break;
              default:
                return this.getLabelForValue(+value);
            }
          },
        },
        grid: {
          display: false,
        },
        border: {
          display: true,
        },
        offset: true,
      },
      y: {
        beginAtZero: hasPositiveValues,
        suggestedMin: yTickFormat === LineChartTickFormat.Percentage ? 0 : suggestedMin,
        suggestedMax: yTickFormat === LineChartTickFormat.Percentage ? 100 : suggestedMax,
        ticks: {
          stepSize: yTickFormat === LineChartTickFormat.Percentage ? 25 : stepSize,
          display: showYTicks,
          callback: function (value, index, values) {
            switch (yTickFormat) {
              case LineChartTickFormat.Percentage: {
                return `${value}%`;
              }
              default:
                return this.getLabelForValue(+value);
            }
          },
        },
        grid: {
          color: () => {
            return '#C6CCD0';
          },
          lineWidth: undefined,
          tickWidth: 0,
          display: showYTicks,
        },
        border: {
          display: true,
          dash: isDotted ? [2, 4] : [],
        },
        offset: true,
      },
    },
  };

  const chartOptions = options
    ? { ...defaultOptions, ...options }
    : defaultOptions;

  return (
    <div className={chartClassNames}>
      <Line height={height} options={chartOptions} data={data} />
    </div>
  );
};

export default LineChart;
