import { Location } from '@hooks/locations/types';
import { GroupsResponse } from '@hooks/groups/types';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { ExtendedOption, IOption } from '@common/types';
import {
  FailedLoginAccountsFiltersResponse,
  FailedLoginAccountsStatusFilter,
} from '@hooks/security/types';

export const addLocationsToFilter = (
  locationsData: Location[],
  filterOptions: IOption[],
  locationIds: string[],
  filter: FilterData[]
) => {
  locationsData?.forEach((location: Location) => {
    filterOptions.push({ label: location.name, value: location.id });
    locationIds.push(location.id);
  });

  filter.push({
    label: 'Location',
    name: 'location',
    singleSelect: false,
    options: filterOptions,
  });
};

export const addGroupsToFilter = (
  groupsData: GroupsResponse[],
  filterOptions: ExtendedOption[],
  filter: FilterData[]
) => {
  groupsData?.forEach((group: GroupsResponse) => {
    filterOptions.push({
      name: group.name,
      value: group.id,
      parentId: group.parentId,
      subGroups: group.subGroups,
      id: group.id,
      label: group.name,
    });
  });

  filter.push({
    label: 'Groups',
    name: 'groups',
    singleSelect: false,
    options: filterOptions,
  });
};

export const addSelectedDataToFilters = (
  data: any,
  filterData: FilterData[]
) => {
  Object.keys(data).forEach((key) => {
    let filterOptions: IOption[] = [];

    if (Array.isArray(data[key].value)) {
      filterOptions = (
        data[key].value as FailedLoginAccountsStatusFilter[]
      )?.map((item) => ({
        label: item.name,
        value: item.value,
      }));
    } else if (typeof data[key].value === 'object') {
      filterOptions = Object.entries(data[key].value).map(([value, label]) => ({
        label: label as string,
        value,
      }));
    }
    const name = data[key].key.replace(/(?<!^)([A-Z])/g, ' $1');
    filterData.push({
      label: mapFilterDisplayNames(name),
      name: key,
      singleSelect: false,
      options: filterOptions,
    });
  });
};

export const addSelectedDataToFilter = (
  data: FailedLoginAccountsFiltersResponse,
  filterData: FilterData[]
) => {
  Object.keys(data).forEach((key) => {
    let filterOptions: IOption[] = [];

    if (Array.isArray(data[key].value)) {
      filterOptions = (
        data[key].value as FailedLoginAccountsStatusFilter[]
      )?.map((item) => ({
        label: item.name,
        value: item.value,
      }));
    } else if (typeof data[key].value === 'object') {
      filterOptions = Object.entries(data[key].value).map(([value, label]) => ({
        label: label as string,
        value,
      }));
    }
    const name = data[key].key.replace(/(?<!^)([A-Z])/g, ' $1');
    filterData.push({
      label: mapFilterDisplayNames(name),
      name: key,
      singleSelect: false,
      options: filterOptions,
    });
  });
};

const mapFilterDisplayNames = (label: string) => {
  if (label === 'Status') return 'Account Status';
  if (label === 'Mfa Status') return 'MFA Status';
  if (label === 'Countries') return 'Country';
  return label;
};

export const getStatusFilterAsQueryParam = (
  data: any,
  filterValues: string[],
  type: 'status' | 'mfaStatus'
) => {
  const params = Array.isArray(data)
    ? filterValues.filter((value) =>
        (data as FailedLoginAccountsStatusFilter[])?.some(
          (option: FailedLoginAccountsStatusFilter) => option?.value === value
        )
      )
    : [];

  if (type === 'mfaStatus') return mapMFAStatusValues(params);

  return params;
};

export const mapMFAStatusValues = (array: string[]) => {
  return array.map((item) =>
    item === 'EnabledMfa'
      ? 'Enabled'
      : item === 'DisabledMfa'
      ? 'Disabled'
      : item
  );
};

export const getCountryFilterAsQueryParam = (
  data: any,
  filterValues: string[]
) => {
  return typeof data === 'object'
    ? filterValues.filter((value) => Object.keys(data).includes(value))
    : [];
};

export const getGroupOrLocationFilterAsQueryParam = (
  filter: string | undefined,
  ids: string[]
) => {
  return filter ? filter.split(',').filter((item) => ids.includes(item)) : [];
};
