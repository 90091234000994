import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { create } from 'zustand';

type InsightsStore = {
    filterData: any;
    transformedFilterData: FilterData[];
    locationIds: string[];
    groupIds: string[];
};

type InsightsStoreActions = {
    setFilterData: (value: any) => void;
    setTransformedFilterData: (value: FilterData[]) => void;
    setLocationIds: (value: string[]) => void;
    setGroupIds: (value: string[]) => void;
};

const useInsightsStore = create<InsightsStore & InsightsStoreActions>((set) => ({
    filterData: {},
    transformedFilterData: [],
    locationIds: [],
    groupIds: [],
    setFilterData: (value: any) => {
        set(() => ({ filterData: value }));
    },
    setTransformedFilterData: (value: FilterData[]) => {
        set(() => ({ transformedFilterData: value }));
    },
    setLocationIds: (value: string[]) => {
        set(() => ({ locationIds: value }));
    },
    setGroupIds: (value: string[]) => {
        set(() => ({ groupIds: value }));
    },

}));

export default useInsightsStore;
