import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { useSearchParams } from 'react-router-dom';
import Logo from '@assets/svg/logo.svg';
import LogoCrop from '@assets/svg/logo_crop.svg';
import LogoElement from '@assets/svg/logo_element.svg';

import { DashboardTiles } from '@common/constants';
import {
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import {
  formatNumberWithSuffix,
  hasNestedArray,
  formatDateInUTC,
} from '@utils/index';
import {
  LineChartIcon,
  LineChartIconUp,
  CalendarIcon,
  ArrowLongRightIcon,
} from '@components/ui/Icons';

const TestPdf: React.FC = () => {
  const [searchParams] = useSearchParams();

  const pParam = searchParams.get('p');

  const decodedData = pParam ? JSON.parse(pParam) : null;

  return (
    <>
      <div
        style={{
          height: '297mm',
          width: '210mm',
          marginTop: '15px',
          marginLeft: '15px',
          paddingLeft: '50px',
          paddingRight: '50px',
          position: 'relative',
          display: 'flex',
          justifyItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          pageBreakBefore: 'always',
        }}
      >
        <img
          style={{
            position: 'absolute',
            top: 50,
            left: 40,
            height: '40px',
          }}
          src={Logo}
          alt="Clevr360 logo"
        />

        <h1
          style={{
            fontSize: '72px',
            breakInside: 'avoid',
          }}
        >
          {decodedData.title}
        </h1>

        <h3
          className="mb-4 flex items-center gap-x-1"
          style={{
            fontSize: '20px',
            breakInside: 'avoid',
          }}
        >
          <CalendarIcon
            color="text-interfaceColor-100"
            size="8"
            classNames="mx-1"
          />
          {formatDateInUTC(decodedData.fromDate)}
          <ArrowLongRightIcon color="text-interfaceColor-100" size="5" />
          {formatDateInUTC(decodedData.toDate)}
        </h3>

        <div
          style={{
            breakInside: 'avoid',
          }}
        >
          {decodedData.subtitles &&
            decodedData.subtitles.map((item: any) => {
              return (
                <p
                  key={item}
                  style={{
                    fontSize: '25px',
                    breakInside: 'avoid',
                    display: 'block',
                    marginBottom: '5px',
                  }}
                >
                  - {item}
                </p>
              );
            })}
        </div>
        <img
          style={{
            position: 'absolute',
            bottom: -26,
            right: -14,
          }}
          src={LogoCrop}
          alt="CloudClevr LogoCrop"
        />
      </div>
      {decodedData.pages.map((item: any, indexPage: number) => {
        return (
          <div
            key={item.title}
            style={{
              height: '297mm',
              width: '210mm',
              marginTop: '15px',
              marginLeft: '15px',
              paddingLeft: '25px',
              paddingRight: '25px',
              position: 'relative',
            }}
          >
            <h1
              style={{
                fontSize: '46px',
                breakInside: 'avoid',
                pageBreakBefore: 'always',
                margin: '20px 0',
              }}
            >
              {item.title}
            </h1>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '20px',
              }}
            >
              {item.components.map((component: any, index: number) => {
                return (
                  <div
                    key={index}
                    style={{
                      flex: `${
                        component.type !== DashboardTiles.ActivityPerDayChart
                          ? '1 0 calc(50% - 20px)'
                          : '100%'
                      }`,
                      breakInside: 'avoid',
                      maxWidth: `${
                        component.type !== DashboardTiles.ActivityPerDayChart
                          ? 'calc(50% - 20px)'
                          : '100%'
                      }`,
                    }}
                  >
                    {component.type === DashboardTiles.LicenseAssignment && (
                      <div style={{ marginBottom: '0' }}>
                        <DoughnutChartTile
                          contextData={[]}
                          animation={false}
                          headerTitle={component.parameters.title}
                          isLoading={false}
                          data={[
                            component.parameters.unassigned,
                            component.parameters.assigned,
                          ]}
                          dataLabels={[
                            'Unassigned Licenses',
                            'Assigned Licenses',
                          ]}
                          showBracketLabelValue={true}
                        />
                      </div>
                    )}
                    {(component.type === DashboardTiles.LicenseActivity ||
                      component.type === 91) && (
                      <div style={{ marginBottom: '0' }}>
                        <DoughnutChartTile
                          classNames={component.type === 91 ? '!h-[280px]' : ''}
                          contextData={[]}
                          animation={false}
                          headerTitle={component.parameters.title}
                          isLoading={false}
                          data={
                            component.parameters.data
                              ? component.parameters.data
                              : [
                                  component.parameters.inactive,
                                  component.parameters.active,
                                ]
                          }
                          isPrintView={component.type === 91}
                          dataLabels={
                            component.parameters.legends || [
                              'Inactive licenses',
                              'Active licenses',
                            ]
                          }
                          additionalTextInfo={
                            component.parameters.percentText ? (
                              <div className="flex flex-row items-start">
                                {component.parameters.isPercentIncreased && (
                                  <LineChartIconUp
                                    color="text-successColor-100"
                                    size="5"
                                  />
                                )}

                                {!component.parameters.isPercentIncreased &&
                                  component.parameters.percentage !== 0 && (
                                    <LineChartIcon
                                      size="4"
                                      color="text-errorColor-100"
                                    />
                                  )}

                                <span
                                  className={`ml-2 flex items-center text-xs ${
                                    component.parameters.isPercentIncreased
                                      ? 'text-successColor-100'
                                      : component.parameters.percentage !== 0
                                      ? 'text-errorColor-100'
                                      : 'text-interfaceColor-80'
                                  }`}
                                >
                                  {component.parameters.percentage === 0 && (
                                    <span className="mr-1 flex h-[1px] w-[12px] bg-interfaceColor-80"></span>
                                  )}
                                  {component.parameters.percentText}
                                </span>
                              </div>
                            ) : null
                          }
                          showBracketLabelValue={
                            component.type === 91 ? false : true
                          }
                        />
                      </div>
                    )}
                    {component.type === DashboardTiles.OverassignedLicenses && (
                      <div style={{ marginBottom: '0' }}>
                        <NumberTile
                          headerTitle={component.parameters.title}
                          isLoading={false}
                          data={[
                            component.parameters.overassigned,
                            component.parameters.percentage,
                          ]}
                          isPercentage={false}
                          percentageLabel="previous period"
                        />
                      </div>
                    )}

                    {component.type === DashboardTiles.ActivityPerDayChart && (
                      <div style={{ marginBottom: '0' }}>
                        <LineChartTile
                          hasPositiveValues={true}
                          dataSetTypes={
                            hasNestedArray(component.parameters.data)
                              ? [LineChartTypes.Dots, LineChartTypes.Dots]
                              : [LineChartTypes.Dots]
                          }
                          headerTitle={component.parameters.title}
                          dataSetColors={
                            component.parameters.lineColors || ['#00CF6C']
                          }
                          isPrintPreview={true}
                          isLoading={false}
                          animation={false}
                          labels={component.parameters.labels}
                          data={
                            hasNestedArray(component.parameters.data)
                              ? component.parameters.data
                              : [component.parameters.data || []]
                          }
                          sortData={[]}
                          compareLabel=""
                          showXTicks={true}
                          showYTicks={true}
                          legendLabels={component.parameters.legends || []}
                          chartHeight={150}
                          isDotted={true}
                          showAgainstDropdown={false}
                        />
                      </div>
                    )}

                    {(component.type === DashboardTiles.ActivityTotalsNumber ||
                      component.type === 84) && (
                      <div style={{ marginBottom: '0' }}>
                        <NumberTile
                          headerTitle={component.parameters.title}
                          isLoading={false}
                          data={[
                            formatNumberWithSuffix(component.parameters.total),
                            component.parameters.percentage,
                          ]}
                          percentageLabel=""
                          isPercentIncreased={
                            component.parameters.isPercentIncreased
                          }
                          bottomLabel={
                            component.parameters.title.startsWith('External')
                              ? 'Users who aren’t part of your business but have access to resources'
                              : ''
                          }
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div
              style={{
                position: 'absolute',
                bottom: '0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                left: '20px',
              }}
            >
              <img
                src={LogoElement}
                alt="logo inverted"
                style={{ marginRight: '12px' }}
              />
              <span>
                {decodedData.title}: {decodedData.date}
              </span>
            </div>
            <p
              style={{
                position: 'absolute',
                bottom: '0',
                right: '20px',
              }}
            >
              {indexPage + 1}
            </p>
          </div>
        );
      })}
    </>
  );
};

export default TestPdf;
