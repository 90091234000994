import { HUB_SETTINGS_CARDS } from '@common/cardConstants';
import { Button, Card } from '@components/ui';
import React from 'react';
import styles from './styles.module.css';
import { Outlet, useNavigate } from 'react-router-dom';
import { WelcomeModal } from '@components/partials/WelcomeModal';

const HubSettingsPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.cardsWrapper}>
      {HUB_SETTINGS_CARDS.map((item) => {
        return (
          <div
            key={item.title}
            className="col-span-6 md:col-span-3 xl:col-span-2"
          >
            <Card classNames="!w-full mb-4 h-auto md:h-[260px]">
              <Card.Header classNames={styles.cardHeader}>
                <div className={styles.iconWrapper}>{item.icon}</div>
                {item.title}
              </Card.Header>
              <Card.Content classNames={styles.cardContentWrapper}>
                <p className="mt-1">{item.content}</p>
                <div>
                  <Button
                    variant="dark"
                    size="medium"
                    className="mt-3 flex w-full md:w-auto"
                    onClick={() => {
                      navigate(item.buttonAction);
                    }}
                  >
                    {item.buttonLabel}
                  </Button>
                </div>
              </Card.Content>
            </Card>
          </div>
        );
      })}
      <Outlet />
      <WelcomeModal
        content={
          <p>
            In this section, control who has access to the Clevr360, and which
            services are shown in it. Also, set operating locations for your
            business to get updates when people are working in, or connecting
            with, locations you wouldn`t expect.
          </p>
        }
        btnText="Got it"
        modalKey="HubSettingsModal"
        title={
          <span>
            Make Clevr360 work harder <br /> for your business
          </span>
        }
      />
    </div>
  );
};

export default HubSettingsPage;
