/* eslint-disable no-empty */
import React, { useEffect, useState } from 'react';

import {
  ALL,
  ANALYTICS_NAVIGATION_MAP,
  DashboardTiles,
  LICENSES_EXPIRING_BAR_LABELS,
  VENDORS,
} from '@common/constants';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import styles from './styles.module.css';

import { useDashboardTileSaveMutation } from '@hooks/dashboard';
import { DashboardIcon } from '@components/ui';
import { MenuItem } from '@components/ui/DropdownMenu';
import { VendorSelection, VendorType } from '@common/types';
import {
  useLicencesActivityQuery,
  useLicencesAssignmentQuery,
  useLicencesExpiredSnapshotQuery,
  useLicencesExpiringSnapshotQuery,
  useLicencesOverassignedQuery,
  useLicencesFiltersQuery,
} from '@hooks/licenses';

import { SelectItem } from '@components/ui/SelectDropdown';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { HorizontalBarChartTile } from '@pages/InsightsAndAnalytics/tiles/HorizontalChartTile';
import {
  useHardwareInventorySnapshotQuery,
  useDeviceInventoryTypesQuery,
  useLicencesInventorySnapshotQuery,
} from '@hooks/utilisation';
import { DeviceUsage } from '@pages/Dashboard/tiles/DeviceUsage';
import { LicenseInventoryChangesOverTime } from '@pages/Dashboard/tiles/LicenseInventoryChangesOverTime';
import { MeetingRoomDeviceUsage } from '@pages/Dashboard/tiles/MeetingRoomDeviceUsage';
import { ConfiguredTrunks, GroupsTeamsInventory, SharePointUsage } from '@pages/Dashboard/tiles';
import { LicensesFilterData } from '@hooks/licenses/types';
import useServicesStore from '@store/servicesStore';
import { isTraditionalCommsDataVisible } from '@utils/index';

const UtilisationPage: React.FC = () => {
  const { traditionalCommsStatus } = useServicesStore();
  const isTraditionalCommsVisible = isTraditionalCommsDataVisible(traditionalCommsStatus);

  const [typeList, setTypeList] = useState<string[]>([]);
  const [vendorSelection, setVendorSelection] = useState<VendorSelection>({
    assignment: ALL,
    overassigned: ALL,
    expiring: ALL,
    expired: ALL,
    inventory: ALL,
  });
  const [isLabelChartLoading, setisLabelChartLoading] = useState(true);
  const [licensesExpiringBarLabels, setLicensesExpiringBarLabels] = useState<
    string[]
  >([]);
  const [days, setDays] = useState('30');
  const { data: filters } = useLicencesFiltersQuery();

  const getVendorList = (type: string): VendorType[] => {
    return vendorSelection[type] === ALL ? VENDORS : [vendorSelection[type]];
  };

  const dataHooks = {
    assignment: useLicencesAssignmentQuery({
      vendorList: getVendorList('assignment'),
    }),
    overassigned: useLicencesOverassignedQuery({
      vendorList: getVendorList('overassigned'),
    }),
    expiring: useLicencesExpiringSnapshotQuery({
      vendorList: getVendorList('expiring'),
    }),
    expired: useLicencesExpiredSnapshotQuery({
      vendorList: getVendorList('expired'),
    }),
    inventory: useLicencesInventorySnapshotQuery({
      vendorList: getVendorList('inventory'),
    }),
    activity: useLicencesActivityQuery({ days }),
    hardware: useHardwareInventorySnapshotQuery({
      typeList: typeList ? typeList.filter(item => item !== 'all') : [],
    }),
  };

  useEffect(() => {
    setisLabelChartLoading(true);
    if (dataHooks.expiring.data) {
      setLicensesExpiringBarLabels(
        LICENSES_EXPIRING_BAR_LABELS.map((label, index) => {
          return `${label} ${dataHooks.expiring.data?.expirations[index].count}`;
        })
      );
      setisLabelChartLoading(false);
    }
  }, [dataHooks.expiring.data]);

  const {
    data: deviceInventoryTypesData,
    isFetched: isDeviceInventoryTypesFetched,
  } = useDeviceInventoryTypesQuery();

  const deviceInventorySortBy: SelectItem[] = [
    { id: '0', name: 'All types', value: 'all' },
  ];

  const dashboardSaveMutation = useDashboardTileSaveMutation();
  const handleItemAction = (type: DashboardTiles) => () => {
    const getParametersData = () => {
      switch (type) {
        case DashboardTiles.LicenseInventory:
          return {
            vendorList: getVendorList('inventory'),
          };
        case DashboardTiles.HardwareInventory:
          return {
            typeList: typeList ? typeList : [],
          };
        case DashboardTiles.LicensesExpiringSoon:
          return { vendorList: getVendorList('expiring') };
        case DashboardTiles.ExpiredAndDisabledLicenses:
          return { vendorList: getVendorList('expired') };
        case DashboardTiles.LicenseAssignment:
          return { vendorList: getVendorList('assignment') };
        case DashboardTiles.LicenseActivity:
          return { days };
        case DashboardTiles.OverassignedLicenses:
          return { vendorList: getVendorList('overassigned') };

        default:
          return {};
      }
    };

    const parametersData = getParametersData();

    dashboardSaveMutation.mutate({
      parameters: JSON.stringify(parametersData),
      tileType: type,
    });
  };

  if (isDeviceInventoryTypesFetched) {
    deviceInventoryTypesData?.map((item, index) => {
      deviceInventorySortBy.push({
        id: (++index).toLocaleString(),
        name: item,
        value: item,
        checked: false
      });
    });
  }

  const handleSelectChange = (value: string) => {
    try {
      const allTypesValue = 'all';
      let newTypes: string[] = [];
      
      if (typeList.includes(value)) {
        newTypes = value === allTypesValue ? [] :
          typeList.filter(type => type !== value && type !== allTypesValue)
      } else {

        if (value === allTypesValue) {
          newTypes = [allTypesValue, ...deviceInventoryTypesData || []]
        } else {
          const selectedValues = [...typeList, value];
          newTypes = selectedValues.length === deviceInventoryTypesData?.length ?
            [...selectedValues, allTypesValue] : selectedValues
        }
      }
      setTypeList(newTypes);
    } catch { }
  };

  const handleDropdownChange = (type: keyof VendorSelection, value: string) => {
    if (type === 'activity') {
      setDays(value);
    } else {
      setVendorSelection((prev) => ({
        ...prev,
        [type]: value as VendorType,
      }));
    }
  };

  const createSortVendorsMenuItems = (
    type: keyof VendorSelection
  ) => {
    const Menu = [
      {
        id: '1',
        label: 'All Vendors',
        action: () => handleDropdownChange(type, 'All'),
      },
    ];
    const vendors = new Set<string>(filters?.licenseType.map((item: LicensesFilterData) => item.vendor));
    Array.from(vendors).map((item: string) => {
      Menu.push({
        id: item,
        label: item,
        action: () => handleDropdownChange(type, item),
      });
    });
    return Menu;
  }

  const createMenuItems = (tileType: DashboardTiles): MenuItem[] => [
    {
      id: '0',
      label: 'Add to Dashboard',
      icon: <DashboardIcon classNames="mr-1" />,
      value: 'addToDashboard',
      action: handleItemAction(tileType),
    },
  ];

  const createSortDaysMenuItems = (type: keyof VendorSelection): MenuItem[] => [
    {
      id: '1',
      label: 'Last 30 days',
      action: () => handleDropdownChange(type, '30'),
    },
    {
      id: '2',
      label: 'Last 60 days',
      action: () => handleDropdownChange(type, '60'),
    },
    {
      id: '3',
      label: 'Last 90 days',
      action: () => handleDropdownChange(type, '90'),
    },
  ];

  return (
    <>
      <div className={styles.pageContainer}>
        <div className={styles.pageGrid}>
          <DoughnutChartTile
            contextData={createMenuItems(DashboardTiles.LicenseInventory)}
            classNames={'col-span-6 md:col-span-6 xl:col-span-3'}
            sortData={createSortVendorsMenuItems('inventory')}
            headerTitle={'License inventory'}
            isLoading={dataHooks.inventory.isLoading}
            data={
              dataHooks.inventory.data
                ? Object.values(dataHooks.inventory.data.inventorySnapshot)
                : []
            }
            dataLabels={
              dataHooks.inventory.data
                ? Object.keys(dataHooks.inventory.data.inventorySnapshot)
                : []
            }
            buttonLabel={'License inventory data'}
            linkPath={ANALYTICS_NAVIGATION_MAP['licenseInventory']}
            isLgSize={true}
            showBracketLabelValue={true}
          />

          <DoughnutChartTile
            contextData={createMenuItems(DashboardTiles.HardwareInventory)}
            classNames={'col-span-6 md:col-span-6 xl:col-span-3'}
            checkBoxItems={
              deviceInventorySortBy.map(item => ({
                ...item,
                checked: typeList.includes(item.value)
              }))
            }
            headerTitle={'Device inventory'}
            isLoading={dataHooks.hardware.isLoading}
            data={
              dataHooks.hardware.data
                ? Object.values(dataHooks.hardware.data.inventorySnapshot)
                : []
            }
            dataLabels={
              dataHooks.hardware.data
                ? Object.keys(dataHooks.hardware.data.inventorySnapshot)
                : []
            }
            handleDropdownSelect={handleSelectChange}
            buttonLabel={'Device inventory data'}
            linkPath={ANALYTICS_NAVIGATION_MAP['deviceInventory']}
            isLgSize={true}
            showBracketLabelValue={true}
          />

          <LicenseInventoryChangesOverTime
            classNames="col-span-full lg:col-span-6 xl:col-span-6"
            params={JSON.stringify({ days: '30' })}
          />

          <DoughnutChartTile
            contextData={createMenuItems(DashboardTiles.LicenseAssignment)}
            classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
            sortData={createSortVendorsMenuItems('assignment')}
            headerTitle={'License assignment'}
            isLoading={dataHooks.assignment.isLoading}
            data={
              dataHooks.assignment.data
                ? Object.values(dataHooks.assignment.data)
                : []
            }
            dataLabels={['Unassigned licenses', 'Assigned licenses']}
            buttonLabel={'Unassigned license data'}
            linkPath={ANALYTICS_NAVIGATION_MAP['assignment']}
            showBracketLabelValue={true}
          />

          <DoughnutChartTile
            contextData={createMenuItems(DashboardTiles.LicenseActivity)}
            classNames={'col-span-6 md:col-span-3 xl:col-span-2 lg:row-start-3'}
            sortData={createSortDaysMenuItems('activity')}
            headerTitle={'License activity'}
            isLoading={dataHooks.activity.isLoading}
            data={
              dataHooks.activity.data
                ? Object.values(dataHooks.activity.data)
                : []
            }
            dataLabels={['Inactive licenses', 'Active licenses']}
            buttonLabel={'Inactive license data'}
            linkPath={ANALYTICS_NAVIGATION_MAP['activity']}
            showBracketLabelValue={true}
          />

          <NumberTile
            contextData={createMenuItems(DashboardTiles.OverassignedLicenses)}
            classNames={'col-span-6 md:col-span-3 xl:col-span-2 lg:row-start-3'}
            sortData={createSortVendorsMenuItems('overassigned')}
            headerTitle={'Overassigned licenses'}
            isLoading={dataHooks.overassigned.isLoading}
            data={
              dataHooks.overassigned.data
                ? Object.values(dataHooks.overassigned.data)
                : []
            }
            buttonLabel={'Overassigned license data'}
            linkPath={ANALYTICS_NAVIGATION_MAP['overassigned']}
          />

          <NumberTile
            contextData={createMenuItems(
              DashboardTiles.ExpiredAndDisabledLicenses
            )}
            classNames={'col-span-6 md:col-span-3 xl:col-span-2 lg:row-start-4'}
            sortData={createSortVendorsMenuItems('expired')}
            headerTitle={'Expired & disabled licenses'}
            isLoading={dataHooks.expired.isLoading}
            data={
              dataHooks.expired.data
                ? Object.values(dataHooks.expired.data)
                : []
            }
            buttonLabel={'Expired license data'}
            linkPath={ANALYTICS_NAVIGATION_MAP['expired']}
          />

          <HorizontalBarChartTile
            contextData={createMenuItems(DashboardTiles.LicensesExpiringSoon)}
            classNames={'col-span-6 xl:col-span-4 lg:row-start-4'}
            sortData={createSortVendorsMenuItems('expiring')}
            headerTitle={'Licenses expiring soon'}
            isLoading={dataHooks.expiring.isLoading || isLabelChartLoading}
            data={
              dataHooks.expiring.data?.expirations.map((item) => item.count) ||
              []
            }
            dataLabels={licensesExpiringBarLabels}
            barBackgroundColors={[
              '#00CF6C',
              '#25A8D1',
              '#B65AD7',
              '#FFB800',
              '#54b534',
            ]}
            buttonLabel={'License expiry data'}
            linkPath={ANALYTICS_NAVIGATION_MAP['expiring']}
          />

          <SharePointUsage
            params={JSON.stringify({ days: '30', dataType: 'sites' })}
          />
        </div>
      </div>
      {isTraditionalCommsVisible && <>
      <h1 className={styles.header}>Calling</h1>
        <div className={styles.pageContainer}>
          <div className={styles.pageGrid}>
            <ConfiguredTrunks params={JSON.stringify({})} />
          </div>
        </div>
      </>
      }

      <h1 className={styles.header}>Cloud storage, groups & teams</h1>
      <div className={styles.pageContainer}>
        <div className={styles.pageGrid}>
          <GroupsTeamsInventory params={JSON.stringify({ vendor: '' })} />
        </div>
      </div>

      <h1 className={styles.header}>Devices</h1>
      <div className={styles.pageContainer}>
        <div className={styles.pageGrid}>
          <DeviceUsage
            classNames={'col-span-6 md:col-span-3 xl:col-span-2 lg:row-start-1'}
            params={JSON.stringify({ days: '30', type: '' })}
          />
          <MeetingRoomDeviceUsage
            classNames={'col-span-6 md:col-span-3 xl:col-span-2 lg:row-start-1'}
            params={JSON.stringify({ days: '30' })}
          />
        </div>
      </div>
    </>
  );
};

export default UtilisationPage;
