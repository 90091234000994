import { API_ENDPOINTS } from '@api/ApiEndpoints';
import { DashboardTiles } from '@common/constants';
import { DashboardIcon, DeleteIcon } from '@components/ui/Icons';
import useUIStore, { NavSource } from '@store/uiStore';
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query';
import { queryClient } from '../../App';
import { DashboardTilesResponse, DashboardUpdateParams } from './types';
import { MenuItem } from '@components/ui/DropdownMenu';

import { useHttpClient } from '@hooks/utils/httpConfig';
import { Variant } from '@components/ui/Toast';

export function useDashboardsQuery(): UseQueryResult<DashboardTilesResponse[]> {
  const dashboardClient = useHttpClient();
  const fetchDashboardTiles = async (): Promise<DashboardTilesResponse[]> => {
    return dashboardClient.get<DashboardTilesResponse[]>(
      `${API_ENDPOINTS.DASHBOARDS}`
    );
  };

  return useQuery(['dashboards'], fetchDashboardTiles, {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
}

export const useDashboardTileSaveMutation = () => {
  const { addToast } = useUIStore();
  const dashboardClient = useHttpClient();
  return useMutation(
    (data: any) =>
      dashboardClient.post(`${API_ENDPOINTS.DASHBOARDS}`, {
        ...data,
      }),
    {
      onSuccess: () => {
        addToast({
          variant: Variant.Success,
          message: 'Tile successfully added to dashboard.',
          closeable: true,
        });
        queryClient.invalidateQueries('dashboards');
      },
      onError: (error: any) => {
        if (error.response?.data?.Reason) {
          addToast({
            variant: Variant.Info,
            message: 'Tile already exists with the same filter.',
            closeable: true,
          });
        } else {
          addToast({
            variant: Variant.Error,
            message: 'Something went wrong. Please, try again.',
            closeable: true,
          });
        }
      },
    }
  );
};

export const useDashboardDeleteTile = () => {
  const { addToast } = useUIStore();
  const dashboardClient = useHttpClient();
  return useMutation(
    (dashobardTileId: string | undefined) =>
      dashboardClient.delete(`${API_ENDPOINTS.DASHBOARDS}/${dashobardTileId}`),
    {
      onSuccess: () => {
        addToast({
          variant: Variant.Success,
          message: 'Tile was successfully removed from dashboard.',
          closeable: true,
        });
        queryClient.invalidateQueries('dashboards');
      },
      onError: () => {
        addToast({
          variant: Variant.Error,
          message: 'Something went wrong. Please, try again.',
          closeable: true,
        });
      },
    }
  );
};

export const useDashboardUpdateTileParams = () => {
  const dashboardClient = useHttpClient();
  return useMutation(
    (data: DashboardUpdateParams) =>
      dashboardClient.patch(
        `${API_ENDPOINTS.DASHBOARDS}/${data.tileId}/parameters`,
        {
          parameters: data.params,
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('dashboards');
      },
    }
  );
};

export const useSaveSelectProfile = (): UseMutationResult<
  any,
  unknown,
  any
> => {
  const dashboardClient = useHttpClient();
  return useMutation(
    (data: any) =>
      dashboardClient.post<any>(`${API_ENDPOINTS.UPDATE_USER_SELECT_PROFILE}`, {
        userProfile: data.value,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('dashboards');
      },
    }
  );
};

export const useRemoveDashboardTile = (id: string | undefined) => {
  const useDashboardDeleteTileMutation = useDashboardDeleteTile();

  const handleRemove = () => {
    if (id) {
      useDashboardDeleteTileMutation.mutate(id);
    }
  };

  const removeMenuItem: MenuItem[] = [
    {
      id: '0',
      label: 'Remove this from your dashboard',
      icon: <DeleteIcon classNames="mr-1" />,
      value: 'removeFromDashboard',
      action: handleRemove,
    },
  ];

  return {
    removeMenuItem,
  };
};

export const useAddDashboardTile = (type: DashboardTiles, parameters?: any) => {
  const dashboardSaveMutation = useDashboardTileSaveMutation();

  const handleAdd = () => {
    dashboardSaveMutation.mutate({
      parameters: JSON.stringify(parameters),
      tileType: type,
    });
  };

  const addMenuItem: MenuItem[] = [
    {
      id: '0',
      label: 'Add to Dashboard',
      icon: <DashboardIcon classNames="mr-1" />,
      value: 'addToDashboard',
      action: handleAdd,
    },
  ];

  return {
    addMenuItem,
  };
};

export const useHandleTileBtnNavigate = (id?: string, options?: number) => {
  const { setNavSource, setNavigationTilePathOptions } = useUIStore();

  const handleButtonClick = () => () => {
    if (id) {
      setNavSource(NavSource.DashboardTile);
    }

    if (options) {
      setNavigationTilePathOptions(options);
    }
  };

  return {
    handleButtonClick,
  };
};
