import { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { DEFAULT_ANIMATION_DURATION } from '../../../../common/constants';
import useUIStore from '../../../../store/uiStore';
import Toast from '../../Toast';
import styles from './styles.module.css';

const ToastContainer: React.FC = () => {
    const { toasts, cleanAllToasts } = useUIStore();
    const nodeRef = useRef(null);

    useEffect(() => {
        cleanAllToasts();
    }, []);

    useEffect(() => {
        const cleanupFunctions: (() => void)[] = [];

        toasts.forEach((t, index) => {
            const toastEl = document.getElementById(`toast-${index}`);
            const animationEndHandler = () => {
                toastEl?.remove();
            };

            toastEl?.addEventListener('animationend', animationEndHandler);
            cleanupFunctions.push(() => {
                toastEl?.removeEventListener('animationend', animationEndHandler);
            });
        });

        return () => {
            cleanupFunctions.forEach(cleanup => cleanup());
        };
    }, [toasts]);

    const animationStyle = {
        animationDelay: '5000ms',
        animationFillMode: 'both',
    };

    return (
        <div
            aria-live="assertive"
            className={styles.toastContainer}
        >
            <ul className='relative' data-testid='toast-list'>
                {toasts.map((toast, index) => (
                    <CSSTransition
                        nodeRef={nodeRef}
                        timeout={DEFAULT_ANIMATION_DURATION}
                        unmountOnExit
                        appear
                        in={true}
                        key={`${toast.variant}-${index}`}
                    >
                        <li className='animate-slide-down' style={animationStyle} id={`toast-${index}`}>
                            <Toast {...toast}></Toast>
                        </li>
                    </CSSTransition>
                ))}
            </ul>
        </div>
    );
};

export default ToastContainer;
