import {
  DEFAULT_PAGE_SIZE,
  GROUP_TABLE_COLUMNS,
  PEOPLE_FILTER_BY,
  PEOPLE_SORT_BY,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { OverlayPage } from '@components/partials/OverlayPage';
import { TableWrapper } from '@components/partials/TableWrapper';
import { UsersMoveIcon } from '@components/ui';
import { useCreateGroup } from '@hooks/groups';
import { GroupForm } from '@hooks/groups/types';
import { usePersonsQuery, useUnassignedGroupsQuery } from '@hooks/people';
import { PeopleSortBy } from '@hooks/people/types';
import { useIsMobile } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';
import useTableStore from '@store/tableStore';
import useUIStore from '@store/uiStore';
import {
  hasPermission,
  isSingularOrPluralPeopleText,
  useTableData,
} from '@utils/index';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { GroupTreeModal } from './Modals/GroupTreeModal';
import { createCellValue } from './utils';
import { Variant } from '@components/ui/Toast';
import { UserRoles, Permissions } from '@hooks/users/types';
import useAuthStore from '@store/authStore';

const pageSize = DEFAULT_PAGE_SIZE;
type SelectedGroup = GroupForm & {
  parentId: string | undefined;
};

export const UnassignedPeoplePage: React.FC = () => {
  const { pageNumber, setPageNumber } = usePagination();
  const [search, setSearch] = useState('');
  const { addToast } = useUIStore();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
    filter: withDefault(StringParam, ''),
  });

  const query = useUnassignedGroupsQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    personStatus: queryParams.filter,
  });

  const personQuery = usePersonsQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    personStatus: queryParams.filter,
  });

  const { allSelectedItems, reset } = useTableStore();
  const { groupData, setGroupData } = useUIStore();
  const [isMovingMemberGroupModalOpen, setIsMovingMemberGroupModalOpen] =
    useState(false);
  const selectedGroup: SelectedGroup | null =
    groupData.name !== undefined ? groupData : null;
  const searchTag = !selectedGroup ? 'Group: Unassigned' : '';
  const headerTitle = !selectedGroup
    ? 'Unassigned people'
    : `Add people to ${selectedGroup.name}`;
  const showBulkAction = !selectedGroup ? true : false;
  const isFooterVisible = !selectedGroup ? false : true;
  const createGroup = useCreateGroup(selectedGroup?.parentId);
  const getTableData = useTableData(
    !selectedGroup ? query : personQuery,
    GROUP_TABLE_COLUMNS,
    createCellValue
  );
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;
  const loggedInUserId = useAuthStore.getState().user?.id || '';

  const checkboxHasPermission = hasPermission(
    userRole,
    Permissions.ADMINS_SUPPORTUSER
  );

  const handleGroupModalClose = () => {
    setIsMovingMemberGroupModalOpen(false);
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const handleCreateGroup = async () => {
    if (userRole === UserRoles.ADMIN) {
      groupData.hubUsersIds?.push(loggedInUserId);
    }

    const data = {
      name: selectedGroup?.name?.trim() || '',
      membersToAdd: allSelectedItems,
      viewersToAdd: Array.from(new Set(groupData.hubUsersIds)) || [],
    };

    try {
      await createGroup.mutateAsync(data);
      navigate(`/accounts/groups`);
      addToast({
        variant: Variant.Success,
        message: 'Your group’s been created',
        closeable: true,
      });
      setGroupData({
        name: undefined,
        parentId: undefined,
        hubUsersIds: undefined,
      });
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: 'Failed to create group',
        closeable: true,
      });
    }
  };

  return (
    <OverlayPage
      isFooterVisible={isFooterVisible}
      path="/accounts/groups"
      headerTitle={headerTitle}
      isCancelBtnVisible={!isMobile}
      footerSaveBtnLabel="Create group"
      footerText={
        allSelectedItems.length === 0
          ? '0 people selected'
          : `${isSingularOrPluralPeopleText(
              allSelectedItems.length
            )} will be added to ${selectedGroup?.name}`
      }
      handleOnSave={handleCreateGroup}
      handleOnClose={() => {
        setGroupData({
          name: undefined,
          parentId: undefined,
          hubUsersIds: undefined,
        });
      }}
      isDisabled={createGroup.isLoading || userRole === UserRoles.STANDARD}
    >
      <TableWrapper
        search={search}
        setSearch={setSearch}
        columns={GROUP_TABLE_COLUMNS}
        data={getTableData()}
        sortData={PEOPLE_SORT_BY}
        filterData={PEOPLE_FILTER_BY}
        searchKey="search"
        query={!selectedGroup ? query : personQuery}
        refetchQuery={!selectedGroup ? query.refetch : personQuery.refetch}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        setPageNumber={setPageNumber}
        isCollapsable={false}
        sliceColumns={5}
        isDownloadAvailable={false}
        searchTag={searchTag}
        showCheckboxes={checkboxHasPermission}
        showBulkAction={showBulkAction}
        bulkContent={
          <>
            <UsersMoveIcon classNames="mr-1" />
            <span>
              Move {isSingularOrPluralPeopleText(allSelectedItems.length)}
            </span>
          </>
        }
        handleBulkAction={() => {
          setIsMovingMemberGroupModalOpen(true);
        }}
      />
      <GroupTreeModal
        group={undefined}
        isModalOpen={isMovingMemberGroupModalOpen}
        handleGroupModalClose={handleGroupModalClose}
      />
    </OverlayPage>
  );
};
