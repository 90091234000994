import { EmailIcon, PhoneIcon } from '@components/ui/Icons';
import { TextTile } from '@pages/InsightsAndAnalytics/tiles/TextTile';
import useAuthStore from '@store/authStore';

type Props = {
  insightDescription: string;
};

export const RecommendationCard: React.FC<Props> = ({ insightDescription }) => {
  const authStore = useAuthStore();
  const activeOperatingCompany = authStore.user?.operatingCompany;

  return activeOperatingCompany ? (
    <TextTile
      headerImage={activeOperatingCompany?.logoUrl}
      classNames={'col-span-3 lg:col-span-1 !w-auto'}
    >
      <div className="flex w-full flex-col justify-start gap-y-2 align-baseline">
        <div className="flex flex-row items-baseline gap-x-2">
          <span className="text-20 font-light text-interfaceColor-100">
            Need help with {insightDescription}?
          </span>
        </div>
        <div className="flex flex-row items-baseline gap-x-2">
          <div className="flex flex-col">
            <span className="mb-2">Drop our support team a line:</span>
            <span className="mb-3 flex flex-row items-center gap-x-2">
              <EmailIcon />
              <span className="tex-16 font-light text-interfaceColor-100">
                {activeOperatingCompany?.email}
              </span>
            </span>
            <span className="flex flex-row items-center gap-x-2">
              <PhoneIcon />
              <span className="tex-16 font-light text-interfaceColor-100">
                {activeOperatingCompany?.phone}
              </span>
            </span>
          </div>
        </div>
      </div>
    </TextTile>
  ) : null;
};
