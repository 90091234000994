import * as React from 'react';
import { IconProps } from './types';

export const EyeIcon: React.FC<IconProps> = ({
  size = 5,
  color = 'text-interfaceColor-100',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      fill="none"
    >
      <g clipPath="url(#clip0_1_685)">
        <path
          d="M15.12 7.13863C15.3095 7.37488 15.4144 7.68182 15.4144 8.00007C15.4144 8.31832 15.3095 8.62526 15.12 8.86151C13.92 10.3144 11.1886 13.143 8 13.143C4.81143 13.143 2.08 10.3144 0.879996 8.86151C0.690465 8.62526 0.585552 8.31832 0.585552 8.00007C0.585552 7.68182 0.690465 7.37488 0.879996 7.13863C2.08 5.68575 4.81143 2.85714 8 2.85714C11.1886 2.85714 13.92 5.68575 15.12 7.13863Z"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 10.2857C9.26237 10.2857 10.2857 9.26237 10.2857 8C10.2857 6.73763 9.26237 5.71429 8 5.71429C6.73764 5.71429 5.71429 6.73763 5.71429 8C5.71429 9.26237 6.73764 10.2857 8 10.2857Z"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
