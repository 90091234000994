import { Button, Card, Tooltip } from '@components/ui';
import {
  AddUserIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CheckCircleIcon,
  DeleteIcon,
  EditIcon,
  EyeIcon,
  HamburgerIcon,
  SubgroupIcon,
  UserIcon,
  UsersMoveIcon,
} from '@components/ui/Icons';
import { GroupsResponse } from '@hooks/groups/types';
import useTableStore from '@store/tableStore';
import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.css';
import useUIStore from '@store/uiStore';
import {
  calculateDeepestLevel,
  findGroupById,
  isUserIdInGroupViewers,
} from '@utils/index';
import { MAX_SUBGROUP_LEVEL } from '@common/constants';
import { GroupVisibilityFlow } from '@pages/PeopleAndGroups/Groups/Modals/ManageGroupVisibilityModal';
import { UserRoles, User } from '@hooks/users/types';
import useAuthStore from '@store/authStore';

import { DropdownMenu, MenuItem } from '../DropdownMenu';

type TreeViewProps = {
  data: GroupsResponse[] | undefined;
  hubUsersQuery?: any;
  onNodeSelect?: (selectedNodes: string) => void;
  isSelectable?: boolean;
  isSimpleTree?: boolean;
  isMoveGroupFlow?: boolean;
  currentNode?: string;
  idsToDisable?: string[];
  groupToMove?: GroupsResponse;
  setGroupForModals?: React.Dispatch<
    React.SetStateAction<GroupsResponse | undefined>
  >;
  setIsCreateEditGroupModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeleteGroupModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMoveGroupModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsGroupVisibilityModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsViewGroupVisibilityModalOpen?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setGroupVisibilityFlow?: React.Dispatch<
    React.SetStateAction<GroupVisibilityFlow | null>
  >;
};

export const TreeView: React.FC<TreeViewProps> = ({
  data,
  hubUsersQuery = 0,
  onNodeSelect,
  setGroupForModals,
  setIsCreateEditGroupModalOpen,
  setIsDeleteGroupModalOpen,
  setIsMoveGroupModalOpen,
  setIsGroupVisibilityModalOpen,
  setIsViewGroupVisibilityModalOpen,
  setGroupVisibilityFlow,
  idsToDisable,
  groupToMove,
  isSelectable = false,
  isSimpleTree = false,
  isMoveGroupFlow = false,
  currentNode = '',
}) => {
  const navigate = useNavigate();
  const { reset } = useTableStore();
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
  const [selectedNode, setSelectedNode] = useState<string | null>(null);
  const refs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const { showGroupTooltip, setShowGroupTooltip } = useUIStore();

  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;
  const [superAdminIds, setSuperAdminIds] = useState<string[]>([]);
  const loggedInUserId = useAuthStore.getState().user?.id || '';
  const [groupsToDisable, setGroupsToDisable] = useState<string[]>([]);

  const findParentGroup = (
    groups: GroupsResponse[],
    targetId: string
  ): GroupsResponse | null => {
    for (const group of groups) {
      if (group.id === targetId) {
        return group;
      }
      const queue: GroupsResponse[] = [...group.subGroups];
      while (queue.length) {
        const current = queue.shift();
        if (!current) continue;
        if (current.id === targetId) {
          return group;
        }
        queue.push(...current.subGroups);
      }
    }
    return null;
  };

  const hasLevel5 = (group: GroupsResponse): boolean => {
    if (group.level === 5) {
      return true;
    }
    return group.subGroups.some((subGroup) => hasLevel5(subGroup));
  };

  const canCreateSubGroup = (
    groups: GroupsResponse[],
    targetId: string
  ): boolean => {
    const parentGroup = findParentGroup(groups, targetId);
    if (!parentGroup) {
      return false;
    }

    return !hasLevel5(parentGroup);
  };

  const getGroupMenuItems = (node: GroupsResponse): MenuItem[] => {
    return [
      {
        id: '1',
        label: 'Add / remove people',
        icon: <AddUserIcon size="5" />,
        action: () => {
          reset();
          navigate(`/accounts/groups/${node.id}/manage-people`, {
            state: { node },
          });
        },
      },
      {
        id: '2',
        label: 'Create subgroup',
        icon: (
          <SubgroupIcon
            classNames={
              node &&
              node.level &&
              node?.level >= MAX_SUBGROUP_LEVEL &&
              !canCreateSubGroup(data!, node.id)
                ? 'text-interfaceColor-40'
                : 'text-interfaceColor-100'
            }
          />
        ),
        disabled:
          node &&
          node.level !== undefined &&
          node?.level >= MAX_SUBGROUP_LEVEL &&
          !canCreateSubGroup(data!, node.id),
        action: () => {
          setIsCreateEditGroupModalOpen
            ? setIsCreateEditGroupModalOpen(true)
            : null;
          setGroupForModals
            ? setGroupForModals({
                id: '',
                name: '',
                parentId: node.id,
                memberCount: 0,
                subGroups: [],
                groupViewers: node.groupViewers,
              })
            : null;
        },
      },
      {
        id: '3',
        label: 'Move group',
        icon: <UsersMoveIcon size={'5'} />,
        action: () => {
          node.deepestLevel = calculateDeepestLevel(node);

          setIsMoveGroupModalOpen ? setIsMoveGroupModalOpen(true) : null;
          setGroupForModals ? setGroupForModals(node) : null;
        },
      },
      {
        id: '4',
        label: 'Edit group name',
        icon: <EditIcon />,
        action: () => {
          setIsCreateEditGroupModalOpen
            ? setIsCreateEditGroupModalOpen(true)
            : null;
          setGroupForModals ? setGroupForModals(node) : null;
        },
      },

      {
        id: '5',
        label: 'Manage visibility',
        icon: <EyeIcon />,
        action: () => {
          const currentGroup = data ? findGroupById(data, node.id) : null;
          setGroupForModals ? setGroupForModals(currentGroup!) : null;
          setIsGroupVisibilityModalOpen
            ? setIsGroupVisibilityModalOpen(true)
            : null;
          setGroupVisibilityFlow
            ? setGroupVisibilityFlow(GroupVisibilityFlow.ManageVisibility)
            : null;
        },
      },
      {
        id: '6',
        classNames: 'text-errorColor-150',
        label: 'Delete group',
        icon: <DeleteIcon color="text-errorColor-150" classNames="h-[1rem]" />,
        action: () => {
          setIsDeleteGroupModalOpen ? setIsDeleteGroupModalOpen(true) : null;
          setGroupForModals ? setGroupForModals(node) : null;
        },
      },
    ];
  };

  const renderDropdownMenu = (node: GroupsResponse) => {
    return (
      <div className="absolute -top-1 right-0 md:relative md:top-0">
        <DropdownMenu
          items={getGroupMenuItems(node)}
          closeOnClickOutside={true}
          dropdownMenuClasses={'!w-[260px]'}
          buttonProps={{ icon: <HamburgerIcon></HamburgerIcon> }}
        ></DropdownMenu>
      </div>
    );
  };

  const dropdownContentWithTooltip = useCallback((node: GroupsResponse) => {
    return (
      <Tooltip
        content="Click this icon to edit, delete or assign more people to this group"
        align="right"
        isOpen={true}
      >
        {renderDropdownMenu(node)}
      </Tooltip>
    );
  }, []);

  const getUsersNum = (node: GroupsResponse) => {
    const groupViewersIds: string[] = [];

    node.groupViewers?.forEach((g) => {
      groupViewersIds.push(g.userId);
    });

    superAdminIds.forEach((id) => {
      if (!groupViewersIds.includes(id)) {
        groupViewersIds.push(id);
      }
    });

    return groupViewersIds.length;
  };

  useEffect(() => {
    if (data?.length === 1 && data[0].subGroups.length === 0) {
      setShowGroupTooltip(true);
    } else {
      setShowGroupTooltip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setShowGroupTooltip]);

  const getDisabledGroupsIds = (subgroup: GroupsResponse) => {
    const groupToDisable: string[] = [];

    const viewersIds = getIds(subgroup);
    if (!viewersIds.includes(loggedInUserId)) {
      groupToDisable.push(subgroup.id);
    }

    subgroup.subGroups.forEach((el: GroupsResponse) => {
      const viewersIds = getIds(el);
      if (!viewersIds.includes(loggedInUserId)) {
        groupToDisable.push(el.id);
      }

      if (el.subGroups && el.subGroups.length > 0) {
        getDisabledGroupsIds(el);
      }
    });

    setGroupsToDisable((prevIds) => [...prevIds, ...groupToDisable]);
  };

  useEffect(() => {
    const nodeIds: string[] = [];
    const traverse = (nodes: GroupsResponse[] | undefined, level = 1) => {
      if (nodes) {
        for (const node of nodes) {
          node.level = level;
          nodeIds.push(node.id);
          traverse(node.subGroups, level + 1);
        }
      }
    };

    traverse(data);
    if (isSimpleTree) {
      setExpandedNodes(nodeIds);
    }

    if (!isMoveGroupFlow && userRole === UserRoles.ADMIN) {
      setGroupsToDisable([]);
      data?.forEach((group) => {
        getDisabledGroupsIds(group);
      });
    }
  }, [data]);

  useEffect(() => {
    const superadminIds: string[] = [];
    if (hubUsersQuery && hubUsersQuery?.items) {
      hubUsersQuery?.items.forEach((user: User) => {
        if (user.role === UserRoles.SUPERADMIN) {
          superadminIds.push(user.id);
        }
      });
    }

    setSuperAdminIds(superadminIds);
  }, [hubUsersQuery, data]);

  const handleNodeToggle = useCallback((node: GroupsResponse) => {
    setExpandedNodes((prevExpanded) => {
      const isExpanded = prevExpanded.includes(node.id);
      return isExpanded
        ? prevExpanded.filter((id) => id !== node.id)
        : [...prevExpanded, node.id];
    });
  }, []);

  const handleNodeSelect = useCallback(
    (nodeId: string, event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      if (isSelectable) {
        setSelectedNode(nodeId);
        onNodeSelect && onNodeSelect(nodeId);
      }
    },
    [isSelectable, onNodeSelect]
  );

  const isNodeExpanded = useCallback(
    (nodeId: string): boolean => {
      return expandedNodes.includes(nodeId);
    },
    [expandedNodes]
  );

  const isNodeSelected = useCallback(
    (nodeId: string): boolean => {
      return selectedNode === nodeId;
    },
    [selectedNode]
  );

  const getIds = (node: GroupsResponse) => {
    const ids: string[] = [];
    if (node.groupViewers) {
      node.groupViewers.forEach((u) => ids.push(u.userId));
    }
    return ids;
  };

  const isDisabled = (node: GroupsResponse) => {
    if (!isMoveGroupFlow) {
      return (
        groupsToDisable.includes(node.id) ||
        node.id === currentNode ||
        (groupToMove &&
          node &&
          groupToMove?.deepestLevel &&
          node?.level &&
          groupToMove?.deepestLevel + node?.level > MAX_SUBGROUP_LEVEL)
      );
    } else {
      if (node.id === currentNode) {
        return true;
      } else if (isMoveGroupFlow) {
        return (
          idsToDisable?.includes(node.id) ||
          (userRole === UserRoles.ADMIN &&
            !getIds(node).includes(loggedInUserId)) ||
          (groupToMove &&
            node &&
            groupToMove?.deepestLevel &&
            node?.level &&
            groupToMove?.deepestLevel + node?.level > MAX_SUBGROUP_LEVEL)
        );
      } else {
        return false;
      }
    }
  };

  const canSeeGroup = (node: GroupsResponse) => {
    if (userRole === UserRoles.SUPERADMIN || userRole === UserRoles.SUPPORT) {
      return true;
    }

    if (userRole === UserRoles.ADMIN) {
      const ids: string[] = [];
      if (node.groupViewers) {
        node.groupViewers.forEach((user) => {
          ids.push(user.userId);
        });
      }

      return ids.includes(loggedInUserId);
    }
  };

  const renderTreeNodes = useCallback(
    (nodes: GroupsResponse[], isRoot = false): JSX.Element[] => {
      return nodes.map((node) => {
        const isSelected = isNodeSelected(node.id);
        const hasChildren = node.subGroups && node.subGroups.length > 0;
        const rootClassName = isRoot
          ? `${styles.treeRootNode} ${
              isSimpleTree && styles.simpleTreeRootNode
            }`
          : '';

        const hasDeepNestedSubGroups = (node: GroupsResponse): boolean => {
          if (node.subGroups) {
            for (const subgroup of node.subGroups) {
              if (
                subgroup.subGroups &&
                subgroup.subGroups.length > 0 &&
                isNodeExpanded(subgroup.id) &&
                hasChildren
              ) {
                return true;
              }
            }
          }
          return false;
        };

        const isDeepNested = isRoot && hasDeepNestedSubGroups(node);
        const rootSimple =
          isDeepNested && isNodeExpanded(node.id)
            ? `${styles.treeRootNodeSimple} ${
                isSimpleTree && styles.simpleTreeRootNodeSimple
              }`
            : styles.treeRootNodeNested;

        return (
          <div
            key={node.id}
            className={`${styles.treeView} ${rootClassName} ${rootSimple}`}
            ref={(el) => (refs.current[node.id] = el)}
            onClick={
              isSimpleTree && isDisabled(node)
                ? undefined
                : (event) => handleNodeSelect(node.id, event)
            }
            data-testid={`node-${node.id}`}
          >
            {hasChildren && isNodeExpanded(node.id) && (
              <div
                className={`${styles.treeViewHeightChild} ${
                  isSimpleTree && styles.simpleTreeViewHeightChild
                }`}
              ></div>
            )}
            <Card
              classNames={`${styles.treeViewCard} ${
                isSelected && styles.treeViewCardSelected
              } ${isSimpleTree && styles.simpleTreeViewCard} ${
                isSimpleTree && isDisabled(node)
                  ? '!bg-interfaceColor-10 !text-interfaceColor-70 !hover:none !cursor-default'
                  : ''
              }`}
            >
              <Card.Header classNames={styles.treeViewCardHeader}>
                <div
                  className={`${styles.treeViewCardHeaderContent} ${
                    isSimpleTree && styles.simpleTreeViewCardHeaderContent
                  }`}
                >
                  <div>
                    {!isSimpleTree && (
                      <p
                        className={styles.groupName}
                        data-testid={`node-name-${node.id}`}
                      >
                        {node.name}
                        {isSelected && (
                          <CheckCircleIcon
                            classNames={styles.treeNodeSelectedIcon}
                          />
                        )}
                      </p>
                    )}

                    {isSimpleTree && (
                      <p
                        className="inline-flex"
                        data-testid={`node-name-${node.id}`}
                      >
                        {node.name.length > 30
                          ? `${node.name.substring(0, 27)}...`
                          : node.name}
                        {isSelected && (
                          <CheckCircleIcon
                            classNames={styles.treeNodeSelectedIcon}
                          />
                        )}
                      </p>
                    )}

                    {!isSimpleTree && (
                      <div className="flex">
                        <Button
                          data-testid={`toggle-button-${node.id}`}
                          className={cn(
                            styles.treeViewActions,
                            node.memberCount === 0 ? 'cursor-not-allowed' : ''
                          )}
                          disabled={
                            node.memberCount === 0 ||
                            ((userRole !== UserRoles.SUPERADMIN && userRole !== UserRoles.SUPPORT) &&
                              !isUserIdInGroupViewers(
                                loggedInUserId,
                                node.groupViewers || []
                              ))
                          }
                          size="extraSmall"
                          variant="default"
                          onClick={() =>
                            navigate(`/accounts/groups/${node.id}/members`, {
                              state: { node },
                            })
                          }
                        >
                          <span
                            className="flex items-center"
                            data-testid={`member-count-${node.id}`}
                          >
                            <UserIcon classNames="w-4 h-4" /> {node.memberCount}
                            <span className={styles.treeViewActionsText}>
                              {node.memberCount === 1 ? 'member' : 'members'}
                            </span>
                          </span>
                        </Button>

                        <Button
                          data-testid={`visibility-button-${node.id}`}
                          className={cn(styles.treeViewActions, 'ml-3')}
                          size="extraSmall"
                          variant="default"
                          onClick={() => {
                            setGroupForModals ? setGroupForModals(node) : null;
                            setIsViewGroupVisibilityModalOpen
                              ? setIsViewGroupVisibilityModalOpen(true)
                              : null;
                            setGroupVisibilityFlow
                              ? setGroupVisibilityFlow(
                                  node.isPubliclyVisible
                                    ? GroupVisibilityFlow.VisibleToAll
                                    : GroupVisibilityFlow.LimitedVisibility
                                )
                              : null;
                          }}
                        >
                          <span
                            className="flex items-center"
                            data-testid={`visibility-details-${node.id}`}
                          >
                            <EyeIcon classNames="w-4 h-4" />
                            <span className={styles.visibilityText}>
                              {node.groupViewers?.length ===
                              hubUsersQuery?.items.length ? (
                                <span>
                                  All Hub users
                                  <span className="hidden lg:ml-1 lg:inline">
                                    can see this
                                  </span>
                                </span>
                              ) : (
                                <span>
                                  {`${getUsersNum(node)} Hub user(s)`}
                                  <span className="hidden lg:ml-1 lg:inline">
                                    can see this
                                  </span>
                                </span>
                              )}
                            </span>
                          </span>
                        </Button>
                      </div>
                    )}
                  </div>

                  {!isSimpleTree && (
                    <div className="flex flex-row space-x-3">
                      {hasChildren && (
                        <Button
                          size="small"
                          variant="default"
                          className={styles.treeNodeExpandButton}
                          onClick={() => handleNodeToggle(node)}
                          data-testid={`member-count-${node.id}`}
                        >
                          <span
                            className="flex items-center "
                            data-testid={`expand-text-${node.id}`}
                          >
                            {node.subGroups && node.subGroups.length ? (
                              <span className="px-1 md:pr-1">
                                {node.subGroups.length}
                              </span>
                            ) : null}
                            {node.subGroups && node.subGroups.length === 1
                              ? 'subgroup'
                              : 'subgroups'}
                            <span className="ml-1 mr-0">
                              {isNodeExpanded(node.id) ? (
                                <ArrowDownIcon size="3" classNames="w-5" />
                              ) : (
                                <ArrowUpIcon size="3" classNames="w-5" />
                              )}
                            </span>
                          </span>
                        </Button>
                      )}

                      {canSeeGroup(node) && (
                        <>
                          {showGroupTooltip
                            ? dropdownContentWithTooltip(node)
                            : renderDropdownMenu(node)}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </Card.Header>
            </Card>

            {isNodeExpanded(node.id) && node.subGroups && (
              <div
                className={cn('ml-8', {
                  '!ml-4': isSimpleTree,
                })}
              >
                {renderTreeNodes(node.subGroups)}
              </div>
            )}
          </div>
        );
      });
    },
    [
      isNodeSelected,
      isNodeExpanded,
      isSimpleTree,
      data?.length,
      showGroupTooltip,
      dropdownContentWithTooltip,
      handleNodeSelect,
      navigate,
      handleNodeToggle,
      superAdminIds,
    ]
  );

  return <>{renderTreeNodes(data || [], true)}</>;
};
