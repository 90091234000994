import * as React from 'react';
import { IconProps } from './types';

export const FilterIcon: React.FC<IconProps> = ({
  color = 'text-interfaceColor-100',
  classNames = '',
  size = 4,
}) => {
  return (
    <svg
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g>
        <path
          d="M2.28571 4C2.51084 4 2.73375 3.95566 2.94174 3.86951C3.14973 3.78335 3.33871 3.65708 3.4979 3.4979C3.65708 3.33871 3.78335 3.14973 3.86951 2.94174C3.95566 2.73375 4 2.51084 4 2.28571C4 2.06059 3.95566 1.83767 3.86951 1.62968C3.78335 1.4217 3.65708 1.23272 3.4979 1.07353C3.33871 0.914343 3.14973 0.78807 2.94174 0.701919C2.73375 0.615768 2.51084 0.571426 2.28571 0.571426C1.83106 0.571426 1.39502 0.752038 1.07353 1.07353C0.752038 1.39502 0.571426 1.83106 0.571426 2.28571C0.571426 2.74037 0.752038 3.1764 1.07353 3.4979C1.39502 3.81939 1.83106 4 2.28571 4Z"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 2.28571H15.4286"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.99999 9.71428C8.22511 9.71428 8.44803 9.66994 8.65602 9.58379C8.86401 9.49764 9.05299 9.37137 9.21217 9.21218C9.37136 9.053 9.49763 8.86401 9.58379 8.65603C9.66994 8.44804 9.71428 8.22512 9.71428 8C9.71428 7.77488 9.66994 7.55196 9.58379 7.34397C9.49763 7.13598 9.37136 6.947 9.21217 6.78782C9.05299 6.62863 8.86401 6.50236 8.65602 6.41621C8.44803 6.33005 8.22511 6.28571 7.99999 6.28571C7.54533 6.28571 7.1093 6.46632 6.78781 6.78782C6.46632 7.10931 6.28571 7.54534 6.28571 8C6.28571 8.45466 6.46632 8.89069 6.78781 9.21218C7.1093 9.53367 7.54533 9.71428 7.99999 9.71428Z"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.571426 8H6.28571"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.71428 8H15.4286"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.7143 15.4286C14.1689 15.4286 14.605 15.248 14.9265 14.9265C15.248 14.605 15.4286 14.1689 15.4286 13.7143C15.4286 13.2596 15.248 12.8236 14.9265 12.5021C14.605 12.1806 14.1689 12 13.7143 12C13.2596 12 12.8236 12.1806 12.5021 12.5021C12.1806 12.8236 12 13.2596 12 13.7143C12 14.1689 12.1806 14.605 12.5021 14.9265C12.8236 15.248 13.2596 15.4286 13.7143 15.4286Z"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 13.7143H0.571426"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
