import React from 'react';
import { IconProps } from './types';

export const ReconnectIcon: React.FC<IconProps> = ({
  size = '12',
  color = 'text-interfaceColor-100',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M8.286.643V7.07h4.5L5.715 17.357v-6.429h-4.5L8.286.643Z"
      />
    </svg>
  );
};
