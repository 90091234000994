import {
  DEFAULT_PAGE_SIZE,
  MFA_STATUS_SORT_BY,
  MFA_STATUS_TABLE_COLUMNS,
  USER_TYPE,
} from '@common/constants';
import { ExtendedOption, IOption, OrderBy } from '@common/types';
import { OverlayPage, TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { PeopleSortBy } from '@hooks/people/types';
import { useIsDesktop } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';

import {
  formatDate,
  getFirstMatchedFilterValue,
  useTableData,
} from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import styles from './styles.module.css';

import { useLocationsQuery } from '@hooks/locations';
import { Location } from '@hooks/locations/types';
import { useGroupsFilterQuery } from '@hooks/groups';
import { GroupsResponse } from '@hooks/groups/types';
import { createCellValue } from './utils';
import {
  useMfaStatusPreviewQuery,
  useMfaStatusSnapshotQuery,
} from '@hooks/security';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';

import { RecommendationCard } from '@pages/Dashboard/tiles/helpers/RecommendationCard';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';

const pageSize = DEFAULT_PAGE_SIZE;

const MfaStatusPage: React.FC = () => {
  const isDesktop = useIsDesktop();
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();

  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, 'mfastatus'),
    order: withDefault(StringParam, 'asc'),
    filter: withDefault(StringParam, ''),
  });

  const { data: locationsData, isFetched: locationDataFetched } =
    useLocationsQuery();
  const redirectPath = useNavSourceRedirect('/insights-and-analytics/assurance');
  const { data: groupsData, isFetched: groupsDataFetched } =
    useGroupsFilterQuery();

  const allLocationIds: string[] = [];
  const groupsIDs: string[] = [];
  const locationFilerOptions: IOption[] = [];
  const groupFilerOptions: ExtendedOption[] = [];
  const transformedFilterData: FilterData[] = [];

  if (locationDataFetched && groupsDataFetched) {
    transformedFilterData.push({
      label: 'User type',
      name: 'userType',
      singleSelect: true,
      options: [
        { label: 'Admin', value: 'Admin' },
        { label: 'Standard', value: 'Standard' },
      ],
    });

    if (locationsData) {
      locationsData?.forEach((location: Location) => {
        locationFilerOptions.push({ label: location.name, value: location.id });
        allLocationIds.push(location.id);
      });

      transformedFilterData.push({
        label: 'Location',
        name: 'location',
        singleSelect: false,
        options: locationFilerOptions,
      });
    }

    if (groupsData) {
      groupsData?.forEach((group: GroupsResponse) => {
        groupFilerOptions.push({
          name: group.name,
          value: group.id,
          parentId: group.parentId,
          subGroups: group.subGroups,
          id: group.id,
          label: group.name,
        });
      });

      transformedFilterData.push({
        label: 'Groups',
        name: 'groups',
        singleSelect: false,
        options: groupFilerOptions,
      });
    }
  }

  useEffect(() => {
    locationsData?.forEach((location: Location) => {
      locationFilerOptions.push({ label: location.name, value: location.id });
      allLocationIds.push(location.id);
    });
    setLocationIds(allLocationIds);
  }, [locationsData]);

  useEffect(() => {
    const nested = (element: any) => {
      element.subGroups.forEach((subElement: any) => {
        groupsIDs.push(subElement.id);
        if (subElement.subGroups && subElement.subGroups.length > 0) {
          nested(subElement);
        }
      });
    };

    groupsData?.forEach((element: any) => {
      groupsIDs.push(element.id);
      if (element.subGroups && element.subGroups.length > 0) {
        nested(element);
      }
    });

    setGroupIds(groupsIDs);
  }, [groupsData]);

  // MFA status - table
  const query = useMfaStatusPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    locationList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => locationIds.includes(filter))
      : [],
    groupList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => groupIds.includes(filter))
      : [],
    userType: getFirstMatchedFilterValue(queryParams, USER_TYPE),
  });
  const getTableData = useTableData(
    query,
    MFA_STATUS_TABLE_COLUMNS,
    createCellValue
  );

  // MFA Status Snapshot - Doughnut
  const { data: mfaStatusData, isLoading: isMfaStatusLoading } =
    useMfaStatusSnapshotQuery({
      userType: getFirstMatchedFilterValue(queryParams, USER_TYPE),
      groupList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => groupIds.includes(filter))
        : [],
      locationList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => locationIds.includes(filter))
        : [],
    });

  // const dashboardSaveMutation = useDashboardTileSaveMutation();

  // const handleItemAction = (type: DashboardTiles) => () => {
  //   const getParametersData = () => {
  //     switch (type) {
  //       case DashboardTiles.MicrosoftMFAStatusSnapshot:
  //         return {
  //           userType: getFirstMatchedFilterValue(queryParams, USER_TYPE),
  //           passwordExpiry: getFirstMatchedFilterValue(queryParams, AUTO_RENEW),
  //           licenseTypeList: getFilterValues(
  //             queryParams,
  //             (filterData?.licenseType?.map((item) => item.value) || []).filter(
  //               (item): item is string => item !== undefined
  //             )
  //           ),
  //           locationList: queryParams.filter
  //             ? queryParams.filter
  //                 .split(',')
  //                 .filter((filter) => locationIds.includes(filter))
  //             : [],
  //         };
  //       default:
  //         return {};
  //     }
  //   };

  //   const parametersData = getParametersData();

  //   dashboardSaveMutation.mutate({
  //     parameters: JSON.stringify(parametersData),
  //     tileType: type,
  //   });
  // };

  // const createMenuItems = (tileType: DashboardTiles): MenuItem[] => [
  //   {
  //     id: '0',
  //     label: 'Add to Dashboard',
  //     icon: <DashboardIcon classNames="mr-1" />,
  //     value: 'addToDashboard',
  //     action: handleItemAction(tileType),
  //   },
  // ];

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.MFA_STATUS_PREVIEW}/export`,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      locationList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => locationIds.includes(filter))
        : [],
      groupList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => groupIds.includes(filter))
        : [],
      userType: getFirstMatchedFilterValue(queryParams, USER_TYPE),
    },
    filename: `Clevr360_MFA_status_${formatDate(new Date().toString())}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`MFA status`}
    >
      <>
        <div className={styles.pageGrid}>
          {isDesktop && (
            <>
              <DoughnutChartTile
                classNames={'col-span-3 lg:col-auto lg:row-start-1'}
                contextData={[]}
                headerTitle={'MFA status'}
                isLoading={isMfaStatusLoading}
                data={mfaStatusData ? Object.values(mfaStatusData) : []}
                dataLabels={['Users without MFA', 'Users with MFA']}
                showBracketLabelValue={true}
              />

              <RecommendationCard insightDescription={'Microsoft MFA status'} />
            </>
          )}
        </div>
        <div className="mt-2">
          <TableWrapper
            search={search}
            setSearch={setSearch}
            searchPlaceholder={'Search by license type, name, email address'}
            columns={MFA_STATUS_TABLE_COLUMNS}
            data={getTableData()}
            sortData={MFA_STATUS_SORT_BY}
            filterData={transformedFilterData}
            searchKey="search"
            query={query}
            refetchQuery={query.refetch}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setPageNumber={setPageNumber}
            isCollapsable={true}
            sliceColumns={2}
            floatingFilterButton={true}
            hasFilterBanner={false}
            isLicensePage={true}
            searchCountStatsLabel="licenses"
            filterHeaderText="Filter MFA status"
            isDownloadAvailable={true}
            handleDownload={handleDownloadClick}
          />
        </div>
      </>
    </OverlayPage>
  );
};

export default MfaStatusPage;
