import { BaseLayout } from '@components/layouts/BaseLayout';

// devs only
import { ReportPreviewPage } from '@pages/Reports/ReportPreview';
import { IconPreviewPage } from '@pages/ip';

import { DashboardPage } from '@pages/Dashboard';
// import HelpPage from '@pages/Help';
import HubSettingsPage from '@pages/HubSettings';
import ConnectedServicesPage from '@pages/HubSettings/ConnectedServices';
import OperatingLocationsPage from '@pages/HubSettings/OperatingLocations';
import HubSettingsUsersPage from '@pages/HubSettings/Users';
import InsightsAndAnalyticsLayout from '@pages/InsightsAndAnalytics';
import ServiceUsagePage from '@pages/InsightsAndAnalytics/Adoption/ServiceUsage';
import ExpiredLicensePage from '@pages/InsightsAndAnalytics/Utilisation/ExpiredLicense';
import ExpiringLicensePage from '@pages/InsightsAndAnalytics/Utilisation/ExpiringLicense';
import InactiveLicensePage from '@pages/InsightsAndAnalytics/Utilisation/InactiveLicense';
import OverassignedLicensePage from '@pages/InsightsAndAnalytics/Utilisation/OverassignedLicense';
import UnassignedLicensePage from '@pages/InsightsAndAnalytics/Utilisation/UnassignedLicense';
import SecurityPage from '@pages/InsightsAndAnalytics/Security';
import MfaStatusPage from '@pages/InsightsAndAnalytics/Security/MfaStatus';
import SharedMailboxesPage from '@pages/InsightsAndAnalytics/Security/SharedMailboxes';
import SignInEnabledPage from '@pages/InsightsAndAnalytics/Security/SignInEnabled';
import UtilisationPage from '@pages/InsightsAndAnalytics/Utilisation';
import LicenseInventoryLayout from '@pages/InsightsAndAnalytics/Utilisation/LicenseInventory';
import LicenseAssignmentPage from '@pages/InsightsAndAnalytics/Utilisation/LicenseInventory/LicenseAssignment';
import SubscriptionsPage from '@pages/InsightsAndAnalytics/Utilisation/LicenseInventory/Subscriptions';
import { NotificationsListPage } from '@pages/Notifications';
import PeopleAndGroupsLayout from '@pages/PeopleAndGroups';
import { AddRemoveMembers as AddRemoveGroupMembers } from '@pages/PeopleAndGroups/Groups/AddRemoveMembers';
import GroupPage from '@pages/PeopleAndGroups/Groups/Groups';
import { MembersPage as GroupMembersPage } from '@pages/PeopleAndGroups/Groups/Members';
import { UnassignedPeoplePage as UnassignedPeopleGroupsPage } from '@pages/PeopleAndGroups/Groups/UnassignedPeople';
import { AddRemoveMembers as AddRemoveLocationMembers } from '@pages/PeopleAndGroups/Locations/AddRemoveMembers';
import LocationPage from '@pages/PeopleAndGroups/Locations/Locations';
import { MembersPage as LocationMembersPage } from '@pages/PeopleAndGroups/Locations/Members';
import { UnassignedPeoplePage as UnassignedPeopleLocationsPage } from '@pages/PeopleAndGroups/Locations/UnassignedPeople';
import PeoplePage from '@pages/PeopleAndGroups/People/People';
import { PrivacyPage } from '@pages/Privacy';
import ReportsPage from '@pages/Reports';
import { TermsAndConditionsPage } from '@pages/Terms';
import { Navigate, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import PrivateRoute from './PrivateRoute';
import DeviceInventoryPage from '@pages/InsightsAndAnalytics/Utilisation/DeviceInventory';
import ServiceAdoptionPage from '@pages/InsightsAndAnalytics/Adoption/ServiceAdoption';
import TeamsGroupsAdoptionPage from '@pages/InsightsAndAnalytics/Adoption/TeamsGroupsAdoption';
import MyProfileLayout from '@pages/HubSettings/MyProfile';
import YourDetailsPage from '@pages/HubSettings/MyProfile/YourDetails';
import EmailNotificationsPage from '@pages/HubSettings/MyProfile/EmailNotifications';
import MsTeamsHealthPage from '@pages/InsightsAndAnalytics/Adoption/MsTeamsHealth';
import AdoptionsPage from '@pages/InsightsAndAnalytics/Adoption';
import CallsMadePeoplePage from '@pages/InsightsAndAnalytics/Productivity/Activities/People';
import CallsMadeLocationsPage from '@pages/InsightsAndAnalytics/Productivity/Activities/Locations';
import CallsMadeGroupsPage from '@pages/InsightsAndAnalytics/Productivity/Activities/Groups';
import CallsMadeLayout from '@pages/InsightsAndAnalytics/Productivity/Activities';
import ProductivityPage from '@pages/InsightsAndAnalytics/Productivity';
import TestPdf from '@pages/TestPdf';
import { OnboardingProfileSelect } from '@pages/Authentication/OnboardingProfileSelect';
import Error404Page from '@pages/ErrorPages/404';
import SharePointUsagePage from '@pages/InsightsAndAnalytics/Utilisation/SharePointUsage';
import MeetingAdoptionPage from '@pages/InsightsAndAnalytics/Adoption/MeetingAdoption';
import UserServiceAdoptionPage from '@pages/InsightsAndAnalytics/Adoption/UserServiceAdoption';
import OrgGroupsActivityUserPage from '@pages/InsightsAndAnalytics/Adoption/OrgGroupsActivityUser';
import DeviceAdoptionPage from '@pages/InsightsAndAnalytics/Adoption/DeviceAdoption';
import MeetingsRoomPage from '@pages/InsightsAndAnalytics/Adoption/MeetingsRoom';
import MsSecureScorePage from '@pages/InsightsAndAnalytics/Security/MsSecureScore';
import ExternalTeamsAccessPage from '@pages/InsightsAndAnalytics/Security/ExternalTeamsAccess';
// import { UserRoles } from '@hooks/users/types';
import UnauthorizedPage from '@pages/UnauthorizedPage';
import GroupsTeamsPage from '@pages/InsightsAndAnalytics/Utilisation/GroupsTeams';
import ExternalTeamsAccessDetailsPage from '@pages/InsightsAndAnalytics/Security/ExternalTeamsAccess/DetailsPage';
import MsSecureScoreActionPage from '@pages/InsightsAndAnalytics/Security/MsSecureScore/DetailsPage';
import TeamsGroupsActivityPage from '@pages/InsightsAndAnalytics/Adoption/TeamsGroupsActivity';
import { Permissions, permissionConfig } from '@hooks/users/types';
import PersonPreviewPage from '@pages/PeopleAndGroups/People/PersonPreviewPage';
import FailedLoginTypesPage from '@pages/InsightsAndAnalytics/Security/FailedLoginData/LoginTypes';
import FailedLoginCountriesPage from '@pages/InsightsAndAnalytics/Security/FailedLoginData/Countries';
import FailedDataLayout from '@pages/InsightsAndAnalytics/Security/FailedLoginData/index';
import FailedLoginAccountsPage from '@pages/InsightsAndAnalytics/Security/FailedLoginData/Accounts';
import EnhancedSecurityPage from '@pages/HubSettings/ConnectedServices/EnhancedSecurity';
import TechincalInformationFormPage from '@pages/HubSettings/ConnectedServices/EnhancedSecurity/TechincalInformationForm';
import MailboxForwarding from '@pages/InsightsAndAnalytics/Security/MailboxForwarding';
import RemoteDataAccountsPage from '@pages/InsightsAndAnalytics/Security/RemoteAccessData/AccountLevel';
import RemoteTenantwideAppsPage from '@pages/InsightsAndAnalytics/Security/RemoteAccessData/TenantwideApps';
import RemoteAccessDataLayout from '@pages/InsightsAndAnalytics/Security/RemoteAccessData/index';
import OutdatedOSDataLayout from '@pages/InsightsAndAnalytics/Security/OutdatedOSData';
import OutdatedOSAccountsPage from '@pages/InsightsAndAnalytics/Security/OutdatedOSData/Accounts';
import SuspiciousActivityLayout from '@pages/InsightsAndAnalytics/Security/SuspiciousActivity';
import SuspiciousActivityEventsPage from '@pages/InsightsAndAnalytics/Security/SuspiciousActivity/ActivityData';
import SuspiciousActivityAccountsPage from '@pages/InsightsAndAnalytics/Security/SuspiciousActivity/Accounts';
import OutdatedBrowserDataLayout from '@pages/InsightsAndAnalytics/Security/OutdatedBrowsers';
import OutdatedBrowsersPage from '@pages/InsightsAndAnalytics/Security/OutdatedBrowsers/Browsers';
import OutdatedBrowserAccountsPage from '@pages/InsightsAndAnalytics/Security/OutdatedBrowsers/Accounts';
import OutdatedOSPage from '@pages/InsightsAndAnalytics/Security/OutdatedOSData/OperatingSystems';
import SuspiciousActivityCountries from '@pages/InsightsAndAnalytics/Security/SuspiciousActivity/Countries';
import DelegatedMailboxLayout from '@pages/InsightsAndAnalytics/Security/DelegatedMailbox';
import DelegatedMailboxesPage from '@pages/InsightsAndAnalytics/Security/DelegatedMailbox/Mailboxes';
import MailboxDelegatesPage from '@pages/InsightsAndAnalytics/Security/DelegatedMailbox/Delegates';
import FailedLoginMapPage from '@pages/InsightsAndAnalytics/Security/FailedLoginData/Map';
import CallForwardingDestinationsPage from '@pages/InsightsAndAnalytics/Security/CallForwardingDestinations';
import CallQueuesPage from '@pages/InsightsAndAnalytics/Adoption/CallQueues';
import ConfiguredTrunksPage from '@pages/InsightsAndAnalytics/Utilisation/ConfiguredTrunks';

export const RouteApp = () => {
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <Routes>
        <Route path="/test-pdf" element={<TestPdf />} />

        <Route element={<BaseLayout />}>
          <Route
            path="/select-profile"
            element={
              <PrivateRoute allowedRoles={permissionConfig[Permissions.ALL]}>
                <OnboardingProfileSelect />
              </PrivateRoute>
            }
          />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <DashboardPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/insights-and-analytics"
            element={<InsightsAndAnalyticsLayout />}
          >
            <Route
              index
              element={<Navigate to="/insights-and-analytics/utilisation" />}
            />
            <Route
              path="utilisation"
              element={
                <PrivateRoute>
                  <UtilisationPage />
                </PrivateRoute>
              }
            />

            <Route
              path="utilisation/expiring-soon"
              element={
                <PrivateRoute>
                  <ExpiringLicensePage />
                </PrivateRoute>
              }
            />

            <Route
              path="utilisation/expired"
              element={
                <PrivateRoute>
                  <ExpiredLicensePage />
                </PrivateRoute>
              }
            />

            <Route
              path="utilisation/unassigned-license"
              element={
                <PrivateRoute>
                  <UnassignedLicensePage />
                </PrivateRoute>
              }
            />

            <Route
              path="utilisation/inactive-license"
              element={
                <PrivateRoute>
                  <InactiveLicensePage />
                </PrivateRoute>
              }
            />

            <Route
              path="utilisation/overassigned-license"
              element={
                <PrivateRoute>
                  <OverassignedLicensePage />
                </PrivateRoute>
              }
            />

            <Route
              path="utilisation/share-point-usage"
              element={
                <PrivateRoute>
                  <SharePointUsagePage />
                </PrivateRoute>
              }
            />

            <Route
              path="utilisation/device-inventory"
              element={
                <PrivateRoute>
                  <DeviceInventoryPage />
                </PrivateRoute>
              }
            />

            <Route
              path="utilisation/groups-and-teams"
              element={
                <PrivateRoute>
                  <GroupsTeamsPage />
                </PrivateRoute>
              }
            />

            <Route
              path="utilisation/configured-trunks"
              element={
                <PrivateRoute isTraditionalCommsPage={true}>
                  <ConfiguredTrunksPage />
                </PrivateRoute>
              }
            />

            <Route
              path="/insights-and-analytics/utilisation/license-inventory"
              element={<LicenseInventoryLayout />}
            >
              <Route
                index
                element={
                  <Navigate to="/insights-and-analytics/utilisation/license-inventory/subscriptions" />
                }
              />

              <Route
                path="subscriptions"
                element={
                  <PrivateRoute>
                    <SubscriptionsPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="license-assignment"
                element={
                  <PrivateRoute>
                    <LicenseAssignmentPage />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route
              path="adoption"
              element={
                <PrivateRoute>
                  <AdoptionsPage />
                </PrivateRoute>
              }
            />

            <Route
              path="adoption/service-usage"
              element={
                <PrivateRoute>
                  <ServiceUsagePage />
                </PrivateRoute>
              }
            />

            <Route
              path="adoption/service-adoption"
              element={
                <PrivateRoute>
                  <ServiceAdoptionPage />
                </PrivateRoute>
              }
            />

            <Route
              path="adoption/teams-and-groups-adoption"
              element={
                <PrivateRoute>
                  <TeamsGroupsAdoptionPage />
                </PrivateRoute>
              }
            />

            <Route
              path="adoption/teams-and-groups-activity"
              element={
                <PrivateRoute>
                  <TeamsGroupsActivityPage />
                </PrivateRoute>
              }
            />

            <Route
              path="adoption/ms-teams-health"
              element={
                <PrivateRoute>
                  <MsTeamsHealthPage />
                </PrivateRoute>
              }
            />

            <Route
              path="adoption/meeting-adoption"
              element={
                <PrivateRoute>
                  <MeetingAdoptionPage />
                </PrivateRoute>
              }
            />

            <Route
              path="adoption/meetings-room"
              element={
                <PrivateRoute>
                  <MeetingsRoomPage />
                </PrivateRoute>
              }
            />

            <Route
              path="adoption/user-service-adoption"
              element={
                <PrivateRoute>
                  <UserServiceAdoptionPage />
                </PrivateRoute>
              }
            />

            <Route
              path="adoption/organisation-groups-activity-user"
              element={
                <PrivateRoute>
                  <OrgGroupsActivityUserPage />
                </PrivateRoute>
              }
            />

            <Route
              path="adoption/device-adoption"
              element={
                <PrivateRoute>
                  <DeviceAdoptionPage />
                </PrivateRoute>
              }
            />

            <Route
              path="adoption/call-queues"
              element={
                <PrivateRoute isTraditionalCommsPage={true}>
                  <CallQueuesPage />
                </PrivateRoute>
              }
            />

            <Route
              path="productivity"
              element={
                <PrivateRoute>
                  <ProductivityPage />
                </PrivateRoute>
              }
            />

            <Route
              path="/insights-and-analytics/productivity/activity"
              element={<CallsMadeLayout />}
            >
              <Route
                index
                element={
                  <Navigate to="/insights-and-analytics/productivity/activity/people" />
                }
              />

              <Route
                path="people"
                element={
                  <PrivateRoute>
                    <CallsMadePeoplePage />
                  </PrivateRoute>
                }
              />

              <Route
                path="locations"
                element={
                  <PrivateRoute>
                    <CallsMadeLocationsPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="groups"
                element={
                  <PrivateRoute>
                    <CallsMadeGroupsPage />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route
              path="assurance"
              element={
                <PrivateRoute
                  allowedRoles={
                    permissionConfig[Permissions.ADMINS_SUPPORTUSER]
                  }
                >
                  <SecurityPage />
                </PrivateRoute>
              }
            />

            <Route
              path="/insights-and-analytics/assurance/failed-login-data"
              element={
                <PrivateRoute
                  allowedRoles={
                    permissionConfig[Permissions.ADMINS_SUPPORTUSER]
                  }
                  isHelixPage={true}
                >
                  <FailedDataLayout />
                </PrivateRoute>
              }
            >
              <Route
                index
                element={
                  <Navigate to="/insights-and-analytics/assurance/failed-login-data/countries" />
                }
              />

              <Route
                path="countries"
                element={
                  <PrivateRoute>
                    <FailedLoginCountriesPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="map"
                element={
                  <PrivateRoute>
                    <FailedLoginMapPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="login-types"
                element={
                  <PrivateRoute>
                    <FailedLoginTypesPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="accounts"
                element={
                  <PrivateRoute>
                    <FailedLoginAccountsPage />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route
              path="/insights-and-analytics/assurance/remote-access-data"
              element={
                <PrivateRoute
                  allowedRoles={
                    permissionConfig[Permissions.ADMINS_SUPPORTUSER]
                  }
                  isHelixPage={true}
                >
                  <RemoteAccessDataLayout />
                </PrivateRoute>
              }
            >
              <Route
                index
                element={
                  <Navigate to="/insights-and-analytics/assurance/remote-access-data/accounts" />
                }
              />

              <Route
                path="accounts"
                element={
                  <PrivateRoute>
                    <RemoteDataAccountsPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="apps"
                element={
                  <PrivateRoute>
                    <RemoteTenantwideAppsPage />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route
              path="/insights-and-analytics/assurance/suspicious-activity"
              element={
                <PrivateRoute
                  allowedRoles={
                    permissionConfig[Permissions.ADMINS_SUPPORTUSER]
                  }
                  isHelixPage={true}
                >
                  <SuspiciousActivityLayout />
                </PrivateRoute>
              }
            >
              <Route
                index
                element={
                  <Navigate to="/insights-and-analytics/assurance/suspicious-activity/activity-data" />
                }
              />

              <Route
                path="activity-data"
                element={
                  <PrivateRoute>
                    <SuspiciousActivityEventsPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="accounts"
                element={
                  <PrivateRoute>
                    <SuspiciousActivityAccountsPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="countries"
                element={
                  <PrivateRoute>
                    <SuspiciousActivityCountries />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route
              path="assurance/microsoft-secure-score"
              element={
                <PrivateRoute
                  allowedRoles={
                    permissionConfig[Permissions.ADMINS_SUPPORTUSER]
                  }
                >
                  <MsSecureScorePage />
                </PrivateRoute>
              }
            />

            <Route
              path="assurance/microsoft-secure-score/action/:actionId"
              element={
                <PrivateRoute
                  allowedRoles={
                    permissionConfig[Permissions.ADMINS_SUPPORTUSER]
                  }
                >
                  <MsSecureScoreActionPage />
                </PrivateRoute>
              }
            />

            <Route
              path="assurance/mfa-status"
              element={
                <PrivateRoute
                  allowedRoles={
                    permissionConfig[Permissions.ADMINS_SUPPORTUSER]
                  }
                >
                  <MfaStatusPage />
                </PrivateRoute>
              }
            />

            <Route
              path="assurance/external-teams-access"
              element={
                <PrivateRoute
                  allowedRoles={
                    permissionConfig[Permissions.ADMINS_SUPPORTUSER]
                  }
                >
                  <ExternalTeamsAccessPage />
                </PrivateRoute>
              }
            />

            <Route
              path="assurance/external-teams-access/details"
              element={
                <PrivateRoute
                  allowedRoles={
                    permissionConfig[Permissions.ADMINS_SUPPORTUSER]
                  }
                >
                  <ExternalTeamsAccessDetailsPage />
                </PrivateRoute>
              }
            />

            <Route
              path="/insights-and-analytics/assurance/outdated-browsers"
              element={
                <PrivateRoute
                  allowedRoles={
                    permissionConfig[Permissions.ADMINS_SUPPORTUSER]
                  }
                  isHelixPage={true}
                >
                  <OutdatedBrowserDataLayout />
                </PrivateRoute>
              }
            >
              <Route
                index
                element={
                  <Navigate to="/insights-and-analytics/assurance/outdated-browsers/accounts" />
                }
              />
              <Route
                path="accounts"
                element={
                  <PrivateRoute>
                    <OutdatedBrowserAccountsPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="browsers"
                element={
                  <PrivateRoute>
                    <OutdatedBrowsersPage />
                  </PrivateRoute>
                }
              />

            </Route>

            <Route
              path="assurance/sign-in-enabled"
              element={
                <PrivateRoute
                  allowedRoles={
                    permissionConfig[Permissions.ADMINS_SUPPORTUSER]
                  }
                >
                  <SignInEnabledPage />
                </PrivateRoute>
              }
            />

            <Route
              path="assurance/mailbox-forwarding"
              element={
                <PrivateRoute
                  allowedRoles={
                    permissionConfig[Permissions.ADMINS_SUPPORTUSER]
                  }
                  isHelixPage={true}
                >
                  <MailboxForwarding />
                </PrivateRoute>
              }
            />

            <Route
              path="assurance/shared-mailboxes"
              element={
                <PrivateRoute
                  allowedRoles={
                    permissionConfig[Permissions.ADMINS_SUPPORTUSER]
                  }
                >
                  <SharedMailboxesPage />
                </PrivateRoute>
              }
            />

            <Route
              path="/insights-and-analytics/assurance/outdated-operating-systems"
              element={
                <PrivateRoute
                  allowedRoles={
                    permissionConfig[Permissions.ADMINS_SUPPORTUSER]
                  }
                  isHelixPage={true}
                >
                  <OutdatedOSDataLayout />
                </PrivateRoute>
              }
            >
              <Route
                index
                element={
                  <Navigate to="/insights-and-analytics/assurance/outdated-operating-systems/accounts" />
                }
              />

              <Route
                path="accounts"
                element={
                  <PrivateRoute>
                    <OutdatedOSAccountsPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="systems"
                element={
                  <PrivateRoute>
                    <OutdatedOSPage />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="/insights-and-analytics/assurance/delegated-mailbox-data"
              element={
                <PrivateRoute
                  allowedRoles={
                    permissionConfig[Permissions.ADMINS_SUPPORTUSER]
                  }
                >
                  <DelegatedMailboxLayout />
                </PrivateRoute>
              }
            >
              <Route
                index
                element={
                  <Navigate to="/insights-and-analytics/assurance/delegated-mailbox-data/delegates" />
                }
              />

              <Route
                path="delegates"
                element={
                  <PrivateRoute>
                    <MailboxDelegatesPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="mailboxes"
                element={
                  <PrivateRoute>
                    <DelegatedMailboxesPage />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route
              path="assurance/call-forwarding-destinations"
              element={
                <PrivateRoute isTraditionalCommsPage={true}>
                  <CallForwardingDestinationsPage />
                </PrivateRoute>
              }
            />
          </Route>

          <Route
            path="/reports"
            element={
              <PrivateRoute>
                <ReportsPage />
              </PrivateRoute>
            }
          />

          <Route
            path="reports/:reportId/preview"
            element={
              <PrivateRoute>
                <ReportPreviewPage />
              </PrivateRoute>
            }
          />

          {/* <Route
            path="/help"
            element={
              <PrivateRoute>
                <HelpPage />
              </PrivateRoute>
            }
          /> */}

          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <HubSettingsPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/settings/manage-users"
            element={
              <PrivateRoute>
                <HubSettingsUsersPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/settings/manage-connected-services"
            element={
              <PrivateRoute>
                <ConnectedServicesPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/settings/enhanced-security"
            element={
              <PrivateRoute>
                <EnhancedSecurityPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/settings/enhanced-security/technical-information"
            element={
              <PrivateRoute>
                <TechincalInformationFormPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/settings/manage-operating-services"
            element={
              <PrivateRoute>
                <OperatingLocationsPage />
              </PrivateRoute>
            }
          />

          <Route path="/my-profile" element={<MyProfileLayout />}>
            <Route index element={<Navigate to="/my-profile/details" />} />
            <Route
              path="/my-profile/details"
              element={
                <PrivateRoute>
                  <YourDetailsPage />
                </PrivateRoute>
              }
            />

            <Route
              path="/my-profile/email-notifications"
              element={
                <PrivateRoute>
                  <EmailNotificationsPage />
                </PrivateRoute>
              }
            />
          </Route>

          <Route
            path="/notifications-list"
            element={
              <PrivateRoute>
                <NotificationsListPage />
              </PrivateRoute>
            }
          />

          <Route path="/accounts" element={<PeopleAndGroupsLayout />}>
            <Route index element={<Navigate to="/accounts/accounts" />} />

            <Route
              path="accounts"
              element={
                <PrivateRoute>
                  <PeoplePage />
                </PrivateRoute>
              }
            />

            <Route
              path="accounts/account/:personId"
              element={
                <PrivateRoute
                  allowedRoles={
                    permissionConfig[Permissions.ADMINS_SUPPORTUSER]
                  }
                >
                  <PersonPreviewPage />
                </PrivateRoute>
              }
            />

            <Route
              path="groups"
              element={
                <PrivateRoute>
                  <GroupPage />
                </PrivateRoute>
              }
            />

            <Route
              path="groups/unassigned-people"
              element={
                <PrivateRoute>
                  <UnassignedPeopleGroupsPage />
                </PrivateRoute>
              }
            />

            <Route
              path="groups/assign-people"
              element={
                <PrivateRoute>
                  <UnassignedPeopleGroupsPage />
                </PrivateRoute>
              }
            />

            <Route
              path="groups/:groupId/members"
              element={
                <PrivateRoute>
                  <GroupMembersPage />
                </PrivateRoute>
              }
            />

            <Route
              path="groups/:groupId/manage-people"
              element={
                <PrivateRoute>
                  <AddRemoveGroupMembers />
                </PrivateRoute>
              }
            />

            <Route
              path="locations"
              element={
                <PrivateRoute>
                  <LocationPage />
                </PrivateRoute>
              }
            />

            <Route
              path="locations/unassigned-people"
              element={
                <PrivateRoute>
                  <UnassignedPeopleLocationsPage />
                </PrivateRoute>
              }
            />

            <Route
              path="locations/assign-people"
              element={
                <PrivateRoute>
                  <UnassignedPeopleLocationsPage />
                </PrivateRoute>
              }
            />

            <Route
              path="locations/:locationId/members"
              element={
                <PrivateRoute>
                  <LocationMembersPage />
                </PrivateRoute>
              }
            />

            <Route
              path="locations/:locationId/manage-people"
              element={
                <PrivateRoute>
                  <AddRemoveLocationMembers />
                </PrivateRoute>
              }
            />
          </Route>

          <Route
            path="/terms-and-conditions"
            element={
              <PrivateRoute>
                <TermsAndConditionsPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/privacy"
            element={
              <PrivateRoute>
                <PrivacyPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/ip"
            element={
              <PrivateRoute>
                <IconPreviewPage />
              </PrivateRoute>
            }
          />

          <Route path="/my-profile" element={<MyProfileLayout />}>
            <Route index element={<Navigate to="/my-profile/details" />} />
            <Route
              path="/my-profile/details"
              element={
                <PrivateRoute>
                  <YourDetailsPage />
                </PrivateRoute>
              }
            />

            <Route
              path="/my-profile/email-notifications"
              element={
                <PrivateRoute>
                  <EmailNotificationsPage />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
          <Route path="/page-not-found" element={<Error404Page />} />
        </Route>
        <Route path="*" element={<Navigate to="/page-not-found" replace />} />
        <Route path="/dashboard" element={<Navigate to="/" replace />} />
      </Routes>
    </QueryParamProvider>
  );
};
