import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useLicencesInventorySnapshotQuery } from '@hooks/utilisation';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useState } from 'react';

type Params = {
  vendorList: string[];
};

export const LicenseInventory: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.LicenseInventory,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: licensesInventoryData,
    isLoading: isLicensesInventoryLading,
    refetch,
  } = useLicencesInventorySnapshotQuery(parameters);

  const handleDropdown = (value: string[]) => {
    setParameters({
      vendorList: value,
    });

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify({
          vendorList: value,
        }),
        tileId: id,
      });

      refetch();
    }
  };

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All Vendors',
      value: 'All',
      action: () => handleDropdown(['Microsoft', 'RingCentral']),
    },
    {
      id: '2',
      label: 'Microsoft',
      value: 'Microsoft',
      action: () => handleDropdown(['Microsoft']),
    },
    {
      id: '3',
      label: 'RingCentral',
      value: 'RingCentral',
      action: () => handleDropdown(['RingCentral']),
    },
  ];

  return (
    parameters && (
      <DoughnutChartTile
        classNames={
          classNames ? classNames : 'col-span-6 md:col-span-6 xl:col-span-4'
        }
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        dropdownMenuItemClasses={'!w-[300px]'}
        sortData={sortMenuItems}
        headerTitle={'License inventory'}
        isLoading={isLicensesInventoryLading}
        initialSelectedItem={sortMenuItems.find(
          (item) =>
            item.value ===
            (parameters.vendorList.length > 1
              ? 'All'
              : parameters.vendorList[0])
        )}
        data={
          licensesInventoryData
            ? Object.values(licensesInventoryData.inventorySnapshot)
            : []
        }
        dataLabels={
          licensesInventoryData
            ? Object.keys(licensesInventoryData.inventorySnapshot)
            : []
        }
        buttonLabel={'License inventory data'}
        linkPath={ANALYTICS_NAVIGATION_MAP['licenseInventory']}
        handleButtonClick={handleButtonClick()}
        isLgSize={true}
        showBracketLabelValue={true}
      />
    )
  );
};
