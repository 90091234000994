import React from 'react';
import Map from '@components/ui/Map';
import { useSuspiciousActivityEvents } from '@hooks/security';
import useTransformData from '@hooks/utils/transformData';
import { useQueryParams, StringParam, withDefault } from 'use-query-params';

const SuspiciousActivityCountries: React.FC = () => {
  const [queryParams] = useQueryParams({
    days: withDefault(StringParam, '30'),
  });

  const days = queryParams.days;

  const { data: suspiciousActivityEventsData } = useSuspiciousActivityEvents({
    days,
  });

  const { data, legendRanges } = useTransformData(
    suspiciousActivityEventsData ?? []
  );

  return (
    <div className="relative mt-2">
      <Map
        actionPath={
          '/insights-and-analytics/assurance/suspicious-activity/activity-data?days='+days
        }
        rawData={data}
        label={'events'}
        legendRanges={legendRanges}
      />
    </div>
  );
};

export default SuspiciousActivityCountries;
