import {
  DEFAULT_PAGE_SIZE,
  CALL_FORWARDING_DESTINATIONS_SORT_BY,
  CALL_FORWARDING_DESTINATIONS_TABLE_COLUMNS,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { OverlayPage, TableWrapper } from '@components/partials';
import { PeopleSortBy } from '@hooks/people/types';
import { useIsDesktop } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';
import { getVendorName, useTableData } from '@utils/index';
import React, { useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import styles from './styles.module.css';
import { useCallForwardingDestinationsPreviewQuery, } from '@hooks/security';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { CallForwardingDestinations } from '@pages/Dashboard/tiles';
import { Banner } from '@components/ui';
import useServicesStore from '@store/servicesStore';
import { createCellValue } from './utils';

const pageSize = DEFAULT_PAGE_SIZE;

const CallForwardingDestinationsPage: React.FC = () => {
  const isDesktop = useIsDesktop();
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const redirectPath = useNavSourceRedirect(
    '/insights-and-analytics/assurance'
  );
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, 'account'),
    order: withDefault(StringParam, 'asc'),
    filter: withDefault(StringParam, ''),
  });

  const { serviceIntegrations } = useServicesStore();
  const serviceIntegration = serviceIntegrations?.find((integration) => 
    integration.vendor === 3 || integration.vendor === 4);

  // table
  const query = useCallForwardingDestinationsPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
  });
  const getTableData = useTableData(
    query,
    CALL_FORWARDING_DESTINATIONS_TABLE_COLUMNS,
    createCellValue
  );

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Call forwarding data`}
    >
      <>
        <div className={styles.pageGrid}>
          {isDesktop && (
            <>
              <CallForwardingDestinations
                classNames="col-span-1"
                isSnapshot={true}
                params={''}
              />
            </>
          )}
        </div>
        <>
          {query.isFetched && !query.data?.items.length && !search &&
            <Banner
              closeable={true}
              message={`No call queue data available within the selected data set. If changes have been made within your
                            ${serviceIntegration?.vendor ? getVendorName(serviceIntegration?.vendor) : ''} system${query.data?.lastUpdatedAt ?
                  ` after ${query.data?.lastUpdatedAt}` : ''}, please request updated data via the CloudClevr support team.`}
              className="mb-4 w-full md:w-[534px] lg:w-[704px]"
            />
          }
          <div className="mt-2">
            <TableWrapper
              search={search}
              setSearch={setSearch}
              searchPlaceholder={'Search by account'}
              columns={CALL_FORWARDING_DESTINATIONS_TABLE_COLUMNS}
              data={getTableData()}
              sortData={CALL_FORWARDING_DESTINATIONS_SORT_BY}
              searchKey="search"
              searchCountStatsLabel="extensions with forwarding rules"
              query={query}
              refetchQuery={query.refetch}
              queryParams={queryParams}
              setQueryParams={setQueryParams}
              setPageNumber={setPageNumber}
              isCollapsable={false}
              sliceColumns={1}
              isLicensePage={true}
              isDownloadAvailable={false}
            />
          </div></>
      </>
    </OverlayPage>
  );
};

export default CallForwardingDestinationsPage;