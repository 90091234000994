import { Card } from '@components/ui';
import { ArrowRightIcon } from '@components/ui/Icons';

import { Link } from 'react-router-dom';

export type TileFooterProps = {
  buttonLabel?: string;
  linkPath?: string;
  handleButtonClick?: () => void;
};

export const TileFooter: React.FC<TileFooterProps> = ({
  buttonLabel = '',
  handleButtonClick,
  linkPath = '/',
}) => {
  return (
    <Card.Footer>
      {buttonLabel && (
        <div className="mt-5 w-full">
          <Link
            to={linkPath}
            onClick={handleButtonClick}
            className="inline-flex h-10 w-full flex-shrink-0 items-center justify-center rounded-[24px] bg-interfaceColor-90 px-4 py-2 text-16 font-normal leading-6 tracking-[0.01em] text-white transition duration-300 ease-in-out hover:bg-interfaceColor-150 hover:text-interfaceColor-10 focus:shadow-focus md:w-auto lg:w-auto"
          >
            {buttonLabel}
            <ArrowRightIcon
              color={'text-primaryBrandColor-100'}
              size="3"
              classNames="ml-2 mt-[2px]"
            />
          </Link>
        </div>
      )}
    </Card.Footer>
  );
};
