import {
    CellBottomPart,
} from '@components/ui/Table/utils';

import {
    CircleRightArrow,
} from '@components/ui/Icons';
import useModalStore from '@store/modal';

export const createCellValue = (
    property: string,
    data: any,
    index: number,
    isMobile: boolean,
) => {
    const value = data[index][property];
    const modalStore = useModalStore.getState();
    const mailboxData = data[index]['delegations'] || [];
    const accountName = data[index]['mailboxEmail'] || "";

    const handleOnClick = () => {
        modalStore.setIsDetailsModalOpen(true);
        modalStore.setDataDetailsModal({
            header: (
                <div className="flex flex-col gap-y-2 break-words w-full">
                    <span className="md:text-36 text-24 text-interfaceColor-100">
                        People with access to
                    </span>
                    <span className="md:text-36 text-24 text-interfaceColor-100">
                        {accountName}
                    </span>
                </div>
            ),
            body: (
                <div className={'overflow-auto min-h-[280px] max-h-[300px] border border-interfaceColor-20 px-4 py-1'}>
                    <ul className="flex flex-col items-start text-16 leading-7">
                        {mailboxData.map((data: {
                            accountEmail: string,
                            displayName: string
                        }) => (
                            <li className="mt-3 w-full" key={data.accountEmail}>
                                <span className="flex flex-col justify-start">
                                    {data.displayName} {!isMobile && (
                                        <CellBottomPart data={data.accountEmail} />
                                    )}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            ),
        });
    };


    switch (property) {
        case 'mailboxEmail':
            return (
                <>
                    <span className="flex items-center">
                        {value}
                    </span>
                </>
            );
        case 'delegations': {
            return (
                <div className="flex flex-row items-center gap-[5px]">
                    <div>
                        {mailboxData.length > 1 ? `${mailboxData.length}` :
                            <span className="flex flex-col">
                                {mailboxData[0].displayName}
                                <CellBottomPart data={mailboxData[0]['accountEmail']} />
                            </span>
                        }
                    </div>
                    {mailboxData.length > 1 && <div className="flex items-center gap-2">
                        <span onClick={handleOnClick}>
                            <CircleRightArrow size="4" classNames="mt-[2px] cursor-pointer" />
                        </span>
                    </div>}
                </div>
            );
        }
    }
};
