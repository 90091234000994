import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useState } from 'react';

import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { useActivityPerDayGroupsTrendQuery } from '@hooks/productivity';
import {
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { getTrendData, getTrendLabels } from '@utils/index';

type Params = {
  activity: string;
  days: string;
};

export const ActivityPerDayGroups: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.ActivityPerDayGroups,
    parameters
  );
  const isOnDashboardPage = !!id;
  const {
    data: activityPerDayPeopleTrendData,
    isLoading: isActivityPerDayPeopleLoading,
    refetch,
  } = useActivityPerDayGroupsTrendQuery(parameters);

  const handleDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      activity: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const activityPerDayMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All activities',
      value: 'allactivities',
      action: () => handleDropdown('allactivities'),
    },
    {
      id: '2',
      label: 'Emails',
      value: 'emails',
      action: () => handleDropdown('emails'),
    },
    {
      id: '3',
      label: 'Messages',
      value: 'messages',
      action: () => handleDropdown('messages'),
    },
    {
      id: '4',
      label: 'Calls',
      value: 'calls',
      action: () => handleDropdown('calls'),
    },

    {
      id: '5',
      label: 'Meetings',
      value: 'meetings',
      action: () => handleDropdown('meetings'),
    },
  ];

  const labels = getTrendLabels(
    activityPerDayPeopleTrendData?.trend || [],
    +parameters.days
  );

  const trendData = getTrendData(
    activityPerDayPeopleTrendData?.trend || [],
    +parameters.days
  );

  const itemActivitySelected = activityPerDayMenuItems.find(
    (item) => item.value === parameters.activity
  );

  return (
    parameters && (
      <LineChartTile
        sortData={activityPerDayMenuItems}
        initialSelectedItem={itemActivitySelected}
        legendLabels={[]}
        compareData={undefined}
        dropdownMenuItemClasses={'!w-[300px]'}
        classNames={'col-span-6 md:col-span-6 xl:col-span-4'}
        tooltipLabel={[itemActivitySelected?.label || '']}
        headerTitle={'Activity per day - Groups'}
        dataSetTypes={[LineChartTypes.Dots]}
        compareLabel=""
        buttonLabel=""
        dataSetColors={['#00CF6C']}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        isLoading={isActivityPerDayPeopleLoading}
        labels={labels}
        data={[trendData]}
        showDateRange={true}
      />
    )
  );
};
