import { WarningCircleIcon } from '@components/ui';
import { CellBottomPart } from '@components/ui/Table/utils';
import { formatDate } from '@utils/index';

export const createCellValue = (property: string, data: any, index: number) => {
  const value = data[index][property];

  switch (property) {
    case 'memberCount':
      return (
        <>
          {value ? (
            <span>{value}</span>
          ) : (
            <div className="flex flex-row items-center">
              <WarningCircleIcon
                color="text-errorColor-150"
                classNames="h-4 w-4 mr-1"
              ></WarningCircleIcon>
              <span className="text-14 text-interfaceColor-80">No members</span>
            </div>
          )}
        </>
      );
    case 'owners':
      return (
        <>
          {data[index]['owners'].length ? (
            <>
              <span>{data[index]['owners'][0]?.['displayName']}</span>
              <CellBottomPart data={data[index]['owners'][0]?.['email']} />
              {data[index]['owners'].length > 1 && (
                <span>+ {data[index]['owners'].length - 1} more</span>
              )}
            </>
          ) : (
            <div className="flex flex-row items-center">
              <WarningCircleIcon
                color="text-errorColor-150"
                classNames="h-4 w-4 mr-1"
              ></WarningCircleIcon>
              <span className="text-14 text-interfaceColor-80">No owner</span>
            </div>
          )}
        </>
      );
    case 'messageCount':
      return (
        <>
          {value ? (
            <>
              <span>{value}</span>
            </>
          ) : (
            <div className="flex flex-row items-center">
              <WarningCircleIcon
                color="text-errorColor-150"
                classNames="h-4 w-4 mr-1"
              ></WarningCircleIcon>
              <span className="text-14 text-interfaceColor-80">
                No messages
              </span>
            </div>
          )}
        </>
      );
    case 'meetingCount':
      return (
        <>
          {value ? (
            <>
              <span>{value}</span>
            </>
          ) : (
            <div className="flex flex-row items-center">
              <WarningCircleIcon
                color="text-errorColor-150"
                classNames="h-4 w-4 mr-1"
              ></WarningCircleIcon>
              <span className="text-14 text-interfaceColor-80">
                No meetings
              </span>
            </div>
          )}
        </>
      );

    case 'lastUsed':
      return <span>{formatDate(value)}</span>;
  }
};
