import { UseMutationOptions } from 'react-query';

export type User = {
  id: string;
  fullName: string;
  emailAddress: string;
  role: string;
  phone: string;
  isEnabled: boolean | string;
  firstName?: string;
  lastName?: string;
  isViewerInherited?: boolean;
  organizationName?: string;
};

export type OperatingCompany = {
  name: string;
  email: string;
  phone: string;
  logoUrl: string;
};

export type UsersResponse = {
  totalItemCount: number;
  itemCount: number;
  totalPageCount: number;
  pageSize: number;
  currentPage: number;
  items: User[];
};

export type CurrentUserResponse = {
  emailAddress: string;
  fullName: string;
  isEnabled: boolean;
  phone: string;
  id: string;
  role: string;
  operatingCompany: OperatingCompany;
  organizationName: string;
};

export type UseUsersQueryOptions = {
  pageSize?: number;
  pageNumber?: number;
};

export enum UserRoles {
  STANDARD = 'Standard',
  ADMIN = 'Admin',
  SUPERADMIN = 'Superadmin',
  SUPPORT = 'SupportUser',
}

export enum Permissions {
  ALL = 'All',
  STANDARD = 'Standard',
  ADMIN = 'Admin',
  SUPERADMIN = 'Superadmin',
  SUPERADMIN_ADMIN = 'Superadmin_Admin',
  SUPERADMIN_SUPPORTUSER = 'Superadmin_SupportUser',
  ADMINS_SUPPORTUSER ='Admins_SupportUser',
}

type PermissionMapping = {
  [Permissions.ALL]: UserRoles[];
  [Permissions.STANDARD]: UserRoles[];
  [Permissions.ADMIN]: UserRoles[];
  [Permissions.SUPERADMIN]: UserRoles[];
  [Permissions.SUPERADMIN_ADMIN]: UserRoles[];
  [Permissions.SUPERADMIN_SUPPORTUSER]: UserRoles[];
  [Permissions.ADMINS_SUPPORTUSER]: UserRoles[];
};

export const permissionConfig: PermissionMapping = {
  [Permissions.ALL]: [
    UserRoles.SUPERADMIN,
    UserRoles.ADMIN,
    UserRoles.STANDARD,
  ],
  [Permissions.STANDARD]: [UserRoles.STANDARD],
  [Permissions.ADMIN]: [UserRoles.ADMIN],
  [Permissions.SUPERADMIN]: [UserRoles.SUPERADMIN],
  [Permissions.SUPERADMIN_ADMIN]: [UserRoles.SUPERADMIN, UserRoles.ADMIN],
  [Permissions.SUPERADMIN_SUPPORTUSER]: [UserRoles.SUPERADMIN, UserRoles.SUPPORT],
  [Permissions.ADMINS_SUPPORTUSER]: [UserRoles.SUPERADMIN, UserRoles.ADMIN, UserRoles.SUPPORT],
};

export type UpdateRoleRequest = {
  roleName: UserRoles;
};

export type UseUpdateRoleOptions = UseMutationOptions<
  unknown,
  unknown,
  UpdateRoleRequest
>;

export type UserRequest = Partial<User>;

export type UserForm = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phone: string;
};

export type UserColumn = User & {
  tableDropdown: string;
};

export type ModalsStatus = {
  None: boolean;
  HubSettingsModal: boolean;
  HelpSupportModal: boolean;
  DashboardModal: boolean;
  ReportsModal: boolean;
  InsightsAnalyticsModal: boolean;
  PeopleGroupsLocationsModal: boolean;
};

export type OnboardingModalStatus = {
  modalsStatus: ModalsStatus;
};

export enum Frequency {
  EverytimeOnEvent = 0,
  Daily = 1,
  Weekly = 2,
  Never = 3,
}

export type SubscriptionSettingsResponse = SubscriptionSettings & {
  id: string;
};

export type SubscriptionSettings = {
  frequency: Frequency;
  licenseContractsExpiring: boolean;
  reportsNotification: boolean;
};

export type UserProfileResponse = {
  data: number | null;
};
