import { TilesProps } from '@common/types';
import { useExternalAppsPublisherVerification } from '@hooks/security';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';

export const PublisherVerification: React.FC<TilesProps> = ({ classNames }) => {
  const { data, isLoading } = useExternalAppsPublisherVerification();

  return (
    <DoughnutChartTile
      classNames={classNames ? classNames : 'col-span-3 lg:col-auto'}
      contextData={[]}
      sortData={[]}
      headerTitle={'Publisher verification'}
      isLoading={isLoading}
      data={data ? [data.unverified, data.verified] : []}
      dataLabelValues={
        data ? [String(data?.unverified), String(data?.verified)] : []
      }
      dataLabels={[
        `Unverified: ${data?.unverified}`,
        `Verified: ${data?.verified}`,
      ]}
      showBracketLabelValue={false}
    />
  );
};
