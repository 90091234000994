
import { Location } from '@hooks/locations/types';
import { GroupsResponse } from '@hooks/groups/types';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { ExtendedOption, IOption } from '@common/types';
import { OutdatedOSFiltersResponse } from '@hooks/security/types';

export const addLocationsToFilter = (locationsData: Location[], filterOptions: IOption[],
): { locations: FilterData, ids: string[] } => {
    const locationIds: string[] = [];
    locationsData?.forEach((location: Location) => {
        if (filterOptions.filter(option => option.value !== location.id)) {
            filterOptions.push({ label: location.name, value: location.id });
            locationIds.push(location.id);
        }
    });

    return {
        locations: {
            label: 'Location',
            name: 'location',
            singleSelect: false,
            options: filterOptions,
        }, ids: locationIds
    }
}

export const addGroupsToFilter = (groupsData: GroupsResponse[], filterOptions: ExtendedOption[]):
    FilterData => {
    groupsData?.forEach((group: GroupsResponse) => {
        filterOptions.push({
            name: group.name,
            value: group.id,
            parentId: group.parentId,
            subGroups: group.subGroups,
            id: group.id,
            label: group.name,
        });
    });

    return {
        label: 'Groups',
        name: 'groups',
        singleSelect: false,
        options: filterOptions,
    };
}

export const addSelectedDataToFilter = (data: OutdatedOSFiltersResponse): FilterData[] => {
    const filterData: FilterData[] = [];
    Object.keys(data).forEach((key) => {
        let filterOptions: IOption[] = [];
        if (Array.isArray(data[key])) {
            filterOptions = (data[key] as string[])?.map((item) => ({
                label: item,
                value: item,
            }));
        }
        if (filterOptions.length)
            filterData.push({
                label: mapFilterDisplayNames(key),
                name: key,
                singleSelect: false,
                options: filterOptions,
            });
    });
    return filterData;
}

const mapFilterDisplayNames = (label: string) => {
    if (label === "operatingSystemNames")
        return "Operating system";
    return label?.replace(/(?<!^)([A-Z])/g, ' $1');
}

export const getGroupOrLocationFilterAsQueryParam = (filter: string | undefined, ids: string[]
) => {
    return filter ? filter.split(',').filter((item) => ids.includes(item)) : []
}

