import React from 'react';
import { IconProps } from './types';

export const DeleteIcon: React.FC<IconProps> = ({
  size = 4,
  color = 'text-interfaceColor-100',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
    >
      <g clipPath="url(#clip0_1_657)">
        <path
          d="M1.14286 4H14.8571"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.85714 4H13.1429V14.2857C13.1429 14.5888 13.0224 14.8795 12.8081 15.0938C12.5938 15.3081 12.3031 15.4286 12 15.4286H4C3.69689 15.4286 3.4062 15.3081 3.19187 15.0938C2.97755 14.8795 2.85714 14.5888 2.85714 14.2857V4Z"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.14286 4V3.42857C5.14286 2.67081 5.44388 1.94408 5.97969 1.40826C6.51551 0.872446 7.24224 0.571426 8 0.571426C8.75776 0.571426 9.48449 0.872446 10.0203 1.40826C10.5561 1.94408 10.8571 2.67081 10.8571 3.42857V4"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.28571 7.43024V12.0034"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.71429 7.43024V12.0034"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
