import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { useAddDashboardTile, useRemoveDashboardTile } from '@hooks/dashboard';
import { useLicencesExpiredSnapshotBarChartQuery } from '@hooks/licenses';
import { HorizontalBarChartTile } from '@pages/InsightsAndAnalytics/tiles/HorizontalChartTile';
import { useEffect, useState } from 'react';

export const ExpiredAndDisabledLicensesServiceType: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [isLabelChartLoading, setisLabelChartLoading] = useState(true);
  const [licensesExpiringBarLabels, setLicensesExpiringBarLabels] = useState<
    string[]
  >([]);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.ExpiredAndDisabledLicensesServiceType,
    ''
  );
  const isOnDashboardPage = !!id;

  const {
    data: licencesExpiredSnapshotBarChartData,
    isLoading: isLicencesExpiredSnapshotBarChartLoading,
  } = useLicencesExpiredSnapshotBarChartQuery(JSON.parse(params));

  useEffect(() => {
    setisLabelChartLoading(true);
    if (licencesExpiredSnapshotBarChartData) {
      setLicensesExpiringBarLabels(
        Object.keys(licencesExpiredSnapshotBarChartData.barChartSnapshot)
      );

      setisLabelChartLoading(false);
    }
  }, [licencesExpiredSnapshotBarChartData]);

  return (
    <HorizontalBarChartTile
      classNames={'col-span-6 xl:col-span-4'}
      dropdownMenuItemClasses={'!w-[300px]'}
      headerTitle={'Expired & disabled licenses: Service type'}
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      isLoading={
        isLicencesExpiredSnapshotBarChartLoading || isLabelChartLoading
      }
      height={160}
      barPercentage={
        Object.keys(licencesExpiredSnapshotBarChartData ?? {}).length < 4
          ? 0.18
          : 0.25
      }
      data={[
        ...Object.values(
          licencesExpiredSnapshotBarChartData?.barChartSnapshot ?? {}
        ),
      ]}
      dataLabels={[...licensesExpiringBarLabels]}
      barBackgroundColors={[
        '#00CF6C',
        '#25A8D1',
        '#B65AD7',
        '#FFB800',
        '#FF9ED3',
      ]}
    />
  );
};
