import {
  DEFAULT_PAGE_SIZE,
  SHARED_MAILBOXES_SORT_BY,
  SHARED_MAILBOXES_TABLE_COLUMNS,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { OverlayPage, TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { PeopleSortBy } from '@hooks/people/types';
import { useIsDesktop } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';

import { formatDate, useTableData } from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import styles from './styles.module.css';

import {
  useSharedMailboxesPreviewQuery,
  useSharedMailboxesSnapshotQuery,
} from '@hooks/security';

import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { createCellValue } from './utils';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { RecommendationCard } from '@pages/Dashboard/tiles/helpers/RecommendationCard';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';

const pageSize = DEFAULT_PAGE_SIZE;

const SharedMailboxesPage: React.FC = () => {
  const isDesktop = useIsDesktop();
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const redirectPath = useNavSourceRedirect('/insights-and-analytics/assurance');
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, 'emailsreceived'),
    order: withDefault(StringParam, 'desc'),
    filter: withDefault(StringParam, ''),
  });

  const transformedFilterData: FilterData[] = [];

  const FILTERS = ['enabled', 'disabled'];

  transformedFilterData.push({
    label: 'Sign-in status',
    name: 'status',
    singleSelect: true,
    options: [
      { label: 'Enabled', value: 'enabled' },
      { label: 'Disabled', value: 'disabled' },
    ],
  });

  useEffect(() => {
    setQueryParams({
      filter: 'enabled',
    });
  }, [setQueryParams]);

  // table
  const query = useSharedMailboxesPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    sharedMailboxStatus: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => FILTERS.includes(filter))
          .toString()
      : '',
  });
  const getTableData = useTableData(
    query,
    SHARED_MAILBOXES_TABLE_COLUMNS,
    createCellValue
  );

  // snapshot
  const { data: sharedMailboxesData, isLoading: isSharedMailboxesLoading } =
    useSharedMailboxesSnapshotQuery({
      sharedMailboxStatus: 'enabled',
    });

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.SHARED_MAILBOXES_PREVIEW}/export`,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      sharedMailboxStatus: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => FILTERS.includes(filter))
            .toString()
        : '',
    },
    filename: `Clevr360_Shared_mailboxes_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Shared mailboxes`}
    >
      <>
        <div className={styles.pageGrid}>
          {isDesktop && (
            <>
              <NumberTile
                classNames={'col-span-3 lg:col-span-1'}
                contextData={[]}
                percentageLabel=""
                headerTitle={`Shared mailboxes with sign-in enabled`}
                isLoading={isSharedMailboxesLoading}
                data={
                  sharedMailboxesData ? Object.values(sharedMailboxesData) : []
                }
              />

              <RecommendationCard insightDescription={'shared mailboxes'} />
            </>
          )}
        </div>
        <div className="mt-2">
          <TableWrapper
            search={search}
            setSearch={setSearch}
            searchPlaceholder={'Search by mailbox name'}
            columns={SHARED_MAILBOXES_TABLE_COLUMNS}
            data={getTableData()}
            sortData={SHARED_MAILBOXES_SORT_BY}
            filterData={transformedFilterData}
            searchKey="search"
            query={query}
            refetchQuery={query.refetch}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setPageNumber={setPageNumber}
            isCollapsable={true}
            sliceColumns={1}
            floatingFilterButton={true}
            hasFilterBanner={false}
            isLicensePage={true}
            searchCountStatsLabel="mailboxes"
            filterHeaderText="Filter shared mailboxes"
            isDownloadAvailable={true}
            handleDownload={handleDownloadClick}
          />
        </div>
      </>
    </OverlayPage>
  );
};

export default SharedMailboxesPage;
