import React from 'react';
import { IconProps } from './types';

export const DashboardIcon: React.FC<IconProps> = ({
  size = '4',
  color = 'text-interfaceColor-90',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clipPath="url(#clip0_1879_5174)">
        <g id="Group">
          <path
            id="Vector"
            d="M14.8577 7.42871H10.2863C9.97068 7.42871 9.71484 7.68455 9.71484 8.00014V14.8573C9.71484 15.1729 9.97068 15.4287 10.2863 15.4287H14.8577C15.1733 15.4287 15.4291 15.1729 15.4291 14.8573V8.00014C15.4291 7.68455 15.1733 7.42871 14.8577 7.42871Z"
            stroke="currentColor"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M14.8577 0.571289H10.2863C9.97068 0.571289 9.71484 0.827126 9.71484 1.14272V3.43986C9.71484 3.75545 9.97068 4.01129 10.2863 4.01129H14.8577C15.1733 4.01129 15.4291 3.75545 15.4291 3.43986V1.14272C15.4291 0.827126 15.1733 0.571289 14.8577 0.571289Z"
            stroke="currentColor"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M5.71512 0.571289H1.14369C0.828103 0.571289 0.572266 0.827126 0.572266 1.14272V7.99986C0.572266 8.31545 0.828103 8.57129 1.14369 8.57129H5.71512C6.03071 8.57129 6.28655 8.31545 6.28655 7.99986V1.14272C6.28655 0.827126 6.03071 0.571289 5.71512 0.571289Z"
            stroke="currentColor"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_4"
            d="M5.71512 11.9883H1.14369C0.828103 11.9883 0.572266 12.2441 0.572266 12.5597V14.8569C0.572266 15.1724 0.828103 15.4283 1.14369 15.4283H5.71512C6.03071 15.4283 6.28655 15.1724 6.28655 14.8569V12.5597C6.28655 12.2441 6.03071 11.9883 5.71512 11.9883Z"
            stroke="currentColor"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1879_5174">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
