import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import {
  AvailableDays,
  NavigationType,
  NumberSnapshotResponse,
} from '@common/types';
import { DashboardIcon } from '@components/ui';
import { MenuItem } from '@components/ui/DropdownMenu';
import { useDashboardTileSaveMutation } from '@hooks/dashboard';
import {
  useActivityPerDayTrendQuery,
  useCallsMadeSnapshotQuery,
  useEmailsSentSnapshotQuery,
  useMeetingsSentSnapshotQuery,
  useMessagesSentSnapshotQuery,
} from '@hooks/productivity';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import {
  formatNumberWithSuffix,
  getTrendData,
  getTrendLabels,
} from '@utils/index';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LineChartTickFormat,
  LineChartTile,
  LineChartTypes,
} from '../tiles/LineChartTile';
import styles from './styles.module.css';

type DaysSelection = {
  calls: AvailableDays;
  emails: AvailableDays;
  messages: AvailableDays;
  meetings: AvailableDays;
  activity: AvailableDays;
};

const ProductivityPage: React.FC = () => {
  const navigate = useNavigate();

  const [days, setDays] = useState<DaysSelection>({
    emails: '30',
    calls: '30',
    messages: '30',
    meetings: '30',
    activity: '30',
  });
  const [totalActivity, setTotalActivity] = useState('allactivities');

  const dashboardSaveMutation = useDashboardTileSaveMutation();

  const dataHooks = {
    calls: useCallsMadeSnapshotQuery({
      days: days.calls,
    }),
    emails: useEmailsSentSnapshotQuery({
      days: days.emails,
    }),
    messages: useMessagesSentSnapshotQuery({
      days: days.messages,
    }),
    meetings: useMeetingsSentSnapshotQuery({
      days: days.meetings,
    }),
    activity: useActivityPerDayTrendQuery({
      activity: totalActivity,
      days: days.activity,
    }),
  };

  const trendData = getTrendData(
    dataHooks.activity.data?.trend || [],
    +days['activity']
  );
  const labels = getTrendLabels(
    dataHooks.activity.data?.trend || [],
    +days['activity']
  );

  const createMenuItems = (tileType: DashboardTiles): MenuItem[] => [
    {
      id: '0',
      label: 'Add to Dashboard',
      icon: <DashboardIcon classNames="mr-1" />,
      value: 'addToDashboard',
      action: handleItemAction(tileType),
    },
  ];

  const createDropdownMenuItems = (type?: keyof DaysSelection): MenuItem[] => [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleDropdownChange(type, '7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleDropdownChange(type, '30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleDropdownChange(type, '60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleDropdownChange(type, '90'),
    },
  ];

  const activityPerDayMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All activities',
      value: 'allactivities',
      action: () => handleTotalActivityDropdown('allactivities'),
    },
    {
      id: '2',
      label: 'Emails',
      value: 'emails',
      action: () => handleTotalActivityDropdown('emails'),
    },
    {
      id: '3',
      label: 'Messages',
      value: 'messages',
      action: () => handleTotalActivityDropdown('messages'),
    },
    {
      id: '4',
      label: 'Calls',
      value: 'calls',
      action: () => handleTotalActivityDropdown('calls'),
    },

    {
      id: '5',
      label: 'Meetings',
      value: 'meetings',
      action: () => handleTotalActivityDropdown('meetings'),
    },
  ];

  const handleTotalActivityDropdown = (value: string) => {
    setTotalActivity(value);
  };

  const handleDropdownChange = (
    type?: keyof DaysSelection,
    value?: AvailableDays
  ) => {
    if (!type) return;
    setDays((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleItemAction = (type: DashboardTiles) => () => {
    const getParametersData = () => {
      switch (type) {
        case DashboardTiles.CallsMade:
          return {
            days: days['calls'],
          };
        case DashboardTiles.EmailsSent:
          return {
            days: days['emails'],
          };
        case DashboardTiles.MessagesSent:
          return {
            days: days['messages'],
          };
        case DashboardTiles.MeetingsSent:
          return {
            days: days['meetings'],
          };
        case DashboardTiles.ActivityPerDay:
          return {
            days: days['activity'],
            activity: totalActivity,
          };
        default:
          return {};
      }
    };

    const parametersData = getParametersData();

    dashboardSaveMutation.mutate({
      parameters: JSON.stringify(parametersData),
      tileType: type,
    });
  };

  const handleButtonClick = (type: NavigationType) => () => {
    navigate(ANALYTICS_NAVIGATION_MAP[type]);
  };

  const itemActivitySelected = activityPerDayMenuItems.find(
    (item) => item.value === totalActivity
  );

  const getNumberTileData = (data: NumberSnapshotResponse | undefined) => {
    if (!data) return [];
    return [formatNumberWithSuffix(data.value), data.percentage || 0];
  };

  return (
    <>
      <div className={styles.pageContainer}>
        <div className={styles.pageGrid}>
          <LineChartTile
            sortData={activityPerDayMenuItems}
            compareData={createDropdownMenuItems('activity')}
            legendLabels={[]}
            contextData={createMenuItems(DashboardTiles.ActivityPerDay)}
            initialSelectedItem={itemActivitySelected}
            initialCompareSelectedItem={createDropdownMenuItems().find(
              (item) => item.value === days['activity']
            )}
            tooltipLabel={[itemActivitySelected?.label || '']}
            headerTitle={'Activity per day'}
            isLoading={dataHooks.activity.isLoading}
            labels={labels}
            classNames="col-span-full lg:col-span-6 xl:col-span-6"
            data={[trendData]}
            compareLabel=""
            buttonLabel="Activity data"
            dataSetTypes={[LineChartTypes.Dots]}
            dataSetColors={['#00CF6C']}
            linkPath={ANALYTICS_NAVIGATION_MAP['activityData']}
            showYTicks={true}
            showXTicks={true}
            isDotted={true}
            chartHeight={150}
            xTickFormat={LineChartTickFormat.Date}
            showDateRange={true}
          />
        </div>

        <h1 className={styles.header}>Service activity</h1>
        <div className={styles.pageGrid}>
          <NumberTile
            classNames="col-span-6 md:col-span-3 xl:col-span-2"
            sortData={createDropdownMenuItems('emails')}
            initialSelectedItem={createDropdownMenuItems().find(
              (item) => item.value === days['emails']
            )}
            percentageLabel=""
            contextData={createMenuItems(DashboardTiles.EmailsSent)}
            headerTitle={'Emails sent'}
            isLoading={dataHooks.emails.isLoading}
            data={getNumberTileData(dataHooks.emails.data)}
            buttonLabel={'Emails data'}
            linkPath={ANALYTICS_NAVIGATION_MAP['activityData']}
          />

          <NumberTile
            classNames="col-span-6 md:col-span-3 xl:col-span-2"
            sortData={createDropdownMenuItems('messages')}
            initialSelectedItem={createDropdownMenuItems().find(
              (item) => item.value === days['messages']
            )}
            percentageLabel=""
            contextData={createMenuItems(DashboardTiles.MessagesSent)}
            headerTitle={'Messages sent'}
            isLoading={dataHooks.messages.isLoading}
            data={getNumberTileData(dataHooks.messages.data)}
            buttonLabel={'Messages data'}
            linkPath={ANALYTICS_NAVIGATION_MAP['activityData']}
          />

          <NumberTile
            classNames="col-span-6 md:col-span-3 xl:col-span-2"
            sortData={createDropdownMenuItems('calls')}
            initialSelectedItem={createDropdownMenuItems().find(
              (item) => item.value === days['calls']
            )}
            percentageLabel=""
            contextData={createMenuItems(DashboardTiles.CallsMade)}
            headerTitle={'Calls made'}
            isLoading={dataHooks.calls.isLoading}
            data={getNumberTileData(dataHooks.calls.data)}
            buttonLabel={'Calls data'}
            linkPath={ANALYTICS_NAVIGATION_MAP['activityData']}
          />

          <NumberTile
            classNames="col-span-6 md:col-span-3 xl:col-span-2"
            sortData={createDropdownMenuItems('meetings')}
            initialSelectedItem={createDropdownMenuItems().find(
              (item) => item.value === days['meetings']
            )}
            percentageLabel=""
            contextData={createMenuItems(DashboardTiles.MeetingsSent)}
            headerTitle={'Meetings joined'}
            isLoading={dataHooks.meetings.isLoading}
            data={getNumberTileData(dataHooks.meetings.data)}
            buttonLabel={'Meetings data'}
            linkPath={ANALYTICS_NAVIGATION_MAP['activityData']}
          />
        </div>
      </div>
    </>
  );
};

export default ProductivityPage;
