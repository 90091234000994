import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useLicencesUsageCorrespondenceQuery } from '@hooks/licenses';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { formatNumberWithSuffix } from '@utils/index';
import { useState } from 'react';

type Params = {
  days: string;
  serviceType: string;
  vendorList: string[];
  licenseTypeList: string[];
  locationList: string[];
};

export const ServiceUsageMessagesEmailsSent: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.ServiceUsageMessagesEmailsSent,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: licencesCorrespondenceData,
    isLoading: isLicencesCorrespondenceLoading,
    refetch,
  } = useLicencesUsageCorrespondenceQuery(parameters);

  const handleDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      serviceType: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Messages',
      value: 'messages',
      action: () => handleDropdown('messages'),
    },
    {
      id: '2',
      label: 'Emails',
      value: 'emails',
      action: () => handleDropdown('emails'),
    },
  ];

  return (
    parameters && (
      <NumberTile
        sortData={sortMenuItems}
        classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
        initialSelectedItem={sortMenuItems.find(
          (item) => item.value === parameters.serviceType
        )}
        dropdownMenuItemClasses={'!w-[300px]'}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        headerTitle={'Service usage: Messages / emails sent'}
        isLoading={isLicencesCorrespondenceLoading}
        data={
          licencesCorrespondenceData
            ? [
                formatNumberWithSuffix(licencesCorrespondenceData.count),
                licencesCorrespondenceData.percentage,
              ]
            : []
        }
        buttonLabel=""
        percentageLabel=""
      />
    )
  );
};
