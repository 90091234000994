import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import { useCollabSnapshotQuery } from '@hooks/adoption';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import {
  TeamGroupType,
  getCollabDataLabels,
} from '@pages/InsightsAndAnalytics/Adoption';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useEffect, useState } from 'react';

type Params = {
  days: string;
};

export const TeamsAndGroupsCollaboration: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
  isSnapshot,
}) => {
  const { handleButtonClick } = useHandleTileBtnNavigate(
    id,
    TeamGroupType.Collaboration
  );
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.TeamsAndGroupsCollaboration,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: teamsGroupsAdoptionData,
    isLoading,
    refetch,
  } = useCollabSnapshotQuery(parameters);

  useEffect(() => {
    const updatedParams = JSON.parse(params);

    setParameters({
      days: updatedParams.days,
    });

    return () => {
      refetch();
    };
  }, [parameters.days, params, refetch]);

  const handleCompareDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const compareMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleCompareDropdown('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleCompareDropdown('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleCompareDropdown('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleCompareDropdown('90'),
    },
  ];

  return (
    parameters && (
      <DoughnutChartTile
        dropdownMenuItemClasses={'!w-[300px]'}
        initialSelectedItem={compareMenuItems.find(
          (item) => item.value === parameters.days
        )}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        classNames={
          classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
        }
        sortData={!isSnapshot ? compareMenuItems : []}
        headerTitle={'Teams & groups collaboration'}
        isLoading={isLoading}
        data={
          teamsGroupsAdoptionData ? Object.values(teamsGroupsAdoptionData) : []
        }
        dataLabels={getCollabDataLabels(teamsGroupsAdoptionData)}
        buttonLabel={!isSnapshot ? 'Teams & groups activity data' : ''}
        linkPath={ANALYTICS_NAVIGATION_MAP['teamsGroupsAdoption']}
        handleButtonClick={!isSnapshot ? handleButtonClick() : undefined}
        showBracketLabelValue={false}
      />
    )
  );
};
