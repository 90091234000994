import { TilesProps } from '@common/types';
import { useMeetingAdoptionSnapshotQuery } from '@hooks/adoption';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useEffect, useState } from 'react';

type Params = {
  days: string;
  type: string;
};

export const MeetingAdoptionSnapshot: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));

  const {
    data: meetingAdoptionData,
    isLoading: isMeetingAdoptionLoading,
    refetch,
  } = useMeetingAdoptionSnapshotQuery(parameters);

  useEffect(() => {
    setParameters(JSON.parse(params));

    return () => {
      refetch();
    };
  }, [params, refetch]);

  return (
    <DoughnutChartTile
      classNames={classNames ? classNames : 'col-span-3 lg:col-auto'}
      contextData={[]}
      sortData={[]}
      headerTitle={'Meeting adoption'}
      dropdownMenuItemClasses={'!w-[300px]'}
      isLoading={isMeetingAdoptionLoading}
      data={
        meetingAdoptionData
          ? [
              meetingAdoptionData?.hasntSetupJoinedMeeting,
              meetingAdoptionData?.setupJoinedMeeting,
            ]
          : []
      }
      dataLabels={[
        `${
          meetingAdoptionData && meetingAdoptionData?.hasntSetupJoinedMeeting
            ? meetingAdoptionData.hasntSetupJoinedMeeting
            : 0
        } people haven’t set up / joined a meeting`,
        `${
          meetingAdoptionData && meetingAdoptionData?.setupJoinedMeeting
            ? meetingAdoptionData.setupJoinedMeeting
            : 0
        } people have set up / joined a meeting`,
      ]}
      showBracketLabelValue={false}
    />
  );
};
