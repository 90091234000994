import { formatDate } from '@utils/index';

export const createCellValue = (property: string, data: any, index: number) => {
  const value = data[index][property];

  switch (property) {
    case 'expiryStatus':
      return (
        <>
          {data[index]['expiryStatus'] !== 'Expires within 7 days' && (
            <span
              className={`status status-active -mt-[3px] align-middle`}
            ></span>
          )}

          {data[index]['expiryStatus'] === 'Expires within 7 days' && (
            <span
              className={`status status-warning -mt-[3px] align-middle`}
            ></span>
          )}
          <span>{value === '' ? 'Free' : value}</span>
          <span className="block text-14 text-interfaceColor-80">
            {data[index]['expiryDate'] && formatDate(data[index]['expiryDate'])}
          </span>
        </>
      );
    // case 'autoRenew':
    //   return (
    //     <>
    //       <CellLeftStatusWithIconPart status={data[index]['autoRenew']} />
    //       <span>{value ? 'Active' : 'Inactive'}</span>
    //     </>
    //   );
  }
};
