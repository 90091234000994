import {
  HubSettingsUserIcon,
  LightningIcon,
  LocationPinIcon,
} from '@components/ui/Icons';

export const HUB_SETTINGS_CARDS = [
  {
    icon: <HubSettingsUserIcon size="5" />,
    title: 'Clevr360 users',
    content:
      'Control who has access to Clevr360 and what they can do, and help them manage their accounts.',
    buttonLabel: 'Manage Clevr360 users',
    buttonAction: '/settings/manage-users',
  },
  {
    icon: <LightningIcon size="5" />,
    title: 'Connected services',
    content:
      'View, edit, add, and remove services that Clevr360 gets data from.',
    buttonLabel: 'Manage connected services',
    buttonAction: '/settings/manage-connected-services',
  },
  // {
  //   icon: <LocationPinIcon size="5" />,
  //   title: 'Operating locations',
  //   content:
  //     'Set approved operating locations to get notifications when people use your services to contact unapproved places.',
  //   buttonLabel: 'Manage operating locations',
  //   buttonAction: '/settings/manage-operating-services',
  // },
];
