import { Button, Card } from '@components/ui';
import { ChartData } from 'chart.js';
import DoughnutChart from '@components/ui/Charts/DoughnutChart';
import { Loader } from '@components/ui/Loader';
import { ArrowDownIcon, HamburgerIcon } from '@components/ui/Icons';
import styles from './styles.module.css';
import cn from 'classnames';
import { SelectDropdown, SelectItem } from '@components/ui/SelectDropdown';
import { DropdownMenu, MenuItem } from '@components/ui/DropdownMenu';
import { useState } from 'react';
import { DEFAULT_CHART_COLORS } from '@common/constants';
import { TileFooter } from '../partials/TileFooter';

export type DoughnutChartTileProps = {
  sortData?: MenuItem[];
  compareData?: MenuItem[];
  contextData: MenuItem[];
  headerTitle: string;
  isLoading: boolean;
  data: number[];
  dataLabels: string[];
  dataLabelValues?: string[];
  handleDropdownSelect?: (value: any) => void;
  buttonLabel?: string;
  handleButtonClick?: () => void;
  classNames?: string;
  isLgSize?: boolean;
  checkBoxItems?: SelectItem[];
  dropdownMenuItemClasses?: string;
  initialSelectedItem?: MenuItem;
  selectedItemsIds?: string[];
  showBracketLabelValue?: boolean;
  initialCompareSelectedItem?: MenuItem;
  animation?: boolean;
  colors?: string[];
  isConsistentDropdownMenuItemClasses?: boolean;
  additionalTextInfo?: React.ReactNode;
  isPrintView?: boolean;
  linkPath?: string;
  showMessageIfEmpty?: boolean;
};

export const DoughnutChartTile: React.FC<DoughnutChartTileProps> = ({
  sortData,
  compareData,
  headerTitle,
  isLoading = false,
  data = [0, 0],
  dataLabels,
  dataLabelValues,
  contextData,
  handleDropdownSelect,
  buttonLabel,
  handleButtonClick,
  classNames,
  isLgSize = false,
  dropdownMenuItemClasses,
  initialSelectedItem,
  checkBoxItems,
  selectedItemsIds,
  animation = true,
  showBracketLabelValue = true,
  initialCompareSelectedItem,
  colors,
  isConsistentDropdownMenuItemClasses,
  additionalTextInfo,
  isPrintView,
  linkPath,
  showMessageIfEmpty = true
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const chartData: ChartData<'doughnut'> = {
    datasets: [
      {
        data,
        backgroundColor: colors ?? DEFAULT_CHART_COLORS,
      },
    ],
  };

  const getChartLegendDotColor = (index: number) => {
    const chartColors = colors ?? DEFAULT_CHART_COLORS;
    const colorIndex = chartColors.length;
    return chartColors[index % colorIndex];
  };

  const handleChange = (id: string) => {
    handleDropdownSelect && handleDropdownSelect(id);
  }

  return (
    <Card classNames={`!w-auto ${classNames}`}>
      <Card.Header classNames={styles.cardHeader}>
        <div className={styles.cardHeaderContainer}>
          <span className="mb-2">{headerTitle}</span>
          {contextData.length ? (
            <DropdownMenu
              items={contextData}
              buttonProps={{ icon: <HamburgerIcon></HamburgerIcon> }}
              dropdownMenuClasses={dropdownMenuItemClasses}
            ></DropdownMenu>
          ) : null}
        </div>

        <div className="flex flex-wrap items-center gap-x-2 gap-y-2">
          {!!sortData?.length && (
            <DropdownMenu
              items={sortData}
              showSelectedItem={true}
              dropdownMenuClasses={
                isConsistentDropdownMenuItemClasses
                  ? dropdownMenuItemClasses
                  : undefined
              }
              buttonProps={{
                label: sortData[0].label,
              }}
              initialSelectedItem={initialSelectedItem || sortData[0]}
            ></DropdownMenu>
          )}
          {compareData && (
            <DropdownMenu
              items={compareData}
              showSelectedItem={true}
              buttonProps={{
                label: compareData[0].label,
              }}
              dropdownMenuClasses={'!w-[220px]'}
              initialSelectedItem={initialCompareSelectedItem || compareData[0]}
            ></DropdownMenu>
          )}

          {checkBoxItems && (
            <SelectDropdown
              header={
                <Button
                  onClick={() => {
                    setIsDropdownOpen(!isDropdownOpen);
                  }}
                  size="small"
                  type="button"
                  variant="inactiveOutline"
                  className="mt-3 w-auto border-none !bg-interfaceColor-15 !shadow-none"
                >
                  Device type <ArrowDownIcon size="3" classNames="ml-1" />
                </Button>
              }
              isSingleSelect={false}
              classNames={'!w-[300px]'}
              isOpen={isDropdownOpen}
              setIsOpen={setIsDropdownOpen}
              items={checkBoxItems}
              onSelect={(ids: string[], selectedItem) => {
                if (selectedItem)
                  handleChange(selectedItem?.value)
              }}
            />
          )}
        </div>
      </Card.Header>

      <Card.Content classNames={styles.cardContent}>
        {isLoading ? (
          <div className="flex w-full justify-center">
            <Loader />
          </div>
        ) : (
          <div className={styles.cardDetails}>
            <div
              className={cn(
                styles.chartContainer,
                isLgSize ? 'lg:!h-[14rem]' : ''
              )}
            >
              {chartData.datasets[0].data.some((i) => i > 0) || !showMessageIfEmpty ? (
                <>
                  <div className="w-1/2 md:w-auto">
                    <DoughnutChart
                      classNames={cn(
                        styles.chart,
                        isLgSize ? 'lg:!h-[14rem] lg:!w-[14rem]' : ''
                      )}
                      data={chartData}
                      animation={animation}
                    />
                  </div>
                  <div className="max-h-40 w-1/2 overflow-y-auto md:w-auto">
                    {isPrintView &&
                      dataLabels.map((label, index) => (
                        <div key={index} className={styles.chartLegendItem}>
                          <span
                            className={styles.chartLegendDot}
                            style={{
                              background: getChartLegendDotColor(index),
                            }}
                          ></span>
                          <span className="text-xs">{label}</span>
                        </div>
                      ))}
                    {!isPrintView &&
                      dataLabels.map((label, index) => (
                        <div key={index} className={styles.chartLegendItem}>
                          <span
                            className={styles.chartLegendDot}
                            style={{
                              background: getChartLegendDotColor(index),
                            }}
                          ></span>
                          <span>
                            {label}
                            {showBracketLabelValue && (
                              <span className="ml-1 break-words">
                                (
                                {dataLabelValues && dataLabelValues[index]
                                  ? dataLabelValues[index]
                                  : data && data[index]}
                                )
                              </span>
                            )}
                          </span>
                        </div>
                      ))}
                    {additionalTextInfo ?? additionalTextInfo}
                  </div>
                </>
              ) : (
                <div className="my-3 flex w-full flex-col items-center justify-center">
                  <h1 className="mb-2 rounded-full bg-interfaceColor-20 px-4 py-1 text-20 text-interfaceColor-100">
                    Insufficient data
                  </h1>
                  <p className="max-w-sm text-center text-interfaceColor-80">
                    Keep using Clevr360 to see how your
                    <span className="mx-1">{headerTitle}</span>
                    have changed over time
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </Card.Content>
      <TileFooter
        linkPath={linkPath}
        buttonLabel={buttonLabel}
        handleButtonClick={handleButtonClick}
      />
    </Card>
  );
};
