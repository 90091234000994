import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { SecureScoreHelper } from './helpers/SecureScoreHelper';

export const SecureScoreDevice: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  return (
    <SecureScoreHelper
      params={params}
      id={id}
      classNames={classNames}
      type={DashboardTiles.SecureScoreDevice}
    />
  );
};
