import { CellBottomPart } from '@components/ui/Table/utils';
import { formatDate, showLastUsedData } from '@utils/index';

export const createCellValue = (
  property: string,
  data: any,
  index: number,
  isMobile: boolean,
  formatedValue: string
) => {
  const value = data[index][property];

  switch (property) {
    case 'assignedTo':
      return (
        <>
          <span>{value ? value : 'Unassigned'}</span>
          {value && <CellBottomPart data={data[index]['emailAddress']} />}
        </>
      );
    case 'lastUsed':
      return (
        <>
          <span>{formatDate(value)}</span>
          {showLastUsedData(data[index]['overNDaysAgo'])}
        </>
      );
    case 'licenseType':
      return (
        <>
          <span>{isMobile ? value : formatedValue}</span>
          {data[index]['isOverassigned'] && (
            <span className="ml-1 rounded-[20px] border border-warningColor-100 bg-warningColor-10 pb-0 pl-2 pr-2 pt-0 text-14 text-interfaceColor-100">
              Overassigned
            </span>
          )}
        </>
      );
    case 'assignmentDate':
      return (
        <>
          <span>{formatDate(value)}</span>
        </>
      );
    case 'expiryDate':
      return (
        <>
          <span>{formatDate(value)}</span>
        </>
      );
      case 'vendor':
        return (
          <>
            <span>{value ?? 'No data available'}</span>
          </>
        );
  }
};
