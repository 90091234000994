import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import {
  useAddDashboardTile,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useSharedMailboxesSnapshotQuery } from '@hooks/security';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';

export const SharedMailboxesWithSignInEnabled: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const { data: sharedMailboxesData, isLoading: isSharedMailboxesLoading } =
    useSharedMailboxesSnapshotQuery();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.SharedMailboxesWithSignInEnabled,
    ''
  );
  const isOnDashboardPage = !!id;

  return (
    <NumberTile
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
      percentageLabel=""
      headerTitle={'Shared mailboxes with sign-in enabled'}
      isLoading={isSharedMailboxesLoading}
      dropdownMenuItemClasses={'!w-[300px]'}
      data={sharedMailboxesData ? Object.values(sharedMailboxesData) : []}
      buttonLabel={'Shared mailbox data'}
      linkPath={ANALYTICS_NAVIGATION_MAP['sharedMailboxes']}
      handleButtonClick={handleButtonClick()}
    />
  );
};
