import { CellBottomPart } from '@components/ui/Table/utils';

export const createCellValue = (
  property: string,
  data: any,
  index: number,
  isMobile: boolean
) => {
  const value = data[index][property];

  switch (property) {
    case 'isEnabled':
      return (
        <>
          <span
            className={`status -mt-[3px] align-middle status-${
              value ? 'active' : 'inactive'
            }`}
          ></span>
          <span>{value ? 'Active' : 'Locked'}</span>
        </>
      );
    case 'fullName':
      return (
        <div className="flex flex-col">
          <span>{value}</span>
          <div className="block">
            <CellBottomPart data={data[index]['emailAddress']} />
          </div>
        </div>
      );
    case 'phone':
      return (
        <>
          <span>{value ? value : 'Not set'}</span>
        </>
      );
  }
};
