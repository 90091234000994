import { API_ENDPOINTS } from '@api/ApiEndpoints';
import { useQuery, UseQueryResult } from 'react-query';
import {
  GroupsCountInfo,
  LocationsCountInfo,
  PersonDetailsQueryOptions,
  PersonDetailsResponse,
  PersonLicensesPreviewQueryOptions,
  PersonLicensesPreviewResponse,
  PersonsResponse,
  UsePersonsQueryOptions,
} from './types';
import { useHttpClient } from '@hooks/utils/httpConfig';
import { getSearchQueryParams } from '@utils/index';
import { AccountType } from '@hooks/locations/types';

export function usePersonsQuery({
  searchTerm,
  sortBy,
  order,
  personStatus,
  groupList,
  accountTypes,
  locationList,  
  pageSize = 20,
  pageNumber = 1,
  enabled = true,
}: UsePersonsQueryOptions = {}): UseQueryResult<PersonsResponse> {
  const personsClient = useHttpClient();
  const fetchPersons = async (): Promise<PersonsResponse> => {
    const params = getSearchQueryParams({
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      locationList,
      groupList,
      status: personStatus,
      types: accountTypes,
    });

    const endpoint = `${API_ENDPOINTS.PERSONS}?${params.toString()}`;
    return personsClient.get<PersonsResponse>(endpoint);
  };

  return useQuery(
    [
      'persons',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      personStatus,
      groupList,
      locationList,
      accountTypes,
    ],
    fetchPersons,
    { enabled }
  );
}

export function useUnassignedLocationsQuery({
  searchTerm,
  sortBy,
  order,
  personStatus,
  pageSize = 20,
  pageNumber = 1,
}: UsePersonsQueryOptions = {}): UseQueryResult<PersonsResponse> {
  const personsClient = useHttpClient();
  const fetchPersons = async (): Promise<PersonsResponse> => {
    const params = {
      pageSize: pageSize,
      pageNumber: pageNumber,
      searchTerm: searchTerm,
      sortBy: sortBy,
      order: order,
      status: personStatus,
    };
    return personsClient.get<PersonsResponse>(
      API_ENDPOINTS.PERSONS_UNASSIGNED_LOCATIONS,
      params
    );
  };

  return useQuery(
    [
      'unassignedLocations',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      personStatus,
    ],
    fetchPersons
  );
}

export function useUnassignedLocationsInfoQuery(): UseQueryResult<LocationsCountInfo> {
  const personsClient = useHttpClient();
  const fetchData = async (): Promise<LocationsCountInfo> => {
    return personsClient.get<LocationsCountInfo>(
      API_ENDPOINTS.PERSONS_UNASSIGNED_LOCATIONS_INFO
    );
  };

  return useQuery(['unassignedLocationsInfo'], fetchData, {
    staleTime: 0,
  });
}

export function useUnassignedGroupsQuery({
  searchTerm,
  sortBy,
  order,
  personStatus,
  pageSize = 20,
  pageNumber = 1,
}: UsePersonsQueryOptions = {}): UseQueryResult<PersonsResponse> {
  const personsClient = useHttpClient();
  const fetchPersons = async (): Promise<PersonsResponse> => {
    const params = {
      pageSize: pageSize,
      pageNumber: pageNumber,
      searchTerm: searchTerm,
      sortBy: sortBy,
      order: order,
      status: personStatus,
    };
    return personsClient.get<PersonsResponse>(
      API_ENDPOINTS.PERSONS_UNASSIGNED_GROUPS,
      params
    );
  };

  return useQuery(
    [
      'unassignedGroups',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      personStatus,
    ],
    fetchPersons
  );
}

export function useUnassignedGroupsInfoQuery(): UseQueryResult<GroupsCountInfo> {
  const personsClient = useHttpClient();
  const fetchData = async (): Promise<GroupsCountInfo> => {
    return personsClient.get<GroupsCountInfo>(
      API_ENDPOINTS.PERSONS_UNASSIGNED_GROUPS_INFO
    );
  };

  return useQuery(['unassignedGroupsInfo'], fetchData, {
    staleTime: 0,
  });
}

export function usePersonQuery({
  personId,
}: PersonDetailsQueryOptions = {}): UseQueryResult<PersonDetailsResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<PersonDetailsResponse> => {
    return httpClient.get<PersonDetailsResponse>(
      `${API_ENDPOINTS.PERSONS}/${personId}`
    );
  };

  return useQuery(['secureScoreAction', personId], fetchData);
}

export function usePersonLicensesPreviewQuery({
  assignedTo,
  pageSize = 20,
  pageNumber = 1,
}: PersonLicensesPreviewQueryOptions = {}): UseQueryResult<PersonLicensesPreviewResponse> {
  const personsClient = useHttpClient();
  const fetchPersonsLicenses =
    async (): Promise<PersonLicensesPreviewResponse> => {
      const params = {
        pageSize: pageSize,
        pageNumber: pageNumber,
        assignedTo: assignedTo,
      };
      return personsClient.get<PersonLicensesPreviewResponse>(
        API_ENDPOINTS.PERSON_LICENSES,
        params
      );
    };

  return useQuery(
    ['personLicenses', pageSize, pageNumber, assignedTo],
    fetchPersonsLicenses,
    {
      enabled: !!assignedTo,
    }
  );
}
export function useAccountTypesQuery(): UseQueryResult<AccountType[]> {
  const locationsClient = useHttpClient();
  const fetchAccountTypes = async (): Promise<Location[]> => {
    return locationsClient.get<Location[]>(API_ENDPOINTS.ACCOUNT_TYPES);
  };
  return useQuery(['accountTypes'], fetchAccountTypes);
}