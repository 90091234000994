import React from 'react';
import { IconProps } from './types';

export const CloseIcon: React.FC<IconProps> = ({
  size = '12',
  color = 'text-interfaceColor-100',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5714 0.428574L0.428558 11.5714"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.428558 0.428574L11.5714 11.5714"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
