import * as React from 'react';
import { IconProps } from './types';

export const PageNotFoundIcon: React.FC<IconProps> = ({
  size = '120',
  strokeWidth = 4,
  classNames = '',
}) => {
  return (
    <svg
      className={`stroke-current w-${size} h-${size} ${classNames}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        stroke="#00EC7B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M85.156 91.147V76.77M89.172 62.406a4.552 4.552 0 0 0-8.03 0l-24.227 46.168a4.41 4.41 0 0 0 .153 4.374 4.522 4.522 0 0 0 3.862 2.157h48.454a4.532 4.532 0 0 0 3.867-2.157 4.42 4.42 0 0 0 .148-4.374L89.172 62.406Z"
      />
      <path
        stroke="#282B2F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M43.23 115.105H9.688a4.79 4.79 0 0 1-4.792-4.792V9.688a4.792 4.792 0 0 1 4.792-4.792h63.696a4.792 4.792 0 0 1 3.388 1.438l17.762 17.73a4.791 4.791 0 0 1 1.404 3.387V43.23M19.271 28.863V48.03M62.397 28.863V48.03M48.022 62.406v19.167M76.772 28.863V48.03"
      />
      <path
        stroke="#282B2F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M40.834 28.844a7.185 7.185 0 0 1 7.188 7.187v4.792a7.187 7.187 0 0 1-14.375 0V36.03a7.187 7.187 0 0 1 7.187-7.187ZM26.459 62.387a7.188 7.188 0 0 1 7.188 7.187v4.792a7.187 7.187 0 1 1-14.375 0v-4.792a7.187 7.187 0 0 1 7.187-7.187Z"
      />
      <path
        stroke="#00EC7B"
        strokeWidth={strokeWidth}
        d="M85.157 103.107a1.196 1.196 0 0 1-.847-2.045c.224-.225.53-.351.847-.351"
      />
      <path
        stroke="#00EC7B"
        strokeWidth={strokeWidth}
        d="M85.156 103.107a1.196 1.196 0 0 0 .847-2.045 1.196 1.196 0 0 0-.847-.351"
      />
    </svg>
  );
};
