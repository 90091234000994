import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useLicencesExpiringSnapshotUserQuery } from '@hooks/licenses';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { useState } from 'react';

type Params = {
  days: string;
  vendorList: string[];
  subscriptionNameList: string[];
  autoRenew: string;
  renewalPeriod: string;
};

export const UsersImpactedByLicenseExpiry: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.UsersImpactedByLicenseExpiry,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: licencesExpiringSnapshotUserData,
    isLoading: isLicencesExpiringSnapshotUserLoading,
    refetch,
  } = useLicencesExpiringSnapshotUserQuery(parameters);

  const handleDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Next 7 days',
      value: '7',
      action: () => handleDropdown('7'),
    },
    {
      id: '2',
      label: 'Next 30 days',
      value: '30',
      action: () => handleDropdown('30'),
    },
    {
      id: '3',
      label: 'Next 60 days',
      value: '60',
      action: () => handleDropdown('60'),
    },
    {
      id: '4',
      label: 'Next 90 days',
      value: '90',
      action: () => handleDropdown('90'),
    },
  ];

  return (
    parameters && (
      <NumberTile
        initialSelectedItem={sortMenuItems.find(
          (item) => item.value === parameters.days
        )}
        dropdownMenuItemClasses={'!w-[300px]'}
        classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
        key={id}
        sortData={sortMenuItems}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        headerTitle={'Users impacted by license expiry'}
        isLoading={isLicencesExpiringSnapshotUserLoading}
        data={
          licencesExpiringSnapshotUserData
            ? Object.values(licencesExpiringSnapshotUserData)
            : []
        }
      />
    )
  );
};
