import { CellBottomPart } from '@components/ui/Table/utils';

export const createCellValue = (
  property: string,
  data: any,
  index: number,
  isMobile: boolean
) => {
  const value = data[index][property];

  switch (property) {
    case 'modelName':
      return (
        <>
          <span>{value}</span>
          {!isMobile && (
            <>
              {data[index]['status'] === 'Offline' &&
                data[index]['modelName'] && (
                  <span className="ml-1 rounded-[20px] border border-warningColor-100 bg-warningColor-10 pb-0 pl-2 pr-2 pt-0 text-14 text-interfaceColor-100">
                    Offline
                  </span>                  
                )}
              <CellBottomPart data={data[index]['givenName']} />
            </>
          )}
        </>
      );
    case 'assigneeDisplayName':
      return (
        <>
          <span>{value || 'Unassigned'}</span>
          <div className="flex flex-col">
            <span className="text-14 tracking-wide text-interfaceColor-80">
              {data[index]['assigneeEmail']}
            </span>
          </div>
        </>
      );
      case 'ipAddress':
      return (
        <>
          <span>{value || 'Not Available'}</span>
          <div className="flex flex-col">
            <span className="text-14 tracking-wide text-interfaceColor-80">
              {data[index]['macAddress'] || 'Not Available'}
            </span>
          </div>
        </>
      );
  }
};
