import React, { useEffect, useState } from 'react';
import Tab, { Tab as TabType } from '../../../../components/ui/Tab';
import { useIsDesktop, useIsMobile } from '@hooks/utils';
import { OverlayPage } from '@components/partials';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { OutDatedOperatingSystemsActivity } from '@pages/Dashboard/tiles';
import { Dropdown } from '@components/ui/Dropdown';
import { LAST_DAYS_SORT_BY, USERTYPE_FILTERS } from '@common/constants';
import styles from './styles.module.css';
import { useQueryParams } from 'use-query-params';
import { OutdatedUsedOS } from './tiles/OutdatedUsedOS';
import { OutdatedOSUsers } from './tiles/OutdatedOSUsers';
import { useLocationsQuery } from '@hooks/locations';
import { useOutdatedOSFiltersQuery } from '@hooks/security';
import { useGroupsFilterQuery } from '@hooks/groups';
import { addGroupsToFilter, addLocationsToFilter, addSelectedDataToFilter } from './utils';
import { ExtendedOption, IOption } from '@common/types';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import useInsightsStore from '@store/insightsStore';
import { getGroupOrLocationFilterAsQueryParam } from '@utils/index';

const floatingSortDropdownData = LAST_DAYS_SORT_BY;

const OutdatedOSDataLayout: React.FC = () => {
    const isMobile = useIsMobile();
    const redirectPath = useNavSourceRedirect(
        '/insights-and-analytics/assurance'
    );
    const [days, setDays] = useState('30');
    const [daysSelectedLabel, setDaysSelectedLabel] = useState(
        floatingSortDropdownData[1].label || ''
    );
    const [daysSelectedIndex, setDaysSelectedIndex] = useState(1);
    const [isDaysDropdownOpen, setIsDaysDropdownOpen] = useState(false);
    const isDesktop = useIsDesktop();
    const [queryParams, setQueryParams] = useQueryParams();
    const [updatedFilterData, setUpdatedFilterData] = useState<FilterData[]>([{
        label: 'User type',
        name: 'accountType',
        singleSelect: false,
        options: [{
            label: 'Internal',
            value: 'Internal',
        }, {
            label: 'External',
            value: 'External',
        }],
    }]);

    const {
        groupIds, locationIds,
        setFilterData,
        setGroupIds, setLocationIds,
        setTransformedFilterData
    } = useInsightsStore();

    const tabs: TabType[] = [
        {
            path: '/insights-and-analytics/assurance/outdated-operating-systems/accounts',
            label: 'Accounts',
        },
        {
            path: '/insights-and-analytics/assurance/outdated-operating-systems/systems',
            label: 'Operating systems',
        },

    ];

    const { data } = useOutdatedOSFiltersQuery(days);

    useEffect(() => {
        if (data) {
            const addedFilters = addSelectedDataToFilter(data);
            setUpdatedFilterData((prev) => ([...addedFilters, ...prev.filter(item => item.name !== "operatingSystemNames")]))
            setFilterData(data);
        }
    }, [data]);

    useEffect(() => {
        setTransformedFilterData(updatedFilterData)
    }, [updatedFilterData]);

    const { data: locationsData, isFetched: isLocationsFetched } = useLocationsQuery();
    const { data: groupsData, isFetched: isGroupsFetched } = useGroupsFilterQuery();

    const groupsIDs: string[] = [];
    const locationFilerOptions: IOption[] = [];
    const groupFilerOptions: ExtendedOption[] = [];

    useEffect(() => {
        if (groupsData && isGroupsFetched) {
            const groups = addGroupsToFilter(groupsData, groupFilerOptions);
            setUpdatedFilterData((prev) => ([...prev, groups]))
            const nested = (element: any) => {
                element.subGroups.forEach((subElement: any) => {
                    groupsIDs.push(subElement.id);
                    if (subElement.subGroups && subElement.subGroups.length > 0) nested(subElement);
                });
            };
            groupsData?.forEach((element: any) => {
                groupsIDs.push(element.id);
                if (element.subGroups && element.subGroups.length > 0) nested(element);
            });
            setGroupIds(groupsIDs);
        }

    }, [groupsData, isGroupsFetched]);

    useEffect(() => {
        if (locationsData && isLocationsFetched) {
            const { locations, ids } = addLocationsToFilter(locationsData, locationFilerOptions);
            setUpdatedFilterData((prev) => ([...prev, locations]))
            setLocationIds(ids);

        }
    }, [locationsData, isLocationsFetched]);

    const handleIsDaysDropdownOpen = () => {
        setIsDaysDropdownOpen(!isDaysDropdownOpen);
    };

    const handleDropdownDaysOnChange = (value: string[], index: number) => {
        setDays(value[0]);
        setDaysSelectedIndex(index);
        setDaysSelectedLabel(floatingSortDropdownData[index].label);
        setQueryParams(
            { days: value[0] || undefined },
            'pushIn'
        );
    };

    return (
        <OverlayPage
            isFooterVisible={false}
            contentClassNames={''}
            path={redirectPath}
            headerTitle={`Outdated OS data`}
        >
            {!isMobile && (
                <div className="absolute left-[40px] top-[100px]">
                    <Dropdown>
                        <Dropdown.TextHeader
                            classNames="!bg-interfaceColor-5 !border !border-interfaceColor-100 rounded-full"
                            label={daysSelectedLabel}
                            handleOpen={handleIsDaysDropdownOpen}
                        ></Dropdown.TextHeader>
                        <Dropdown.List
                            className="!z-[999] text-16"
                            open={isDaysDropdownOpen}
                            align="left"
                        >
                            {floatingSortDropdownData.map((item, index) => {
                                return (
                                    <Dropdown.DefaultItem
                                        key={index}
                                        item={item}
                                        currentIndex={index}
                                        selectedIndex={daysSelectedIndex}
                                        onChange={() =>
                                            handleDropdownDaysOnChange(item.value, index)
                                        }
                                    ></Dropdown.DefaultItem>
                                );
                            })}
                        </Dropdown.List>
                    </Dropdown>
                </div>
            )}
            <div className={styles.pageGrid}>
                {isDesktop && (
                    <>
                        <OutDatedOperatingSystemsActivity
                            isSnapshot={true}
                            classNames="col-span-1"
                            params={JSON.stringify({
                                days,
                                OSType: queryParams.filter
                                    ? queryParams.filter
                                        .split(',')
                                        .filter((filter: string) => data?.operatingSystemNames.includes(filter))
                                    : '',
                                accountType: queryParams.filter
                                    ? queryParams.filter
                                        .split(',')
                                        .filter((filter: string) => USERTYPE_FILTERS.includes(filter))
                                        .toString()
                                    : '',
                                locationList: getGroupOrLocationFilterAsQueryParam(
                                    queryParams.filter,
                                    locationIds
                                ),
                                groupList: getGroupOrLocationFilterAsQueryParam(
                                    queryParams.filter,
                                    groupIds
                                ),

                            })} />
                        <OutdatedUsedOS
                            isSnapshot={true}
                            classNames="col-span-1"
                            params={JSON.stringify({
                                days,
                                OSType: queryParams.filter
                                    ? queryParams.filter
                                        .split(',')
                                        .filter((filter: string) => data?.operatingSystemNames.includes(filter))
                                    : '',
                                accountType: queryParams.filter
                                    ? queryParams.filter
                                        .split(',')
                                        .filter((filter: string) => USERTYPE_FILTERS.includes(filter))
                                        .toString()
                                    : '',
                                locationList: getGroupOrLocationFilterAsQueryParam(
                                    queryParams.filter,
                                    locationIds
                                ),
                                groupList: getGroupOrLocationFilterAsQueryParam(
                                    queryParams.filter,
                                    groupIds
                                ),

                            })} />
                        <OutdatedOSUsers
                            isSnapshot={true}
                            classNames="col-span-1"
                            params={JSON.stringify({
                                days,
                                OSType: queryParams.filter
                                    ? queryParams.filter
                                        .split(',')
                                        .filter((filter: string) => data?.operatingSystemNames.includes(filter))
                                    : '',
                                accountType: queryParams.filter
                                    ? queryParams.filter
                                        .split(',')
                                        .filter((filter: string) => USERTYPE_FILTERS.includes(filter))
                                        .toString()
                                    : '',
                                locationList: getGroupOrLocationFilterAsQueryParam(
                                    queryParams.filter,
                                    locationIds
                                ),
                                groupList: getGroupOrLocationFilterAsQueryParam(
                                    queryParams.filter,
                                    groupIds
                                ),

                            })} />
                    </>
                )}
            </div>
            <div className="mt-[5px]">
                <Tab tabs={tabs} className={isMobile ? 'mb-[100px]' : 'mb-[0px]'} />
            </div>
        </OverlayPage>
    );
};

export default OutdatedOSDataLayout;
