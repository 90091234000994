import cn from 'classnames';
import React, { InputHTMLAttributes } from 'react';
import styles from './styles.module.css';

export type TextareaProps = InputHTMLAttributes<HTMLTextAreaElement> & {
    className?: string;
    inputClassName?: string;
    label?: string;
    placeholder?: string;
    name: string;
    error?: string;
};

const classes = {
    root: styles.root,
};

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
    (
        {
            className = 'block',
            label,
            name,
            error,
            placeholder,
            inputClassName,
            ...rest
        },
        ref
    ) => {
        const rootClassName = cn(classes.root, inputClassName);

        return (
            <div className={className}>
                {label && (
                    <label
                        htmlFor={name}
                        data-testid="label"
                        className={styles.label}
                    >
                        {label}
                    </label>
                )}
                <textarea
                    data-testid={name}
                    id={name}
                    name={name}
                    ref={ref}
                    placeholder={placeholder}
                    className={rootClassName}
                    autoComplete="off"
                    spellCheck="false"
                    aria-invalid={error ? 'true' : 'false'}
                    {...rest}
                />
                {error && <p data-testid="error" className="my-2 text-xs text-red-500">{error}</p>}
            </div>
        );
    }
);

Textarea.displayName = 'Textarea';
