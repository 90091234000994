import * as React from 'react';
import { IconProps } from './types';

export const LongArrowLeftIcon: React.FC<IconProps> = ({
  size = '5',
  color = 'text-interfaceColor-100',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${color} ${classNames}`}
    >
      <g clipPath="url(#clip0_2136_1527)">
        <path
          d="M12.7262 4.99988L1.27381 4.99988"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.35718 1.91658L1.27384 4.99992L4.35718 8.08325"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2136_1527">
          <rect
            width="13.3333"
            height="8.33333"
            fill="white"
            transform="translate(13.6666 9.16663) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
