import * as React from 'react';
import { IconProps } from './types';

export const LightningIcon: React.FC<IconProps> = ({
  color = 'text-primaryBrandColor-100',
  classNames = '',
  size = 4,
}) => {
  return (
    <svg
      className={`stroke-current w-${size} h-${size} ${color} ${classNames}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 22"
      fill="none"
    >
      <path
        d="M9.5 1.25v7.5h5.25l-8.25 12v-7.5H1.25l8.25-12Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
    </svg>
  );
};
