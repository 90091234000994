import { create } from 'zustand';

type ModalStore = {
  isDetailsModalOpen: boolean;
  dataDetailsModal: DetailsModal;
  isHelixSuccessModalOpen: boolean;
};

type DetailsModal = {
  header: JSX.Element | null;
  body: JSX.Element | null;
};

type ModalStoreActions = {
  setIsDetailsModalOpen: (value: boolean) => void;
  setDataDetailsModal: (value: DetailsModal) => void;
  setHelixSuccessModalOpen: (value: boolean) => void;
};

const useModalStore = create<ModalStore & ModalStoreActions>((set) => ({
  isDetailsModalOpen: false,
  isHelixSuccessModalOpen: false,
  dataDetailsModal: {
    header: null,
    body: null,
  },
  setIsDetailsModalOpen: (value: boolean) => {
    set(() => ({ isDetailsModalOpen: value }));
  },
  setDataDetailsModal: (value: DetailsModal) => {
    set(() => ({ dataDetailsModal: value }));
  },
  setHelixSuccessModalOpen: (value: boolean) => {
    set(() => ({ isHelixSuccessModalOpen: value }));
  },
}));

export default useModalStore;
