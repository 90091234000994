import { CircleRightArrow } from '@components/ui/Icons';
import {
  CellBottomGroupPart,
  CellBottomPart,
  CellLeftStatusPart,
} from '@components/ui/Table/utils';
import { UserRoles, Permissions } from '@hooks/users/types';
import useAuthStore from '@store/authStore';
import { formatDate, hasPermission } from '@utils/index';
import { Link } from 'react-router-dom';

export const createCellValue = (
  property: string,
  data: any,
  index: number,
  isMobile: boolean
) => {
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;

  const seePeopleDetailsHasPermission = hasPermission(
    userRole,
    Permissions.ADMINS_SUPPORTUSER
  );

  const value = data[index][property];

  switch (property) {
    case 'status':
      return (
        <>
          <CellLeftStatusPart status={value} />
          <span>{value}</span>
        </>
      );
    case 'type':
      return (
        <>
          <span>
            {value ? value.charAt(0).toUpperCase() + value.slice(1) : '-'}
          </span>
        </>
      );
    case 'groups':
      return (
        <>
          <span>{value[0]?.name ?? 'Unassigned'}</span>
          {Array.isArray(value) && value.length - 1 > 0 && (
            <CellBottomGroupPart count={value.length - 1} />
          )}
        </>
      );
    case 'fullName':
      return (
        <>
          <span className="flex items-center">
            {value}{' '}
            {seePeopleDetailsHasPermission && (
              <Link
                className="ml-2 mt-[3px] cursor-pointer"
                to={`/accounts/accounts/account/${data[index]['id']}`}
              >
                <CircleRightArrow size="4" />
              </Link>
            )}
          </span>
          {!isMobile && <CellBottomPart data={data[index]['emailAddress']} />}
        </>
      );
    case 'location':
      return (
        <>
          <span>{value ? value.name : 'Unassigned'}</span>
        </>
      );
    case 'userSince':
      return (
        <>
          <span>{formatDate(value)}</span>
        </>
      );
  }
};
