import React from 'react';
import { IconProps } from './types';

export const ArrowRightIcon: React.FC<IconProps> = ({
  size = '5',
  color = 'text-white',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L5 5L1 9"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
