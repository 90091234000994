import { Link } from 'react-router-dom';
import {
  CheckCircleIcon,
  CircleRightArrow,
  WarningCircleIcon,
} from '@components/ui/Icons';
import useModalStore from '@store/modal';

export const createCellValue = (property: string, data: any, index: number) => {
  const value = data[index][property];
  const modalStore = useModalStore.getState();

  const handleOnClick = () => {
    modalStore.setIsDetailsModalOpen(true);
    modalStore.setDataDetailsModal({
      header: (
        <div className="flex flex-col gap-y-2">
          <div
            className={`permisssion-level-badge mb-1 flex h-[32px] w-fit items-center px-[14px] py-0 text-16 leading-[32px] permission-level-${data[index].permissionLevel}`}
          >
            <span>{data[index].permissionLevel} access</span>
          </div>
          <span className="text-36 text-interfaceColor-100">
            {data[index].applicationName || '-'}
          </span>
          <div className="flex flex-row flex-wrap items-center gap-x-4 gap-y-0">
            <Link
              target="_blank"
              to={data[index]['appUrl']}
              className="text-16 text-interfaceColor-100 underline"
            >
              {data[index]['appUrl']}
            </Link>
            <span className="h-4 w-[1px] bg-interfaceColor-40"></span>

            <span className="text-16 text-interfaceColor-100">
              {data[index].publisherName || '-'}
            </span>
            <span>
              {data[index]['publisherVerification'] === 'Verified' ? (
                <div className="flex items-center gap-2">
                  <CheckCircleIcon
                    color="text-primaryBrandColor-150"
                    size="4"
                  />
                  <span className="text-16 text-interfaceColor-80">
                    Verified publisher
                  </span>
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  <WarningCircleIcon
                    color="text-warningColor-150"
                    classNames="h-4 w-4 mr-1"
                  ></WarningCircleIcon>
                  <span className="text-16 text-interfaceColor-80">
                    Unverified publisher
                  </span>
                </div>
              )}
            </span>
          </div>
        </div>
      ),
      body: (
        <div className="flex flex-col">
          <span className="mb-3 text-20 text-interfaceColor-100">
            This app has {data[index].permissions.length} permissions:
          </span>
          <div className="flex max-h-72 flex-row flex-wrap items-center gap-2 overflow-y-auto">
            {data[index].permissions.map((permission: any, index: number) => (
              <span
                key={index}
                className="bg-interfaceColor-15 px-3 py-2 text-16"
              >
                {permission}
              </span>
            ))}
          </div>
        </div>
      ),
    });
  };

  switch (property) {
    case 'applicationName':
      return (
        <>
          <div className="flex items-center gap-2">
            <span>{value || 'No data available'}</span>{' '}
            <span className="cursor-pointer" onClick={handleOnClick}>
              <CircleRightArrow size="4" classNames="mt-[2px]" />
            </span>
          </div>
          <div className="flex flex-col">
            <Link
              target="_blank"
              to={data[index]['appUrl']}
              className="text-14 tracking-wide text-interfaceColor-80"
            >
              {data[index]['appUrl']}
            </Link>
          </div>
        </>
      );
    case 'publisherName':
      return (
        <div className="flex flex-col">
          <span>{value || 'No data available'}</span>
          <div className="flex">
            {data[index]['publisherVerification'] === 'Verified' ? (
              <div className="flex items-center gap-2">
                <CheckCircleIcon color="text-primaryBrandColor-150" size="4" />
                <span>Verified</span>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <WarningCircleIcon
                  color="text-warningColor-150"
                  classNames="h-4 w-4 mr-1"
                ></WarningCircleIcon>
                <span>Unverified</span>
              </div>
            )}
          </div>
        </div>
      );
    case 'permissionLevel':
      return (
        <div className={`permisssion-level-badge permission-level-${value}`}>
          <span>{value}</span>
        </div>
      );
    case 'accessGranted':
      return (
        <>
          <span>{new Date(value || '')?.toLocaleDateString() || ''}</span>
        </>
      );
  }
};
