import { API_ENDPOINTS } from '@api/ApiEndpoints';
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query';
import {
  OnboardingModalStatus,
  SubscriptionSettings,
  SubscriptionSettingsResponse,
  UpdateRoleRequest,
  UseUpdateRoleOptions,
  UseUsersQueryOptions,
  User,
  UserProfileResponse,
  UserRequest,
  UsersResponse,
} from './types';
import { useHttpClient } from '@hooks/utils/httpConfig';
import useAuthStore from '@store/authStore';

export function useUsersQuery({
  pageSize = 20,
  pageNumber = 1,
}: UseUsersQueryOptions = {}): UseQueryResult<UsersResponse> {
  const usersClient = useHttpClient();
  const fetchMembers = async (): Promise<UsersResponse> => {
    const params = {
      pageSize: pageSize,
      pageNumber: pageNumber,
    };
    return usersClient.get<UsersResponse>(`${API_ENDPOINTS.USERS}`, params);
  };

  return useQuery(['hubUsers', pageSize, pageNumber], fetchMembers);
}

export const useUpdateUserRole = (
  userId: string | undefined,
  options?: UseUpdateRoleOptions
): UseMutationResult<unknown, unknown, UpdateRoleRequest> => {
  const usersClient = useHttpClient();
  return useMutation(
    (data: UpdateRoleRequest) =>
      usersClient.patch(`${API_ENDPOINTS.USERS}/${userId}/role`, data),
    options
  );
};

export const useLockUser = (userId: string | undefined) => {
  const usersClient = useHttpClient();
  return useMutation(() =>
    usersClient.patch(`${API_ENDPOINTS.USERS}/${userId}/lock`, null)
  );
};

export const useUnlockUser = (userId: string | undefined) => {
  const usersClient = useHttpClient();
  return useMutation(() =>
    usersClient.patch(`${API_ENDPOINTS.USERS}/${userId}/unlock`, null)
  );
};

export const useDeleteUser = (userId: string | undefined) => {
  const usersClient = useHttpClient();
  return useMutation(() =>
    usersClient.delete(`${API_ENDPOINTS.USERS}/${userId}`)
  );
};

export const useCreateUser = () => {
  const usersClient = useHttpClient();
  return useMutation((data: Partial<User>) =>
    usersClient.post(`${API_ENDPOINTS.USERS}`, {
      ...data,
    })
  );
};

export const useUpdateUser = (userId: string | undefined) => {
  const usersClient = useHttpClient();
  return useMutation((data: UserRequest) =>
    usersClient.patch(`${API_ENDPOINTS.USERS}/${userId}/`, data)
  );
};

export const useUpdateModalStatus = () => {
  const usersClient = useHttpClient();
  return useMutation((data: OnboardingModalStatus) =>
    usersClient.patch(`${API_ENDPOINTS.UPDATE_USER_ONBOARDING_MODAL}`, data)
  );
};

export function useUserOnboardingStatusModalsQuery(
  manual = false
): UseQueryResult<OnboardingModalStatus> {
  const usersClient = useHttpClient();
  const fetchUserOnboardingStatusModals =
    async (): Promise<OnboardingModalStatus> => {
      return usersClient.get<OnboardingModalStatus>(
        `${API_ENDPOINTS.USER_ONBOARDING_MODAL}`
      );
    };

  return useQuery(
    ['userOnboardingProgressModals'],
    fetchUserOnboardingStatusModals,
    {
      enabled: !manual,
    }
  );
}

export function useCurrentUserQuery(): UseQueryResult<User> {
  const usersClient = useHttpClient();
  const fetchUser = async (): Promise<User> => {
    return usersClient.get<User>(API_ENDPOINTS.CURRENT_USER);
  };

  return useQuery(['currentUser'], fetchUser);
}

export function useSubscriptionSettings(): UseQueryResult<SubscriptionSettingsResponse> {
  const usersClient = useHttpClient();
  const fetchSubscriptionSettings =
    async (): Promise<SubscriptionSettingsResponse> => {
      return usersClient.get<SubscriptionSettingsResponse>(
        API_ENDPOINTS.SUBSCRIPTION_SETTINGS
      );
    };

  return useQuery(['subscriptionSettings'], fetchSubscriptionSettings);
}

export const useSubscriptionSettingsUpdate = (): UseMutationResult<
  SubscriptionSettingsResponse,
  unknown,
  SubscriptionSettings
> => {
  const usersClient = useHttpClient();
  return useMutation((data: SubscriptionSettings) =>
    usersClient.patch<SubscriptionSettingsResponse>(
      API_ENDPOINTS.SUBSCRIPTION_SETTINGS,
      {
        ...data,
      }
    )
  );
};

export function useUserProfile(): UseQueryResult<UserProfileResponse> {
  const usersClient = useHttpClient();
  const authStore = useAuthStore();
  const fetchUserProfile = async (): Promise<UserProfileResponse> => {
    return usersClient.get<UserProfileResponse>(
      API_ENDPOINTS.USER_ONBOARDING_PROFILE
    );
  };

  return useQuery(['userOnboardingProfile'], fetchUserProfile, {
    onSuccess: (response: UserProfileResponse) => {
      if (response !== null) {
        authStore.setHasSelectedProfile(response.data);
      }
    },
  });
}

export const useResetPasswordQuery = (): UseMutationResult<
  unknown,
  unknown,
  void,
  unknown
> => {
  const groupsClient = useHttpClient();
  const API_ENDPOINT = `${API_ENDPOINTS.RESET_USER_PASSWORD}`;

  return useMutation(() => groupsClient.get<any>(API_ENDPOINT));
};
export const useCheckTermsConditions = () => {
  const authStore = useAuthStore();
  const client = useHttpClient();

  return useMutation<boolean, Error, void>(
    async () => {
      const response = await client.get<boolean>(
        API_ENDPOINTS.TERMS_CONDITIONS_AGREE
      );
      return response;
    },
    {
      onSuccess: async () => {
        try {
          const hasGranted = await client.get<boolean>(
            `${API_ENDPOINTS.TERMS_CONDITIONS_HAS_GRANTED}`
          );
          authStore.setTermsAndConditions(hasGranted);
        } catch (error) {
          console.error('Failed to fetch terms and conditions status:', error);
        }
      },
      onError: (error) => {
        console.error('Error agreeing to terms and conditions:', error);
      },
    }
  );
};
