import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import {
  useAddDashboardTile,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useNoLicencesSnapshotQuery } from '@hooks/security';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';

export const UsersWithSignInEnabledButNoLicenses: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.UsersWithSignInEnabledButNoLicenses,
    ''
  );
  const isOnDashboardPage = !!id;

  const { data: signInEnabledData, isLoading: isSignInEnabledLoading } =
    useNoLicencesSnapshotQuery(JSON.parse(params));

  return (
    <NumberTile
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
      percentageLabel=""
      dropdownMenuItemClasses={'!w-[300px]'}
      headerTitle={'Users with sign-in enabled but no licenses'}
      isLoading={isSignInEnabledLoading}
      data={signInEnabledData ? Object.values(signInEnabledData) : []}
      buttonLabel={'Unassigned user data'}
      linkPath={ANALYTICS_NAVIGATION_MAP['signInEnabled']}
      handleButtonClick={handleButtonClick()}
    />
  );
};
