import React from 'react';
import { IconProps } from './types';

export const WarningCircleIcon: React.FC<IconProps> = ({
  size = '18',
  color = 'text-infoColor-150',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      className={`w-${size} h-${size} ${classNames}`}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99998 16.4286C13.1027 16.4286 16.4286 13.1027 16.4286 9.00001C16.4286 4.89733 13.1027 1.57144 8.99998 1.57144C4.8973 1.57144 1.57141 4.89733 1.57141 9.00001C1.57141 13.1027 4.8973 16.4286 8.99998 16.4286Z"
        className={`stroke-current ${color}`}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 8.5L9 12.5"
        className={`stroke-current ${color}`}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="9" cy="6" r="1" className={`fill-current ${color}`} />
    </svg>
  );
};
