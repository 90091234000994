import {
  DEFAULT_PAGE_SIZE,
  LICENSE_ASSIGNMENT_SORT_BY,
  LICENSE_ASSIGNMENT_TABLE_COLUMNS,
  VENDORS,
} from '@common/constants';
import { ExtendedOption, IOption, OrderBy } from '@common/types';
import { TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { useLicencesFiltersQuery } from '@hooks/licenses';
import {
  LicensesFilterData,
  LicensesFiltersResponse,
} from '@hooks/licenses/types';
import { PeopleSortBy } from '@hooks/people/types';
import { usePagination } from '@hooks/utils/pagination';
import { getFilterValues, formatDate, useTableData } from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { createCellValue } from './utils';
import { useLicensesInventoryAssignmentPreviewQuery } from '@hooks/utilisation';
import { Banner } from '@components/ui';
import { useLocationsQuery } from '@hooks/locations';
import { useGroupsFilterQuery } from '@hooks/groups';
import { Location } from '@hooks/locations/types';
import { GroupsResponse } from '@hooks/groups/types';
import styles from './styles.module.css';
import { useIsDesktop } from '@hooks/utils';
import { RecommendationCard } from '@pages/Dashboard/tiles/helpers/RecommendationCard';
import useDownloadCsv from '@hooks/utils/export';
import { API_ENDPOINTS } from '@api/ApiEndpoints';

const pageSize = DEFAULT_PAGE_SIZE;

const LicenseAssignmentPage: React.FC = () => {
  const isDesktop = useIsDesktop();
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, 'expirydate'),
    order: withDefault(StringParam, 'asc'),
    filter: withDefault(StringParam, ''),
  });
  const { data: filterData, isFetched: filterDataFetched } =
    useLicencesFiltersQuery(
      'true',
      queryParams.filter.includes('freeLicenses')
    );
  const { data: locationsData, isFetched: locationDataFetched } =
    useLocationsQuery();
  const { data: groupsData, isFetched: groupsDataFetched } =
    useGroupsFilterQuery();

  const allLocationIds: string[] = [];
  const groupsIDs: string[] = [];
  const locationFilerOptions: IOption[] = [];
  const groupFilerOptions: ExtendedOption[] = [];
  const transformedFilterData: FilterData[] = [];

  // License inventory - table

  const query = useLicensesInventoryAssignmentPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    locationList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => locationIds.includes(filter))
      : [],
    groupList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => groupIds.includes(filter))
      : [],
    includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
    licenseTypeList: getFilterValues(
      queryParams,
      (filterData?.licenseType?.map((item) => item.value) || []).filter(
        (item): item is string => item !== undefined
      )
    ),
    vendorList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => VENDORS.includes(filter as any))
      : [],
  });
  const getTableData = useTableData(
    query,
    LICENSE_ASSIGNMENT_TABLE_COLUMNS,
    createCellValue
  );

  const propertyLabels: Record<string, string> = {
    vendor: 'Vendor',
    licensetype: 'License Type',
  };

  const getPropertyLabel = (property: string): string => {
    return propertyLabels[property.toLowerCase()] || property;
  };

  if (filterDataFetched && locationDataFetched && groupsDataFetched) {
    if (filterData) {
      Object.keys(filterData).forEach((property) => {
        const filterDataArray: FilterData = {
          label: getPropertyLabel(property),
          name: property.toLowerCase(),
          options: [],
          singleSelect: !!(property === 'vendor'),
        };

        const propertyData =
          filterData[property as keyof LicensesFiltersResponse];

        const items = propertyData as unknown as LicensesFilterData[];

        items.forEach((item) => {
          if (item.vendor === "RingCentral") return;
          const option = {
            label: item.value || item.vendor,
            value: item.value || item.vendor,
          };

          filterDataArray.options.push(option);
        });
        
        transformedFilterData.push(filterDataArray);
      });

      transformedFilterData.push({
        label: 'Free licences',
        name: 'freeLicenses',
        singleSelect: true,
        options: [{ label: 'Show free licenses', value: 'freeLicenses' }],
      });
    }

    if (locationsData) {
      locationsData?.forEach((location: Location) => {
        locationFilerOptions.push({ label: location.name, value: location.id });
        allLocationIds.push(location.id);
      });

      transformedFilterData.push({
        label: 'Location',
        name: 'location',
        singleSelect: false,
        options: locationFilerOptions,
      });
    }

    if (groupsData) {
      groupsData?.forEach((group: GroupsResponse) => {
        groupFilerOptions.push({
          name: group.name,
          value: group.id,
          parentId: group.parentId,
          subGroups: group.subGroups,
          id: group.id,
          label: group.name,
        });
      });

      transformedFilterData.push({
        label: 'Groups',
        name: 'groups',
        singleSelect: false,
        options: groupFilerOptions,
      });
    }
  }

  useEffect(() => {
    locationsData?.forEach((location: Location) => {
      locationFilerOptions.push({ label: location.name, value: location.id });
      allLocationIds.push(location.id);
    });
    setLocationIds(allLocationIds);
  }, [locationsData]);

  useEffect(() => {
    const nested = (element: any) => {
      element.subGroups.forEach((subElement: any) => {
        groupsIDs.push(subElement.id);
        if (subElement.subGroups && subElement.subGroups.length > 0) {
          nested(subElement);
        }
      });
    };

    groupsData?.forEach((element: any) => {
      groupsIDs.push(element.id);
      if (element.subGroups && element.subGroups.length > 0) {
        nested(element);
      }
    });

    setGroupIds(groupsIDs);
  }, [groupsData]);

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.LICENSE_INVENTORY_ASSIGNMENT_PREVIEW}/export`,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      locationList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => locationIds.includes(filter))
        : [],
      groupList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => groupIds.includes(filter))
        : [],
      includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
      vendorList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => VENDORS.includes(filter as any))
        : [],
      licenseTypeList: getFilterValues(
        queryParams,
        (filterData?.licenseType?.map((item) => item.value) || []).filter(
          (item): item is string => item !== undefined
        )
      ),
    },
    filename: `Clevr360_License inventory_License_assignment_${formatDate(
      new Date().toString()
    )}.csv`,
  });
  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <>
      <div className={styles.pageGrid}>
        {isDesktop && (
          <RecommendationCard insightDescription={'license inventory'} />
        )}
      </div>
        <Banner
          variant="info"
          message="RingCentral’s technology doesn’t currently allow us to access to this data. We’re working on it."
          className="mb-6 flex-grow"
          title="This table doesn’t include RingCentral licenses"
        />
      <TableWrapper
        search={search}
        setSearch={setSearch}
        searchPlaceholder={'Search by license type, name, email address'}
        columns={LICENSE_ASSIGNMENT_TABLE_COLUMNS}
        data={getTableData()}
        sortData={LICENSE_ASSIGNMENT_SORT_BY}
        filterData={transformedFilterData}
        searchKey="search"
        query={query}
        refetchQuery={query.refetch}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        setPageNumber={setPageNumber}
        isCollapsable={true}
        sliceColumns={2}
        floatingFilterButton={true}
        floatingFilterButtonTop={'155px'}
        hasFilterBanner={false}
        isLicensePage={true}
        searchCountStatsLabel="licenses"
        filterHeaderText="Filter license assignment"
        isDownloadAvailable={true}
        handleDownload={handleDownloadClick}
      />
    </>
  );
};

export default LicenseAssignmentPage;
