import { DashboardTiles } from '@common/constants';
import {
  ExternalTeamsAccess,
  ExternalTeamsAccessProps,
} from './ExternalTeamsAccess';

export const ExternalTeamsAccessSnapshot: React.FC<
  ExternalTeamsAccessProps
> = ({ params, id, classNames }) => {
  return (
    <ExternalTeamsAccess
      params={params}
      id={id}
      classNames={classNames}
      isSnapshot={true}
      type={DashboardTiles.ExternalTeamsAccessSnapshot}
    />
  );
};
