import { formatDate } from '@utils/index';

export const createCellValue = (property: string, data: any, index: number) => {
  const value = data[index][property];

  switch (property) {
    case 'assignedTo':
      return (
        <>
          <span>{value || 'Unassigned'}</span>
        </>
      );
    case 'assignmentDate':
      return (
        <>
          <span>{formatDate(value)}</span>
        </>
      );
    case 'expiryDate':
      return (
        <>
          <span>{formatDate(value)}</span>
        </>
      );
    case 'lastUsed':
      return (
        <>
          <span>{formatDate(value)}</span>
        </>
      );
      case 'vendor':
        return (
          <>
            <span>{value ?? 'No data available'}</span>
          </>
        );
  }
};
