import { DashboardTiles } from '@common/constants';
import {
  GroupsTeamsInventory,
  GroupsTeamsInventoryProps,
} from './GroupsTeamsInventory';

export const GroupsTeamsInventorySnapshot: React.FC<
  GroupsTeamsInventoryProps
> = ({ params, id, classNames }) => {
  return (
    <GroupsTeamsInventory
      params={params}
      id={id}
      classNames={classNames}
      isSnapshot={true}
      type={DashboardTiles.GroupsTeamsInventorySnapshot}
    />
  );
};
