import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useLicencesExpiredSnapshotQuery } from '@hooks/licenses';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { useState } from 'react';

type Params = {
  vendorList: string[];
};

export const ExpiredAndDisabledLicenses: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.ExpiredAndDisabledLicenses,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: licensesExpiredData,
    isLoading,
    refetch,
  } = useLicencesExpiredSnapshotQuery(parameters);

  const handleDropdown = (value: string[]) => {
    setParameters({
      vendorList: value,
    });

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify({
          vendorList: value,
        }),
        tileId: id,
      });

      refetch();
    }
  };

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All Vendors',
      value: 'All',
      action: () => handleDropdown(['Microsoft', 'RingCentral']),
    },
    {
      id: '2',
      label: 'Microsoft',
      value: 'Microsoft',
      action: () => handleDropdown(['Microsoft']),
    },
    {
      id: '3',
      label: 'RingCentral',
      value: 'RingCentral',
      action: () => handleDropdown(['RingCentral']),
    },
  ];

  return (
    parameters && (
      <NumberTile
        classNames={
          classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
        }
        initialSelectedItem={sortMenuItems.find(
          (item) =>
            item.value ===
            (parameters.vendorList.length > 1
              ? 'All'
              : parameters.vendorList[0])
        )}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        sortData={sortMenuItems}
        headerTitle={'Expired & disabled licenses'}
        dropdownMenuItemClasses={'!w-[300px]'}
        isLoading={isLoading}
        data={licensesExpiredData ? Object.values(licensesExpiredData) : []}
        buttonLabel={'Expired license data'}
        linkPath={ANALYTICS_NAVIGATION_MAP['expired']}
        handleButtonClick={handleButtonClick()}
      />
    )
  );
};
