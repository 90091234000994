import * as React from 'react';
import { IconProps } from './types';

export const UsersLocationMoveIcon: React.FC<IconProps> = ({
  size = 6,
  color = 'text-interfaceColor-100',
  classNames = '',
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={`stroke-current w-${size} h-${size} ${color} ${classNames}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1671_268)">
        <path
          d="M9.40299 9.99349C10.8412 9.99349 12.0072 8.82757 12.0072 7.38933C12.0072 5.95108 10.8412 4.78516 9.40299 4.78516C7.96475 4.78516 6.79883 5.95108 6.79883 7.38933C6.79883 8.82757 7.96475 9.99349 9.40299 9.99349Z"
          stroke="#282B2F"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1434 17.2859H4.71484V16.7211C4.72314 15.9271 4.93253 15.1482 5.32346 14.4571C5.7144 13.766 6.27411 13.1853 6.95034 12.7692C7.62656 12.353 8.39722 12.1151 9.19034 12.0775C9.26106 12.0742 9.33174 12.0724 9.40234 12.0723C9.47295 12.0724 9.54363 12.0742 9.61434 12.0775C10.4075 12.1151 11.1781 12.353 11.8544 12.7692"
          stroke="#282B2F"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.918 16.3926H18.7157"
          stroke="#282B2F"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5371 18.57L18.7156 16.3915L16.5371 14.2129"
          stroke="#282B2F"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1671_268">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
