import * as React from 'react';
import { IconProps } from './types';

export const CloudClevrLogoIcon: React.FC<IconProps> = ({
  color = 'text-primaryBrandColor-100',
  classNames = '',
  size = 4,
}) => {
  return (
    <svg
      className={`fill-current w-${size} h-${size} ${color} ${classNames}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 18"
      fill="none"
    >
      <path
        fill={color}
        d="m4.823 12.77 4.367 3.071-4.174 2.16L.648 14.93V9.6l4.175-2.16v5.33ZM9.178 5.23 4.81 2.16 8.985 0l4.367 3.07V8.4l-4.175 2.16V5.23Z"
      />
    </svg>
  );
};
