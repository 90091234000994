import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { useAddDashboardTile, useRemoveDashboardTile } from '@hooks/dashboard';
import { useExternalUsersAccessQuery } from '@hooks/security';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';

export const TeamsWithExternalAccess: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.TeamsWithExternalAccess,
    ''
  );
  const isOnDashboardPage = !!id;

  const { data: externalUsersData, isLoading: isExternalUsersLoading } =
    useExternalUsersAccessQuery();

  return (
    <DoughnutChartTile
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      sortData={[]}
      headerTitle={'Teams with external access'}
      dropdownMenuItemClasses={'!w-[300px]'}
      isLoading={isExternalUsersLoading}
      data={
        externalUsersData
          ? [
              externalUsersData?.teamsWithExternalAccess,
              externalUsersData?.teamsWithoutExternalAccess,
            ]
          : []
      }
      dataLabels={[
        `${
          externalUsersData && externalUsersData?.teamsWithExternalAccess
            ? externalUsersData.teamsWithExternalAccess
            : 0
        } teams with external access`,
        `${
          externalUsersData && externalUsersData?.teamsWithoutExternalAccess
            ? externalUsersData.teamsWithoutExternalAccess
            : 0
        } teams without external access`,
      ]}
      showBracketLabelValue={false}
    />
  );
};
