import * as React from 'react';
import { IconProps } from './types';

export const BellIcon: React.FC<IconProps> = ({
  color = 'white',
  classNames = '',
  size = 4,
}) => {
  return (
    <svg
      className={`stroke-current w-${size} h-${size} ${color} ${classNames}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 18"
      fill="none"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.857 16.43h2.286"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.077 6.648a5.077 5.077 0 1 0-10.154 0v5.043c0 .505-.201.99-.558 1.346-.357.358-.717.558-1.222.558h13.714c-.505 0-.865-.2-1.222-.558a1.904 1.904 0 0 1-.558-1.346V6.648Z"
      />
    </svg>
  );
};
