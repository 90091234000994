import {
    CellBottomGroupPart,
    CellBottomPart,
} from '@components/ui/Table/utils';
import { UserRoles, Permissions } from '@hooks/users/types';
import useAuthStore from '@store/authStore';
import { hasPermission } from '@utils/index';

import { Link } from 'react-router-dom';
import {
    CircleRightArrow,
} from '@components/ui/Icons';
import useModalStore from '@store/modal';
import { Table } from '@components/ui';
import { MAILBOX_DELEGATES_MODAL_COLUMNS } from '@common/constants';

export const createCellValue = (
    property: string,
    data: any,
    index: number,
    isMobile: boolean,
) => {
    const value = data[index][property];
    const userRole = useAuthStore.getState().role || UserRoles.STANDARD;
    const modalStore = useModalStore.getState();
    const mailboxData = data[index]['accessibleMailboxes'] || [];
    const accountName = data[index]['accountFullName'] || "";

    const seeDetailsHasPermission = hasPermission(
        userRole,
        Permissions.ADMINS_SUPPORTUSER
    );

    const handleOnClick = () => {
        modalStore.setIsDetailsModalOpen(true);
        modalStore.setDataDetailsModal({
            header: (
                <div className="flex flex-col gap-y-2 break-words">
                    <span className="text-36 text-interfaceColor-100">
                        Mailboxes accessible by:
                    </span>
                    <span className="text-36 text-interfaceColor-100">
                        {accountName}
                    </span>
                </div>
            ),
            body: (
                <div className={'overflow-auto min-h-[280px] max-h-[400px] border border-interfaceColor-20 px-4 py-1'}>
                    <ul className="flex flex-col items-start text-16 leading-7">
                        {mailboxData.map((data: string) => (
                            <li className="mt-3 w-full" key={data}>
                                {data}
                            </li>
                        ))}
                    </ul>
                </div>
            ),
        });
    };


    switch (property) {
        case 'accountFullName':
            return (
                <>
                    <span className="flex items-center">
                        {value}{' '}
                        {seeDetailsHasPermission && (
                            <Link
                                className="ml-2 mt-[3px] cursor-pointer"
                                to={`/accounts/accounts/account/${data[index]['accountId']}`}
                            >
                                <CircleRightArrow size="4" classNames="mt-[0px]" />
                            </Link>
                        )}
                    </span>
                    {!isMobile && (
                        <CellBottomPart data={data[index]['accountEmailAddress']} />
                    )}
                </>
            );
        case 'accessibleMailboxes': {
            return (
                <div className="flex flex-row items-center gap-[5px]">
                    <div>
                        {
                            mailboxData.length > 1 ? `${mailboxData.length} mailboxes` : mailboxData[0]}

                    </div>
                    {mailboxData.length > 1 && <div className="flex items-center gap-2">
                        <span onClick={handleOnClick}>
                            <CircleRightArrow size="4" classNames="mt-[2px] cursor-pointer" />
                        </span>
                    </div>}
                </div>
            );
        }
        case 'locationName':
            return (
                <>
                    <span>{value ?? 'Unassigned'}</span>
                </>
            );

        case 'groups':
            return (
                <>
                    <span>{value?.[0] ?? 'Unassigned'}</span>
                    {data[index]['groups'].length - 1 > 0 && (
                        <CellBottomGroupPart count={data[index]['groups'].length - 1} />
                    )}
                </>
            );
    }
};
