import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export type BaseQueryParams = {
  [key: string]: string;
};

export type BaseSetQueryParams = (key: string, value: string) => void;

type PeopleQueryParams = BaseQueryParams & {
  search: string;
  sortBy: string;
  order: string;
  filter: string;
};

type QueryParamsStore = {
  peopleQueryParams: PeopleQueryParams;
};

type QueryParamsStoreActions = {
  setPeopleQueryParamsStore: (key: string, value: string) => void;
};

const useQueryParamsStore = create(
  devtools(
    persist<QueryParamsStore & QueryParamsStoreActions>(
      (set) => ({
        peopleQueryParams: {
          search: '',
          sortBy: '',
          order: '',
          filter: '',
        },
        setPeopleQueryParamsStore: (key, value) =>
          set((state) => ({
            peopleQueryParams: { ...state.peopleQueryParams, [key]: value },
          })),
      }),
      { name: 'query-params' }
    ),
    { enabled: false }
  )
);

export default useQueryParamsStore;
