import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import {
  useAddDashboardTile,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useMailboxesForwardingRulesSnapshotQuery } from '@hooks/security';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';

export const MailboxesForwardingRules: React.FC<TilesProps> = ({
  classNames,
  id,
  isSnapshot
}) => {
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.MailboxesForwardingRules,
    ''
  );
  const isOnDashboardPage = !!id;
  const { data, isLoading } = useMailboxesForwardingRulesSnapshotQuery();

  return (
    <DoughnutChartTile
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      contextData={!isSnapshot ? 
        (isOnDashboardPage ? removeMenuItem : addMenuItem) : []
      }
      sortData={[]}
      headerTitle={'Mailboxes with auto-forwarding rules'}
      dropdownMenuItemClasses={'!w-[300px]'}
      isLoading={isLoading}
      data={data ? [data.snapshot.Enabled, data?.snapshot.Disabled] : []}
      dataLabels={[
        `Auto-forwarding rules enabled`,
        `Auto-forwarding rules disabled`,
      ]}
      buttonLabel={!isSnapshot ? 'Mailbox forwarding data' : ''}
      linkPath={ANALYTICS_NAVIGATION_MAP['mailboxForwardingData']}
      handleButtonClick={!isSnapshot ? handleButtonClick() : undefined}
    />
  );
};
