import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useLicencesActivityQuery } from '@hooks/licenses';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useState } from 'react';

type Params = {
  days: string;
};

export const LicenseActivity: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.LicenseActivity,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: licensActivityData,
    isLoading,
    refetch,
  } = useLicencesActivityQuery(parameters);

  const handleDropdown = (value: string) => {
    setParameters({
      days: value,
    });

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify({
          days: value,
        }),
        tileId: id,
      });

      refetch();
    }
  };

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 30 days',
      value: '30',
      action: () => handleDropdown('30'),
    },
    {
      id: '2',
      label: 'Last 60 days',
      value: '60',
      action: () => handleDropdown('60'),
    },
    {
      id: '3',
      label: 'Last 90 days',
      value: '90',
      action: () => handleDropdown('90'),
    },
  ];

  return (
    parameters && (
      <DoughnutChartTile
        dropdownMenuItemClasses={'!w-[300px]'}
        initialSelectedItem={sortMenuItems.find(
          (item) => item.value === parameters.days
        )}
        sortData={sortMenuItems}
        classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
        headerTitle={'License activity'}
        isLoading={isLoading}
        data={licensActivityData ? Object.values(licensActivityData) : []}
        dataLabels={['Inactive licenses', 'Active licenses']}
        buttonLabel={'Inactive license data'}
        linkPath={ANALYTICS_NAVIGATION_MAP['activity']}
        handleButtonClick={handleButtonClick()}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      />
    )
  );
};
