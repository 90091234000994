import useUIStore from '@store/uiStore';
import { HttpClient } from '@api/HttpClient';
import { Variant } from '@components/ui/Toast';
import { HTTP_CONFIG_API_URL } from '@common/constants';

export const useHttpClient = () => {
  const { addToast } = useUIStore();

  const showToast = (message: string, variant: Variant) => {
    addToast({
      variant: variant,
      message: message,
      closeable: true,
    });
  };

  const httpClient = new HttpClient(
    HTTP_CONFIG_API_URL.organization,
    showToast
  );

  return httpClient;
};
