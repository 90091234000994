import { Button } from '@components/ui';
import { ArrowRightIcon } from '@components/ui/Icons';
import useUIStore from '@store/uiStore';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { GetInTouchModal } from './Modals/GetInTouchModal';

export const Footer: React.FC = () => {
  const isSidebarOpen = useUIStore((state) => state.isSidebarOpen);
  const [isGetInTouchModalOpen, setIsGetInTouchModalOpen] = useState(false);
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className="mt-10 flex w-full flex-col items-start overflow-x-hidden text-interfaceColor-100 md:mx-0 md:flex-row md:items-center md:justify-between lg:items-center">
        <span
          className={`${
            isSidebarOpen ? 'pl-6' : 'pl-4'
          } text-14 font-semibold md:ml-[-16px] lg:text-16`}
        >
          {'\u00A9'} {currentYear} CloudClevr Holdings Ltd.
        </span>
        <ul className="mb-3 flex flex-row gap-[24px] pl-4 text-16 leading-6 text-interfaceColor-100 md:mb-0">
          <li className="border-b-2 border-interfaceColor-100 pb-0">
            <Link to="/terms-and-conditions">Terms & conditions</Link>
          </li>
          <li className="border-b-2 border-interfaceColor-100 pb-0">
            <Link to="/privacy">Privacy</Link>
          </li>
        </ul>
        <Button
          className="mr-0 w-full !justify-start rounded-none !text-16 !leading-6 md:ml-auto md:mr-[-16px] md:w-[168px] md:justify-center"
          variant="dark"
          onClick={() => setIsGetInTouchModalOpen(true)}
        >
          <div className="flex items-center justify-between">
            Get in touch <ArrowRightIcon classNames="ml-2" size="3" />
          </div>
        </Button>
      </footer>

      <GetInTouchModal
        isModalOpen={isGetInTouchModalOpen}
        handleModalClose={() => setIsGetInTouchModalOpen(false)}
      />
    </>
  );
};
