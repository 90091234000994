import { CellAction, CellBottomPart } from '@components/ui/Table/utils';
import { formatNumberWithSuffix, formatSeconds } from '@utils/index';

export const useCreateCellValue = (
  property: string,
  data: any,
  index: number,
  isMobile: boolean
) => {
  const value = data[index][property];
  isMobile;
  switch (property) {
    case 'serviceName':
      return (
        <>
          {!isMobile && <span>{value || 'No data available'}</span>}

          {isMobile ? (
            <div>
              <span>{value || 'No data available'}</span>
              {data[index]['vendors'].length == 1 ? (
                <CellBottomPart
                  data={`${data[index]['vendors'][0]['vendor']}`}
                />
              ) : (
                <div className="flex flex-row items-center">
                  <CellBottomPart
                    data={`${data[index]['vendors'].length - 1} vendors`}
                  />
                </div>
              )}
            </div>
          ) : data[index]['vendors'].length == 1 ? (
            <CellBottomPart data={`${data[index]['vendors'][0]['vendor']}`} />
          ) : (
            <div className="flex flex-row items-center">
              <CellBottomPart
                data={`${data[index]['vendors'].length - 1} vendors`}
              />
              <CellAction index={index} isVideoFormat={value === 'Video'} />
            </div>
          )}
        </>
      );
    case 'adoptionRate':
      return (
        <>
          <span>
            {Number(data[index]['vendors'][0]['adoptionRate']).toFixed(2)}%
          </span>
          {!isMobile && (
            <CellBottomPart
              data={`${data[index]['vendors'][0]['usesUsersCount']} - ${data[index]['vendors'][0]['usersCount']} users`}
            />
          )}
        </>
      );
    case 'uses':
      return (
        <>
          <span>
            {data[index].serviceName !== 'Video'
              ? formatNumberWithSuffix(data[index]['vendors'][0]['uses'])
              : formatSeconds(data[index]['vendors'][0]['uses'])}
          </span>
        </>
      );

    case 'vendor':
      return (
        <>
          {isMobile &&
            data[index]['vendors'].length > 1 &&
            data[index]['vendors'].slice(1).map((item: any, indexItem: any) => {
              return (
                <div
                  key={indexItem}
                  className="my-2 border-none bg-interfaceColor-5 p-4"
                >
                  <div>
                    <span className="mb-2 font-bold">{item.vendor}</span>
                  </div>
                  <div>
                    <span>Adoption rate:</span> {item.adoptionRate.toFixed(2)}%
                  </div>
                  <div>
                    <span>Total uses:</span>{' '}
                    {data[index].serviceName === 'Video'
                      ? formatSeconds(item.uses)
                      : formatNumberWithSuffix(item.uses)}
                  </div>
                </div>
              );
            })}
        </>
      );
  }
};
