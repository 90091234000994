import { useEffect, useState } from 'react';
import {
  DashboardTiles,
  LICENSES_EXPIRING_BAR_LABELS,
} from '@common/constants';
import { TilesProps } from '@common/types';
import { useAddDashboardTile, useRemoveDashboardTile } from '@hooks/dashboard';
import { useLicencesExpiringSnapshotQuery } from '@hooks/licenses';
import { HorizontalBarChartTile } from '@pages/InsightsAndAnalytics/tiles/HorizontalChartTile';

export const LicensesExpiringSoonSnapshot: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.LicensesExpiringSoonSnapshot,
    ''
  );
  const isOnDashboardPage = !!id;
  const [licensesExpiringBarLabels, setLicensesExpiringBarLabels] = useState<
    string[]
  >([]);

  const { data: licencesExpiringData, isLoading: isLicencesExpiringLoading } =
    useLicencesExpiringSnapshotQuery(JSON.parse(params));

  useEffect(() => {
    if (licencesExpiringData) {
      setLicensesExpiringBarLabels(
        LICENSES_EXPIRING_BAR_LABELS.map((label, index) => {
          return `${label} ${licencesExpiringData.expirations[index].count}`;
        })
      );
    }
  }, [licencesExpiringData]);

  return (
    <HorizontalBarChartTile
      key={id}
      classNames={'col-span-3 lg:col-span-3'}
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      dropdownMenuItemClasses={'!w-[300px]'}
      headerTitle={'Licenses expiring soon: Snapshot'}
      isLoading={isLicencesExpiringLoading}
      data={licencesExpiringData?.expirations.map((item) => item.count) || []}
      dataLabels={licensesExpiringBarLabels}
      barBackgroundColors={[
        '#00CF6C',
        '#25A8D1',
        '#B65AD7',
        '#FFB800',
        '#54b534',
      ]}
    />
  );
};
