import {
  CellBottomPart,
} from '@components/ui/Table/utils';
import { CallForwardingDestinationsData } from '@hooks/security/types';

export const createCellValue = (
  property: keyof CallForwardingDestinationsData,
  data: CallForwardingDestinationsData[],
  index: number,
  isMobile: boolean
) => {
  const value = data[index][property];

  switch (property) {
    case 'vendor':
      return (
        <>
          <span>{value}</span>
        </>
      );
    case 'extensionNumber':
      return (
        <>
          <span className="flex items-center">
            {value}
          </span>
          {!isMobile && (
            <CellBottomPart data={data[index]['detail']} />
          )}
        </>
      );
    case 'always':
      return (
        <>
          <span className="flex items-center">
            {value || '-'}</span>

        </>
      );
    case 'busyInt':
      return (
        <>
          <span>{value || '-'}</span>
        </>
      );
    case 'busyExt':
      return (
        <>
          <span>{value || '-'}</span>
        </>
      );
    case 'noAnswerInt':
      return (
        <>
          <span>{value || '-'}</span>
        </>
      );
    case 'noAnswerExt':
      return (
        <>
          <span>{value || '-'}</span>
        </>
      );

  }
};
