import * as React from 'react';
import { IconProps } from './types';

export const AddUserIcon: React.FC<IconProps> = ({
  color = 'text-interfaceColor-100',
  classNames = '',
  size = 4,
}) => {
  return (
    <svg
      className={`stroke-current w-${size} h-${size} ${color} ${classNames}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M6.71429 7.28571C7.47205 7.28571 8.19878 6.98469 8.7346 6.44888C9.27041 5.91306 9.57143 5.18633 9.57143 4.42857C9.57143 3.67081 9.27041 2.94408 8.7346 2.40827C8.19878 1.87245 7.47205 1.57143 6.71429 1.57143C5.95653 1.57143 5.2298 1.87245 4.69399 2.40827C4.15817 2.94408 3.85715 3.67081 3.85715 4.42857C3.85715 5.18633 4.15817 5.91306 4.69399 6.44888C5.2298 6.98469 5.95653 7.28571 6.71429 7.28571Z"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.14286 15.2857H1.57143V14.6663C1.57875 13.9754 1.72492 13.293 2.00125 12.6597C2.27757 12.0264 2.67841 11.4551 3.17993 10.9798C3.68145 10.5045 4.27341 10.1349 4.92063 9.89295C5.56785 9.65101 6.25711 9.54167 6.94743 9.57143C7.68888 9.60662 8.41398 9.80154 9.07314 10.1429"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4286 10.7143L11.0457 16.0971L8.61142 16.4286L8.95428 13.9943L14.3257 8.61143L16.4286 10.7143Z"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
