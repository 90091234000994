import cn from 'classnames';
import React, { ButtonHTMLAttributes, forwardRef } from 'react';

export type ButtonVariant =
  | 'dark'
  | 'white'
  | 'primary'
  | 'danger'
  | 'dangerOutline'
  | 'successOutline'
  | 'inactiveOutline'
  | 'default'
  | 'outlineLight'
  | 'outlineDark';

export type ButtonSize = 'large' | 'medium' | 'small' | 'extraSmall';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  contentClassname?: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  active?: boolean;
  loading?: boolean;
  disabled?: boolean;
};

const classes = {
  root: 'inline-flex items-center rounded-[24px] text-20 justify-center font-normal tracking-[0.01em] flex-shrink-0 leading-6 disabled:bg-interfaceColor-20 disabled:text-interfaceColor-80 transition duration-300 ease-in-out focus:shadow-focus',
  primary:
    'bg-primaryBrandColor-100 text-interfaceColor-100 hover:bg-primaryBrandColor-150',
  dark: 'bg-interfaceColor-90 text-white hover:bg-interfaceColor-150 hover:text-interfaceColor-10',
  outlineLight:
    'border border-1 border-interfaceColor-150 bg-transparent text-interfaceColor-100 hover:bg-interfaceColor-20',
  outlineDark:
    'border border-1 border-primaryBrandColor-100 bg-interfaceColor-100 text-primaryBrandColor-100 hover:bg-interfaceColor-150 hover:text-primaryBrandColor-150',
  default:
    'bg-interfaceColor-10 text-interfaceColor-100 hover:bg-interfaceColor-20',
  danger:
    'bg-errorColor-100 text-white hover:bg-errorColor-150 hover:bg-errorColor-10',
  dangerOutline:
    'border border-1 border-errorColor-100 bg-transparent text-errorColor-100 hover:bg-errorColor-10 hover:text-errorColor-150',
  successOutline:
    'border border-1 border-successColor-100 bg-transparent text-successColor-100 hover:bg-successColor-10 hover:text-successColor-100',
  inactiveOutline:
    'border border-1 border-interfaceColor-80 bg-transparent text-successColor-80 hover:bg-interfaceColor-20 hover:text-interfaceColor-100',
  loading:
    'h-5 w-5 absolute animate-spin rounded-full border-[3px] border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]',
  extraSmall: 'py-[0.125rem] px-3 h-6 text-[14px]',
  small: 'py-1 px-3 text-[16px]',
  medium: 'py-2 px-4 h-10 text-[16px]',
  large: 'py-3 px-5 h-12 text-[20px]',
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      className,
      variant = 'primary',
      size = 'large',
      children,
      active,
      loading = false,
      disabled = false,
      contentClassname = "",
      ...rest
    } = props;

    const classesName = cn(
      classes.root,
      {
        [classes.primary]: variant === 'primary',
        [classes.dark]: variant === 'dark',
        [classes.outlineLight]: variant === 'outlineLight',
        [classes.outlineDark]: variant === 'outlineDark',
        [classes.default]: variant === 'default',
        [classes.danger]: variant === 'danger',
        [classes.dangerOutline]: variant === 'dangerOutline',
        [classes.successOutline]: variant === 'successOutline',
        [classes.inactiveOutline]: variant === 'inactiveOutline',
        [classes.extraSmall]: size === 'extraSmall',
        [classes.small]: size === 'small',
        [classes.medium]: size === 'medium',
        [classes.large]: size === 'large',
      },
      className
    );

    return (
      <button
        aria-pressed={active}
        data-variant={variant}
        ref={ref}
        className={classesName}
        disabled={disabled}
        {...rest}
      >
        <span
          className={cn('flex items-center', contentClassname, {
            ['opacity-0']: loading,
          })}
        >
          {children}
        </span>
        {loading && (
          <div
            className={cn(classes.loading, {
              ['border-t-interfaceColor-100']:
                variant === 'primary' ||
                variant === 'outlineLight' ||
                variant === 'default',
              ['border-t-errorColor-100']: variant === 'dangerOutline',
              ['border-b-white border-l-transparent border-r-white border-t-white']:
                variant === 'primary' ||
                variant === 'danger' ||
                variant === 'outlineDark' ||
                variant === 'dark',
            })}
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';
