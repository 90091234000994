import { WarningCircleIcon } from '@components/ui';
import {
  CellBottomGroupPart,
  CellBottomPart,
} from '@components/ui/Table/utils';
import { formatDate } from '@utils/index';

const lastUsed = (value: string | null, data: string) => {
  if (value) {
    const today = formatDate(new Date().toString());
    const formattedData = formatDate(data);
    const label = `Last collaborated ${
      today === formattedData ? 'today' : formattedData
    }`;
    return <CellBottomPart data={label} />;
  } else {
    return (
      <div className="flex flex-row items-center">
        <WarningCircleIcon
          color="text-errorColor-150"
          classNames="h-4 w-4 mr-1"
        ></WarningCircleIcon>
        <span className="text-14 text-interfaceColor-80">
          Never collaborated
        </span>
      </div>
    );
  }
};

const showIconAndText = (value: number) => {
  let msg = '';
  if (value <= 30) {
    msg = `Hasn’t collaborated in last ${value} ${value > 1 ? 'days' : 'day'} `;
  } else if (value >= 31 && value <= 60) {
    msg = `Hasn’t collaborated in last 30 days`;
  } else if (value >= 61 && value <= 90) {
    msg = `Hasn’t collaborated in last 60 days`;
  } else if (value >= 91) {
    msg = `Hasn’t collaborated in last 90 days`;
  }

  let iconColor = 'text-infoColor-150';
  if (value >= 30 && value < 90) {
    iconColor = 'text-warningColor-150';
  }
  if (value >= 90) {
    iconColor = 'text-errorColor-100';
  }

  return (
    <p className="flex items-center">
      <WarningCircleIcon color={iconColor} classNames="h-4 w-4 mr-1" />
      <span className="text-14 text-interfaceColor-80">{msg}</span>
    </p>
  );
};

export const createCellValue = (
  property: string,
  data: any,
  index: number,
  isMobile: boolean
) => {
  const value = data[index][property];

  switch (property) {
    case 'displayName':
      return (
        <>
          <span>{value}</span>
          {!isMobile && <CellBottomPart data={data[index]['emailAddress']} />}
        </>
      );
    case 'msTeamsMembershipCount':
      return (
        <>
          <span>{value ? value : 0}</span>
        </>
      );

    case 'lastCollaborated':
      return (
        <>
          <span>{formatDate(value)}</span>
          {data[index]['hasntCollaboratedInNDays'] > 0 &&
            showIconAndText(data[index]['hasntCollaboratedInNDays'])}
        </>
      );
    case 'groups':
      return (
        <>
          <span>{value?.[0] ?? 'Unassigned'}</span>
          {data[index]['groups'].length - 1 > 0 && (
            <CellBottomGroupPart count={data[index]['groups'].length - 1} />
          )}
        </>
      );
    case 'locationName':
      return (
        <>
          <span>{value ?? 'Unassigned'}</span>
        </>
      );
    // case 'rcMembers':
    //   return (
    //     <>
    //       <span>{value ? value : 0}</span>
    //       {lastUsed(value, data[index]['rcLastUsed'])}
    //     </>
    //   );
  }
};
