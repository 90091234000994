import { Button, Modal } from '@components/ui';
import { GroupsResponse } from '@hooks/groups/types';
import { User, UserRoles } from '@hooks/users/types';
import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import useAuthStore from '@store/authStore';
import { sortUsers, userItem } from './utils';

export type GroupViewer = {
  userId?: string;
  isViewerInherited?: boolean;
};

export type ViewGroupVisibilityModalProps = {
  group: GroupsResponse | undefined;
  usersData: User[] | [];
  isModalOpen: boolean;
  handleModalClose: () => void;
  handleSuccess: () => void;
};

export const ViewGroupVisibilityModal: React.FC<
  ViewGroupVisibilityModalProps
> = ({ group, usersData, isModalOpen, handleModalClose, handleSuccess }) => {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const [selectedTab, setSelectedTab] = useState(1);
  const [canSeeUsers, setCanSeeUsers] = useState<User[]>([]);
  const [cannotSeeUsers, setCannotSeeUsers] = useState<User[]>([]);
  const [isVisibleForAllUsers, setIsVisibleForAllUsers] = useState(false);
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;
  const loggedInUserId = useAuthStore.getState().user?.id || '';

  const canChangeVisibility = () => {
    if (userRole === UserRoles.SUPERADMIN || userRole === UserRoles.SUPPORT) {
      return true;
    }

    if (userRole === UserRoles.ADMIN) {
      const ids: string[] = [];
      if (group?.groupViewers) {
        group.groupViewers.forEach((user) => {
          ids.push(user.userId);
        });
      }

      return ids.includes(loggedInUserId);
    }
  };

  const handleVisibility = async () => {
    setSelectedTab(1);
    handleSuccess();
  };

  const handleClose = () => {
    setSelectedTab(1);
    handleModalClose();
  };

  useEffect(() => {
    const canSeeUsersId: string[] = [];
    const canSeeUsers: User[] = [];
    const cannotSeeUsers: User[] = [];

    group?.groupViewers?.forEach((gu) => {
      canSeeUsersId.push(gu.userId);
    });

    usersData.forEach((user: User) => {
      if (
        user.role === UserRoles.SUPERADMIN &&
        !canSeeUsersId.includes(user.id)
      ) {
        canSeeUsers.push(user);
      } else {
        if (!canSeeUsersId.includes(user.id)) {
          cannotSeeUsers.push(user);
        }
      }

      if (canSeeUsersId.includes(user.id)) {
        const isInheritedUser = group?.groupViewers?.find(
          (u: GroupViewer) => u.userId === user.id
        );
        user.isViewerInherited = isInheritedUser
          ? isInheritedUser.isViewerInherited
          : false;
        canSeeUsers.push(user);
      }
    });

    setCanSeeUsers(sortUsers(canSeeUsers, loggedInUserId));
    setCannotSeeUsers(sortUsers(cannotSeeUsers, loggedInUserId));
    setIsVisibleForAllUsers(canSeeUsers.length === usersData.length);
  }, [usersData, selectedTab, group]);

  return (
    <Modal
      headerTxt={
        isVisibleForAllUsers
          ? `All Hub users can see this group`
          : `${canSeeUsers.length} Hub user(s) can see this group`
      }
      modalClassName={'h-full mt-0'}
      isOpen={isModalOpen}
      size={isTablet || isMobile ? 'small' : 'medium'}
      position={isMobile ? 'bottom' : 'default'}
      showCloseBtn={true}
      onClose={handleClose}
      actionButtons={
        <div className="flex flex-col gap-2 lg:flex-row">
          {canChangeVisibility() && (
            <Button variant="primary" onClick={handleVisibility}>
              Manage visibility
            </Button>
          )}
          <Button variant="outlineLight" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      }
      contentClassName="flex !flex-row !items-start !justify-start bg-white"
    >
      <div className="w-full">
        {isVisibleForAllUsers && (
          <p className="mb-2">
            {`All ${
              usersData?.length || ''
            } Hub users in your organisation can see this group and its
            subgroups.`}
          </p>
        )}

        {isVisibleForAllUsers && (
          <div className="max-h-[400px] overflow-y-scroll rounded-lg border border-interfaceColor-40 p-2 md:max-h-[300px]">
            {canSeeUsers.map((user) => userItem(user))}
          </div>
        )}

        {!isVisibleForAllUsers && (
          <div className="flex">
            <p
              onClick={() => {
                setSelectedTab(1);
              }}
              className={cn(
                'mb-3 cursor-pointer pb-1',
                selectedTab === 1
                  ? 'border-b-2 border-primaryBrandColor-100 font-bold'
                  : ''
              )}
            >
              {`Can see group (${canSeeUsers.length})`}
            </p>
            <p
              onClick={() => {
                setSelectedTab(2);
              }}
              className={cn(
                'mb-3 ml-4 cursor-pointer pb-1',
                selectedTab === 2
                  ? 'border-b-2 border-primaryBrandColor-100 font-bold'
                  : ''
              )}
            >
              {`Can’t see group (${cannotSeeUsers.length})`}
            </p>
          </div>
        )}

        {!isVisibleForAllUsers &&
          selectedTab === 1 &&
          canSeeUsers.length > 0 && (
            <div className="max-h-[400px] overflow-y-scroll rounded-lg border border-interfaceColor-40 p-2 md:max-h-[300px]">
              {canSeeUsers.map((user) => userItem(user))}
            </div>
          )}

        {!isVisibleForAllUsers &&
          selectedTab === 2 &&
          cannotSeeUsers.length > 0 && (
            <div className="max-h-[400px] overflow-y-scroll rounded-lg border border-interfaceColor-40 p-2 md:max-h-[300px]">
              {cannotSeeUsers.map((user) => userItem(user))}
            </div>
          )}
      </div>
    </Modal>
  );
};
