import {
  ChartComponent,
  KeyParameter,
  ReportSelectedVendors,
} from '@hooks/reports/types';
import { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { formatNumberWithSuffix, hasNestedArray } from '@utils/index';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { LineChartIcon, LineChartIconUp } from '@components/ui';

type Props = {
  item: ChartComponent;
  isLoading?: boolean;
  selectedVendor: ReportSelectedVendors;
};

const RenderChartTile: React.FC<Props> = ({
  isLoading = false,
  item,
  selectedVendor,
}) => {
  const [selectedSortData, setSelectedSortData] = useState<string>();
  const [selectCompareData, setSelectedCompareData] = useState<string>();
  const [vendorData, setVendorData] = useState<any>();

  useEffect(() => {
    if (selectedVendor) {
      setVendorData(
        item.keyParameters.find(
          (kp: KeyParameter) => kp.key === selectedVendor[item.type]
        )
      );
    }
  }, [item.keyParameters, item.type, selectedVendor]);

  useEffect(() => {
    if (item.dropDowns && item.dropDowns.length) {
      setSelectedSortData(item.dropDowns[0].preselectedValue);
      if (item.dropDowns[1]) {
        setSelectedCompareData(item.dropDowns[1].preselectedValue);
      }
    }
  }, [item.type, item.dropDowns]);

  const handleSortData = (value: string) => {
    setSelectedSortData(value);
  };

  const handleCompareData = (value: string) => {
    setSelectedCompareData(value);
  };

  const createSortVendorsMenuItems = (type: number): MenuItem[] => [
    {
      id: '1',
      label: 'All Vendors',
      value: 'All',
      action: () => handleVendorDropdown(type, 'All'),
    },
    {
      id: '2',
      label: 'Microsoft',
      value: 'Microsoft',
      action: () => handleVendorDropdown(type, 'Microsoft'),
    },
    {
      id: '3',
      label: 'RingCentral',
      value: 'RingCentral',
      action: () => handleVendorDropdown(type, 'RingCentral'),
    },
  ];

  const createSortProductivityMenuItems = (type: number): MenuItem[] => [
    {
      id: '1',
      label: 'All activities',
      value: 'All',
      action: () => handleVendorDropdown(type, 'All'),
    },
    {
      id: '2',
      label: 'Emails',
      value: 'Emails',
      action: () => handleVendorDropdown(type, 'Emails'),
    },
    {
      id: '3',
      label: 'Calls',
      value: 'Calls',
      action: () => handleVendorDropdown(type, 'Calls'),
    },
    {
      id: '4',
      label: 'Meetings',
      value: 'Meetings',
      action: () => handleVendorDropdown(type, 'Meetings'),
    },
  ];

  const handleVendorDropdown = (type: number, value: string) => {
    setVendorData(
      item.keyParameters.find((kp: KeyParameter) => kp.key === value)
    );
  };

  const commonProps = {
    key: item.type,
    classNames: styles.doughnutChartTile,
    headerTitle: vendorData?.parameters.title as string,
    isLoading: !vendorData,
    sortData: createSortVendorsMenuItems(item.type),
  };

  const itemActivitySelected = createSortProductivityMenuItems(item.type).find(
    (item) => item.value === vendorData?.key
  );

  if (item) {
    switch (item.type) {
      case 58:
        if (item.dropDowns && item.dropDowns.length) {
          let lineChartData: KeyParameter | undefined = undefined;
          if (item.dropDowns[1]) {
            lineChartData = item.keyParameters.find(
              (kp: KeyParameter) =>
                kp.key === `${selectedSortData}:${selectCompareData}`
            );
          } else {
            lineChartData = item.keyParameters.find(
              (kp: KeyParameter) => kp.key === `${selectedSortData}`
            );
          }

          const sortDataDropdown: MenuItem[] = [];
          const compareDataDropdown: MenuItem[] = [];

          if (item.dropDowns && item.dropDowns.length && item.type === 58) {
            item.dropDowns[0].dropDownItems.map((x, i) => {
              sortDataDropdown.push({
                id: `${i + 1}`,
                label: x.label,
                value: x.value,
                action: () => handleSortData(x.value!),
              });
            });

            if (item.dropDowns[1]) {
              item.dropDowns[1].dropDownItems.map((x, i) => {
                compareDataDropdown.push({
                  id: `${i + 1}`,
                  label: x.label,
                  value: x.value,
                  action: () => handleCompareData(x.value!),
                });
              });
            }
          }
          return (
            <LineChartTile
              key={lineChartData?.parameters.title}
              classNames={'col-span-3'}
              dataSetTypes={
                hasNestedArray(lineChartData?.parameters?.data || [])
                  ? [LineChartTypes.Dots, LineChartTypes.Dots]
                  : [LineChartTypes.Dots]
              }
              legendLabels={lineChartData?.parameters?.legends || []}
              sortData={sortDataDropdown}
              compareData={compareDataDropdown}
              initialSelectedItem={sortDataDropdown.find(
                (x) => x.value === selectedSortData
              )}
              initialCompareSelectedItem={compareDataDropdown.find(
                (x) => x.value === selectCompareData
              )}
              headerTitle={lineChartData?.parameters.title || ''}
              dataSetColors={
                lineChartData?.parameters.lineColors || ['#00CF6C']
              }
              isLoading={!lineChartData}
              labels={lineChartData?.parameters?.labels || undefined}
              tooltipLabel={''}
              data={
                hasNestedArray(lineChartData?.parameters?.data || [])
                  ? lineChartData?.parameters.data
                  : [lineChartData?.parameters.data || []]
              }
              showCompareDropdown={compareDataDropdown.length > 0}
              showAgainstDropdown={true}
              hasPositiveValues={true}
              showXTicks={true}
              showYTicks={true}
              chartHeight={200}
              isDotted={true}
              compareLabel={''}
              adjustedPosition={{ right: '-50px' }}
            />
          );
        } else {
          return (
            <LineChartTile
              key={vendorData?.parameters.title}
              classNames={`${
                vendorData?.parameters.title ===
                'Shared mailboxes with sign-in enabled'
                  ? 'col-span-3 lg:col-span-2'
                  : 'col-span-3'
              }`}
              dataSetTypes={
                hasNestedArray(vendorData?.parameters?.data || [])
                  ? [LineChartTypes.Dots, LineChartTypes.Dots]
                  : [LineChartTypes.Dots]
              }
              legendLabels={vendorData?.parameters?.legends || []}
              initialSelectedItem={itemActivitySelected}
              headerTitle={vendorData?.parameters.title || ''}
              dataSetColors={vendorData?.parameters.lineColors || ['#00CF6C']}
              isLoading={!vendorData || isLoading}
              labels={vendorData?.parameters?.labels || undefined}
              tooltipLabel={''}
              data={
                hasNestedArray(vendorData?.parameters?.data || [])
                  ? vendorData?.parameters.data
                  : [vendorData?.parameters.data || []]
              }
              sortData={
                vendorData?.parameters.lineColors
                  ? []
                  : createSortProductivityMenuItems(item.type)
              }
              hasPositiveValues={true}
              showXTicks={true}
              showYTicks={true}
              chartHeight={200}
              isDotted={true}
              showAgainstDropdown={!vendorData?.parameters.lineColors}
              compareLabel={''}
              adjustedPosition={{ right: '-100px' }}
            />
          );
        }

      case 91: {
        let lineChartData: KeyParameter | undefined = undefined;
        if (item.dropDowns && item.dropDowns.length && item.dropDowns[0]) {
          lineChartData = item.keyParameters.find(
            (kp: KeyParameter) => kp.key === `${selectedSortData}`
          );
        } else {
          lineChartData = item.keyParameters.find(
            (kp: KeyParameter) => kp.key === `All`
          );
        }

        const doughnutSortData: MenuItem[] = [];

        if (item.dropDowns && item.dropDowns.length) {
          item.dropDowns[0].dropDownItems.map((x, i) => {
            doughnutSortData.push({
              id: `${i + 1}`,
              label: x.label,
              value: x.value,
              action: () => handleSortData(x.value!),
            });
          });
        }

        return (
          <DoughnutChartTile
            isLoading={isLoading}
            contextData={[]}
            key={lineChartData?.parameters.title}
            sortData={doughnutSortData}
            classNames="col-span-3 lg:col-span-1 !h-[280px]"
            initialSelectedItem={doughnutSortData.find(
              (x) => x.value === selectedSortData
            )}
            headerTitle={lineChartData?.parameters.title || ''}
            data={lineChartData?.parameters.data || []}
            dataLabels={lineChartData?.parameters.legends || []}
            showBracketLabelValue={false}
            additionalTextInfo={
              lineChartData?.parameters.percentText ? (
                <div className="flex flex-row items-start">
                  {lineChartData.parameters.isPercentIncreased && (
                    <LineChartIconUp color="text-successColor-100" size="5" />
                  )}

                  {!lineChartData.parameters.isPercentIncreased &&
                    lineChartData.parameters.percentage !== 0 && (
                      <LineChartIcon size="4" color="text-errorColor-100" />
                    )}

                  <span
                    className={`ml-2 flex items-center text-xs ${
                      lineChartData.parameters.isPercentIncreased
                        ? 'text-successColor-100'
                        : lineChartData.parameters.percentage !== 0
                        ? 'text-errorColor-100'
                        : 'text-interfaceColor-80'
                    }`}
                  >
                    {lineChartData.parameters.percentage === 0 && (
                      <span className="mr-1 flex h-[1px] w-[12px] bg-interfaceColor-80"></span>
                    )}
                    {lineChartData.parameters.percentText}
                  </span>
                </div>
              ) : null
            }
          />
        );
      }

      case 59:
      case 84:
        if (Object.hasOwn(item, 'dropDowns') && item.type === 59) {
          return (
            <NumberTile
              classNames="col-span-3 lg:col-span-1"
              headerTitle={item?.keyParameters[0].parameters.title || ''}
              isLoading={!item || isLoading}
              data={[
                formatNumberWithSuffix(item?.keyParameters[0].parameters.total),
                item?.keyParameters[0].parameters.percentage || 0,
              ]}
              percentageLabel=""
              isPercentIncreased={
                item?.keyParameters[0].parameters.isPercentIncreased
              }
              bottomLabel={
                item?.keyParameters[0].parameters?.title.startsWith('External')
                  ? 'Users who aren’t part of your business but have access to resources'
                  : ''
              }
            />
          );
        } else {
          return (
            <NumberTile
              classNames="col-span-3 lg:col-span-1"
              headerTitle={vendorData?.parameters.title || ''}
              isLoading={!vendorData || isLoading}
              data={[
                formatNumberWithSuffix(vendorData?.parameters.total),
                vendorData?.parameters.percentage || 0,
              ]}
              percentageLabel=""
              isPercentIncreased={vendorData?.parameters.isPercentIncreased}
              bottomLabel={
                vendorData?.parameters?.title.startsWith('External')
                  ? 'Users who aren’t part of your business but have access to resources'
                  : ''
              }
            />
          );
        }

      case 7:
      case 10:
        return (
          <DoughnutChartTile
            contextData={[]}
            {...commonProps}
            data={
              (vendorData?.parameters
                ? item.type === 7
                  ? [
                      vendorData.parameters.unassigned,
                      vendorData.parameters.assigned,
                    ]
                  : [
                      vendorData.parameters.inactive,
                      vendorData.parameters.active,
                    ]
                : []) as number[]
            }
            dataLabels={
              item.type === 7
                ? ['Unassigned Licenses', 'Assigned Licenses']
                : ['Inactive licenses', 'Active licenses']
            }
            showBracketLabelValue={true}
          />
        );
      case 13:
        return (
          <NumberTile
            {...commonProps}
            data={
              (vendorData?.parameters
                ? [
                    vendorData.parameters.overassigned,
                    vendorData.parameters.percentage,
                  ]
                : []) as number[]
            }
            percentageLabel="(previous period)"
          />
        );
      default:
        return null;
    }
  }
};

export default RenderChartTile;
