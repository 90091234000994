import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { AdoptionsHelper } from './helpers/AdoptionsHelper';

export const InternalPhoneAdoption: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  return (
    <AdoptionsHelper
      params={params}
      id={id}
      classNames={classNames}
      type={DashboardTiles.InternalPhoneAdoption}
    />
  );
};