import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { SelectItem } from '@components/ui/SelectDropdown';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import {
  useDeviceInventoryTypesQuery,
  useHardwareInventorySnapshotQuery,
} from '@hooks/utilisation';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useEffect, useState } from 'react';

type Params = {
  typeList: string[];
};

export const HardwareInventory: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.HardwareInventory,
    parameters
  );
  const isOnDashboardPage = !!id;

  const [hardwareInventorySortByItems, setHardwareInventorySortByItems] =
    useState<SelectItem[]>([{ id: '0', name: 'All types', value: 'all' }]);

  const {
    data: hardwareInventoryData,
    isLoading: isHardwareInventoryLoading,
    refetch,
  } = useHardwareInventorySnapshotQuery(parameters);

  const {
    data: hardwareInventoryTypesData,
    isFetched: isHardwareInventoryTypesFetched,
    isLoading,
  } = useDeviceInventoryTypesQuery();

  useEffect(() => {
    if (isHardwareInventoryTypesFetched && hardwareInventoryTypesData) {
      const newItems = hardwareInventoryTypesData.map((item, index) => ({
        id: (index + 1).toLocaleString(),
        name: item,
        value: item,
        checked: parameters.typeList.includes(item),
      }));

      setHardwareInventorySortByItems((prev) => [...prev, ...newItems]);
    }
  }, [isHardwareInventoryTypesFetched, hardwareInventoryTypesData]);

  const handleSelectChange = (item: any) => {
    let newTypes: any[] = [];
    const selected: string[] = [];

    newTypes = hardwareInventorySortByItems.map((i) => {
      if (item.includes(i.id)) {
        selected.push(i.name);
        return { ...i, checked: true };
      } else {
        return { ...i, checked: false };
      }
    });

    setHardwareInventorySortByItems([...newTypes]);

    const newParameters = {
      ...parameters,
      typeList: selected,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });
      refetch();
    }
  };

  return (
    parameters &&
    !isLoading &&
    !isHardwareInventoryLoading && (
      <DoughnutChartTile
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        dropdownMenuItemClasses={'!w-[300px]'}
        classNames={
          isOnDashboardPage
            ? 'col-span-6 md:col-span-6 xl:col-span-4'
            : 'col-span-3 lg:col-span-3'
        }
        checkBoxItems={hardwareInventorySortByItems}
        headerTitle={'Device inventory'}
        isLoading={isHardwareInventoryLoading}
        data={
          hardwareInventoryData
            ? Object.values(hardwareInventoryData.inventorySnapshot)
            : []
        }
        dataLabels={
          hardwareInventoryData
            ? Object.keys(hardwareInventoryData.inventorySnapshot)
            : []
        }
        handleDropdownSelect={handleSelectChange}
        buttonLabel={'Device inventory data'}
        linkPath={ANALYTICS_NAVIGATION_MAP['deviceInventory']}
        handleButtonClick={handleButtonClick()}
        isLgSize={true}
      />
    )
  );
};
