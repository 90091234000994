import classnames from 'classnames';
import { useMemo } from 'react';
import { Outlet, useLocation, Link, Location } from 'react-router-dom';
import cn from 'classnames';
import { useQueryParams } from 'use-query-params';

export type Tab = {
  path: string;
  label: string;
};

type TabProps = {
  tabs: Tab[];
  className?: string;
  queryParams?: { [key: string]: string };
};

type GetLinkClassesFn = (tabPath: string) => string;

const Tab: React.FC<TabProps> = ({ tabs, className, queryParams }) => {
  const location: Location = useLocation();
  const [currentQueryParams] = useQueryParams();

  const getLinkClasses: GetLinkClassesFn = useMemo(() => {
    return (tabPath: string) => {
      return classnames(
        'tracking-[0.01em] flex items-center justify-center bg-interfaceColor-5 border-b-[1px] border-b-solid pl-0 py-2 pr-[16px] md:p-2 md:pr-[16px] text-18 md:text-20 font-normal hover:text-interfaceColor-90 transition-all duration-300',
        {
          'border-b-interfaceColor-80 text-interfaceColor-80 mb-[1px]':
            tabPath !== location.pathname,
          'border-b-[2px] border-b-primaryBrandColor-100 text-interfaceColor-100':
            tabPath === location.pathname,
        }
      );
    };
  }, [location.pathname]);

  const getQueryParams = (queryParams: { [key: string]: string } | undefined) => {
    let params = '';
    Object.entries(queryParams || {}).map(([key, value]) =>
      params += `${key}=${value}&`
    )
    return params;
  }

  return (
    <>
      <nav
        className={cn(
          'mb-4 w-[calc(100vw-50px)] overflow-x-auto pb-2 lg:w-full',
          className
        )}
      >
        <ul className="flex justify-start gap-4 whitespace-nowrap">
          {tabs.map(({ path, label }) => {
            const to = `${path}?${getQueryParams({
              ...currentQueryParams,
              ...queryParams
            })}`;
            return (
              <li key={path}>
                <Link
                  data-testid={`tab-${label}`}
                  to={to}
                  className={getLinkClasses(path)}
                >
                  {label}
                </Link>
              </li>)
          }
          )}
        </ul>
      </nav>
      <Outlet />
    </>
  );
};

export default Tab;
