import { TilesProps } from '@common/types';
import { useSuspiciousActivitySourceCountryQuery } from '@hooks/security';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';

export const SuspiciousActivitySourceCountry: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const { data, isLoading } = useSuspiciousActivitySourceCountryQuery(
    JSON.parse(params)
  );

  const countryCounts = data?.snapshot ? Object.values(data.snapshot) : [];
  const countryNames = data?.snapshot ? Object.keys(data.snapshot) : [];

  return (
    <DoughnutChartTile
      key={id}
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      data={countryCounts}
      dataLabels={countryNames}
      contextData={[]}
      headerTitle={'Suspicious activity: Source country'}
      isLoading={isLoading}
    />
  );
};
