import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { useAddDashboardTile, useRemoveDashboardTile } from '@hooks/dashboard';
import { useTotalSharePointSites } from '@hooks/utilisation';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { useEffect, useState } from 'react';

type Params = {
  includePersonalSites: boolean;
};

export const TotalSharePointSites: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.TotalSharePointSites,
    parameters
  );
  const isOnDashboardPage = !!id;

  const { data, isLoading, refetch } = useTotalSharePointSites(parameters);

  useEffect(() => {
    const updatedParams = JSON.parse(params);

    setParameters({
      includePersonalSites: updatedParams.includePersonalSites,
    });

    return () => {
      refetch();
    };
  }, [parameters.includePersonalSites, params, refetch]);

  return (
    <NumberTile
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      data={data ? [Number(data)] : [0]}
      headerTitle={'Total SharePoint sites'}
      dropdownMenuItemClasses={'!w-[300px]'}
      isLoading={isLoading}
      percentageLabel=""
    />
  );
};
