import * as React from 'react';
import { IconProps } from './types';

export const SandClockIcon: React.FC<IconProps> = ({
  color = 'text-primaryBrandColor-100',
  classNames = '',
  size = 4,
}) => {
  return (
    <svg
      className={`stroke-current w-${size} h-${size} ${color} ${classNames}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_2513_13016)">
        <path
          d="M13.3346 6.66574C13.3346 7.54979 12.9834 8.39764 12.3583 9.02276C11.7332 9.64788 10.8854 9.99907 10.0013 9.99907C9.11725 9.99907 8.2694 9.64788 7.64428 9.02276C7.01916 8.39764 6.66797 7.54979 6.66797 6.66574V3.80859H13.3346V6.66574Z"
          stroke="#657482"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3346 13.3333C13.3346 12.4493 12.9834 11.6014 12.3583 10.9763C11.7332 10.3512 10.8854 10 10.0013 10C9.11725 10 8.2694 10.3512 7.64428 10.9763C7.01916 11.6014 6.66797 12.4493 6.66797 13.3333V16.1905H13.3346V13.3333Z"
          stroke="#657482"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.76172 3.80859H15.2379"
          stroke="#657482"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.76172 16.1914H15.2379"
          stroke="#657482"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2513_13016">
          <rect
            width="13.3333"
            height="13.3333"
            fill="white"
            transform="translate(3.33203 3.33203)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
