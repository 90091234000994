import {
  AUTO_RENEW,
  DEFAULT_PAGE_SIZE,
  RENEWAL_PERIOD,
  SUBSCRIPTIONS_SORT_BY,
  SUBSCRIPTIONS_TABLE_COLUMNS,
  SUBSCRIPTION_STATUS,
  VENDORS,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { useLicencesFiltersQuery } from '@hooks/licenses';
import {
  LicensesFilterData,
  LicensesFiltersResponse,
} from '@hooks/licenses/types';
import { PeopleSortBy } from '@hooks/people/types';
import { usePagination } from '@hooks/utils/pagination';
import {
  formatDate,
  getFilterValues,
  getFirstMatchedFilterValue,
  useTableData,
} from '@utils/index';
import React, { useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { createCellValue } from './utils';
import { useLicensesInventoryPreviewQuery } from '@hooks/utilisation';
import { RecommendationCard } from '@pages/Dashboard/tiles/helpers/RecommendationCard';
import styles from './styles.module.css';
import { useIsDesktop } from '@hooks/utils';
import useDownloadCsv from '@hooks/utils/export';
import { API_ENDPOINTS } from '@api/ApiEndpoints';

const pageSize = DEFAULT_PAGE_SIZE;

const SubscriptionsPage: React.FC = () => {
  const isDesktop = useIsDesktop();
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, 'assigned'),
    order: withDefault(StringParam, 'desc'),
    filter: withDefault(StringParam, ''),
  });
  const { data: filterData, isFetched: filterDataFetched } =
    useLicencesFiltersQuery(
      'true',
      queryParams.filter.includes('freeLicenses')
    );
  const transformedFilterData: FilterData[] = [];

  const propertyLabels: Record<string, string> = {
    vendor: 'Vendor',
    licensetype: 'License Type',
  };

  // License inventory - table
  const query = useLicensesInventoryPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
    subscriptionNameList: getFilterValues(
      queryParams,
      (filterData?.licenseType?.map((item) => item.value) || []).filter(
        (item): item is string => item !== undefined
      )
    ),
    vendorList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => VENDORS.includes(filter as any))
      : [],
    expiryStatus: getFirstMatchedFilterValue(
      queryParams,
      SUBSCRIPTION_STATUS
    ).replace('Status', ''),
    autoRenew: getFirstMatchedFilterValue(queryParams, AUTO_RENEW),
    renewalPeriod: getFirstMatchedFilterValue(queryParams, RENEWAL_PERIOD),
  });
  const getTableData = useTableData(
    query,
    SUBSCRIPTIONS_TABLE_COLUMNS,
    createCellValue
  );

  const getPropertyLabel = (property: string): string => {
    return propertyLabels[property.toLowerCase()] || property;
  };

  if (filterDataFetched) {
    if (filterData) {
      Object.keys(filterData).forEach((property) => {
        const filterDataArray: FilterData = {
          label: getPropertyLabel(property),
          name: property.toLowerCase(),
          options: [],
          singleSelect: !!(property === 'vendor'),
        };

        const propertyData =
          filterData[property as keyof LicensesFiltersResponse];

        if ('value' in propertyData) {
          const item = propertyData as LicensesFilterData;

          const option = {
            label: item.value || item.vendor,
            value: item.value || item.vendor,
            count: item.count,
          };

          filterDataArray.options.push(option);
        } else {
          const items = propertyData as unknown as LicensesFilterData[];

          items.forEach((item) => {
            const option = {
              label: item.value || item.vendor,
              value: item.value || item.vendor,
            };

            filterDataArray.options.push(option);
          });
        }
        transformedFilterData.push(filterDataArray);
      });

      transformedFilterData.push(
        {
          label: 'Status',
          name: 'status',
          singleSelect: true,
          options: [
            { label: 'Active', value: 'StatusActive' },
            { label: 'Expired', value: 'StatusExpired' },
            { label: 'Disabled', value: 'StatusDisabled' },
          ],
        }
        // {
        //   label: 'Auto renew status',
        //   name: 'autoRenew',
        //   singleSelect: true,
        //   options: [
        //     { label: 'Active', value: 'Active' },
        //     { label: 'Inactive', value: 'Inactive' },
        //   ],
        // },
        // {
        //   label: 'Renewal period',
        //   name: 'renewalPeriod',
        //   singleSelect: true,
        //   options: [
        //     { label: 'Annual', value: 'Annual' },
        //     { label: 'Monthly', value: 'Monthly' },
        //   ],
        // }
      );

      transformedFilterData.push({
        label: 'Free licences',
        name: 'freeLicenses',
        singleSelect: true,
        options: [{ label: 'Show free licenses', value: 'freeLicenses' }],
      });
    }
  }

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.LICENSE_INVENTORY_PREVIEW}/export`,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
      subscriptionNameList: getFilterValues(
        queryParams,
        (filterData?.licenseType?.map((item) => item.value) || []).filter(
          (item): item is string => item !== undefined
        )
      ),
      vendorList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => VENDORS.includes(filter as any))
        : [],
      expiryStatus: getFirstMatchedFilterValue(
        queryParams,
        SUBSCRIPTION_STATUS
      ),
      autoRenew: getFirstMatchedFilterValue(queryParams, AUTO_RENEW),
      renewalPeriod: getFirstMatchedFilterValue(queryParams, RENEWAL_PERIOD),
    },
    filename: `Clevr360_License_inventory_Subscriptions_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <div>
      <div className={styles.pageGrid}>
        {isDesktop && (
          <RecommendationCard insightDescription={'license inventory'} />
        )}
      </div>
      <TableWrapper
        search={search}
        setSearch={setSearch}
        searchPlaceholder={'Search by subscription type, name, email address'}
        columns={SUBSCRIPTIONS_TABLE_COLUMNS}
        data={getTableData()}
        sortData={SUBSCRIPTIONS_SORT_BY}
        filterData={transformedFilterData}
        searchKey="search"
        query={query}
        refetchQuery={query.refetch}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        setPageNumber={setPageNumber}
        isCollapsable={true}
        sliceColumns={4}
        floatingFilterButton={true}
        floatingFilterButtonTop={'155px'}
        hasFilterBanner={false}
        isLicensePage={true}
        searchCountStatsLabel="subscriptions"
        filterHeaderText="Filter license inventory"
        isDownloadAvailable={true}
        handleDownload={handleDownloadClick}
      />
    </div>
  );
};

export default SubscriptionsPage;
