import cn from 'classnames';
import { useState } from 'react';
import styles from './styles.module.css';
import {
  CheckCircleIcon,
  WarningCircleIcon,
  WarningIcon,
  CalendarIcon,
  CloseIcon,
} from '@components/ui/Icons';

export enum Variant {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Success = 'success',
  HighContrast = 'highContrast',
}

export type ToastProps = {
  message: string | undefined | null;
  title?: string;
  variant?: Variant;
  closeable?: boolean;
  onClose?: () => void;
  className?: string;
};

const Toast: React.FC<ToastProps> = ({
  message = '',
  closeable = false,
  variant = Variant.Info,
  className,
  onClose,
  title = '',
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const getIconByVariant = (variant: ToastProps['variant']) => {
    switch (variant) {
      case 'warning':
        return (
          <WarningCircleIcon
            classNames="absolute h-4 w-4 top-[20px]"
            color="#E4AA13"
          />
        );
      case 'info':
        return <WarningCircleIcon classNames="absolute h-4 w-4 top-[20px]" />;
      case 'error':
        return <WarningIcon classNames="absolute h-4 w-4 top-[20px]" />;
      case 'success':
        return <CheckCircleIcon classNames="absolute h-4 w-4 top-[20px]" />;
      case 'highContrast':
        return (
          <CalendarIcon
            color="text-primaryBrandColor-100"
            classNames="absolute h-4 w-4 top-[17px]"
            size="6"
          />
        );
      default:
        return null;
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={cn(styles.wrapper, styles[variant], className)}
      role="alert"
    >
      {getIconByVariant(variant)}
      {title ? (
        <div className="ml-6 flex">
          <p data-testid="toast-title" className={styles.title}>
            {title}
          </p>
          <p data-testid="toast-message" className={styles.message}>
            {message}
          </p>
        </div>
      ) : (
        <div className="mx-2">
          <p data-testid="toast-message" className={styles.errorMessage}>
            {message}
          </p>
        </div>
      )}

      {closeable && (
        <button
          data-testid="toast-close-button"
          data-dismiss="alert"
          aria-label="Close"
          onClick={() => {
            if (closeable) {
              setIsVisible(false);
              if (typeof onClose === 'function') {
                onClose();
              }
            }
          }}
          title="Close alert"
          className={styles.closeBtn}
        >
          <span aria-hidden="true">
            <CloseIcon classNames="h-3 w-3" />
          </span>
        </button>
      )}
    </div>
  );
};

export default Toast;
