import {
  DEFAULT_PAGE_SIZE,
  PEOPLE_FILTER_BY,
  PEOPLE_SORT_BY,
  PEOPLE_TABLE_COLUMNS,
  PERSON_STATUS,
} from '@common/constants';
import { ExtendedOption, IOption, OrderBy } from '@common/types';
import { NoResults, TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { Button, Loader } from '@components/ui';
import { useGroupsQuery } from '@hooks/groups';
import { GroupsResponse } from '@hooks/groups/types';
import { useLocationsQuery } from '@hooks/locations';
import { AccountType, Location } from '@hooks/locations/types';
import { useAccountTypesQuery, usePersonsQuery } from '@hooks/people';
import { AccountTypesResponse, PeopleSortBy } from '@hooks/people/types';
import { usePagination } from '@hooks/utils/pagination';
import { useStickyElements } from '@hooks/utils/stickyElements';
import useQueryParamsStore from '@store/queryParamsStore';
import {
  formatDate,
  getFirstMatchedFilterValue,
  useTableData,
} from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { createCellValue } from './utils';
import useAuthStore from '@store/authStore';
import { UserRoles } from '@hooks/users/types';
import { useNavigate } from 'react-router-dom';
import useDownloadCsv from '@hooks/utils/export';
import { API_ENDPOINTS } from '@api/ApiEndpoints';

const pageSize = DEFAULT_PAGE_SIZE;

const PeoplePage: React.FC = () => {
  const [hasFetchedInitially, setHasFetchedInitially] = useState(false);

  const { pageNumber, setPageNumber } = usePagination();
  const [search, setSearch] = useState('');
  const { peopleQueryParams, setPeopleQueryParamsStore } =
    useQueryParamsStore();
  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [accountTypes, setAccountTypes] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
    filter: withDefault(StringParam, ''),
  });
  useStickyElements();
  const navigate = useNavigate();
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;

  const { data: locationsData, isFetched: locationDataFetched } =
    useLocationsQuery();
    const { data: accountTypesData, isFetched: accountTypesDataFetched } =
    useAccountTypesQuery();
  const { data: groupsData, isFetched: groupsDataFetched } = useGroupsQuery();

  const allLocationIds: string[] = [];
  const allAccountTypes: string[] = [];
  const groupsIDs: string[] = [];
  const locationFilerOptions: IOption[] = [];
  const accountTypesFilerOptions: IOption[] = [];
  const groupFilerOptions: ExtendedOption[] = [];
  const transformedFilterData: FilterData[] = [];

  useEffect(() => {
    if (peopleQueryParams.search !== '') {
      setQueryParams({ search: peopleQueryParams.search }, 'pushIn');
    }
    if (peopleQueryParams.sortBy) {
      setQueryParams({ sortBy: peopleQueryParams.sortBy }, 'pushIn');
    }
    if (peopleQueryParams.order) {
      setQueryParams({ order: peopleQueryParams.order }, 'pushIn');
    }
    if (peopleQueryParams.filter !== '') {
      setQueryParams({ filter: peopleQueryParams.filter }, 'pushIn');
    }
    setSearch(peopleQueryParams.search);
  }, []);

  const initalFetch = usePersonsQuery({
    pageSize,
    pageNumber: 1,
    searchTerm: '',
    sortBy: 'fullname',
    order: 'asc',
    personStatus: '',
    enabled: !hasFetchedInitially,
  });

  useEffect(() => {
    if (initalFetch.isSuccess || initalFetch.isError) {
      setHasFetchedInitially(true);
    }
  }, [initalFetch.isSuccess, initalFetch.isError]);

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: API_ENDPOINTS.PERSONS_DOWNLOAD,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      personStatus: getFirstMatchedFilterValue(queryParams, PERSON_STATUS),
      accountTypes: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => accountTypes.includes(filter))
        : [],
      locationList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => locationIds.includes(filter))
        : [],
      groupList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => groupIds.includes(filter))
        : [],
    },
    filename: `Clevr360_Accounts_${formatDate(new Date().toString())}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  const query = usePersonsQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    personStatus: getFirstMatchedFilterValue(queryParams, PERSON_STATUS),
    groupList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => groupIds.includes(filter))
      : [],
      locationList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => locationIds.includes(filter))
      : [],
    accountTypes: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => accountTypes.includes(filter))
      : [],
  });
  const getTableData = useTableData(
    query,
    PEOPLE_TABLE_COLUMNS,
    createCellValue
  );
  if (locationDataFetched && groupsDataFetched) {
    if (accountTypesData) {
      accountTypesData?.forEach((accountTypes: AccountTypesResponse) => {
        accountTypesFilerOptions.push({ label: accountTypes.name, value: accountTypes.id });
        allAccountTypes.push(accountTypes.id);
      });

      transformedFilterData.push({
        label: 'Type',
        name: 'type',
        singleSelect: false,
        options: accountTypesFilerOptions,
      });
    }

    transformedFilterData.push(PEOPLE_FILTER_BY[0]);

    if (locationsData) {
      locationsData?.forEach((location: Location) => {
        locationFilerOptions.push({ label: location.name, value: location.id });
        allLocationIds.push(location.id);
      });

      transformedFilterData.push({
        label: 'Location',
        name: 'location',
        singleSelect: false,
        options: locationFilerOptions,
      });
    }

    if (groupsData) {
      groupsData?.forEach((group: GroupsResponse) => {
        groupFilerOptions.push({
          name: group.name,
          value: group.id,
          parentId: group.parentId,
          subGroups: group.subGroups,
          id: group.id,
          label: group.name,
        });
      });

      transformedFilterData.push({
        label: 'Groups',
        name: 'groups',
        singleSelect: false,
        options: groupFilerOptions,
      });
    }
  }

  useEffect(() => {
    locationsData?.forEach((location: Location) => {
      locationFilerOptions.push({ label: location.name, value: location.id });
      allLocationIds.push(location.id);
    });
    setLocationIds(allLocationIds);
  }, [allLocationIds, locationFilerOptions, locationsData]);

  useEffect(() => {
    accountTypesData?.forEach((accountType: AccountType) => {
      accountTypesFilerOptions.push({ label: accountType.name, value: accountType.id });
      allAccountTypes.push(accountType.id);
    });
    setAccountTypes(allAccountTypes);
  }, [accountTypesData, accountTypesFilerOptions, allAccountTypes]);

  useEffect(() => {
    const nested = (element: any) => {
      element.subGroups.forEach((subElement: any) => {
        groupsIDs.push(subElement.id);
        if (subElement.subGroups && subElement.subGroups.length > 0) {
          nested(subElement);
        }
      });
    };

    groupsData?.forEach((element: any) => {
      groupsIDs.push(element.id);
      if (element.subGroups && element.subGroups.length > 0) {
        nested(element);
      }
    });

    setGroupIds(groupsIDs);
  }, [groupsData]);

  if (query.isLoading && !hasFetchedInitially) {
    return <Loader />;
  }

  const haveResults = initalFetch?.data?.totalPageCount !== 0;
  return (
    <>
      {haveResults ? (
        <TableWrapper
          search={search}
          setSearch={setSearch}
          columns={PEOPLE_TABLE_COLUMNS}
          data={getTableData()}
          sortData={PEOPLE_SORT_BY}
          filterData={transformedFilterData}
          searchKey="search"
          query={query}
          refetchQuery={query.refetch}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          storedQueryParams={peopleQueryParams}
          setQueryParamsStore={setPeopleQueryParamsStore}
          setPageNumber={setPageNumber}
          isCollapsable={false}
          sliceColumns={5}
          isOverlayPage={false}
          hasFilterBanner={false}
          searchCountStatsLabel="accounts"
          handleDownload={handleDownloadClick}
        />
      ) : (
        <NoResults
          headerTxt={
            userRole === UserRoles.SUPERADMIN
              ? 'Connect your services to Clevr360 to see accounts here.'
              : "You'll see accounts here when your services are connected to Clevr360. Ask somebody with superadmin privileges to do this."
          }
          contentTxt=""
          actionButtons={
            userRole === UserRoles.SUPERADMIN && (
              <Button
                variant="primary"
                className="mt-6"
                onClick={() => navigate('/settings/manage-connected-services')}
              >
                <div className="flex items-center justify-center">
                  <p className="px-2">Connect your services</p>
                </div>
              </Button>
            )
          }
        />
      )}
    </>
  );
};

export default PeoplePage;
