import { useEffect, useState } from 'react';

import { TilesProps } from '@common/types';
import { useMeetingAdoptionTrendQuery } from '@hooks/adoption';
import {
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { getTrendData, getTrendLabels } from '@utils/index';

type Params = {
  days: string;
};

export const DailyMeetingAttendanceTrend: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));

  const {
    data: meetingAdoptionTrendData,
    isLoading: isLicencesUsageTrendDataLoading,
    refetch,
  } = useMeetingAdoptionTrendQuery(parameters);

  useEffect(() => {
    setParameters(JSON.parse(params));

    return () => {
      refetch();
    };
  }, [params, refetch]);

  const labels = getTrendLabels(
    meetingAdoptionTrendData?.trend || [],
    +parameters.days
  );

  const trendData = getTrendData(
    meetingAdoptionTrendData?.trend || [],
    +parameters.days
  );

  return (
    parameters && (
      <LineChartTile
        classNames={classNames ? classNames : 'col-span-3'}
        sortData={[]}
        compareData={[]}
        showCompareDropdown={false}
        showAgainstDropdown={false}
        legendLabels={[]}
        contextData={[]}
        tooltipLabel={['Meeting(s)']}
        headerTitle={'Daily meeting attendance: trend over time'}
        isLoading={isLicencesUsageTrendDataLoading}
        labels={labels}
        data={[trendData]}
        compareLabel=""
        dropdownMenuItemClasses={'!w-[300px]'}
        dataSetTypes={[LineChartTypes.Dots]}
        dataSetColors={['#00CF6C']}
      />
    )
  );
};
