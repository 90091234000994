import * as React from 'react';
import { IconProps } from './types';

export const ResetPasswordIcon: React.FC<IconProps> = ({
  color = 'text-primaryBrandColor-100',
  classNames = '',
  size = 4,
}) => {
  return (
    <svg
      className={`stroke-current w-${size} h-${size} ${color} ${classNames}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 10"
      fill="none"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
        d="M14.287 1H1.715A1.143 1.143 0 0 0 .572 2.143v5.714A1.143 1.143 0 0 0 1.715 9h12.572a1.143 1.143 0 0 0 1.142-1.143V2.143A1.143 1.143 0 0 0 14.287 1ZM10.857 6.143h1.715"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
        d="M4 5.286a.286.286 0 1 1 0-.571M4 5.286a.286.286 0 1 0 0-.571M6.858 5.286a.286.286 0 1 1 0-.571"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
        d="M6.857 5.286a.286.286 0 1 0 0-.571"
      />
    </svg>
  );
};
