import {
  calculateDateDifference,
  formatBytes,
  formatDate,
  isNumberIncludingZero,
  showLastUsedData,
} from '@utils/index';
import { Link } from 'react-router-dom';

export const createCellValue = (property: string, data: any, index: number) => {
  const value = data[index][property];

  const noDataLabel = (
    <span className="text-interfaceColor-80">No data available</span>
  );

  switch (property) {
    case 'name':
      return (
        <>
          {value ? (
            <div className="flex flex-col">
              <Link target="_blank" to={data[index]['url']}>
                {value}
              </Link>
              {data[index]['url'] && (
                <span className="text-interfaceColor-80">
                  {data[index]['url']}
                </span>
              )}
            </div>
          ) : (
            noDataLabel
          )}
        </>
      );
    case 'visits':
      return (
        <>
          <span>{value}</span>
        </>
      );
    case 'lastActivity':
      return (
        <>
          <span>
            {data[index]['lastActivity'] ? formatDate(value) : noDataLabel}
          </span>
          {data[index]['lastActivity'] &&
            showLastUsedData(
              calculateDateDifference(formatDate(data[index]['lastActivity']))
            )}
        </>
      );
    case 'files':
      return (
        <>
          <span>
            {isNumberIncludingZero(data[index]['files'])
              ? data[index]['files']
              : noDataLabel}
          </span>
        </>
      );
    case 'storage':
      return (
        <>
          <span>{formatBytes(data[index]['storage'], true)}</span>
        </>
      );
    case 'pages':
      return (
        <>
          <span>
            {isNumberIncludingZero(data[index]['pages'])
              ? data[index]['pages']
              : noDataLabel}
          </span>
        </>
      );
  }
};
