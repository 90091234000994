import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import {
  useAddDashboardTile,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useExternalTeamsAccessQuery } from '@hooks/security';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';

export type ExternalTeamsAccessProps = TilesProps & {
  type?: DashboardTiles;
};

export const ExternalTeamsAccess: React.FC<ExternalTeamsAccessProps> = ({
  params,
  id,
  type,
  classNames,
  isSnapshot,
}) => {
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { addMenuItem } = useAddDashboardTile(
    type || DashboardTiles.ExternalTeamsAccess,
    ''
  );
  const isOnDashboardPage = !!id;

  // data hook
  const {
    data: teamsGroupsInventoryData,
    isLoading: teamsGroupsInventoryLoading,
  } = useExternalTeamsAccessQuery();

  return (
    <NumberTile
      dropdownMenuItemClasses={'!w-[300px]'}
      sortData={[]}
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      headerTitle={'External Teams access'}
      isLoading={teamsGroupsInventoryLoading}
      data={
        teamsGroupsInventoryData ? Object.values(teamsGroupsInventoryData) : []
      }
      buttonLabel={!isSnapshot ? 'External Teams access' : ''}
      linkPath={ANALYTICS_NAVIGATION_MAP['externalTeamsAccess']}
      handleButtonClick={!isSnapshot ? handleButtonClick() : undefined}
      bottomLabel="Users who aren’t part of your business have access to Teams"
      percentageLabel=""
    />
  );
};
