import * as React from 'react';
import { IconProps } from './types';

export const LocationIcon: React.FC<IconProps> = ({
  size = 4,
  color = 'text-interfaceColor-100',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      fill="none"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M1.822 6.088c0 3.466 3.443 6.1 4.714 6.955.284.19.646.19.93 0 1.27-.854 4.713-3.489 4.713-6.955C12.18 3.173 9.861.81 7.001.81S1.822 3.173 1.822 6.09Z"
      />
      <path
        strokeWidth={strokeWidth}
        d="M7 7.476a1.905 1.905 0 1 0 0-3.81 1.905 1.905 0 0 0 0 3.81Z"
      />
    </svg>
  );
};
