import React, { InputHTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './styles.module.css';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: string;
  name: string;
  error?: string;
  defaultClassName?: string;
  checked: boolean;
  labelClassName?: string;
  labelTitle?: string;
  showFadeOut?: boolean;
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      className,
      label,
      name,
      error,
      onChange,
      defaultClassName,
      checked,
      labelClassName,
      labelTitle,
      showFadeOut = false,
      disabled,
      ...rest
    },
    ref
  ) => {
    return (
      <div className={className}>
        <div className={styles.ccCheckboxWrapper}>
          <input
            id={name}
            name={name}
            checked={checked}
            type="checkbox"
            ref={ref}
            className={cn(
              styles.ccCheckbox,
              `${defaultClassName ? styles[defaultClassName] : ''}`,
              {
                '!cursor-normal': disabled,
                '!cursor-pointer': !disabled,
              }
            )}
            disabled={disabled}
            onChange={onChange}
            {...rest}
          />

          <label
            title={labelTitle}
            htmlFor={name}
            className={cn(styles.ccCheckboxLabel, labelClassName, {
              '!cursor-normal': disabled,
              '!cursor-pointer': !disabled,
            })}
          >
            {label}
            {showFadeOut && <span className={styles.fadeOut}></span>}
          </label>
        </div>

        {error && <p className={styles.ccCheckboxError}>{error}</p>}
      </div>
    );
  }
);

Checkbox.displayName = 'Checkbox';
