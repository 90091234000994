import {
  CellBottomGroupPart,
  CellBottomPart,
} from '@components/ui/Table/utils';
import { formatDate } from '@utils/index';

export const createCellValue = (
  property: string,
  data: any,
  index: number,
  isMobile: boolean
) => {
  const value = data[index][property];

  switch (property) {
    case 'groups':
      return (
        <>
          <span>{value?.[0] ?? 'Unassigned'}</span>
          {Array.isArray(value) && value.length - 1 > 0 && (
            <CellBottomGroupPart count={value.length - 1} />
          )}
        </>
      );
    case 'fullName':
      return (
        <>
          <span>{value}</span>
          {!isMobile && <CellBottomPart data={data[index]['emailAddress']} />}
        </>
      );
    case 'userSince':
      return (
        <>
          <span>{formatDate(value)}</span>
        </>
      );
      case 'locationName':
        return (
          <>
           <span>{value ?? 'Unassigned'}</span>
          </>
        );
    case 'lastUse':
      return (
        <>
          <span>{formatDate(value)}</span>
        </>
      );
  }
};
