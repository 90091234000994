import React from 'react';
import { IconProps } from './types';

export const CircleRightArrow: React.FC<IconProps> = ({
  size = '5',
  color = 'text-overlay',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <g id="arrow-right-circle-1--arrow-keyboard-circle-button-right">
          <path
            id="Vector"
            d="M6.04883 4.14453L8.90597 7.00167L6.04883 9.85882"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M7.00102 13.1915C10.42 13.1915 13.1915 10.42 13.1915 7.00102C13.1915 3.58212 10.42 0.810547 7.00102 0.810547C3.58212 0.810547 0.810547 3.58212 0.810547 7.00102C0.810547 10.42 3.58212 13.1915 7.00102 13.1915Z"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};
