import * as React from 'react';
import { IconProps } from './types';

export const StructureIcon: React.FC<IconProps> = ({
  size = 5,
  color = 'text-interfaceColor-100',
  strokeWidth = 1.14,
  classNames = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      fill="none"
    >
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        clipPath="url(#a)"
      >
        <path d="M4.82 7.071V1.928c0-.71.576-1.285 1.286-1.285h5.786c.71 0 1.286.575 1.286 1.285v5.143c0 .71-.576 1.286-1.286 1.286H6.106c-.71 0-1.286-.576-1.286-1.286ZM9 8.357V13.5M2.57 13.5v-1.287c0-.71.576-1.285 1.286-1.285h10.286c.71 0 1.286.575 1.286 1.285V13.5M.643 16.071v-1.285c0-.71.575-1.286 1.285-1.286h1.286c.71 0 1.286.576 1.286 1.286v1.285c0 .71-.576 1.286-1.286 1.286H1.928c-.71 0-1.285-.575-1.285-1.286ZM7.07 16.071v-1.285c0-.71.576-1.286 1.286-1.286h1.286c.71 0 1.286.576 1.286 1.286v1.285c0 .71-.576 1.286-1.286 1.286H8.356c-.71 0-1.286-.575-1.286-1.286ZM13.5 16.071v-1.285c0-.71.576-1.286 1.286-1.286h1.285c.71 0 1.286.576 1.286 1.286v1.285c0 .71-.575 1.286-1.286 1.286h-1.285c-.71 0-1.286-.575-1.286-1.286Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
