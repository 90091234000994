import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
    useAddDashboardTile,
    useDashboardUpdateTileParams,
    useHandleTileBtnNavigate,
    useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useDelegatedMailboxDataSnapshotQuery } from '@hooks/security';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { useEffect, useState } from 'react';

type Params = {
    days: string;
};

export const DelegatedMailboxData: React.FC<TilesProps> = ({
    params,
    id,
    classNames,
    isSnapshot
}) => {
    const [parameters, setParameters] = useState<Params>(JSON.parse(params));
    const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
    const { removeMenuItem } = useRemoveDashboardTile(id);
    const { handleButtonClick } = useHandleTileBtnNavigate(id);
    const { addMenuItem } = useAddDashboardTile(
        DashboardTiles.DelegatedMailboxData,
        parameters
    );
    const isOnDashboardPage = !!id;

    // data hook
    const { data, isLoading, refetch } = useDelegatedMailboxDataSnapshotQuery(parameters);

    const compareMenuItems: MenuItem[] = [
        {
            id: '1',
            label: 'Last 7 days',
            value: '7',
            action: () => handleDropdownChange('7'),
        },
        {
            id: '2',
            label: 'Last 30 days',
            value: '30',
            action: () => handleDropdownChange('30'),
        },
        {
            id: '3',
            label: 'Last 60 days',
            value: '60',
            action: () => handleDropdownChange('60'),
        },
        {
            id: '4',
            label: 'Last 90 days',
            value: '90',
            action: () => handleDropdownChange('90'),
        },
    ];

    const handleDropdownChange = (value: string) => {
        const newParameters = {
            ...parameters,
            days: value,
        };

        setParameters(newParameters);

        if (isOnDashboardPage) {
            useDashboardUpdateTileParamsMutation.mutate({
                params: JSON.stringify(newParameters),
                tileId: id,
            });

            refetch();
        }
    };

    useEffect(() => {
        const updatedParams = JSON.parse(params);
        setParameters({
            days: updatedParams.days,
        });
    }, [params]);

    return (
        <NumberTile
            dropdownMenuItemClasses={'!w-[300px]'}
            sortData={[]}
            classNames={
                classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
            }
            initialCompareSelectedItem={compareMenuItems.find(
                (item) => item.value === parameters.days
            )}
            compareData={!isSnapshot ? compareMenuItems : undefined}
            contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
            headerTitle={'Mailboxes accessed by delegates'}
            isLoading={isLoading}
            data={
                data ? Object.values(data) : []
            }
            buttonLabel={!isSnapshot ? 'Delegated mailbox data' : ''}
            linkPath={ANALYTICS_NAVIGATION_MAP['delegatedMailboxData']}
            handleButtonClick={!isSnapshot ? handleButtonClick() : undefined}
            bottomLabel="Mailboxes accessed by other users through delegation"
            percentageLabel=""
        />
    );
};
