import React from 'react';

type WarningIconProps = {
  size?: string;
  color?: string;
  strokeWidth?: number;
  strokeWidthCircle?: number;
  classNames?: string;
}

export const WarningIcon: React.FC<WarningIconProps> = ({
  size = '16',
  color = 'text-errorColor-100',
  strokeWidth = 1.14286,
  strokeWidthCircle = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      className={`w-${size} h-${size} ${classNames}`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.01709 1.62501C8.92118 1.44951 8.77547 1.30223 8.59601 1.19938C8.41654 1.09653 8.21028 1.04211 7.99994 1.04211C7.7896 1.04211 7.58334 1.09653 7.40388 1.19938C7.22441 1.30223 7.07871 1.44951 6.9828 1.62501L0.697085 13.4107C0.609416 13.5738 0.567677 13.7552 0.575831 13.9377C0.583984 14.1202 0.64176 14.2977 0.743671 14.4534C0.845583 14.609 0.988246 14.7377 1.15811 14.8271C1.32798 14.9165 1.51941 14.9637 1.71423 14.9643H14.2857C14.4805 14.9637 14.6719 14.9165 14.8418 14.8271C15.0116 14.7377 15.1543 14.609 15.2562 14.4534C15.3581 14.2977 15.4159 14.1202 15.4241 13.9377C15.4322 13.7552 15.3905 13.5738 15.3028 13.4107L9.01709 1.62501Z"
        className={`stroke-current ${color}`}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 6L8 9"
        className={`stroke-current ${color}`}
        strokeWidth={strokeWidthCircle}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="8" cy="11.5" r="1" className={`fill-current ${color}`} />
    </svg>
  );
};
