import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

import React from 'react';
import cn from 'classnames';

type DoughnutChartProps = {
  data: ChartData<'doughnut'>;
  classNames?: string;
  options?: ChartOptions<'doughnut'>;
  animation?: boolean;
};

const DoughnutChart: React.FC<DoughnutChartProps> = ({
  data,
  classNames = '',
  options,
  animation,
}) => {
  const chartClassNames = cn(classNames);

  const defaultOptions: ChartOptions<'doughnut'> = {
    responsive: true,
    devicePixelRatio: 2,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    cutout: '55%',
    animation: animation
      ? {
          animateRotate: true,
          animateScale: false,
        }
      : false,
  };

  const chartOptions = options
    ? { ...defaultOptions, ...options }
    : defaultOptions;

  return (
    <div className={chartClassNames}>
      <Doughnut height={80} options={chartOptions} data={data} />
    </div>
  );
};

export default DoughnutChart;
