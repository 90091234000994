import { useQuery, UseQueryResult } from 'react-query';
import { API_ENDPOINTS } from '../../api/ApiEndpoints';
import {
  LicensesActivityResponse,
  LicensesAssignmentResponse,
  LicensesTrendResponse,
  LicensesOverassignedResponse,
  UseLicensesActivityQueryOptions,
  UseLicensesAssignmentQueryOptions,
  UseLicensesTrendQueryOptions,
  UseLicensesOverassignedQueryOptions,
  LicensesAssignmentPreviewResponse,
  UseLicensesAssignmentPreviewQueryOptions,
  LicensesFiltersResponse,
  UseLicensesUsageTrendQueryOptions,
  LicensesUsageTrendResponse,
  UseLicensesCallTimeQueryOptions,
  LicensesCallTimeResponse,
  UseLicensesCorrespondencedQueryOptions,
  LicensesCorrespondenceResponse,
  UseLicensesUsagePreviewQueryOptions,
  LicensesUsagePreviewResponse,
  LicensesExpiringResponse,
  UseLicensesExpiringPreviewQueryOptions,
  LicensesExpiringVendorResponse,
  UseLicensesExpiringVendorQueryOptions,
  LicensesExpiringUserResponse,
  LicensesExpiredResponse,
  LicencesExpiredBarChartResponse,
} from './types';
import { useHttpClient } from '@hooks/utils/httpConfig';
import { getSearchQueryParams } from '@utils/index';

export function useLicencesActivityQuery({
  days,
  includeFreeLicenses,
}: UseLicensesActivityQueryOptions = {}): UseQueryResult<LicensesActivityResponse> {
  const licensesClient = useHttpClient();
  const fetchLicensesActivity = async (): Promise<LicensesActivityResponse> => {
    const params = {
      days,
      includeFreeLicenses: includeFreeLicenses
        ? `${includeFreeLicenses}`
        : 'false',
    };
    return licensesClient.get<LicensesActivityResponse>(
      API_ENDPOINTS.LICENSES_ACTIVITY,
      params
    );
  };

  return useQuery(
    ['licensesActivity', days, includeFreeLicenses],
    fetchLicensesActivity
  );
}

export function useLicencesExpiringSnapshotQuery({
  vendorList,
  autoRenew,
  renewalPeriod,
  subscriptionNameList,
  includeFreeLicenses,
}: UseLicensesAssignmentQueryOptions = {}): UseQueryResult<LicensesExpiringResponse> {
  const licensesClient = useHttpClient();
  const fetchLicencesExpiring = async (): Promise<LicensesExpiringResponse> => {
    const baseParams: Record<string, string> = {};

    if (autoRenew) baseParams.autoRenew = autoRenew;
    if (renewalPeriod) baseParams.renewalPeriod = renewalPeriod;

    if (includeFreeLicenses) {
      baseParams.includeFreeLicenses = `${includeFreeLicenses}`;
    }

    const vendorListParams = vendorList
      ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
      : '';
    const subscriptionNameListParams = subscriptionNameList
      ? subscriptionNameList
          .map((licenseType) => `SubscriptionNameList=${licenseType}`)
          .join('&')
      : '';

    const endpoint = `${
      API_ENDPOINTS.LICENSES_EXPIRING_SNAPSHOT
    }?${new URLSearchParams(
      baseParams
    ).toString()}&${vendorListParams}&${subscriptionNameListParams}`;

    return licensesClient.get<LicensesExpiringResponse>(endpoint);
  };

  return useQuery(
    [
      'licencesExpiring',
      vendorList,
      autoRenew,
      renewalPeriod,
      subscriptionNameList,
      includeFreeLicenses,
    ],
    fetchLicencesExpiring
  );
}

export function useLicencesExpiredSnapshotBarChartQuery({
  vendorList,
  autoRenew,
  renewalPeriod,
  subscriptionNameList,
  includeFreeLicenses,
}: UseLicensesAssignmentQueryOptions = {}): UseQueryResult<LicencesExpiredBarChartResponse> {
  const licensesClient = useHttpClient();
  const fetchLicencesExpiredBarChart =
    async (): Promise<LicencesExpiredBarChartResponse> => {
      const baseParams: Record<string, string> = {};

      if (autoRenew) baseParams.autoRenew = autoRenew;
      if (renewalPeriod) baseParams.renewalPeriod = renewalPeriod;

      if (includeFreeLicenses) {
        baseParams.includeFreeLicenses = `${includeFreeLicenses}`;
      }

      const vendorListParams = vendorList
        ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
        : '';
      const subscriptionNameListParams = subscriptionNameList
        ? subscriptionNameList
            .map((licenseType) => `SubscriptionNameList=${licenseType}`)
            .join('&')
        : '';

      const endpoint = `${
        API_ENDPOINTS.LICENSES_EXPIRED_SNAPSHOT_BAR_CHART
      }?${new URLSearchParams(
        baseParams
      ).toString()}&${vendorListParams}&${subscriptionNameListParams}`;

      return licensesClient.get<LicencesExpiredBarChartResponse>(endpoint);
    };

  return useQuery(
    [
      'licencesExpiredBarChart',
      vendorList,
      autoRenew,
      renewalPeriod,
      subscriptionNameList,
      includeFreeLicenses,
    ],
    fetchLicencesExpiredBarChart
  );
}

export function useLicencesExpiredSnapshotQuery({
  vendorList,
  autoRenew,
  renewalPeriod,
  subscriptionNameList,
  includeFreeLicenses,
}: UseLicensesAssignmentQueryOptions = {}): UseQueryResult<LicensesExpiredResponse> {
  const licensesClient = useHttpClient();
  const fetchLicencesExpired = async (): Promise<LicensesExpiredResponse> => {
    const baseParams: Record<string, string> = {};

    if (autoRenew) baseParams.autoRenew = autoRenew;
    if (renewalPeriod) baseParams.renewalPeriod = renewalPeriod;
    if (includeFreeLicenses) {
      baseParams.includeFreeLicenses = `${includeFreeLicenses}`;
    }

    const vendorListParams = vendorList
      ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
      : '';
    const subscriptionNameListParams = subscriptionNameList
      ? subscriptionNameList
          .map((licenseType) => `SubscriptionNameList=${licenseType}`)
          .join('&')
      : '';

    const endpoint = `${
      API_ENDPOINTS.LICENSES_EXPIRED_SNAPSHOT
    }?${new URLSearchParams(
      baseParams
    ).toString()}&${vendorListParams}&${subscriptionNameListParams}`;

    return licensesClient.get<LicensesExpiredResponse>(endpoint);
  };

  return useQuery(
    [
      'licencesExpired',
      vendorList,
      autoRenew,
      renewalPeriod,
      subscriptionNameList,
      includeFreeLicenses,
    ],
    fetchLicencesExpired
  );
}

export function useLicencesExpiringSnapshotVendorQuery({
  vendorList,
  autoRenew,
  renewalPeriod,
  subscriptionNameList,
  includeFreeLicenses,
}: UseLicensesExpiringVendorQueryOptions = {}): UseQueryResult<LicensesExpiringVendorResponse> {
  const licensesClient = useHttpClient();
  const fetchLicencesExpiring =
    async (): Promise<LicensesExpiringVendorResponse> => {
      const baseParams: Record<string, string> = {};

      if (autoRenew) baseParams.autoRenew = autoRenew;
      if (renewalPeriod) baseParams.renewalPeriod = renewalPeriod;

      if (includeFreeLicenses) {
        baseParams.includeFreeLicenses = `${includeFreeLicenses}`;
      }

      const vendorListParams = vendorList
        ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
        : '';
      const subscriptionNameListParams = subscriptionNameList
        ? subscriptionNameList
            .map((licenseType) => `SubscriptionNameList=${licenseType}`)
            .join('&')
        : '';

      const endpoint = `${
        API_ENDPOINTS.LICENSES_EXPIRING_SNAPSHOT_VENDOR
      }?${new URLSearchParams(
        baseParams
      ).toString()}&${vendorListParams}&${subscriptionNameListParams}`;

      return licensesClient.get<LicensesExpiringVendorResponse>(endpoint);
    };

  return useQuery(
    [
      'licencesExpiringVendor',
      vendorList,
      autoRenew,
      renewalPeriod,
      subscriptionNameList,
      includeFreeLicenses,
    ],
    fetchLicencesExpiring
  );
}

export function useLicencesExpiringSnapshotUserQuery({
  days,
  vendorList,
  autoRenew,
  renewalPeriod,
  subscriptionNameList,
  includeFreeLicenses,
}: UseLicensesExpiringVendorQueryOptions = {}): UseQueryResult<LicensesExpiringUserResponse> {
  const licensesClient = useHttpClient();
  const fetchLicencesExpiring =
    async (): Promise<LicensesExpiringUserResponse> => {
      const baseParams: Record<string, string> = {
        days: days || '7',
      };

      if (autoRenew) baseParams.autoRenew = autoRenew;
      if (renewalPeriod) baseParams.renewalPeriod = renewalPeriod;

      if (includeFreeLicenses) {
        baseParams.includeFreeLicenses = `${includeFreeLicenses}`;
      }

      const vendorListParams = vendorList
        ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
        : '';
      const subscriptionNameListParams = subscriptionNameList
        ? subscriptionNameList
            .map((licenseType) => `SubscriptionNameList=${licenseType}`)
            .join('&')
        : '';

      const endpoint = `${
        API_ENDPOINTS.LICENSES_EXPIRING_USER
      }?${new URLSearchParams(
        baseParams
      ).toString()}&${vendorListParams}&${subscriptionNameListParams}`;

      return licensesClient.get<LicensesExpiringUserResponse>(endpoint);
    };

  return useQuery(
    [
      'licencesExpiringUser',
      days,
      vendorList,
      autoRenew,
      renewalPeriod,
      subscriptionNameList,
      includeFreeLicenses,
    ],
    fetchLicencesExpiring
  );
}

export function useLicencesAssignmentQuery({
  vendorList,
  includeFreeLicenses,
}: UseLicensesAssignmentQueryOptions = {}): UseQueryResult<LicensesAssignmentResponse> {
  const licensesClient = useHttpClient();
  const fetchLicensesAssignment =
    async (): Promise<LicensesAssignmentResponse> => {
      const params = new URLSearchParams();
      params.append('Days', '1');

      params.append(
        'includeFreeLicenses',
        includeFreeLicenses ? `${includeFreeLicenses}` : 'false'
      );

      if (vendorList) {
        vendorList.forEach((vendor) => {
          params.append('vendorList', vendor);
        });
      }
      return licensesClient.get<LicensesAssignmentResponse>(
        `${API_ENDPOINTS.LICENSES_ASSIGNMENT}?${params.toString()}`
      );
    };

  return useQuery(
    ['licensesAssignment', vendorList, includeFreeLicenses],
    fetchLicensesAssignment
  );
}

export function useLicencesFiltersQuery(
  includeEnabledOnly?: string,
  includeFreeLicenses?: boolean
): UseQueryResult<LicensesFiltersResponse> {
  const licensesClient = useHttpClient();
  const fetchLicensesFilters = async (): Promise<LicensesFiltersResponse> => {
    const params = new URLSearchParams();

    params.append(
      'includeEnabledOnly',
      includeEnabledOnly ? `${includeEnabledOnly}` : 'true'
    );

    params.append(
      'includeFreeLicenses',
      includeFreeLicenses ? `${includeFreeLicenses}` : 'false'
    );
    return licensesClient.get<LicensesFiltersResponse>(
      `${API_ENDPOINTS.LICENSES_FILTERS}?${params.toString()}`
    );
  };

  return useQuery(
    ['licensesFilters', includeEnabledOnly, includeFreeLicenses],
    fetchLicensesFilters
  );
}

export function useLicencesOverassignedQuery({
  vendorList,
  includeFreeLicenses,
}: UseLicensesOverassignedQueryOptions = {}): UseQueryResult<LicensesOverassignedResponse> {
  const licensesClient = useHttpClient();
  const fetchLicensesOverassigned =
    async (): Promise<LicensesOverassignedResponse> => {
      const params = new URLSearchParams();
      params.append('Days', '30');

      params.append(
        'includeFreeLicenses',
        includeFreeLicenses ? `${includeFreeLicenses}` : 'false'
      );

      if (vendorList) {
        vendorList.forEach((vendor) => {
          params.append('vendorList', vendor);
        });
      }
      return licensesClient.get<LicensesOverassignedResponse>(
        `${API_ENDPOINTS.LICENSES_OVERASSIGNED_SNAPSHOT}?${params.toString()}`
      );
    };

  return useQuery(
    ['licensesOverassigned', vendorList, includeFreeLicenses],
    fetchLicensesOverassigned
  );
}

export function useLicensesAssignmentPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  vendorList,
  licenseTypeList,
  includeFreeLicenses,
}: UseLicensesAssignmentPreviewQueryOptions = {}): UseQueryResult<LicensesAssignmentPreviewResponse> {
  const licensesClient = useHttpClient();

  const fetchData = async (): Promise<LicensesAssignmentPreviewResponse> => {
    const params = getSearchQueryParams({
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      includeFreeLicenses: includeFreeLicenses?.toString(),
      VendorList: vendorList,
      LicenseTypeList: licenseTypeList,
    });

    const endpoint = `${
      API_ENDPOINTS.LICENSES_ASSIGNMENT_PREVIEW
    }?${params.toString()}`;
    return licensesClient.get<LicensesAssignmentPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'licensesAssignmentPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      vendorList,
      licenseTypeList,
      includeFreeLicenses,
    ],
    fetchData
  );
}

export function useLicencesTrendQuery({
  days,
  vendorList,
  licenseTypeList,
  includeFreeLicenses,
}: UseLicensesTrendQueryOptions = {}): UseQueryResult<LicensesTrendResponse> {
  const licensesClient = useHttpClient();
  const fetchLicensesUtilisation = async (): Promise<LicensesTrendResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.days = days;

    if (includeFreeLicenses) {
      baseParams.includeFreeLicenses = `${includeFreeLicenses}`;
    }

    const vendorListParams = vendorList
      ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
      : '';
    const licenseTypeListParams = licenseTypeList
      ? licenseTypeList
          .map((licenseType) => `LicenseTypeList=${licenseType}`)
          .join('&')
      : '';

    const endpoint = `${API_ENDPOINTS.LICENSES_TREND}?${new URLSearchParams(
      baseParams
    ).toString()}&${vendorListParams}&${licenseTypeListParams}`;

    return licensesClient.get<LicensesTrendResponse>(endpoint);
  };

  return useQuery(
    [
      'licensesTrend',
      { days, vendorList, licenseTypeList, includeFreeLicenses },
    ],
    fetchLicensesUtilisation
  );
}

export function useLicencesActivityTrendQuery({
  days,
  vendorList,
  licenseTypeList,
  groupList,
  locationList,
  includeFreeLicenses,
}: UseLicensesTrendQueryOptions = {}): UseQueryResult<LicensesTrendResponse> {
  const licensesClient = useHttpClient();
  const fetchLicensesUtilisation = async (): Promise<LicensesTrendResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.days = days;

    if (includeFreeLicenses) {
      baseParams.includeFreeLicenses = `${includeFreeLicenses}`;
    }

    const groupListParams = groupList
      ? groupList.map((group) => `GroupList=${group}`).join('&')
      : '';

    const locationListParams = locationList
      ? locationList.map((location) => `LocationList=${location}`).join('&')
      : '';

    const vendorListParams = vendorList
      ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
      : '';
    const licenseTypeListParams = licenseTypeList
      ? licenseTypeList
          .map((licenseType) => `LicenseTypeList=${licenseType}`)
          .join('&')
      : '';

    const endpoint = `${
      API_ENDPOINTS.LICENSES_ACTIVITY_TREND
    }?${new URLSearchParams(
      baseParams
    ).toString()}&${vendorListParams}&${licenseTypeListParams}&${groupListParams}&${locationListParams}`;

    return licensesClient.get<LicensesTrendResponse>(endpoint);
  };

  return useQuery(
    [
      'licensesActivityTrend',
      {
        days,
        vendorList,
        licenseTypeList,
        groupList,
        locationList,
        includeFreeLicenses,
      },
    ],
    fetchLicensesUtilisation
  );
}

export function useLicencesOverassignedTrendQuery({
  days,
  vendorList,
  licenseTypeList,
  includeFreeLicenses,
}: UseLicensesTrendQueryOptions = {}): UseQueryResult<LicensesTrendResponse> {
  const licensesClient = useHttpClient();
  const fetchLicensesUtilisation = async (): Promise<LicensesTrendResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.days = days;

    if (includeFreeLicenses) {
      baseParams.includeFreeLicenses = `${includeFreeLicenses}`;
    }

    const vendorListParams = vendorList
      ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
      : '';
    const licenseTypeListParams = licenseTypeList
      ? licenseTypeList
          .map((licenseType) => `LicenseTypeList=${licenseType}`)
          .join('&')
      : '';

    const endpoint = `${
      API_ENDPOINTS.LICENSES_OVERASSIGNED_TREND
    }?${new URLSearchParams(
      baseParams
    ).toString()}&${vendorListParams}&${licenseTypeListParams}`;

    return licensesClient.get<LicensesTrendResponse>(endpoint);
  };

  return useQuery(
    [
      'licensesOverassignedTrend',
      days,
      vendorList,
      licenseTypeList,
      includeFreeLicenses,
    ],
    fetchLicensesUtilisation
  );
}

export function useLicensesActivityPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  vendorList,
  licenseTypeList,
  includeFreeLicenses,
}: UseLicensesAssignmentPreviewQueryOptions = {}): UseQueryResult<LicensesAssignmentPreviewResponse> {
  const licensesClient = useHttpClient();
  const fetchLicensesAssignmentPreview =
    async (): Promise<LicensesAssignmentPreviewResponse> => {
      const baseParams: Record<string, string> = {
        pageSize: pageSize.toString(),
        pageNumber: pageNumber.toString(),
      };

      if (searchTerm) baseParams.searchTerm = searchTerm;
      if (sortBy) baseParams.sortBy = sortBy;
      if (order) baseParams.order = order;

      if (includeFreeLicenses) {
        baseParams.includeFreeLicenses = `${includeFreeLicenses}`;
      }

      const vendorListParams = vendorList
        ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
        : '';
      const licenseTypeListParams = licenseTypeList
        ? licenseTypeList
            .map((licenseType) => `LicenseTypeList=${licenseType}`)
            .join('&')
        : '';

      const endpoint = `${
        API_ENDPOINTS.LICENSES_ACTIVITY_PREVIEW
      }?${new URLSearchParams(
        baseParams
      ).toString()}&${vendorListParams}&${licenseTypeListParams}`;

      return licensesClient.get<LicensesAssignmentPreviewResponse>(endpoint);
    };

  return useQuery(
    [
      'licensesActivityPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      vendorList,
      licenseTypeList,
      includeFreeLicenses,
    ],
    fetchLicensesAssignmentPreview
  );
}

export function useLicensesOverassignedPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  vendorList,
  licenseTypeList,
  locationList,
  groupList,
  includeFreeLicenses,
}: UseLicensesAssignmentPreviewQueryOptions = {}): UseQueryResult<LicensesAssignmentPreviewResponse> {
  const licensesClient = useHttpClient();
  const fetchLicensesAssignmentPreview =
    async (): Promise<LicensesAssignmentPreviewResponse> => {
      const baseParams: Record<string, string> = {
        pageSize: pageSize.toString(),
        pageNumber: pageNumber.toString(),
      };

      if (searchTerm) baseParams.searchTerm = searchTerm;
      if (sortBy) baseParams.sortBy = sortBy;
      if (order) baseParams.order = order;

      if (includeFreeLicenses) {
        baseParams.includeFreeLicenses = `${includeFreeLicenses}`;
      }

      const groupListParams = groupList
        ? groupList.map((group) => `GroupList=${group}`).join('&')
        : '';

      const locationListParams = locationList
        ? locationList.map((location) => `LocationList=${location}`).join('&')
        : '';

      const vendorListParams = vendorList
        ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
        : '';
      const licenseTypeListParams = licenseTypeList
        ? licenseTypeList
            .map((licenseType) => `LicenseTypeList=${licenseType}`)
            .join('&')
        : '';

      const endpoint = `${
        API_ENDPOINTS.LICENSES_OVERASSIGNED
      }?${new URLSearchParams(
        baseParams
      ).toString()}&${vendorListParams}&${licenseTypeListParams}&${groupListParams}&${locationListParams}`;

      return licensesClient.get<LicensesAssignmentPreviewResponse>(endpoint);
    };

  return useQuery(
    [
      'licensesOverassignedPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      vendorList,
      licenseTypeList,
      locationList,
      groupList,
      includeFreeLicenses,
    ],
    fetchLicensesAssignmentPreview
  );
}

export function useLicensesExpiringPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  vendorList,
  subscriptionNameList,
  autoRenew,
  renewalPeriod,
  includeFreeLicenses,
}: UseLicensesExpiringPreviewQueryOptions = {}): UseQueryResult<LicensesAssignmentPreviewResponse> {
  const licensesClient = useHttpClient();
  const fetchLicensesExpiringPreview =
    async (): Promise<LicensesAssignmentPreviewResponse> => {
      const baseParams: Record<string, string> = {
        pageSize: pageSize.toString(),
        pageNumber: pageNumber.toString(),
      };

      if (searchTerm) baseParams.searchTerm = searchTerm;
      if (sortBy) baseParams.sortBy = sortBy;
      if (order) baseParams.order = order;
      if (autoRenew) baseParams.autoRenew = autoRenew;
      if (renewalPeriod) baseParams.renewalPeriod = renewalPeriod;
      if (includeFreeLicenses) {
        baseParams.includeFreeLicenses = `${includeFreeLicenses}`;
      }

      const vendorListParams = vendorList
        ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
        : '';
      const subscriptionNameListParams = subscriptionNameList
        ? subscriptionNameList
            .map((licenseType) => `SubscriptionNameList=${licenseType}`)
            .join('&')
        : '';

      const endpoint = `${
        API_ENDPOINTS.LICENSES_EXPIRING_PREVIEW
      }?${new URLSearchParams(
        baseParams
      ).toString()}&${vendorListParams}&${subscriptionNameListParams}`;

      return licensesClient.get<LicensesAssignmentPreviewResponse>(endpoint);
    };

  return useQuery(
    [
      'licensesExpiringPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      vendorList,
      subscriptionNameList,
      autoRenew,
      renewalPeriod,
      includeFreeLicenses,
    ],
    fetchLicensesExpiringPreview
  );
}

export function useLicensesExpiredPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  vendorList,
  subscriptionNameList,
  autoRenew,
  renewalPeriod,
  includeFreeLicenses,
}: UseLicensesExpiringPreviewQueryOptions = {}): UseQueryResult<LicensesAssignmentPreviewResponse> {
  const licensesClient = useHttpClient();
  const fetchLicensesExpiredPreview =
    async (): Promise<LicensesAssignmentPreviewResponse> => {
      const baseParams: Record<string, string> = {
        pageSize: pageSize.toString(),
        pageNumber: pageNumber.toString(),
      };

      if (searchTerm) baseParams.searchTerm = searchTerm;
      if (sortBy) baseParams.sortBy = sortBy;
      if (order) baseParams.order = order;
      if (autoRenew) baseParams.autoRenew = autoRenew;
      if (renewalPeriod) baseParams.renewalPeriod = renewalPeriod;

      if (includeFreeLicenses) {
        baseParams.includeFreeLicenses = `${includeFreeLicenses}`;
      }

      const vendorListParams = vendorList
        ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
        : '';
      const subscriptionNameListParams = subscriptionNameList
        ? subscriptionNameList
            .map((licenseType) => `SubscriptionNameList=${licenseType}`)
            .join('&')
        : '';

      const endpoint = `${
        API_ENDPOINTS.LICENSES_EXPIRED_PREVIEW
      }?${new URLSearchParams(
        baseParams
      ).toString()}&${vendorListParams}&${subscriptionNameListParams}`;

      return licensesClient.get<LicensesAssignmentPreviewResponse>(endpoint);
    };

  return useQuery(
    [
      'licensesExpiredPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      vendorList,
      subscriptionNameList,
      autoRenew,
      renewalPeriod,
      includeFreeLicenses,
    ],
    fetchLicensesExpiredPreview
  );
}

export function useLicencesUsageTrendQuery({
  days,
  serviceType,
  vendorList,
  licenseTypeList,
  locationList,
  groupList,
  includeFreeLicenses,
}: UseLicensesUsageTrendQueryOptions = {}): UseQueryResult<LicensesUsageTrendResponse> {
  const licensesClient = useHttpClient();
  const fetchData = async (): Promise<LicensesUsageTrendResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;
    if (serviceType) baseParams.ServiceType = serviceType;

    if (includeFreeLicenses) {
      baseParams.includeFreeLicenses = `${includeFreeLicenses}`;
    }

    const vendorListParams = vendorList
      ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
      : '';
    const licenseTypeListParams = licenseTypeList
      ? licenseTypeList
          .map((licenseType) => `LicenseTypeList=${licenseType}`)
          .join('&')
      : '';

    const endpoint = `${
      API_ENDPOINTS.LICENSES_USAGE_TREND
    }?${new URLSearchParams(
      baseParams
    ).toString()}&${vendorListParams}&${licenseTypeListParams}`;

    return licensesClient.get<LicensesUsageTrendResponse>(endpoint);
  };

  return useQuery(
    [
      'licensesUsageTrend',
      {
        days,
        serviceType,
        vendorList,
        licenseTypeList,
        locationList,
        groupList,
        includeFreeLicenses,
      },
    ],
    fetchData
  );
}

export function useLicencesUsageCallTimeQuery({
  days,
  vendorList,
  licenseTypeList,
  locationList,
  groupList,
  includeFreeLicenses,
}: UseLicensesCallTimeQueryOptions = {}): UseQueryResult<LicensesCallTimeResponse> {
  const licensesClient = useHttpClient();
  const fetchData = async (): Promise<LicensesCallTimeResponse> => {
    const baseParams: Record<string, string> = {};
    if (days) baseParams.Days = days;

    if (includeFreeLicenses) {
      baseParams.includeFreeLicenses = `${includeFreeLicenses}`;
    }

    const vendorListParams = vendorList
      ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
      : '';
    const licenseTypeListParams = licenseTypeList
      ? licenseTypeList
          .map((licenseType) => `LicenseTypeList=${licenseType}`)
          .join('&')
      : '';

    const endpoint = `${
      API_ENDPOINTS.LICENSES_USAGE_CALL_TIME
    }?${new URLSearchParams(
      baseParams
    ).toString()}&${vendorListParams}&${licenseTypeListParams}`;

    return licensesClient.get<LicensesCallTimeResponse>(endpoint);
  };

  return useQuery(
    [
      'licensesUsageCallTime',
      {
        days,
        vendorList,
        licenseTypeList,
        locationList,
        groupList,
        includeFreeLicenses,
      },
    ],
    fetchData
  );
}

export function useLicencesUsageCorrespondenceQuery({
  days,
  serviceType,
  vendorList,
  licenseTypeList,
  locationList,
  groupList,
}: UseLicensesCorrespondencedQueryOptions = {}): UseQueryResult<LicensesCorrespondenceResponse> {
  const licensesClient = useHttpClient();
  const fetchData = async (): Promise<LicensesCorrespondenceResponse> => {
    const baseParams: Record<string, string> = {};
    if (days) baseParams.Days = days;
    if (serviceType) baseParams.ServiceType = serviceType;

    const vendorListParams = vendorList
      ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
      : '';
    const licenseTypeListParams = licenseTypeList
      ? licenseTypeList
          .map((licenseType) => `LicenseTypeList=${licenseType}`)
          .join('&')
      : '';

    const endpoint = `${
      API_ENDPOINTS.LICENSES_USAGE_CORRESPODENCE
    }?${new URLSearchParams(
      baseParams
    ).toString()}&${vendorListParams}&${licenseTypeListParams}`;

    return licensesClient.get<LicensesCorrespondenceResponse>(endpoint);
  };

  return useQuery(
    [
      'licensesUsageCorrespodence',
      {
        days,
        serviceType,
        vendorList,
        licenseTypeList,
        locationList,
        groupList,
      },
    ],
    fetchData
  );
}

export function useLicensesUsagePreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  vendorList,
  licenseTypeList,
  locationList,
  groupList,
  includeFreeLicenses,
}: UseLicensesUsagePreviewQueryOptions = {}): UseQueryResult<LicensesUsagePreviewResponse> {
  const licensesClient = useHttpClient();
  const fetchData = async (): Promise<LicensesUsagePreviewResponse> => {
    const baseParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.searchTerm = searchTerm;
    if (sortBy) baseParams.sortBy = sortBy;
    if (order) baseParams.order = order;

    if (includeFreeLicenses) {
      baseParams.includeFreeLicenses = `${includeFreeLicenses}`;
    }

    const vendorListParams = vendorList
      ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
      : '';
    const licenseTypeListParams = licenseTypeList
      ? licenseTypeList
          .map((licenseType) => `LicenseTypeList=${licenseType}`)
          .join('&')
      : '';
    const locationListParams = locationList
      ? locationList.map((location) => `LocationList=${location}`).join('&')
      : '';
    const groupListParams = groupList
      ? groupList.map((group) => `GroupList=${group}`).join('&')
      : '';

    const endpoint = `${
      API_ENDPOINTS.LICENSES_USAGE_PREVIEW
    }?${new URLSearchParams(
      baseParams
    ).toString()}&${vendorListParams}&${licenseTypeListParams}&${locationListParams}&${groupListParams}`;

    return licensesClient.get<LicensesUsagePreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'licensesUsagePreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      vendorList,
      licenseTypeList,
      locationList,
      groupList,
      includeFreeLicenses,
    ],
    fetchData
  );
}


