import React, { useEffect } from 'react';
import { ArrowDownIcon } from '@components/ui/Icons';
import styles from './styles.module.css';
import cn from 'classnames';

export type SelectItem = {
  id?: string;
  name?: string;
  value?: string;
  disabled?: boolean;
};

type SelectProps = {
  items: SelectItem[];
  selected: string;
  selectOnChange: (value: string) => void;
  classNames?: string;
};

export const Select: React.FC<SelectProps> = ({
  items,
  selected,
  selectOnChange,
  classNames,
}) => {
  const [open, setOpen] = React.useState(false);
  const [headerLabel, setHeaderLabel] = React.useState('Select');

  useEffect(() => {
    if (selected) {
      const selectedItem: SelectItem[] = items.filter((i) => i.id === selected);
      setHeaderLabel(selectedItem[0]?.name ?? 'Select');
    } else {
      setHeaderLabel('Select');
    }
  }, [items, selected]);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleItemClick = (item: SelectItem) => {
    if (item.disabled) return;
    selectOnChange(item?.id ?? '');
    setHeaderLabel(item?.name ?? 'Select');
    setOpen(false);
  };

  return (
    <div className={cn(styles.wrapper, classNames)}>
      <button className="w-full" onClick={toggleOpen}>
        <span className={styles.header}>
          {headerLabel}
          <ArrowDownIcon size="3" classNames="w-5" />
        </span>
      </button>

      {open && (
        <ul className={styles.list}>
          <li className={styles.listItem} onClick={() => handleItemClick({})}>
            Select
          </li>

          {items.map((item) => (
            <li
              className={cn(styles.listItem, {
                [styles.disabled]: item.disabled,
              })}
              key={item.id}
              value={item.id}
              onClick={() => handleItemClick(item)}
            >
              {item.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
