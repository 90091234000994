import {
    CircleRightArrow,
} from '@components/ui/Icons';
import { CellBottomPart } from '@components/ui/Table/utils';
import useModalStore from '@store/modal';

export const createCellValue = (
    property: string,
    data: any,
    index: number,
) => {
    const value = data[index][property];
    const modalStore = useModalStore.getState();
    const extensions = data[index]['extensions'] || [];

    const handleOnClick = () => {
        modalStore.setIsDetailsModalOpen(true);
        modalStore.setDataDetailsModal({
            header: (
                <div className="flex flex-col gap-y-2 break-words">
                    <span className="text-36 text-interfaceColor-100">
                        Extensions
                    </span>
                </div>
            ),
            body: (
                <div className={'overflow-auto min-h-[200px] max-h-[400px] border border-interfaceColor-20 px-4 py-1'}>
                    <ul className="flex flex-col items-start text-16 leading-7">
                        {extensions?.map((extension: any) => (
                            <li className="mt-3 w-full" key={data}>
                                <span className="text-16 text-interfaceColor-100 pr-7">{extension?.number}</span>
                                <span className="text-16 text-interfaceColor-100">{extension?.name}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            ),
        });
    };

    switch (property) {
        case 'vendor':
            return (
                <>
                    <span>{value ?? ""}</span>
                </>
            );
        case 'name': {
            return (
                <div className="flex flex-col items-start">
                    <div className="flex flex-row items-center gap-[5px]">
                        <span>{value}</span>
                        {extensions.length > 0 && <span onClick={handleOnClick}>
                            <CircleRightArrow size="4" classNames="mt-[2px] cursor-pointer" />
                        </span>}
                    </div>
                    <CellBottomPart data={`${extensions.length} extension${extensions.length > 1 || extensions.length === 0 ? 's' : ''}`} />
                </div >
            );
        }
        case 'number':
            return (
                <>
                    <span>{value ?? ""}</span>
                </>
            );
        case 'mode':
            return (
                <>
                    <span>{value ?? ""}</span>
                </>
            );

        case 'default':
            return (
                <></>
            );
    }
};
