import * as React from 'react';
import { IconProps } from './types';

export const PhoneIcon: React.FC<IconProps> = ({
  color = 'text-primaryBrandColor-100',
  classNames = '',
  size = 4,
}) => {
  return (
    <svg
      viewBox="0 0 16 16"
      className={`stroke-current w-${size} h-${size} ${color} ${classNames}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3214_64794)">
        <path
          d="M12.0003 0.572266H4.00028C3.3691 0.572266 2.85742 1.08394 2.85742 1.71512V14.2866C2.85742 14.9178 3.3691 15.4294 4.00028 15.4294H12.0003C12.6315 15.4294 13.1431 14.9178 13.1431 14.2866V1.71512C13.1431 1.08394 12.6315 0.572266 12.0003 0.572266Z"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.42969 12.5723H8.57254"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3214_64794">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
