import { CellBottomPart } from '@components/ui/Table/utils';
import { formatSeconds } from '@utils/index';

export const createCellValue = (
  property: string,
  data: any,
  index: number,
  isMobile: boolean
) => {
  const value = data[index][property];

  switch (property) {
    case 'fullName':
      return (
        <>
          <span>{value}</span>
          {!isMobile && <CellBottomPart data={data[index]['emailAddress']} />}
        </>
      );
    case 'messagesSent':
      return (
        <>
          <span>{value ? value : 0}</span>
          {/* {lastUsed(value, data[index]['messagesSentLastUsed'])} */}
        </>
      );
    case 'phoneCallsDuration':
      return (
        <>
          <span>{value ? formatSeconds(value) : 0}</span>
          {/* {lastUsed(value, data[index]['internalPhoneCallsLastUsed'])} */}
        </>
      );
    case 'pstnCallsDuration':
      return (
        <>
          <span>{value ? formatSeconds(value) : 0}</span>
          {/* {lastUsed(value, data[index]['externalPhoneCallsLastUsed'])} */}
        </>
      );
    case 'videoCallsDuration':
      return (
        <>
          <span>{value ? formatSeconds(value) : 0}</span>
          {/* {lastUsed(value, data[index]['videoLastUsed'])} */}
        </>
      );
  }
};
