import React from 'react';
import { IconProps } from './types';

export const DeactivateIcon: React.FC<IconProps> = ({
  size = '12',
  color = 'text-interfaceColor-100',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M12.925 12.924a6.965 6.965 0 0 0-9.849-9.85M1.57 5.321a6.964 6.964 0 0 0 9.11 9.11"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M14.965 14.964 1.037 1.036"
      />
    </svg>
  );
};
