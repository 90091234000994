import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useOutdatedOSActivitySnapshotQuery } from '@hooks/outdatedData/OutdatedOperatingSystems';
import { useOutdatedOSFiltersQuery } from '@hooks/security';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { useEffect, useState } from 'react';

type Params = {
  days: string;
  groupList?: string[];
  OSType?: string;
  accountType?: string;
  locationList?: string[];
};

export const OutDatedOperatingSystemsActivity: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
  isSnapshot,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.OutDatedOperatingSystemsActivity,
    parameters
  );

  const isOnDashboardPage = !!id;
  // data hook
  const {
    data: outdatedOperatingSystems,
    isLoading: isOutdatedOperatingSystemsLoading,
    refetch,
  } = useOutdatedOSActivitySnapshotQuery(parameters);

  useEffect(() => {
    setParameters(JSON.parse(params));
    return () => {
      refetch();
    };
  }, [params, refetch]);

  const { data } = useOutdatedOSFiltersQuery(parameters?.days || "30");

  let sortMenuItems: MenuItem[] = [];

  if (data) {
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        const filter = (data[key] as string[])?.map((item, index) =>
        ({
          id: index + 1,
          label: item,
          value: item,
          action: () => handleSortDropdownChange(item),
        })
        );
        sortMenuItems = [
          {
            id: '1',
            label: 'All OS',
            value: '',
            action: () => handleSortDropdownChange(''),
          },
          ...filter
        ]
      }
    });
  }

  const compareMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All users',
      value: '',
      action: () => handleDropdownChange(''),
    },
    {
      id: '2',
      label: 'External',
      value: 'external',
      action: () => handleDropdownChange('external'),
    },
    {
      id: '3',
      label: 'Internal',
      value: 'internal',
      action: () => handleDropdownChange('internal'),
    },
  ];

  const handleDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      accountType: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const handleSortDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      OSType: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  useEffect(() => {
    const updatedParams = JSON.parse(params);
    setParameters({
      OSType: updatedParams.OSType,
      accountType: updatedParams.accountType,
      days: updatedParams.days
    });
  }, [params]);

  return (
    parameters && (
      <NumberTile
        dropdownMenuItemClasses={'!w-[300px]'}
        initialSelectedItem={sortMenuItems.find(
          (item) => item.value === parameters.OSType
        )}
        initialCompareSelectedItem={compareMenuItems.find(
          (item) => item.value === parameters.accountType
        )}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        classNames={
          classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
        }
        sortData={!isSnapshot ? sortMenuItems : []}
        compareData={!isSnapshot ? compareMenuItems : undefined}
        headerTitle={'Logins: outdated OS'}
        isLoading={isOutdatedOperatingSystemsLoading}
        data={outdatedOperatingSystems ? Object.values(outdatedOperatingSystems) : []}
        buttonLabel={!isSnapshot ? 'Outdated OS data' : ''}
        linkPath={ANALYTICS_NAVIGATION_MAP['outdatedOperatingSystems']}
        handleButtonClick={handleButtonClick()}
        percentageLabel=""
        bottomLabel={isSnapshot ? 'Total logins on outdated OSs' : 'Total logins'}
        isArrowColorReversed={true}
      />
    )
  );
};
