import { useIsMobile } from '@hooks/utils';
import useUIStore from '@store/uiStore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

const nameToInitials = (fullName: string | undefined) => {
  if (fullName === undefined) return '';
  const maxNumOfInitials = 2;

  return fullName
    .trim()
    .split(' ')
    .map((name, index) =>
      index < maxNumOfInitials ? name.charAt(0).toUpperCase() : ''
    )
    .join('');
};

type AvatarProps = {
  fullName: string | undefined;
};

export const Avatar: React.FC<AvatarProps> = ({ fullName }) => {
  const navigate = useNavigate();
  const { setSidebar } = useUIStore();
  const isMobile = useIsMobile();
  const initials = nameToInitials(fullName);

  return (
    <div
      onClick={() => {
        navigate('/my-profile/details');
        if (isMobile) {
          setSidebar(false);
        }
      }}
      className={styles.avatarWrapper}
    >
      {initials}
    </div>
  );
};
