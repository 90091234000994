import React, { InputHTMLAttributes } from 'react';
import styles from './styles.module.css';

export interface RadioButtonProps
  extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: string;
  name: string;
  error?: string;
}

export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ className, label, name, error, onChange, ...rest }, ref) => {
    return (
      <div className={className}>
        <div className={styles.ccRadioButtonWrapper}>
          <input
            id={name}
            name={name}
            type="radio"
            ref={ref}
            className={styles.ccRadioButton}
            onChange={onChange}
            {...rest}
          />

          <label htmlFor={name} className={styles.ccRadioButtonLabel}>
            {label}
          </label>
        </div>

        {error && <p className={styles.ccRadioButtonError}>{error}</p>}
      </div>
    );
  }
);

RadioButton.displayName = 'RadioButton';
