import { API_ENDPOINTS } from '@api/ApiEndpoints';
import {
  FailedLoginAccountsSnapshotResponse,
  FailedLoginDataQueryOptions,
  FailedLoginDataResponse,
} from './types';
import { UseQueryResult, useQuery } from 'react-query';
import { useHttpClient } from '@hooks/utils/httpConfig';


export function useFailedLoginCountriesSnapshotQuery({
  days,
}: FailedLoginDataQueryOptions = {}): UseQueryResult<FailedLoginDataResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<FailedLoginDataResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;

    const endpoint = `${API_ENDPOINTS.FAILED_LOGIN_DATA_COUNTRIES_SNAPSHOT
      }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<FailedLoginDataResponse>(endpoint)
  };
  return useQuery(['failedLoginCountriesSnapshot', days], fetchSnapshot);
}

export function useFailedMSLoginTypesSnapshotQuery({
  days
}: FailedLoginDataQueryOptions = {}): UseQueryResult<FailedLoginDataResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<FailedLoginDataResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;

    const endpoint = `${API_ENDPOINTS.FAILED_LOGIN_TYPES_SNAPSHOT
      }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<FailedLoginDataResponse>(endpoint)
  };
  return useQuery(['failedMSLoginTypesSnapshot', days], fetchSnapshot);
}

export function useFailedMSLoginAccountsSnapshotQuery({
  days, mfaStatus
}: FailedLoginDataQueryOptions = {}): UseQueryResult<FailedLoginAccountsSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<FailedLoginAccountsSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;
    if (mfaStatus) baseParams.MfaStatus = mfaStatus;

    const endpoint = `${API_ENDPOINTS.FAILED_LOGIN_ACCOUNTS_SNAPSHOT
      }?${new URLSearchParams(baseParams).toString()}`;

   return httpClient.get<FailedLoginAccountsSnapshotResponse>(endpoint)
  };
  return useQuery(['failedMSLoginsSnapshot', days, mfaStatus], fetchSnapshot);
}


