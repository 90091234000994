import { CellBottomGroupPart, CellBottomPart } from '@components/ui/Table/utils';
import { formatDate } from '@utils/index';

export const createCellValue = (property: string, data: any, index: number) => {
  const value = data[index][property];

  switch (property) {
    case 'totalMessagesSent':
      return (
        <>
          <span>{value != null ? value : 'Not applicable'}</span>
          {data[index]['lastMessageSent'] && (
            <CellBottomPart
              data={`Last: ${formatDate(data[index]['lastMessageSent'])}`}
            />
          )}
        </>
      );
    case 'totalEmailsSent':
      return (
        <>
          <span>{value != null ? value : 'Not applicable'}</span>
          {data[index]['lastEmailSent'] && (
            <CellBottomPart
              data={`Last: ${formatDate(data[index]['lastEmailSent'])}`}
            />
          )}
        </>
      );
    case 'totalVideoCallsDuration':
      return (
        <>
          <span>{value != null ? value : 'Not applicable'}</span>
          {data[index]['lastVideoCall'] && (
            <CellBottomPart
              data={`Last: ${formatDate(data[index]['lastVideoCall'])}`}
            />
          )}
        </>
      );
    case 'totalPhoneCallsDuration':
      return (
        <>
          <span>{value != null ? value : 'Not applicable'}</span>
          {data[index]['lastPhoneCall'] && (
            <CellBottomPart
              data={`Last: ${formatDate(data[index]['lastPhoneCall'])}`}
            />
          )}
        </>
      );
    case 'totalFilesAccessedOrModified':
      return (
        <>
          <span>{value != null ? value : 'Not applicable'}</span>
          {data[index]['lastFileAccessedOrModified'] && (
            <CellBottomPart
              data={`Last: ${formatDate(
                data[index]['lastFileAccessedOrModified']
              )}`}
            />
          )}
        </>
      );
    case 'assignedTo':
      return (
        <>
          <span>{value != null ? value : 'Not assigned'}</span>
          {data[index]['emailAddress'] && (
            <CellBottomPart data={data[index]['emailAddress']} />
          )}
        </>
      );
      case 'groups':
        return (
          <>
            <span>{value?.[0] ?? 'Unassigned'}</span>
            {data[index]['groups'].length - 1 > 0 && (
              <CellBottomGroupPart count={data[index]['groups'].length - 1} />
            )}
          </>
        );
  }
};
