import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import { useMeetingAdoptionSnapshotQuery } from '@hooks/adoption';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useState } from 'react';

type Params = {
  days: string;
};

export const MeetingAdoption: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.MeetingAdoption,
    parameters
  );
  const isOnDashboardPage = !!id;
  // data hook
  const {
    data: meetingAdoptionData,
    isLoading: isMeetingAdoptionLoading,
    refetch,
  } = useMeetingAdoptionSnapshotQuery(parameters);

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleDropdownChange('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleDropdownChange('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleDropdownChange('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleDropdownChange('90'),
    },
  ];

  const handleDropdownChange = (value: string) => {
    const newParameters = {
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  return (
    <DoughnutChartTile
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      sortData={sortMenuItems}
      initialSelectedItem={sortMenuItems.find(
        (item) => item.value === parameters.days
      )}
      headerTitle={'Meeting adoption'}
      dropdownMenuItemClasses={'!w-[300px]'}
      isLoading={isMeetingAdoptionLoading}
      data={
        meetingAdoptionData
          ? [
              meetingAdoptionData?.hasntSetupJoinedMeeting,
              meetingAdoptionData?.setupJoinedMeeting,
            ]
          : []
      }
      dataLabels={[
        `${
          meetingAdoptionData && meetingAdoptionData?.hasntSetupJoinedMeeting
            ? meetingAdoptionData.hasntSetupJoinedMeeting
            : 0
        } people haven’t set up / joined a meeting`,
        `${
          meetingAdoptionData && meetingAdoptionData?.setupJoinedMeeting
            ? meetingAdoptionData.setupJoinedMeeting
            : 0
        } people have set up / joined a meeting`,
      ]}
      buttonLabel={'Meeting adoption data'}
      linkPath={ANALYTICS_NAVIGATION_MAP['meetingAdoption']}
      handleButtonClick={handleButtonClick()}
      showBracketLabelValue={false}
    />
  );
};
