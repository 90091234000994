import React, { ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

const createWrapperElement = (wrapperId: string) => {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);
  document.body.appendChild(wrapperElement);

  return wrapperElement;
};

interface ReactPortalProps {
  children: ReactNode;
  wrapperId?: string;
}

export const ReactPortal: React.FC<ReactPortalProps> = ({
  children,
  wrapperId = 'wrapper',
}) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(
    null
  );
  const systemCreated = useRef(false);

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId) as HTMLDivElement | null;
    if (!element) {
      systemCreated.current = true;
      element = createWrapperElement(wrapperId);
    }
    setWrapperElement(element);

    return () => {
      if (systemCreated.current && element && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
};
