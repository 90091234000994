import useAuthStore from '@store/authStore';
import React from 'react';
import { useLocation } from 'react-router-dom';

const titles: { [key: string]: string } = {
  '/': 'Dashboard',
  '/reports': 'Reports',
  '/accounts': 'Accounts',
  '/insights-and-analytics': 'Insights & analytics',
  // help: 'Help, support & ideas',
  '/settings/manage-users': 'Manage Clevr360 users',
  '/settings/manage-connected-services': 'Connected services',
  '/settings/manage-operating-services': 'Manage operating services',
  '/my-profile': 'User profile',
  '/settings': 'Settings',
};

const getTitle = (pathname: string) => {
  if (pathname === '/') return titles['/'];

  const sortedRoutes = Object.keys(titles).sort((a, b) => b.length - a.length);
  for (const route of sortedRoutes) {
    if (route !== '/' && pathname.startsWith(route)) {
      return titles[route];
    }
  }
  return '';
};

type Props = {
  headerActions?: React.ReactNode;
};

export const PageHeader: React.FC<Props> = ({ headerActions }) => {
  const authStore = useAuthStore();
  const location = useLocation();
  const title = getTitle(location.pathname);

  return (
    <header className="mb-5 flex items-center justify-between">
      <h1 className="tracking[-0.02em] text-32 font-normal leading-9 text-interfaceColor-100 sm:leading-[48px] md:text-[48px] lg:leading-[72px]">
        {title === titles.dashboard
          ? `Hi, ${authStore.user?.fullName.split(' ')[0] ?? ''}`
          : title}
      </h1>

      {headerActions && headerActions}
    </header>
  );
};
