import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import { useMeetingsInactiveRoomsSnapshotQuery } from '@hooks/adoption';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';

import { useEffect, useState } from 'react';

export type InactiveRoomsProps = TilesProps & {
  type?: DashboardTiles;
};

type Params = {
  days: string;
};

export const InactiveRooms: React.FC<InactiveRoomsProps> = ({
  params,
  id,
  classNames,
  isSnapshot,
  type,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { addMenuItem } = useAddDashboardTile(
    type || DashboardTiles.InactiveRooms,
    parameters
  );
  const isOnDashboardPage = !!id;

  // data hook
  const {
    data: inactiveRoomsData,
    isLoading: inactiveRoomsDataLoading,
    refetch,
  } = useMeetingsInactiveRoomsSnapshotQuery(parameters);

  useEffect(() => {
    setParameters(JSON.parse(params));

    return () => {
      refetch();
    };
  }, [params, refetch]);

  const handleDropdownChange = (value: string) => {
    const newParameters = {
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleDropdownChange('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleDropdownChange('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleDropdownChange('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleDropdownChange('90'),
    },
  ];

  return (
    <DoughnutChartTile
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      contextData={
        !isSnapshot ? (isOnDashboardPage ? removeMenuItem : addMenuItem) : []
      }
      headerTitle={'Inactive rooms'}
      sortData={!isSnapshot ? sortMenuItems : []}
      dropdownMenuItemClasses={'!w-[300px]'}
      isLoading={inactiveRoomsDataLoading}
      initialSelectedItem={
        !isSnapshot
          ? sortMenuItems.find((item) => item.value === parameters.days)
          : undefined
      }
      data={
        inactiveRoomsData
          ? [
              inactiveRoomsData?.roomsWithoutBookings,
              inactiveRoomsData?.bookedRooms,
            ]
          : []
      }
      dataLabels={[
        `${
          inactiveRoomsData && inactiveRoomsData?.roomsWithoutBookings
            ? inactiveRoomsData.roomsWithoutBookings
            : 0
        } rooms had no bookings`,
        `${
          inactiveRoomsData && inactiveRoomsData?.bookedRooms
            ? inactiveRoomsData.bookedRooms
            : 0
        } rooms had bookings`,
      ]}
      showBracketLabelValue={false}
      buttonLabel={!isSnapshot ? 'Meeting rooms data' : ''}
      linkPath={ANALYTICS_NAVIGATION_MAP['meetingsRoom']}
      handleButtonClick={!isSnapshot ? handleButtonClick() : undefined}
    />
  );
};
