import { Button, OnwardIcon, WarningCircleIcon } from '@components/ui';
import { ExternalTeamsAccessTeamType } from '@hooks/security/types';
import useUIStore from '@store/uiStore';
import { useNavigate } from 'react-router-dom';

const showLastUsedData = (days: number) => {
  const iconColor =
    days >= 90
      ? 'text-errorColor-100'
      : days >= 60
      ? 'text-warningColor-150'
      : 'text-infoColor-150';

  return (
    <p className="flex items-center">
      <WarningCircleIcon color={iconColor} classNames="h-4 w-4 mr-1" />
      <span className="text-14 text-interfaceColor-80">
        {`Over ${days} ${days === 1 ? 'day' : 'days'} ago`}
      </span>
    </p>
  );
};

type NavigateButtonProps = {
  data: any;
  index: number;
};

const NavigateButton: React.FC<NavigateButtonProps> = ({ data, index }) => {
  const navigate = useNavigate();
  const { setExternalTeamsDetails } = useUIStore();

  const handleClick = () => {
    const email = data[index]['email'];
    const associatedTeams: ExternalTeamsAccessTeamType[] =
      data[index]['associatedTeams'];
    const teamsIds: string[] = [];

    associatedTeams.forEach((el) => {
      if (el && el['id']) {
        teamsIds.push(el['id']);
      }
    });

    setExternalTeamsDetails({ email, ids: teamsIds });
    navigate(`/insights-and-analytics/assurance/external-teams-access/details`);
  };

  return (
    <Button
      onClick={handleClick}
      size="extraSmall"
      variant="outlineLight"
      className={
        'w-auto border-none shadow-none hover:bg-transparent focus:shadow-none'
      }
    >
      <OnwardIcon classNames="ml-2 cursor-pointer" size="5" />
    </Button>
  );
};

export const createCellValue = (property: string, data: any, index: number) => {
  const value = data[index][property];

  switch (property) {
    case 'email':
      return (
        <span className="flex flex-row items-center">
          <span>{value}</span>
          {data[index]['associatedTeams'].length - 1 > 0 && (
            <NavigateButton data={data} index={index} />
          )}
        </span>
      );
    case 'teamDisplayName':
      return (
        <>
          <span>{value || 'No data available'}</span>
          {data[index]['associatedTeams'].length - 1 > 0 && (
            <div className="flex flex-row items-center">
              <span className="text-14 text-interfaceColor-80">{`+ ${
                data[index]['associatedTeams'].length - 1
              } more`}</span>
            </div>
          )}
        </>
      );
    case 'hasntCollaboratedDays':
      return (
        <>
          {data[index]['hasntCollaboratedDays']
            ? showLastUsedData(data[index]['hasntCollaboratedDays'])
            : 'No data available'}
        </>
      );
  }
};
