import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useSharePointUsageSnapshotQuery } from '@hooks/utilisation';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { useState } from 'react';

type Params = {
  dataType: string;
  days: string;
};

export const SharePointUsage: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.SharePointUsage,
    parameters
  );
  const isOnDashboardPage = !!id;

  const { data: sharePointUsageData, isLoading } =
    useSharePointUsageSnapshotQuery(parameters);

  const handleDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      dataType: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });
    }
  };

  const handleCompareDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });
    }
  };

  const sharePointUsageMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Sites',
      value: 'sites',
      action: () => handleDropdown('sites'),
    },
    {
      id: '2',
      label: 'Active sites',
      value: 'activesites',
      action: () => handleDropdown('activesites'),
    },
    {
      id: '3',
      label: 'Pages',
      value: 'pages',
      action: () => handleDropdown('pages'),
    },
    {
      id: '5',
      label: 'Visits',
      value: 'visits',
      action: () => handleDropdown('visits'),
    },
    {
      id: '4',
      label: 'Files',
      value: 'files',
      action: () => handleDropdown('files'),
    },
  ];

  const compareMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleCompareDropdown('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleCompareDropdown('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleCompareDropdown('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleCompareDropdown('90'),
    },
  ];

  const sharePointData = sharePointUsageData
    ? Object.values(sharePointUsageData)
    : [];

  return (
    parameters && (
      <NumberTile
        initialSelectedItem={sharePointUsageMenuItems.find(
          (item) => item.value === parameters.dataType
        )}
        initialCompareSelectedItem={compareMenuItems.find(
          (item) => item.value === parameters.days
        )}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        classNames={
          classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
        }
        compareData={compareMenuItems}
        sortData={sharePointUsageMenuItems}
        headerTitle={'SharePoint usage'}
        dropdownMenuItemClasses={'!w-[300px]'}
        isLoading={isLoading}
        data={sharePointData}
        buttonLabel={'SharePoint data'}
        linkPath={ANALYTICS_NAVIGATION_MAP['sharePointData']}
        handleButtonClick={handleButtonClick()}
        percentageLabel=""
      />
    )
  );
};
