import * as React from 'react';
import { IconProps } from './types';

export const UserIcon: React.FC<IconProps> = ({
  color = 'text-interfaceColor-100',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`stroke-current ${color} ${classNames}`}>
      <path
        fill="none"
        d="M6.99999 7.00002C8.70945 7.00002 10.0952 5.61424 10.0952 3.90478C10.0952 2.19532 8.70945 0.80954 6.99999 0.80954C5.29054 0.80954 3.90475 2.19532 3.90475 3.90478C3.90475 5.61424 5.29054 7.00002 6.99999 7.00002Z"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M12.8858 13.1905C12.4859 11.9437 11.7005 10.8561 10.6428 10.0844C9.58491 9.31279 8.3094 8.897 7.00007 8.897C5.69072 8.897 4.41521 9.31279 3.35741 10.0844C2.29962 10.8561 1.51418 11.9437 1.11435 13.1905"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
