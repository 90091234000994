import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarIcon, CloseIcon, LongArrowLeftIcon } from '../Icons';
import dayjs from 'dayjs';
import cn from 'classnames';
import { useIsMobile } from '@hooks/utils';

interface DatePickerProps {
  initialValue?: Date | null;
  icon?: React.ReactNode;
  onChange: (value: Date) => void;
  inputClass?: string;
  customInput?: React.ReactElement;
  minDate?: Date | null;
  onClear?: () => void;
}

const DatePickerInput: React.FC<DatePickerProps> = ({
  onChange,
  customInput,
  icon,
  initialValue = null,
  inputClass,
  minDate,
}) => {
  const isMobile = useIsMobile();
  const [viewMode, setViewMode] = useState<'days' | 'months' | 'years'>('days');
  const [selectedDate, setSelectedDate] = useState<Date | null>(initialValue);

  useEffect(() => {
    setSelectedDate(initialValue);
  }, [initialValue]);

  const handleDateChange = (date: Date | null): void => {
    if (date) {
      setSelectedDate(date);
      onChange(date);

      setViewMode('days');
    }
  };

  const renderCustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: {
    date: Date;
    decreaseMonth: () => void;
    increaseMonth: () => void;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
  }) => (
    <div className="react-datepicker__header mx-4 flex items-center justify-between px-2">
      <button
        className="rounded-full bg-interfaceColor-10 p-2"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <LongArrowLeftIcon color="text-interfaceColor-100" size="10" />
      </button>
      <div className="react-datepicker__current-month flex gap-x-1">
        <span className="cursor-pointer" onClick={() => setViewMode('months')}>
          {date.toLocaleString('default', { month: 'long' })}
        </span>
        <span className="cursor-pointer" onClick={() => setViewMode('years')}>
          {date.getFullYear()}
        </span>
      </div>
      <button
        className="rounded-full bg-interfaceColor-10 p-2"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <LongArrowLeftIcon
          color="text-interfaceColor-100"
          classNames="rotate-180"
          size="10"
        />
      </button>
    </div>
  );

  const CustomInput = forwardRef<
    HTMLButtonElement,
    { value?: string; onClick?: () => void }
  >(({ value, onClick }, ref) => (
    <button
      ref={ref}
      type="button"
      className={cn(
        'flex justify-between gap-x-1 rounded-[4px] border border-interfaceColor-40 p-2 md:min-w-[160px] md:gap-x-4',
        {
          'text-interfaceColor-80': !selectedDate,
        }
      )}
      onClick={onClick}
    >
      <span>{value ? dayjs(value).format('DD/MM/YYYY') : 'DD/MM/YYYY'}</span>
      {/* {selectedDate && (
        <span onClick={onClear}>
          <CloseIcon />
        </span>
      )} */}

      <CalendarIcon />
    </button>
  ));

  CustomInput.displayName = 'CustomInput';

  return (
    <div className={inputClass}>
      {icon}
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        renderCustomHeader={renderCustomHeader}
        showMonthYearPicker={viewMode === 'months'}
        showYearPicker={viewMode === 'years'}
        customInput={customInput || <CustomInput />}
        shouldCloseOnSelect={true}
        showPopperArrow={false}
        withPortal={isMobile}
        yearItemNumber={18}
        disabledKeyboardNavigation
        showTwoColumnMonthYearPicker
        minDate={minDate}
        popperPlacement={isMobile ? 'bottom' : 'top-start'}
        portalId={isMobile ? 'root-portal' : undefined}
      />
    </div>
  );
};

export default DatePickerInput;
