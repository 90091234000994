import { API_ENDPOINTS } from '@api/ApiEndpoints';
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from 'react-query';
import { Notification, UseNotificationQueryOptions } from './types';
import { useHttpClient } from '@hooks/utils/httpConfig';

export function useNotificationsQuery(): UseQueryResult<Notification[]> {
  const notificationsClient = useHttpClient();
  const fetchNotifications = async (): Promise<Notification[]> => {
    return notificationsClient.get<Notification[]>(
      `${API_ENDPOINTS.NOTIFICATIONS}`
    );
  };

  return useQuery(['notifications'], fetchNotifications);
}

export function useNotificationInfiniteScroll({
  pageSize = 20,
}: UseNotificationQueryOptions = {}): UseInfiniteQueryResult<Notification[]> {
  const notificationsClient = useHttpClient();
  const fetchNotifications = async ({
    pageParam = 1,
  }): Promise<Notification[]> => {
    const params = {
      pageSize: pageSize,
      pageNumber: pageParam,
    };

    if (pageParam) {
      return notificationsClient.get<Notification[]>(
        API_ENDPOINTS.NOTIFICATIONS_PAGE,
        params
      );
    } else {
      return [];
    }
  };

  return useInfiniteQuery('notificationInfiniteScroll', fetchNotifications, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length < pageSize) {
        return null;
      } else {
        return pages.length + 1;
      }
    },
  });
}

export const useDismissNotification = () => {
  const notificationsClient = useHttpClient();
  return useMutation((notificationId: string) =>
    notificationsClient.patch(
      `${API_ENDPOINTS.NOTIFICATIONS}/${notificationId}/dismiss`,
      null
    )
  );
};
