import { useEffect, useState } from 'react';

import { TilesProps } from '@common/types';
import { useUserServiceAdoptionTrendQuery } from '@hooks/adoption';
import {
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { getTrendData, getTrendLabels } from '@utils/index';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { DashboardTiles } from '@common/constants';

type Params = {
  days: string;
  dataType: string;
};

export const UserServiceAdoptionTrend: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.UserServiceAdoptionTrend,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: userServiceAdoptionTrendData,
    isLoading: isUserServiceAdoptiondDataLoading,
    refetch,
  } = useUserServiceAdoptionTrendQuery(parameters);

  useEffect(() => {
    setParameters(JSON.parse(params));

    return () => {
      refetch();
    };
  }, [params, refetch]);

  const labels = getTrendLabels(
    userServiceAdoptionTrendData?.trend || [],
    +parameters.days
  );

  const trendData = getTrendData(
    userServiceAdoptionTrendData?.trend || [],
    +parameters.days
  );

  const handleDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      dataType: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Messages',
      value: 'messages',
      action: () => handleDropdownChange('messages'),
    },
    {
      id: '2',
      label: 'Emails',
      value: 'emails',
      action: () => handleDropdownChange('emails'),
    },
    {
      id: '3',
      label: 'Phone',
      value: 'phone',
      action: () => handleDropdownChange('phone'),
    },
    {
      id: '4',
      label: 'Video calls',
      value: 'videocalls',
      action: () => handleDropdownChange('videocalls'),
    },
  ];

  return (
    parameters && (
      <LineChartTile
        classNames={
          classNames ? classNames : 'col-span-6 md:col-span-6 xl:col-span-4'
        }
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        initialSelectedItem={sortMenuItems.find(
          (item) => item.value === parameters.dataType
        )}
        sortData={sortMenuItems}
        compareData={[]}
        showCompareDropdown={false}
        showAgainstDropdown={true}
        legendLabels={[]}
        tooltipLabel={['']}
        headerTitle={'Service adoption: Trend over time'}
        isLoading={isUserServiceAdoptiondDataLoading}
        labels={labels}
        data={[trendData]}
        compareLabel=""
        dropdownMenuItemClasses={'!w-[300px]'}
        dataSetTypes={[LineChartTypes.Dots]}
        dataSetColors={['#00CF6C']}
      />
    )
  );
};
