import { FailedLoginDataCountries } from '@hooks/security/types';

export const createCellValue = (
  property: keyof FailedLoginDataCountries,
  data: FailedLoginDataCountries[],
  index: number
) => {
  const value = data[index][property];

  switch (property) {
    case 'countryName':
      return (
        <>
          <span>{value}</span>
        </>
      );
    case 'numberOfFailedLogins':
      return (
        <>
          <span>{value}</span>
        </>
      );
    case 'numberOfSuccessfulLogins':
      return (
        <>
          <span>{value}</span>
        </>
      );
  }
};
