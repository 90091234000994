import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps, TrendDataItem } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useLicencesInventoryChangesTrendQuery } from '@hooks/utilisation';
import {
  LineChartTickFormat,
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { getTrendData, getTrendLabels } from '@utils/index';
import { useState } from 'react';

type Params = {
  days: string;
};

export const LicenseInventoryChangesOverTime: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.LicenseInventoryChangesOverTime,
    parameters
  );
  const isOnDashboardPage = !!id;
  // data hook
  const {
    data: licencesInventoryChangesTrendData,
    isLoading: isLicencesInventoryChangesTrendDataLoading,
    refetch,
  } = useLicencesInventoryChangesTrendQuery(parameters);

  const tmpTrend: TrendDataItem[] = [];
  const tmpCompareAgainst: TrendDataItem[] = [];
  if (
    licencesInventoryChangesTrendData &&
    licencesInventoryChangesTrendData.licenseInventory
  ) {
    licencesInventoryChangesTrendData.licenseInventory.forEach((item) => {
      tmpTrend.push({ date: item.date, value: item.totalLicenses });
      tmpCompareAgainst.push({ date: item.date, value: item.inactiveLicenses });
    });
  }

  const labels = getTrendLabels(tmpTrend, +parameters.days);

  const compareAgainstTrendData = getTrendData(
    tmpCompareAgainst,
    +parameters.days
  );

  const trendData = getTrendData(tmpTrend, +parameters.days);

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleDropdownChange('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleDropdownChange('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleDropdownChange('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleDropdownChange('90'),
    },
  ];

  const handleDropdownChange = (value: string) => {
    const newParameters = {
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  return (
    parameters && (
      <LineChartTile
        classNames={`col-span-6 md:col-span-6 xl:col-span-4 ${classNames}`}
        sortData={sortMenuItems}
        legendLabels={['Total licenses', 'Inactive licenses']}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        initialSelectedItem={sortMenuItems.find(
          (item) => item.value === parameters.days
        )}
        tooltipLabel={['Total licenses', 'Inactive licenses']}
        headerTitle={'License inventory changes over time'}
        isLoading={isLicencesInventoryChangesTrendDataLoading}
        labels={labels}
        data={[trendData, compareAgainstTrendData]}
        compareLabel=""
        dropdownMenuItemClasses={'!w-[300px]'}
        buttonLabel="License inventory data"
        dataSetTypes={[LineChartTypes.Dots, LineChartTypes.Dots]}
        dataSetColors={['#00CF6C', '#3F454B']}
        linkPath={ANALYTICS_NAVIGATION_MAP['licenseInventory']}
        handleButtonClick={handleButtonClick()}
        showYTicks={isOnDashboardPage ? false : true}
        showXTicks={isOnDashboardPage ? false : true}
        isDotted={isOnDashboardPage ? false : true}
        chartHeight={150}
        xTickFormat={LineChartTickFormat.Date}
        showDateRange={true}
      />
    )
  );
};
