import {
  DEFAULT_PAGE_SIZE,
  LOCATION_TABLE_COLUMNS,
  PEOPLE_FILTER_BY,
  PEOPLE_SORT_BY,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { OverlayPage } from '@components/partials/OverlayPage';
import { TableWrapper } from '@components/partials/TableWrapper';
import { UsersMoveIcon } from '@components/ui';
import { useLocationsQuery, useMembersQuery } from '@hooks/locations';
import { PeopleSortBy } from '@hooks/people/types';
import { usePagination } from '@hooks/utils/pagination';
import useTableStore from '@store/tableStore';
import { hasPermission, isSingularOrPluralPeopleText, useTableData } from '@utils/index';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { MoveLocationModal } from './Modals/MoveLocationModal';
import { createCellValue } from './utils';
import { UserRoles, Permissions } from '@hooks/users/types';
import useAuthStore from '@store/authStore';

const pageSize = DEFAULT_PAGE_SIZE;

export const MembersPage: React.FC = () => {
  const { locationId } = useParams();
  const { pageNumber, setPageNumber } = usePagination();
  const [search, setSearch] = useState('');
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;

  const checkboxHasPermission = hasPermission(
    userRole,
    Permissions.ADMINS_SUPPORTUSER
  );

  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
    filter: withDefault(StringParam, ''),
  });

  const query = useMembersQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    personStatus: queryParams.filter,
    locationId,
  });
  const getTableData = useTableData(
    query,
    LOCATION_TABLE_COLUMNS,
    createCellValue
  );
  const locationsQuery = useLocationsQuery();
  const { allSelectedItems } = useTableStore();
  const currentLocation = query?.data?.items?.[0]?.location;

  const [isMovingMemberLocationModalOpen, setIsMovingMemberLocationModalOpen] =
    useState(false);
  const handleLocationModalClose = () => {
    setIsMovingMemberLocationModalOpen(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      path="/accounts/locations"
      headerTitle={`People assigned to ${currentLocation?.name ?? ''}`}
      headerText="Select people to move them to a different location."
    >
      <TableWrapper
        search={search}
        setSearch={setSearch}
        columns={LOCATION_TABLE_COLUMNS}
        data={getTableData()}
        sortData={PEOPLE_SORT_BY}
        filterData={PEOPLE_FILTER_BY}
        searchKey="search"
        query={query}
        refetchQuery={query.refetch}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        setPageNumber={setPageNumber}
        isCollapsable={false}
        sliceColumns={5}
        isDownloadAvailable={false}
        searchTag={`Location: ${currentLocation?.name ?? ''}`}
        showCheckboxes={checkboxHasPermission}
        showBulkAction={true}
        bulkContent={
          <>
            <UsersMoveIcon classNames="mr-1" />
            <span>
              Move {isSingularOrPluralPeopleText(allSelectedItems.length)}
            </span>
          </>
        }
        handleBulkAction={() => {
          setIsMovingMemberLocationModalOpen(true);
        }}
      />
      <MoveLocationModal
        items={(locationsQuery.data || []).map((item) => ({
          id: item.id,
          name: item.name,
        }))}
        isModalOpen={isMovingMemberLocationModalOpen}
        handleLocationModalClose={handleLocationModalClose}
      />
    </OverlayPage>
  );
};
