import * as React from 'react';
import { IconProps } from './types';

export const ArrowUpIcon: React.FC<IconProps> = ({
  size = '5',
  color = 'text-interfaceColor-100',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
    >
      <path
        id="Vector"
        d="M4 8.99997L8 4.99997L12 8.99997"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
