import { DashboardTiles } from '@common/constants';
import { MeetingRoomVideo, MeetingRoomVideoProps } from './MeetingRoomVideo';

export const MeetingRoomVideoSnapshot: React.FC<MeetingRoomVideoProps> = ({
  params,
  id,
  classNames,
}) => {
  return (
    <MeetingRoomVideo
      params={params}
      id={id}
      classNames={classNames}
      isSnapshot={true}
      type={DashboardTiles.MeetingRoomVideoSnapshot}
    />
  );
};
