import { Checkbox } from '@components/ui/Checkbox';
import { Dropdown, DropdownData } from '@components/ui/Dropdown';
import { HamburgerIcon, MinusIcon, PlusIcon } from '@components/ui/Icons';
import cn from 'classnames';
import { useCallback, useState } from 'react';

type ContextMenuState = {
  [key: string]: boolean;
};

type CardProps<T extends Record<string, unknown>> = {
  item: any;
  rawItem?: any;
  columns: any[];
  classNames?: string;
  headerKey?: keyof T;
  sliceColumns?: number;
  isCollapsable?: boolean;
  allSelectedItems?: any;
  handleSelect: (selected: any | null, all?: boolean) => void;
  showCheckboxes?: boolean;
  setDropDownTableRow?: (id: string) => void;
  setDropDownAction?: React.Dispatch<React.SetStateAction<string | undefined>>;
  dropDownData?: DropdownData[];
  showDropDownMobile?: boolean;
};

export const Card = <T extends Record<string, unknown>>({
  item,
  rawItem,
  columns,
  classNames = '',
  headerKey = '',
  sliceColumns = 3,
  isCollapsable = true,
  handleSelect,
  showCheckboxes = false,
  allSelectedItems,
  setDropDownTableRow,
  setDropDownAction,
  dropDownData = [],
  showDropDownMobile,
}: CardProps<T>) => {
  const [showAll, setShowAll] = useState(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<ContextMenuState>(
    {}
  );
  const [isSortModalOpen, setIsSortModalOpen] = useState<ContextMenuState>({});

  const handleShowMore = () => {
    setShowAll(!showAll);
  };

  const openContextMenu = useCallback((id: string) => {
    setIsContextMenuOpen((prevOpenMenus) => {
      const updatedOpenMenus: ContextMenuState = {};
      for (const key in prevOpenMenus) {
        updatedOpenMenus[key] = false;
      }
      return {
        ...updatedOpenMenus,
        [id]: !prevOpenMenus[id],
      };
    });
  }, []);

  const onChangeContext = (row: any, item: DropdownData) => {
    setDropDownTableRow ? setDropDownTableRow(row.id) : null;
    setDropDownAction ? setDropDownAction(item?.value?.[0]) : null;
    setIsContextMenuOpen((prevOpenMenus) => ({
      ...prevOpenMenus,
      [row.id]: !prevOpenMenus[row.id],
    }));
  };

  const handleModalOnChange = (values: string[], index: number, id: string) => {
    setDropDownTableRow ? setDropDownTableRow(item.id) : null;
    setDropDownAction ? setDropDownAction(values?.[0]) : null;

    setIsSortModalOpen((prevOpenMenus) => ({
      ...prevOpenMenus,
      [id]: !isSortModalOpen[id],
    }));
  };

  const handleMobileModalOpen = (item: any) => {
    setIsSortModalOpen((prevOpenMenus) => {
      const updatedOpenMenus: ContextMenuState = {};

      for (const key in prevOpenMenus) {
        updatedOpenMenus[key] = false;
      }

      return {
        ...updatedOpenMenus,
        [item.id]: !isSortModalOpen[item.id],
      };
    });
  };

  const handleMobileModalClose = (item: any) => {
    setIsSortModalOpen((prevOpenMenus) => ({
      ...prevOpenMenus,
      [item.id]: !isSortModalOpen[item.id],
    }));
  };

  const keepFirstNProps = (obj: any, n: number) => {
    const result: any = {};
    const keys = Object.keys(obj).slice(0, n);

    for (const key of keys) {
      result[key] = obj[key];
    }

    return result;
  };

  const showButtonText = showAll ? 'Show Less' : 'Show More';
  const firstProp = Object.keys(item)[0];
  const id = item['id'] ? item['id'] : null;
  const headerValue =
    ((headerKey ? item[headerKey] : item[firstProp]) as React.ReactNode) ?? '';

  if (headerKey) {
    const { headerKey, ...rest } = item;
    item = rest;
  } else {
    const { [firstProp]: r, ...rest } = item;
    item = rest;
  }

  const ignoreProps = ['id', 'tableDropdown'];

  const cardClasses = cn(
    'flex',
    'flex-col',
    'bg-white',
    'text-interfaceColor-100',
    'w-full',
    classNames
  );

  item = keepFirstNProps(
    item,
    showAll ? Object.keys(item).length : sliceColumns
  );

  if (id) {
    item['id'] = id;
  }

  const getSelectedItem = (row: any) => {
    return allSelectedItems?.includes(row.id) || false;
  };

  const dropdownContent = useCallback(
    (row: any) => {
      const dataDropdown: { [key: string]: DropdownData[] } = {};
      dataDropdown[row.id] = dropDownData.map((item) => ({
        id: row.id,
        ...item,
      }));

      return (
        <Dropdown
          dropdownModalProps={{
            handleModalOpen: () => handleMobileModalOpen(row),
            handleModalClose: () => handleMobileModalClose(row),
            isModalOpen: isSortModalOpen[row.id],
            data: dataDropdown[row.id],
            rowData: row,
            classNames:
              'flex h-8 w-8 items-center justify-center rounded-full bg-interfaceColor-30',
            handleModalOnChange: handleModalOnChange,
            headerValueMobile: <HamburgerIcon />,
            hasCheck: false,
          }}
        >
          <Dropdown.IconHeader
            icon={<HamburgerIcon />}
            handleOpen={() => openContextMenu(row.id)}
          ></Dropdown.IconHeader>
          <Dropdown.List
            className={'z-[9999]'}
            open={isContextMenuOpen[row.id]}
          >
            {dataDropdown[row.id].map((item) => {
              return (
                <Dropdown.ContextItem
                  key={item.label}
                  label={item.label}
                  icon={item.icon}
                  onChange={() => onChangeContext(row, item)}
                  color={item.color || ''}
                />
              );
            })}
          </Dropdown.List>
        </Dropdown>
      );
    },
    [isSortModalOpen, isContextMenuOpen, openContextMenu, onChangeContext]
  );

  return (
    <div className="mb-4 flex w-full flex-row justify-start bg-white px-5 py-4">
      {showCheckboxes && (
        <div>
          <Checkbox
            checked={getSelectedItem(item)}
            onChange={() => handleSelect(item)}
            name={`item-${item?.id}`}
          />
        </div>
      )}
      <div className={cardClasses}>
        <div
          data-testid="header"
          className="mb-1 flex flex-row text-16 font-semibold text-interfaceColor-100"
        >
          <>{headerValue}</>
        </div>
        <ul className="flex w-full flex-col">
          {Object.keys(item).map(
            (key: string, index: number) =>
              !ignoreProps.includes(key) && (
                <li
                  data-testid={`${key}`}
                  className="relative w-full text-16"
                  key={`property${index}`}
                >
                  {columns[index + 1].displayName && (
                    <span className="font-normal tracking-[0.01rem] text-interfaceColor-100">
                      {columns[index + 1].displayName}:{' '}
                    </span>
                  )}
                  {item[key]}
                </li>
              )
          )}
        </ul>

        {isCollapsable && (
          <span
            className="mt-2 flex cursor-pointer items-center text-14 underline"
            onClick={handleShowMore}
          >
            {!showAll ? (
              <PlusIcon classNames="mr-2" />
            ) : (
              <MinusIcon classNames="mr-2" />
            )}
            {showButtonText}
          </span>
        )}
      </div>
      {!!dropDownData.length && showDropDownMobile && (
        <div className="absolute right-[20px]">{dropdownContent(rawItem)}</div>
      )}
    </div>
  );
};
