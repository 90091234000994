import { CellBottomPart } from '@components/ui/Table/utils';
import { formatDate } from '@utils/index';

export const createCellValue = (
  property: string,
  data: any,
  index: number,
  isMobile: boolean
) => {
  const value = data[index][property];
  isMobile;
  switch (property) {
    case 'fullName':
      return (
        <div className="flex flex-col">
          <span>{value || 'No data available'}</span>
          <div className="block">
            <CellBottomPart data={data[index]['emailAddress']} />
          </div>
        </div>
      );
    case 'totalMsMeetingsJoined':
      return (
        <>
          <span>{value}</span>
          {value > 0 && (
            <CellBottomPart
              data={`Last: ${formatDate(data[index]['lastMsJoinedActivity'])}`}
            />
          )}
        </>
      );
    case 'totalMsMeetingsOrganized':
      return (
        <>
          <span>{value}</span>
          {value > 0 && (
            <CellBottomPart
              data={`Last: ${formatDate(
                data[index]['lastMsOrganizedActivity']
              )}`}
            />
          )}
        </>
      );
    case 'totalRcMeetingsJoined':
      return (
        <>
          <span>{value}</span>
        </>
      );
    case 'totalRcMeetingsOrganized':
      return (
        <>
          <span>{value}</span>
        </>
      );
  }
};
