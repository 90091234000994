import * as React from 'react';
import { IconProps } from './types';

export const LineChartIconUp: React.FC<IconProps> = ({
  size = '5',
  color = 'text-successColor-100',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      viewBox="0 0 17 16"
      fill="none"
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3828 6.66602H16.1923V10.4755"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M16.1915 6.66602L10.8105 12.047C10.7215 12.1342 10.6019 12.1831 10.4772 12.1831C10.3526 12.1831 10.2329 12.1342 10.1439 12.047L7.9534 9.85649C7.86439 9.76924 7.74472 9.72037 7.62007 9.72037C7.49542 9.72037 7.37575 9.76924 7.28674 9.85649L3.81055 13.3327"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
{
  /* <svg
viewBox="0 0 16 16"
fill="none"
className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
xmlns="http://www.w3.org/2000/svg"
>
<path
  d="M6.42859 2H8.7143V4.28571"
  strokeLinecap="round"
  strokeLinejoin="round"
  strokeWidth={strokeWidth}
/>
<path
  d="M8.71434 2L5.48577 5.22857C5.43236 5.28092 5.36056 5.31025 5.28577 5.31025C5.21098 5.31025 5.13917 5.28092 5.08577 5.22857L3.77148 3.91429C3.71808 3.86194 3.64627 3.83261 3.57148 3.83261C3.49669 3.83261 3.42489 3.86194 3.37148 3.91429L1.28577 6"
  strokeLinecap="round"
  strokeLinejoin="round"
  strokeWidth={strokeWidth}
/>
</svg> */
}
