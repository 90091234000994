import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { ServiceActivitiesHelper } from './helpers/ServiceActivitiesHelper';

export const MeetingsSent: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  return (
    <ServiceActivitiesHelper
      params={params}
      id={id}
      classNames={classNames}
      type={DashboardTiles.MeetingsSent}
    />
  );
};