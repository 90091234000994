import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { useAddDashboardTile, useRemoveDashboardTile } from '@hooks/dashboard';
import { useActiveSharePointSites } from '@hooks/utilisation';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { useEffect, useState } from 'react';

type Params = {
  days: string;
  includePersonalSites: boolean;
};

export const ActiveSharePointSites: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));

  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.ActiveSharePointSites,
    parameters
  );
  const isOnDashboardPage = !!id;

  const { data, isLoading, refetch } = useActiveSharePointSites(parameters);

  useEffect(() => {
    const updatedParams = JSON.parse(params);

    setParameters({
      days: updatedParams.days,
      includePersonalSites: updatedParams.includePersonalSites,
    });

    return () => {
      refetch();
    };
  }, [parameters.days, parameters.includePersonalSites, params, refetch]);

  return (
    parameters && (
      <NumberTile
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        classNames={
          classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
        }
        data={data ? [Number(data)] : [0]}
        headerTitle={'Active SharePoint sites'}
        dropdownMenuItemClasses={'!w-[300px]'}
        isLoading={isLoading}
        percentageLabel=""
      />
    )
  );
};
