import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import {
  useDeviceInventoryTypesQuery,
  useDeviceUsageSnapshotQuery,
} from '@hooks/utilisation';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useEffect, useState } from 'react';

type Params = {
  days: string;
  type: string;
};

export const DeviceUsage: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.DeviceUsage,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: deviceUsageData,
    isLoading: isServiceUsageLoading,
    refetch,
  } = useDeviceUsageSnapshotQuery(parameters);

  const { data: typesData } = useDeviceInventoryTypesQuery();

  useEffect(() => {
    setParameters(JSON.parse(params));

    // return () => {
    //   refetch();
    // };
  }, [params]);

  const daysMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 30 days',
      value: '30',
      action: () => handleDropdownChange('30'),
    },
    {
      id: '2',
      label: 'Last 60 days',
      value: '60',
      action: () => handleDropdownChange('60'),
    },
    {
      id: '3',
      label: 'Last 90 days',
      value: '90',
      action: () => handleDropdownChange('90'),
    },
  ];

  const typesMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All types',
      value: '',
      action: () => handleTypesDropdownChange(''),
    },
  ];

  const handleDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const handleTypesDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      type: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  if (typesData) {
    typesData.forEach((device, index) => {
      typesMenuItems.push({
        id: index + 2,
        label: device,
        value: device,
        action: () => handleTypesDropdownChange(device),
      });
    });
  }

  return (
    <DoughnutChartTile
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      sortData={typesMenuItems}
      compareData={daysMenuItems}
      initialSelectedItem={typesMenuItems.find(
        (item) => item.value === parameters.type
      )}
      initialCompareSelectedItem={daysMenuItems.find(
        (item) => item.value === parameters.days
      )}
      headerTitle={'Device usage'}
      dropdownMenuItemClasses={'!w-[300px]'}
      isLoading={isServiceUsageLoading}
      data={
        deviceUsageData
          ? [
              deviceUsageData?.offlineDevicesCount,
              deviceUsageData?.onlineButNotUsedDevicesCount,
              deviceUsageData?.onlineDevicesCount,
            ]
          : []
      }
      dataLabels={['Offline', 'Online, but unused', 'Used']}
      buttonLabel={'Device usage data'}
      linkPath={ANALYTICS_NAVIGATION_MAP['deviceInventory']}
      handleButtonClick={handleButtonClick()}
      showBracketLabelValue={true}
      colors={['#3F454B', '#B65AD7', '#00CF6C']}
    />
  );
};
