import { CONNECTED_SERVICES } from '@common/dropdownConstants';
import {
  ArrowRightIcon,
  ArrowLongRightIcon,
  Banner,
  Button,
  ButtonVariant,
  Card,
  CheckCircleIcon,
  DeactivateIcon,
  HamburgerIcon,
  Input,
  Loader,
  Modal,
  PlusIcon,
  ReconnectIcon,
  SandClockIcon,
  WarningIcon,
} from '@components/ui';
import { Dropdown, DropdownData } from '@components/ui/Dropdown';
import { useIsDesktop, useIsMobile, useIsTablet } from '@hooks/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { ConnectServiceModal } from './Modals/ConnectServiceModal';
import { DeactivateServiceModal } from './Modals/DeactivateServiceModal';

import { helixMsalConfig, scopeConnectionRequest } from '@api/msalConfig';
import Microsoft from '@assets/svg/microsoft.svg';
import RingCentral from '@assets/svg/ringcentral.svg';
import Mitel from '@assets/svg/mitel.svg';
import Avaya from '@assets/svg/avaya.svg';
import {
  AuthenticationResult,
  Configuration,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  useConnectedServicesQuery,
  useReactivateService,
  useServiceIntegrationReconnectHelix,
  useServiceIntegrationReconnectMS,
  useServiceIntegrationReconnectRC,
  useCancelServiceUC,
} from '@hooks/connectedServices';
import {
  ServiceIntegrationReconnectMSData,
  ServiceIntergrationResponse,
} from '@hooks/connectedServices/types';
import useUIStore from '@store/uiStore';
import { getVendorName, hasPermission } from '@utils/index';
import jwt_decode from 'jwt-decode';
import { RING_CENTRAL_CONSOLE } from '@common/constants';
import { UserRoles, Permissions } from '@hooks/users/types';
import useAuthStore from '@store/authStore';
import { Variant } from '@components/ui/Toast';
import { useNavigate } from 'react-router-dom';
import useModalStore from '@store/modal';
import { DropdownMenu, MenuItem } from '@components/ui/DropdownMenu';

type ContextMenuState = {
  [key: string]: boolean;
};

export enum AvailableServices {
  MICROSOFT = 0,
  RINGCENTRAL = 1,
}

const ConnectedServicesPage: React.FC = () => {
  const navigate = useNavigate();
  const { isHelixSuccessModalOpen, setHelixSuccessModalOpen } = useModalStore();
  const isDesktop = useIsDesktop();
  const [isConnectServiceModalOpen, setIsConnectServiceModalOpen] =
    useState(false);
  const [isDeactivateServiceModalOpen, setIsDeactivateServiceModalOpen] =
    useState(false);
  const [serviceForModals, setServiceForModals] = useState<
    ServiceIntergrationResponse | undefined
  >(undefined);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<ContextMenuState>(
    {}
  );
  const [isRingCentralModalOpen, setIsRingCentralModalOpen] = useState(false);
  const [isUCentricModalOpen, setIsUCentricModalOpen] = useState(false);
  const [isSortModalOpen, setIsSortModalOpen] = useState<ContextMenuState>({});
  const { addToast } = useUIStore();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const [selectedId, setSelectedId] = useState<string>('');
  const [ringCentralJWT, setRingCentralJWT] = useState('');
  const [validationJWTMessage, setValidationJWTMessage] = useState<
    string | undefined
  >(undefined);
  const [isInfoBannerVisible, setInfoBannerVisible] = useState(true);
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;

  const configureBtnHasPermission = hasPermission(
    userRole,
    Permissions.SUPERADMIN_SUPPORTUSER
  );

  const [isHelixModalVisible, setIsHelixModalVisible] = useState(false);

  useEffect(() => {
    if (isHelixSuccessModalOpen) {
      const timer = setTimeout(() => {
        setIsHelixModalVisible(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isHelixSuccessModalOpen]);

  const openContextMenu = useCallback((id: string) => {
    setIsContextMenuOpen((prevOpenMenus) => {
      const updatedOpenMenus: ContextMenuState = {};
      for (const key in prevOpenMenus) {
        updatedOpenMenus[key] = false;
      }
      return {
        ...updatedOpenMenus,
        [id]: !prevOpenMenus[id],
      };
    });
  }, []);

  const { data, isLoading, isFetching, isSuccess, refetch } =
    useConnectedServicesQuery();

  const { mutateAsync: mutateAsyncDeactivateService } = useReactivateService();

  const handleReactivateService = async (
    service: ServiceIntergrationResponse
  ) => {
    try {
      await mutateAsyncDeactivateService(service?.connectionId ?? '0');
      addToast({
        variant: Variant.Success,
        message: `${getVendorName(service?.vendor)} service is now connected.`,
        closeable: true,
      });
      refetch();
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: `Something went wrong, and ${service?.vendor} service wasn't connected.`,
        closeable: true,
      });
    }
  };

  const { mutateAsync: mutateAsyncCancelService } = useCancelServiceUC();

  const handleCancelServiceUC = async (
    service: ServiceIntergrationResponse
  ) => {
    try {
      await mutateAsyncCancelService(service?.connectionId ?? '0');
      addToast({
        variant: Variant.Success,
        message: `We've cancelled your ${getVendorName(service?.vendor)} connection.  If you change your mind you can start the process again.`,
        closeable: true,
      });
      refetch();
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: `Something went wrong, and ${service?.vendor} service wasn't cancelled.`,
        closeable: true,
      });
    }
  };

  const msalConfig: Configuration = {
    auth: {
      clientId: window.apiClientId || 'fe34167c-4e95-4a74-9ad6-6b1bc1b0fcbb',
      authority: 'https://login.microsoftonline.com/common',
      redirectUri: window.location.origin,
    },
  };

  const serviceIntegrationReconnectMS = useServiceIntegrationReconnectMS();
  const serviceIntegrationReconnectHelix =
    useServiceIntegrationReconnectHelix();

  const serviceIntegrationReconnectRC = useServiceIntegrationReconnectRC();

  const reconnectMSHandler = async (id: string) => {
    const msalInstance = await new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    return await msalInstance
      .loginPopup({ ...scopeConnectionRequest, prompt: 'consent' })
      .then(async (response: AuthenticationResult) => {
        if (response) {
          const data: ServiceIntegrationReconnectMSData = {
            tenantId: response.tenantId,
            connectionId: id,
          };

          try {
            const response = await serviceIntegrationReconnectMS.mutateAsync(
              data
            );

            if (response) {
              addToast({
                variant: Variant.Success,
                message: 'Microsoft service is now connected.',
                closeable: true,
              });
              refetch();
            }
          } catch (error) {
            addToast({
              variant: Variant.Error,
              message: `Something went wrong, and Microsoft service wasn't connected.`,
              closeable: true,
            });
          }
        }
      })
      .catch((e: any) => console.log(e));
  };

  const handleRingCentralConnection = async () => {
    try {
      const data = {
        jwt: ringCentralJWT,
        connectionId: serviceForModals?.connectionId,
      };
      const response = await serviceIntegrationReconnectRC.mutateAsync(data);

      if (response) {
        setIsRingCentralModalOpen(false);
        addToast({
          variant: Variant.Success,
          message: 'RingCentral service is now connected.',
          closeable: true,
        });
        refetch();
      }
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: `Something went wrong, and RingCentral service wasn't connected.`,
        closeable: true,
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      jwt_decode(event.target.value);
      setRingCentralJWT(event.target.value);
      setValidationJWTMessage(undefined);
    } catch (e) {
      setValidationJWTMessage('Invalid JWT format');
    }
  };

  const onChangeContext = (row: any, item: DropdownData) => {
    setServiceForModals(row);
    if (item?.value?.[0] === 'deactivate') {
      setIsDeactivateServiceModalOpen(true);
    }

    if (item?.value?.[0] === 'reconnect') {
      handleReactivateService(row);
    }

    if (item?.value?.[0] === 'reactivate') {
      if (row.vendor === AvailableServices.MICROSOFT) {
        reconnectMSHandler(row.connectionId);
      }
      if (row.vendor === AvailableServices.RINGCENTRAL) {
        setIsRingCentralModalOpen(true);
        window.open(`${RING_CENTRAL_CONSOLE}${window.apiRingCentralClientId}`);
      }
    }
    
    if (item?.value?.[0] === 'cancel') {
      handleCancelServiceUC(row);
    }

    setIsContextMenuOpen((prevOpenMenus) => ({
      ...prevOpenMenus,
      [row.id]: !prevOpenMenus[row.id],
    }));
  };

  const handleModalOnChange = (values: string[], index: number, id: string) => {
    if (values?.[0] === 'deactivate') {
      if (data?.length) {
        const selectedService = data.filter(
          (service) => service.connectionId === id
        )[0];
        setServiceForModals(selectedService);
        setIsDeactivateServiceModalOpen(true);
      }
    }

    if (values?.[0] === 'reconnect') {
      if (data?.length) {
        const selectedService = data.filter(
          (service) => service.connectionId === id
        )[0];
        handleReactivateService(selectedService);
      }
    }

    if (values?.[0] === 'reactivate') {
      if (data?.length) {
        const selectedService = data.filter(
          (service) => service.connectionId === id
        )[0];

        if (selectedService.vendor === AvailableServices.MICROSOFT) {
          reconnectMSHandler(selectedService.connectionId);
        }
        if (selectedService.vendor === AvailableServices.RINGCENTRAL) {
          setIsRingCentralModalOpen(true);
          setServiceForModals(selectedService);
          window.open(
            `${RING_CENTRAL_CONSOLE}${window.apiRingCentralClientId}`
          );
        }
      }
    }
    if (values?.[0] === 'cancel') {
      if (data?.length) {
        const selectedService = data.filter(
          (service) => service.connectionId === id
        )[0];
        handleCancelServiceUC(selectedService);
      }
    }

    setIsSortModalOpen((prevOpenMenus) => ({
      ...prevOpenMenus,
      [id]: !isSortModalOpen[id],
    }));
  };

  const handleMobileModalOpen = (item: any) => {
    setIsSortModalOpen((prevOpenMenus) => {
      const updatedOpenMenus: ContextMenuState = {};
      for (const key in prevOpenMenus) {
        updatedOpenMenus[key] = false;
      }
      return {
        ...updatedOpenMenus,
        [item.connectionId]: !isSortModalOpen[item.connectionId],
      };
    });
  };

  const handleMobileModalClose = (item: any) => {
    setIsSortModalOpen((prevOpenMenus) => ({
      ...prevOpenMenus,
      [item.connectionId]: !isSortModalOpen[item.connectionId],
    }));
  };

  const dropdownContent = useCallback(
    (row: ServiceIntergrationResponse) => {
      const dataDropdown: { [key: string]: DropdownData[] } = {};
      dataDropdown[row.connectionId] = CONNECTED_SERVICES.map((item) => ({
        id: row.connectionId,
        ...item,
      }));
      return (
        <Dropdown
          dropdownModalProps={{
            handleModalOpen: () => handleMobileModalOpen(row),
            handleModalClose: () => handleMobileModalClose(row),
            isModalOpen: isSortModalOpen[row.connectionId],
            data: dataDropdown[row.connectionId],
            rowData: row,
            classNames:
              'flex h-8 w-8 items-center justify-center rounded-full bg-interfaceColor-30',
            handleModalOnChange: handleModalOnChange,
            headerValueMobile: <HamburgerIcon />,
            hasCheck: false,
          }}
        >
          <Dropdown.IconHeader
            icon={<HamburgerIcon />}
            handleOpen={() => openContextMenu(row.connectionId)}
          ></Dropdown.IconHeader>
          <Dropdown.List open={isContextMenuOpen[row.connectionId]}>
            {dataDropdown[row.connectionId].map((item) => {
              return (
                <Dropdown.ContextItem
                  key={item.label}
                  label={item.label}
                  icon={item.icon}
                  onChange={() => onChangeContext(row, item)}
                  color={item.color || ''}
                  hidden={item.isHidden && item.isHidden(row)}
                />
              );
            })}
          </Dropdown.List>
        </Dropdown>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSortModalOpen, isContextMenuOpen, openContextMenu, onChangeContext]
  );

  const helixIntegration = data?.find((item) => item.vendor === 2) || null;

  const connectEnhancedSecurityHandler = async () => {
    const msalInstance = new PublicClientApplication(helixMsalConfig);
    await msalInstance.initialize();
    return await msalInstance
      .loginPopup({ ...scopeConnectionRequest, prompt: 'consent' })
      .then(async (response: AuthenticationResult) => {
        if (response && helixIntegration) {
          const data: ServiceIntegrationReconnectMSData = {
            tenantId: response.tenantId,
            connectionId: helixIntegration.connectionId,
          };
          try {
            const response = await serviceIntegrationReconnectHelix.mutateAsync(
              data
            );

            if (response) {
              addToast({
                variant: Variant.Success,
                message: 'Enhanced Security Insights are connected.',
                closeable: true,
              });
              refetch();
            }
          } catch (error) {
            addToast({
              variant: Variant.Error,
              message: `Something went wrong, and Enhanced Security Insights wasn't connected.`,
              closeable: true,
            });
          }
        }
      })
      .catch((e: any) => console.log(e));
  };

  const handleItemAction = (type: string) => () => {
    if (type === 'deactivate' && helixIntegration) {
      setServiceForModals(helixIntegration);
      setIsDeactivateServiceModalOpen(true);
    }

    if (type === 'reactivate' && helixIntegration) {
      handleReactivateService(helixIntegration);
    }
    alert("cancel");
  };


  const createMenuItems = (): MenuItem[] => {
    const items: MenuItem[] = [];
    if (helixIntegration?.status === 0) {
      items.push({
        id: '0',
        label: 'Deactivate service',
        icon: <DeactivateIcon classNames="mr-1" size="4" />,
        value: 'deactivate',
        action: handleItemAction('deactivate'),
      });
    }

    if (helixIntegration?.status === 1) {
      items.push({
        id: '0',
        label: 'Reactivate service',
        icon: <ReconnectIcon classNames="mr-1" size="4" />,
        value: 'reactivate',
        action: handleItemAction('reactivate'),
      });
    }

    return items;
  };

  const getVendorLogo = (vendor: number): string => {
    switch (vendor) {
      case 0:
        return Microsoft;
      case 1:
        return RingCentral;
      case 3:
        return Mitel;
      case 4: 
        return Avaya;
      default:
        return Microsoft;
    }
  };


  if (isLoading || isFetching) {
    return <Loader />;
  }

  return (
    <>
      {isSuccess && data.length ? (
        <div>
          {isInfoBannerVisible && (
            <Banner
              closeable={true}
              onClose={() => setInfoBannerVisible(false)}
              message="Connected services may take a few minutes to update after you make changes. Please, try to refresh the page. If things aren’t working as you expected after 15 minutes, contact us."
              className="mb-4 w-full md:w-[534px] lg:w-[704px]"
            />
          )}
          {helixIntegration && helixIntegration.status === 4 && (
            <Banner
              className="mb-4 w-full md:w-[534px] lg:w-[704px]"
              message={
                <div className="flex flex-col justify-start gap-y-2">
                  <p>
                    Enhanced Security Insights are almost ready. Click the
                    button to complete setup.
                  </p>
                  <Button
                    variant="outlineLight"
                    size="medium"
                    className={'mt-3 w-fit text-interfaceColor-100 md:mt-0'}
                    onClick={() => {
                      connectEnhancedSecurityHandler();
                    }}
                  >
                    Complete setup
                    <ArrowRightIcon
                      color="text-interfaceColor-100"
                      size="2"
                      classNames="ml-2 mt-[3px] text-interfaceColor-100"
                    />
                  </Button>
                </div>
              }
              variant={'info'}
              closeable={false}
            />
          )}
          <div className="mt-6">
            {data
              .filter((item) => item.vendor !== 2)
              .map((service) => (
                <Card
                  classNames={`bg-white mb-8`}
                  key={`service-${service.connectionId}`}
                >
                  <div
                    className={`relative w-full ${
                      service.vendor === 0 ? '!mb-[60px]  lg:!mb-[16px]' : ''
                    }`}
                  >
                      <img
                        className="absolute left-1 top-[25px] -translate-y-1/2 transform lg:top-1/2"
                        src={getVendorLogo(service.vendor)}
                      />

                    <Card.Header classNames="text-20 leading-7 ml-12">
                      {getVendorName(service.vendor)}
                    </Card.Header>

                    <Card.Content
                      classNames={`ml-12 ${service.vendor === 0 ? 'pb-1' : ''}`}
                    >
                      <div className="">
                        <p className="text-interfaceColor-80">
                          {service?.tenantName}
                        </p>
                        <Button
                          size="small"
                          className={`relative mt-3 cursor-default !pl-[8px] !pr-[8px] xl:absolute xl:mt-0 ${
                            isDesktop
                              ? `transform ${
                                  userRole === UserRoles.SUPERADMIN ||
                                  userRole === UserRoles.SUPPORT
                                    ? 'lg:right-[57px]'
                                    : 'lg:right-[12px]'
                                } lg:top-1/2 lg:-translate-y-1/2`
                              : 'w-full transform xl:bottom-[15px] xl:left-2 xl:mx-2 xl:w-[calc(100%-30px)]'
                          } ${
                            service.status === 0 ? '!bg-successColor-10' : ''
                          } ${service.status === 2 ? '!bg-errorColor-10' : ''}${
                            service.status === 1 || service.status === 5 ? '!bg-interfaceColor-20' : ''
                          }`}
                          variant={
                            service
                              ? ((service.status === 0
                                  ? 'successOutline'
                                  : service.status === 2
                                  ? 'dangerOutline'
                                  : service.status === 1 || service.status === 5
                                  ? 'inactiveOutline'
                                  : 'default') as ButtonVariant)
                              : ('default' as ButtonVariant)
                          }
                        >
                          {service.status === 0 && (
                            <CheckCircleIcon
                              color="text-successColor-100"
                              classNames="h-4 w-4 mx-1 align-middle inline mr-1"
                            />
                          )}
                          {service.status === 2 && (
                            <WarningIcon
                              color="text-errorColor-100"
                              classNames="h-4 w-4 mx-1 align-middle inline mr-1"
                            />
                          )}
                          {service.status === 1 && (
                            <DeactivateIcon
                              color="text-interfaceColor-80"
                              classNames="h-4 w-4 mx-1 align-middle inline mr-1"
                            />
                          )}
                          {service.status === 5 && (
                            <SandClockIcon
                              color="text-interfaceColor-80"
                              classNames="h-4 w-4 mx-1 align-middle inline mr-1"
                            />
                          )}
                          {service.status === 0
                            ? 'Connected'
                            : service.status === 1
                            ? 'Inactive'
                            : service.status === 5
                            ? 'In Progress'
                            :'Disconnected'}
                        </Button>
                        {configureBtnHasPermission && (
                          <div className="absolute right-[10px] top-[25px] z-50 -translate-y-1/2 transform lg:top-1/2">
                            {dropdownContent(service)}
                          </div>
                        )}
                      </div>
                    </Card.Content>
                  </div>
                  {service.vendor === 0 && (
                    <Card.Footer classNames="relative border-t pt-4 border-t-interfaceColor-20">
                      {helixIntegration?.vendor === 2 ? (
                        <Card classNames="!bg-interfaceColor-5 !shadow-none !w-full">
                          <Card.Header classNames="flex flex-col lg:flex-row items-start lg:items-center justify-start lg:justify-between w-full">
                            <div>Enhanced Security Insights</div>

                            <div className="flex w-full items-center gap-x-4 lg:w-auto">
                              {(helixIntegration.status === 3) && (
                                <div className="mt-3 flex w-full items-center justify-center rounded-full border border-interfaceColor-80 bg-interfaceColor-20 px-[12px] py-[3px] lg:mt-0 lg:w-auto">
                                  <SandClockIcon
                                    color="text-interfaceColor-80"
                                    classNames="mr-1 mt-[1px]"
                                  />

                                  <span className="text-interfaceColor-80">
                                    In progress
                                  </span>
                                </div>
                              )}
                              {(helixIntegration.status === 4) && (
                                <div>
                                  <Button
                                    size="small"
                                    variant="dark"
                                    className={`${isDesktop ?
                                      userRole === UserRoles.SUPERADMIN ||
                                        userRole === UserRoles.SUPPORT ? 'lg:right-[57px] absolute transform lg:-translate-y-1/2' :
                                        'lg:right-[12px] absolute transform lg:-translate-y-1/2'
                                      : 'relative w-full transform xl:bottom-[15px] lg:right-[40px] xl:left-2 xl:mx-2 xl:w-[calc(100%-30px)]'}`}
                                    onClick={() => {
                                      connectEnhancedSecurityHandler();
                                    }}>
                                    Complete setup
                                    <ArrowLongRightIcon />
                                  </Button>
                                  {helixIntegration && (
                                    <div className={`${isDesktop ? '' : 'lg:mt-[15px]'} absolute right-[10px] inline-block -translate-y-1/2`}>
                                      <DropdownMenu
                                        closeOnClickOutside={true}
                                        items={[]}
                                        buttonProps={{
                                          icon: <HamburgerIcon></HamburgerIcon>,
                                        }}
                                        dropdownMenuClasses={'hidden'}
                                      ></DropdownMenu></div>
                                  )}
                                </div>
                              )}

                              {helixIntegration.status === 0 && (
                                <div className="mt-3 flex w-full items-center justify-center rounded-full border border-successColor-100 bg-successColor-10 px-[12px] py-[3px] lg:mt-0 lg:w-auto">
                                  <CheckCircleIcon
                                    color="text-successColor-100"
                                    classNames="h-4 w-4 mx-1 align-middle inline mr-1"
                                  />

                                  <span className="text-successColor-100">
                                    Connected
                                  </span>
                                </div>
                              )}

                              {helixIntegration.status === 1 && (
                                <div className="mt-3 flex w-full items-center justify-center rounded-full border border-interfaceColor-20 bg-interfaceColor-20 px-[12px] py-[3px] lg:mt-0 lg:w-auto">
                                  <CheckCircleIcon
                                    color="text-interfaceColor-80"
                                    classNames="h-4 w-4 mx-1 align-middle inline mr-1"
                                  />

                                  <span className="text-interfaceColor-80">
                                    Inactive
                                  </span>
                                </div>
                              )}

                              {helixIntegration &&
                              createMenuItems().length > 0 ? (
                                <DropdownMenu
                                  closeOnClickOutside={true}
                                  items={createMenuItems()}
                                  buttonProps={{
                                    icon: <HamburgerIcon></HamburgerIcon>,
                                  }}
                                  dropdownMenuClasses={'!w-[220px]'}
                                ></DropdownMenu>
                              ) : null}
                            </div>
                          </Card.Header>
                        </Card>
                      ) : (
                        configureBtnHasPermission && <Banner
                          message={
                            <div className="flex flex-col justify-start gap-y-2">
                              <p>
                                Activate Enhanced Security Insights to keep your
                                business safe.
                              </p>
                              <Button
                                variant="outlineLight"
                                size="small"
                                className={
                                  'mt-3 w-fit text-interfaceColor-100 md:mt-0'
                                }
                                onClick={() =>
                                  navigate('/settings/enhanced-security')
                                }
                              >
                                Activate now
                                <ArrowRightIcon
                                  color="text-interfaceColor-100"
                                  size="2"
                                  classNames="ml-2 mt-[3px] text-interfaceColor-100"
                                />
                              </Button>
                            </div>
                          }
                          variant={'info'}
                          closeable={false}
                        />
                      )}
                    </Card.Footer>
                  )}
                </Card>
              ))}
          </div>

          {configureBtnHasPermission && (
            <Button
              size="large"
              variant="default"
              className="!md:h-10 !h-[70px] w-full !justify-start rounded-[4px] !bg-interfaceColor-10 text-20 !text-interfaceColor-100 sm:w-[375px] md:w-[534px] lg:w-[704px]"
              onClick={() => setIsConnectServiceModalOpen(true)}
            >
              <PlusIcon classNames="mr-2 md:w-4 w-[20px]" />
              <span className="text-left">
                Get more insights: Add another service
              </span>
            </Button>
          )}
        </div>
      ) : null}

      {isSuccess && !data.length && (
        <Card
          classNames={`bg-white relative mb-6 !pb-[60px] lg:!pb-[16px]`}
          key="no-results"
        >
          <Card.Header classNames="text-20 leading-7">
            <p>You don`t have any connected services</p>
          </Card.Header>

          <Card.Content>
            <div>
              Connect a service to get insights and analytics for it.
              {configureBtnHasPermission && (
                <Button
                  size="medium"
                  className={`absolute ${
                    isDesktop
                      ? 'transform lg:right-[15px] lg:top-1/2 lg:-translate-y-1/2'
                      : 'bottom-[15px] left-1/2 -translate-x-1/2 transform'
                  }`}
                  variant="primary"
                  onClick={() => setIsConnectServiceModalOpen(true)}
                >
                  Connect a service
                </Button>
              )}
            </div>
          </Card.Content>
        </Card>
      )}

      <ConnectServiceModal
        activeServices={data}
        msalConfig={msalConfig}
        isModalOpen={isConnectServiceModalOpen}
        handleModalClose={() => {
          setIsConnectServiceModalOpen(false);
        }}
        handleSuccess={() => {
          setIsConnectServiceModalOpen(false);
          refetch();
        }}
      />

      <DeactivateServiceModal
        service={serviceForModals}
        isModalOpen={isDeactivateServiceModalOpen}
        handleModalClose={() => {
          setIsDeactivateServiceModalOpen(false);
        }}
        handleSuccess={() => {
          setIsDeactivateServiceModalOpen(false);
          refetch();
        }}
      />

      <Modal
        headerTxt="We've opened RingCentral in a new tab"
        modalClassName={'h-full mt-0'}
        isTruncatedHeader={false}
        isOpen={isRingCentralModalOpen}
        size={isTablet || isMobile ? 'small' : 'medium'}
        position={isMobile ? 'bottom' : 'default'}
        showCloseBtn={true}
        onClose={() => {
          setSelectedId('');
          setIsRingCentralModalOpen(false);
        }}
        actionButtons={
          <div className="mt-5 flex flex-col gap-2 lg:flex-row">
            <Button
              type="submit"
              variant="primary"
              loading={serviceIntegrationReconnectRC.isLoading}
              disabled={!ringCentralJWT}
              onClick={handleRingCentralConnection}
            >
              Done: Connect RingCentral
            </Button>
            <Button
              variant="outlineLight"
              onClick={() => {
                setSelectedId('');
                setIsRingCentralModalOpen(false);
              }}
            >
              Cancel
            </Button>
          </div>
        }
        contentClassName="flex !flex-row !items-start !justify-start bg-white"
      >
        <div className="w-full">
          <p className="mb-2">
            To connect RingCentral, you`ll need to generate a JWT token. To do
            this, follow these steps:
          </p>
          <ul className="flex flex-col gap-y-3">
            <li className="flex flex-row">
              <span className="mr-7">1.</span>
              <span>
                Log into RingCentral and click <b>Credentials</b> in the
                navigation on the left of the screen.
              </span>
            </li>
            <li className="flex flex-row">
              <span className="mr-7">2.</span>
              <span>
                In the Label field, enter <b>CloudClevr</b>. Under{' '}
                <b>Select environment</b>, choose <b>Production</b>.
              </span>
            </li>
            {/* <li>
              <div className="flex flex-row">
                <span className="mr-7">3.</span>
                <span>
                  Check the <b>Only specific apps of my choice</b> box, and
                  enter the following client ID:
                </span>
              </div>

              <span className="my-2 ml-10 flex flex-row items-center justify-between bg-interfaceColor-15 p-3">
                <span>cQRofUcvRc3bFhtjkezhtg</span>
                <ClipboardIcon classNames="-mb-[5px]" />
              </span>
            </li> */}
            <li className="flex flex-row">
              <span className="mr-7">3.</span>
              <span>
                Click the <b>Create JWT</b> button.
              </span>
            </li>
            <li>
              <div className="flex flex-row">
                <span className="mr-7">4.</span>
                <span>Copy JWT token and paste it into this box:</span>
              </div>

              <Input
                name={'jwtToken'}
                label="JWT Token"
                placeholder="Paste your JWT token"
                className="my-2 ml-10 block !text-16"
                onChange={handleChange}
                error={validationJWTMessage}
              />
            </li>
          </ul>
        </div>
      </Modal>
      <Modal
        headerTxt={''}
        isTruncatedHeader={false}
        isOpen={isHelixModalVisible}
        modalClassName={'h-full mt-0'}
        size={'small'}
        position={isMobile ? 'bottom' : 'default'}
        showCloseBtn={false}
        onClose={() => {
          setIsHelixModalVisible(false);
          setHelixSuccessModalOpen(false);
        }}
        closeOnClickOutside={false}
        actionButtons={<div className="flex flex-col gap-2 md:flex-row"></div>}
        contentClassName="flex flex-column !items-start bg-white"
      >
        <div className="flex flex-col justify-start gap-y-4">
          <CheckCircleIcon color="text-primaryBrandColor-150" size="10" />
          <h1 className="text-32 leading-9">
            We have everything we need to get started
          </h1>
          <p>
            Thanks for sharing your information. We&apos;ll let you know when
            we&apos;re done connecting Enhanced Security Insights.
          </p>
          <Button
            variant="primary"
            onClick={() => {
              setIsHelixModalVisible(false);
              setHelixSuccessModalOpen(false);
            }}
            className="w-fit"
            size="medium"
          >
            Got it
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ConnectedServicesPage;
