import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import {
    useAddDashboardTile,
    useHandleTileBtnNavigate,
    useRemoveDashboardTile,
} from '@hooks/dashboard';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useEmptyCallQueueSnapshotQuery } from '@hooks/security';

export const CallQueues: React.FC<TilesProps & { title?: string }> = ({
    id,
    classNames,
    isSnapshot,
    title
}) => {
    const { handleButtonClick } = useHandleTileBtnNavigate(id);
    const { removeMenuItem } = useRemoveDashboardTile(id);
    const { addMenuItem } = useAddDashboardTile(
        DashboardTiles.CallQueues,
        {}
    );
    const isOnDashboardPage = !!id;

    const {
        data,
        isLoading,
    } = useEmptyCallQueueSnapshotQuery();

    return (
        <DoughnutChartTile
            dropdownMenuItemClasses={'!w-[300px]'}
            contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
            classNames={
                classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
            }
            headerTitle={title || 'Call queues'}
            isLoading={isLoading}
            data={
                data && data.snapshot
                    ? Object.values(data.snapshot).map((item) => +item)
                    : []
            }
            buttonLabel={!isSnapshot ? 'Call queue data' : ''}
            linkPath={ANALYTICS_NAVIGATION_MAP['callQueues']}
            dataLabels={
                data
                    ? [`Empty call queues: ${data.snapshot?.empty}`,
                    `Populated call queues: ${data.snapshot?.populated}`]
                    : []
            }
            handleButtonClick={handleButtonClick()}
            showMessageIfEmpty={false}
            showBracketLabelValue={false}
        />
    );
};
