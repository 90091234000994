import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import {
  useAddDashboardTile,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useGeneratedTrunksSnapshotQuery } from '@hooks/utilisation';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';

export const ConfiguredTrunks: React.FC<TilesProps> = ({
  id,
  classNames,
  isSnapshot,
}) => {
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.ConfiguredTrunks,
    {}
  );
  const isOnDashboardPage = !!id;

  // data hook
  const {
    data,
    isLoading,
  } = useGeneratedTrunksSnapshotQuery();

  return (
    <NumberTile
      dropdownMenuItemClasses={'!w-[300px]'}
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      headerTitle={'Trunks configured'}
      isLoading={isLoading}
      data={
        data ? Object.values(data) : []
      }
      buttonLabel={!isSnapshot ? 'Trunk data' : ''}
      linkPath={ANALYTICS_NAVIGATION_MAP['configuredTrunksData']}
      handleButtonClick={!isSnapshot ? handleButtonClick() : undefined}
    />
  );
};
