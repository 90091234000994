import { User, UserRoles } from '@hooks/users/types';
import cn from 'classnames';

export const getBadgeLabel = (user: User) => {
  if (user.role === UserRoles.SUPERADMIN) {
    return 'Superadmin';
  } else if (user.role === UserRoles.ADMIN) {
    return 'Admin';
  } else {
    return 'Can view parent';
  }
};

export const showBadge = (user: User) => {
  const badgeClassName = 'ml-3 flex h-[22px] items-center justify-center rounded-full bg-interfaceColor-20 p-[2px] pb-[2px] pl-[8px] pr-[8px] text-interfaceColor-100';
  
  if (user.role === UserRoles.ADMIN && user.isViewerInherited) {
    return (
      <>
        <span className={cn(badgeClassName)}>
          Admin
        </span>
        <span className={cn(badgeClassName)}>
          Can view parent
        </span>
      </>
    );
  } else if (
    user.role === UserRoles.SUPERADMIN ||
    user.role === UserRoles.ADMIN ||
    user.isViewerInherited
  ) {
    return (
      <span className={cn(badgeClassName)}>
        {getBadgeLabel(user)}
      </span>
    );
  } else {
    return null;
  }
};

export function userItem(user: User) {
  return (
    <div className="mb-4 flex" key={user.id}>
      <div className="flex flex-col">
        <div className="flex">
          <span className="text-interfaceColor-100">{user.fullName}</span>
          {showBadge(user)}
        </div>
        <span className="text-interfaceColor-80">{user.emailAddress}</span>
      </div>
    </div>
  );
}

export const sortUsers = (data: User[], loggedInUserId: string) => {
  const superAdmins: User[] = [];
  const viewerInherited: User[] = [];
  const admins: User[] = [];
  const standardUsers: User[] = [];

  data.forEach((user) => {
    if (user.role === UserRoles.SUPERADMIN) {
      superAdmins.push(user);
    } else if (user.isViewerInherited) {
      viewerInherited.push(user);
    } else if (user.role === UserRoles.ADMIN) {
      admins.push(user);
    } else {
      standardUsers.push(user);
    }
  });

  superAdmins.sort((a, b) => {
    if (a.id === loggedInUserId) {
      return -1;
    } else if (b.id === loggedInUserId) {
      return 1;
    } else {
      return 0;
    }
  });

  admins.sort((a, b) => {
    if (a.id === loggedInUserId) {
      return -1;
    } else if (b.id === loggedInUserId) {
      return 1;
    } else {
      return 0;
    }
  });

  return [...superAdmins, ...viewerInherited, ...admins, ...standardUsers];
};
