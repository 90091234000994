import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useEffect, useState } from 'react';

import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { useTeamsGroupsTrendQuery } from '@hooks/adoption';
import {
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { getTrendData, getTrendLabels } from '@utils/index';

type Params = {
  dataType: string;
  days: string;
};

export const TeamsAndGroupsAdoptionOverTime: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.TeamsAndGroupsAdoptionOverTime,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: teamsGroupsTrendData,
    isLoading: isTeamsGroupsTrendDataLoading,
    refetch,
  } = useTeamsGroupsTrendQuery(parameters);

  useEffect(() => {
    const updatedParams = JSON.parse(params);

    setParameters({
      days: updatedParams.days,
      dataType: parameters.dataType,
    });

    return () => {
      refetch();
    };
  }, [parameters.days, params, refetch]);

  const labels = getTrendLabels(
    teamsGroupsTrendData?.trend || [],
    +parameters.days
  );

  const compareAgainstTrendData = getTrendData(
    teamsGroupsTrendData?.compareAgainst || [],
    +parameters.days
  );

  const trendData = getTrendData(
    teamsGroupsTrendData?.trend || [],
    +parameters.days
  );

  const handleDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      dataType: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Collaboration',
      value: 'collaborating',
      action: () => handleDropdown('collaborating'),
    },
    {
      id: '2',
      label: 'Adoption',
      value: 'inTeam',
      action: () => handleDropdown('inTeam'),
    },
  ];

  const selectedSortItem = sortMenuItems.find(
    (item) => item.value === parameters.dataType
  );

  return (
    parameters && (
      <LineChartTile
        sortData={sortMenuItems}
        initialSelectedItem={selectedSortItem}
        legendLabels={[`Last ${parameters.days} days`, 'Previous period']}
        compareData={undefined}
        dropdownMenuItemClasses={'!w-[300px]'}
        classNames={
          classNames ? classNames : 'col-span-6 md:col-span-6 xl:col-span-4'
        }
        tooltipLabel={[`Last ${parameters.days} days`, 'Previous period']}
        headerTitle={'Teams & groups adoption over time'}
        dataSetTypes={[LineChartTypes.Dots, LineChartTypes.Line]}
        compareLabel=""
        buttonLabel=""
        dataSetColors={['#00CF6C', '#3F454B']}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        isLoading={isTeamsGroupsTrendDataLoading}
        labels={labels}
        data={[trendData, compareAgainstTrendData]}
      />
    )
  );
};
