import { ORDER_BY_VALUES } from '@common/constants';
import { Dropdown } from '@components/ui/Dropdown';
import { ArrowDownIcon } from '@components/ui/Icons';
import { QueryParams, SetQueryParams } from '@pages/PeopleAndGroups/types';
import { BaseSetQueryParams } from '@store/queryParamsStore';
import React, { useCallback, useEffect, useState } from 'react';

export type SortData = {
  label: string;
  value: string[];
  orderByLabels?: string[];
};

type SortProps = {
  sortData: SortData[];
  queryParams: QueryParams;
  setQueryParams: SetQueryParams;
  setQueryParamsStore?: BaseSetQueryParams;
};

export const Sort: React.FC<SortProps> = ({
  sortData,
  queryParams,
  setQueryParams,
  setQueryParamsStore,
}) => {
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [sortByIndex, setSortByIndex] = useState(0);
  const [orderByIndex, setOrderByIndex] = useState(sortData.length);
  const [orderByLabelIndex, setOrderByLabelIndex] = useState(0);
  const [headerLabel, setHeaderLabel] = useState('');
  const [updatedSortData, setUpdatedSortData] = useState(sortData);

  const createOrderByDropdownItems = (index: number) => {
    const orderByItems = (sortData[index].orderByLabels ?? []).map(
      (label, i) => ({
        label,
        value: [ORDER_BY_VALUES[i]],
      })
    );

    setUpdatedSortData([...sortData, ...orderByItems]);
  };

  useEffect(() => {
    setQueryParams({ sortBy: queryParams.sortBy || '' }, 'replaceIn');
    setQueryParams({ order: queryParams.order || '' }, 'replaceIn');
  }, [setQueryParams]);

  const createHeaderLabel = (index: number, orderIndex?: number) => {
    const sortLabel = sortData[index].label || '';
    const orderByLabels = sortData[index].orderByLabels ?? [];
    setHeaderLabel(`${sortLabel} (${orderByLabels[orderIndex || 0]})`);
  };

  const updateQueryParams = (index: number, orderIndex?: number) => {
    setQueryParams({ sortBy: sortData[index].value[0] }, 'replaceIn');
    setQueryParams({ order: ORDER_BY_VALUES[orderIndex || 0] }, 'replaceIn');
    if (setQueryParamsStore) {
      setQueryParamsStore('sortBy', sortData[index].value[0]);
      setQueryParamsStore('order', ORDER_BY_VALUES[orderIndex || 0]);
    }
  };

  const getOrderItemIndex = (value: string) => {
    return value === ORDER_BY_VALUES[0] ? sortData.length : sortData.length + 1;
  };

  const getOrderItemLabelIndex = (value: string) => {
    return value === ORDER_BY_VALUES[0] ? 0 : 1;
  };

  const isMultiSelectItem = (value: string) => {
    return ORDER_BY_VALUES.includes(value);
  };

  useEffect(() => {
    let sortIndex = 0;
    let orderLabelIndex = 0;

    if (queryParams.sortBy) {
      sortData.forEach((item, index) => {
        if (item.value[0] === queryParams.sortBy) {
          setSortByIndex(index);
          sortIndex = index;
        }
      });
    }
    if (queryParams.order) {
      setOrderByIndex(getOrderItemIndex(queryParams.order));
      orderLabelIndex = getOrderItemLabelIndex(queryParams.order);
      setOrderByLabelIndex(orderLabelIndex);
    }

    createOrderByDropdownItems(sortIndex);
    createHeaderLabel(sortIndex, orderLabelIndex);
  }, [queryParams, setSortByIndex, setOrderByIndex, setOrderByLabelIndex]);

  const handleSortModalOpen = () => {
    setIsSortModalOpen(true);
  };

  const handleSortModalClose = () => {
    setIsSortModalOpen(false);
  };

  const handleIsDropdownOpen = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOrderByOnChange = (value: string) => {
    const orderLabelIndex = getOrderItemLabelIndex(value);
    const orderIndex = getOrderItemIndex(value);

    createHeaderLabel(sortByIndex, orderLabelIndex);
    updateQueryParams(sortByIndex, orderLabelIndex);
    setOrderByIndex(orderIndex);
    setOrderByLabelIndex(orderLabelIndex);
  };

  const handleItemChange = (value: string[], index: number) => {
    const selectedValue = value[0];
    if (ORDER_BY_VALUES.includes(selectedValue)) {
      handleOrderByOnChange(selectedValue);
      return;
    }
    createOrderByDropdownItems(index);
    createHeaderLabel(index, orderByLabelIndex);
    updateQueryParams(index, orderByLabelIndex);
    setSortByIndex(index);
  };

  const handleSortModalOnChange = (values: string[], index: number) => {
    handleItemChange(values, index);
    setIsSortModalOpen(false);
  };

  const handleDropdownOnChange = (value: string[], index: number) => {
    handleItemChange(value, index);
    setIsDropdownOpen(false);
  };

  return (
    <Dropdown
      dropdownModalProps={{
        handleModalOpen: handleSortModalOpen,
        handleModalClose: handleSortModalClose,
        handleModalOnChange: handleSortModalOnChange,
        isModalOpen: isSortModalOpen,
        headerValueMobile: (
          <>
            {headerLabel} <ArrowDownIcon classNames="h-4 w-4 pl-1" />
          </>
        ),
        selectedIndex: [sortByIndex, orderByIndex],
        data: updatedSortData,
        classNames:
          'p-0 h-[50px] flex justify-center w-full hover:bg-interfaceColor-10',
      }}
    >
      <Dropdown.TextHeader
        label={headerLabel}
        handleOpen={handleIsDropdownOpen}
      ></Dropdown.TextHeader>
      <Dropdown.List open={isDropdownOpen} align="right">
        {updatedSortData.map((item, index) => {
          return (
            <Dropdown.DefaultItem
              key={index}
              item={item}
              currentIndex={index}
              isMultiSelectItem={isMultiSelectItem(item.value[0])}
              selectedIndex={[sortByIndex, orderByIndex]}
              onChange={() => handleDropdownOnChange(item.value, index)}
            ></Dropdown.DefaultItem>
          );
        })}
      </Dropdown.List>
    </Dropdown>
  );
};
