import {
    DEFAULT_PAGE_SIZE,
    MAILBOX_DELEGATES_SORT_BY,
    MAILBOX_DELEGATES_TABLE_COLUMNS,
} from '@common/constants';
import { ExtendedOption, IOption, OrderBy } from '@common/types';
import { TableWrapper } from '@components/partials';

import { PeopleSortBy } from '@hooks/people/types';
import { useDelegatedMailboxFilterQuery, useDelegatesDataPreviewQuery } from '@hooks/security';
import { usePagination } from '@hooks/utils/pagination';
import { Location } from '@hooks/locations/types';
import {
    addGroupDataToPageFilter, addLocationDataToPageFilter, addSelectedDataToFilter,
    formatDate, getGroupOrLocationFilterAsQueryParam, useTableData
} from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';
import { useLocation } from 'react-router-dom';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { useLocationsQuery } from '@hooks/locations';
import { useGroupsFilterQuery } from '@hooks/groups';
import { createCellValue } from './utils';
import { Modal } from '@components/ui';
import useModalStore from '@store/modal';
import { mapFilterDisplayNames } from '../helper';

const pageSize = DEFAULT_PAGE_SIZE;

const MailboxDelegatesPage: React.FC = () => {
    const days = new URLSearchParams(useLocation().search).get("days") || "30";
    const {
        setIsDetailsModalOpen,
        setDataDetailsModal,
        isDetailsModalOpen,
        dataDetailsModal,
    } = useModalStore();

    const [search, setSearch] = useState('');
    const { pageNumber, setPageNumber } = usePagination();
    const [locationIds, setLocationIds] = useState<string[]>([]);
    const [groupIds, setGroupIds] = useState<string[]>([]);

    const [queryParams, setQueryParams] = useQueryParams({
        search: withDefault(StringParam, ''),
        sortBy: withDefault(StringParam, 'account'),
        order: withDefault(StringParam, 'asc'),
        filter: withDefault(StringParam, ''),
    });

    const allLocationIds: string[] = [];
    const groupsIDs: string[] = [];
    const locationFilerOptions: IOption[] = [];
    const groupFilerOptions: ExtendedOption[] = [];
    const transformedFilterData: FilterData[] = [];

    //filters
    const { data } = useDelegatedMailboxFilterQuery({ days });
    const { data: locationsData, isFetched: locationDataFetched } =
        useLocationsQuery();
    const { data: groupsData, isFetched: groupsDataFetched } =
        useGroupsFilterQuery();

    if (data) {
        addSelectedDataToFilter(data, transformedFilterData, mapFilterDisplayNames);
    }
    if (locationDataFetched && locationsData)
        addLocationDataToPageFilter(locationsData, locationFilerOptions,
            allLocationIds, transformedFilterData);

    if (groupsDataFetched && groupsData)
        addGroupDataToPageFilter(groupsData, groupFilerOptions, transformedFilterData);

    useEffect(() => {
        locationsData?.forEach((location: Location) => {
            locationFilerOptions.push({ label: location.name, value: location.id });
            allLocationIds.push(location.id);
        });
        setLocationIds(allLocationIds);
    }, [locationsData]);

    useEffect(() => {
        const nested = (element: any) => {
            element.subGroups.forEach((subElement: any) => {
                groupsIDs.push(subElement.id);
                if (subElement.subGroups && subElement.subGroups.length > 0) {
                    nested(subElement);
                }
            });
        };

        groupsData?.forEach((element: any) => {
            groupsIDs.push(element.id);
            if (element.subGroups && element.subGroups.length > 0) {
                nested(element);
            }
        });

        setGroupIds(groupsIDs);
    }, [groupsData]);

    // table
    const query = useDelegatesDataPreviewQuery({
        pageSize,
        pageNumber,
        searchTerm: search,
        sortBy: queryParams.sortBy as PeopleSortBy,
        order: queryParams.order as OrderBy,
        days,
        locationList: getGroupOrLocationFilterAsQueryParam(queryParams.filter, locationIds),
        groupList: getGroupOrLocationFilterAsQueryParam(queryParams.filter, groupIds),
        mailboxOwners: queryParams.filter
            ? queryParams.filter
                .split(',')
                .filter((filter) => data?.mailboxOwners.includes(filter))
            : [],
    });

    const getTableData = useTableData(
        query,
        MAILBOX_DELEGATES_TABLE_COLUMNS,
        createCellValue
    );

    const [shouldDownload, setShouldDownload] = useState(false);
    const { refetch: downloadCsv } = useDownloadCsv({
        shouldDownload,
        endpoint: `${API_ENDPOINTS.DELEGATES_EXPORT}`,
        params: {
            pageSize,
            pageNumber,
            searchTerm: search,
            sortBy: queryParams.sortBy as PeopleSortBy,
            order: queryParams.order as OrderBy,
            days,
            locationList: getGroupOrLocationFilterAsQueryParam(queryParams.filter, locationIds),
            groupList: getGroupOrLocationFilterAsQueryParam(queryParams.filter, groupIds),
            mailboxOwners: queryParams.filter
                ? queryParams.filter
                    .split(',')
                    .filter((filter) => data?.mailboxOwners.includes(filter))
                : [],
        },
        filename: `Clevr360_Delegated_mailbox_data_${formatDate(
            new Date().toString()
        )}.csv`,
    });

    const handleDownloadClick = () => {
        setShouldDownload(true);
        downloadCsv();
        setShouldDownload(false);
    };

    return (<>      
    <div className="">
        <TableWrapper
            search={search}
            setSearch={setSearch}
            searchPlaceholder={'Search by account, mailbox name'}
            columns={MAILBOX_DELEGATES_TABLE_COLUMNS}
            data={getTableData()}
            sortData={MAILBOX_DELEGATES_SORT_BY}
            searchKey="search"
            query={query}
            filterData={transformedFilterData}
            refetchQuery={query.refetch}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setPageNumber={setPageNumber}
            isCollapsable={true}
            hasFilterBanner={false}
            isLicensePage={true}
            sliceColumns={2}
            searchCountStatsLabel="delegates"
            isDownloadAvailable={true}
            filterHeaderText="Filter delegates"
            handleDownload={handleDownloadClick}
            displayAsDropdownFilterItems={["Mailbox ID"]}
        />
    </div>
        <Modal
            headerTxt={dataDetailsModal.header}
            isOpen={isDetailsModalOpen}
            size={'medium'}
            showCloseBtn={true}
            onClose={() => {
                setIsDetailsModalOpen(false);
                setDataDetailsModal({ body: null, header: null });
            }}
            contentClassName="bg-white"
            actionButtons={<></>}
        >
            <div className="w-full">{dataDetailsModal.body}</div>
        </Modal></>
    );
};

export default MailboxDelegatesPage;
