import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import { useDevicesAdoptionSnapshotQuery } from '@hooks/adoption';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { useState } from 'react';

type Params = {
  days: string;
  dataType: string;
};

export const DeviceAdoption: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.DeviceAdoption,
    parameters
  );
  const isOnDashboardPage = !!id;
  // data hook
  const {
    data: deviceAdoptionData,
    isLoading: isDeviceAdoptionLoading,
    refetch,
  } = useDevicesAdoptionSnapshotQuery(parameters);

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All types',
      value: 'all',
      action: () => handleSortDropdownChange('all'),
    },
    {
      id: '2',
      label: 'Meeting rooms',
      value: 'meetingrooms',
      action: () => handleSortDropdownChange('meetingrooms'),
    },
    {
      id: '3',
      label: 'Users',
      value: 'users',
      action: () => handleSortDropdownChange('users'),
    },
  ];

  const compareMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleDropdownChange('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleDropdownChange('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleDropdownChange('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleDropdownChange('90'),
    },
  ];

  const handleDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const handleSortDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      dataType: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  return (
    parameters && (
      <NumberTile
        dropdownMenuItemClasses={'!w-[300px]'}
        initialSelectedItem={sortMenuItems.find(
          (item) => item.value === parameters.dataType
        )}
        initialCompareSelectedItem={compareMenuItems.find(
          (item) => item.value === parameters.days
        )}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        classNames={
          classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
        }
        sortData={sortMenuItems}
        compareData={compareMenuItems}
        headerTitle={'Devices'}
        isLoading={isDeviceAdoptionLoading}
        data={deviceAdoptionData ? Object.values(deviceAdoptionData) : []}
        buttonLabel={'Device adoption data'}
        linkPath={ANALYTICS_NAVIGATION_MAP['deviceAdoptionData']}
        handleButtonClick={handleButtonClick()}
        percentageLabel=""
        bottomLabel="Inactive devices"
        isArrowColorReversed={true}
      />
    )
  );
};
