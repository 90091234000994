import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  adjustRounding,
  formatServiceAdoptionValue,
  useUserServiceAdoptionSnapshotQuery,
} from '@hooks/adoption';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useState } from 'react';

type Params = {
  days: string;
  dataType: string;
};

export const UserServiceAdoptionSnapshot: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.UserServiceAdoptionSnapshot,
    parameters
  );
  const isOnDashboardPage = !!id;
  // data hook
  const {
    data: userServiceAdoptionData,
    isLoading: isUserServiceAdoptionDataLoading,
    refetch,
  } = useUserServiceAdoptionSnapshotQuery(parameters);

  const compareMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleCompareDropdown('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleCompareDropdown('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleCompareDropdown('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleCompareDropdown('90'),
    },
  ];

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Messages',
      value: 'messages',
      action: () => handleDropdownChange('messages'),
    },
    {
      id: '2',
      label: 'Emails',
      value: 'emails',
      action: () => handleDropdownChange('emails'),
    },
    {
      id: '3',
      label: 'Phone',
      value: 'phone',
      action: () => handleDropdownChange('phone'),
    },
    {
      id: '4',
      label: 'Video calls',
      value: 'videocalls',
      action: () => handleDropdownChange('videocalls'),
    },
  ];

  const handleDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      dataType: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const handleCompareDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  return (
    <DoughnutChartTile
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      sortData={sortMenuItems}
      compareData={compareMenuItems}
      initialSelectedItem={sortMenuItems.find(
        (item) => item.value === parameters.dataType
      )}
      initialCompareSelectedItem={compareMenuItems.find(
        (item) => item.value === parameters.days
      )}
      headerTitle={'Service adoption'}
      dropdownMenuItemClasses={'!w-[300px]'}
      isLoading={isUserServiceAdoptionDataLoading}
      data={
        userServiceAdoptionData
          ? [
              userServiceAdoptionData?.notUsingPercentage,
              userServiceAdoptionData?.usingPercentage,
            ]
          : []
      }
      dataLabels={[
        `${
          userServiceAdoptionData && userServiceAdoptionData?.notUsingPercentage
            ? formatServiceAdoptionValue(
                adjustRounding(userServiceAdoptionData.notUsingPercentage)
              )
            : 0
        }% of users have not used ${
          parameters.dataType === 'videocalls'
            ? 'video calls'
            : parameters.dataType
        }`,
        `${
          userServiceAdoptionData && userServiceAdoptionData?.usingPercentage
            ? formatServiceAdoptionValue(
                adjustRounding(userServiceAdoptionData.usingPercentage)
              )
            : 0
        }% of users have used  ${
          parameters.dataType === 'videocalls'
            ? 'video calls'
            : parameters.dataType
        }`,
      ]}
      buttonLabel={'Service adoption data'}
      linkPath={ANALYTICS_NAVIGATION_MAP['userServiceAdoption']}
      handleButtonClick={handleButtonClick()}
      showBracketLabelValue={false}
    />
  );
};
