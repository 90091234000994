import { Banner, Card, Loader, RadioButton } from '@components/ui';
import { Switch } from '@headlessui/react';
import {
  useSubscriptionSettings,
  useSubscriptionSettingsUpdate,
} from '@hooks/users';
import { Frequency } from '@hooks/users/types';
import React, { useEffect, useState } from 'react';

const EmailNotificationsPage: React.FC = () => {
  const [enabled, setEnabled] = useState(true);
  const [licenseContractsExpiring, setLicenseContractsExpiring] =
    useState(false);
  const [reportsNotification, setReportsNotification] = useState(false);
  const [frequency, setFrequency] = useState(Frequency.EverytimeOnEvent);

  const { data, isLoading } = useSubscriptionSettings();

  const updateSubscriptionSettings = useSubscriptionSettingsUpdate();

  useEffect(() => {
    if (data) {
      setLicenseContractsExpiring(data.licenseContractsExpiring);
      setReportsNotification(data.reportsNotification);
      setFrequency(data.frequency);
    }
  }, [data]);

  const handleUpdate = (
    newFrequency: Frequency,
    newLicenseContractsExpiring: boolean,
    newReportsNotification: boolean
  ) => {
    updateSubscriptionSettings.mutate({
      frequency: newFrequency !== undefined ? newFrequency : frequency,
      licenseContractsExpiring:
        newLicenseContractsExpiring !== undefined
          ? newLicenseContractsExpiring
          : licenseContractsExpiring,
      reportsNotification:
        newReportsNotification !== undefined
          ? newReportsNotification
          : reportsNotification,
    });
  };
  useEffect(() => {
    if (data) {
      setLicenseContractsExpiring(data.licenseContractsExpiring);
      setReportsNotification(data.reportsNotification);
      setFrequency(data.frequency);
    }
  }, [data]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Card classNames="!w-full">
        <h1 className="mb-5 text-20 font-normal text-interfaceColor-100">
          We’ll email you when...
        </h1>
        <Switch.Group>
          <div className="relative mb-5 flex items-center">
            <Switch
              disabled={true}
              checked={enabled}
              onChange={setEnabled}
              className={`${
                enabled ? 'bg-interfaceColor-30' : 'bg-interfaceColor-30'
              } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-infoColor-10 focus:ring-offset-2`}
            >
              <span
                className={`${
                  enabled ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-interfaceColor-60 transition-transform`}
              />
            </Switch>

            {enabled && (
              <span className="absolute left-[28px]">
                <svg
                  width="8"
                  height="6"
                  viewBox="0 0 8 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.33398 3.22222L2.66732 5L6.66732 1"
                    stroke="#ffffff"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            )}
            <Switch.Label className="ml-4">
              There’s a security alert, or a problem with one of your connected
              services
            </Switch.Label>
          </div>
        </Switch.Group>

        <Switch.Group>
          <div className="relative mb-5 flex items-center">
            <Switch
              disabled={isLoading}
              checked={licenseContractsExpiring}
              onChange={() => {
                setLicenseContractsExpiring((current) => {
                  const newValue = !current;
                  handleUpdate(frequency, newValue, reportsNotification);
                  return newValue;
                });
              }}
              className={`${
                licenseContractsExpiring
                  ? 'bg-primaryBrandColor-100'
                  : 'bg-interfaceColor-30'
              } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-infoColor-10 focus:ring-offset-2`}
            >
              <span
                className={`${
                  licenseContractsExpiring ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
              />
            </Switch>

            {licenseContractsExpiring && (
              <span className="absolute left-[28px] animate-fadeIn">
                <svg
                  width="8"
                  height="6"
                  viewBox="0 0 8 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.33398 3.22222L2.66732 5L6.66732 1"
                    stroke="#282B2F"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            )}

            <Switch.Label className="ml-4">
              You have licenses or contracts due to expire within the next 7
              days
            </Switch.Label>
          </div>
        </Switch.Group>

        <Switch.Group>
          <div className="relative flex items-center">
            <Switch
              disabled={isLoading}
              checked={reportsNotification}
              onChange={() => {
                setReportsNotification((current) => {
                  const newValue = !current;
                  handleUpdate(frequency, licenseContractsExpiring, newValue);
                  return newValue;
                });
              }}
              className={`${
                reportsNotification
                  ? 'bg-primaryBrandColor-100'
                  : 'bg-interfaceColor-30'
              } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-infoColor-10 focus:ring-offset-2`}
            >
              <span
                className={`${
                  reportsNotification ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
              />
            </Switch>

            {reportsNotification && (
              <span className="absolute left-[28px] animate-fadeIn">
                <svg
                  width="8"
                  height="6"
                  viewBox="0 0 8 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.33398 3.22222L2.66732 5L6.66732 1"
                    stroke="#282B2F"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            )}
            <Switch.Label className="ml-4">
              A new report is published, or we update an existing report
            </Switch.Label>
          </div>
        </Switch.Group>
      </Card>

      <Card classNames="mt-6 !w-full">
        <h1 className="mb-2 text-20 font-normal text-interfaceColor-100">
          Email frequency
        </h1>
        <Banner
          className="mb-5 !w-full"
          variant="info"
          message={
            'We’ll always email you straight away about critical security or integration issues, regardless of your settings.'
          }
        />
        <div className="mb-2 mt-4">
          <RadioButton
            disabled={isLoading}
            name={Frequency.EverytimeOnEvent.toString()}
            value={Frequency.EverytimeOnEvent}
            label="Every time an event I’ve asked to be emailed about happens"
            checked={frequency === Frequency.EverytimeOnEvent}
            onChange={() => {
              const newFrequency = Frequency.EverytimeOnEvent;
              setFrequency(newFrequency);
              handleUpdate(
                newFrequency,
                licenseContractsExpiring,
                reportsNotification
              );
            }}
          ></RadioButton>
          <span className="ml-[37px] inline-block text-14 text-interfaceColor-80">
            You’ll get multiple emails a day. It might get quite intense.
          </span>
        </div>
        <div className="mb-2 mt-4">
          <RadioButton
            disabled={isLoading}
            name={Frequency.Daily.toString()}
            value={Frequency.Daily}
            label="Once a day"
            checked={frequency === Frequency.Daily}
            onChange={() => {
              const newFrequency = Frequency.Daily;
              setFrequency(newFrequency);
              handleUpdate(
                newFrequency,
                licenseContractsExpiring,
                reportsNotification
              );
            }}
          ></RadioButton>
          <span className="ml-[37px] inline-block text-14 text-interfaceColor-80">
            You’ll get a digest email at the end of each day, containing every
            event you’ve asked to be emailed about from that day.
          </span>
        </div>

        <div className="mb-2 mt-4">
          <RadioButton
            disabled={isLoading}
            name={Frequency.Weekly.toString()}
            value={Frequency.Weekly}
            label="Once a week"
            checked={frequency === Frequency.Weekly}
            onChange={() => {
              const newFrequency = Frequency.Weekly;
              setFrequency(newFrequency);
              handleUpdate(
                newFrequency,
                licenseContractsExpiring,
                reportsNotification
              );
            }}
          ></RadioButton>
          <span className="ml-[37px] inline-block text-14 text-interfaceColor-80">
            You’ll get a digest email at 12pm every Friday, containing every
            event you’ve asked to be emailed about from the previous week.
          </span>
        </div>

        <div className="mb-2 mt-4">
          <RadioButton
            disabled={isLoading}
            name={Frequency.Never.toString()}
            value={Frequency.Never}
            label="Never"
            checked={frequency === Frequency.Never}
            onChange={() => {
              const newFrequency = Frequency.Never;
              setFrequency(newFrequency);
              handleUpdate(
                newFrequency,
                licenseContractsExpiring,
                reportsNotification
              );
            }}
          ></RadioButton>
          <span className="ml-[37px] inline-block text-14 text-interfaceColor-80">
            You’ll still get emails about critical, real-time security or
            integration issues which impact your business.
          </span>
        </div>
      </Card>
    </div>
  );
};

export default EmailNotificationsPage;
