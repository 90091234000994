import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { useAddDashboardTile, useRemoveDashboardTile } from '@hooks/dashboard';
import { useLicencesExpiringSnapshotVendorQuery } from '@hooks/licenses';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';

export const LicensesExpiringSoonVendorSplit: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.LicensesExpiringSoonVendorSplit,
    ''
  );
  const isOnDashboardPage = !!id;

  const {
    data: licencesExpiringSnapshotVendorData,
    isLoading: isLicencesExpiringSnapshotVendorLoading,
  } = useLicencesExpiringSnapshotVendorQuery(JSON.parse(params));

  return (
    <DoughnutChartTile
      key={id}
      classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      dropdownMenuItemClasses={'!w-[300px]'}
      headerTitle={'Licenses expiring soon: Vendor split'}
      isLoading={isLicencesExpiringSnapshotVendorLoading}
      data={
        licencesExpiringSnapshotVendorData
          ? [
              licencesExpiringSnapshotVendorData.vendorSnapshot.Microsoft || 0,
              licencesExpiringSnapshotVendorData.vendorSnapshot.RingCentral ||
                0,
            ]
          : []
      }
      dataLabels={['Microsoft', 'RingCentral']}
    />
  );
};
