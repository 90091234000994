import { DashboardTiles } from '@common/constants';
import { DashboardTilesResponse } from '@hooks/dashboard/types';
import * as dashboardTiles from './tiles';
import { FC } from 'react';
import { TilesProps } from '@common/types';

type AllAvailableTilesDB = keyof typeof DashboardTiles | undefined;

type ExcludeTiles = 'ActivityPerDayChart';

type AllAvailableTilesComponent = Exclude<AllAvailableTilesDB, ExcludeTiles>;

export function renderTile(tileResponse: DashboardTilesResponse) {
  const tile: AllAvailableTilesComponent | undefined = getDashboardTileKey(
    tileResponse.type
  );

  if (tile && Object.keys(dashboardTiles).includes(tile)) {
    const DynamicComponent = dashboardTiles[tile] as FC<TilesProps>;
    return (
      <DynamicComponent
        id={tileResponse.id}
        params={tileResponse.parameters}
        key={tileResponse.id}
      />
    );
  } else {
    return <></>;
  }
}

const getDashboardTileKey = (
  tileNumber: number
): keyof AllAvailableTilesComponent | undefined => {
  const keys = Object.keys(DashboardTiles).filter((key) => isNaN(Number(key)));
  const resultKey = keys.find(
    (key) => (DashboardTiles as any)[key] === tileNumber
  );

  return resultKey as keyof AllAvailableTilesComponent | undefined;
};
