import {
  ALL,
  ANALYTICS_NAVIGATION_MAP,
  DashboardTiles,
} from '@common/constants';
import { NavigationType, VendorType } from '@common/types';
import { DashboardIcon } from '@components/ui';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  adjustRounding,
  formatServiceAdoptionValue,
  useCollabSnapshotQuery,
  useMsTeamsHealthSnapshotQuery,
  useServiceAdoptionSnapshotQuery,
  useTeamsGroupsAdoptionSnapshotQuery,
} from '@hooks/adoption';
import {
  CollabSnapshotResponse,
  TeamsGroupsAdoptionSnapshotResponse,
} from '@hooks/adoption/types';
import { useDashboardTileSaveMutation } from '@hooks/dashboard';
import { useLicencesUsageTrendQuery } from '@hooks/licenses';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import {
  LineChartTickFormat,
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { getTrendData, getTrendLabels, isTraditionalCommsDataVisible } from '@utils/index';
import React, { useState } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import styles from './styles.module.css';
import { MeetingAdoption } from '@pages/Dashboard/tiles/MeetingAdoption';
import { UserServiceAdoptionSnapshot } from '@pages/Dashboard/tiles/UserServiceAdoptionSnapshot';
import { OrgGroupActivityUser } from '@pages/Dashboard/tiles/OrgGroupActivityUser';
import { InactiveRooms } from '@pages/Dashboard/tiles/InactiveRooms';
import { MeetingRoomVideo } from '@pages/Dashboard/tiles/MeetingRoomVideo';
import { MeetingRoomsHoursBooked } from '@pages/Dashboard/tiles/MeetingRoomsHoursBooked';
import { DeviceAdoption } from '@pages/Dashboard/tiles/DeviceAdoption';
import { TeamsAndGroupsActivitySnapshot } from '@pages/Dashboard/tiles/TeamsAndGroupsActivitySnapshot';
import useUIStore from '@store/uiStore';
import { CallQueues } from '@pages/Dashboard/tiles';
import useServicesStore from '@store/servicesStore';

export enum TeamGroupType {
  Adoption,
  Collaboration,
}

export const getTeamsGroupsDataLabels = (
  data: TeamsGroupsAdoptionSnapshotResponse | undefined
) => {
  if (!data) {
    return [];
  }

  const props: (keyof TeamsGroupsAdoptionSnapshotResponse)[] = [
    'notInTeam',
    'inTeam',
  ];
  const labels = ['people not in a team', 'people in a team'];

  return props.map((i, index) => {
    return `${data[i] || 0} ${labels[index]}`;
  });
};

export const getCollabDataLabels = (
  data: CollabSnapshotResponse | undefined
) => {
  if (!data) {
    return [];
  }

  const props: (keyof CollabSnapshotResponse)[] = [
    'notCollaborate',
    'collaborate',
  ];
  const labels = ['people not collaborating', 'people collaborating'];

  return props.map((i, index) => {
    return `${data[i] || 0} ${labels[index]}`;
  });
};

const AdoptionsPage: React.FC = () => {
  const navigate = useNavigate();
  const [days, setDays] = useState('7');
  const [serviceType, setServiceType] = useState('messaging');
  const [serviceAdoptionType, setServiceAdoptionType] = useState('allservices');
  const [teamsGroupsVendorType, setTeamsGroupsVendorType] =
    useState<VendorType>(ALL);
  const [teamsGroupsDays, setTeamsGroupsDays] = useState('30');
  const [collabDays, setCollabDays] = useState('30');
  const [msTeamsServiceType, setMsTeamsServiceType] = useState('membership');

  const { traditionalCommsStatus } = useServicesStore();
  const isTraditionalCommsVisible = isTraditionalCommsDataVisible(traditionalCommsStatus);

  // Service usage: Trend over time
  const {
    data: licencesUsageTrendData,
    isLoading: isLicencesUsageTrendDataLoading,
  } = useLicencesUsageTrendQuery({
    days,
    serviceType,
  });

  const labels = getTrendLabels(licencesUsageTrendData?.trend || [], +days);

  const compareAgainstTrendData = getTrendData(
    licencesUsageTrendData?.compareAgainst || [],
    +days
  );

  const trendData = getTrendData(licencesUsageTrendData?.trend || [], +days);

  // Service adoption by user
  const { data: serviceAdoptionData, isLoading: isServiceAdoptionLoading } =
    useServiceAdoptionSnapshotQuery({
      serviceType: serviceAdoptionType,
      days: '30',
    });

  // Teams & groups adoption
  const {
    data: teamsGroupsAdoptionData,
    isLoading: isTeamsGroupsAdoptionLoading,
  } = useTeamsGroupsAdoptionSnapshotQuery({
    vendorType: teamsGroupsVendorType,
    days: teamsGroupsDays,
  });

  // Collaboration
  const { data: collabData, isLoading: isCollabDataLoading } =
    useCollabSnapshotQuery({
      vendorType: teamsGroupsVendorType,
      days: collabDays,
    });

  // MS Teams health
  const { data: msTeamsHealthData, isLoading: isMsHealthLoading } =
    useMsTeamsHealthSnapshotQuery({
      days: '30',
      dataType: msTeamsServiceType,
    });

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Messaging',
      value: 'messaging',
      action: () => handleDropdown('messaging'),
    },
    {
      id: '2',
      label: 'Email',
      value: 'email',
      action: () => handleDropdown('email'),
    },
    {
      id: '3',
      label: 'Video calls',
      value: 'videocalls',
      action: () => handleDropdown('videocalls'),
    },
    {
      id: '4',
      label: 'Phone calls',
      value: 'phonecalls',
      action: () => handleDropdown('phonecalls'),
    },
    {
      id: '5',
      label: 'File storage',
      value: 'filestorage',
      action: () => handleDropdown('filestorage'),
    },
  ];

  const compareMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleCompareDropdown('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleCompareDropdown('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleCompareDropdown('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleCompareDropdown('90'),
    },
  ];

  const createMenuItems = (tileType: DashboardTiles): MenuItem[] => [
    {
      id: '0',
      label: 'Add to Dashboard',
      icon: <DashboardIcon classNames="mr-1" />,
      value: 'addToDashboard',
      action: handleItemAction(tileType),
    },
  ];

  const createServiceAdoptionMenuItems = (): MenuItem[] => [
    {
      id: '1',
      label: 'All services',
      action: () => handleServiceAdoptionDropdown('allservices'),
    },
    {
      id: '2',
      label: 'Chat/Messaging',
      action: () => handleServiceAdoptionDropdown('messages'),
    },
    {
      id: '3',
      label: 'Phone (Internal)',
      action: () => handleServiceAdoptionDropdown('internalcalls'),
    },
    {
      id: '4',
      label: 'Phone (External)',
      action: () => handleServiceAdoptionDropdown('externalcalls'),
    },
    {
      id: '5',
      label: 'Video',
      action: () => handleServiceAdoptionDropdown('videocalls'),
    },
  ];

  const teamsGroupsSortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All vendors',
      value: 'All',
      action: () => handleTeamsGroupsDropdown('All'),
    },
    {
      id: '2',
      label: 'Microsoft',
      value: 'Microsoft',
      action: () => handleTeamsGroupsDropdown('Microsoft'),
    },
    {
      id: '3',
      label: 'RingCentral',
      value: 'RingCentral',
      action: () => handleTeamsGroupsDropdown('RingCentral'),
    },
  ];

  const teamsGroupsCompareMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleTeamsGroupsCompareDropdown('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleTeamsGroupsCompareDropdown('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleTeamsGroupsCompareDropdown('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleTeamsGroupsCompareDropdown('90'),
    },
  ];

  const collabCompareMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleCollabCompareDropdown('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleCollabCompareDropdown('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleCollabCompareDropdown('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleCollabCompareDropdown('90'),
    },
  ];

  const msTeamsHealthSortMenuItems: MenuItem[] = [
    {
      id: '2',
      label: 'Membership',
      value: 'membership',
      action: () => handleMsTeamsHealthDropdown('membership'),
    },
    {
      id: '3',
      label: 'Owner',
      value: 'owner',
      action: () => handleMsTeamsHealthDropdown('owner'),
    },
  ];

  const handleDropdown = (value: string) => {
    setServiceType(value);
  };

  const handleCompareDropdown = (value: string) => {
    setDays(value);
  };

  const handleTeamsGroupsDropdown = (value: string) => {
    setTeamsGroupsVendorType(value as VendorType);
  };

  const handleTeamsGroupsCompareDropdown = (value: string) => {
    setTeamsGroupsDays(value);
  };

  const handleCollabCompareDropdown = (value: string) => {
    setCollabDays(value);
  };

  const handleMsTeamsHealthDropdown = (value: string) => {
    setMsTeamsServiceType(value);
  };

  const dashboardSaveMutation = useDashboardTileSaveMutation();

  const handleItemAction = (type: DashboardTiles) => () => {
    const getParametersData = () => {
      switch (type) {
        case DashboardTiles.ServiceUsageTrendOverTime:
          return {
            days,
            serviceType,
          };
        case DashboardTiles.ServiceAdoptionByUser:
          return {
            serviceType: serviceAdoptionType,
            days: '30',
          };
        case DashboardTiles.TeamsAndGroupsAdoption:
          return {
            vendorType: teamsGroupsVendorType,
            days: teamsGroupsDays,
          };
        case DashboardTiles.MsTeamsHealth:
          return {
            dataType: msTeamsServiceType,
            days: '30',
          };
        case DashboardTiles.TeamsAndGroupsCollaboration:
          return {
            vendorType: teamsGroupsVendorType,
            days: collabDays,
          };
        default:
          return {};
      }
    };

    const parametersData = getParametersData();

    dashboardSaveMutation.mutate({
      parameters: JSON.stringify(parametersData),
      tileType: type,
    });
  };

  const { setNavigationTilePathOptions } = useUIStore();

  const handleServiceAdoptionDropdown = (value: string) => {
    setServiceAdoptionType(value);
  };

  return (
    <>
      <div className={styles.pageContainer}>
        <div className={styles.pageGrid}>
          <LineChartTile
            classNames="col-span-full lg:col-span-6 xl:col-span-6"
            sortData={sortMenuItems}
            compareData={compareMenuItems}
            legendLabels={['Microsoft']}
            contextData={createMenuItems(
              DashboardTiles.ServiceUsageTrendOverTime
            )}
            initialSelectedItem={sortMenuItems.find(
              (item) => item.value === serviceType
            )}
            initialCompareSelectedItem={compareMenuItems.find(
              (item) => item.value === days
            )}
            tooltipLabel={['Microsoft']}
            headerTitle={'Service usage: Trend over time'}
            isLoading={isLicencesUsageTrendDataLoading}
            labels={labels}
            data={[trendData, compareAgainstTrendData]}
            compareLabel="over"
            buttonLabel="Service usage data"
            dataSetTypes={[LineChartTypes.Dots, LineChartTypes.Dots]}
            dataSetColors={['#00CF6C']}
            linkPath={ANALYTICS_NAVIGATION_MAP['serviceUsage']}
            showYTicks={true}
            showXTicks={true}
            isDotted={true}
            chartHeight={150}
            xTickFormat={LineChartTickFormat.Date}
            showDateRange={true}
          />

          <UserServiceAdoptionSnapshot
            params={JSON.stringify({ days: '7', dataType: 'messages' })}
          />

          <DeviceAdoption
            params={JSON.stringify({ days: '30', dataType: 'all' })}
          />
        </div>
      </div>

      <h1 className={styles.header}>Meetings & rooms</h1>
      <div className={styles.pageContainer}>
        <div className={styles.pageGrid}>
          <MeetingAdoption params={JSON.stringify({ days: '30' })} />

          <MeetingRoomsHoursBooked
            classNames="col-span-full sm:col-span-6 lg:col-span-3 xl:col-span-4"
            params={JSON.stringify({ days: '30', dataType: 'top' })}
          />

          <MeetingRoomVideo
            params={JSON.stringify({ days: '30', roomId: 'allrooms' })}
          />

          <InactiveRooms params={JSON.stringify({ days: '30' })} />
        </div>
      </div>

      {isTraditionalCommsVisible && <>
        <h1 className={styles.header}>Calling</h1>
        <div className={styles.pageContainer}>
          <div className={styles.pageGrid}>
            <CallQueues params={""} />
          </div>
        </div>
      </>
      }

      <h1 className={styles.header}>Teams & groups</h1>
      <div className={styles.pageContainer}>
        <div className={styles.pageGrid}>
          <DoughnutChartTile
            contextData={createMenuItems(DashboardTiles.TeamsAndGroupsAdoption)}
            classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
            sortData={teamsGroupsCompareMenuItems}
            initialSelectedItem={teamsGroupsCompareMenuItems.find(
              (item) => item.value === teamsGroupsDays
            )}
            headerTitle={'MS Teams adoption'}
            isLoading={isTeamsGroupsAdoptionLoading}
            data={
              teamsGroupsAdoptionData
                ? Object.values(teamsGroupsAdoptionData)
                : []
            }
            dataLabels={getTeamsGroupsDataLabels(teamsGroupsAdoptionData)}
            showBracketLabelValue={false}
            buttonLabel={'Teams adoption data'}
            linkPath={ANALYTICS_NAVIGATION_MAP['teamsGroupsAdoption']}
            handleButtonClick={() => {
              setNavigationTilePathOptions(TeamGroupType.Adoption);
            }}
          />

          <DoughnutChartTile
            contextData={createMenuItems(
              DashboardTiles.TeamsAndGroupsCollaboration
            )}
            classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
            sortData={collabCompareMenuItems}
            initialSelectedItem={collabCompareMenuItems.find(
              (item) => item.value === collabDays
            )}
            headerTitle={'Teams & groups collaboration'}
            isLoading={isCollabDataLoading}
            data={collabData ? Object.values(collabData) : []}
            dataLabels={getCollabDataLabels(collabData)}
            showBracketLabelValue={false}
            buttonLabel={'Teams & groups activity data'}
            linkPath={ANALYTICS_NAVIGATION_MAP['teamsGroupsAdoption']}
            handleButtonClick={() => {
              setNavigationTilePathOptions(TeamGroupType.Collaboration);
            }}
          />

          <TeamsAndGroupsActivitySnapshot
            classNames="col-span-6 md:col-span-3 xl:col-span-2"
            params={JSON.stringify({ days: '30', dataType: 'allactivity' })}
          />

          <OrgGroupActivityUser
            params={JSON.stringify({ days: '30', dataType: 'allactivity' })}
          />

          <DoughnutChartTile
            contextData={createMenuItems(DashboardTiles.MsTeamsHealth)}
            classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
            sortData={msTeamsHealthSortMenuItems}
            initialSelectedItem={msTeamsHealthSortMenuItems.find(
              (item) => item.value === msTeamsServiceType
            )}
            headerTitle={'MS Teams health'}
            isLoading={isMsHealthLoading && !msTeamsHealthData}
            data={
              msTeamsHealthData
                ? [
                  msTeamsHealthData?.teamsWith,
                  msTeamsHealthData?.teamsWithout,
                ]
                : []
            }
            dataLabels={[
              `${msTeamsHealthData && msTeamsHealthData?.teamsWithout
                ? msTeamsHealthData.teamsWithout
                : 0
              } teams have no  ${msTeamsServiceType === 'owner' ? 'owners' : 'members'
              }`,
              `${msTeamsHealthData && msTeamsHealthData?.teamsWith
                ? msTeamsHealthData.teamsWith
                : 0
              } teams have ${msTeamsServiceType === 'owner' ? `owners` : 'members'
              }`,
            ]}
            showBracketLabelValue={false}
            buttonLabel={'MS Teams health data'}
            linkPath={ANALYTICS_NAVIGATION_MAP['msTeamsHealth']}
          />

          <DoughnutChartTile
            contextData={createMenuItems(DashboardTiles.ServiceAdoptionByUser)}
            classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
            sortData={createServiceAdoptionMenuItems()}
            headerTitle={'Service adoption by user'}
            isLoading={isServiceAdoptionLoading}
            data={serviceAdoptionData ? Object.values(serviceAdoptionData) : []}
            dataLabels={[
              `${serviceAdoptionData
                ? formatServiceAdoptionValue(
                  adjustRounding(
                    Number(serviceAdoptionData['notUsingPercentage'])
                  )
                )
                : 0
              }% of users have never used one or more services`,
              `${serviceAdoptionData
                ? formatServiceAdoptionValue(
                  adjustRounding(
                    Number(serviceAdoptionData['usingPercentage'])
                  )
                )
                : 0
              }% of users have used all services`,
            ]}
            showBracketLabelValue={false}
            buttonLabel={'Service adoption by user data'}
            linkPath={ANALYTICS_NAVIGATION_MAP['serviceAdoption']}
          />
        </div>
      </div>
    </>
  );
};

export default AdoptionsPage;
