import { API_ENDPOINTS } from '@api/ApiEndpoints';
import {
  BasePreviewQueryOptions,
  BaseSnapshotQueryOptions,
  MeetingRoomTrendCompareAgainstResponse,
  NumberSnapshotResponse,
  TrendCompareAgainstResponse,
} from '@common/types';
import { ServiceType } from '@pages/InsightsAndAnalytics/Adoption/ServiceAdoption';
import { UseQueryResult, useQuery } from 'react-query';
import {
  CollabSnapshotResponse,
  DeviceAdoptionPreviewQueryOptions,
  DeviceAdoptionQueryOptions,
  DeviceAdoptionSnapshotResponse,
  GroupsActivitySnapshotResponse,
  InactiveRoomsSnapshotResponse,
  MeetingAdoptionPreviewQueryOptions,
  MeetingAdoptionQueryOptions,
  MeetingAdoptionResponse,
  MeetingAdoptionSnapshotResponse,
  MeetingAdoptionTrendResponse,
  MeetingRoomVideoQueryOptions,
  MeetingRoomsPreviewResponse,
  MeetingRoomsResponse,
  MeetingRoomsSnapshotResponse,
  MeetingsHeldSnapshotResponse,
  MeetingsRoomBookedHoursResponse,
  MessagesSentSnapshotResponse,
  MsTeamsHealthPreviewQueryOptions,
  MsTeamsHealthPreviewResponse,
  MsTeamsHealthSnapshotResponse,
  OrgGroupActivityUserPreviewResponse,
  OrgGroupActivityUserSnapshotResponse,
  ServiceAdoptionPreviewQueryOptions,
  ServiceAdoptionPreviewResponse,
  ServiceAdoptionSnapshotResponse,
  TeamsGroupsActivityPreviewQueryOptions,
  TeamsGroupsActivityPreviewResponse,
  TeamsGroupsAdoptionPreviewQueryOptions,
  TeamsGroupsAdoptionPreviewResponse,
  TeamsGroupsAdoptionSnapshotResponse,
  UseMeetingsHeldSnapshotQueryOptions,
  UseMessagesSentSnapshotQueryOptions,
  UseServiceAdoptionSnapshotQueryOptions,
  UseTeamsGroupsAdoptionQueryOptions,
  UserServiceAdoption,
  UserServiceAdoptionActivityResponse,
  UserServiceAdoptionPreviewQueryOptions,
  UserServiceAdoptionQueryOptions,
  UserServiceAdoptionSnapshotResponse,
  UserServiceAdoptionStructured,
  UserServiceAdoptionStructuredResponse,
  UserServiceAdoptionTrendResponse,
} from './types';
import { useHttpClient } from '@hooks/utils/httpConfig';

export function useServiceAdoptionSnapshotQuery({
  serviceType,
  days,
}: UseServiceAdoptionSnapshotQueryOptions = {}): UseQueryResult<ServiceAdoptionSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<ServiceAdoptionSnapshotResponse> => {
    const params = new URLSearchParams();
    if (serviceType) {
      params.append('ServiceType', serviceType);
    }
    if (days) {
      params.append('Days', days);
    }

    return httpClient.get<ServiceAdoptionSnapshotResponse>(
      `${API_ENDPOINTS.SERVICE_ADOPTION_SNAPSHOT}?${params.toString()}`
    );
  };

  return useQuery(['serviceAdoption', serviceType, days], fetchSnapshot);
}

export function useAdoptionPerServiceSnapshotQuery({
  serviceType,
  days,
}: UseServiceAdoptionSnapshotQueryOptions = {}): UseQueryResult<ServiceAdoptionSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<ServiceAdoptionSnapshotResponse> => {
    const params = new URLSearchParams();
    if (days) {
      params.append('Days', days);
    }

    let endpoint = `${API_ENDPOINTS.SERVICE_ADOPTION_BASE}`;

    switch (serviceType) {
      case ServiceType.Chat:
        endpoint += 'messagessent';
        break;
      case ServiceType.PhoneExternal:
        endpoint += 'externalcall';
        break;
      case ServiceType.PhoneInternal:
        endpoint += 'internalcall';
        break;
      case ServiceType.Video:
        endpoint += 'videocall';
        break;
      default:
        endpoint;
    }

    return httpClient.get<ServiceAdoptionSnapshotResponse>(
      `${endpoint}?${params.toString()}`
    );
  };

  return useQuery(['serviceAdoption', days, serviceType], fetchSnapshot);
}

export function useServiceAdoptionPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  days,
  serviceAdoptionActivity,
  locationList,
  groupList,
}: ServiceAdoptionPreviewQueryOptions = {}): UseQueryResult<ServiceAdoptionPreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<ServiceAdoptionPreviewResponse> => {
    const baseParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.searchTerm = searchTerm;
    if (sortBy) baseParams.sortBy = sortBy;
    if (order) baseParams.order = order;
    if (days) baseParams.Days = days;

    const groupListParams = groupList
      ? groupList.map((group) => `GroupList=${group}`).join('&')
      : '';

    const locationListParams = locationList
      ? locationList.map((location) => `LocationList=${location}`).join('&')
      : '';

    const filterParams = serviceAdoptionActivity
      ? serviceAdoptionActivity
          .map((ac) => `UserActivityIndicators=${ac}`)
          .join('&')
      : '';

    const endpoint = `${API_ENDPOINTS.SERVICE_ADOPTION}?${new URLSearchParams(
      baseParams
    ).toString()}&${filterParams}&${groupListParams}&${locationListParams}`;

    return httpClient.get<ServiceAdoptionPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'serviceAdoptionPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      serviceAdoptionActivity,
      days,
      locationList,
      groupList,
    ],
    fetchData
  );
}

export function useTeamsGroupsAdoptionSnapshotQuery({
  days,
}: UseTeamsGroupsAdoptionQueryOptions = {}): UseQueryResult<TeamsGroupsAdoptionSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot =
    async (): Promise<TeamsGroupsAdoptionSnapshotResponse> => {
      const params = new URLSearchParams();

      // if (vendorType) {
      //   params.append('VendorType', vendorType);
      // }
      if (days) {
        params.append('Days', days);
      }

      return httpClient.get<TeamsGroupsAdoptionSnapshotResponse>(
        `${API_ENDPOINTS.TEAMS_GROUPS_ADOPTION_SNAPSHOT}?${params.toString()}`
      );
    };

  return useQuery(['teamsGroupsAdoption', days], fetchSnapshot);
}

export function useCollabSnapshotQuery({
  days,
}: UseTeamsGroupsAdoptionQueryOptions = {}): UseQueryResult<CollabSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<CollabSnapshotResponse> => {
    const params = new URLSearchParams();

    if (days) {
      params.append('Days', days);
    }

    return httpClient.get<CollabSnapshotResponse>(
      `${API_ENDPOINTS.COLLAB_SNAPSHOT}?${params.toString()}`
    );
  };

  return useQuery(['collab', days], fetchSnapshot);
}

export function useTeamsGroupsAdoptionPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  collaboration,
  locationList,
  groupList,
}: TeamsGroupsAdoptionPreviewQueryOptions = {}): UseQueryResult<TeamsGroupsAdoptionPreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<TeamsGroupsAdoptionPreviewResponse> => {
    const baseParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.searchTerm = searchTerm;
    if (sortBy) baseParams.sortBy = sortBy;
    if (order) baseParams.order = order;

    const groupListParams = groupList
      ? groupList.map((group) => `GroupList=${group}`).join('&')
      : '';

    const locationListParams = locationList
      ? locationList.map((location) => `LocationList=${location}`).join('&')
      : '';

    const filterListParams = collaboration
      ? collaboration.map((vendor) => `NeverCollaborated=${vendor}`).join('&')
      : '';

    const endpoint = `${
      API_ENDPOINTS.TEAMS_GROUPS_ADOPTION
    }?${new URLSearchParams(
      baseParams
    ).toString()}&${filterListParams}&${groupListParams}&${locationListParams}`;

    return httpClient.get<TeamsGroupsAdoptionPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'teamGroupsAdoptionPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      collaboration,
      locationList,
      groupList,
    ],
    fetchData
  );
}

export function useTeamsGroupsTrendQuery({
  days,
  dataType,
}: UseTeamsGroupsAdoptionQueryOptions = {}): UseQueryResult<TrendCompareAgainstResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<TrendCompareAgainstResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;
    if (dataType) baseParams.DataType = dataType;

    const endpoint = `${
      API_ENDPOINTS.TEAMS_GROUPS_ADOPTION_TREND
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<TrendCompareAgainstResponse>(endpoint);
  };

  return useQuery(['teamGroupsAdoptionTrend', { days, dataType }], fetchData);
}

export function useMsTeamsHealthSnapshotQuery({
  days,
  dataType,
  collaboration,
}: UseTeamsGroupsAdoptionQueryOptions = {}): UseQueryResult<MsTeamsHealthSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<MsTeamsHealthSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;
    if (dataType) baseParams.dataType = dataType;

    let collaborationListParams = '';

    collaborationListParams = collaboration
      ? collaboration.map((item) => `PoorHealthIndicators=${item}`).join('&')
      : '';

    const endpoint = `${
      API_ENDPOINTS.MS_TEAMS_HEALTH_SNAPSHOT
    }?${new URLSearchParams(baseParams).toString()}&${collaborationListParams}`;

    return httpClient.get<MsTeamsHealthSnapshotResponse>(endpoint);
  };

  return useQuery(
    ['msTeamsHealth', days, dataType, collaboration],
    fetchSnapshot
  );
}

export function useMsTeamsHealthPreviewQuery({
  days,
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  msTeamsHealthCollaboration,
}: MsTeamsHealthPreviewQueryOptions = {}): UseQueryResult<MsTeamsHealthPreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<MsTeamsHealthPreviewResponse> => {
    const baseParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.searchTerm = searchTerm;
    if (sortBy) baseParams.sortBy = sortBy;
    if (order) baseParams.order = order;
    if (days) baseParams.days = days;

    const collaborationListParams = msTeamsHealthCollaboration
      ? msTeamsHealthCollaboration
          .map((item) => `PoorHealthIndicators=${item}`)
          .join('&')
      : '';

    const endpoint = `${API_ENDPOINTS.MS_TEAMS_HEALTH}?${new URLSearchParams(
      baseParams
    ).toString()}&${collaborationListParams}`;

    return httpClient.get<MsTeamsHealthPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'msTeamsHealthPreview',
      days,
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      msTeamsHealthCollaboration,
    ],
    fetchData
  );
}

export function useMessagesSentSnapshotQuery({
  days,
  collaboration,
}: UseMessagesSentSnapshotQueryOptions = {}): UseQueryResult<MessagesSentSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<MessagesSentSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.days = days;

    const collaborationListParams = collaboration
      ? collaboration.map((item) => `PoorHealthIndicators=${item}`).join('&')
      : '';

    const endpoint = `${API_ENDPOINTS.MS_MESSAGES_SENT}?${new URLSearchParams(
      baseParams
    ).toString()}&${collaborationListParams}`;

    return httpClient.get<MessagesSentSnapshotResponse>(endpoint);
  };

  return useQuery(['msMessagesSent', days, collaboration], fetchData);
}

export function useMeetingsHeldSnapshotQuery({
  days,
  collaboration,
}: UseMeetingsHeldSnapshotQueryOptions = {}): UseQueryResult<MeetingsHeldSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<MeetingsHeldSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.days = days;

    const collaborationListParams = collaboration
      ? collaboration.map((item) => `PoorHealthIndicators=${item}`).join('&')
      : '';

    const endpoint = `${API_ENDPOINTS.MEETINGS_HELD}?${new URLSearchParams(
      baseParams
    ).toString()}&${collaborationListParams}`;

    return httpClient.get<MeetingsHeldSnapshotResponse>(endpoint);
  };

  return useQuery(['meetingsHeld', days, collaboration], fetchData);
}

export function useMeetingAdoptionSnapshotQuery({
  days,
  type,
}: MeetingAdoptionQueryOptions = {}): UseQueryResult<MeetingAdoptionSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<MeetingAdoptionSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;

    const endpoint = `${
      API_ENDPOINTS.MEETING_ADOPTION_SNAPSHOT
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<MeetingAdoptionSnapshotResponse>(endpoint);
  };

  return useQuery(['meetingAdoption', days, type], fetchSnapshot);
}

export function useMeetingAdoptionPreviewQuery({
  days,
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  meetingAdoptionFilter,
  locationList,
  groupList,
}: MeetingAdoptionPreviewQueryOptions = {}): UseQueryResult<MeetingAdoptionResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<MeetingAdoptionResponse> => {
    const baseParams: Record<string, string> = {
      PageSize: pageSize.toString(),
      PageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.SearchTerm = searchTerm;
    if (sortBy) baseParams.SortBy = sortBy;
    if (order) baseParams.Order = order;
    if (days) baseParams.Days = days;

    const filterParams = meetingAdoptionFilter
      ? meetingAdoptionFilter
          .map((item) => `HasntSetupJoinedMeeting=${item}`)
          .join('&')
      : '';

    const groupListParams = groupList
      ? groupList.map((group) => `GroupList=${group}`).join('&')
      : '';

    const locationListParams = locationList
      ? locationList.map((location) => `LocationList=${location}`).join('&')
      : '';

    const endpoint = `${API_ENDPOINTS.MEETING_ADOPTION}?${new URLSearchParams(
      baseParams
    ).toString()}&${filterParams}&${groupListParams}&${locationListParams}`;

    return httpClient.get<MeetingAdoptionResponse>(endpoint);
  };

  return useQuery(
    [
      'meetingAdoptionPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      meetingAdoptionFilter,
      days,
      locationList,
      groupList,
    ],
    fetchData
  );
}

export function useMeetingAdoptionTrendQuery({
  days,
}: MeetingAdoptionQueryOptions = {}): UseQueryResult<MeetingAdoptionTrendResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<MeetingAdoptionTrendResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;

    const endpoint = `${
      API_ENDPOINTS.MEETING_ADOPTION_TREND
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<MeetingAdoptionTrendResponse>(endpoint);
  };

  return useQuery(
    [
      'meetingAdoptionTrend',
      {
        days,
      },
    ],
    fetchData
  );
}

export function useUserServiceAdoptionSnapshotQuery({
  days,
  dataType,
}: UserServiceAdoptionQueryOptions = {}): UseQueryResult<UserServiceAdoptionSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot =
    async (): Promise<UserServiceAdoptionSnapshotResponse> => {
      const baseParams: Record<string, string> = {};

      if (days) baseParams.Days = days;
      if (dataType) baseParams.DataType = dataType;

      const endpoint = `${
        API_ENDPOINTS.USER_SERVICE_ADOPTION_SNAPSHOT
      }?${new URLSearchParams(baseParams).toString()}`;

      return httpClient.get<UserServiceAdoptionSnapshotResponse>(endpoint);
    };

  return useQuery(['userServiceAdoption', days, dataType], fetchSnapshot);
}

export function useUserServiceAdoptionPreviewQuery({
  days,
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  userServiceAdoptionFilter,
}: UserServiceAdoptionPreviewQueryOptions = {}): UseQueryResult<UserServiceAdoptionStructuredResponse> {
  const httpClient = useHttpClient();
  const fetchData =
    async (): Promise<UserServiceAdoptionStructuredResponse> => {
      const baseParams: Record<string, string> = {
        PageSize: pageSize.toString(),
        PageNumber: pageNumber.toString(),
      };

      if (searchTerm) baseParams.SearchTerm = searchTerm;
      if (sortBy) baseParams.SortBy = sortBy;
      if (order) baseParams.Order = order;
      if (days) baseParams.Days = days;

      const filterParams = userServiceAdoptionFilter
        ? userServiceAdoptionFilter
            .map((item) => `ServiceAdoption=${item}`)
            .join('&')
        : '';

      const endpoint = `${
        API_ENDPOINTS.USER_SERVICE_ADOPTION_ACTIVITY
      }?${new URLSearchParams(baseParams).toString()}&${filterParams}`;

      const response =
        await httpClient.get<UserServiceAdoptionActivityResponse>(endpoint);

      const {
        items,
        currentPage,
        itemCount,
        pageSize: pageSizeResponse,
        totalItemCount,
        totalPageCount,
        lastUpdatedAt,
      } = response;

      const groupedByService: Record<string, UserServiceAdoption[]> =
        items.reduce((acc, item) => {
          const { serviceName } = item;
          acc[serviceName] = acc[serviceName] || [];
          acc[serviceName].push(item);
          return acc;
        }, {} as Record<string, UserServiceAdoption[]>);

      const vendorOrder = ['All', 'Microsoft', 'RingCentral'];

      const structuredData: UserServiceAdoptionStructured[] = Object.entries(
        groupedByService
      ).map(([serviceName, vendors]) => {
        const sortedVendors = vendors.sort((a, b) => {
          const indexA = vendorOrder.indexOf(a.vendor);
          const indexB = vendorOrder.indexOf(b.vendor);
          return indexA - indexB;
        });

        return { serviceName, vendors: sortedVendors };
      });

      return {
        items: structuredData,
        currentPage,
        itemCount,
        pageSize: pageSizeResponse,
        totalItemCount,
        totalPageCount,
        lastUpdatedAt,
      };
    };

  return useQuery(
    [
      'userServiceAdoptionPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      userServiceAdoptionFilter,
      days,
    ],
    fetchData
  );
}

export function useUserServiceAdoptionTrendQuery({
  days,
  dataType,
}: UserServiceAdoptionQueryOptions = {}): UseQueryResult<UserServiceAdoptionTrendResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<UserServiceAdoptionTrendResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;
    if (dataType) baseParams.DataType = dataType;

    const endpoint = `${
      API_ENDPOINTS.USER_SERVICE_ADOPTION_TREND
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<UserServiceAdoptionTrendResponse>(endpoint);
  };

  return useQuery(
    [
      'userServiceAdoptionTrend',
      {
        days,
        dataType,
      },
    ],
    fetchData
  );
}

export function useOrgGroupActivityUserTopTeamsSnapshotQuery({
  days,
  dataType,
}: BaseSnapshotQueryOptions = {}): UseQueryResult<OrgGroupActivityUserSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot =
    async (): Promise<OrgGroupActivityUserSnapshotResponse> => {
      const baseParams: Record<string, string> = {};

      if (days) baseParams.Days = days;
      if (dataType) baseParams.DataType = dataType;

      const endpoint = `${
        API_ENDPOINTS.ORG_GROUPS_ACTIVITY_USER_TOP_TEAMS_SNAPSHOT
      }?${new URLSearchParams(baseParams).toString()}`;

      return httpClient.get<OrgGroupActivityUserSnapshotResponse>(endpoint);
    };

  return useQuery(
    ['orgGroupActivityUserTopTeam', days, dataType],
    fetchSnapshot
  );
}

export function useOrgGroupActivityUserSnapshotMessagesQuery({
  days,
}: BaseSnapshotQueryOptions = {}): UseQueryResult<NumberSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<NumberSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;

    const endpoint = `${
      API_ENDPOINTS.ORG_GROUPS_ACTIVITY_USER_MESSAGES_SNAPSHOT
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<NumberSnapshotResponse>(endpoint);
  };

  return useQuery(['orgGroupActivityUserMessages', days], fetchSnapshot);
}

export function useOrgGroupActivityUserSnapshotMeetingsQuery({
  days,
}: BaseSnapshotQueryOptions = {}): UseQueryResult<NumberSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<NumberSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;

    const endpoint = `${
      API_ENDPOINTS.ORG_GROUPS_ACTIVITY_USER_MEETINGS_SNAPSHOT
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<NumberSnapshotResponse>(endpoint);
  };

  return useQuery(['orgGroupActivityUserMeetings', days], fetchSnapshot);
}

export function useOrgGroupActivityUserSnapshotQuery({
  days,
  dataType,
}: BaseSnapshotQueryOptions = {}): UseQueryResult<OrgGroupActivityUserSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot =
    async (): Promise<OrgGroupActivityUserSnapshotResponse> => {
      const baseParams: Record<string, string> = {};

      if (days) baseParams.Days = days;
      if (dataType) baseParams.DataType = dataType;

      const endpoint = `${
        API_ENDPOINTS.ORG_GROUPS_ACTIVITY_USER_SNAPSHOT
      }?${new URLSearchParams(baseParams).toString()}`;

      return httpClient.get<OrgGroupActivityUserSnapshotResponse>(endpoint);
    };

  return useQuery(
    ['orgGroupActivityUserSnapshot', days, dataType],
    fetchSnapshot
  );
}

export function useOrgGroupActivityUserPreviewQuery({
  days,
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
}: BasePreviewQueryOptions = {}): UseQueryResult<OrgGroupActivityUserPreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<OrgGroupActivityUserPreviewResponse> => {
    const baseParams: Record<string, string> = {
      PageSize: pageSize.toString(),
      PageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.SearchTerm = searchTerm;
    if (sortBy) baseParams.SortBy = sortBy;
    if (order) baseParams.Order = order;
    if (days) baseParams.Days = days;

    const endpoint = `${
      API_ENDPOINTS.ORG_GROUPS_ACTIVITY_USER_PREVIEW
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<OrgGroupActivityUserPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'orgGroupActivityUserPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      days,
    ],
    fetchData
  );
}

export function useDevicesAdoptionSnapshotQuery({
  days,
  dataType,
}: DeviceAdoptionQueryOptions = {}): UseQueryResult<DeviceAdoptionSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<DeviceAdoptionSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;
    if (dataType) baseParams.DeviceType = dataType;

    const endpoint = `${
      API_ENDPOINTS.DEVICE_ADOPTION_SNAPSHOT
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<DeviceAdoptionSnapshotResponse>(endpoint);
  };

  return useQuery(['deviceAdoption', days, dataType], fetchSnapshot);
}

export function useDeviceAdoptionPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  typeList,
  vendorList,
  locationList,
  groupList,
}: DeviceAdoptionPreviewQueryOptions = {}): UseQueryResult<MeetingAdoptionResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<MeetingAdoptionResponse> => {
    const baseParams: Record<string, string> = {
      PageSize: pageSize.toString(),
      PageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.SearchTerm = searchTerm;
    if (sortBy) baseParams.SortBy = sortBy;
    if (order) baseParams.Order = order;

    const typeListParams = typeList
      ? typeList.map((type) => `TypeList=${type}`).join('&')
      : '';

    const vendorListParams = vendorList
      ? vendorList.map((vendor) => `VendorList=${vendor}`).join('&')
      : '';

    const groupListParams = groupList
      ? groupList.map((group) => `GroupList=${group}`).join('&')
      : '';

    const locationListParams = locationList
      ? locationList.map((location) => `LocationList=${location}`).join('&')
      : '';

    const endpoint = `${
      API_ENDPOINTS.DEVICE_ADOPTION_PREVIEW
    }?${new URLSearchParams(
      baseParams
    ).toString()}&${typeListParams}&${vendorListParams}&${groupListParams}&${locationListParams}`;

    return httpClient.get<MeetingAdoptionResponse>(endpoint);
  };

  return useQuery(
    [
      'deviceAdoptionPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      typeList,
      vendorList,
      locationList,
      groupList,
    ],
    fetchData
  );
}

export function useMeetingsInactiveRoomsSnapshotQuery({
  days,
}: MeetingAdoptionQueryOptions = {}): UseQueryResult<InactiveRoomsSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<InactiveRoomsSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;

    const endpoint = `${
      API_ENDPOINTS.MEETINGS_INACTIVE_ROOMS_SNAPSHOT
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<InactiveRoomsSnapshotResponse>(endpoint);
  };

  return useQuery(['inactiveRooms', days], fetchSnapshot);
}

export function useMeetingRoomVideoSnapshotQuery({
  days,
  roomId,
}: MeetingRoomVideoQueryOptions = {}): UseQueryResult<MeetingRoomsSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<MeetingRoomsSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;
    if (roomId) baseParams.RoomId = roomId;

    const endpoint = `${
      API_ENDPOINTS.MEETING_ROOM_VIDEO_SNAPSHOT
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<MeetingRoomsSnapshotResponse>(endpoint);
  };

  return useQuery(['meetingRoomsVideo', days, roomId], fetchSnapshot);
}

export function useMeetingRoomsQuery(): UseQueryResult<MeetingRoomsResponse> {
  const httpClient = useHttpClient();
  const fetchRooms = async (): Promise<MeetingRoomsResponse> => {
    return httpClient.get<MeetingRoomsResponse>(
      `${API_ENDPOINTS.MEETING_ROOMS}`
    );
  };

  return useQuery(['meetingRooms'], fetchRooms);
}

export function useMeetingsRoomBookedHoursSnapshot({
  days,
  dataType,
}: BaseSnapshotQueryOptions = {}): UseQueryResult<MeetingsRoomBookedHoursResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<MeetingsRoomBookedHoursResponse> => {
    const baseParams: Record<string, string> = {};

    if (dataType) baseParams.Rank = dataType;
    if (days) baseParams.Days = days;

    const endpoint = `${
      API_ENDPOINTS.MEETINGS_ROOMS_BOOKED_HOURS_SNAPSHOT
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<MeetingsRoomBookedHoursResponse>(endpoint);
  };

  return useQuery(['meetingsRoomsBookedHours', days, dataType], fetchSnapshot);
}

export function useMeetingRoomsTrend({
  days,
  roomId,
}: MeetingRoomVideoQueryOptions = {}): UseQueryResult<MeetingRoomTrendCompareAgainstResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot =
    async (): Promise<MeetingRoomTrendCompareAgainstResponse> => {
      const baseParams: Record<string, string> = {};

      if (days) baseParams.Days = days;
      if (roomId) baseParams.RoomId = roomId;

      const endpoint = `${
        API_ENDPOINTS.MEETING_ROOMS_BOOKED_HOURS_TREND
      }?${new URLSearchParams(baseParams).toString()}`;

      return httpClient.get<MeetingRoomTrendCompareAgainstResponse>(endpoint);
    };

  return useQuery(['meetingRoomsTrend', days, roomId], fetchSnapshot);
}

export function useMeetingRoomsPreviewQuery({
  days,
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  groupList,
  locationList,
}: BasePreviewQueryOptions = {}): UseQueryResult<MeetingRoomsPreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<MeetingRoomsPreviewResponse> => {
    const baseParams: Record<string, string> = {
      PageSize: pageSize.toString(),
      PageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.SearchTerm = searchTerm;
    if (sortBy) baseParams.SortBy = sortBy;
    if (order) baseParams.Order = order;
    if (days) baseParams.Days = days;

    const groupListParams = groupList
      ? groupList.map((group) => `GroupList=${group}`).join('&')
      : '';

    const locationListParams = locationList
      ? locationList.map((location) => `LocationList=${location}`).join('&')
      : '';

    const endpoint = `${
      API_ENDPOINTS.MEETING_ROOMS_PREVIEW
    }?${new URLSearchParams(
      baseParams
    ).toString()}&${groupListParams}&${locationListParams}`;

    return httpClient.get<MeetingRoomsPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'meetingRoomsPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      days,
      locationList,
      groupList,
    ],
    fetchData
  );
}

export function useTeamsAndGroupActivitySnapshot({
  days,
  dataType,
}: DeviceAdoptionQueryOptions = {}): UseQueryResult<GroupsActivitySnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<GroupsActivitySnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;
    if (dataType) baseParams.DataType = dataType;

    const endpoint = `${
      API_ENDPOINTS.TEAMS_AND_GROUPS_ACTIVTY_SNAPSHOT
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<GroupsActivitySnapshotResponse>(endpoint);
  };

  return useQuery(
    ['teamsAndGroupActivitySnapshot', days, dataType],
    fetchSnapshot
  );
}

export function useTeamsGroupsActivityPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  activityType,
  days,
  locationList,
  groupList,
}: TeamsGroupsActivityPreviewQueryOptions = {}): UseQueryResult<TeamsGroupsActivityPreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<TeamsGroupsActivityPreviewResponse> => {
    const baseParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
    };
    if (days) baseParams.Days = days;
    if (searchTerm) baseParams.SearchTerm = searchTerm;
    if (sortBy) baseParams.sortBy = sortBy;
    if (order) baseParams.order = order;

    const groupListParams = groupList
      ? groupList.map((group) => `GroupList=${group}`).join('&')
      : '';

    const locationListParams = locationList
      ? locationList.map((location) => `LocationList=${location}`).join('&')
      : '';

    const activityTypeParams = activityType
      ? activityType.map((activity) => `ActivityType=${activity}`).join('&')
      : '';

    const endpoint = `${
      API_ENDPOINTS.TEAMS_AND_GROUPS_ACTIVTY_PREVIEW
    }?${new URLSearchParams(
      baseParams
    ).toString()}&${activityTypeParams}&${groupListParams}&${locationListParams}`;

    return httpClient.get<TeamsGroupsActivityPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'teamGroupsActivityPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      activityType,
      days,
      locationList,
      groupList,
    ],
    fetchData
  );
}

export const formatServiceAdoptionValue = (value: number) => {
  const roundedValue = value >= 0.5 ? Math.ceil(value) : Math.floor(value);
  const formattedValue = roundedValue.toFixed(0);

  return formattedValue;
};

export const adjustRounding = (value: number): number => {
  if (value < 0.5) {
    return 0;
  } else if (value >= 99.5) {
    return 100;
  } else {
    return Math.round(value);
  }
};
