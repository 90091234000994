import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { useAddDashboardTile, useRemoveDashboardTile } from '@hooks/dashboard';
import { useLicencesAssignmentQuery } from '@hooks/licenses';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';

export const UnassignedLicensesSnapshot: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.UnassignedLicensesSnapshot,
    ''
  );
  const isOnDashboardPage = !!id;

  const {
    data: licencesAssignmentData,
    isLoading: isLicencesAssignmentLoading,
  } = useLicencesAssignmentQuery(JSON.parse(params));

  return (
    <DoughnutChartTile
      classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
      dropdownMenuItemClasses={'!w-[300px]'}
      headerTitle={'Unassigned licenses: Snapshot'}
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      isLoading={isLicencesAssignmentLoading}
      data={licencesAssignmentData ? Object.values(licencesAssignmentData) : []}
      dataLabels={['Unassigned licenses', 'Assigned licenses']}
    />
  );
};
