import {
  CellLeftStatusPart,
  CellLeftStatusWithIconPart,
} from '@components/ui/Table/utils';
import { formatDate } from '@utils/index';

export const createCellValue = (property: string, data: any, index: number) => {
  const value = data[index][property];

  switch (property) {
    case 'status':
      return (
        <>
          <CellLeftStatusPart status={value} />
          <span>{value}</span>
          {value === 'Expired' && (
            <span className="block text-14 text-interfaceColor-80">
              Will be disabled {formatDate(data[index]['disabledDate'])}
            </span>
          )}
          {value === 'Disabled' && (
            <span className="block text-14 text-interfaceColor-80">
              Will be deleted {formatDate(data[index]['deletedDate'])}
            </span>
          )}
        </>
      );
    case 'autoRenew':
      return (
        <>
          <CellLeftStatusWithIconPart status={data[index]['autoRenew']} />
          <span>{value ? 'Active' : 'Inactive'}</span>
        </>
      );
  }
};
