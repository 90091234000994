import * as React from 'react';
import { IconProps } from './types';

export const AddPeopleIcon: React.FC<IconProps> = ({
  size = 5,
  color = 'text-white',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      className={`stroke-current w-${size} h-${size} ${color} ${classNames}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M9.715 10.287a2.857 2.857 0 1 0 0-5.715 2.857 2.857 0 0 0 0 5.715ZM11.43 18.286H4.571v-.62a5.154 5.154 0 0 1 7.429-4.57M16 12.572v6.857M12.572 16h6.857"
      />
    </svg>
  );
};
