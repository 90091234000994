import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useState } from 'react';
import { TilesProps } from '@common/types';
import { useActivityPerDayTrendQuery } from '@hooks/productivity';
import {
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { getTrendData, getTrendLabels } from '@utils/index';
import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';

type Params = {
  activity: string;
  days: string;
};

export const ActivityPerDay: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.ActivityPerDay,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: activityPerDayPeopleTrendData,
    isLoading: isActivityPerDayPeopleLoading,
    refetch,
  } = useActivityPerDayTrendQuery(parameters);

  const handleDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      activity: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const handleDaysDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const activityPerDayMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All activities',
      value: 'allactivities',
      action: () => handleDropdown('allactivities'),
    },
    {
      id: '2',
      label: 'Emails',
      value: 'emails',
      action: () => handleDropdown('emails'),
    },
    {
      id: '3',
      label: 'Messages',
      value: 'messages',
      action: () => handleDropdown('messages'),
    },
    {
      id: '4',
      label: 'Calls',
      value: 'calls',
      action: () => handleDropdown('calls'),
    },

    {
      id: '5',
      label: 'Meetings',
      value: 'meetings',
      action: () => handleDropdown('meetings'),
    },
  ];

  const createDropdownMenuItems = (): MenuItem[] => [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleDaysDropdownChange('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleDaysDropdownChange('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleDaysDropdownChange('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleDaysDropdownChange('90'),
    },
  ];

  const labels = getTrendLabels(
    activityPerDayPeopleTrendData?.trend || [],
    +parameters.days
  );

  const trendData = getTrendData(
    activityPerDayPeopleTrendData?.trend || [],
    +parameters.days
  );

  const itemActivitySelected = activityPerDayMenuItems.find(
    (item) => item.value === parameters.activity
  );

  const itemDaysSelected = createDropdownMenuItems().find(
    (item) => item.value === parameters.days
  );

  return (
    parameters && (
      <LineChartTile
        classNames={
          classNames ? classNames : 'col-span-6 md:col-span-6 xl:col-span-4'
        }
        sortData={activityPerDayMenuItems}
        initialSelectedItem={itemActivitySelected}
        initialCompareSelectedItem={itemDaysSelected}
        legendLabels={[]}
        compareData={createDropdownMenuItems()}
        dropdownMenuItemClasses={'!w-[300px]'}
        tooltipLabel={[itemActivitySelected?.label || '']}
        headerTitle={'Activity per day'}
        dataSetTypes={[LineChartTypes.Dots]}
        compareLabel=""
        dataSetColors={['#00CF6C']}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        isLoading={isActivityPerDayPeopleLoading}
        labels={labels}
        data={[trendData]}
        buttonLabel="Activity data"
        linkPath={ANALYTICS_NAVIGATION_MAP['activityData']}
        handleButtonClick={handleButtonClick()}
        showDateRange={true}
      />
    )
  );
};
