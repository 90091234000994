import React from 'react';
import styles from './styles.module.css';
import cn from 'classnames';

type CardProps = {
  children: React.ReactNode;
  classNames?: string;
  testId?: string;
};

type CardComponentType = React.FC<CardProps> & {
  Header: React.FC<CardProps>;
  Content: React.FC<CardProps>;
  Footer: React.FC<CardProps>;
};

export const Card: CardComponentType = ({ children, classNames = '', testId }) => {
  const cardClassNames = cn(styles.card, classNames);
  return (
    <div className={cardClassNames}  data-testid={testId}>
      {children}
    </div>
  );
};

const CardSection: React.FC<CardProps> = ({
  children,
  classNames = '',
  testId,
}) => {
  const sectionClassNames = cn(classNames);
  return (
    <div className={sectionClassNames} data-testid={testId}>
      {children}
    </div>
  );
};

Card.Header = CardSection;
Card.Content = CardSection;
Card.Footer = CardSection;
