import { PageNotFoundIcon } from '@components/ui/Icons/PageNotFoundIcon';
import styles from './styles.module.css';

const Error404Page: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <PageNotFoundIcon />
      <h2 className={styles.header}>Error 404: not found</h2>
      <p>
        We can’t find the page you’re looking for. There are a few reasons why
        this might be happening:
      </p>
      <ul className={styles.list}>
        <li>
          <span>URL typo:</span> the link you clicked to get here may have been
          incorrect. Make sure it’s accurate, or try using the navigation to
          find this page.
        </li>
        <li>
          <span>Server issue:</span> there could be a temporary problem with our
          servers that’s preventing us from accessing this content. If this is
          the case, try refreshing in a few minutes.
        </li>
        <li>
          <span>Resource removed:</span> the content you’re trying to get to may
          not exist any more. This is only likely to happen if the resource
          you’re looking for is very old.
        </li>
      </ul>
      <p>
        If none of the above helps solve your problem, please contact us for
        further support.
      </p>
    </div>
  );
};

export default Error404Page;
