import React, { useState } from 'react';
import Tab, { Tab as TabType } from '../../../../components/ui/Tab';
import { useIsDesktop, useIsMobile } from '@hooks/utils';
import { OverlayPage } from '@components/partials';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import {
  FailedLoginAccounts,
  FailedLoginCountries,
} from '@pages/Dashboard/tiles';
import { Dropdown } from '@components/ui/Dropdown';
import { LAST_DAYS_SORT_BY } from '@common/constants';
import styles from './styles.module.css';
import { FailedLoginTypes } from '@pages/Dashboard/tiles/FailedLoginTypes';
import { useQueryParams } from 'use-query-params';

const floatingSortDropdownData = LAST_DAYS_SORT_BY;

const FailedDataLayout: React.FC = () => {
  const isMobile = useIsMobile();
  const redirectPath = useNavSourceRedirect(
    '/insights-and-analytics/assurance'
  );
  const [days, setDays] = useState('30');
  const [daysSelectedLabel, setDaysSelectedLabel] = useState(
    floatingSortDropdownData[1].label || ''
  );
  const [daysSelectedIndex, setDaysSelectedIndex] = useState(1);
  const [isDaysDropdownOpen, setIsDaysDropdownOpen] = useState(false);
  const isDesktop = useIsDesktop();
  const [queryParams, setQueryParams] = useQueryParams();

  const tabs: TabType[] = [
    {
      path: '/insights-and-analytics/assurance/failed-login-data/accounts',
      label: 'Accounts',
    },
    {
      path: '/insights-and-analytics/assurance/failed-login-data/countries',
      label: 'Countries',
    },
    {
      path: '/insights-and-analytics/assurance/failed-login-data/map',
      label: 'Map',
    },
    {
      path: '/insights-and-analytics/assurance/failed-login-data/login-types',
      label: 'Login types',
    },
  ];

  const handleIsDaysDropdownOpen = () => {
    setIsDaysDropdownOpen(!isDaysDropdownOpen);
  };

  const handleDropdownDaysOnChange = (value: string[], index: number) => {
    setDays(value[0]);
    setDaysSelectedIndex(index);
    setDaysSelectedLabel(floatingSortDropdownData[index].label);
    setQueryParams({ days: value[0] || undefined }, 'pushIn');
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Failed login data`}
    >
      {!isMobile && (
        <div className="absolute left-[40px] top-[100px]">
          <Dropdown>
            <Dropdown.TextHeader
              classNames="!bg-interfaceColor-5 !border !border-interfaceColor-100 rounded-full"
              label={daysSelectedLabel}
              handleOpen={handleIsDaysDropdownOpen}
            ></Dropdown.TextHeader>
            <Dropdown.List
              className="!z-[999] text-16"
              open={isDaysDropdownOpen}
              align="left"
            >
              {floatingSortDropdownData.map((item, index) => {
                return (
                  <Dropdown.DefaultItem
                    key={index}
                    item={item}
                    currentIndex={index}
                    selectedIndex={daysSelectedIndex}
                    onChange={() =>
                      handleDropdownDaysOnChange(item.value, index)
                    }
                  ></Dropdown.DefaultItem>
                );
              })}
            </Dropdown.List>
          </Dropdown>
        </div>
      )}
      <div className={styles.pageGrid}>
        {isDesktop && (
          <>
            <FailedLoginAccounts
              isSnapshot={true}
              classNames="col-span-1"
              params={JSON.stringify({ days, mfaStatus: 'all' })}
            />
            <FailedLoginCountries
              isSnapshot={true}
              classNames="col-span-1"
              params={JSON.stringify({ days })}
            />
            <FailedLoginTypes
              isSnapshot={true}
              classNames="col-span-1"
              params={JSON.stringify({ days })}
            />
          </>
        )}
      </div>
      <div className="mt-[5px]">
        <Tab queryParams={{ days }} tabs={tabs} className={isMobile ? 'mb-[100px]' : 'mb-[0px]'} />
      </div>
    </OverlayPage>
  );
};

export default FailedDataLayout;
