import * as React from 'react';
import { IconProps } from './types';

export const LocationPinIcon: React.FC<IconProps> = ({
  color = 'text-primaryBrandColor-100',
  classNames = '',
  size = 4,
}) => {
  return (
    <svg
      className={`stroke-current w-${size} h-${size} ${color} ${classNames}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 20"
      fill="none"
    >
      <path
        d="M15 7.334c0 4-6 8.667-6 8.667s-6-4.667-6-8.667c0-3.268 2.732-6 6-6s6 2.732 6 6Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M9 9.334a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM3.667 14.014C2.029 14.502 1 15.21 1 16c0 1.474 3.581 2.667 8 2.667 4.419 0 8-1.193 8-2.667 0-.789-1.03-1.498-2.667-1.986"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
    </svg>
  );
};
