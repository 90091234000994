import { OverlayPage } from '@components/partials';
import { Button, DownloadIcon } from '@components/ui';
import { SectionNav } from '@components/ui/SectionNav';
import React, { useEffect } from 'react';
import styles from './styles.module.css';
import { useIsDesktop } from '@hooks/utils';
import useUIStore from '@store/uiStore';

const sectionNavItems = [
  { id: '1', label: 'Introduction' },
  { id: '2', label: 'Important information and who we are' },
  { id: '3', label: 'Your legal rights' },
  { id: '4', label: 'Your legal rights' },
  { id: '5', label: 'Lawful basis' },
  { id: '6', label: 'Third parties' },
  { id: '7', label: 'What personal data we collect' },
  { id: '8', label: 'Version' },
];

export const PrivacyPage: React.FC = () => {
  const isDesktop = useIsDesktop();
  const { isOverlayFixed, setIsOverlayFixed } = useUIStore();

  useEffect(() => {
    if (isOverlayFixed) {
      setIsOverlayFixed(false);
    }

    return () => {
      if (!isOverlayFixed) {
        setIsOverlayFixed(true);
      }
    };
  }, []);

  return (
    <OverlayPage
      headerTitle="Privacy"
      headerText="Effective as of August 17th 2023"
      isFooterVisible={false}
      hasScroll={true}
    >
      <div className={styles.wrapper}>
        <div className={styles.sectionNavWrapper}>
          <SectionNav
            items={sectionNavItems}
            scrollOffset={isDesktop ? -140 : -200}
          />
        </div>
        <div className={styles.sectionsWrapper}>
          {/* <div className={styles.hero}>
            <h1>What’s in these terms?</h1>
            <p className="mb-2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro
              similique ut, autem voluptatum delectus qui ex commodi. Quis,
              repudiandae harum odit numquam voluptate obcaecati, expedita quod
              ea veniam, hic dolor.
            </p>
            <Button size="medium" type="button" variant="outlineLight">
              <DownloadIcon classNames="mr-2" /> Download terms & conditions
            </Button>
          </div> */}

          <section id="section1">
            <h1 className={styles.h1}>Introduction</h1>
            <p className={styles.paragraph}>
              Welcome to the privacy policy for CloudClevr Clevr360
            </p>
            <p className={styles.paragraph}>
              CloudClevr and Clevr360 respects your privacy and is committed to
              protecting your personal data. This privacy policy will inform you
              as to how we look after your personal data when you visit our
              website and tell you about your privacy rights and how the law
              protects you.
            </p>
          </section>

          <section id="section2">
            <h1 className={styles.h1}>Important information and who we are</h1>
            <h2 className={styles.h2}>Purpose of this privacy policy</h2>
            <p className={styles.paragraph}>
              This privacy policy aims to give you information on how Clevr360
              collects and processes your personal data through your use of the
              Clevr360 website.
            </p>
            <p className={styles.paragraph}>
              It is important that you read this privacy policy together with
              the Clevr360 website’s <b>Terms of Use</b>, <b>Cookies Policy</b>,
              and
              <b> Acceptable Use Policy</b>.
            </p>
            <h2 className={styles.h2}>Data Controller</h2>
            <p className={styles.paragraph}>
              This privacy policy is issued on behalf of Clevr360 so when we
              mention “we”, “us” or “our” in this privacy policy, we are
              referring to CloudClevr and Clevr360 who are jointly responsible
              for the processing of your data.
            </p>
            <p className={styles.paragraph}>
              CloudClevr is the “Data Controller” as defined within the General
              Data Protection Regulation 016/679.
            </p>
            <p className={styles.paragraph}>
              If you have any questions about this privacy policy, including any
              requests to exercise your legal rights, please contact us using
              the details set out below.
            </p>
            <h2 className={styles.h2}>Contact details</h2>
            <p className={styles.paragraph}>
              When contacting us about the Clevr360 website, please do so as per
              the details below:
            </p>
            <h4 className={styles.h4}>Postal address</h4>
            <p className={styles.paragraph}>
              Data Protection <br />
              CloudClevr Holdings Limited <br />
              Bridgeway House <br />
              Stratford Upon Avon <br />
              Warwickshire <br />
              CV37 6YX
            </p>
            <h4 className={styles.h4}>Email</h4>
            <p className={styles.paragraph}>
              <a href="mailto:dataprotection@cloudclevr.com">
                dataprotection@cloudclevr.com
              </a>
            </p>
            <p className={styles.paragraph}>
              You have the right to make a complaint at any time to the
              Information Commissioner’s Office (ICO), the UK supervisory
              authority for data protection issues (
              <b>
                <a href="https://ico.org.uk/">www.ico.org.uk</a>
              </b>
              ). We would, however, appreciate the chance to deal with your
              concerns before you approach the ICO, so please contact us in the
              first instance.
            </p>
            <h2 className={styles.h2}>Third-party links</h2>
            <p className={styles.paragraph}>
              Our website may include links to third-party websites, plug-ins,
              and applications. Clicking on those links or enabling those
              connections may allow third parties to collect or share data about
              you. We do not control these third-party websites and are not
              responsible for their privacy statements. When you leave our
              website, we encourage you to read the privacy policy of every
              website you visit.
            </p>
            <h2 className={styles.h2}>Cookies</h2>
            <p className={styles.paragraph}>
              You can set your browser to refuse all or some browser cookies, or
              to alert you when websites set or access cookies. If you disable
              or refuse cookies, please note that some parts of this website may
              become inaccessible or not function properly. For more information
              about the cookies we use, please see the Clevr360 Cookies Policy.
            </p>
            <h2 className={styles.h2}>Data security</h2>
            <p className={styles.paragraph}>
              We have put in place appropriate security measures to prevent your
              personal data from being accidentally lost, used, or accessed in
              an unauthorised way, altered, or disclosed. In addition, we limit
              access to your personal data to those employees, agents,
              contractors and other third parties who have a business need to
              know. They will only process your personal data on our
              instructions, and they are subject to a duty of confidentiality.
            </p>
            <p className={styles.paragraph}>
              We have put in place procedures to deal with any suspected
              personal data breach and will notify you and any applicable
              regulator of a breach where we are legally required to do so.
            </p>
            <h2 className={styles.h2}>Data use</h2>
            <p className={styles.paragraph}>
              What, how, and why we collect personal data is described more
              fully below.
            </p>
            <h4 className={styles.h4}>Where people’s data comes from</h4>
            <p className={styles.paragraph}>
              Peoples’ data is retrieved through automatic processing via
              configured integration to your companies’ services. Only services
              you allow will provide data we process.
            </p>
            <h4 className={styles.h4}>Data retention</h4>
            <p className={styles.paragraph}>
              We will only retain your personal data for as long as necessary to
              fulfil the purposes we collected it for, including for the
              purposes of satisfying any legal, accounting, regulatory or
              reporting requirements. To determine the appropriate retention
              period for personal data, we consider the amount, nature, and
              sensitivity of the personal data, the potential risk of harm from
              unauthorised use or disclosure of your personal data, the purposes
              for which we process your personal data and whether we can achieve
              those purposes through other means, and the applicable legal
              requirements.
            </p>
            <p className={styles.paragraph}>
              Details of retention periods for various aspects of your personal
              data are available on request by contacting us.
            </p>
            <h4 className={styles.h4}>Sharing of data</h4>
            <p className={styles.paragraph}>
              Data might be shared within the CloudClevr group, to support you
              as a customer of Clevr360. Any personal identifiable information
              will not be shared outside the CloudClevr group.
            </p>
            <h4 className={styles.h4}>Anonymised data</h4>
            <p className={styles.paragraph}>
              In some circumstances we may anonymise your personal data (so that
              it can no longer be associated with you) for research or
              statistical purposes in which case we may use this information
              indefinitely without further notice to you.
            </p>
          </section>

          <section id="section3">
            <h1 className={styles.h1}>Your legal rights</h1>
            <p className={styles.paragraph}>
              Under certain circumstances, you have rights under data protection
              laws in relation to your personal data, including:
            </p>
            <ul className={styles.list}>
              <li>requesting access to your personal data</li>
              <li>requesting the correction of your personal data</li>
              <li>requesting erasure of your personal data</li>
              <li>objecting to processing of your personal data</li>
              <li>requesting restriction of processing your personal data</li>
              <li>requesting transfer of your personal data</li>
              <li>the right to withdraw consent previously given.</li>
            </ul>
            <p className={styles.paragraph}>
              If you wish to exercise any of the rights set out above, please
              email{' '}
              <a href="mailto:dataprotection@cloudclevr.com">
                dataprotection@cloudclevr.com
              </a>
            </p>
            <h2 className={styles.h2}>No fee usually required</h2>
            <p className={styles.paragraph}>
              You will not have to pay a fee to access your personal data (or to
              exercise any of the other rights). However, we may charge a
              reasonable fee if your request is clearly unfounded, repetitive,
              or excessive. Alternatively, we may refuse to comply with your
              request in these circumstances.
            </p>
            <h2 className={styles.h2}>What we may need from you</h2>
            <p className={styles.paragraph}>
              We may need to request specific information from you to help us
              confirm your identity and ensure your right to access your
              personal data (or to exercise any of your other rights). This is a
              security measure to ensure that personal data is not disclosed to
              any person who has no right to receive it. We may also contact you
              to ask you for further information in relation to your request to
              speed up our response.
            </p>
            <h2 className={styles.h2}>Time limit to respond</h2>
            <p className={styles.paragraph}>
              We try to respond to all legitimate requests within one month.
              Occasionally it may take us longer than a month if your request is
              particularly complex or you have made several requests. In this
              case, we will notify you and keep you updated.
            </p>
          </section>

          <section id="section4">
            <h1 className={styles.h1}>Your legal rights</h1>
            <h2 className={styles.h2}>Your Rights</h2>
            <p className={styles.paragraph}>You have the right to:</p>
            <ul className={styles.list}>
              <li>
                <b>Request access</b> to your personal data (commonly known as a
                “data subject access request”). This enables you to receive a
                copy of the personal data we hold about you and to check that we
                are lawfully processing it.
              </li>
              <li>
                <b>Request correction</b> of the personal data that we hold
                about you. This enables you to have any incomplete or inaccurate
                data we hold about you corrected, though we may need to verify
                the accuracy of the new data you provide to us.
              </li>
              <li>
                <b>Request erasure</b> of your personal data. This enables you
                to ask us to delete or remove personal data where there is no
                good reason for us continuing to process it. You also have the
                right to ask us to delete or remove your personal data where you
                have successfully exercised your right to object to processing
                (see below), where we may have processed your information
                unlawfully or where we are required to erase your personal data
                to comply with local law. Note, however, that we may not always
                be able to comply with your request of erasure for specific
                legal reasons which will be notified to you, if applicable, at
                the time of your request.
              </li>
              <li>
                <b>Object to processing</b> of your personal data where we are
                relying on a legitimate interest (or those of a third party) and
                there is something about your situation which makes you want to
                object to processing on this ground as you feel it impacts on
                your fundamental rights and freedoms. You also have the right to
                object where we are processing your personal data for direct
                marketing purposes. In some cases, we may demonstrate that we
                have compelling legitimate grounds to process your information
                which override your rights and freedoms.
              </li>
              <li>
                <b>Request restriction of processing</b> of your personal data.
                This enables you to ask us to suspend the processing of your
                personal data in the following scenarios:
                <ul className={styles.list}>
                  <li>if you want us to establish the data’s accuracy</li>
                  <li>
                    where our use of the data is unlawful, but you do not want
                    us to erase it
                  </li>
                  <li>
                    where you need us to hold the data even if we no longer
                    require it as you need it to establish, exercise or defend
                    legal claims{' '}
                  </li>
                  <li>
                    or you have objected to our use of your data, but we need to
                    verify whether we have overriding legitimate grounds to use
                    it.
                  </li>
                </ul>
              </li>
              <li>
                <b>Request the transfer</b> of your personal data to you or to a
                third party. We will provide to you, or a third party you have
                chosen, your personal data in a structured, commonly used,
                machine-readable format.
              </li>
              <li>
                Note that this right only applies to automated information which
                you initially provided consent for us to use or where we used
                the information to perform a contract with you.
              </li>
              <li>
                <b>Withdraw consent at any time</b> where we are relying on
                consent to process your personal data. However, this will not
                affect the lawfulness of any processing carried out before you
                withdraw your consent. If you withdraw your consent, we may not
                be able to provide certain products or services to you. We will
                advise you if this is the case at the time you withdraw your
                consent.
              </li>
            </ul>
          </section>

          <section id="section5">
            <h1 className={styles.h1}>Lawful basis</h1>
            <h2 className={styles.h2}>Performance of Contract</h2>
            <p className={styles.paragraph}>
              Performance of Contract means processing your data where it is
              necessary for the performance of a contract to which you are a
              party or to take steps at your request before entering in to such
              a contract.
            </p>
            <h2 className={styles.h2}>Legitimate Interest</h2>
            <p className={styles.paragraph}>
              Legitimate Interest means the interest of our business in
              conducting and managing our business to enable us to give you the
              best service/product and the best and most secure experience. We
              make sure we consider and balance any potential impact on you
              (both positive and negative) and your rights before we process
              your personal data for our legitimate interests. We do not use
              your personal data for activities where our interests are
              overridden by the impact on you (unless we have your consent or
              are otherwise required or permitted to by law). You can obtain
              further information about how we assess our legitimate interests
              against any potential impact on you in respect of specific
              activities by emailing{' '}
              <a href="mailto:dataprotection@cloudclevr.com">
                dataprotection@cloudclevr.com
              </a>
            </p>
            <h2 className={styles.h2}>Children’s Privacy</h2>
            <p className={styles.paragraph}>
              Clevr360 does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 13. If We become aware that We have collected
              Personal Data from anyone under the age of 13 without verification
              of parental consent, we take steps to remove that information from
              Our servers.
            </p>
            <h2 className={styles.h2}>
              Comply with a legal or regulatory obligation.
            </h2>
            <p className={styles.paragraph}>
              Comply with a legal or regulatory obligation means processing your
              personal data where it is necessary for compliance with a legal or
              regulatory obligation that we are subject to.
            </p>
          </section>

          <section id="section6">
            <h1 className={styles.h1}>Third parties</h1>
            <h2 className={styles.h2}>Internal Third Parties</h2>
            <p className={styles.paragraph}>
              Internal Third Parties means the companies listed within
              CloudClevr and members of their respective group of companies who
              are not listed as the Data Controller for the Clevr360 website.
            </p>
            <h2 className={styles.h2}>External Third Parties </h2>
            <p className={styles.paragraph}>
              External Third Parties means companies, individuals, or other
              organisations that the Data Controllers may need to process your
              personal data acting as either a data processor, or joint data
              controller. The categories are as follows:
            </p>
            <ul className={styles.list}>
              <li>
                Service providers acting as data processors and based within the
                European Economic Area (EEA) who may host the Clevr360 website
                and provide IT and system administration services, and process
                online payments on behalf of the CloudClevr.
              </li>
              <li>
                Development teams acting as data processors and based within or
                outside the EEA who may provide IT and system support and
                administration services.
              </li>
              <li>
                Professional advisers acting as processors or joint controllers
                including lawyers, bankers, auditors, and insurers based within
                the EEA who provide consultancy, banking, legal, insurance and
                accounting services.
              </li>
              <li>
                HM Revenue & Customs, regulators and other authorities acting as
                processors or joint controllers based in the United Kingdom and
                Ireland who require reporting of processing activities in
                certain circumstances.
              </li>
              <li>
                Market research and customer insight organisations, acting as
                joint data controllers, who are based within the EEA.
              </li>
            </ul>
          </section>

          <section id="section7">
            <h1 className={styles.h1}>What personal data we collect</h1>
            <p className={styles.paragraph}>
              We may collect, use, store and transfer various kinds of personal
              data about you which we have grouped together follows:
            </p>
            <ul className={styles.list}>
              <li>
                <b>Identity Data (ID)</b> includes your first name, last name,
                username, email address, contact numbers, and locations.
              </li>
              <li>
                <b>Profile Data (PD)</b> includes job title, organisation name,
                organisation address, information relating to which sector your
                organisation is in.
              </li>
              <li>
                <b>Financial Data (FD)</b> includes bank account and payment
                card details should you chose to pay for any Clevr360 products
                or services.
              </li>
              <li>
                <b>Transaction Data (TrD)</b> means details about payments to
                and from you and other details of products and services you have
                purchased from us.
              </li>
              <li>
                <b>Technical Data (TD)</b> may include internet protocol (IP)
                address, your login data, browser type and version, time zone
                setting and location, browser plug-in types and versions,
                operating system and platform and other technology on the
                devices you use to access CloudClevr websites, how often and for
                how long you access the Clevr360 website.
              </li>
              <li>
                <b>Marketing and Communications Data (MCD)</b> includes your
                preferences in receiving marketing from us and our third parties
                and your communication preferences.
              </li>
            </ul>
            <p className={styles.paragraph}>
              We also collect, use, and share within the Clevr360 aggregated
              data such as statistical or demographic data for the Clevr360’s
              legitimate business purposes. Aggregated data may be derived from
              your personal data but is not considered personal data in law as
              this data does not directly or indirectly reveal your identity.
              For example, we may aggregate your Usage Data to calculate the
              percentage of users accessing a specific website feature.
            </p>
            <p className={styles.paragraph}>
              However, if we combine or connect Aggregated Data with your
              personal data so that it can directly or indirectly identify you,
              we treat the combined data as personal data which will be used in
              accordance with this privacy policy.
            </p>
          </section>

          <section id="section8" className="mb-[600px]">
            <h1 className={styles.h1}>Version</h1>
            <p className={styles.paragraph}>
              This policy was last updated on the 19th of July 2023. We may
              update it at any time to improve our customer experience or to
              comply with changes in the law.
            </p>
          </section>
        </div>
      </div>
    </OverlayPage>
  );
};
