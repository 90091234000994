import { formatNumberWithSuffix } from '@utils/index';

export const useCreateCellValue = (
  property: string,
  data: any,
  index: number,
  isMobile: boolean
) => {
  const value = data[index][property];
  isMobile;
  switch (property) {
    case 'teamName':
      return (
        <>
          <span>{value || 'No data available'}</span>
        </>
      );
    case 'memberCount':
      return (
        <>
          <span>{formatNumberWithSuffix(value) || '0'}</span>
        </>
      );
    case 'messageByUserCount':
      return (
        <>
          <span>{Math.round(value) || 0}</span>
        </>
      );
    case 'meetingByUserCount':
      return <>{Math.round(value) || 0}</>;
  }
};
