import { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Checkbox } from '../Checkbox';
import cn from 'classnames';

export type Item = {
  id: string;
  name: string;
  parentId: string;
  subGroups: any[];
};

type CheckboxState = {
  [index: string]: boolean;
};

type TreeViewCheckboxProps = {
  data?: Item[];
  onSelectionChange?: (selected: CheckboxState, item: Item) => void;
  selectedCheckboxes: string[];
};

export const TreeViewCheckbox: React.FC<TreeViewCheckboxProps> = ({
  data = [],
  onSelectionChange,
  selectedCheckboxes,
}) => {
  const [cbSelected, setCbSelected] = useState<CheckboxState>({});

  useEffect(() => {
    const initialState: CheckboxState = {};

    const nested = (element: any) => {
      element.subGroups.forEach((subElement: any) => {
        initialState[subElement.id] =
          selectedCheckboxes.includes(subElement.id) || false;
        if (subElement.subGroups && subElement.subGroups.length > 0) {
          nested(subElement);
        }
      });
    };

    data.forEach((element: any) => {
      initialState[element.id] =
        selectedCheckboxes.includes(element.id) || false;
      if (element.subGroups && element.subGroups.length > 0) {
        nested(element);
      }
    });

    setCbSelected(initialState);
  }, [selectedCheckboxes]);

  const updateInitialState = (item: Item) => {
    setCbSelected((prev) => {
      const updatedState = {
        ...prev,
        [item.id]: !prev[item.id],
      };

      onSelectionChange?.(updatedState, item);
      return updatedState;
    });
  };

  const NestedSubGroups = ({
    subGroups,
    element,
  }: {
    subGroups: any;
    element: any;
  }) => {
    return (
      <ul className="relative">
        {subGroups.map((subElement: any) => (
          <li
            key={subElement.name}
            className={cn(
              styles.subGroupItem,
              element?.parentId
                ? styles.subGroupWithParentId
                : styles.subGroupWithoutParentId
            )}
          >
            <Checkbox
              showFadeOut={true}
              className="relative z-[20] w-full"
              labelClassName="whitespace-nowrap w-full overflow-hidden"
              labelTitle={subElement.name}
              name={subElement.name}
              label={subElement.name}
              checked={cbSelected[subElement.id] || false}
              onChange={() => updateInitialState(subElement)}
            />

            {subElement.subGroups && subElement.subGroups.length > 0 && (
              <NestedSubGroups
                element={subElement}
                subGroups={subElement.subGroups}
              />
            )}
          </li>
        ))}
      </ul>
    );
  };

  const elementsToRender = data.map((element) => (
    <div key={element.name} className="relative pb-4">
      <Checkbox
        showFadeOut={true}
        className="relative z-[20] w-full"
        labelClassName="whitespace-nowrap w-full overflow-hidden"
        labelTitle={element.name}
        name={element.name}
        label={element.name}
        checked={cbSelected[element.id] || false}
        onChange={() => updateInitialState(element)}
      />

      {element.subGroups && element.subGroups.length > 0 && (
        <NestedSubGroups element={element} subGroups={element.subGroups} />
      )}
    </div>
  ));

  return <div>{elementsToRender}</div>;
};
