import { create } from 'zustand';

type SelectedItemsStore = {
  defaultPreselectedItems: string[];
  newlySelectedItems: string[];
  newlyUnselectedItems: string[];
  allSelectedItems: string[];
  totalItemsPerPage: Record<number, number>;
  selectedCountPerPage: Record<number, number>;
  expandedRows: any[];
  tableDataexpandedRows: React.ReactElement | null;
  expandedRowId: number;
  isVideoFormat: boolean;
  startsAt: Date | null;
  endsAt: Date | null;
  shouldApplyRange: boolean;
  downloadType: string | null;
  isLoadingExportData: boolean;
};

type SelectedItemsStoreActions = {
  setNewlySelectedItems: (items: string[]) => void;
  setNewlyUnselectedItems: (items: string[]) => void;
  setAllSelectedItems: (items: string[]) => void;
  removeAllSelectedItems: (items: string[]) => void;
  setDefaultPreselectedItems: (items: string[]) => void;
  reset: () => void;
  resetAllSelectedItems: () => void;
  removeNewlySelectedItems: (items: string[]) => void;
  removeNewlyUnselectedItems: (items: string[]) => void;
  setTotalItemsPerPage: (currentPage: number, totalItems: number) => void;
  setSelectedCountPerPage: (currentPage: number, selectedNum: number) => void;
  setExpandedRows: (items: any[]) => void;
  setExpandedRowId: (item: number, isVideoFormat: boolean) => void;
  setStartsAt: (value: Date | null) => void;
  setEndsAt: (value: Date | null) => void;
  setShouldApplyRange: (value: boolean) => void;
  setDownloadType: (item: string | null) => void;
  setIsLoadingExportData: (item: boolean) => void;
};

const useTableStore = create<SelectedItemsStore & SelectedItemsStoreActions>(
  (set) => ({
    startsAt: null,
    endsAt: null,
    expandedRowId: -1,
    isVideoFormat: false,
    shouldApplyRange: false,
    defaultPreselectedItems: [],
    tableDataexpandedRows: null,
    expandedRows: [],
    newlySelectedItems: [],
    newlyUnselectedItems: [],
    allSelectedItems: [],
    totalItemsPerPage: {},
    selectedCountPerPage: {},
    downloadType: null,
    isLoadingExportData: false,
    setIsLoadingExportData: (value) =>
      set(() => ({
        isLoadingExportData: value,
      })),
    setDownloadType: (value) =>
      set(() => ({
        downloadType: value,
      })),
    setNewlySelectedItems: (items) =>
      set((state) => ({
        newlySelectedItems: [
          ...state.newlySelectedItems,
          ...items.filter((i) => !state.newlySelectedItems.includes(i)),
        ],
      })),
    setNewlyUnselectedItems: (items) =>
      set((state) => ({
        newlyUnselectedItems: [
          ...state.newlyUnselectedItems,
          ...items.filter((i) => !state.newlyUnselectedItems.includes(i)),
        ],
      })),
    setAllSelectedItems: (items) =>
      set((state) => ({
        allSelectedItems: [
          ...state.allSelectedItems,
          ...items.filter((i) => !state.allSelectedItems.includes(i)),
        ],
      })),
    setTotalItemsPerPage: (currentPage, totalItems) =>
      set((state) => ({
        totalItemsPerPage: {
          ...state.totalItemsPerPage,
          [currentPage]: totalItems,
        },
      })),
    setSelectedCountPerPage: (currentPage, selectedNum) =>
      set((state) => ({
        selectedCountPerPage: {
          ...state.selectedCountPerPage,
          [currentPage]: selectedNum,
        },
      })),
    removeAllSelectedItems: (items) =>
      set((state) => ({
        allSelectedItems: [
          ...state.allSelectedItems.filter((i) => !items.includes(i)),
        ],
      })),
    removeNewlyUnselectedItems: (items) =>
      set((state) => ({
        newlyUnselectedItems: [
          ...state.newlyUnselectedItems.filter((i) => !items.includes(i)),
        ],
      })),
    removeNewlySelectedItems: (items) =>
      set((state) => ({
        newlySelectedItems: [
          ...state.newlySelectedItems.filter((i) => !items.includes(i)),
        ],
      })),
    reset: () =>
      set({
        defaultPreselectedItems: [],
        newlySelectedItems: [],
        allSelectedItems: [],
        newlyUnselectedItems: [],
        totalItemsPerPage: {},
        selectedCountPerPage: {},
      }),
    setEndsAt: (value) => set({ endsAt: value }),
    setStartsAt: (value) => set({ startsAt: value }),
    resetAllSelectedItems: () => set({ allSelectedItems: [] }),
    setShouldApplyRange: (value) => set({ shouldApplyRange: value }),
    setDefaultPreselectedItems: (items) =>
      set(() => ({
        defaultPreselectedItems: [...items],
      })),
    setExpandedRows: (items) =>
      set(() => ({
        expandedRows: [...items],
      })),
    setExpandedRowId: (item, isVideoFormat) => {
      set((state) => ({
        expandedRowId: state.expandedRowId === item ? -1 : item,
        isVideoFormat,
      }));
    },
  })
);

export default useTableStore;
