import React from 'react';

import { OverlayPage } from '@components/partials';
import { useIsMobile } from '@hooks/utils';
import Tab, { Tab as TabType } from '../../../../components/ui/Tab';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';

const CallsMadeLayout: React.FC = () => {
  const isMobile = useIsMobile();
  const redirectPath = useNavSourceRedirect(
    '/insights-and-analytics/productivity'
  );

  const tabs: TabType[] = [
    {
      path: '/insights-and-analytics/productivity/activity/people',
      label: 'Accounts',
    },
    {
      path: '/insights-and-analytics/productivity/activity/groups',
      label: 'Groups',
    },
    {
      path: '/insights-and-analytics/productivity/activity/locations',
      label: 'Locations',
    },
  ];

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Activity`}
    >
      <div className="mt-[-37px]">
        <Tab tabs={tabs} className={isMobile ? 'mb-[100px]' : 'mb-[70px]'} />
      </div>
    </OverlayPage>
  );
};

export default CallsMadeLayout;
