import { API_ENDPOINTS } from '@api/ApiEndpoints';
import { UseQueryResult, useQuery } from 'react-query';
import { useHttpClient } from '@hooks/utils/httpConfig';
import {
    OutdatedOSQueryOptions, OutdatedOSSActivitynapshotResponse,
    OutdatedUsedOSSnapshotResponse, OutdatedOSUsersSnapshotResponse
} from './types';
import { getSearchQueryParams } from '@utils/index';

export function useOutdatedOSActivitySnapshotQuery({
    days, OSType, accountType, groupList, locationList
}: OutdatedOSQueryOptions = {}): UseQueryResult<OutdatedOSSActivitynapshotResponse> {
    const httpClient = useHttpClient();
    const fetchSnapshot = async (): Promise<OutdatedOSSActivitynapshotResponse> => {
        const params = getSearchQueryParams({
            days,
            OSType,
            accountType,
            groupList,
            locationList
        });

        const endpoint = `${API_ENDPOINTS.OUTDATED_OS_ACTIVITY_SNAPSHOT
            }?${params.toString()}`;

        return httpClient.get<OutdatedOSSActivitynapshotResponse>(endpoint)
    };
    return useQuery(['outdatedOSActivity', days, OSType, accountType, groupList, locationList], fetchSnapshot);
}

export function useOutdatedOSUsedSnapshotQuery({ days, OSType, accountType, groupList, locationList }: OutdatedOSQueryOptions = {}):
    UseQueryResult<OutdatedUsedOSSnapshotResponse> {
    const httpClient = useHttpClient();
    const fetchSnapshot = async (): Promise<OutdatedUsedOSSnapshotResponse> => {
        const params = getSearchQueryParams({
            days,
            OSType,
            accountType,
            groupList,
            locationList
        });

        const endpoint = `${API_ENDPOINTS.OUTDATED_OS_SNAPSHOT
            }?${params.toString()}`;

        return httpClient.get<OutdatedUsedOSSnapshotResponse>(endpoint)
    };
    return useQuery(['outdatedUsedOS', { days, OSType, accountType, groupList, locationList }], fetchSnapshot);
}


export function useOutdatedOSUserTypesSnapshotQuery({ days, OSType, accountType, groupList, locationList }: OutdatedOSQueryOptions = {}):
    UseQueryResult<OutdatedOSUsersSnapshotResponse> {
    const httpClient = useHttpClient();
    const fetchSnapshot = async (): Promise<OutdatedOSUsersSnapshotResponse> => {
        const params = getSearchQueryParams({
            days,
            OSType,
            accountType,
            groupList,
            locationList
        });

        const endpoint = `${API_ENDPOINTS.OUTDATED_OS_USER_TYPES_SNAPSHOT
            }?${params.toString()}`;

        return httpClient.get<OutdatedOSUsersSnapshotResponse>(endpoint)
    };
    return useQuery(['outdatedOSUserTypes', { days, OSType, accountType, groupList, locationList }], fetchSnapshot);
}



