import { Button, Input, Modal } from '@components/ui';
import { Variant } from '@components/ui/Toast';
import { useUpdateGroup } from '@hooks/groups';
import { GroupForm, GroupsResponse } from '@hooks/groups/types';
import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import useUIStore from '@store/uiStore';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';

export type CreateEditGroupModalProps = {
  group: GroupsResponse | undefined;
  groupsData: GroupsResponse[] | [];
  isModalOpen: boolean;
  handleEditGroupModalClose: () => void;
  handleSuccess: () => void;
};

export const CreateEditGroupModal: React.FC<CreateEditGroupModalProps> = ({
  group,
  groupsData,
  isModalOpen,
  handleEditGroupModalClose,
  handleSuccess,
}) => {
  const { addToast } = useUIStore();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const { setGroupData } = useUIStore();
  const updateGroup = useUpdateGroup(group?.id);
  const [groupNames, setGroupNames] = useState<string[]>([]);

  useEffect(() => {
    const names: string[] = [];
    const nested = (subGroup: GroupsResponse) => {
      subGroup.subGroups.forEach((sub) => {
        names.push(sub.name);
        if (sub.subGroups) {
          nested(sub);
        }
      });
    };

    groupsData.forEach((group) => {
      names.push(group.name);
      if (group.subGroups.length) {
        nested(group);
      }
    });

    setGroupNames(names);
  }, [groupsData]);

  const handleSubmit = async (
    formData: GroupForm,
    actions: FormikHelpers<{ name: string }>
  ) => {
    if (!group?.id) {
      setGroupData({ name: formData.name || undefined, parentId: group?.parentId || undefined })
      handleSuccess();
      actions.resetForm();
      return;
    }

    try {
      await updateGroup.mutateAsync(formData);
      addToast({
        variant: Variant.Success,
        message: 'We’ve updated your group’s name',
        closeable: true,
      });
      handleSuccess();
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: 'Failed to edit group',
        closeable: true,
      });
    }

    actions.setSubmitting(false);
    actions.resetForm();
  };

  const validate = (values: GroupForm) => {
    const errors: Partial<GroupForm> = {};
    const groupExists = groupNames.some(
      (g) => g.toLowerCase() === values.name?.toLowerCase().trim()
    );

    if (!values.name?.trim()) {
      errors.name = 'Group name is required';
    }

    if (values.name && values.name.length < 3) {
      errors.name = 'Group name must contain at least three characters.';
    }

    if (groupExists) {
      errors.name =
        'There’s already a group/subgroup with that name. Try a different name.';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{ name: group?.name || '' }}
      validate={validate}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions);
      }}
    >
      {({ errors, isSubmitting, isValid, dirty }) => (
        <Modal
          headerTxt={group?.id ? 'Edit group name' : 'Give your group a name'}
          modalClassName={'h-full mt-0'}
          isOpen={isModalOpen}
          size={isTablet || isMobile ? 'small' : 'medium'}
          position={isMobile ? 'bottom' : 'default'}
          showCloseBtn={true}
          onClose={handleEditGroupModalClose}
          contentClassName="flex !flex-column !items-start !justify-start bg-white"
        >
          <div className="w-full">
            <Form>
              <Field
                className="mb-5"
                label="Group name"
                name="name"
                type="text"
                maxLength="255"
                inputClassName="!text-interfaceColor-100"
                as={Input}
                error={errors.name}
              />
              <p className="mb-5 text-14 text-interfaceColor-80">
                We’ll use this to refer to your group in insights, analytics and
                reports, so make sure it’s easy to understand.
              </p>
              <div className="mt-5 flex flex-col gap-2 lg:flex-row">
                <Button
                  type="submit"
                  variant="primary"
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  {group?.id ? 'Save changes' : 'Next: group visibility'}
                </Button>
                <Button
                  type="button"
                  variant="outlineLight"
                  onClick={handleEditGroupModalClose}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      )}
    </Formik>
  );
};
