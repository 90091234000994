import { Button } from '@components/ui/Button';
import { ArrowRightIcon } from '@components/ui/Icons';
import { formatDate } from '@utils/index';

import { useNavigate } from 'react-router';

type NavigateButtonProps = {
  data: any;
  index: number;
};

const NavigateButton: React.FC<NavigateButtonProps> = ({ data, index }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/reports/${data[index]['id']}/preview`, {
      state: {
        id: data[index]['id'],
        name: data[index]['name'],
      },
    });
  };

  return (
    <Button
      onClick={handleClick}
      size="small"
      variant="outlineLight"
      className={'mt-3 w-full md:mt-0 md:w-auto'}
    >
      View report{' '}
      <ArrowRightIcon color="text-interfaceColor-100 ml-2 mt-[3px]" size="3" />
    </Button>
  );
};

export default NavigateButton;

export const createCellValue = (property: string, data: any, index: number) => {
  const value = data[index][property];
  switch (property) {
    case 'actions':
      return <NavigateButton data={data} index={index} />;
    case 'name':
      return (
        <>
          <span>{value}</span>
          {!data[index]['isRead'] && (
            <span className="ml-2 rounded-[20px] border border-successColor-100 bg-successColor-10 px-[8px] py-[2px] text-14 text-successColor-100">
              Unread
            </span>
          )}
        </>
      );
    case 'date':
      return (
        <>
          <span>{formatDate(value)}</span>
        </>
      );
  }
};
