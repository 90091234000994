import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useMeetingRoomVideoSnapshotQuery,
  useMeetingRoomsQuery,
} from '@hooks/adoption';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { convertMinutesToHours } from '@utils/index';

import { useEffect, useState } from 'react';

export type MeetingRoomVideoProps = TilesProps & {
  type?: DashboardTiles;
};

type Params = {
  days: string;
  roomId: string;
};

export const MeetingRoomVideo: React.FC<MeetingRoomVideoProps> = ({
  params,
  id,
  classNames,
  isSnapshot,
  type,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { addMenuItem } = useAddDashboardTile(
    type || DashboardTiles.MeetingRoomVideo,
    parameters
  );
  const isOnDashboardPage = !!id;
  // data hook
  const { data: meetingRoomVideoData, isLoading: meetingRoomVideoDataLoading } =
    useMeetingRoomVideoSnapshotQuery(parameters);
  const { data: meetingRoomsData } = useMeetingRoomsQuery();

  useEffect(() => {
    if (type === DashboardTiles.MeetingRoomVideoSnapshot) {
      const updatedParams = JSON.parse(params);

      setParameters({
        days: updatedParams.days,
        roomId: parameters.roomId,
      });
    }
  }, [params]);

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All rooms',
      value: 'allrooms',
      action: () => handleSortDropdownChange('allrooms'),
    },
  ];

  const handleCompareDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });
    }
  };

  const compareMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleCompareDropdown('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleCompareDropdown('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleCompareDropdown('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleCompareDropdown('90'),
    },
  ];

  const handleSortDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      roomId: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });
    }
  };

  if (meetingRoomsData) {
    meetingRoomsData.rooms.forEach((room, index) => {
      sortMenuItems.push({
        id: index + 2,
        label: room.name,
        value: room.id,
        action: () => handleSortDropdownChange(room.id),
      });
    });
  }

  return (
    <DoughnutChartTile
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      sortData={sortMenuItems}
      compareData={!isSnapshot ? compareMenuItems : undefined}
      initialSelectedItem={sortMenuItems.find(
        (item) => item.value === parameters.roomId
      )}
      initialCompareSelectedItem={
        !isSnapshot
          ? compareMenuItems.find((item) => item.value === parameters.days)
          : undefined
      }
      headerTitle={'Meeting room video'}
      dropdownMenuItemClasses={isOnDashboardPage ? '!w-[300px]' : '!w-[250px]'}
      isConsistentDropdownMenuItemClasses={true}
      isLoading={meetingRoomVideoDataLoading}
      data={
        meetingRoomVideoData
          ? [
              meetingRoomVideoData?.unusedVideoMinutes,
              meetingRoomVideoData?.usedVideoMinutes,
            ]
          : []
      }
      dataLabels={[
        `${
          meetingRoomVideoData && meetingRoomVideoData?.unusedVideoMinutes
            ? convertMinutesToHours(meetingRoomVideoData.unusedVideoMinutes)
            : 0
        } where video wasn’t used`,
        `${
          meetingRoomVideoData && meetingRoomVideoData?.usedVideoMinutes
            ? convertMinutesToHours(meetingRoomVideoData.usedVideoMinutes)
            : 0
        } where video was used`,
      ]}
      showBracketLabelValue={false}
      buttonLabel={!isSnapshot ? 'Meeting rooms data' : ''}
      linkPath={ANALYTICS_NAVIGATION_MAP['meetingsRoom']}
      handleButtonClick={!isSnapshot ? handleButtonClick() : undefined}
    />
  );
};
