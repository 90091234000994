import React from 'react';
import loader from '@assets/gif/loader.gif';
import styles from './styles.module.css';

export const Loader: React.FC = () => {
  return (
    <div className={styles.loader}>
      <img
        className="h-14 w-14 mt-5"
        src={loader}
        alt="Loading..."
        data-testid="loader-image"
      />
      <span className="text-interfaceColor-90" data-testid="loader-text">
        Loading
      </span>
    </div>
  );
};
