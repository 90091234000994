import {
  ArrowDownIcon,
  ArrowRightIcon,
  ArrowUpIcon,
} from '@components/ui/Icons';
import { useIsDesktop } from '@hooks/utils';
import cn from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-scroll';
import styles from './styles.module.css';

export type SectionNavItem = {
  id?: string;
  label?: string;
  children?: SectionNavItem[];
};

type SectionNavProps = {
  items: SectionNavItem[];
  headerLabel?: string;
  animationDuration?: number;
  scrollOffset?: number;
};

export const SectionNav: React.FC<SectionNavProps> = ({
  items,
  headerLabel = 'Jump to section',
  animationDuration = 300,
  scrollOffset = -140,
}) => {
  const isDesktop = useIsDesktop();
  const [isOpen, setIsOpen] = useState(isDesktop ? true : false);

  const toggleOpen = () => {
    !isDesktop ? setIsOpen(!isOpen) : null;
  };

  const closeSectionNav = () => {
    !isDesktop ? setIsOpen(false) : null;
  };

  return (
    <div className={styles.wrapper}>
      <ul className={styles.list}>
        <li
          onClick={toggleOpen}
          className={cn(
            styles.headerListItem,
            !isDesktop ? 'cursor-pointer' : ''
          )}
        >
          {headerLabel}
          {!isDesktop && (
            <div>
              {!isOpen && <ArrowDownIcon />}
              {isOpen && <ArrowUpIcon />}
            </div>
          )}
        </li>
        {isOpen &&
          items.map((item) => (
            <li
              className={`${styles.listItem} ${
                item.children?.length ? 'flex-col' : 'flex-row'
              }`}
              key={item.id}
            >
              <Link
                activeClass="sectionNavActive"
                to={`section${item.id}`}
                spy={true}
                smooth={true}
                offset={scrollOffset}
                duration={animationDuration}
                className={styles.linkItem}
                onClick={closeSectionNav}
              >
                {item.label}
                <ArrowRightIcon
                  color={'text-interfaceColor-80'}
                  size="3"
                  classNames="ml-4"
                />
              </Link>
              {item.children?.length && (
                <ul className="w-full py-2">
                  {item.children?.map((child) => {
                    return (
                      <li className={styles.listChildItem} key={child.id}>
                        <Link
                          activeClass="sectionNavActive"
                          to={`section-${item.id}-${child.id}`}
                          spy={true}
                          smooth={true}
                          offset={scrollOffset}
                          duration={animationDuration}
                          className={styles.linkItem}
                          onClick={closeSectionNav}
                        >
                          {child.label}
                        </Link>
                        <ArrowRightIcon
                          color={'text-interfaceColor-80'}
                          size="3"
                          classNames="ml-4"
                        />
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};
