import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import {
    useAddDashboardTile,
    useHandleTileBtnNavigate,
    useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useCallForwardingDataSnapshotQuery } from '@hooks/security';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';


export const CallForwardingDestinations: React.FC<TilesProps> = ({
    id,
    classNames,
    isSnapshot
}) => {
    const { removeMenuItem } = useRemoveDashboardTile(id);
    const { handleButtonClick } = useHandleTileBtnNavigate(id);
    const { addMenuItem } = useAddDashboardTile(
        DashboardTiles.CallForwardingDestinations,
        {}
    );
    const isOnDashboardPage = !!id;

    // data hook
    const { data, isLoading } = useCallForwardingDataSnapshotQuery();

    return (
        <NumberTile
            dropdownMenuItemClasses={'!w-[300px]'}
            sortData={[]}
            classNames={
                classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
            }
            contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
            headerTitle={'Call forwarding rules'}
            isLoading={isLoading}
            data={
                data ? Object.values(data) : [0]
            }
            buttonLabel={!isSnapshot ? 'Call forwarding data' : ''}
            linkPath={ANALYTICS_NAVIGATION_MAP['callForwardingDestinations']}
            handleButtonClick={!isSnapshot ? handleButtonClick() : undefined}
            bottomLabel="Call forwarding rules configured"
            percentageLabel=""
        />
    );
};
