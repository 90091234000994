import {
  DEFAULT_PAGE_SIZE,
  DashboardTiles,
  LAST_DAYS_SORT_BY,
  MS_TEAMS_HEALTH_SORT_BY,
  MS_TEAMS_TABLE_COLUMNS,
} from '@common/constants';
import { OrderBy, VendorType } from '@common/types';
import { OverlayPage, TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { DashboardIcon } from '@components/ui';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useMeetingsHeldSnapshotQuery,
  useMessagesSentSnapshotQuery,
  useMsTeamsHealthPreviewQuery,
  useMsTeamsHealthSnapshotQuery,
} from '@hooks/adoption';
import { useDashboardTileSaveMutation } from '@hooks/dashboard';
import { PeopleSortBy } from '@hooks/people/types';
import { useIsDesktop, useIsMobile } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { formatDate, formatNumberWithSuffix, useTableData } from '@utils/index';
import React, { useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import styles from './styles.module.css';
import { createCellValue } from './utils';
import { Dropdown } from '@components/ui/Dropdown';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { RecommendationCard } from '@pages/Dashboard/tiles/helpers/RecommendationCard';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';

const pageSize = DEFAULT_PAGE_SIZE;
const floatingSortDropdownData = LAST_DAYS_SORT_BY;

const MsTeamsHealthPage: React.FC = () => {
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const [msTeamsServiceType, setMsTeamsServiceType] = useState('Membership');
  const [days, setDays] = useState('30');
  const [daysSelectedLabel, setDaysSelectedLabel] = useState(
    floatingSortDropdownData[1].label || ''
  );
  const [daysSelectedIndex, setDaysSelectedIndex] = useState(1);
  const [isDaysDropdownOpen, setIsDaysDropdownOpen] = useState(false);
  const redirectPath = useNavSourceRedirect('/insights-and-analytics/adoption');
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
    filter: withDefault(StringParam, ''),
  });

  const FILTERS = ['NoMembers', 'NoOwners'];
  const transformedFilterData: FilterData[] = [];

  transformedFilterData.push({
    label: 'Poor health indicators',
    name: 'collaboration',
    options: [
      { label: 'No members', value: 'NoMembers' },
      {
        label: 'No owners',
        value: 'NoOwners',
      },
    ],
  });

  // doughnut
  const { data: msTeamsHealthData, isLoading: isMsHealthLoading } =
    useMsTeamsHealthSnapshotQuery({
      collaboration: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => FILTERS.includes(filter))
        : [],
      dataType: msTeamsServiceType.toLowerCase(),
      days,
    });

  // table
  const query = useMsTeamsHealthPreviewQuery({
    days,
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    msTeamsHealthCollaboration: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => FILTERS.includes(filter))
      : [],
  });
  const getTableData = useTableData(
    query,
    MS_TEAMS_TABLE_COLUMNS,
    createCellValue
  );

  // number tile
  const {
    data: messagesSentDataSnapshot,
    isLoading: isMessagesSentSnapshotLoading,
  } = useMessagesSentSnapshotQuery({
    days,
    collaboration: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => FILTERS.includes(filter))
      : [],
  });

  // number tile
  const {
    data: meetingsHeldDataSnapshot,
    isLoading: isMeetingsHeldSnapshotLoading,
  } = useMeetingsHeldSnapshotQuery({
    days,
    collaboration: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => FILTERS.includes(filter))
      : [],
  });

  const dashboardSaveMutation = useDashboardTileSaveMutation();

  const handleItemAction = (type: DashboardTiles) => () => {
    const getParametersData = () => {
      switch (type) {
        case DashboardTiles.TeamsHealth:
          return {
            collaboration: queryParams.filter
              ? queryParams.filter
                  .split(',')
                  .filter((filter) => FILTERS.includes(filter))
              : [],
            dataType: msTeamsServiceType.toLowerCase(),
            days,
          };
        default:
          return {};
      }
    };

    const parametersData = getParametersData();

    dashboardSaveMutation.mutate({
      parameters: JSON.stringify(parametersData),
      tileType: type,
    });
  };

  const createMenuItems = (tileType: DashboardTiles): MenuItem[] => [
    {
      id: '0',
      label: 'Add to Dashboard',
      icon: <DashboardIcon classNames="mr-1" />,
      value: 'addToDashboard',
      action: handleItemAction(tileType),
    },
  ];

  const msTeamsHealthSortMenuItems: MenuItem[] = [
    {
      id: '2',
      label: 'Membership',
      value: 'membership',
      action: () => handleMsTeamsHealthDropdown('membership'),
    },
    {
      id: '3',
      label: 'Owner',
      value: 'owner',
      action: () => handleMsTeamsHealthDropdown('owner'),
    },
  ];

  const handleMsTeamsHealthDropdown = (value: string) => {
    setMsTeamsServiceType(value as VendorType);
  };

  const handleIsDaysDropdownOpen = () => {
    setIsDaysDropdownOpen(!isDaysDropdownOpen);
  };

  const handleDropdownDaysOnChange = (value: string[], index: number) => {
    setDays(value[0]);
    setDaysSelectedIndex(index);
    setDaysSelectedLabel(floatingSortDropdownData[index].label);
  };

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.MS_TEAMS_HEALTH}/export`,
    params: {
      days,
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      msTeamsHealthCollaboration: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => FILTERS.includes(filter))
        : [],
    },
    filename: `Clevr360_MS_Teams_health_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`MS Teams health`}
    >
      <>
        {!isMobile && (
          <div className="absolute left-[40px] top-[113px]">
            <Dropdown>
              <Dropdown.TextHeader
                classNames="!bg-interfaceColor-5 !border !border-interfaceColor-100 rounded-full"
                label={daysSelectedLabel}
                handleOpen={handleIsDaysDropdownOpen}
              ></Dropdown.TextHeader>
              <Dropdown.List
                className="!z-[999] text-16"
                open={isDaysDropdownOpen}
                align="left"
              >
                {floatingSortDropdownData.map((item, index) => {
                  return (
                    <Dropdown.DefaultItem
                      key={index}
                      item={item}
                      currentIndex={index}
                      selectedIndex={daysSelectedIndex}
                      onChange={() =>
                        handleDropdownDaysOnChange(item.value, index)
                      }
                    ></Dropdown.DefaultItem>
                  );
                })}
              </Dropdown.List>
            </Dropdown>
          </div>
        )}
        <div className={styles.pageGrid}>
          {isDesktop && (
            <>
              <DoughnutChartTile
                contextData={createMenuItems(DashboardTiles.TeamsHealth)}
                sortData={msTeamsHealthSortMenuItems}
                initialSelectedItem={msTeamsHealthSortMenuItems.find(
                  (item) => item.value === msTeamsServiceType
                )}
                headerTitle={'Teams health'}
                isLoading={isMsHealthLoading}
                data={
                  msTeamsHealthData
                    ? [
                        msTeamsHealthData?.teamsWith,
                        msTeamsHealthData?.teamsWithout,
                      ]
                    : []
                }
                dataLabels={[
                  `${
                    msTeamsHealthData && msTeamsHealthData?.teamsWithout
                      ? msTeamsHealthData.teamsWithout
                      : 0
                  } teams have no  ${
                    msTeamsServiceType === 'owner' ? 'owners' : 'members'
                  }`,
                  `${
                    msTeamsHealthData && msTeamsHealthData?.teamsWith
                      ? msTeamsHealthData.teamsWith
                      : 0
                  } teams have ${
                    msTeamsServiceType === 'owner' ? `owners` : 'members'
                  }`,
                ]}
                showBracketLabelValue={false}
              />

              <NumberTile
                sortData={[]}
                contextData={[]}
                headerTitle={'Total messages sent'}
                isLoading={isMessagesSentSnapshotLoading}
                data={
                  messagesSentDataSnapshot
                    ? [formatNumberWithSuffix(messagesSentDataSnapshot.value)]
                    : []
                }
              />

              <NumberTile
                sortData={[]}
                contextData={[]}
                headerTitle={'Total meetings held'}
                isLoading={isMeetingsHeldSnapshotLoading}
                data={
                  meetingsHeldDataSnapshot
                    ? [formatNumberWithSuffix(meetingsHeldDataSnapshot.value)]
                    : []
                }
              />

              <RecommendationCard insightDescription={'MS Teams health'} />
            </>
          )}
        </div>
        <div className="mt-2">
          <TableWrapper
            search={search}
            setSearch={setSearch}
            searchPlaceholder={'Search by team name or owner'}
            columns={MS_TEAMS_TABLE_COLUMNS}
            data={getTableData()}
            sortData={MS_TEAMS_HEALTH_SORT_BY}
            filterData={transformedFilterData}
            searchKey="search"
            query={query}
            refetchQuery={query.refetch}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setPageNumber={setPageNumber}
            isCollapsable={true}
            sliceColumns={1}
            floatingFilterButton={true}
            hasFilterBanner={false}
            isLicensePage={true}
            searchCountStatsLabel="teams"
            floatingFilterButtonLeft={!isMobile ? '190px' : ''}
            filterHeaderText="Filter teams and groups"
            isDownloadAvailable={true}
            handleDownload={handleDownloadClick}
          />
        </div>
      </>
    </OverlayPage>
  );
};

export default MsTeamsHealthPage;
