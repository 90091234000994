import { CellBottomPart } from '@components/ui/Table/utils';
import {
  calculateDateDifference,
  convertDecimalToHoursMinutes,
  convertMinutesToHours,
  formatDate,
  showLastUsedData,
} from '@utils/index';

export const createCellValue = (
  property: string,
  data: any,
  index: number,
  isMobile: boolean
) => {
  const value = data[index][property];
  isMobile;
  switch (property) {
    case 'name':
      return (
        <>
          <span>{value || 'No data available'}</span>
          {!isMobile && <CellBottomPart data={data[index]['emailAddress']} />}
        </>
      );
    case 'minutesBooked':
      return (
        <>
          <span>{convertMinutesToHours(value)}</span>
          {value > 0 && (
            <CellBottomPart
              data={`Average: ${convertDecimalToHoursMinutes(
                data[index]['hoursAverage']
              )}  / day`}
            />
          )}
        </>
      );
    case 'videoMinutesUsed':
      return (
        <>
          <span>{convertMinutesToHours(value)}</span>
          {value > 0 && (
            <CellBottomPart
              data={`${data[index]['videoPercentage'].toFixed(
                0
              )}% of booked hours`}
            />
          )}
        </>
      );
    case 'lastActivity':
      return (
        <>
          <span>{formatDate(value)}</span>
          {data[index]['lastActivity']
            ? showLastUsedData(
                calculateDateDifference(
                  formatDate(data[index]['lastActivity'])
                ),
                true
              )
            : ''}
        </>
      );
  }
};
