import {
  AddPeopleIcon,
  AddUserIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  BellIcon,
  CalendarIcon,
  CheckCircleIcon,
  CloseCircleIcon,
  CloseIcon,
  CloudClevrLogoIcon,
  DashboardIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  EyeIcon,
  FilterIcon,
  HamburgerIcon,
  HubSettingsUserIcon,
  LightInfoIcon,
  LightningIcon,
  LocationIcon,
  LocationPinIcon,
  LockIcon,
  LogoutIcon,
  MinusIcon,
  MoveIcon,
  PlusIcon,
  ResetPasswordIcon,
  SearchIcon,
  StructureIcon,
  SubgroupIcon,
  TimesCircleIcon,
  UnlockIcon,
  UserIcon,
  UsersMoveIcon,
  WarningCircleIcon,
  WarningIcon,
  ArrowLongRightIcon,
} from '@components/ui';
import React from 'react';

export const IconPreviewPage: React.FC = () => {
  return (
    <>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-3">
          <AddPeopleIcon color="text-primaryBrandColor-150" size="5" />
          <p>AddPeople</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <AddUserIcon color="text-primaryBrandColor-150" size="5" />
          <p>AddUser</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <ArrowDownIcon color="text-primaryBrandColor-150" size="5" />
          <p>ArrowDown</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <ArrowLeftIcon color="text-primaryBrandColor-150" size="5" />
          <p>ArrowLeft</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <ArrowRightIcon color="text-primaryBrandColor-150" size="5" />
          <p>ArrowRight</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <ArrowUpIcon color="text-primaryBrandColor-150" size="5" />
          <p>ArrowUp</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <BellIcon color="text-primaryBrandColor-150" size="5" />
          <p>Bell</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <CalendarIcon color="text-primaryBrandColor-150" size="5" />
          <p>Calendar</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <CheckCircleIcon color="text-primaryBrandColor-150" size="5" />
          <p>CheckCircle</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <CloseCircleIcon color="text-primaryBrandColor-150" size="5" />
          <p>CloseCircle</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <CloseIcon color="text-primaryBrandColor-150" size="5" />
          <p>Close</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <CloudClevrLogoIcon color="text-primaryBrandColor-150" size="5" />
          <p>CloudClevrLogo</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <DashboardIcon color="text-primaryBrandColor-150" size="5" />
          <p>Dashboard</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <DeleteIcon color="text-primaryBrandColor-150" size="5" />
          <p>Delete</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <DownloadIcon color="text-primaryBrandColor-150" size="5" />
          <p>Download</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <EditIcon color="text-primaryBrandColor-150" size="5" />
          <p>Edit</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <EyeIcon color="text-primaryBrandColor-150" size="5" />
          <p>Eye</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <FilterIcon color="text-primaryBrandColor-150" size="5" />
          <p>Filter</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <HamburgerIcon color="text-primaryBrandColor-150" size="5" />
          <p>Hamburger</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <HubSettingsUserIcon color="text-primaryBrandColor-150" size="5" />
          <p>HubSettingsUser</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <LightInfoIcon color="text-primaryBrandColor-150" size="5" />
          <p>LightInfo</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <LightningIcon color="text-primaryBrandColor-150" size="5" />
          <p>Lightning</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <LocationIcon color="text-primaryBrandColor-150" size="5" />
          <p>Location</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <LocationPinIcon color="text-primaryBrandColor-150" size="5" />
          <p>LocationPin</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <LockIcon color="text-primaryBrandColor-150" size="5" />
          <p>Lock</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <LogoutIcon color="text-primaryBrandColor-150" size="5" />
          <p>Logout</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <MinusIcon color="text-primaryBrandColor-150" size="5" />
          <p>Minus</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <MoveIcon color="text-primaryBrandColor-150" size="5" />
          <p>Move</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <PlusIcon color="text-primaryBrandColor-150" size="5" />
          <p>Plus</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <ResetPasswordIcon color="text-primaryBrandColor-150" size="5" />
          <p>ResetPassword</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <SearchIcon color="text-primaryBrandColor-150" size="5" />
          <p>Search</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <StructureIcon color="text-primaryBrandColor-150" size="5" />
          <p>Structure</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <SubgroupIcon color="text-primaryBrandColor-150" size="5" />
          <p>Subgroup</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <TimesCircleIcon color="text-primaryBrandColor-150" size="5" />
          <p>TimesCircle</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <UnlockIcon color="text-primaryBrandColor-150" size="6" />
          <p>Unlock</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <UserIcon color="text-primaryBrandColor-150" classNames="w-4 h-4" />
          <p>User</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <UsersMoveIcon color="text-primaryBrandColor-150" />
          <p>UsersMove</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <WarningCircleIcon color="text-primaryBrandColor-150" size="5" />
          <p>WarningCircle</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <WarningIcon color="text-primaryBrandColor-150" size="5" />
          <p>Warning</p>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <ArrowLongRightIcon color="text-primaryBrandColor-150" size="5" />
          <p>ArrowLongRight</p>
        </div>
      </div>
    </>
  );
};
