
export const createCellValue = (
    property: string,
    data: any,
    index: number,
) => {
    const value = data[index][property];

    switch (property) {
        case 'trunkName':
            return (
                <>
                    <span className="flex items-center">
                        {value}{' '}
                    </span>
                </>
            );
        case 'vendor':
            return (
                <>
                    <span>{value ?? ''}</span>
                </>
            );
        case 'callingPartyNumber':
            return (
                <>
                    <span>{value ?? ''}</span>
                </>
            );
        case 'type':
            return (
                <>
                    <span>{value ?? ''}</span>
                </>
            );
        case 'capacity':
            return (
                <>
                    <span>{value ?? '0'}</span>
                </>
            );
    }
};
