import * as React from 'react';
import { IconProps } from './types';

export const EmailIcon: React.FC<IconProps> = ({
  color = 'text-primaryBrandColor-100',
  classNames = '',
  size = 4,
}) => {
  return (
    <svg
      viewBox="0 0 16 16"
      className={`stroke-current w-${size} h-${size} ${color} ${classNames}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3214_64779)">
        <path
          d="M14.8569 6.85742V14.286C14.8569 14.5891 14.7364 14.8798 14.5221 15.0941C14.3078 15.3084 14.0171 15.4289 13.714 15.4289H2.28544C1.98233 15.4289 1.69164 15.3084 1.47731 15.0941C1.26299 14.8798 1.14258 14.5891 1.14258 14.286V6.85742"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.14258 7.72656L7.99972 12.0008L14.8569 7.72656"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.85742 5.71512V1.71512C2.85742 1.41201 2.97783 1.12133 3.19215 0.907C3.40648 0.692674 3.69717 0.572266 4.00028 0.572266H12.0003C12.3034 0.572266 12.5941 0.692674 12.8084 0.907C13.0227 1.12133 13.1431 1.41201 13.1431 1.71512V5.71512"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.28516 4H9.71373"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.28516 6.85742H9.71373"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3214_64779">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
