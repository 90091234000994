import { TilesProps } from '@common/types';
import { useOutdatedOSUsedSnapshotQuery } from '@hooks/outdatedData/OutdatedOperatingSystems';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useEffect, useState } from 'react';

type Params = {
  days: string;
  groupList?: string[];
  OSType?: string;
  accountType?: string;
  locationList?: string[];
};

export const OutdatedUsedOS: React.FC<TilesProps> = ({
  params,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));

  const {
    data: outdatedOSData,
    isLoading: isOutdatedOSDataLoading,
    refetch,
  } = useOutdatedOSUsedSnapshotQuery(parameters);

  useEffect(() => {
    setParameters(JSON.parse(params));
    return () => {
      refetch();
    };
  }, [params, refetch]);

  return (
    params && (
      <DoughnutChartTile
        dropdownMenuItemClasses={'!w-[300px]'}
        contextData={[]}
        classNames={
          classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
        }
        sortData={[]}
        headerTitle={'Outdated OSs used'}
        isLoading={isOutdatedOSDataLoading}
        data={
          outdatedOSData && outdatedOSData.snapshot
            ? Object.values(outdatedOSData.snapshot).map((item) => +item)
            : []
        }
        dataLabels={
          outdatedOSData
            ? Object.keys(outdatedOSData.snapshot).map((item) => item + ':')
            : []
        }
      />
    )
  );
};
