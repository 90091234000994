import {
    CellBottomGroupPart,
    CellBottomPart,
} from '@components/ui/Table/utils';
import { UserRoles, Permissions } from '@hooks/users/types';
import useAuthStore from '@store/authStore';
import { hasPermission } from '@utils/index';

import { Link } from 'react-router-dom';
import {
    CircleRightArrow,
} from '@components/ui/Icons';

export const createCellValue = (
    property: string,
    data: any,
    index: number,
    isMobile: boolean,
) => {
    const value = data[index][property];
    const userRole = useAuthStore.getState().role || UserRoles.STANDARD;

    const seeDetailsHasPermission = hasPermission(
        userRole,
        Permissions.ADMINS_SUPPORTUSER
    );

    switch (property) {
        case 'accountFullName':
            return (
                <>
                    <span className="flex items-center">
                        {value}{' '}
                        {seeDetailsHasPermission && (
                            <Link
                                className="ml-2 mt-[3px] cursor-pointer"
                                to={`/accounts/accounts/account/${data[index]['accountId']}`}
                            >
                                <CircleRightArrow size="4" classNames="mt-[0px]" />
                            </Link>
                        )}
                    </span>
                    {!isMobile && (
                        <CellBottomPart data={data[index]['accountEmailAddress']} />
                    )}
                </>
            );
        case 'groups':
            return (
                <>
                    <span>{value?.[0] ?? 'Unassigned'}</span>
                    {data[index]['groups'].length - 1 > 0 && (
                        <CellBottomGroupPart count={data[index]['groups'].length - 1} />
                    )}
                </>
            );
        case 'locationName':
            return (
                <>
                    <span>{value ?? 'Unassigned'}</span>
                </>
            );
        case 'fullVersionName':
            return (
                <>
                    <span>{value ?? ''}</span>
                </>
            );
            case 'accountType':
                return (
                    <>
                        <span>{value ?? ''}</span>
                    </>
                );
        case 'default':
            return (
                <></>
            );
    }
};
