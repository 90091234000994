import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { useAddDashboardTile, useRemoveDashboardTile } from '@hooks/dashboard';
import { useSecureScoreTypesSnapshotQuery } from '@hooks/security';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { isWholeNumber } from '@utils/index';

type SecureScoreHelperProp = TilesProps & {
  type: number;
};

export const SecureScoreHelper: React.FC<SecureScoreHelperProp> = ({
  params,
  id,
  classNames,
  type,
}) => {
  const SecureScoreTilesConfig: Record<
    number,
    { headerTitle: string; category: string }
  > = {
    [DashboardTiles.SecureScoreIdentity]: {
      headerTitle: 'Secure Score: Identity',
      category: 'Identity',
    },
    [DashboardTiles.SecureScoreDevice]: {
      headerTitle: 'Secure Score: Device',
      category: 'Device',
    },
    [DashboardTiles.SecureScoreApps]: {
      headerTitle: 'Secure Score: Apps',
      category: 'Apps',
    },
    [DashboardTiles.SecureScoreInfrastructure]: {
      headerTitle: 'Secure Score: Infrastructure',
      category: 'Infrastructure',
    },
  };

  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(type, '');
  const isOnDashboardPage = !!id;
  // data hook
  const { data: secureScoreData, isLoading: isSecureScoreLoading } =
    useSecureScoreTypesSnapshotQuery({
      category: SecureScoreTilesConfig[type].category,
    });

  const formattedPercentage = secureScoreData
    ? isWholeNumber(secureScoreData.scorePercentage)
      ? `${Math.round(secureScoreData.scorePercentage)}%`
      : `${secureScoreData.scorePercentage.toFixed(2)}%`
    : '0%';

  return (
    <NumberTile
      dropdownMenuItemClasses={'!w-[300px]'}
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      key={id}
      sortData={undefined}
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      headerTitle={SecureScoreTilesConfig[type].headerTitle}
      isLoading={isSecureScoreLoading}
      data={[formattedPercentage] || []}
      percentageLabel=""
    />
  );
};
