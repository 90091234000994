import * as React from 'react';
import { IconProps } from './types';

export const ArrowDownIcon: React.FC<IconProps> = ({
  size = '5',
  color = 'text-interfaceColor-100',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      fill="none"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="m12 7-4 4-4-4"
      />
    </svg>
  );
};
