import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useEffect, useState } from 'react';

import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { useSharePointUsageTrendQuery } from '@hooks/utilisation';
import {
  LineChartTile,
  LineChartTooltipFormat,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { getTrendData, getTrendLabels } from '@utils/index';

type Params = {
  days: string;
  dataType: string;
};

export const SharePointUsageTrendOverTime: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.SharePointUsageTrendOverTime,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: sharePointUsageTrendData,
    isLoading: isSharePointUsageLoading,
    refetch,
  } = useSharePointUsageTrendQuery(parameters);

  const labels = getTrendLabels(
    sharePointUsageTrendData?.trend || [],
    +parameters.days
  );

  const compareAgainstTrendData = getTrendData(
    sharePointUsageTrendData?.compareAgainst || [],
    +parameters.days
  );

  const trendData = getTrendData(
    sharePointUsageTrendData?.trend || [],
    +parameters.days
  );

  useEffect(() => {
    const updatedParams = JSON.parse(params);

    setParameters({
      days: updatedParams.days,
      dataType: parameters.dataType,
    });

    return () => {
      refetch();
    };
  }, [parameters.dataType, parameters.days, params, refetch]);

  const handleDropdown = (value: string, type: keyof Params) => {
    const newParameters = {
      ...parameters,
      [type]: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const sharePointSortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Sites',
      value: 'sites',
      action: () => handleDropdown('sites', 'dataType'),
    },
    {
      id: '2',
      label: 'Active sites',
      value: 'activesites',
      action: () => handleDropdown('activesites', 'dataType'),
    },
    {
      id: '3',
      label: 'Pages',
      value: 'pages',
      action: () => handleDropdown('pages', 'dataType'),
    },
    {
      id: '5',
      label: 'Visits',
      value: 'visits',
      action: () => handleDropdown('visits', 'dataType'),
    },
    {
      id: '4',
      label: 'Files',
      value: 'files',
      action: () => handleDropdown('files', 'dataType'),
    },
  ];

  const selectedSortItem = sharePointSortMenuItems.find(
    (item) => item.value === parameters.dataType
  );

  return (
    parameters && (
      <LineChartTile
        classNames={
          classNames ? classNames : 'col-span-6 md:col-span-6 xl:col-span-4'
        }
        sortData={sharePointSortMenuItems}
        initialSelectedItem={selectedSortItem}
        compareLabel=""
        animation={false}
        legendLabels={[
          (selectedSortItem && selectedSortItem.label) || '',
          `Previous ${parameters.days} days`,
        ]}
        dataSetColors={['#00CF6C', '#3F454B']}
        dataSetTypes={[LineChartTypes.Dots, LineChartTypes.Line]}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        tooltipLabel={selectedSortItem?.label || ''}
        headerTitle={'SharePoint usage: trend over time'}
        isLoading={isSharePointUsageLoading}
        labels={labels}
        data={[trendData, compareAgainstTrendData]}
        tooltipLabelFormat={LineChartTooltipFormat.Storage}
        dataSetLabel="Storage"
        adjustedPosition={{ right: '-45px' }}
        showDateRange={true}
      />
    )
  );
};
