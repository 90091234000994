import { Button, Input, Modal, Select, SelectItem } from '@components/ui';

import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import useUIStore from '@store/uiStore';
import { useEffect, useState } from 'react';
import {
  AuthenticationResult,
  Configuration,
  PublicClientApplication,
} from '@azure/msal-browser';
import { scopeConnectionRequest } from '@api/msalConfig';
import {
  useServiceIntegrationMS,
  useServiceIntegrationRC,
  useServiceIntegrationUC,
} from '@hooks/connectedServices';
import {
  ServiceIntegrationMSData,
  ServiceIntergrationResponse,
} from '@hooks/connectedServices/types';
import jwt_decode from 'jwt-decode';
import { RING_CENTRAL_CONSOLE } from '@common/constants';
import { Variant } from '@components/ui/Toast';
import { getVendorName } from '@utils/index';

export type ConnectServiceModalProps = {
  isModalOpen: boolean;
  handleModalClose: () => void;
  handleSuccess: () => void;
  msalConfig: Configuration;
  activeServices: ServiceIntergrationResponse[] | undefined;
};

export const ConnectServiceModal: React.FC<ConnectServiceModalProps> = ({
  isModalOpen,
  handleModalClose,
  handleSuccess,
  msalConfig,
  activeServices,
}) => {
  const { addToast } = useUIStore();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const [selectedId, setSelectedId] = useState<string>('');
  const [selectedVendor, setSelectedVendor] = useState<string>('');
  const [isRingCentralModalOpen, setIsRingCentralModalOpen] = useState(false);
  const [ringCentralJWT, setRingCentralJWT] = useState('');
  const [vendor, setVendor] = useState(0);
  const [isUCentricModalOpen, setIsUCentricModalOpen] = useState(false);

  const [validationJWTMessage, setValidationJWTMessage] = useState<
    string | undefined
  >(undefined);

  const getUpdatedItems = () => {
    const updatedItems: SelectItem[] = [
      { id: '1', name: 'Microsoft', value: 'microsoft', disabled: false },
      { id: '2', name: 'RingCentral', value: 'ringcentral', disabled: false },
      { id: '4', name: 'Mitel', value: 'mitel', disabled: false  },
      { id: '5', name: 'Avaya', value: 'avaya', disabled: false  },
    ];

    if (activeServices) {
      activeServices.forEach((service) => {
        if (service.vendor === 0) {
          const item = updatedItems.find((item) => item.value === 'microsoft');
          if (item) item.disabled = true;
        }
        if (service.vendor === 1) {
          const item = updatedItems.find(
            (item) => item.value === 'ringcentral'
          );
          if (item) item.disabled = true;
        }
        if (service.vendor === 3) {
          const item = updatedItems.find((item) => item.value === 'mitel');
          if (item) item.disabled = true;
        }
        if (service.vendor === 4) {
          const item = updatedItems.find((item) => item.value === 'avaya');
          if (item) item.disabled = true;
        }
      });
    }

    return updatedItems;
  };

  const [items, setItems] = useState<SelectItem[]>(getUpdatedItems());

  useEffect(() => {
    setItems(getUpdatedItems());
  }, [activeServices]);

  const handleSelectChange = (id: string) => {
    setSelectedId(id);
    setSelectedVendor(items.find(i=>i.id === id)?.name || "");
  };

  const serviceIntegrationMS = useServiceIntegrationMS();
  const serviceIntegrationRC = useServiceIntegrationRC();
  const serviceIntegrationUC = useServiceIntegrationUC();

  const connectMSHandler = async () => {
    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    return await msalInstance
      .loginPopup({ ...scopeConnectionRequest, prompt: 'consent' })
      .then(async (response: AuthenticationResult) => {
        if (response) {
          const data: ServiceIntegrationMSData = {
            tenantId: response.tenantId,
          };

          try {
            const response = await serviceIntegrationMS.mutateAsync(data);

            if (response) {
              addToast({
                variant: Variant.Success,
                message: 'Microsoft service is now connected.',
                closeable: true,
              });
              handleSuccess();
            }
          } catch (error) {
            addToast({
              variant: Variant.Error,
              message: `Something went wrong, and Microsoft service wasn't connected.`,
              closeable: true,
            });
          }
        }
      })
      .catch(() => {
        addToast({
          variant: Variant.Error,
          message: `Something went wrong.`,
          closeable: true,
        });
      });
  };

  const handleConnectService = async () => {
    // Microsoft
    if (selectedId === '1') {
      connectMSHandler();
    }

    // RingCentral
    if (selectedId === '2') {
      setIsRingCentralModalOpen(true);
      window.open(`${RING_CENTRAL_CONSOLE}${window.apiRingCentralClientId}`);
    }

    if (selectedId == '4' || selectedId == '5')
      {
        const vendor = Number.parseInt(selectedId);
        setIsUCentricModalOpen(true);
        setVendor(vendor - 1); // Vendor in dropdown is one greater than vendor in backend.
      }
  };

  const handleRingCentralConnection = async () => {
    try {
      const data = {
        jwt: ringCentralJWT,
      };
      const response = await serviceIntegrationRC.mutateAsync(data);

      if (response) {
        addToast({
          variant: Variant.Success,
          message: 'RingCentral service is now connected.',
          closeable: true,
        });
        handleSuccess();
      }
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: `Something went wrong, and RingCentral service wasn't connected.`,
        closeable: true,
      });
    }
  };

  const handleUcentricConnection = async () => {
    try {
      const data = {
        vendor: vendor,
      };
      const response = await serviceIntegrationUC.mutateAsync(data);

      if (response) {
        addToast({
          variant: Variant.Success,
          message: `We'll be in touch to arrange an engineer visit.`,
          closeable: true,
        });
        handleSuccess();
      }
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: `Something went wrong, and your ${getVendorName(vendor)} service connection request was not submitted.`,
        closeable: true,
      });
    }
  };



  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      jwt_decode(event.target.value);
      setRingCentralJWT(event.target.value);
      setValidationJWTMessage(undefined);
    } catch (e) {
      setValidationJWTMessage('Invalid JWT format');
    }
  };

  return (
    <>
      <Modal
        headerTxt="Connect a service"
        modalClassName={'h-full mt-0'}
        isOpen={isModalOpen}
        size={isTablet || isMobile ? 'small' : 'medium'}
        position={isMobile ? 'bottom' : 'default'}
        showCloseBtn={true}
        onClose={() => {
          setSelectedId('');
          handleModalClose();
        }}
        actionButtons={
          <div className="mt-5 flex flex-col gap-2 lg:flex-row">
            <Button
              type="submit"
              variant="primary"
              loading={serviceIntegrationMS.isLoading}
              disabled={!selectedId}
              onClick={handleConnectService}
            >
              Next
            </Button>
            <Button
              variant="outlineLight"
              onClick={() => {
                setSelectedId('');
                handleModalClose();
              }}
            >
              Cancel
            </Button>
          </div>
        }
        contentClassName="flex !flex-row !items-start !justify-start bg-white"
      >
        <div className="w-full">
          <label className="mb-2 flex text-16 font-semibold text-interfaceColor-100">
            Select a service to connect
          </label>
          <Select
            classNames={'w-full'}
            items={items}
            selected={selectedId}
            selectOnChange={handleSelectChange}
          />
        </div>
      </Modal>

      <Modal
        headerTxt="We've opened RingCentral in a new tab"
        modalClassName={'h-full mt-0'}
        isTruncatedHeader={false}
        isOpen={isRingCentralModalOpen}
        size={isTablet || isMobile ? 'small' : 'medium'}
        position={isMobile ? 'bottom' : 'default'}
        showCloseBtn={true}
        onClose={() => {
          setSelectedId('');
          setIsRingCentralModalOpen(false);
        }}
        actionButtons={
          <div className="mt-5 flex flex-col gap-2 lg:flex-row">
            <Button
              type="submit"
              variant="primary"
              loading={serviceIntegrationRC.isLoading}
              disabled={!ringCentralJWT}
              onClick={handleRingCentralConnection}
            >
              Done: Connect RingCentral
            </Button>
            <Button
              variant="outlineLight"
              onClick={() => {
                setSelectedId('');
                setIsRingCentralModalOpen(false);
              }}
            >
              Cancel
            </Button>
          </div>
        }
        contentClassName="flex !flex-row !items-start !justify-start bg-white"
      >
        <div className="w-full">
          <p className="mb-2">
            To connect RingCentral, you`ll need to generate a JWT token. To do
            this, follow these steps:
          </p>
          <ul className="flex flex-col gap-y-3">
            <li className="flex flex-row">
              <span className="mr-7">1.</span>
              <span>
                Log into RingCentral and click <b>Credentials</b> in the
                navigation on the left of the screen.
              </span>
            </li>
            <li className="flex flex-row">
              <span className="mr-7">2.</span>
              <span>
                In the Label field, enter <b>CloudClevr</b>. Under{' '}
                <b>Select environment</b>, choose <b>Production</b>.
              </span>
            </li>
            {/* <li>
              <div className="flex flex-row">
                <span className="mr-7">3.</span>
                <span>
                  Check the <b>Only specific apps of my choice</b> box, and
                  enter the following client ID:
                </span>
              </div>

              <span className="my-2 ml-10 flex flex-row items-center justify-between bg-interfaceColor-15 p-3">
                <span>cQRofUcvRc3bFhtjkezhtg</span>
                <ClipboardIcon classNames="-mb-[5px]" />
              </span>
            </li> */}
            <li className="flex flex-row">
              <span className="mr-7">3.</span>
              <span>
                Click the <b>Create JWT</b> button.
              </span>
            </li>
            <li>
              <div className="flex flex-row">
                <span className="mr-7">4.</span>
                <span>Copy JWT token and paste it into this box:</span>
              </div>

              <Input
                name={'jwtToken'}
                label="JWT Token"
                placeholder="Paste your JWT token"
                className="my-2 ml-10 block !text-16"
                onChange={handleChange}
                error={validationJWTMessage}
              />
            </li>
          </ul>
        </div>
      </Modal>
      <Modal
        headerTxt={`Connect ${selectedVendor}?`}
        isTruncatedHeader={false}
        isOpen={isUCentricModalOpen}
        modalClassName={'h-full mt-0'}
        size={'small'}
        position={isMobile ? 'bottom' : 'default'}
        showCloseBtn={true}
        onClose={() => {
          setSelectedId('');
          setIsUCentricModalOpen(false);
        }}
        closeOnClickOutside={false}
        actionButtons={<div className="flex flex-col gap-2 md:flex-row">          
          <Button
            variant="primary"
            onClick={handleUcentricConnection}
            className="w-fit"
            size="medium"
          >
            Request engineer visit
          </Button>
          <Button
            variant="outlineLight"
            onClick={() => {
              setIsUCentricModalOpen(false);
            }}
            className="w-fit"
            size="medium"
          >
            Cancel
          </Button>
        </div>}
        contentClassName="flex flex-column !items-start bg-white"
      >
        <div className="flex flex-col justify-start gap-y-4">
          <p>
            Connecting to {selectedVendor} requires an engineer visit.  The engineer may ask you to make some firewall changes, and you&apos;ll need a call logging application to get access to call log insights.
          </p>
          <p>
            Click the button to request an engineer visit.  We&apos;ll be in touch to arrange a date and time.
          </p>
        </div>
      </Modal>
    </>
  );
};
