import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query';
import {
  ReportFiltersResponse,
  ReportPreviewResponse,
  ReportResponse,
  UseReportsPreviewQueryOptions,
  UseReportsQueryOptions,
} from './types';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import { useHttpClient } from '@hooks/utils/httpConfig';

export function useReportQuery({
  searchTerm,
  sortBy,
  order,
  status,
  pageSize = 20,
  pageNumber = 1,
  groupTypeList,
  startDate,
  endDate,
}: UseReportsQueryOptions = {}): UseQueryResult<ReportResponse> {
  const reportClient = useHttpClient();
  const fetchReports = async (): Promise<ReportResponse> => {
    const baseParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.searchTerm = searchTerm;
    if (sortBy) baseParams.sortBy = sortBy;
    if (order) baseParams.order = order;
    if (status) baseParams.status = status;

    if (startDate) {
      baseParams.startDate = startDate;
    }

    if (endDate) {
      baseParams.endDate = endDate;
    }

    const reportTypeListParams = groupTypeList
      ? groupTypeList.map((type) => `ReportGroupTypeList=${type}`).join('&')
      : '';

    const endpoint = `${API_ENDPOINTS.REPORTS}?${new URLSearchParams(
      baseParams
    ).toString()}&${reportTypeListParams}`;

    return reportClient.get<ReportResponse>(endpoint);
  };

  return useQuery(
    [
      'reports',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      status,
      groupTypeList,
      startDate,
      endDate,
    ],
    fetchReports
  );
}

export function useReportPreviewQuery({
  reportId,
}: UseReportsPreviewQueryOptions = {}): UseQueryResult<ReportPreviewResponse> {
  const reportClient = useHttpClient();
  const fetchReportPreview = async (): Promise<ReportPreviewResponse> => {
    const endpoint = `${API_ENDPOINTS.REPORTS}/${reportId}/details`;

    return reportClient.get<ReportPreviewResponse>(endpoint);
  };

  return useQuery(['reportPreview', reportId], fetchReportPreview);
}

export const useReportPdfUrlPreviewQuery = (
  reportId: string | undefined
): UseMutationResult<unknown, unknown, void, unknown> => {
  const groupsClient = useHttpClient();
  const API_ENDPOINT = `${API_ENDPOINTS.REPORTS}/${reportId}/download-url`;

  return useMutation(() => groupsClient.get<any>(API_ENDPOINT));
};

export function useReportFiltersQuery(): UseQueryResult<ReportFiltersResponse> {
  const reportClient = useHttpClient();
  const fetchReportFilters = async (): Promise<ReportFiltersResponse> => {
    const endpoint = `${API_ENDPOINTS.REPORTS_FILTER}`;

    return reportClient.get<ReportFiltersResponse>(endpoint);
  };

  return useQuery(['reportsFilters'], fetchReportFilters);
}
