import { TilesProps } from '@common/types';
import { useDelegatedMailboxLeaderboardQuery } from '@hooks/security';
import { TableTile } from '@pages/InsightsAndAnalytics/tiles/TableTile';
import { useEffect, useState } from 'react';

type Params = {
    days: string;
};

export const Leaderboard: React.FC<TilesProps> = ({
    params,
    classNames,
}) => {
    const [parameters, setParameters] = useState<Params>(JSON.parse(params));
    // data hook
    const { data, isLoading, refetch } = useDelegatedMailboxLeaderboardQuery(parameters);

    useEffect(() => {
        const updatedParams = JSON.parse(params);
        setParameters({
            days: updatedParams.days,
        });
        refetch();
    }, [params]);

    return (
        <TableTile
            classNames={
                classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
            }
            contextData={[]}
            columnOrder={['mailboxEmailAddress', 'delegatesTotalCount']}
            compareData={undefined}
            headerTitle={'Leaderboard: mailboxes with the most delegates'}
            dropdownMenuItemClasses={'!w-[300px]'}
            isLoading={isLoading}
            data={data?.mailboxOwners || []}
            truncateLabel={false}
        />
    );
};
