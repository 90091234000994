import React from 'react';
import { IconProps } from './types';

export const LightInfoIcon: React.FC<IconProps> = ({
  size = '16',
  color = 'text-white',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clipPath="url(#clip0_1_561)">
        <g id="Group">
          <path
            id="Vector"
            d="M13.1429 5.71429C13.1492 4.79631 12.9097 3.89336 12.4492 3.09918C11.9888 2.30501 11.3241 1.64856 10.5243 1.19799C9.72449 0.747417 8.81863 0.519148 7.9008 0.536879C6.98296 0.55461 6.0866 0.817694 5.30477 1.29882C4.52294 1.77995 3.88414 2.46157 3.4547 3.27294C3.02525 4.0843 2.82081 4.99583 2.8626 5.91289C2.90439 6.82994 3.19089 7.7191 3.69234 8.48804C4.1938 9.25699 4.89194 9.87769 5.71429 10.2857V12C5.71429 12.1516 5.77449 12.2969 5.88166 12.4041C5.98882 12.5112 6.13417 12.5714 6.28572 12.5714H9.71429C9.86584 12.5714 10.0112 12.5112 10.1184 12.4041C10.2255 12.2969 10.2857 12.1516 10.2857 12V10.2857C11.14 9.86484 11.8599 9.21405 12.3647 8.40649C12.8694 7.59892 13.1389 6.6666 13.1429 5.71429V5.71429Z"
            stroke="currentColor"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M5.71429 15.4286H10.2857"
            stroke="currentColor"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1_561">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
