import { ServiceIntergrationResponse } from '@hooks/connectedServices/types';
import { create } from 'zustand';

type ServicesStore = {
    helixStatus: ServiceStatus | undefined;
    traditionalCommsStatus: ServiceStatus | undefined;
    isFetched: boolean;
    serviceIntegrations: ServiceIntergrationResponse[] | undefined;
};

export enum ServiceStatus {
    Active = 0,
    Inactive = 1,
    Disconnected = 2,
    PendingVendorApproval = 3,
    PendingUserConsent = 4
}

type ModalStoreActions = {
    setHelixStatus: (value: ServiceStatus | undefined) => void;
    setTraditionalCommsStatus: (value: ServiceStatus | undefined) => void;
    SetIsFetched: (value: boolean) => void;
    setServiceIntegrations: (value: ServiceIntergrationResponse[] | undefined) => void;
};

const useServicesStore = create<ServicesStore & ModalStoreActions>((set) => ({
    isFetched: false,
    helixStatus: undefined,
    traditionalCommsStatus: undefined,
    serviceIntegrations: undefined,
    SetIsFetched: (value: boolean) => {
        set(() => ({ isFetched: value }));
    },
    setHelixStatus: (value: ServiceStatus | undefined) => {
        set(() => ({ helixStatus: value }));
    },
    setTraditionalCommsStatus: (value: ServiceStatus | undefined) => {
        set(() => ({ traditionalCommsStatus: value }));
    },
    setServiceIntegrations: (value: ServiceIntergrationResponse[] | undefined) => {
        set(() => ({ serviceIntegrations: value }));
    },
}));

export default useServicesStore;
