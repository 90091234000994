import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import {
  DEFAULT_PAGE_SIZE,
  LOCATION_TABLE_COLUMNS,
  PEOPLE_FILTER_BY,
  PEOPLE_SORT_BY,
} from '@common/constants';

import { OrderBy } from '@common/types';
import { OverlayPage } from '@components/partials/OverlayPage';
import { TableWrapper } from '@components/partials/TableWrapper';
import { Loader } from '@components/ui/Loader';
import { useUpdateLocationMembers } from '@hooks/locations';
import { Location } from '@hooks/locations/types';
import { usePersonsQuery } from '@hooks/people';
import { PeopleSortBy } from '@hooks/people/types';
import useTableStore from '@store/tableStore';
import useUIStore from '@store/uiStore';
import { hasPermission, useTableData } from '../../../utils';
import { useIsMobile } from '@hooks/utils/isMobile';
import { usePagination } from '@hooks/utils/pagination';
import { createCellValue } from './utils';
import { Variant } from '@components/ui/Toast';
import { UserRoles, Permissions } from '@hooks/users/types';
import useAuthStore from '@store/authStore';

const pageSize = DEFAULT_PAGE_SIZE;

export const AddRemoveMembers: React.FC = () => {
  const navigate = useNavigate();
  const { locationId } = useParams();
  const currentLocation = useLocation();
  const { addToast } = useUIStore();
  const { pageNumber, setPageNumber } = usePagination();
  const [search, setSearch] = useState('');
  const isMobile = useIsMobile();
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
    filter: withDefault(StringParam, ''),
  });
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;

  const checkboxHasPermission = hasPermission(
    userRole,
    Permissions.ADMINS_SUPPORTUSER
  );

  const personsQuery = usePersonsQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    personStatus: queryParams.filter,
  });
  const getTableData = useTableData(
    personsQuery,
    LOCATION_TABLE_COLUMNS,
    createCellValue
  );
  const updateLocationMembersMutation = useUpdateLocationMembers(locationId);
  const { reset, newlySelectedItems, newlyUnselectedItems } = useTableStore();

  const selectedLocation: Location = currentLocation.state?.location;
  const locationName = selectedLocation.name || '';

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const handleUpdateMembers = async () => {
    const data = {
      addedMemberIds: newlySelectedItems,
      removedMemberIds: newlyUnselectedItems,
    };

    try {
      await updateLocationMembersMutation.mutateAsync(data);

      addToast({
        variant: Variant.Success,
        message: 'We’ve saved your changes',
        closeable: true,
      });
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: 'Failed to update members',
        closeable: true,
      });
    }

    navigate('/accounts/locations');
  };

  const getFooterText = (): string => {
    let newSelectedText = '';
    let removeSelectedText = '';

    if (newlySelectedItems.length) {
      newSelectedText = `${newlySelectedItems.length} will be moved to ${selectedLocation.name}.`;
    }

    if (newlyUnselectedItems.length) {
      removeSelectedText = `${newlyUnselectedItems.length} will be removed.`;
    }

    return `${newSelectedText} ${removeSelectedText}`;
  };

  return (
    <OverlayPage
      footerText={getFooterText()}
      isFooterVisible={true}
      isCancelBtnVisible={!isMobile}
      handleOnSave={handleUpdateMembers}
      path="/accounts/locations"
      headerTitle={`${locationName}: Add / remove people`}
    >
      {!personsQuery.isFetched ||
      !personsQuery.data ||
      personsQuery.isLoading ? (
        <Loader />
      ) : (
        <TableWrapper
          search={search}
          setSearch={setSearch}
          columns={LOCATION_TABLE_COLUMNS}
          data={getTableData()}
          sortData={PEOPLE_SORT_BY}
          filterData={PEOPLE_FILTER_BY}
          searchKey="search"
          query={personsQuery}
          refetchQuery={personsQuery.refetch}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          setPageNumber={setPageNumber}
          isCollapsable={false}
          sliceColumns={5}
          isDownloadAvailable={false}
          searchTag={`Location: ${locationName}`}
          showCheckboxes={checkboxHasPermission}
          filterByData={selectedLocation.id}
          filterByKey={'location'}
        />
      )}
    </OverlayPage>
  );
};
