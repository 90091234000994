import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

import React from 'react';
import cn from 'classnames';

export type DatasetType = {
  data: number[];
  backgroundColor: string[];
};

type HorizontalBarChartProps = {
  datasets: DatasetType[];
  labels: string[];
  options?: ChartOptions<'bar'>;
  classNames?: string;
  height?: number;
};

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({
  datasets,
  labels,
  classNames = '',
  options,
  height = 160,
}) => {
  const chartClassNames = cn(classNames);

  const data: ChartData<'bar', number[], string> = {
    labels,
    datasets,
  };

  const defaultOptions: ChartOptions<'bar'> = {
    indexAxis: 'y',
    devicePixelRatio: 2,
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    layout: {
      padding: 0,
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        offset: true,
      },
      y: {
        ticks: {
          display: true,
          crossAlign: 'far',
          padding: 0,
          font: {
            size: 16,
            family: 'Roobert',
          },
          align: 'center',
          color: '#282B2F',
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        offset: true,
      },
    },
  };

  const chartOptions = options
    ? { ...defaultOptions, ...options }
    : defaultOptions;

  return (
    <div className={chartClassNames}>
      <Bar height={height} data={data} options={chartOptions} />
    </div>
  );
};

export default HorizontalBarChart;
