import { OverlayPage } from '@components/partials/OverlayPage';
import {
  useLicencesExpiringSnapshotQuery,
  useLicencesExpiringSnapshotUserQuery,
  useLicencesExpiringSnapshotVendorQuery,
  useLicencesFiltersQuery,
  useLicensesExpiringPreviewQuery,
} from '@hooks/licenses';
import React, { useEffect, useState } from 'react';

import {
  DEFAULT_PAGE_SIZE,
  LICENSES_EXPIRING_BAR_LABELS,
  LICENSES_EXPIRED_SORT_BY,
  LICENSES_EXPIRING_TABLE_COLUMNS,
  VENDORS,
  DashboardTiles,
} from '@common/constants';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';

import styles from './styles.module.css';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { OrderBy } from '@common/types';
import { NoResults, TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import {
  LicensesExpiringSortBy,
  LicensesFilterData,
  LicensesFiltersResponse,
} from '@hooks/licenses/types';
import { useIsDesktop } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';
import { HorizontalBarChartTile } from '@pages/InsightsAndAnalytics/tiles//HorizontalChartTile';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles//NumberTile';
import { createCellValue } from './utils';
import { useDashboardTileSaveMutation } from '@hooks/dashboard';
import { Button, DashboardIcon } from '@components/ui';
import { MenuItem } from '@components/ui/DropdownMenu';
import { formatDate, useTableData } from '@utils/index';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';
import { useNavigate } from 'react-router-dom';

const pageSize = DEFAULT_PAGE_SIZE;

const ExpiringLicensePage: React.FC = () => {
  const navigate = useNavigate();
  const { pageNumber, setPageNumber } = usePagination();
  const [search, setSearch] = useState('');
  const isDesktop = useIsDesktop();
  const [days, setDays] = useState('7');
  const [licensesExpiringBarLabels, setLicensesExpiringBarLabels] = useState<
    string[]
  >([]);
  const redirectPath = useNavSourceRedirect(
    '/insights-and-analytics/utilisation'
  );
  const handleDropdown = (value: string) => {
    setDays(value);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, 'status'),
    order: withDefault(StringParam, 'asc'),
    filter: withDefault(StringParam, ''),
  });

  const { data: filterData } = useLicencesFiltersQuery(
    'true',
    queryParams.filter.includes('freeLicenses')
  );

  const query = useLicensesExpiringPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as LicensesExpiringSortBy,
    order: queryParams.order as OrderBy,
    vendorList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => VENDORS.includes(filter as any))
      : [],
    includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
    subscriptionNameList:
      queryParams.filter && filterData
        ? queryParams.filter
            .split(',')
            .filter((filter) =>
              filterData?.licenseType
                ?.map((item) => item.value)
                .includes(filter)
            )
        : [],
    autoRenew: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) =>
            ['Active', 'Inactive'].map((item) => item).includes(filter)
          )[0]
      : '',
    renewalPeriod: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) =>
            ['Annual', 'Monthly'].map((item) => item).includes(filter)
          )[0]
      : '',
  });

  const propertyLabels: Record<string, string> = {
    vendor: 'Vendor',
    licensetype: 'License Type',
  };

  const getPropertyLabel = (property: string): string => {
    return propertyLabels[property.toLowerCase()] || property;
  };

  const transformedFilterData: FilterData[] = [];
  if (filterData) {
    Object.keys(filterData).forEach((property) => {
      const filterDataArray: FilterData = {
        label: getPropertyLabel(property),
        name: property.toLowerCase(),
        options: [],
        singleSelect: !!(property === 'vendor'),
      };

      const propertyData =
        filterData[property as keyof LicensesFiltersResponse];

      if ('value' in propertyData) {
        const item = propertyData as LicensesFilterData;

        const option = {
          label: item.value || item.vendor,
          value: item.value || item.vendor,
          count: item.count,
        };

        filterDataArray.options.push(option);
      } else {
        const items = propertyData as unknown as LicensesFilterData[];

        items.forEach((item) => {
          const option = {
            label: item.value || item.vendor,
            value: item.value || item.vendor,
          };

          filterDataArray.options.push(option);
        });
      }

      filterDataArray.options.forEach((option) => {
        const matchingVendor = filterData.licenseType.find(
          (v) => v.value === option.value
        );

        if (matchingVendor) {
          option.vendor = matchingVendor.vendor;
        }
      });

      transformedFilterData.push(filterDataArray);
    });

    transformedFilterData.push(
      {
        label: 'Expiry status',
        name: 'expiryStatus',
        options: [
          { label: 'Active', value: 'expiryStatusActive', disabled: true },
          { label: 'Expired', value: 'expiryStatusExpired', disabled: true },
          { label: 'Disabled', value: 'expiryStatusDisabled', disabled: true },
        ],
      }
      // {
      //   label: 'Auto renew status',
      //   name: 'autoRenew',
      //   singleSelect: true,
      //   options: [
      //     { label: 'Active', value: 'Active' },
      //     { label: 'Inactive', value: 'Inactive' },
      //   ],
      // },
      // {
      //   label: 'Renewal period',
      //   name: 'renewalPeriod',
      //   singleSelect: true,
      //   options: [
      //     { label: 'Annual', value: 'Annual' },
      //     { label: 'Monthly', value: 'Monthly' },
      //   ],
      // }
    );

    transformedFilterData.push({
      label: 'Free licences',
      name: 'freeLicenses',
      singleSelect: true,
      options: [{ label: 'Show free licenses', value: 'freeLicenses' }],
    });
  }

  const { data: licencesExpiringData, isLoading: isLicencesExpiringLoading } =
    useLicencesExpiringSnapshotQuery({
      vendorList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => VENDORS.includes(filter as any))
        : VENDORS,
      includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
    });

  useEffect(() => {
    if (licencesExpiringData) {
      setLicensesExpiringBarLabels(
        LICENSES_EXPIRING_BAR_LABELS.map((label, index) => {
          return `${label} ${licencesExpiringData.expirations[index].count}`;
        })
      );
    }
  }, [licencesExpiringData]);

  const {
    data: licencesExpiringSnapshotVendorData,
    isLoading: isLicencesExpiringSnapshotVendorLoading,
  } = useLicencesExpiringSnapshotVendorQuery({
    vendorList: VENDORS,
    subscriptionNameList:
      queryParams.filter && filterData
        ? queryParams.filter
            .split(',')
            .filter((filter) =>
              filterData?.licenseType
                ?.map((item) => item.value)
                .includes(filter)
            )
        : [],
    autoRenew: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) =>
            ['Active', 'Inactive'].map((item) => item).includes(filter)
          )[0]
      : '',
    renewalPeriod: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) =>
            ['Annual', 'Monthly'].map((item) => item).includes(filter)
          )[0]
      : '',
    includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
  });

  const {
    data: licencesExpiringSnapshotUserData,
    isLoading: isLicencesExpiringSnapshotUserLoading,
  } = useLicencesExpiringSnapshotUserQuery({
    days,
    vendorList: VENDORS,
    subscriptionNameList:
      queryParams.filter && filterData
        ? queryParams.filter
            .split(',')
            .filter((filter) =>
              filterData?.licenseType
                ?.map((item) => item.value)
                .includes(filter)
            )
        : [],
    autoRenew: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) =>
            ['Active', 'Inactive'].map((item) => item).includes(filter)
          )[0]
      : '',
    renewalPeriod: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) =>
            ['Annual', 'Monthly'].map((item) => item).includes(filter)
          )[0]
      : '',
    includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
  });
  const getTableData = useTableData(
    query,
    LICENSES_EXPIRING_TABLE_COLUMNS,
    createCellValue
  );

  const dashboardSaveMutation = useDashboardTileSaveMutation();

  const handleItemAction = (type: DashboardTiles) => () => {
    const getParametersData = () => {
      const subscriptionNameList =
        queryParams.filter && filterData
          ? queryParams.filter
              .split(',')
              .filter((filter) =>
                filterData?.licenseType
                  ?.map((item) => item.value)
                  .includes(filter)
              )
          : [];

      const autoRenew = queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) =>
              ['Active', 'Inactive'].map((item) => item).includes(filter)
            )[0]
        : '';

      const renewalPeriod = queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) =>
              ['Annual', 'Monthly'].map((item) => item).includes(filter)
            )[0]
        : '';

      return {
        days,
        vendorList: VENDORS,
        subscriptionNameList,
        autoRenew,
        renewalPeriod,
        includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
      };
    };

    const parametersData = getParametersData();

    if (type === DashboardTiles.LicensesExpiringSoonSnapshot) {
      const params = {
        vendorList: queryParams.filter
          ? queryParams.filter
              .split(',')
              .filter((filter) => VENDORS.includes(filter as any)) || VENDORS
          : VENDORS,
      };

      dashboardSaveMutation.mutate({
        parameters: JSON.stringify(params),
        tileType: type,
      });
    }

    if (
      type === DashboardTiles.LicensesExpiringSoonVendorSplit ||
      type === DashboardTiles.UsersImpactedByLicenseExpiry
    ) {
      dashboardSaveMutation.mutate({
        parameters: JSON.stringify(parametersData),
        tileType: type,
      });
    }
  };

  const createMenuItems = (tileType: DashboardTiles): MenuItem[] => [
    {
      id: '0',
      label: 'Add to Dashboard',
      icon: <DashboardIcon classNames="mr-1" />,
      value: 'addToDashboard',
      action: handleItemAction(tileType),
    },
  ];

  const createSortDaysMenuItems = (): MenuItem[] => [
    {
      id: '1',
      label: 'Next 7 days',
      action: () => handleDropdown('7'),
    },
    {
      id: '2',
      label: 'Next 30 days',
      action: () => handleDropdown('30'),
    },
    {
      id: '3',
      label: 'Next 60 days',
      action: () => handleDropdown('60'),
    },
    {
      id: '4',
      label: 'Next 90 days',
      action: () => handleDropdown('90'),
    },
  ];

  const haveResults = query?.data?.totalPageCount !== 0;

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.LICENSES_EXPIRING_PREVIEW}/export`,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as LicensesExpiringSortBy,
      order: queryParams.order as OrderBy,
      vendorList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => VENDORS.includes(filter as any))
        : [],
      includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
      subscriptionNameList:
        queryParams.filter && filterData
          ? queryParams.filter
              .split(',')
              .filter((filter) =>
                filterData?.licenseType
                  ?.map((item) => item.value)
                  .includes(filter)
              )
          : [],
      autoRenew: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) =>
              ['Active', 'Inactive'].map((item) => item).includes(filter)
            )[0]
        : '',
      renewalPeriod: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) =>
              ['Annual', 'Monthly'].map((item) => item).includes(filter)
            )[0]
        : '',
    },
    filename: `Clevr360_Licenses_expiring_soon_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Licenses expiring soon`}
    >
      <div className={styles.pageGrid}>
        {isDesktop && (
          <>
            <HorizontalBarChartTile
              classNames={'col-span-3 lg:col-span-2'}
              contextData={[]}
              headerTitle={'Licenses expiring soon: Snapshot'}
              isLoading={isLicencesExpiringLoading}
              data={
                licencesExpiringData?.expirations.map((item) => item.count) ||
                []
              }
              dataLabels={licensesExpiringBarLabels}
              barBackgroundColors={[
                '#00CF6C',
                '#25A8D1',
                '#B65AD7',
                '#FFB800',
                '#54b534',
              ]}
            />

            <DoughnutChartTile
              classNames={'col-span-3 lg:col-auto'}
              contextData={[]}
              headerTitle={'Licenses expiring soon: Vendor split'}
              isLoading={isLicencesExpiringSnapshotVendorLoading}
              data={
                licencesExpiringSnapshotVendorData
                  ? [
                      licencesExpiringSnapshotVendorData.vendorSnapshot
                        .Microsoft || 0,
                      licencesExpiringSnapshotVendorData.vendorSnapshot
                        .RingCentral || 0,
                    ]
                  : []
              }
              dataLabels={['Microsoft', 'RingCentral']}
              showBracketLabelValue={true}
            />

            <NumberTile
              sortData={createSortDaysMenuItems()}
              contextData={createMenuItems(
                DashboardTiles.UsersImpactedByLicenseExpiry
              )}
              headerTitle={'Users impacted by license expiry'}
              isLoading={isLicencesExpiringSnapshotUserLoading}
              data={
                licencesExpiringSnapshotUserData
                  ? Object.values(licencesExpiringSnapshotUserData)
                  : []
              }
            />
          </>
        )}
      </div>

      {haveResults ? (
        <TableWrapper
          search={search}
          setSearch={setSearch}
          searchPlaceholder={'Search by subscription type'}
          columns={LICENSES_EXPIRING_TABLE_COLUMNS}
          data={getTableData()}
          sortData={LICENSES_EXPIRED_SORT_BY}
          filterData={transformedFilterData}
          searchKey="search"
          query={query}
          refetchQuery={query.refetch}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          setPageNumber={setPageNumber}
          isCollapsable={true}
          sliceColumns={3}
          floatingFilterButton={true}
          hasFilterBanner={false}
          isLicensePage={true}
          searchCountStatsLabel="subscriptions with licenses expiring soon"
          filterHeaderText="Filter expiring licenses"
          preselectedFilter={['expiryStatusActive']}
          isDownloadAvailable={true}
          handleDownload={handleDownloadClick}
        />
      ) : (
        <NoResults
          headerTxt={"You don't have any licenses expiring soon"}
          contentTxt=""
          actionButtons={
            <Button
              variant="primary"
              className="mt-6"
              onClick={() =>
                navigate(
                  '/insights-and-analytics/utilisation/license-inventory/subscriptions'
                )
              }
            >
              <div className="flex items-center justify-center">
                <p className="px-2">View all licenses</p>
              </div>
            </Button>
          }
        />
      )}
    </OverlayPage>
  );
};

export default ExpiringLicensePage;
