import {
  DEFAULT_PAGE_SIZE,
  EXTERNAL_APPS_SORT_BY,
  EXTERNAL_APPS_TABLE_COLUMNS,
} from '@common/constants';
import { ExtendedOption, IOption, OrderBy } from '@common/types';
import { TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { PeopleSortBy } from '@hooks/people/types';
import {
  useExternalAppsFiltersQuery,
  useExternalAppsPreviewQuery,
} from '@hooks/security';
import { usePagination } from '@hooks/utils/pagination';
import { formatDate, useTableData } from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { createCellValue } from './utils';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';
import { useLocationsQuery } from '@hooks/locations';
import { useGroupsFilterQuery } from '@hooks/groups';
import { GroupsResponse } from '@hooks/groups/types';
import { Location } from '@hooks/locations/types';
import { Modal } from '@components/ui/Modal';
import useModalStore from '@store/modal';

const pageSize = DEFAULT_PAGE_SIZE;

const RemoteDataAccountsPage: React.FC = () => {
  const {
    setIsDetailsModalOpen,
    setDataDetailsModal,
    isDetailsModalOpen,
    dataDetailsModal,
  } = useModalStore();
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, 'access'),
    order: withDefault(StringParam, 'desc'),
    filter: withDefault(StringParam, ''),
  });

  const { data } = useExternalAppsFiltersQuery();
  const { data: locationsData, isFetched: locationDataFetched } =
    useLocationsQuery();
  const { data: groupsData, isFetched: groupsDataFetched } =
    useGroupsFilterQuery();

  const allLocationIds: string[] = [];
  const groupsIDs: string[] = [];
  const locationFilerOptions: IOption[] = [];
  const groupFilerOptions: ExtendedOption[] = [];
  const transformedFilterData: FilterData[] = [];

  if (data && locationDataFetched && groupsDataFetched) {
    Object.keys(data).forEach((key) => {
      let filterOptions: IOption[] = [];

      if (Array.isArray(data[key].value)) {
        filterOptions = data[key].value.map((option) => ({
          label: option.name,
          value: option.value,
        }));
      }

      transformedFilterData.push({
        label:
          data[key].key === 'PermissionLevels'
            ? 'Intrusiveness'
            : 'Publisher verification',
        name: key,
        singleSelect: false,
        options: filterOptions,
      });
    });

    if (locationsData) {
      locationsData?.forEach((location: Location) => {
        locationFilerOptions.push({ label: location.name, value: location.id });
        allLocationIds.push(location.id);
      });

      transformedFilterData.push({
        label: 'Location',
        name: 'location',
        singleSelect: false,
        options: locationFilerOptions,
      });
    }

    if (groupsData) {
      groupsData?.forEach((group: GroupsResponse) => {
        groupFilerOptions.push({
          name: group.name,
          value: group.id,
          parentId: group.parentId,
          subGroups: group.subGroups,
          id: group.id,
          label: group.name,
        });
      });

      transformedFilterData.push({
        label: 'Groups',
        name: 'groups',
        singleSelect: false,
        options: groupFilerOptions,
      });
    }
  }

  useEffect(() => {
    locationsData?.forEach((location: Location) => {
      locationFilerOptions.push({ label: location.name, value: location.id });
      allLocationIds.push(location.id);
    });
    setLocationIds(allLocationIds);
  }, [locationsData]);

  useEffect(() => {
    const nested = (element: any) => {
      element.subGroups.forEach((subElement: any) => {
        groupsIDs.push(subElement.id);
        if (subElement.subGroups && subElement.subGroups.length > 0) {
          nested(subElement);
        }
      });
    };

    groupsData?.forEach((element: any) => {
      groupsIDs.push(element.id);
      if (element.subGroups && element.subGroups.length > 0) {
        nested(element);
      }
    });

    setGroupIds(groupsIDs);
  }, [groupsData]);

  const filterValues = queryParams.filter.split(',');

  // table
  const query = useExternalAppsPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    locationList: queryParams.filter
      ? queryParams.filter
        .split(',')
        .filter((filter) => locationIds.includes(filter))
      : [],
    groupList: queryParams.filter
      ? queryParams.filter
        .split(',')
        .filter((filter) => groupIds.includes(filter))
      : [],
    publisherVerification: Array.isArray(data?.publisherVerification.value)
      ? filterValues.filter((value) =>
        data?.publisherVerification.value.some(
          (option) => option.value === value
        )
      )
      : [],
    permissionLevels: Array.isArray(data?.permissionLevels.value)
      ? filterValues.filter((value) =>
        data?.permissionLevels.value.some((option) => option.value === value)
      )
      : [],
  });

  const getTableData = useTableData(
    query,
    EXTERNAL_APPS_TABLE_COLUMNS,
    createCellValue
  );

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.EXTERNAL_APPLICATIONS_EXPORT}`,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      locationList: queryParams.filter
        ? queryParams.filter
          .split(',')
          .filter((filter) => locationIds.includes(filter))
        : [],
      groupList: queryParams.filter
        ? queryParams.filter
          .split(',')
          .filter((filter) => groupIds.includes(filter))
        : [],
      publisherVerification: Array.isArray(data?.publisherVerification.value)
        ? filterValues.filter((value) =>
          data?.publisherVerification.value.some(
            (option) => option.value === value
          )
        )
        : [],
      permissionLevels: Array.isArray(data?.permissionLevels.value)
        ? filterValues.filter((value) =>
          data?.permissionLevels.value.some(
            (option) => option.value === value
          )
        )
        : [],
    },
    filename: `Clevr360_Remote_access_data_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
      <>
        <div className="mt-2">
          <TableWrapper
            search={search}
            setSearch={setSearch}
            searchPlaceholder={
              'Search by account, publisher, app name, location, group'
            }
            columns={EXTERNAL_APPS_TABLE_COLUMNS}
            data={getTableData()}
            sortData={EXTERNAL_APPS_SORT_BY}
            filterData={transformedFilterData}
            searchKey="search"
            query={query}
            refetchQuery={query.refetch}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setPageNumber={setPageNumber}
            isCollapsable={false}
            isLicensePage={true}
            floatingFilterButton={false}
            hasFilterBanner={false}
            searchCountStatsLabel="account-level access instances"
            subTitle="Each row shows an instance of an app being given access by an individual account."
            filterHeaderText="Filter suspicious activity"
            isDownloadAvailable={true}
            handleDownload={handleDownloadClick}
            sliceColumns={2}
          />
        </div>

        <Modal
          headerTxt={dataDetailsModal.header}
          isOpen={isDetailsModalOpen}
          size={'large'}
          showCloseBtn={true}
          onClose={() => {
            setIsDetailsModalOpen(false);
            setDataDetailsModal({ body: null, header: null });
          }}
          contentClassName="bg-white"
          actionButtons={<></>}
        >
          <div className="w-full">{dataDetailsModal.body}</div>
        </Modal>
      </>
  );
};

export default RemoteDataAccountsPage;
