import React, { useEffect, useState } from 'react';
import Tab, { Tab as TabType } from '../../../../components/ui/Tab';
import { useIsDesktop, useIsMobile } from '@hooks/utils';
import { OverlayPage } from '@components/partials';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { SuspiciousActivityTrend } from '@pages/Dashboard/tiles';
import { Dropdown } from '@components/ui/Dropdown';
import { LAST_DAYS_SORT_BY } from '@common/constants';
import styles from './styles.module.css';
import { useQueryParams, StringParam, withDefault } from 'use-query-params';
import { SuspiciousActivitySourceCountry } from '@pages/Dashboard/tiles/SuspiciousActivitySourceCountry';
import { SuspiciousActivityTotalEvents } from '@pages/Dashboard/tiles/SuspiciousActivityTotalEvents';

const floatingSortDropdownData = LAST_DAYS_SORT_BY;

const SuspiciousActivityLayout: React.FC = () => {
  const isMobile = useIsMobile();
  const redirectPath = useNavSourceRedirect('/insights-and-analytics/assurance');
  const isDesktop = useIsDesktop();

  const [queryParams, setQueryParams] = useQueryParams({
    days: withDefault(StringParam, '30'), // Default value if not present
  });

  const [daysSelectedLabel, setDaysSelectedLabel] = useState(
    floatingSortDropdownData.find((item) => item.value[0] === queryParams.days)?.label || ''
  );
  const [daysSelectedIndex, setDaysSelectedIndex] = useState(
    floatingSortDropdownData.findIndex((item) => item.value[0] === queryParams.days)
  );
  const [isDaysDropdownOpen, setIsDaysDropdownOpen] = useState(false);

  useEffect(() => {
    if (queryParams.days) {
      setDaysSelectedLabel(
        floatingSortDropdownData.find((item) => item.value[0] === queryParams.days)?.label || ''
      );
      setDaysSelectedIndex(
        floatingSortDropdownData.findIndex((item) => item.value[0] === queryParams.days)
      );
    }
  }, [queryParams.days]);

  const tabs: TabType[] = [
    {
      path: '/insights-and-analytics/assurance/suspicious-activity/activity-data',
      label: 'Suspicious activity data',
    },
    {
      path: '/insights-and-analytics/assurance/suspicious-activity/accounts',
      label: 'Accounts',
    },
    {
      path: '/insights-and-analytics/assurance/suspicious-activity/countries',
      label: 'Countries',
    },
  ];

  const handleIsDaysDropdownOpen = () => {
    setIsDaysDropdownOpen(!isDaysDropdownOpen);
  };

  const handleDropdownDaysOnChange = (value: string[], index: number) => {
    setQueryParams({ days: value[0] || undefined }, 'pushIn');
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Suspicious activity`}
    >
      <>
        {!isMobile && (
          <div className="absolute left-[40px] top-[113px]">
            <Dropdown>
              <Dropdown.TextHeader
                classNames="!bg-interfaceColor-5 !border !border-interfaceColor-100 rounded-full"
                label={daysSelectedLabel}
                handleOpen={handleIsDaysDropdownOpen}
              ></Dropdown.TextHeader>
              <Dropdown.List
                className="!z-[999] text-16"
                open={isDaysDropdownOpen}
                align="left"
              >
                {floatingSortDropdownData.map((item, index) => {
                  return (
                    <Dropdown.DefaultItem
                      key={index}
                      item={item}
                      currentIndex={index}
                      selectedIndex={daysSelectedIndex}
                      onChange={() =>
                        handleDropdownDaysOnChange(item.value, index)
                      }
                    ></Dropdown.DefaultItem>
                  );
                })}
              </Dropdown.List>
            </Dropdown>
          </div>
        )}
        <div className={styles.pageGrid}>
          {isDesktop && (
            <>
              <SuspiciousActivityTrend
                isSnapshot={true}
                classNames="col-span-2"
                params={JSON.stringify({ days: queryParams.days })}
              />

              <SuspiciousActivitySourceCountry
                classNames="col-span-1"
                params={JSON.stringify({ days: queryParams.days })}
              />

              <SuspiciousActivityTotalEvents
                classNames="col-span-1"
                params={JSON.stringify({ days: queryParams.days })}
              />
            </>
          )}
        </div>
        <div className="mt-[5px]">
          <Tab
            tabs={tabs}
            queryParams={{ days: queryParams.days }} // Pass current days parameter to tabs
            className={isMobile ? 'mb-[100px]' : 'mb-[0px]'}
          />
        </div>
      </>
    </OverlayPage>
  );
};

export default SuspiciousActivityLayout;
