import { Configuration, LogLevel } from '@azure/msal-browser';

const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

/**
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: window.b2cPolicy || 'B2C_1A_Signup_Signin',
  },
  authorities: {
    signUpSignIn: {
      authority: `${
        window.customDomain || 'https://cloudclevrb2cdev.b2clogin.com'
      }/${window.tenantName || 'cloudclevrb2cdev.onmicrosoft.com'}/${
        window.b2cPolicy || 'B2C_1A_Signup_Signin'
      }`,
    },
  },
  authorityDomain:
    window.customDomain || `https://cloudclevrb2cdev.b2clogin.com`,
};

/**
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: window.clientId || 'e753dc5f-27be-4642-b313-79ecafe4fd26',
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: window.redirectUrl || 'http://localhost:3000',

    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',

    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerCallback: (
      level: LogLevel,
      message: string,
      containsPii: boolean
    ) => {
      if (containsPii) {
        return;
      }
      switch (level) {
        case LogLevel.Error:
          console.error(message);
          return;
        case LogLevel.Info:
          console.info(message);
          return;
        case LogLevel.Verbose:
          console.log(message);
          return;
        case LogLevel.Warning:
          console.warn(message);
          return;
        default:
          return;
      }
    },
    allowRedirectInIframe: true,
  },
};

export const scopeConnectionRequest = {
  scopes: ['https://graph.microsoft.com/.default'],
};

export const silentRequest = {
  scopes: window.scopes || [
    'openid',
    'profile',
    'offline_access',
    'https://cloudclevrb2cdev.onmicrosoft.com/api/api.access',
  ],
};

export const helixMsalConfig: Configuration = {
  auth: {
    clientId: window.helixAppClientId || '37859ad3-e1e4-4d3a-a64c-a572d7e0a937',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: window.location.origin,
  },
};
