import { TilesProps } from '@common/types';
import { useSuspiciousActivityTotalEventQuery } from '@hooks/security';

import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { formatNumberWithSuffix } from '@utils/index';

export const SuspiciousActivityTotalEvents: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const { data, isLoading } = useSuspiciousActivityTotalEventQuery(
    JSON.parse(params)
  );

  return (
    <NumberTile
      key={id}
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      bottomLabel=""
      percentageLabel=""
      headerTitle={'Suspicious activity: Total events'}
      isLoading={isLoading}
      data={data ? [formatNumberWithSuffix(data.count), data.percentage] : []}
    />
  );
};
