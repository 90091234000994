import Tab, { Tab as TabType } from '@components/ui/Tab';
import { WelcomeModal } from '@components/partials/WelcomeModal';

const PeopleAndGroupsLayout: React.FC = () => {
  const tabs: TabType[] = [
    { path: '/accounts/accounts', label: 'Accounts' },
    { path: '/accounts/groups', label: 'Groups' },
    { path: '/accounts/locations', label: 'Locations' },
  ];

  return (
    <div>
      <Tab tabs={tabs} />

      <WelcomeModal
        content={
          <p>
            Configure and connect user accounts in your organisation with the 
            services they use. Then assign them to organisational groups and 
            locations, to get rich insights and reports about how your services 
            are used across your business.
          </p>
        }
        btnText="Got it"
        modalKey="PeopleGroupsLocationsModal"
        title={
          <span>
            Set up teams, roles and <br /> locations to get smarter <br />
            insights and reports
          </span>
        }
      />
    </div>
  );
};

export default PeopleAndGroupsLayout;
