import { CellBottomPart } from '@components/ui/Table/utils';
import { formatDate } from '@utils/index';

export const createCellValue = (
  property: string,
  data: any,
  index: number,
  isMobile: boolean
) => {
  const value = data[index][property];

  switch (property) {
    case 'locationName':
      return (
        <>
          <span>{value ?? 'Unassigned'}</span>
          {data[index]['membersCount'] && (
            <CellBottomPart
              data={`${data[index]['membersCount']} ${
                data[index]['membersCount'] > 0 ? 'people' : 'person'
              }`}
            />
          )}
        </>
      );
    case 'emailsSent':
      return (
        <>
          <span>{value}</span>
          {Number(value) !== 0 && data[index]['emailsSentLastUsed'] && (
            <CellBottomPart
              data={`Last: ${formatDate(data[index]['emailsSentLastUsed'])}`}
            />
          )}
        </>
      );
    case 'messagesSent':
      return (
        <>
          <span>{value}</span>
          {Number(value) !== 0 && data[index]['messagesSentLastUsed'] && (
            <CellBottomPart
              data={`Last: ${formatDate(data[index]['messagesSentLastUsed'])}`}
            />
          )}
        </>
      );
    case 'callsMade':
      return (
        <>
          <span>{value}</span>
          {Number(value) !== 0 && data[index]['callsMadeLastUsed'] && (
            <CellBottomPart
              data={`Last: ${formatDate(data[index]['callsMadeLastUsed'])}`}
            />
          )}
        </>
      );
    case 'meetingsJoined':
      return (
        <>
          <span>{value}</span>
          {Number(value) !== 0 && data[index]['meetingsJoinedLastUsed'] && (
            <CellBottomPart
              data={`Last: ${formatDate(
                data[index]['meetingsJoinedLastUsed']
              )}`}
            />
          )}
        </>
      );
  }
};
