import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useGroupsTeamsInventorySnapshotQuery } from '@hooks/utilisation';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';

import { useEffect, useState } from 'react';

export type GroupsTeamsInventoryProps = TilesProps & {
  type?: DashboardTiles;
};

type Params = {
  vendor: string;
};

export const GroupsTeamsInventory: React.FC<GroupsTeamsInventoryProps> = ({
  params,
  id,
  type,
  classNames,
  isSnapshot,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { addMenuItem } = useAddDashboardTile(
    type || DashboardTiles.GroupsTeamsInventory,
    parameters
  );
  const isOnDashboardPage = !!id;

  // data hook
  const {
    data: teamsGroupsInventoryData,
    isLoading: teamsGroupsInventoryLoading,
    refetch,
  } = useGroupsTeamsInventorySnapshotQuery(parameters);

  useEffect(() => {
    setParameters(JSON.parse(params));

    return () => {
      refetch();
    };
  }, [params, refetch]);

  const handleDropdown = (value: string) => {
    const newParameters = {
      vendor: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All Vendors',
      value: '',
      action: () => handleDropdown(''),
    },
    {
      id: '2',
      label: 'Microsoft',
      value: 'microsoft',
      action: () => handleDropdown('microsoft'),
    },
    {
      id: '3',
      label: 'RingCentral',
      value: 'ringcentral',
      action: () => handleDropdown('ringcentral'),
    },
  ];

  return (
    <NumberTile
      dropdownMenuItemClasses={'!w-[300px]'}
      sortData={!isSnapshot ? sortMenuItems : []}
      initialSelectedItem={sortMenuItems.find(
        (item) => item.value === parameters.vendor
      )}
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      headerTitle={'Groups & Teams inventory'}
      isLoading={teamsGroupsInventoryLoading}
      data={
        teamsGroupsInventoryData ? Object.values(teamsGroupsInventoryData) : []
      }
      buttonLabel={!isSnapshot ? 'Groups & teams data' : ''}
      linkPath={ANALYTICS_NAVIGATION_MAP['groupsTeamsData']}
      handleButtonClick={!isSnapshot ? handleButtonClick() : undefined}
      bottomLabel="Groups / Teams in your organisation"
      percentageLabel="(30 days)"
    />
  );
};
