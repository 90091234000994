import { PageNotFoundIcon } from '@components/ui/Icons/PageNotFoundIcon';
import styles from './styles.module.css';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AuthError, InteractionStatus } from '@azure/msal-browser';
import { silentRequest } from '@api/msalConfig';
import useUIStore from '@store/uiStore';
import { Variant } from '@components/ui/Toast';

const UnauthorizedPage: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { addToast } = useUIStore();
  const { inProgress, instance } = useMsal();

  const handleLogin = async () => {
    try {
      if (isAuthenticated && inProgress === InteractionStatus.None) {
        await instance.acquireTokenSilent(silentRequest);
      }
    } catch (error) {
      console.error('Silent token acquisition failed:', error);
    }

    try {
      await instance.loginRedirect(silentRequest);
    } catch (error: unknown) {
      if (error instanceof AuthError && error.errorMessage) {
        addToast({ variant: Variant.Error, message: error.errorMessage });
      } else {
        addToast({
          variant: Variant.Error,
          message: 'An error occurred during login.',
        });
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <PageNotFoundIcon />
      <h2 className={styles.header}>Error 401: unauthorised</h2>
      <p>
        Sorry, it doesn’t look like you have the right permissions to access
        this page. This could be because:
      </p>
      <ul className={styles.list}>
        <li>
          You’re not logged in, or your session has expired.{' '}
          <span
            className="cursor-pointer !font-light underline"
            onClick={handleLogin}
          >
            {' '}
            Try logging in again.
          </span>
        </li>
        <li>
          Your account doesn’t have the right privileges to access this page.
          Contact your IT manager for support.
        </li>
      </ul>
      <p>
        If you think you should be able to access this content, please contact
        us for further assistance.
      </p>
    </div>
  );
};

export default UnauthorizedPage;
