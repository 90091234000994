import React, { useEffect } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { Loader } from '@components/ui';
import useAuthStore from '@store/authStore';
import { UserRoles } from '@hooks/users/types';
import { useNavigate } from 'react-router-dom';
import useServicesStore from '@store/servicesStore';
import { isHelixDataVisible, isTraditionalCommsDataVisible } from '@utils/index';

type PrivateRouteProps = {
  children: JSX.Element;
  allowedRoles?: UserRoles[];
  isHelixPage?: boolean;
  isTraditionalCommsPage?: boolean;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  allowedRoles,
  isHelixPage = false,
  isTraditionalCommsPage = false
}) => {
  const { instance, inProgress } = useMsal();
  const authStore = useAuthStore();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;
  const { helixStatus, isFetched, traditionalCommsStatus } = useServicesStore();

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      authStore.setIsAuthenticated(false);
      instance.loginRedirect();
    } else if (
      (isAuthenticated &&
        allowedRoles &&
        !allowedRoles.includes(userRole)) || 
        (
          (isHelixPage && isFetched && !isHelixDataVisible(helixStatus)) 
          || (isTraditionalCommsPage && isFetched && !isTraditionalCommsDataVisible(traditionalCommsStatus))
        )
    ) {
      navigate('/unauthorized', { replace: true });
    }
  }, [isAuthenticated, inProgress, userRole, allowedRoles,
      isHelixPage, isTraditionalCommsPage, helixStatus,
      traditionalCommsStatus, isFetched]);

  return isAuthenticated && inProgress === InteractionStatus.None ? (
    <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
  ) : (
    <Loader />
  );
};

export default PrivateRoute;
