import { TilesProps } from '@common/types';
import { useCallQueueSnapshotQuery } from '@hooks/security';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';


export const CallQueuesConfigured: React.FC<TilesProps> = ({
    classNames,
}) => {
    // data hook
    const { data, isLoading } = useCallQueueSnapshotQuery();

    return (
        <NumberTile
            dropdownMenuItemClasses={'!w-[300px]'}
            sortData={[]}
            classNames={
                classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
            }
            compareData={undefined}
            headerTitle={'Call queues configured'}
            isLoading={isLoading}
            data={data ? Object.values(data) : []}
            bottomLabel='Total call queues configured'
        />
    );
};
