import {
  CellBottomGroupPart,
  CellBottomPart,
  CellLeftStatusPart,
} from '@components/ui/Table/utils';
import { formatDate } from '@utils/index';

export const createCellValue = (property: string, data: any, index: number) => {
  const value = data[index][property];

  const getMfaStatusLabels = (value: string): string => {
    if (value === 'Enforced') {
      return 'Active';
    } else if (value === 'Disabled') {
      return 'Inactive';
    } else {
      return value;
    }
  };

  switch (property) {
    case 'mfaStatus':
      return (
        <>
          <CellLeftStatusPart
            status={value === 'Enabled' ? 'enabledmfa' : value}
          />
          <span>{getMfaStatusLabels(value)}</span>
          {value === 'Enabled' && (
            <CellBottomPart data="User hasn't completed setup" />
          )}
        </>
      );
    case 'groups':
      return (
        <>
          <span>{value?.[0] ?? 'Unassigned'}</span>
          {data[index]['groups'].length - 1 > 0 && (
            <CellBottomGroupPart count={data[index]['groups'].length - 1} />
          )}
        </>
      );
    case 'locationName':
      return (
        <>
          <span>{value ?? 'Unassigned'}</span>
        </>
      );
    case 'fullName':
      return (
        <>
          <span>{value}</span>
          <CellBottomPart data={data[index]['emailAddress']} />
        </>
      );
    case 'passwordExpiry':
      return (
        <>
          <span>{formatDate(value)}</span>
        </>
      );
    case 'vendor':
      return (
        <>
          <span>{value ?? 'No data available'}</span>
        </>
      );
  }
};
