import { Card } from '@components/ui';
import { Loader } from '@components/ui/Loader';
import {
  HamburgerIcon,
  LineChartIcon,
  LineChartIconUp,
} from '@components/ui/Icons';
import styles from './styles.module.css';
import cn from 'classnames';
import { DropdownMenu, MenuItem } from '@components/ui/DropdownMenu';
import { TileFooter } from '../partials/TileFooter';

export type TableTileProps = {
  sortData?: MenuItem[];
  compareData?: MenuItem[];
  contextData: MenuItem[];
  headerTitle: string;
  isLoading: boolean;
  data: any[];
  buttonLabel?: string;
  handleButtonClick?: () => void;
  classNames?: string;
  isLgSize?: boolean;
  dropdownMenuItemClasses?: string;
  initialSelectedItem?: MenuItem;
  initialCompareSelectedItem?: MenuItem;
  columnOrder?: string[];
  hideDropdowns?: boolean;
  fractionDigits?: number;
  linkPath?: string;
  truncateLabel?: boolean;
};

export const TableTile: React.FC<TableTileProps> = ({
  sortData,
  compareData,
  headerTitle,
  isLoading = false,
  data = [],
  contextData,
  buttonLabel,
  handleButtonClick,
  classNames,
  isLgSize = false,
  dropdownMenuItemClasses,
  initialSelectedItem,
  initialCompareSelectedItem,
  columnOrder,
  hideDropdowns = false,
  fractionDigits = 2,
  linkPath,
  truncateLabel = true
}) => {
  return (
    <Card classNames={`!w-auto ${classNames}`}>
      <Card.Header classNames={styles.cardHeader}>
        <div className={styles.cardHeaderContainer}>
          <span className="mb-2">{headerTitle}</span>
          {contextData.length ? (
            <DropdownMenu
              items={contextData}
              buttonProps={{ icon: <HamburgerIcon></HamburgerIcon> }}
              dropdownMenuClasses={dropdownMenuItemClasses}
            ></DropdownMenu>
          ) : null}
        </div>

        {!hideDropdowns && !!sortData?.length && (
          <DropdownMenu
            items={sortData}
            showSelectedItem={true}
            buttonProps={{
              label: sortData[0].label,
            }}
            initialSelectedItem={initialSelectedItem || sortData[0]}
          ></DropdownMenu>
        )}
        {!hideDropdowns && compareData && (
          <span className="ml-4">
            <DropdownMenu
              items={compareData}
              showSelectedItem={true}
              buttonProps={{
                label: compareData[0].label,
              }}
              dropdownMenuClasses={'!w-[220px]'}
              initialSelectedItem={initialCompareSelectedItem || compareData[0]}
            ></DropdownMenu>
          </span>
        )}
      </Card.Header>

      <Card.Content classNames={styles.cardContent}>
        {isLoading ? (
          <div className="flex w-full justify-center">
            <Loader />
          </div>
        ) : (
          <div className={styles.cardDetails}>
            <div
              className={cn(
                styles.chartContainer,
                isLgSize ? 'mt-6 lg:!h-[9rem]' : ''
              )}
            >
              {data.length ? (
                <div className="w-full">
                  <table
                    className="mb-0 w-full table-auto border-separate border-spacing-y-1 xl:mb-6"
                    style={{ borderSpacing: '0 8px' }}
                  >
                    <tbody>
                      {data.map((item, index) => {
                        const keysToRender = columnOrder || Object.keys(item);
                        return (
                          <tr key={index} className="h-10 bg-interfaceColor-5">
                            {keysToRender.map((key, keyIndex) => {
                              let value = item[key];

                              // Formatting for decimals
                              if (
                                typeof value === 'number' &&
                                value !== Math.floor(value)
                              ) {
                                value = value.toFixed(fractionDigits);
                              }

                              if (
                                keyIndex === 0 &&
                                typeof value === 'string' &&
                                value.length > 20
                              ) {
                                value = truncateLabel ? `${value.substring(0, 20)}...` : value;
                              }

                              let cellContent = value;
                              let textColorClass = 'text-interfaceColor-100';

                              if (keyIndex === 2 && key in item) {
                                if (value < 0) {
                                  textColorClass = 'text-errorColor-100';
                                  cellContent = (
                                    <div className="flex items-center">
                                      <LineChartIcon
                                        color={textColorClass}
                                        classNames="mr-2"
                                        size="5"
                                      />
                                      {value}%
                                    </div>
                                  );
                                } else if (value > 0) {
                                  textColorClass = 'text-successColor-100';
                                  cellContent = (
                                    <div className="flex items-center">
                                      <LineChartIconUp
                                        color={textColorClass}
                                        classNames="mr-2"
                                        size="5"
                                      />
                                      {value}%
                                    </div>
                                  );
                                } else {
                                  textColorClass = 'text-gray-500';
                                  cellContent = `${value}%`;
                                }
                              }

                              return (
                                <td
                                  className={cn(
                                    ' bg-interfaceColor-5 px-2 py-3',
                                    textColorClass,
                                    keyIndex === 0
                                      ? 'inline-block sm:w-[70%] w-[100%] overflow-hidden sm:truncate'
                                      : ''
                                  )}
                                  key={keyIndex}
                                >
                                  {cellContent}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="my-3 flex w-full flex-col items-center justify-center">
                  <h1 className="mb-2 rounded-full bg-interfaceColor-20 px-4 py-1 text-20 text-interfaceColor-100">
                    Insufficient data
                  </h1>
                  <p className="max-w-sm text-center text-interfaceColor-80">
                    Keep using Clevr360 to see how your
                    <span className="mx-1">{headerTitle}</span>
                    have changed over time
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </Card.Content>
      <TileFooter
        linkPath={linkPath}
        buttonLabel={buttonLabel}
        handleButtonClick={handleButtonClick}
      />
    </Card>
  );
};
