import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import { useServiceAdoptionSnapshotQuery } from '@hooks/adoption';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import {
  ServiceType,
  getDataLabels,
} from '@pages/InsightsAndAnalytics/Adoption/ServiceAdoption';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useState } from 'react';

type Params = {
  serviceType: string;
  days: string;
};

type AdoptionsHelperProps = TilesProps & {
  type: number;
};

export const AdoptionsHelper: React.FC<AdoptionsHelperProps> = ({
  params,
  id,
  type,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(type, '');
  const isOnDashboardPage = !!id;
  const {
    data: serviceAdoptionData,
    isLoading: isServiceAdoptionLoading,
    refetch,
  } = useServiceAdoptionSnapshotQuery(parameters);

  const headerTitle: Record<number, string> = {
    [DashboardTiles.ServiceAdoptionByUser]: 'Service adoption by user',
    [DashboardTiles.MessagingAdoption]: 'Messaging adoption',
    [DashboardTiles.InternalPhoneAdoption]: 'Internal phone adoption',
    [DashboardTiles.ExternalPhoneAdoption]: 'External phone adoption',
    [DashboardTiles.VideoAdoption]: 'Video adoption',
  };

  const handleServiceAdoptionDropdown = (value: string) => {
    const newParams = {
      ...parameters,
      serviceType: value,
    };

    setParameters(newParams);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParams),
        tileId: id,
      });

      refetch();
    }
  };

  const sortMenuItems = (): MenuItem[] => [
    {
      id: '1',
      label: 'All services',
      value: 'allservices',
      action: () => handleServiceAdoptionDropdown('allservices'),
    },
    {
      id: '2',
      label: 'Chat/Messaging',
      value: ServiceType.Chat,
      action: () => handleServiceAdoptionDropdown(ServiceType.Chat),
    },
    {
      id: '3',
      label: 'Phone (Internal)',
      value: ServiceType.PhoneInternal,
      action: () => handleServiceAdoptionDropdown(ServiceType.PhoneInternal),
    },
    {
      id: '4',
      label: 'Phone (External)',
      value: ServiceType.PhoneExternal,
      action: () => handleServiceAdoptionDropdown(ServiceType.PhoneExternal),
    },
    {
      id: '5',
      label: 'Video',
      value: ServiceType.Video,
      action: () => handleServiceAdoptionDropdown(ServiceType.Video),
    },
  ];

  return (
    <DoughnutChartTile
      classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      sortData={
        type === DashboardTiles.ServiceAdoptionByUser
          ? sortMenuItems()
          : undefined
      }
      initialSelectedItem={sortMenuItems().find(
        (item) => item.value === parameters.serviceType
      )}
      headerTitle={headerTitle[type]}
      dropdownMenuItemClasses={'!w-[300px]'}
      isLoading={isServiceAdoptionLoading}
      data={serviceAdoptionData ? Object.values(serviceAdoptionData) : []}
      dataLabels={getDataLabels(serviceAdoptionData)}
      buttonLabel={
        type === DashboardTiles.ServiceAdoptionByUser
          ? 'Service adoption by user data'
          : ''
      }
      linkPath={ANALYTICS_NAVIGATION_MAP['serviceAdoption']}
      handleButtonClick={handleButtonClick()}
      showBracketLabelValue={false}
    />
  );
};
