import {
  CellBottomGroupPart,
  CellBottomPart,
} from '@components/ui/Table/utils';
import { hasPermission } from '@utils/index';
import { UserRoles, Permissions } from '@hooks/users/types';
import useAuthStore from '@store/authStore';
import { Link } from 'react-router-dom';
import { CircleRightArrow } from '@components/ui';

export const createCellValue = (
  property: keyof any,
  data: any[],
  index: number,
  isMobile: boolean
) => {
  const value = data[index][property];
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;
  const seePeopleDetailsHasPermission = hasPermission(
    userRole,
    Permissions.SUPERADMIN_ADMIN
  );

  switch (property) {
    case 'accountFullName':
      return (
        <>
          <span className="flex items-center">
            {value}{' '}
            {seePeopleDetailsHasPermission && (
              <Link
                className="ml-2 mt-[3px] cursor-pointer"
                to={`/accounts/accounts/account/${data[index]['accountId']}`}
              >
                <CircleRightArrow size="4" />
              </Link>
            )}
          </span>
          {!isMobile && (
            <CellBottomPart data={data[index]['accountEmailAddress']} />
          )}
        </>
      );
    case 'groups':
      return (
        <>
          <span>{value?.[0] ?? 'Unassigned'}</span>
          {data[index]['groups']?.length - 1 > 0 && (
            <CellBottomGroupPart count={data[index]['groups']?.length - 1} />
          )}
        </>
      );
    case 'locationName':
      return (
        <>
          <span>{value ?? 'Unassigned'}</span>
        </>
      );
    case 'fullVersionName':
      return (
        <>
          <span>{value ?? ''}</span>
        </>
      );

    case 'accountType':
      return (
        <>
          <span>{value}</span>
        </>
      );
    case 'totalLoginCount':
      return (
        <>
          <span>{value}</span>
        </>
      );

  }
};
