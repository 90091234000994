import * as React from 'react';
import { IconProps } from './types';
import { sizeClasses } from '.';

export const PlusIcon: React.FC<IconProps> = ({
  size = '4',
  color = 'text-interfaceColor-100',
  strokeWidth = 1.143,
  classNames = '',
}) => {
  return (
    <svg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={`${sizeClasses[size]} stroke-current ${color} ${classNames}`}
      fill="none"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M11 8H5m3-3v6M8 15.429A7.429 7.429 0 1 0 8 .572a7.429 7.429 0 0 0 0 14.857Z"
      />
    </svg>
  );
};
