import React from 'react';

import Tab, { Tab as TabType } from '../../../components/ui/Tab';

const MyProfileLayout: React.FC = () => {
  const tabs: TabType[] = [
    { path: '/my-profile/details', label: 'User profile' },
    {
      path: '/my-profile/email-notifications',
      label: 'Email notifications',
    },
  ];

  return (
    <>
      <Tab tabs={tabs} />
    </>
  );
};

export default MyProfileLayout;
