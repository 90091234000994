import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { useAddDashboardTile, useRemoveDashboardTile } from '@hooks/dashboard';
import { useSecureScoreComparisonQuery } from '@hooks/security';
import { HorizontalBarChartTile } from '@pages/InsightsAndAnalytics/tiles/HorizontalChartTile';
import { useEffect, useState } from 'react';

export const SecureScoreComparison: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [isLabelChartLoading, setisLabelChartLoading] = useState(true);
  const [licensesExpiringBarLabels, setLicensesExpiringBarLabels] = useState<
    string[]
  >([]);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.SecureScoreComparison,
    ''
  );
  const isOnDashboardPage = !!id;

  const { data: licenseExpiringData, isLoading } =
    useSecureScoreComparisonQuery();

  useEffect(() => {
    setisLabelChartLoading(true);
    if (licenseExpiringData) {
      setLicensesExpiringBarLabels([
        `Your score: ${licenseExpiringData?.yourScore.toFixed(1) || 0}%`,
        `Organisations like yours: ${
          licenseExpiringData?.comparisonScore.toFixed(1) || 0
        }%`,
      ]);
      setisLabelChartLoading(false);
    }
  }, [licenseExpiringData]);

  return (
    <HorizontalBarChartTile
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      classNames={classNames ? classNames : 'col-span-full lg:col-span-4'}
      sortData={undefined}
      dropdownMenuItemClasses={'!w-[300px]'}
      headerTitle={'Secure score comparison'}
      isLoading={isLoading || isLabelChartLoading}
      data={licenseExpiringData ? [
        parseFloat(licenseExpiringData?.yourScore.toFixed(1) || '0'),
        parseFloat(licenseExpiringData?.comparisonScore.toFixed(1) || '0'),
      ] : []}
      dataLabels={licensesExpiringBarLabels}
      barBackgroundColors={['#00CF6C', '#B65AD7']}
    />
  );
};
