import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import {
  useMeetingRoomDeviceUsageSnapshotQuery,
  useMeetingRoomsDevicesQuery,
} from '@hooks/utilisation';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useEffect, useState } from 'react';

type Params = {
  days: string;
  deviceType: string;
};

export const MeetingRoomDeviceUsage: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.MeetingRoomDeviceUsage,
    parameters
  );
  const isOnDashboardPage = !!id;
  // data hooks
  const {
    data: meetingRoomDeviceUsageData,
    isLoading: isMeetingRoomDeviceUsageData,
    refetch,
  } = useMeetingRoomDeviceUsageSnapshotQuery(parameters);
  const { data: meetingRoomsDevicesData } = useMeetingRoomsDevicesQuery();

  useEffect(() => {
    const updatedParams = JSON.parse(params);

    setParameters({
      days: updatedParams.days,
      deviceType: parameters.deviceType,
    });

    return () => {
      refetch();
    };
  }, [parameters.deviceType, params, refetch]);

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All types',
      value: 'alltypes',
      action: () => handleSortDropdownChange('alltypes'),
    },
  ];

  const compareMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 30 days',
      value: '30',
      action: () => handleDropdownChange('30'),
    },
    {
      id: '2',
      label: 'Last 60 days',
      value: '60',
      action: () => handleDropdownChange('60'),
    },
    {
      id: '3',
      label: 'Last 90 days',
      value: '90',
      action: () => handleDropdownChange('90'),
    },
  ];

  const handleSortDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      deviceType: value === 'alltypes' ? '' : value,
    };
    setParameters(newParameters);
    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });
      refetch();
    }
  };

  const handleDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  if (meetingRoomsDevicesData) {
    meetingRoomsDevicesData.forEach((device, index) => {
      sortMenuItems.push({
        id: index + 2,
        label: device,
        value: device,
        action: () => handleSortDropdownChange(device),
      });
    });
  }

  return (
    <DoughnutChartTile
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      sortData={sortMenuItems}
      compareData={compareMenuItems}
      initialSelectedItem={sortMenuItems.find(
        (item) => item.value === parameters.days
      )}
      initialCompareSelectedItem={compareMenuItems.find(
        (item) => item.value === parameters.days
      )}
      headerTitle={'Meeting room device usage'}
      dropdownMenuItemClasses={'!w-[300px]'}
      isLoading={isMeetingRoomDeviceUsageData}
      data={
        meetingRoomDeviceUsageData
          ? [
              meetingRoomDeviceUsageData?.offlineDevicesCount,
              meetingRoomDeviceUsageData?.onlineButNotUsedDevicesCount,
              meetingRoomDeviceUsageData?.onlineDevicesCount,
            ]
          : []
      }
      dataLabels={[
        `${
          meetingRoomDeviceUsageData &&
          meetingRoomDeviceUsageData?.offlineDevicesCount
            ? meetingRoomDeviceUsageData.offlineDevicesCount
            : 0
        } offline devices`,
        `${
          meetingRoomDeviceUsageData &&
          meetingRoomDeviceUsageData?.onlineButNotUsedDevicesCount
            ? meetingRoomDeviceUsageData.onlineButNotUsedDevicesCount
            : 0
        } devices online but unused`,
        `${
          meetingRoomDeviceUsageData &&
          meetingRoomDeviceUsageData?.onlineDevicesCount
            ? meetingRoomDeviceUsageData.onlineDevicesCount
            : 0
        } devices used`,
      ]}
      buttonLabel={'Device usage data'}
      linkPath={ANALYTICS_NAVIGATION_MAP['deviceInventory']}
      handleButtonClick={handleButtonClick()}
      showBracketLabelValue={false}
      colors={['#3F454B', '#B65AD7', '#00CF6C']}
    />
  );
};
