import { DashboardTiles } from '@common/constants';
import { InactiveRooms, InactiveRoomsProps } from './InactiveRooms';

export const InactiveRoomsSnapshot: React.FC<InactiveRoomsProps> = ({
  params,
  id,
  classNames,
}) => {
  return (
    <InactiveRooms
      params={params}
      id={id}
      classNames={classNames}
      isSnapshot={true}
      type={DashboardTiles.InactiveRoomsSnapshot}
    />
  );
};
