import { CellBottomPart } from '@components/ui/Table/utils';
import { capitalizeFirstLetter, formatDate } from '@utils/index';

export const createCellValue = (
  property: string,
  data: any,
  index: number,
  isMobile: boolean
) => {
  const value = data[index][property];

  switch (property) {
    case 'modelName':
      return (
        <>
          <span>{value}</span>
          {!isMobile && (
            <>
              <CellBottomPart data={data[index]['givenName']} />
            </>
          )}
        </>
      );
    case 'assigneeDisplayName':
      return (
        <>
          <span>{value || 'Unassigned'}</span>
          <div className="flex flex-col">
            <span className="text-14 tracking-wide text-interfaceColor-80">
              {data[index]['assigneeEmail']}
            </span>
          </div>
        </>
      );
    case 'initialDate':
      return (
        <>
          <span>{formatDate(value)}</span>
        </>
      );
    case 'connectionStatus':
      return (
        <>
          <span>{value ? capitalizeFirstLetter(value) : 'Unknown'}</span>
          {value && (
            <>
              {data[index]['connectedSince'] ? (
                <CellBottomPart
                  data={`Connected since ${formatDate(
                    data[index]['connectedSince']
                  )}`}
                />
              ) : (
                <CellBottomPart data={'Never connected'} />
              )}
            </>
          )}
        </>
      );
      case 'vendor':
        return (
          <>
            <span>{value ?? 'No data available'}</span>
          </>
        );
        case 'ipAddress':
      return (
        <>
          <span>{value || 'Not Available'}</span>
          <div className="flex flex-col">
            <span className="text-14 tracking-wide text-interfaceColor-80">
              {data[index]['macAddress'] || 'Not Available'}
            </span>
          </div>
        </>
      );
  }
};
