import {
  DEFAULT_PAGE_SIZE,
  REPORTS_SORT_BY,
  REPORTS_TABLE_COLUMNS,
} from '@common/constants';
import { IOption, OrderBy } from '@common/types';
import { TableWrapper } from '@components/partials/TableWrapper';
import { WelcomeModal } from '@components/partials/WelcomeModal';
import { Loader } from '@components/ui';
import { PeopleSortBy } from '@hooks/people/types';
import { useReportFiltersQuery, useReportQuery } from '@hooks/reports';
import { usePagination } from '@hooks/utils/pagination';
import { useStickyElements } from '@hooks/utils/stickyElements';
import { getFirstMatchedFilterValue, useTableData } from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { createCellValue } from './utils';
import { GroupTypeReport, StatusReport } from '@hooks/reports/types';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import dayjs from 'dayjs';
import useTableStore from '@store/tableStore';

const pageSize = DEFAULT_PAGE_SIZE;

const ReportsPage: React.FC = () => {
  const { pageNumber, setPageNumber } = usePagination();
  const [search, setSearch] = useState('');
  const [hasFetchedInitially, setHasFetchedInitially] = useState(false);
  const { shouldApplyRange } = useTableStore();

  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, 'CreatedAt'),
    order: withDefault(StringParam, 'desc'),
    filter: withDefault(StringParam, ''),
    from: withDefault(StringParam, ''),
    to: withDefault(StringParam, ''),
  });
  useStickyElements();

  const { data } = useReportFiltersQuery();

  useEffect(() => {
    if (shouldApplyRange) {
      const start = dayjs(queryParams.from, 'DD/MM/YYYY');
      const end = dayjs(queryParams.to, 'DD/MM/YYYY');

      if (start.isValid()) {
        setFrom(start.format('MM/DD/YYYY'));
      } else {
        setFrom('');
      }

      if (end.isValid()) {
        setTo(end.format('MM/DD/YYYY'));
      } else {
        setTo('');
      }
    }
  }, [shouldApplyRange, queryParams.from, queryParams.to]);

  const reportGroupFilterOptions: IOption[] = [];
  const reportStatusFilterOptions: IOption[] = [];

  const transformedFilterData: FilterData[] = [];

  if (data) {
    data.groupType.forEach((group: GroupTypeReport) => {
      reportGroupFilterOptions.push({
        value: group.value.toString(),
        label: group.groupType,
      });
    });
    data.status.forEach((item: StatusReport) => {
      reportStatusFilterOptions.push({
        value: item.status,
        label: item.status,
      });
    });

    transformedFilterData.push({
      label: 'Status',
      name: 'statusRead',
      singleSelect: true,
      options: reportStatusFilterOptions,
    });

    transformedFilterData.push({
      label: 'Group Type',
      name: 'groupType',
      singleSelect: false,
      options: reportGroupFilterOptions,
    });
  }

  const query = useReportQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    status: getFirstMatchedFilterValue(
      queryParams,
      data?.status.map((item) => item.status) || []
    ),
    groupTypeList:
      queryParams.filter
        .split(',')
        .filter((filter) =>
          data?.groupType.map((item) => item.value.toString())?.includes(filter)
        ) || [],
    startDate: from,
    endDate: to,
  });

  const getTableData = useTableData(
    query,
    REPORTS_TABLE_COLUMNS,
    createCellValue
  );

  useEffect(() => {
    if (query.isSuccess || query.isError) {
      setHasFetchedInitially(true);
    }
  }, [query.isSuccess, query.isError]);

  if (query.isLoading && !hasFetchedInitially) {
    return <Loader />;
  }

  return (
    <div>
      <TableWrapper
        search={search}
        setSearch={setSearch}
        searchPlaceholder={'Search by report name or type'}
        columns={REPORTS_TABLE_COLUMNS}
        data={getTableData()}
        sortData={REPORTS_SORT_BY}
        searchKey="search"
        filterData={transformedFilterData}
        query={query}
        refetchQuery={query.refetch}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        setPageNumber={setPageNumber}
        isCollapsable={false}
        sliceColumns={5}
        isDownloadAvailable={false}
        hasFilterBanner={false}
        isLicensePage={true}
        searchCountStatsLabel="reports"
        filterHeaderText="Filter reports"
        isOverlayPage={false}
        hasRangeFilter={true}
      />
      <WelcomeModal
        content={
          <p>
            You`ll find all your reports and recommendations documents in this
            section. We`ll let you know when new reports are available.
          </p>
        }
        btnText="Got it"
        modalKey="ReportsModal"
        title={
          <span>
            See where you are, and <br /> where you were
          </span>
        }
      />
    </div>
  );
};

export default ReportsPage;
