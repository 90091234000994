import React from 'react';
import { IconProps } from './types';

export const CalendarIcon: React.FC<IconProps> = ({
    size = '18',
    color = 'text-interfaceColor-100',
    strokeWidth = 1.2,
    classNames = '',
}) => (
    <svg
        className={`w-${size} h-${size} ${classNames}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width="24"
        height="24"
        viewBox="0 0 24 24"
    >
        <g
            className={`stroke-current ${color}`}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
            clipPath="url(#clip0_2263_22845)"
        >
            <path d="M5.71427 6.28571C5.41116 6.28571 5.12047 6.40612 4.90615 6.62044C4.69182 6.83477 4.57141 7.12545 4.57141 7.42856V18.2857C4.57141 18.5888 4.69182 18.8795 4.90615 19.0938C5.12047 19.3081 5.41116 19.4286 5.71427 19.4286H18.2857C18.5888 19.4286 18.8795 19.3081 19.0938 19.0938C19.3081 18.8795 19.4286 18.5888 19.4286 18.2857V7.42856C19.4286 7.12545 19.3081 6.83477 19.0938 6.62044C18.8795 6.40612 18.5888 6.28571 18.2857 6.28571H16"/>
            <path d="M8 4.57141V7.99998"/>
            <path d="M16 4.57141V7.99998"/>
            <path d="M8 6.28571H13.7143"/>
            <path d="M8.00002 11.4286C8.31561 11.4286 8.57145 11.1727 8.57145 10.8571C8.57145 10.5415 8.31561 10.2857 8.00002 10.2857C7.68443 10.2857 7.42859 10.5415 7.42859 10.8571C7.42859 11.1727 7.68443 11.4286 8.00002 11.4286Z"/>
            <path d="M12 11.4286C12.3156 11.4286 12.5714 11.1727 12.5714 10.8571C12.5714 10.5415 12.3156 10.2857 12 10.2857C11.6844 10.2857 11.4286 10.5415 11.4286 10.8571C11.4286 11.1727 11.6844 11.4286 12 11.4286Z"/>
            <path d="M16 11.4286C16.3156 11.4286 16.5714 11.1727 16.5714 10.8571C16.5714 10.5415 16.3156 10.2857 16 10.2857C15.6845 10.2857 15.4286 10.5415 15.4286 10.8571C15.4286 11.1727 15.6845 11.4286 16 11.4286Z"/>
            <path d="M8.00002 16C8.31561 16 8.57145 15.7441 8.57145 15.4285C8.57145 15.113 8.31561 14.8571 8.00002 14.8571C7.68443 14.8571 7.42859 15.113 7.42859 15.4285C7.42859 15.7441 7.68443 16 8.00002 16Z"/>
            <path d="M12 16C12.3156 16 12.5714 15.7441 12.5714 15.4285C12.5714 15.113 12.3156 14.8571 12 14.8571C11.6844 14.8571 11.4286 15.113 11.4286 15.4285C11.4286 15.7441 11.6844 16 12 16Z"/>
        </g>
        <defs>
            <clipPath id="clip0_2263_22845">
                <rect width="16" height="16" fill="white" transform="translate(4 4)"/>
            </clipPath>
        </defs>
    </svg>
)
