import { useState } from 'react';

import { ArrowLongRightIcon, Button, LogoutIcon } from '@components/ui';
import { RadioGroup } from '@headlessui/react';
import logo from '@assets/svg/logo.svg';
import CheckedIcon from '@assets/svg/checked.svg';
import { useIsMobile, useIsTablet } from '@hooks/utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar } from 'swiper/modules';
import { profiles } from '@common/constants';
import { useSaveSelectProfile } from '@hooks/dashboard';
import { Link, useNavigate } from 'react-router-dom';
import useAuthStore from '@store/authStore';
import useUIStore from '@store/uiStore';
import { useMsal } from '@azure/msal-react';
import { AuthError } from '@azure/msal-browser';
import { silentRequest } from '@api/msalConfig';
import { InsightProfile, SelectProfile } from '@common/types';
import { Variant } from '@components/ui/Toast';
import { TermsModal } from '@components/partials/TermsModal';

export const OnboardingProfileSelect: React.FC = () => {
  const [selected, setSelected] = useState(null);
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const { addToast } = useUIStore();
  const { instance } = useMsal();

  const saveProfileSelect = useSaveSelectProfile();
  const { setTermsAcknowledged } = useUIStore();
  const handleLogout = async () => {
    try {
      const response = await instance.acquireTokenSilent(silentRequest);
      const idTokenHint = response.idToken;
      authStore.setIsAuthenticated(false);
      authStore.setToken(null);
      authStore.setUserRole(null);
      setTermsAcknowledged(false);

      await instance.logoutRedirect({
        postLogoutRedirectUri: window.redirectUrl || 'http://localhost:3000',
        idTokenHint,
      });
    } catch (error: unknown) {
      if (error instanceof AuthError && error.errorMessage)
        addToast({ variant: Variant.Error, message: error.errorMessage });
      else {
        addToast({
          variant: Variant.Error,
          message: 'An error occurred during logout.',
        });
      }
    }
  };

  const renderRadioOption = (profile: InsightProfile) => {
    return (
      <RadioGroup.Option
        disabled={!authStore.isTermsAndCondtitionsGranted}
        key={profile.name}
        value={profile}
        className={({ checked }) =>
          `${checked ? 'border-primaryBrandColor-100' : 'border-white'}
      relative my-1 flex h-296 w-264 cursor-pointer rounded-lg border bg-white p-4 shadow-md focus:outline-none lg:p-6 xl:p-8`
        }
      >
        {({ checked }) => (
          <>
            <div className="flex w-full flex-col items-start justify-between">
              <img alt={profile.name} src={profile.imgPath} />
              <p className="text-28 leading-[36px] text-interfaceColor-100">
                {profile.name}
              </p>
              <p className="text-16 leading-[24px] text-interfaceColor-100">
                {profile.description}
              </p>

              {checked && (
                <div className="absolute -right-2 -top-2 shrink-0 text-black">
                  <img src={CheckedIcon} alt="checked" />
                </div>
              )}
            </div>
          </>
        )}
      </RadioGroup.Option>
    );
  };

  return (
    <div className="fixed left-0 top-0 z-50 h-full w-full">
      <div className="items-left relative flex min-h-screen flex-col justify-center bg-[#f8f9fa] px-6 lg:px-24 xl:px-36">
        <img
          src={logo}
          className="absolute left-8 top-8 block h-[28px] w-[150px] transform"
          alt="CloudClevr Logo"
        />

        <div className="absolute right-8 top-6 flex">
          <Button
            variant={'dark'}
            className="!h-[40px] !px-5"
            onClick={handleLogout}
            type="button"
            size="extraSmall"
          >
            <div className="flex items-center justify-center">
              <LogoutIcon classNames="h-2 w-2 mt-2" color="text-white" />
              <span className="leading-3">Log out</span>
            </div>
          </Button>
        </div>

        <h1 className="font-400 mb-2 mt-18 w-auto text-[30px] leading-[2.5rem] text-interfaceColor-100 md:mb-4 md:mt-12 md:text-[36px] lg:mt-8 lg:w-[50rem] lg:text-[48px] lg:leading-[3.5rem]">
          {`What information are you most interested in,
        ${authStore.user?.fullName.split(' ')[0] ?? ''}?`}
        </h1>
        <div className="mb-4 flex w-full flex-col items-stretch justify-start lg:w-auto lg:flex-row lg:items-center lg:justify-between">
          <p className="text-16 font-normal leading-[1.75rem] text-interfaceColor-100 md:text-20">
            We&apos;ll use this to make sure we&apos;re showing you useful
            information.
            <br /> You can change your selection any time.
          </p>

          <Link
            to="/terms-and-conditions"
            className="absolute bottom-1 flex w-[calc(100%-48px)] items-center justify-between gap-x-8 rounded-sm bg-interfaceColor-10 px-4 py-3 shadow-shadow-01 md:bottom-4 lg:relative lg:bottom-auto lg:w-auto lg:justify-start"
          >
            <span className="text-14 font-semibold md:text-18">
              View Clevr360 terms & conditions
            </span>
            <ArrowLongRightIcon
              color="text-primaryBrandColor-100"
              size="5"
              classNames="mt-1"
            />
          </Link>
        </div>

        <div
          className={`mb-14 flex flex-col flex-wrap gap-4 md:gap-8 xl:max-w-[1440px]`}
        >
          <RadioGroup value={selected} onChange={setSelected}>
            <div className="hidden grid-cols-4 gap-x-8 lg:grid">
              {profiles.map((profile) => {
                return renderRadioOption(profile);
              })}
            </div>
            <div className="block lg:hidden">
              <Swiper
                spaceBetween={isMobile ? 50 : 20}
                centeredSlides={false}
                slidesPerView={isTablet ? 2.5 : 1.5}
                loopedSlides={profiles.length}
                pagination={{ clickable: true }}
                modules={[Navigation, Scrollbar]}
              >
                {profiles.map((profile: SelectProfile) => (
                  <SwiperSlide key={profile.value}>
                    {renderRadioOption(profile)}
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </RadioGroup>
          <Button
            className="w-full md:w-[175px]"
            disabled={!selected || !authStore.isTermsAndCondtitionsGranted}
            loading={saveProfileSelect.isLoading}
            variant="primary"
            onClick={() => {
              saveProfileSelect.mutate(selected);
              navigate('/');
            }}
          >
            Save selection
          </Button>
        </div>
      </div>

      <TermsModal />
    </div>
  );
};
