import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import { useTeamsGroupsAdoptionSnapshotQuery } from '@hooks/adoption';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { getTeamsGroupsDataLabels } from '@pages/InsightsAndAnalytics/Adoption';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useEffect, useState } from 'react';

type Params = {
  vendorType: string;
  days: string;
};

type TeamsAndGroupsAdoptionSnapshotProps = TilesProps & {
  type: number;
};

export const TeamsAndGroupsAdoptionSnapshot: React.FC<
  TeamsAndGroupsAdoptionSnapshotProps
> = ({ params, id, classNames, type, isSnapshot }) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.TeamsAndGroupsAdoptionSnapshot,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: teamsGroupsAdoptionData,
    isLoading,
    refetch,
  } = useTeamsGroupsAdoptionSnapshotQuery(parameters);

  useEffect(() => {
    const updatedParams = JSON.parse(params);

    setParameters({
      days: updatedParams.days,
      vendorType: parameters.vendorType,
    });

    return () => {
      refetch();
    };
  }, [parameters.days, params, refetch]);

  const handleDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      vendorType: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const handleCompareDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const compareMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleCompareDropdown('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleCompareDropdown('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleCompareDropdown('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleCompareDropdown('90'),
    },
  ];

  return (
    parameters && (
      <DoughnutChartTile
        dropdownMenuItemClasses={'!w-[300px]'}
        initialSelectedItem={compareMenuItems.find(
          (item) => item.value === parameters.days
        )}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        classNames={
          classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
        }
        sortData={
          type === DashboardTiles.TeamsAndGroupsAdoption
            ? compareMenuItems
            : undefined
        }
        headerTitle={'Microsoft Teams adoption'}
        isLoading={isLoading}
        data={
          teamsGroupsAdoptionData ? Object.values(teamsGroupsAdoptionData) : []
        }
        dataLabels={getTeamsGroupsDataLabels(teamsGroupsAdoptionData)}
        showBracketLabelValue={false}
        buttonLabel={!isSnapshot ? 'Teams adoption data' : ''}
        linkPath={ANALYTICS_NAVIGATION_MAP['teamsGroupsAdoption']}
        handleButtonClick={!isSnapshot ? handleButtonClick() : undefined}
      />
    )
  );
};
