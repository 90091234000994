import { useIsMobile } from '@hooks/utils';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Checkbox } from '../Checkbox';
import { Modal } from '../Modal';
import styles from './styles.module.css';

export type SelectItem = {
  id: string;
  name: string;
  value: string;
  icon?: React.ReactNode;
  classNames?: string;
  checked?: boolean;
};

type SelectDropdownProps = {
  header: React.ReactNode;
  items: SelectItem[] | undefined;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onSelect: (ids: string[], selectedItem?: SelectItem) => void;
  classNames?: string;
  isSingleSelect?: boolean;
  selectedItems?: string[];
};

export const SelectDropdown: React.FC<SelectDropdownProps> = ({
  header,
  items,
  isOpen,
  setIsOpen,
  onSelect,
  classNames,
  isSingleSelect = true,
  selectedItems,
}) => {
  const [selectedIds, setSelectedIds] = useState<string[]>(selectedItems || []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const nodeRefWrapper = useRef<HTMLDivElement>(null);
  const nodeRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (items) {
      items.forEach((item) => {
        setSelectedIds((prevSelectedIds) => {
          if (item.checked) {
            const allItems = new Set([...prevSelectedIds, item.id]);
            return Array.from(allItems)
          } else {
            return prevSelectedIds.filter(id => id !== item.id)
          }
        });
      })
    }
  }, [items]);

  useEffect(() => {
    if (isMobile && isOpen) {
      setIsModalOpen(true);
    }
  }, [isOpen, isMobile]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        nodeRef.current &&
        nodeRefWrapper.current &&
        !nodeRef.current.contains(event.target as Node) &&
        !nodeRefWrapper.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleItemClick = (item: SelectItem) => {
    if (isSingleSelect) {
      // setSelectedId(item.id === selectedId ? null : item.id);
    } else {
      setSelectedIds((prevSelectedIds) => {
        if (item.id === '0') {
          // select all
          if (!selectedIds.includes(item.id)) {
            const allIds =
              items?.map((_, index) => index.toLocaleString()) || [];
            onSelect(allIds, item);
            return allIds;
          }
          // unselect all
          onSelect([], item);
          return [];
        }

        // unselect
        if (prevSelectedIds.includes(item.id)) {
          const updatedIds = prevSelectedIds.filter(
            (id) => id !== item.id && id !== '0'
          );
          onSelect(updatedIds, item);
          return updatedIds;
        }

        // select
        const updatedIds = [...prevSelectedIds, item.id];
        // select all as well
        if (items?.length && prevSelectedIds.length - items.length === -2) {
          updatedIds.push('0');
        }
        onSelect(updatedIds, item);
        return updatedIds;
      });
    }
  };

  return (
    <>
      {!isMobile && items?.length && (
        <div ref={nodeRefWrapper}>
          {header}
          <div className={cn(styles.wrapper, classNames)} ref={nodeRef}>
            {isOpen && !isMobile && (
              <ul className={styles.list}>
                {items.map((item) => (
                  <li className={styles.listItem} key={item.id}>
                    {isSingleSelect ? (
                      <div
                        // className={cn(styles.singleSelectItem, {
                        //   [styles.selected]: item.id === selectedIds,
                        // })}
                        onClick={() => handleItemClick(item)}
                      >
                        {item.name}
                      </div>
                    ) : (
                      <Checkbox
                        checked={selectedIds.includes(item.id)}
                        className="relative mb-5 mt-3 w-full"
                        labelClassName="w-full"
                        label={item.name}
                        name={item.value}
                        onChange={() => handleItemClick(item)}
                      />
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}

      {isMobile && items?.length && (
        <div>
          {header}
          <Modal
            isOpen={isModalOpen}
            position="bottom"
            size="small"
            showCloseBtn={true}
            onClose={() => {
              setIsOpen(false);
              setIsModalOpen(false);
            }}
            modalClassName='overflow-auto h-full'
            contentClassName={'bg-white'}
          >
            <ul className={styles.listModal}>
              {items.map((item) => (
                <li className={styles.listItemModal} key={item.id}>
                  {isSingleSelect ? (
                    <div
                      onClick={() => handleItemClick(item)}
                    >
                      <span className={classNames}>{item.icon}</span>{' '}
                      {item.name}
                    </div>
                  ) : (
                    <Checkbox
                      checked={selectedIds.includes(item.id)}
                      className="relative mb-5 mt-3 w-full"
                      labelClassName="w-full"
                      label={item.name}
                      name={item.value}
                      onChange={() => handleItemClick(item)}
                    />
                  )}
                </li>
              ))}
            </ul>
          </Modal>
        </div>
      )}
    </>
  );
};
