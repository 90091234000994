import { useState } from 'react';
import styles from './styles.module.css';

type TooltipAlignOptions =
  | 'top'
  | 'left'
  | 'right'
  | 'bottom'
  | 'bottomLeft'
  | 'bottomRight'
  | 'topLeft'
  | 'topRight';

type TooltipProps = {
  children: React.ReactNode;
  content: string;
  isOpen?: boolean;
  align?: TooltipAlignOptions;
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  isOpen = false,
  align = 'top',
}) => {
  const [open, setOpen] = useState(isOpen);

  const handleShow = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className={styles.wrapper}
      onMouseEnter={handleShow}
      onMouseLeave={handleClose}
    >
      {children}
      {open && (
        <div
          data-testid="tooltip"
          className={`${styles.tooltip} ${styles[align]}`}
        >
          {content}
        </div>
      )}
    </div>
  );
};
