import greenLogo from '@assets/images/green-logo-no-text.png';
import styles from './styles.module.css';

type NoResultsProps = {
  headerTxt: string;
  contentTxt: string;
  actionButtons?: React.ReactNode;
};

export const NoResults: React.FC<NoResultsProps> = ({
  headerTxt,
  contentTxt,
  actionButtons,
}) => {
  return (
    <div className={styles.wrapper}>
      <img className={styles.logo} src={greenLogo} alt="Clevr360 logo" />
      <p className={styles.headerTxt} data-testid="headerTxt">
        {headerTxt}
      </p>
      <p className={styles.contentTxt} data-testid="contentTxt">
        {contentTxt}
      </p>
      {actionButtons && actionButtons}
    </div>
  );
};
