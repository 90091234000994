import { silentRequest } from '@api/msalConfig';
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Footer, Header, PageHeader, Sidebar } from '@components/partials';
import { Loader } from '@components/ui';
import useUIStore from '@store/uiStore';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useAuthStore from '@store/authStore';
import { HttpClient } from '@api/HttpClient';
import {
  CurrentUserResponse,
  OnboardingModalStatus,
  UserRoles,
} from '@hooks/users/types';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import { HTTP_CONFIG_API_URL } from '@common/constants';
import { useUserProfile } from '@hooks/users';
import { Variant } from '@components/ui/Toast';
import useServicesStore from '@store/servicesStore';
import { ServiceIntergrationResponse } from '@hooks/connectedServices/types';
import { useHttpClient } from '@hooks/utils/httpConfig';

export const BaseLayout = () => {
  const { isOverlayFixed, addToast } = useUIStore();
  const authStore = useAuthStore();
  const servicesStore = useServicesStore();
  const [isLoading, setIsLoading] = useState(true);

  const { data, isLoading: isLoadingProfile } = useUserProfile();

  const { instance, accounts, inProgress } = useMsal();

  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const showToast = (message: string, variant: Variant) => {
    addToast({
      variant: variant,
      message: message,
      closeable: true,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const usersClient = new HttpClient(
    HTTP_CONFIG_API_URL.organization,
    showToast
  );
  const connectedServicesClient = useHttpClient();

  useEffect(() => {
    const handleTokenAcquisition = async () => {
      try {
        setIsLoading(true);
        if (inProgress === InteractionStatus.None) {
          await instance
            .acquireTokenSilent(silentRequest)
            .then((tokenResponse) => {
              const accessToken = tokenResponse.idToken;
              authStore.setToken(accessToken);
              authStore.setTokenWithScope(tokenResponse.accessToken);
              authStore.setAccounts(accounts);
              authStore.setIsAuthenticated(isAuthenticated);
              setIsLoading(false);
            })
            .catch((error) => {
              if (error instanceof InteractionRequiredAuthError) {
                setIsLoading(false);
                return instance.acquireTokenRedirect(silentRequest);
              }
            });

          const [currentUserResponse, termsResponse] = await Promise.all([
            usersClient.get<CurrentUserResponse>(API_ENDPOINTS.CURRENT_USER),
            usersClient.get<boolean>(
              API_ENDPOINTS.TERMS_CONDITIONS_HAS_GRANTED
            ),
          ]);

          if (termsResponse) {
            await usersClient
              .get<OnboardingModalStatus>(
                `${API_ENDPOINTS.USER_ONBOARDING_MODAL}`
              )
              .then((response: any) => {
                authStore.setModalsStatus(response.modalsStatus);
              });

            await connectedServicesClient
              .get<ServiceIntergrationResponse[]>(
                `${API_ENDPOINTS.SERVICE_INTEGRATIONS}`
              )
              .then((response: ServiceIntergrationResponse[]) => {
                servicesStore.setServiceIntegrations(response);
                servicesStore.setHelixStatus(response?.find((item) =>
                  item.vendor === 2)?.status);
                servicesStore.setTraditionalCommsStatus(response?.find((item) =>
                  item.vendor === 3 || item.vendor === 4)?.status)
                servicesStore.SetIsFetched(true);
              });
          }

          authStore.setTermsAndConditions(termsResponse);
          authStore.setUser(currentUserResponse);
          authStore.setUserRole(currentUserResponse.role as UserRoles);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    handleTokenAcquisition();
  }, [inProgress, instance, isAuthenticated, navigate, setIsLoading]);

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      if (
        data === null &&
        (authStore.role === UserRoles.ADMIN ||
          authStore.role === UserRoles.SUPERADMIN ||
          authStore.role === UserRoles.STANDARD)
      ) {
        navigate('/select-profile');
      } else if (
        data &&
        !authStore.isTermsAndCondtitionsGranted &&
        authStore.role !== UserRoles.SUPPORT
      ) {
        navigate('/terms-and-conditions');
      }
    } else if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect();
    }
  }, [
    isAuthenticated,
    inProgress,
    instance,
    authStore.selectedProfile,
    data,
    authStore.role,
    authStore.isTermsAndCondtitionsGranted,
  ]);

  if (
    isLoading ||
    !isAuthenticated ||
    inProgress !== InteractionStatus.None ||
    isLoadingProfile
  ) {
    return <Loader />;
  }

  return !isOverlayFixed &&
    !isLoading &&
    inProgress === InteractionStatus.None ? (
    <Outlet />
  ) : (
    !isLoading && (
      <div
        id="content"
        className="relative block w-full bg-interfaceColor-5 p-0 px-0 pb-0 pt-0"
      >
        <Sidebar />

        <main
          className={cn(
            'ml-0 flex min-h-screen flex-col justify-between xl:ml-[300px] xl:min-h-[calc(100vh-35px)]'
          )}
        >
          <Header />
          <div className="p-4 lg:p-5 xl:pt-[80px]">
            <PageHeader />
            <Outlet />
          </div>
          <div className="flex w-full pl-0 md:pl-10">
            <Footer />
          </div>
        </main>
      </div>
    )
  );
};
