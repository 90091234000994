import { useQuery } from 'react-query';
import { useHttpClient } from '../httpConfig';
import { FetchCsvFileParams, UseDownloadCsvParams } from '@common/types';
import useTableStore from '@store/tableStore';

export const fetchCsvFile = async ({
  httpClient,
  endpoint,
  params,
  downloadType,
}: FetchCsvFileParams): Promise<Blob> => {
  const baseParams = new URLSearchParams({
    ...(params.pageSize && { pageSize: params.pageSize.toString() }),
    ...(params.pageNumber && { pageNumber: params.pageNumber.toString() }),
    ...(params.sortBy && { sortBy: params.sortBy }),
    ...(params.order && { order: params.order }),
  });

  if (params.assignedTo) {
    baseParams.append('assignedTo', params.assignedTo);
  }

  if (params.type) {
    baseParams.append('type', params.type);
  }

  if (params.days) {
    baseParams.append('days', `${params.days}`);
  }

  if (downloadType === 'filters') {
    if (params.ids) {
      baseParams.append('TeamIds', `${params.ids}`);
    }

    if (params.userType) {
      baseParams.append('userType', `${params.userType}`);
    }

    if (params.includeFreeLicenses) {
      baseParams.append('includeFreeLicenses', `${params.includeFreeLicenses}`);
    }

    if (params.includePersonalSites) {
      baseParams.append(
        'includePersonalSites',
        `${params.includePersonalSites}`
      );
    }

    if (params.sharedMailboxStatus) {
      baseParams.append('status', `${params.sharedMailboxStatus}`);
    }

    if (params.searchTerm) {
      baseParams.append('searchTerm', params.searchTerm);
    }

    if (params.onlineStatus) {
      baseParams.append('onlineStatus', params.onlineStatus);
    }

    if (params.personStatus) {
      baseParams.append('status', params.personStatus);
    }

    if (params.expiryStatus) {
      baseParams.append(
        'expiryStatus',
        params.expiryStatus.replace('Status', '')
      );
    }

    if (params.autoRenew) {
      baseParams.append('autoRenew', params.autoRenew);
    }

    if (params.renewalPeriod) {
      baseParams.append('renewalPeriod', params.renewalPeriod);
    }

    params.ipTypes?.map((item) => baseParams.append('IpTypes', item));

    params.countries?.map((item) => baseParams.append('Countries', item));

    params.eventTypes?.map((item) => baseParams.append('EventTypes', item));

    params.msTeamsHealthCollaboration?.map((item) =>
      baseParams.append('PoorHealthIndicators', item)
    );

    params.subscriptionNameList?.map((item) =>
      baseParams.append('SubscriptionNameList', item)
    );

    params.collaboration?.map((item) =>
      baseParams.append('NeverCollaborated', item)
    );

    params.userServiceAdoptionFilter?.map((item) =>
      baseParams.append('ServiceAdoption', item)
    );

    params.externalTeamsCollaboration?.map((item) =>
      baseParams.append('HasntCollaboratingDaysFilter', item)
    );

    params.activity?.map((item) => baseParams.append('LastActivity', item));

    params.category?.map((cat) => baseParams.append('CategoryList', cat));

    params.secureScoreStatus?.map((status) =>
      baseParams.append('IncludeCompleted', status)
    );

    params.meetingAdoptionFilter?.map((adoption) =>
      baseParams.append('HasntSetupJoinedMeeting', adoption)
    );

    params.licenseTypeList?.map((license) =>
      baseParams.append('licenseTypeList', license)
    );

    params.serviceAdoptionActivity?.map((group) =>
      baseParams.append('UserActivityIndicators', group)
    );

    params.publisherVerification?.map((item) =>
      baseParams.append('PublisherVerification', item)
    );

    params.permissionLevels?.map((item) =>
      baseParams.append('PermissionLevels', item)
    );

    params.groupList?.map((group) => baseParams.append('groupList', group));

    params.locationList?.map((location) =>
      baseParams.append('locationList', location)
    );

    params.activityType?.map((vendor) =>
      baseParams.append('ActivityType', vendor)
    );

    params.accountTypes?.map((accountType) =>
      baseParams.append('types', accountType)
    );

    params.vendorList?.map((vendor) => baseParams.append('vendorList', vendor));

    params.typeList?.forEach((type) => baseParams.append('typeList', type));

    params.locationList?.map((location) =>
      baseParams.append('locationList', location)
    );

    params.accountStatus?.map((status) => baseParams.append('status', status));

    params.mfaStatus?.map((status) => baseParams.append('mfaStatus', status));

    params.accountType?.map((accType) =>
      baseParams.append('accountType', accType)
    );

    params.OSType?.map((OSType) => baseParams.append('OSType', OSType));

    params.browserType?.map((item) => baseParams.append('browserType', item));

    params.TrunkNameList?.map((item) => baseParams.append('TrunkNameList', item));
    params.TrunkTypeList?.map((item) => baseParams.append('TrunkTypeList', item));
  }

  const queryString = baseParams.toString();

  const response = await httpClient.get(`${endpoint}?${queryString}`, {
    responseType: 'blob',
  });

  if (!response) {
    return new Blob([]);
  }
  return new Blob([response]);
};

const useDownloadCsv = ({
  shouldDownload,
  endpoint,
  params,
  filename,
}: UseDownloadCsvParams) => {
  const httpClient = useHttpClient();
  const { downloadType, setIsLoadingExportData } = useTableStore();

  const { refetch, isLoading } = useQuery<Blob>(
    ['download', endpoint, JSON.stringify(params)],
    () => fetchCsvFile({ httpClient, endpoint, params, downloadType }),
    {
      enabled: !!shouldDownload,
      onSuccess: (response) => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        window.URL.revokeObjectURL(url);
        setIsLoadingExportData(false);
      },
    }
  );

  return { refetch, isLoading };
};

export default useDownloadCsv;
