import React from 'react';
import { IconProps } from './types';

export const CheckCircleIcon: React.FC<IconProps> = ({
  size = '18',
  color = 'text-primaryBrandColor-100',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99998 16.4286C13.1027 16.4286 16.4286 13.1027 16.4286 8.99998C16.4286 4.8973 13.1027 1.57141 8.99998 1.57141C4.8973 1.57141 1.57141 4.8973 1.57141 8.99998C1.57141 13.1027 4.8973 16.4286 8.99998 16.4286Z"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99988 9.5L7.49988 11.5L11.9999 7"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
