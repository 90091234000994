import React from 'react';

import Tab, { Tab as TabType } from '../../../../components/ui/Tab';
import { OverlayPage } from '@components/partials';
import { useIsMobile } from '@hooks/utils';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';

const LicenseInventoryLayout: React.FC = () => {
  const isMobile = useIsMobile();
  const redirectPath = useNavSourceRedirect(
    '/insights-and-analytics/utilisation'
  );

  const tabs: TabType[] = [
    {
      path: '/insights-and-analytics/utilisation/license-inventory/subscriptions',
      label: 'Subscriptions',
    },
    {
      path: '/insights-and-analytics/utilisation/license-inventory/license-assignment',
      label: 'License assignment',
    },
  ];

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`License inventory`}
    >
      <div className="mt-[-37px] px-4 md:px-0">
        <Tab tabs={tabs} className={isMobile ? 'mb-[100px]' : 'mb-[70px]'} />
      </div>
    </OverlayPage>
  );
};

export default LicenseInventoryLayout;
