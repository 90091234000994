export const API_ENDPOINTS = {
  PERSONS: '/organization/persons',
  PERSONS_DOWNLOAD: '/organization/persons/download',
  PERSONS_UNASSIGNED_LOCATIONS: '/organization/persons/unassigned/locations',
  PERSONS_UNASSIGNED_GROUPS: '/organization/persons/unassigned/groups',
  PERSONS_UNASSIGNED_LOCATIONS_INFO:
    '/organization/persons/unassigned/locations/info',
  PERSONS_UNASSIGNED_GROUPS_INFO:
    '/organization/persons/unassigned/groups/info',
  LOCATIONS: '/organization/locations',
  ACCOUNT_TYPES: '/organization/accountTypes',
  GROUPS: '/organization/groups',
  GROUPS_FILTER: '/organization/groups/filters',
  PERSON_LICENSES: '/analytics/licenses/byuser',
  LICENSES_ACTIVITY: '/analytics/licenses/activity/snapshot',
  LICENSES_ASSIGNMENT: '/analytics/licenses/unassigned/snapshot',
  LICENSES_OVERASSIGNED: '/analytics/licenses/overassigned',
  LICENSES_OVERASSIGNED_SNAPSHOT: '/analytics/licenses/overassigned/snapshot',
  LICENSES_OVERASSIGNED_TREND: '/analytics/licenses/overassigned/trend',
  LICENSES_TREND: '/analytics/licenses/unassigned/trend',
  LICENSES_ACTIVITY_TREND: '/analytics/licenses/activity/trend',
  LICENSES_ASSIGNMENT_PREVIEW: '/analytics/licenses/unassigned',
  LICENSES_ACTIVITY_PREVIEW: '/analytics/licenses/activity',
  LICENSES_FILTERS: '/analytics/licenses/unassigned/filter',
  // ADOPTION & PRODUCTIVITY
  LICENSES_USAGE_TREND: '/analytics/licenseusage/usage/trend',
  LICENSES_USAGE_CALL_TIME: '/analytics/licenseusage/usage/snapshot/avg-time',
  LICENSES_USAGE_CORRESPODENCE:
    '/analytics/licenseusage/usage/snapshot/correspondence',
  LICENSES_USAGE_PREVIEW: '/analytics/licenseusage/usage',
  SERVICE_ADOPTION_SNAPSHOT: 'analytics/useractivity/user/snapshot/adoption',
  SERVICE_ADOPTION_BASE: 'analytics/useractivity/user/snapshot/',
  SERVICE_ADOPTION: 'analytics/useractivity/activity',
  TEAMS_GROUPS_ADOPTION_SNAPSHOT: 'analytics/teamadoption/in-team/snapshot',
  COLLAB_SNAPSHOT: 'analytics/teamadoption/collaborate/snapshot',
  TEAMS_GROUPS_ADOPTION: 'analytics/teamadoption',
  TEAMS_GROUPS_ADOPTION_TREND: 'analytics/teamadoption/trend',
  MS_TEAMS_HEALTH_SNAPSHOT: 'analytics/msteamshealth/snapshot',
  MEETING_ADOPTION_SNAPSHOT:
    'analytics/productivity/meetings-adoption/snapshot',
  DEVICE_ADOPTION_SNAPSHOT: 'analytics/hardwareinventory/inactive-snapshot',
  MEETING_ADOPTION: 'analytics/productivity/meetings-adoption/activity',
  MEETING_ADOPTION_TREND: 'analytics/productivity/meetings-adoption/trend',
  DEVICE_ADOPTION_PREVIEW: 'analytics/hardwareinventory/device-adoption',
  DEVICE_USAGE_SNAPSHOT: 'analytics/meetingrooms/device-usage/snapshot',
  DEVICES_FILTERS: '/analytics/hardwareinventory/filter',
  MS_TEAMS_HEALTH: 'analytics/msteamshealth',
  MS_MESSAGES_SENT: 'analytics/msteamshealth/snapshot/messages',
  MEETINGS_HELD: 'analytics/msteamshealth/snapshot/meetings',
  CALLS_MADE: 'analytics/productivity/calls/snapshot',
  EMAILS_SENT: 'analytics/productivity/emails/snapshot',
  MESSAGES_SENT: 'analytics/productivity/messages/snapshot',
  MEETINGS_SENT: 'analytics/productivity/meetings/snapshot',
  PEOPLE_ACTIVITY: 'analytics/productivity/activity',
  LOCATIONS_ACTIVITY: 'analytics/productivity/activity/locations',
  GROUPS_ACTIVITY: 'analytics/productivity/activity/groups',
  ACTIVITY_PER_DAY_PEOPLE_TREND: 'analytics/productivity/activity/trend',
  ACTIVITY_PER_DAY_LOCATIONS_TREND: 'analytics/productivity/activity/trend',
  ACTIVITY_PER_DAY_GROUPS_TREND: 'analytics/productivity/activity/trend',
  TOTAL_ACTIVITY_PEOPLE: 'analytics/productivity/activity/snapshot',
  TOTAL_ACTIVITY_LOCATIONS: 'analytics/productivity/activity/snapshot',
  TOTAL_ACTIVITY_GROUPS: 'analytics/productivity/activity/snapshot',
  LICENSES_EXPIRING_SNAPSHOT:
    '/analytics/subscriptions/expiring/snapshot/days-left',
  LICENSES_EXPIRED_SNAPSHOT_BAR_CHART:
    '/analytics/subscriptions/expired/snapshot/bar-chart',
  LICENSES_EXPIRED_SNAPSHOT: '/analytics/subscriptions/expired/snapshot/number',
  LICENSES_EXPIRING_SNAPSHOT_VENDOR:
    '/analytics/subscriptions/expiring/snapshot/vendor',
  LICENSES_EXPIRING_USER: '/analytics/subscriptions/expiring/user',
  LICENSES_EXPIRING_PREVIEW: '/analytics/subscriptions/expiring',
  LICENSES_EXPIRED_PREVIEW: '/analytics/subscriptions/expired',
  MFA_STATUS_SNAPSHOT: '/analytics/security/status/snapshot',
  MFA_STATUS_PREVIEW: '/analytics/security/status',
  SIGN_IN_ENABLED_SNAPSHOT: '/analytics/security/no-licenses/snapshot',
  SIGN_IN_ENABLED_TREND: '/analytics/security/no-licenses/trend',
  SIGN_IN_ENABLED_PREVIEW: '/analytics/security/no-licenses',
  SHARED_MAILBOXES_SNAPSHOT: '/analytics/security/shared-mailboxes/snapshot',
  SHARED_MAILBOXES_TREND: '/analytics/security/shared-mailboxes/trend',
  SHARED_MAILBOXES_PREVIEW: '/analytics/security/shared-mailboxes',
  USERS: '/organization/users',
  NOTIFICATIONS: '/organization/notifications',
  NOTIFICATIONS_PAGE: '/organization/notifications/page',
  CURRENT_USER: '/organization/users/me',
  RESET_USER_PASSWORD: '/organization/users/me/reset-password',
  SERVICE_INTEGRATIONS: '/organization/serviceintegrations',
  SERVICE_INTEGRATIONS_HELIX: '/organization/serviceintegrations/helix',
  SERVICE_INTERGRATION_MS: '/organization/serviceintegrations/microsoft',
  SERVICE_INTERGRATION_RC: '/organization/serviceintegrations/ringcentral',
  SERVICE_INTERGRATION_UC: '/organization/serviceintegrations/ucentric',
  SERVICE_INTERGRATION_STATUS: '/organization/serviceintegrations',
  REPORTS: '/organization/reports',
  REPORTS_FILTER: '/organization/reports/filters',
  // ONBOARDING
  USER_ONBOARDING_PROFILE: '/organization/useronboarding/get-user-profile',
  USER_ONBOARDING_MODAL: '/organization/useronboarding',
  UPDATE_USER_ONBOARDING_MODAL:
    '/organization/useronboarding/update-modal-status',
  UPDATE_USER_SELECT_PROFILE: '/organization/dashboards/onboarding-dashboard',

  // ANALYTICS - UTILISATION TAB
  LICENSE_INVENTORY: '/analytics/subscriptions/inventory/snapshot',
  LICENSE_INVENTORY_PREVIEW: '/analytics/subscriptions/inventory',
  LICENSE_INVENTORY_ASSIGNMENT_PREVIEW: '/analytics/licenses/inventory',
  DEVICE_INVENTORY_PREVIEW: '/analytics/hardwareinventory/inventory',
  HARDWARE_INVENTORY: '/analytics/hardwareinventory/snapshot',
  HARDWARE_INVENTORY_TYPES: '/analytics/hardwareinventory/types',
  SHARE_POINT_USAGE: '/analytics/sharepoint/snapshot',
  SHARE_POINT_ACTIVE_SITES: '/analytics/sharepoint/active',
  SHARE_POINT_TOTAL_SITES: '/analytics/sharepoint/total',
  SHARE_POINT_USAGE_TREND: '/analytics/sharepoint/usage/trend',
  SHARE_POINT_USAGE_PREVIEW: '/analytics/sharepoint/usage',
  LICENSES_INVENTORY_CHANGES: '/analytics/licenses/inventory/trend',

  // USER SERVICE ADOPTION
  USER_SERVICE_ADOPTION_SNAPSHOT:
    '/analytics/useractivity/user-service-adoption/snapshot',
  USER_SERVICE_ADOPTION_ACTIVITY:
    '/analytics/useractivity/user-service-adoption/activity',
  USER_SERVICE_ADOPTION_TREND:
    '/analytics/useractivity/user-service-adoption/trend',

  // ORG GROUPS ACTIVITY USER
  ORG_GROUPS_ACTIVITY_USER_SNAPSHOT:
    '/analytics/msteamshealth/org-groups-activity/snapshot',
  ORG_GROUPS_ACTIVITY_USER_TOP_TEAMS_SNAPSHOT:
    '/analytics/msteamshealth/org-groups-activity/top-teams/snapshot',
  ORG_GROUPS_ACTIVITY_USER_MESSAGES_SNAPSHOT:
    '/analytics/msteamshealth/org-groups-activity/snapshot/messages',
  ORG_GROUPS_ACTIVITY_USER_MEETINGS_SNAPSHOT:
    '/analytics/msteamshealth/org-groups-activity/snapshot/meetings',
  ORG_GROUPS_ACTIVITY_USER_PREVIEW:
    '/analytics/msteamshealth/org-groups-activity',

  // MEETING ROOMS
  MEETINGS_ROOMS_BOOKED_HOURS_SNAPSHOT:
    '/analytics/meetingrooms/booked-hours/snapshot',
  MEETINGS_INACTIVE_ROOMS_SNAPSHOT: '/analytics/meetingrooms/inactive/snapshot',
  MEETING_ROOM_VIDEO_SNAPSHOT: '/analytics/meetingrooms/video/snapshot',
  MEETING_ROOMS: '/analytics/meetingrooms/rooms',
  MEETING_ROOMS_BOOKED_HOURS_TREND:
    '/analytics/meetingrooms/booked-hours/trend',
  MEETING_ROOMS_PREVIEW: '/analytics/meetingrooms',

  // EXTERNAL TEAMS ACCESS
  EXTERNAL_USERS_ACCESS_SNAPSHOT:
    'analytics/userassociatedteams/external-users/snapshot',
  EXTERNAL_TEAMS_ACCESS_SNAPSHOT:
    'analytics/userassociatedteams/external-teams/snapshot',
  EXTERNAL_TEAMS_ACCESS_PREVIEW: 'analytics/userassociatedteams/external-users',
  EXTERNAL_TEAMS_ACCESS_DETAILS_PREVIEW: 'analytics/msteamshealth',

  // SUBSCRIPTION SETTINGS
  SUBSCRIPTION_SETTINGS: '/organization/notificationsubscriptions/me',

  // GROUPS & TEAMS
  TEAMS_GROUPS_SNAPSHOT:
    '/analytics/msteamshealth/groups-teams-inventory/snapshot',

  // DASHBOARD TILES
  DASHBOARDS: '/organization/dashboards',

  TERMS_CONDITIONS_AGREE: '/organization/termsandconditions/agree',
  TERMS_CONDITIONS_HAS_GRANTED:
    '/organization/termsandconditions/has-been-agreed',

  // MS SECURE SCORE
  MS_SECURE_SCORE: '/analytics/securescore',
  MS_SECURE_SCORE_SNAPSHOT: '/analytics/securescore/category',
  SECURE_SCORE_TREND: '/analytics/securescore/trend',
  SECURE_SCORE_PREVIEW: '/analytics/securescore/remediation-actions',
  SECURE_SCORE_COMPARISON: '/analytics/securescore/comparison',
  MS_SECURE_SCORE_ACTION: '/analytics/securescore/remediation-action',

  // MEETING ROOMS DEVICES
  MEETING_ROOMS_DEVICES:
    '/analytics/hardwareinventory/meeting-room-device-types',
  MR_MEETING_ROOMS_DEVICES: '/analytics/meetingrooms/mr-device-usage/snapshot',
  MEETING_ROOMS_DEVICES_SNAPSHOT:
    'analytics/meetingrooms/device-usage/snapshot',

  //TEAMS AND GROUPS ACTIVITY
  TEAMS_AND_GROUPS_ACTIVTY_SNAPSHOT: '/analytics/groupactivity/snapshot',
  TEAMS_AND_GROUPS_ACTIVTY_PREVIEW: '/analytics/groupactivity',

  // EXPORTS
  DEVICE_ADOPTION_EXPORT: '/analytics/hardwareinventory/device-adoption/export',
  HARDWARE_INVENTORY_EXPORT: '/analytics/hardwareinventory/inventory/export',
  LICENSES_ACTIVITY_EXPORT: '/analytics/licenses/activity/export',

  // FILTERS
  HARDWARE_INVENTORY_FILTER: '/analytics/hardwareinventory/inventory/filter',

  // SUSPICIOUS ACTIVITY
  SUSPICIOUS_ACTIVITY_TREND: '/analytics/security/suspicious-events/trend',
  SUSPICIOUS_ACTIVITY_TOTAL_EVENT:
    '/analytics/security/suspicious-events/activity',
  SUSPICIOUS_ACTIVITY_SOURCE_COUNTRY:
    '/analytics/security/suspicious-events/snapshot',
  SUSPICIOUS_ACTIVITY_EVENTS_PAGE: '/analytics/security/suspicious-events/page',
  SUSPICIOUS_ACTIVITY_EVENTS_EXPORT:
    '/analytics/security/suspicious-events/export',
  SUSPICIOUS_ACTIVITY_EVENTS_FILTER:
    '/analytics/security/suspicious-events/filter',

  SUSPICIOUS_ACTIVITY_ACCOUNTS_PAGE:
    '/analytics/security/suspicious-events/page/accounts',
  SUSPICIOUS_ACTIVITY_ACCOUNTS_EXPORT:
    '/analytics/security/suspicious-events/export/accounts',
  SUSPICIOUS_ACTIVITY_ACCOUNTS_FILTER:
    '/analytics/security/suspicious-events/filter/accounts',
  SUSPICIOUS_ACTIVITY_COUNTRIES: '/analytics/security/suspicious-events/map',
  COUNTRIES_FILTER:
    '/analytics/security/suspicious-events/filter/countries',

  // EXTERNAL APPLICATIONS
  EXTERNAL_APPLICATIONS_ACTIVITY:
    '/analytics/security/external-applications/activity',
  EXTERNAL_APPLICATIONS_PUBLISHER_VERIFICATION:
    '/analytics/security/external-applications/publisher-verification',
  EXTERNAL_APPLICATIONS_ACCOUNT_LEVEL:
    '/analytics/security/external-applications/snapshot/account-level',
  EXTERNAL_APPLICATIONS_TENANT_WIDE:
    '/analytics/security/external-applications/snapshot/tenant-wide',
  EXTERNAL_APPLICATIONS_PAGE: '/analytics/security/external-applications/page',
  EXTERNAL_APPLICATIONS_TENANT_WIDE_PAGE:
    '/analytics/security/external-applications/page/tenant-wide',
  EXTERNAL_APPLICATIONS_EXPORT:
    '/analytics/security/external-applications/export',
  EXTERNAL_APPLICATIONS_FILTER:
    '/analytics/security/external-applications/filter',
  EXTERNAL_APPLICATIONS_TENANTWIDE_EXPORT:
    '/analytics/security/external-applications/export/tenant-wide',
  // FAILED LOGIN DATA
  FAILED_LOGIN_DATA_COUNTRIES_PREVIEW:
    '/analytics/security/login-attempt/countries/page',
  FAILED_LOGIN_DATA_COUNTRIES_SNAPSHOT:
    '/analytics/security/login-attempt/countries/snapshot',
  FAILED_LOGIN_DATA_COUNTRIES_EXPORT:
    '/analytics/security/login-attempt/countries/export',
  FAILED_LOGIN_DATA_ATTEMPTS_MAP:
    '/analytics/security/login-attempt/countries/map',
  FAILED_LOGIN_TYPES_PREVIEW: '/analytics/security/login-attempt/types/page',
  FAILED_LOGIN_TYPES_SNAPSHOT:
    '/analytics/security/login-attempt/types/snapshot',
  FAILED_LOGIN_TYPES_EXPORT: '/analytics/security/login-attempt/types/export',
  FAILED_LOGIN_ACCOUNTS_PREVIEW:
    '/analytics/security/login-attempt/accounts/page',
  FAILED_LOGIN_ACCOUNTS_SNAPSHOT:
    '/analytics/security/login-attempt/accounts/snapshot',
  FAILED_LOGIN_ACCOUNTS_EXPORT:
    '/analytics/security/login-attempt/accounts/export',
  FAILED_LOGIN_ACCOUNTS_FILTER:
    '/analytics/security/login-attempt/accounts/filter',

  // MAILBOX FORWARDING RULES
  MAILBOX_FORWARDING_RULE_STATUS:
    '/analytics/security/mailbox-forwarding-rules/snapshot/rule-status',
  MAILBOX_FORWARDING_ACCOUNT_TYPE:
    '/analytics/security/mailbox-forwarding-rules/snapshot/account-type',
  MAILBOX_FORWARDING_PREVIEW:
    '/analytics/security/mailbox-forwarding-rules/page',
  MAILBOX_FORWARDING_EXPORT:
    '/analytics/security/mailbox-forwarding-rules/export',
  //outdated OS
  OUTDATED_OS_USER_TYPES_SNAPSHOT:
    '/analytics/security/outdated-operating-systems/snapshot/user-types',
  OUTDATED_OS_ACTIVITY_SNAPSHOT:
    '/analytics/security/outdated-operating-systems/activity',
  OUTDATED_OS_SNAPSHOT:
    '/analytics/security/outdated-operating-systems/snapshot/operating-systems',
  OUTDATED_OS_ACCOUNTS_PREVIEW:
    '/analytics/security/outdated-operating-systems/page',
  OUTDATED_OS_DATA_PREVIEW:
    '/analytics/security/outdated-operating-systems/page/operating-systems',
  OUTDATED_OS_FILTER: '/analytics/security/outdated-operating-systems/filter',
  OUTDATED_OS_ACCOUNTS_EXPORT:
    '/analytics/security/outdated-operating-systems/export',
  OUTDATED_OS_DATA_EXPORT:
    '/analytics/security/outdated-operating-systems/export/operating-systems',
  // OUTDATED BROWSERS
  OUTDATED_BROWSERS_ACTIVITY: '/analytics/security/outdated-browsers/activity',
  OUTDATED_BROWSERS_FILTERS: '/analytics/security/outdated-browsers/filter',
  OUTDATED_BROWSER_ACCOUNTSS_EXPORT:
    '/analytics/security/outdated-browsers/export',
  OUTDATED_BROWSER_ACCOUNTS_PREVIEW:
    '/analytics/security/outdated-browsers/page',
  OUTDATED_BROWSERS_SNAPSHOT:
    '/analytics/security/outdated-browsers/snapshot/browsers',
  OUTDATED_BROWSERS_USER_TYPES:
    '/analytics/security/outdated-browsers/snapshot/user-types',
  OUTDATED_BROWSERS_EXPORT:
    '/analytics/security/outdated-browsers/export/browsers',
  OUTDATED_BROWSERS_PREVIEW:
    '/analytics/security/outdated-browsers/page/browsers',
  // DELEGATED MAILBOX
  DELEGATED_MAILBOX_LEADERBOARD: '/analytics/security/mailbox-delegation-accesses/leaderboard',
  DELEGATED_MAILBOX_ACCOUNTS_SNAPSHOT:
    '/analytics/security/mailbox-delegation-accesses/snapshot/accounts',
  DELEGATED_MAILBOX_SNAPSHOT:
    '/analytics/security/mailbox-delegation-accesses/snapshot/mailboxes',
  DELEGATES_FILTER: '/analytics/security/mailbox-delegation-accesses/filter',
  DELEGATES_EXPORT: '/analytics/security/mailbox-delegation-accesses/export',
  DELEGATES_PREVIEW: '/analytics/security/mailbox-delegation-accesses/page',
  DELEGATED_MAILBOX_EXPORT: '/analytics/security/mailbox-delegation-accesses/export/mailbox',
  DELEGATED_MAILBOX_PAGE: '/analytics/security/mailbox-delegation-accesses/page/mailbox',
  // CALL FORWARDING DESTINATIONS
  CALL_FORWARDING_DESTINATIONS_SNAPSHOT:'/analytics/callforwarding/snapshot',
  CALL_FORWARDING_DESTINATIONS_FILTER: '/analytics/callforwarding/filter',
  CALL_FORWARDING_DESTINATIONS_EXPORT: '/analytics/callforwarding/export',
  CALL_FORWARDING_DESTINATIONS_PREVIEW: '/analytics/callforwarding/page',
  // CALL QUEUES
  CALL_QUEUES_FILTER: '/analytics/callqueues/filter',
  CALL_QUEUES_EXPORT: '/analytics/callqueues/export',
  CALL_QUEUES_PREVIEW: '/analytics/callqueues/page',
  CALL_QUEUES_SNAPSHOT: '/analytics/callqueues/snapshot',
  EMPTY_CALL_QUEUES_SNAPSHOT: '/analytics/callqueues/snapshot/empty-call-queues',
  // GENERATED TRUNKS
  GENERATED_TRUNKS_SNAPSHOT: '/analytics/trunks/snapshot',
  GENERATED_TRUNKS_PAGE: '/analytics/trunks/page',
  GENERATED_TRUNKS_FILTERS: '/analytics/trunks/filter',
  GENERATED_TRUNKS_EXPORT: '/analytics/trunks/export',
};
