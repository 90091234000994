import { OverlayPage } from '@components/partials/OverlayPage';
import {
  DownloadIcon,
  Loader,
  CalendarIcon,
  ArrowLongRightIcon,
} from '@components/ui';
import {
  useReportPdfUrlPreviewQuery,
  useReportPreviewQuery,
} from '@hooks/reports';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './styles.module.css';
import {
  ChartComponent,
  ReportChartData,
  ReportSelectedVendors,
} from '@hooks/reports/types';
import {} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { hasPermission, formatDateInUTC } from '@utils/index';
import { UserRoles, Permissions } from '@hooks/users/types';
import useAuthStore from '@store/authStore';
import RenderChartTile from './RenderChartTile';
import axios from 'axios';
import dayjs from 'dayjs';
import useUIStore from '@store/uiStore';
import { Variant } from '@components/ui/Toast';

export const ReportPreviewPage: React.FC = () => {
  const [selectedVendors, setSelectedVendors] = useState<ReportSelectedVendors>(
    {}
  );
  const [pdfUrl, setPdfUrl] = useState('');

  let chartData: ReportChartData | null = null;

  const { reportId } = useParams<{ reportId: string }>();

  const query = useReportPreviewQuery({
    reportId: reportId,
  });

  const { mutateAsync, isLoading: isPdfUrlLoading } =
    useReportPdfUrlPreviewQuery(reportId);

  if (query.isFetched) {
    if (query.data?.jsonReportData) {
      chartData = JSON.parse(query.data?.jsonReportData);
    }
  }

  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;

  const downloadHasPermission = hasPermission(
    userRole,
    Permissions.ADMINS_SUPPORTUSER
  );

  useEffect(() => {
    getPdfUrl();
  }, []);

  const { addToast, setSidebar } = useUIStore();

  useEffect(() => {
    if (chartData) {
      const initialVendors: ReportSelectedVendors = {};
      chartData.components.forEach((component: ChartComponent) => {
        initialVendors[component.type] = 'All';
      });
      setSelectedVendors(initialVendors);
    }
  }, [query.isFetched]);

  const getPdfUrl = async () => {
    if (downloadHasPermission) {
      const res = await mutateAsync();
      setPdfUrl(res as string);
    }
  };

  const downloadFile = async () => {
    try {
      const date = query.data?.name.substring(0, 11);
      const reportName = query.data?.name.substring(12).trim() ?? 'report';

      const name = reportName
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join('');

      const response = await axios({
        url: pdfUrl,
        method: 'GET',
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Clevr360_${name}_${dayjs(date, 'MMM DD YYYY').format(
          'DD-MMM-YYYY'
        )}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: `Failed to download the file: ${error}`,
      });
    }
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      path="/reports"
      headerTitle={query.data?.name || ''}
      isLoading={query.isLoading}
    >
      {query.isLoading ? (
        <Loader />
      ) : (
        <>
          {chartData?.fromDate !== undefined &&
            chartData?.toDate !== undefined && (
              <h4
                className="mb-4 flex items-center gap-x-1"
                style={{
                  fontSize: '16px',
                  breakInside: 'avoid',
                }}
              >
                <CalendarIcon
                  color="text-interfaceColor-100"
                  size="6"
                  classNames="ml-1"
                />
                {formatDateInUTC(chartData?.fromDate)}
                <ArrowLongRightIcon color="text-interfaceColor-100" size="5" />
                {formatDateInUTC(chartData?.toDate)}
              </h4>
            )}
          {downloadHasPermission && !isPdfUrlLoading && (
            <button
              className="inline-flex flex-shrink-0 items-center justify-center rounded-[24px] bg-interfaceColor-90 px-[16px] py-[10px] text-16 font-normal leading-6 tracking-[0.01em] text-white transition duration-300 ease-in-out hover:bg-interfaceColor-150 hover:text-interfaceColor-10 focus:shadow-focus disabled:bg-interfaceColor-20 disabled:text-interfaceColor-80"
              onClick={() => downloadFile()}
            >
              {' '}
              <DownloadIcon
                classNames="mr-2"
                color="text-primaryBrandColor-150"
              />
              Download report
            </button>
          )}

          {query.isFetched && chartData && !query.isLoading && (
            <div className={styles.pageGrid}>
              {chartData.components.map((item) => {
                return (
                  <RenderChartTile
                    key={item.type}
                    item={item}
                    selectedVendor={selectedVendors}
                  />
                );
              })}
            </div>
          )}
        </>
      )}
    </OverlayPage>
  );
};
