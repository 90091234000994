import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import {
  useAddDashboardTile,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useExternalAppsMSDataQuery } from '@hooks/security';

import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';

export const ExternalAppsMSData: React.FC<TilesProps> = ({
  id,
  classNames,
  isSnapshot,
}) => {
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.ExternalAppsMSData,
    ''
  );
  const isOnDashboardPage = !!id;

  // data hook
  const { data, isLoading } = useExternalAppsMSDataQuery();

  return (
    <NumberTile
      dropdownMenuItemClasses={'!w-[300px]'}
      sortData={[]}
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      contextData={!isSnapshot ? 
        (isOnDashboardPage ? removeMenuItem : addMenuItem) : []
      }
      headerTitle={'External apps with access to MS data'}
      isLoading={isLoading}
      data={data ? [data.totalCount || 0, data.percentage] : []}
      buttonLabel={!isSnapshot ? 'Remote access data' : ''}
      linkPath={ANALYTICS_NAVIGATION_MAP['remoteAccessData']}
      handleButtonClick={!isSnapshot ? handleButtonClick() : undefined}
      percentageLabel=""
    />
  );
};
