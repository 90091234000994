import Map from '@components/ui/Map';

import { useFailedLoginAttempts } from '@hooks/security';
import useTransformData from '@hooks/utils/transformData';

import React from 'react';
import { useLocation } from 'react-router-dom';

const FailedLoginMapPage: React.FC = () => {
  const days = new URLSearchParams(useLocation().search).get('days') || '30';  
  
  const { data: suspiciousActivityEventsData } = useFailedLoginAttempts({
    days,
  });

  const { data, legendRanges } = useTransformData(
    suspiciousActivityEventsData ?? []
  );

  return (
    <div className="relative mt-2">
      <Map
        actionPath={
          '/insights-and-analytics/assurance/failed-login-data/countries?days=' +days
        }
        rawData={data}
        label={'events'}
        legendRanges={legendRanges}
      />
    </div>
  );
};

export default FailedLoginMapPage;
