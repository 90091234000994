import React from 'react';

import Tab, { Tab as TabType } from '../../components/ui/Tab';
import { WelcomeModal } from '@components/partials/WelcomeModal';
import useAuthStore from '@store/authStore';
import { UserRoles, Permissions } from '@hooks/users/types';
import { hasPermission } from '@utils/index';

const InsightsAndAnalyticsLayout: React.FC = () => {
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;

  const securityTabHasPermission = hasPermission(
    userRole,
    Permissions.ADMINS_SUPPORTUSER
  );

  const tabs: TabType[] = [
    { path: '/insights-and-analytics/utilisation', label: 'Utilisation' },
    { path: '/insights-and-analytics/adoption', label: 'Adoption' },
    {
      path: '/insights-and-analytics/productivity',
      label: 'Productivity',
    },
  ];

  // if (securityTabHasPermission) {
  tabs.push({
    path: '/insights-and-analytics/assurance',
    label: 'Assurance',
  });
  // }

  return (
    <div>
      <Tab tabs={tabs} />
      {/* <WelcomeModal
        content={
          <>
            <p className="mt-2 mb-5">
              In Insights & analytics, find the information you need to make the
              right decisions for your business.
              <br /><br />
              See key insights and drill into the data behind them, or dive
              straight into complete, downloadable data around utilisation,
              adoption, productivity, or security.
            </p>
          </>
        }
        btnText="Got it"
        modalKey="InsightsAnalyticsModal"
        title={
          <span>
            Everything you need to <br /> know, at your fingertips
          </span>
        }
      /> */}
    </div>
  );
};

export default InsightsAndAnalyticsLayout;
