import * as React from 'react';
import { IconProps } from './types';

export const UnlockIcon: React.FC<IconProps> = ({
  color = 'text-primaryBrandColor-100',
  classNames = '',
  size = 4,
}) => {
  return (
    <svg
      className={`stroke-current w-${size} h-${size} ${color} ${classNames}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 16"
      fill="none"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
        d="M11.57 6.287H2.429A1.143 1.143 0 0 0 1.285 7.43v6.857a1.143 1.143 0 0 0 1.143 1.143h9.143a1.143 1.143 0 0 0 1.143-1.143V7.43a1.143 1.143 0 0 0-1.143-1.143Z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
        d="M9.823 1.75A3.932 3.932 0 0 0 7 .571a4 4 0 0 0-4 4v1.715M7 11.43a.571.571 0 1 0 0-1.143.571.571 0 0 0 0 1.143Z"
      />
    </svg>
  );
};
