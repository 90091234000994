import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import { useMeetingsRoomBookedHoursSnapshot } from '@hooks/adoption';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { TableTile } from '@pages/InsightsAndAnalytics/tiles/TableTile';
import { convertMinutesToHours } from '@utils/index';
import { useState } from 'react';

type Params = {
  days: string;
  dataType: string;
};

export const MeetingRoomsHoursBooked: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.MeetingRoomsHoursBooked,
    parameters
  );
  const isOnDashboardPage = !!id;
  // data hook
  const { data, isLoading, refetch } =
    useMeetingsRoomBookedHoursSnapshot(parameters);

  const compareMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleCompareDropdown('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleCompareDropdown('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleCompareDropdown('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleCompareDropdown('90'),
    },
  ];

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Top 3',
      value: 'top',
      action: () => handleDropdownChange('top'),
    },
    {
      id: '2',
      label: 'Bottom 3',
      value: 'bottom',
      action: () => handleDropdownChange('bottom'),
    },
  ];

  const handleDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      dataType: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const handleCompareDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const formattedData = data?.roomHoursBooked.map((item) => ({
    ...item,
    roomBookedMinutes: convertMinutesToHours(item.roomBookedMinutes),
  }));

  return (
    <TableTile
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      sortData={sortMenuItems}
      compareData={compareMenuItems}
      columnOrder={['roomName', 'roomBookedMinutes', 'percentage']}
      initialSelectedItem={sortMenuItems.find(
        (item) => item.value === parameters.dataType
      )}
      initialCompareSelectedItem={compareMenuItems.find(
        (item) => item.value === parameters.days
      )}
      headerTitle={'Rooms: hours booked'}
      dropdownMenuItemClasses={'!w-[300px]'}
      isLoading={isLoading}
      data={formattedData || []}
      buttonLabel={'Meeting rooms data'}
      linkPath={ANALYTICS_NAVIGATION_MAP['meetingsRoom']}
      handleButtonClick={handleButtonClick()}
    />
  );
};
