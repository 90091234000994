import {
  DEFAULT_PAGE_SIZE,
  GROUPS_TEAMS_SORT_BY,
  GROUPS_TEAMS_TABLE_COLUMNS,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { OverlayPage, TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { PeopleSortBy } from '@hooks/people/types';
import { usePagination } from '@hooks/utils/pagination';
import { useMsTeamsHealthPreviewQuery } from '@hooks/adoption';
import { useIsDesktop } from '@hooks/utils';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { GroupsTeamsInventorySnapshot } from '@pages/Dashboard/tiles/GroupsTeamsInventorySnapshot';
import { formatDate, useTableData } from '@utils/index';
import React, { useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import styles from './styles.module.css';
import { createCellValue } from './utils';
import { MSTeamsAdoption } from '@pages/Dashboard/tiles';
import { MSTeamsCollaboration } from '@pages/Dashboard/tiles/MSTeamsCollaboration';
import { RecommendationCard } from '@pages/Dashboard/tiles/helpers/RecommendationCard';
import useDownloadCsv from '@hooks/utils/export';
import { API_ENDPOINTS } from '@api/ApiEndpoints';

const pageSize = DEFAULT_PAGE_SIZE;

const GroupsTeamsPage: React.FC = () => {
  const [search, setSearch] = useState('');
  const isDesktop = useIsDesktop();
  const { pageNumber, setPageNumber } = usePagination();
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
    filter: withDefault(StringParam, ''),
  });
  const tableDays = '30';

  const redirectPath = useNavSourceRedirect(
    '/insights-and-analytics/utilisation'
  );
  // filters data
  const transformedFilterData: FilterData[] = [];
  const FILTERS = ['NoMembers', 'NoOwners'];

  transformedFilterData.push({
    label: 'Poor health indicators',
    name: 'collaboration',
    options: [
      { label: 'No members', value: 'NoMembers' },
      {
        label: 'No owners',
        value: 'NoOwners',
      },
    ],
  });

  // table
  const query = useMsTeamsHealthPreviewQuery({
    days: tableDays,
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    msTeamsHealthCollaboration: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => FILTERS.includes(filter))
      : [],
  });
  const getTableData = useTableData(
    query,
    GROUPS_TEAMS_TABLE_COLUMNS,
    createCellValue
  );

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.MS_TEAMS_HEALTH}/export`,
    params: {
      days: tableDays,
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      msTeamsHealthCollaboration: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => FILTERS.includes(filter))
        : [],
    },
    filename: `Clevr360_Groups_&_Teams_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Groups & Teams`}
    >
      <div className={styles.pageGrid}>
        {isDesktop && (
          <>
            <GroupsTeamsInventorySnapshot
              params={JSON.stringify({ vendor: '' })}
              classNames="col-span-3 xl:col-auto"
            />

            <MSTeamsAdoption
              params={JSON.stringify({ days: '30' })}
              classNames="col-span-3 xl:col-auto"
            />

            <MSTeamsCollaboration
              params={JSON.stringify({ days: '30' })}
              classNames="col-span-3 xl:col-auto"
            />

            <RecommendationCard insightDescription={'Groups & Teams'} />
          </>
        )}
      </div>

      <TableWrapper
        search={search}
        setSearch={setSearch}
        searchPlaceholder={'Search by team name or owner'}
        columns={GROUPS_TEAMS_TABLE_COLUMNS}
        data={getTableData()}
        sortData={GROUPS_TEAMS_SORT_BY}
        filterData={transformedFilterData}
        searchKey="search"
        query={query}
        refetchQuery={query.refetch}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        setPageNumber={setPageNumber}
        isCollapsable={true}
        sliceColumns={1}
        floatingFilterButton={true}
        hasFilterBanner={false}
        isLicensePage={true}
        searchCountStatsLabel="teams"
        filterHeaderText="Filter Groups & Teams"
        isDownloadAvailable={true}
        handleDownload={handleDownloadClick}
      />
    </OverlayPage>
  );
};

export default GroupsTeamsPage;
