import React from 'react';
import Tab, { Tab as TabType } from '../../../../components/ui/Tab';
import { useIsDesktop, useIsMobile } from '@hooks/utils';
import { OverlayPage } from '@components/partials';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { ExternalAppsMSData,  } from '@pages/Dashboard/tiles';
import styles from './styles.module.css';
import { PublisherVerification } from '@pages/Dashboard/tiles/PublisherVerification';
import { AccessIntrusivenessAppsAccountLevel } from '@pages/Dashboard/tiles/AccessIntrusivenessAppsAccountLevel';
import { AccessIntrusivenessAppsTenantLevel } from '@pages/Dashboard/tiles/AccessIntrusivenessAppsTenantLevel';

const RemoteAccessDataLayout: React.FC = () => {
  const isMobile = useIsMobile();
  const redirectPath = useNavSourceRedirect(
    '/insights-and-analytics/assurance'
  );
  const isDesktop = useIsDesktop();

  const tabs: TabType[] = [
    {
      path: '/insights-and-analytics/assurance/remote-access-data/accounts',
      label: 'Account level',
    },
    {
      path: '/insights-and-analytics/assurance/remote-access-data/apps',
      label: 'Tenantwide',
    },
  ];


  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Remote access data`}
    >
      <div className={styles.pageGrid}>
        {isDesktop && (
          <>
            <ExternalAppsMSData
              params={''}
              isSnapshot={true}
              classNames="col-span-3 lg:col-auto"
            />
            <PublisherVerification params={''} />
            <AccessIntrusivenessAppsAccountLevel params={''} />
            <AccessIntrusivenessAppsTenantLevel params={''} />
          </>
        )}
      </div>
      <div className="mt-[5px]">
        <Tab tabs={tabs} className={isMobile ? 'mb-[100px]' : 'mb-[0px]'} />
      </div>
    </OverlayPage>
  );
};

export default RemoteAccessDataLayout;
