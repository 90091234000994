import {
  DEFAULT_PAGE_SIZE,
  MAILBOX_FORWARDING_TABLE_COLUMNS,
  MAILBOX_FORWARDING_SORT_BY,
} from '@common/constants';
import { ExtendedOption, IOption, OrderBy } from '@common/types';
import { OverlayPage, TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { PeopleSortBy } from '@hooks/people/types';
import { useIsDesktop } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';

import { formatDate, useTableData } from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import styles from './styles.module.css';

import { useMailboxForwardingDataPreviewQuery } from '@hooks/security';
import { Location } from '@hooks/locations/types';
import { createCellValue } from './utils';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import useDownloadCsv from '@hooks/utils/export';
import { MailboxesForwardingRules } from '@pages/Dashboard/tiles';
import { MailboxesForwardingAccountType } from '@pages/Dashboard/tiles/MailboxesForwardingAccountType';
import { GroupsResponse } from '@hooks/groups/types';
import { useGroupsFilterQuery } from '@hooks/groups';
import { useLocationsQuery } from '@hooks/locations';

const pageSize = DEFAULT_PAGE_SIZE;

const MailboxForwarding: React.FC = () => {
  const isDesktop = useIsDesktop();
  const [search, setSearch] = useState('');
  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const { pageNumber, setPageNumber } = usePagination();
  const redirectPath = useNavSourceRedirect(
    '/insights-and-analytics/assurance'
  );
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, 'account'),
    order: withDefault(StringParam, 'asc'),
    filter: withDefault(StringParam, ''),
  });

  const transformedFilterData: FilterData[] = [];

  const FILTERS = ['internal', 'external'];

  transformedFilterData.push({
    label: 'Forward account type',
    name: 'accountType',
    singleSelect: true,
    options: [
      { label: 'Internal', value: 'internal' },
      { label: 'External', value: 'external' },
    ],
  });

  const { data: locationsData, isFetched: locationDataFetched } =
    useLocationsQuery();

  const { data: groupsData, isFetched: groupsDataFetched } =
    useGroupsFilterQuery();

  const allLocationIds: string[] = [];
  const groupsIDs: string[] = [];
  const locationFilerOptions: IOption[] = [];
  const groupFilerOptions: ExtendedOption[] = [];

  if (locationDataFetched && groupsDataFetched) {
    if (locationsData) {
      locationsData?.forEach((location: Location) => {
        locationFilerOptions.push({ label: location.name, value: location.id });
        allLocationIds.push(location.id);
      });

      transformedFilterData.push({
        label: 'Location',
        name: 'location',
        singleSelect: false,
        options: locationFilerOptions,
      });
    }

    if (groupsData) {
      groupsData?.forEach((group: GroupsResponse) => {
        groupFilerOptions.push({
          name: group.name,
          value: group.id,
          parentId: group.parentId,
          subGroups: group.subGroups,
          id: group.id,
          label: group.name,
        });
      });

      transformedFilterData.push({
        label: 'Groups',
        name: 'groups',
        singleSelect: false,
        options: groupFilerOptions,
      });
    }
  }

  useEffect(() => {
    locationsData?.forEach((location: Location) => {
      locationFilerOptions.push({ label: location.name, value: location.id });
      allLocationIds.push(location.id);
    });
    setLocationIds(allLocationIds);
  }, [locationsData]);

  useEffect(() => {
    const nested = (element: any) => {
      element.subGroups.forEach((subElement: any) => {
        groupsIDs.push(subElement.id);
        if (subElement.subGroups && subElement.subGroups.length > 0) {
          nested(subElement);
        }
      });
    };

    groupsData?.forEach((element: any) => {
      groupsIDs.push(element.id);
      if (element.subGroups && element.subGroups.length > 0) {
        nested(element);
      }
    });

    setGroupIds(groupsIDs);
  }, [groupsData]);

  // table
  const query = useMailboxForwardingDataPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    locationList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => locationIds.includes(filter))
      : [],
    groupList: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => groupIds.includes(filter))
      : [],
    accountType: queryParams.filter
      ? queryParams.filter
          .split(',')
          .filter((filter) => FILTERS.includes(filter))
      : [],
  });
  const getTableData = useTableData(
    query,
    MAILBOX_FORWARDING_TABLE_COLUMNS,
    createCellValue
  );

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: API_ENDPOINTS.MAILBOX_FORWARDING_EXPORT,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      accountType: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => FILTERS.includes(filter))
        : [],
      locationList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => locationIds.includes(filter))
        : [],
      groupList: queryParams.filter
        ? queryParams.filter
            .split(',')
            .filter((filter) => groupIds.includes(filter))
        : [],
    },
    filename: `Clevr360_Mailbox_forwarding_data_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Mailbox forwarding data`}
    >
      <>
        <div className={styles.pageGrid}>
          {isDesktop && (
            <>
              <MailboxesForwardingRules
                classNames="col-span-1"
                isSnapshot={true}
                params={''}
              />
              <MailboxesForwardingAccountType params={''} />
            </>
          )}
        </div>
        <div className="mt-2">
          <TableWrapper
            search={search}
            setSearch={setSearch}
            searchPlaceholder={'Search by account, forwarding address'}
            columns={MAILBOX_FORWARDING_TABLE_COLUMNS}
            data={getTableData()}
            sortData={MAILBOX_FORWARDING_SORT_BY}
            filterData={transformedFilterData}
            searchKey="search"
            query={query}
            refetchQuery={query.refetch}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setPageNumber={setPageNumber}
            isCollapsable={false}
            sliceColumns={1}
            isLicensePage={true}
            floatingFilterButton={false}
            hasFilterBanner={false}
            searchCountStatsLabel="auto-forwarding rules"
            filterHeaderText="Filter mailbox forwarding data"
            isDownloadAvailable={true}
            handleDownload={handleDownloadClick}
          />
        </div>
      </>
    </OverlayPage>
  );
};

export default MailboxForwarding;
