import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import {
  useCallsMadeSnapshotQuery,
  useEmailsSentSnapshotQuery,
  useMeetingsSentSnapshotQuery,
  useMessagesSentSnapshotQuery,
} from '@hooks/productivity';
import { UseActivitiesQueryOptions } from '@hooks/productivity/types';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { useState } from 'react';
import { UseQueryResult } from 'react-query';

type ServiceActivitiesTilesConf = Record<
  number,
  {
    title: string;
    btnLabel: string;
    fn: any;
  }
>;

type ServiceActivitiesHelperProps = TilesProps & {
  type: number;
};

type Params = {
  days: string;
};

type QueryFunction<T> = (
  options: UseActivitiesQueryOptions
) => UseQueryResult<T>;

export const ServiceActivitiesHelper: React.FC<
  ServiceActivitiesHelperProps
> = ({ params, id, type }) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(type, '');
  const isOnDashboardPage = !!id;

  const tilesConfig: ServiceActivitiesTilesConf = {
    [DashboardTiles.EmailsSent]: {
      title: 'Emails sent',
      btnLabel: 'Emails data',
      fn: useEmailsSentSnapshotQuery,
    },
    [DashboardTiles.MessagesSent]: {
      title: 'Messages sent',
      btnLabel: 'Messages data',
      fn: useMessagesSentSnapshotQuery,
    },
    [DashboardTiles.CallsMade]: {
      title: 'Calls made',
      btnLabel: 'Calls data',
      fn: useCallsMadeSnapshotQuery,
    },
    [DashboardTiles.MeetingsSent]: {
      title: 'Meetings joined',
      btnLabel: 'Meetings data',
      fn: useMeetingsSentSnapshotQuery,
    },
  };

  const {
    data: serviceActivityData,
    isLoading: isServiceActivityDataLoading,
    refetch,
  } = tilesConfig[type].fn(parameters);

  const handleServiceAdoptionDropdown = (value: string) => {
    const newParameters = {
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const createDropdownMenuItems = (): MenuItem[] => [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleServiceAdoptionDropdown('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleServiceAdoptionDropdown('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleServiceAdoptionDropdown('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleServiceAdoptionDropdown('90'),
    },
  ];

  return (
    <NumberTile
      dropdownMenuItemClasses={'!w-[300px]'}
      sortData={createDropdownMenuItems()}
      initialSelectedItem={createDropdownMenuItems().find(
        (item) => item.value === parameters.days
      )}
      classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
      percentageLabel=""
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      headerTitle={tilesConfig[type].title}
      isLoading={isServiceActivityDataLoading}
      data={serviceActivityData ? Object.values(serviceActivityData) : []}
      buttonLabel={tilesConfig[type].btnLabel}
      linkPath={ANALYTICS_NAVIGATION_MAP['activityData']}
      handleButtonClick={handleButtonClick()}
    />
  );
};
