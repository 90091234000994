import {
  CellLeftStatusPart,
  CellLeftStatusWithIconPart,
} from '@components/ui/Table/utils';
import { formatDate } from '@utils/index';

export const createCellValue = (property: string, data: any, index: number) => {
  const value = data[index][property];

  switch (property) {
    case 'status':
      return (
        <>
          <CellLeftStatusPart status={value} />
          <span>{value || ''}</span>
          <span className="block text-14 text-interfaceColor-80">
            {data[index]['expiryDate']
              ? `Expires ${formatDate(data[index]['expiryDate'])}`
              : 'No data available'}
          </span>
        </>
      );
    case 'autoRenew':
      return (
        <>
          <CellLeftStatusWithIconPart status={value} />
          <span>{value ? 'Active' : 'Inactive'}</span>
        </>
      );
      case 'vendor':
        return (
          <>
            <span>{value ?? 'No data available'}</span>
          </>
        );
  }
};
