
export const createCellValue = (
    property: string,
    data: any,
    index: number
) => {
    const value = data[index][property];

    switch (property) {
        case 'fullVersionName':
            return (
                <>
                    <span className="flex items-center">
                        {value ?? ""}
                    </span>
                </>
            );
        case 'totalLoginCount':
            return (
                <>
                    <span className="flex items-center">
                        {value ?? '0'}</span>
                </>
            );
        case 'externalUsersLoginCount':
            return (
                <>
                    <span className="flex items-center">
                        {value ?? '0'}</span>
                </>
            );
        case 'internalUsersLoginCount':
            return (
                <>
                    <span className="flex items-center">
                        {value ?? '0'}</span>
                </>
            );
        case 'default':
            return (
                <></>
            );
    }
};
