import { CellBottomPart } from '@components/ui/Table/utils';
import { formatDate, formatNumberWithSuffix } from '@utils/index';

export const createCellValue = (
  property: string,
  data: any,
  index: number,
) => {
  const value = data[index][property];

  switch (property) {
    case 'teamName':
      return (
        <>
          <span>{value}</span>
        </>
      );
    case 'status':
      return (
        <>
          <span
            className={`status -mt-[3px] align-middle status-${value}`}
          ></span>
          <span className="capitalize">{value}</span>
        </>
      );

    case 'createAt':
      return (
        <>
          <span>{formatDate(value)}</span>
        </>
      );
    case 'owners':
      return value ? (
        <>
          <span>{value[0].displayName}</span>
          <CellBottomPart data={value[0].email} />
        </>
      ) : (
        <>-</>
      );
    case 'messageCount':
      return (
        <>
          <span>{formatNumberWithSuffix(value)}</span>
        </>
      );
    case 'meetingCount':
      return (
        <>
          <span>{value}</span>
        </>
      );
  }
};
