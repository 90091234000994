import useUIStore, { NavSource } from '@store/uiStore';

export const useNavSourceRedirect = (defaultPath: string) => {
  const { navSource } = useUIStore();
  let path = defaultPath;

  if (
    navSource === NavSource.DashboardTile ||
    navSource === NavSource.DashboardNotification
  ) {
    path = '/';
  } else if (navSource === NavSource.NotificationPage) {
    path = '/notifications-list';
  } else {
    path = defaultPath;
  }

  return path;
};
