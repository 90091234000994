import { API_ENDPOINTS } from '@api/ApiEndpoints';
import { NumberSnapshotResponse } from '@common/types';
import { UseQueryResult, useQuery } from 'react-query';
import {
  ActivitiesPreviewQueryOptions,
  ActivityPerDayTrendResponse,
  GroupsActivityPreviewResponse,
  LocationsActivityPreviewResponse,
  PeopleActivityPreviewResponse,
  UseActivitiesQueryOptions,
} from './types';
import { useHttpClient } from '@hooks/utils/httpConfig';

export function useCallsMadeSnapshotQuery({
  days,
}: UseActivitiesQueryOptions = {}): UseQueryResult<NumberSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<NumberSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;

    const endpoint = `${API_ENDPOINTS.CALLS_MADE}?${new URLSearchParams(
      baseParams
    ).toString()}`;

    return httpClient.get<NumberSnapshotResponse>(endpoint);
  };

  return useQuery(['callsMade', days], fetchData);
}

export function useEmailsSentSnapshotQuery({
  days,
}: UseActivitiesQueryOptions = {}): UseQueryResult<NumberSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<NumberSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;

    const endpoint = `${API_ENDPOINTS.EMAILS_SENT}?${new URLSearchParams(
      baseParams
    ).toString()}`;

    return httpClient.get<NumberSnapshotResponse>(endpoint);
  };

  return useQuery(['emailsSent', days], fetchData);
}

export function useMessagesSentSnapshotQuery({
  days,
}: UseActivitiesQueryOptions = {}): UseQueryResult<NumberSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<NumberSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;

    const endpoint = `${API_ENDPOINTS.MESSAGES_SENT}?${new URLSearchParams(
      baseParams
    ).toString()}`;

    return httpClient.get<NumberSnapshotResponse>(endpoint);
  };

  return useQuery(['messagesSent', days], fetchData);
}

export function useMeetingsSentSnapshotQuery({
  days,
}: UseActivitiesQueryOptions = {}): UseQueryResult<NumberSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<NumberSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (days) baseParams.Days = days;

    const endpoint = `${API_ENDPOINTS.MEETINGS_SENT}?${new URLSearchParams(
      baseParams
    ).toString()}`;

    return httpClient.get<NumberSnapshotResponse>(endpoint);
  };

  return useQuery(['meetingsSentSent', days], fetchData);
}

export function useActivityPeoplePreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  locationList,
  groupList,
  days,
  type,
}: ActivitiesPreviewQueryOptions = {}): UseQueryResult<PeopleActivityPreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<PeopleActivityPreviewResponse> => {
    const baseParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.searchTerm = searchTerm;
    if (sortBy) baseParams.sortBy = sortBy;
    if (order) baseParams.order = order;
    if (days) baseParams.Days = days;

    const locationListParams = locationList
      ? locationList.map((location) => `LocationList=${location}`).join('&')
      : '';
    const groupListParams = groupList
      ? groupList.map((group) => `GroupList=${group}`).join('&')
      : '';

    const endpoint = `${API_ENDPOINTS.PEOPLE_ACTIVITY}?${new URLSearchParams(
      baseParams
    ).toString()}&${locationListParams}&${groupListParams}`;

    return httpClient.get<PeopleActivityPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'activityPeople',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      locationList,
      groupList,
      days,
      type,
    ],
    fetchData
  );
}

export function useActivityLocationsPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  days,
  type,
}: ActivitiesPreviewQueryOptions = {}): UseQueryResult<LocationsActivityPreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<LocationsActivityPreviewResponse> => {
    const baseParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.searchTerm = searchTerm;
    if (sortBy) baseParams.sortBy = sortBy;
    if (order) baseParams.order = order;
    if (days) baseParams.Days = days;

    const endpoint = `${API_ENDPOINTS.LOCATIONS_ACTIVITY}?${new URLSearchParams(
      baseParams
    ).toString()}`;

    return httpClient.get<LocationsActivityPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'activityLocations',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      days,
      type,
    ],
    fetchData
  );
}

export function useActivityGroupsPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  days,
  type,
}: ActivitiesPreviewQueryOptions = {}): UseQueryResult<GroupsActivityPreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<GroupsActivityPreviewResponse> => {
    const baseParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.searchTerm = searchTerm;
    if (sortBy) baseParams.sortBy = sortBy;
    if (order) baseParams.order = order;
    if (days) baseParams.Days = days;

    const endpoint = `${API_ENDPOINTS.GROUPS_ACTIVITY}?${new URLSearchParams(
      baseParams
    ).toString()}`;

    return httpClient.get<GroupsActivityPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'activityGroups',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      days,
      type,
    ],
    fetchData
  );
}

export function useActivityPerDayPeopleTrendQuery({
  activity,
  days,
}: UseActivitiesQueryOptions = {}): UseQueryResult<ActivityPerDayTrendResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<ActivityPerDayTrendResponse> => {
    const baseParams: Record<string, string> = {};

    if (activity) baseParams.ActivityType = activity;
    if (days) baseParams.Days = days;

    const endpoint = `${
      API_ENDPOINTS.ACTIVITY_PER_DAY_PEOPLE_TREND
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<ActivityPerDayTrendResponse>(endpoint);
  };

  return useQuery(['activityPerDayPeopleTrend', { activity, days }], fetchData);
}

export function useActivityPerDayLocationsTrendQuery({
  activity,
  days,
}: UseActivitiesQueryOptions = {}): UseQueryResult<ActivityPerDayTrendResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<ActivityPerDayTrendResponse> => {
    const baseParams: Record<string, string> = {};

    if (activity) baseParams.ActivityType = activity;
    if (days) baseParams.Days = days;

    const endpoint = `${
      API_ENDPOINTS.ACTIVITY_PER_DAY_LOCATIONS_TREND
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<ActivityPerDayTrendResponse>(endpoint);
  };

  return useQuery(
    ['activityPerDayLocationsTrend', { activity, days }],
    fetchData
  );
}

export function useActivityPerDayGroupsTrendQuery({
  activity,
  days,
}: UseActivitiesQueryOptions = {}): UseQueryResult<ActivityPerDayTrendResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<ActivityPerDayTrendResponse> => {
    const baseParams: Record<string, string> = {};

    if (activity) baseParams.ActivityType = activity;
    if (days) baseParams.Days = days;

    const endpoint = `${
      API_ENDPOINTS.ACTIVITY_PER_DAY_GROUPS_TREND
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<ActivityPerDayTrendResponse>(endpoint);
  };

  return useQuery(['activityPerDayGroupsTrend', { activity, days }], fetchData);
}

export function useTotalActivityPeopleSnapshotQuery({
  activity,
  days,
}: UseActivitiesQueryOptions = {}): UseQueryResult<NumberSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<NumberSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (activity) baseParams.ActivityType = activity;
    if (days) baseParams.Days = days;

    const endpoint = `${
      API_ENDPOINTS.TOTAL_ACTIVITY_PEOPLE
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<NumberSnapshotResponse>(endpoint);
  };

  return useQuery(['totalActivityPeople', activity, days], fetchSnapshot);
}

export function useTotalActivityLocationsSnapshotQuery({
  activity,
  days,
}: UseActivitiesQueryOptions = {}): UseQueryResult<NumberSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<NumberSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (activity) baseParams.ActivityType = activity;
    if (days) baseParams.Days = days;

    const endpoint = `${
      API_ENDPOINTS.TOTAL_ACTIVITY_LOCATIONS
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<NumberSnapshotResponse>(endpoint);
  };

  return useQuery(['totalActivityLocations', activity, days], fetchSnapshot);
}

export function useTotalActivityGroupsSnapshotQuery({
  activity,
  days,
}: UseActivitiesQueryOptions = {}): UseQueryResult<NumberSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<NumberSnapshotResponse> => {
    const baseParams: Record<string, string> = {};

    if (activity) baseParams.ActivityType = activity;
    if (days) baseParams.Days = days;

    const endpoint = `${
      API_ENDPOINTS.TOTAL_ACTIVITY_GROUPS
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<NumberSnapshotResponse>(endpoint);
  };

  return useQuery(['totalActivityGroups', activity, days], fetchSnapshot);
}

export function useActivityPerDayTrendQuery({
  activity,
  days,
}: UseActivitiesQueryOptions = {}): UseQueryResult<ActivityPerDayTrendResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<ActivityPerDayTrendResponse> => {
    const baseParams: Record<string, string> = {};

    if (activity) baseParams.ActivityType = activity;
    if (days) baseParams.Days = days;

    const endpoint = `${
      API_ENDPOINTS.ACTIVITY_PER_DAY_PEOPLE_TREND
    }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<ActivityPerDayTrendResponse>(endpoint);
  };

  return useQuery(['activityPerDayTrend', { activity, days }], fetchData);
}
