import { OverlayPage } from '@components/partials/OverlayPage';

import React, { useState } from 'react';

import { useNavSourceRedirect } from '@hooks/utils/dashboard';

import {
  Button,
  DeleteIcon,
  Modal,
  PlusIcon,
} from '@components/ui';
import { useIsMobile } from '@hooks/utils';
import DynamicIPForm from './parts/DynamicIPForm';
import MultiSelectDropdown, { Option } from '@components/ui/Multiselect';
import {
  useCountriesFiltersQuery,
  useHelixIntegrationMutation,
} from '@hooks/security';
import { useNavigate } from 'react-router-dom';
import { HelixData, IPRow } from '@hooks/security/types';
import useModalStore from '@store/modal';
import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import { helixMsalConfig, scopeConnectionRequest } from '@api/msalConfig';
import useUIStore from '@store/uiStore';
import { Variant } from '@components/ui/Toast';

const TechincalInformationFormPage: React.FC = () => {
  const redirectPath = useNavSourceRedirect('/settings/enhanced-security');
  const [ipAddresses, setIpAddresses] = useState<IPRow[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<Option[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { addToast } = useUIStore();

  const helixIntegration = useHelixIntegrationMutation();

  const handleIpAddressSave = (
    data: { location: string; ipAddress: string }[]
  ) => {
    setIpAddresses((prevState) => [...prevState, ...data]);
    setIsModalOpen(false);
  };

  const handleIpAddressDelete = (index: number) => {
    setIpAddresses((prevState) => prevState.filter((_, i) => i !== index));
  };

  const { data } = useCountriesFiltersQuery();

  let countryOptions: Option[] = [];

  if (data && data.countries.value) {
    countryOptions = Object.entries(data.countries.value).map(
      ([value, label]) => ({
        value,
        label: label as string,
      })
    );
  }

  const { setHelixSuccessModalOpen } = useModalStore();

  const handleSubmit = async () => {
    const data: HelixData = {
      countries: selectedCountries.map((country) => country.value),
      ips: ipAddresses,
    };
    const msalInstance = new PublicClientApplication(helixMsalConfig);
    await msalInstance.initialize();
    return await msalInstance
      .loginPopup({ ...scopeConnectionRequest, prompt: 'consent' })
      .then(async (response: AuthenticationResult) => {
        if (response) {
          helixIntegration.mutateAsync(data).then(() => {
            navigate('/settings/manage-connected-services');
            setHelixSuccessModalOpen(true);
          }).catch((e: any) =>
            addToast({
              variant: Variant.Error,
              message: `Something went wrong, please try again later.`,
              closeable: true,
            }));
        }
      })
      .catch((e: any) => console.log(e));
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={``}
    >
      <div className="py-2">
        <div className="container mx-auto px-6 md:px-6 lg:px-16">
          <div className="mx-auto mt-12 max-w-2xl">
            <h3 className="mb-8 text-36 text-interfaceColor-100">
              Optional: Share technical information
            </h3>
            <p className="mb-4 text-16 text-interfaceColor-100">
              To help us connect Enhanced Security Insights, tell us a bit about
              your estate:
            </p>
            <p className="mb-2 text-16 font-semibold">
              Optional: Where are your users based?
            </p>
            <div className="relative mb-12">
              <MultiSelectDropdown
                options={countryOptions}
                selectedOptions={selectedCountries}
                setSelectedOptions={setSelectedCountries}
                placeholder="Select countries"
                searchPlaceholder="Search countries"
                infoText="Select all countries that apply"
                dropdownMenuClasses="h-[300px]"
              />
            </div>
            <p className="text-16 font-semibold">
              Optional: Enter the IP address or IP ranges of your office
              locations if you know them
            </p>
            <div className="flex w-full flex-col justify-evenly">
              {ipAddresses.length > 0
                ? ipAddresses.map((ip, index) => (
                  <div
                    key={index}
                    className="mt-4 flex items-center gap-4 rounded-[4px] bg-white shadow-sm"
                  >
                    <div className="flex flex-grow gap-x-4 px-4">
                      <p className="text-16 text-black">{ip.location}</p>
                      <p className="text-16 text-black">{ip.ipAddress}</p>
                    </div>

                    <button
                      className="cursor-pointer rounded-r-[4px] bg-interfaceColor-90 p-3 transition hover:bg-interfaceColor-100"
                      onClick={() => handleIpAddressDelete(index)}
                    >
                      <DeleteIcon size="4" color="text-white" />
                    </button>
                  </div>
                ))
                : null}
            </div>
            <Button
              onClick={() => setIsModalOpen(true)}
              variant="outlineLight"
              size="medium"
              className="mt-3"
            >
              <PlusIcon
                color="text-interfaceColor-100"
                classNames="mr-2 mt-[2px]"
                size="3"
              />
              <span className="text-interfaceColor-100">Add IPs</span>
            </Button>
            <div className="mt-6">
              Click Submit to start the process of connecting Enhanced Security
              Insights. This usually takes a few days, and we&apos;ll let you
              know when we&apos;re done.
            </div>
            <div className="mt-5 flex justify-start gap-x-6">
              <Button
                onClick={() => handleSubmit()}
                variant="primary"
                size="medium"
                className="mt-3"
              >
                <span className="text-interfaceColor-100">Submit</span>
              </Button>
              <Button
                onClick={() => navigate('/settings/enhanced-security')}
                variant="outlineLight"
                size="medium"
                className="mt-3"
              >
                <span className="text-interfaceColor-100">Cancel</span>
              </Button>
            </div>
          </div>
        </div>
        <Modal
          headerTxt={'Add IPs'}
          isTruncatedHeader={false}
          isOpen={isModalOpen}
          modalClassName={'h-full mt-0'}
          size={'medium'}
          position={isMobile ? 'bottom' : 'default'}
          showCloseBtn={true}
          onClose={() => {
            setIsModalOpen(false);
          }}
          actionButtons={
            <div className="flex flex-col gap-2 md:flex-row"></div>
          }
          contentClassName="flex flex-column !items-start bg-white"
        >
          <DynamicIPForm
            onSave={handleIpAddressSave}
            onCancel={() => setIsModalOpen(false)}
          />
        </Modal>
      </div>
    </OverlayPage>
  );
};

export default TechincalInformationFormPage;
