import loader from '@assets/gif/loader.gif';
import { Button, Checkbox } from '@components/ui';
import { HamburgerIcon } from '@components/ui/Icons';
import { UserRoles } from '@hooks/users/types';
import useAuthStore from '@store/authStore';
import useTableStore from '@store/tableStore';
import useUIStore from '@store/uiStore';
import cn from 'classnames';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  Fragment,
} from 'react';
import { Dropdown, DropdownData } from '../Dropdown';
import { UseQueryResult } from 'react-query';
import { formatDate, formatNumberWithSuffix, formatSeconds } from '@utils/index';
import { useIsTablet } from '@hooks/utils/isTablet';
import styles from './styles.module.css';
import { Pagination } from '@components/partials/Pagination';
import { CellBottomPart } from './utils';
import dayjs from 'dayjs';

export type Column<T> = {
  key: keyof T;
  displayName: string;
  displayNameDesc?: string;
  isComplexCell?: boolean;
  isMobileLabel?: boolean;
};

type ContextMenuState = {
  [key: string]: boolean;
};

type TableProps<T extends object> = {
  columns: Column<T>[];
  data: any[];
  query?: UseQueryResult<any>;
  isLoading: boolean;
  startIndex?: number;
  endIndex?: number;
  totalCount: number;
  currentPage: number;
  totalPages: number;
  tableHeaderStickyPosition?: number | undefined;
  allSelectedItems?: any;
  setPageNumber: (pageNumber: number) => void;
  handleBulkAction?: () => void;
  handleSelect: (selected: any | null, all?: boolean) => void;
  showCheckboxes?: boolean;
  showBulkAction?: boolean;
  bulkContent?: React.ReactElement;
  setDropDownTableRow?: (id: string) => void;
  setDropDownAction?: React.Dispatch<React.SetStateAction<string | undefined>>;
  dropDownData?: DropdownData[];
  hasExpandedItems?: boolean;
  isFirstColumnSticky: boolean;
  isGrayHeader?: boolean;
};

export const Table = <T extends object>({
  columns,
  data,
  query,
  isLoading,
  currentPage,
  totalPages,
  setPageNumber,
  tableHeaderStickyPosition = undefined,
  handleBulkAction,
  handleSelect,
  showCheckboxes = false,
  showBulkAction = false,
  allSelectedItems,
  bulkContent,
  setDropDownTableRow,
  setDropDownAction,
  dropDownData = [],
  hasExpandedItems,
  isFirstColumnSticky,
  isGrayHeader = false,
}: TableProps<T>) => {
  const {
    totalItemsPerPage,
    selectedCountPerPage,
    newlySelectedItems,
    defaultPreselectedItems,
    expandedRows,
    expandedRowId,
    isVideoFormat,
  } = useTableStore();
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;
  const loggedInUserId = useAuthStore.getState().user?.id || '';
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<ContextMenuState>(
    {}
  );
  const { isAtStickyPositions } = useUIStore();

  const rawData = (query && query.data?.items) || [];
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isScrolledToRight, setIsScrolledToRight] = useState(false);
  const isTablet = useIsTablet();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (container && isFirstColumnSticky) {
        const isRightScrolled = container.scrollLeft > 0;
        setIsScrolledToRight(isRightScrolled);
      }
    };

    const container = containerRef.current;
    if (container && isFirstColumnSticky) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container && isFirstColumnSticky) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isFirstColumnSticky]);

  // dropdown stuff
  const openContextMenu = useCallback((id: string) => {
    setIsContextMenuOpen((prevOpenMenus) => {
      const updatedOpenMenus: ContextMenuState = {};
      for (const key in prevOpenMenus) {
        updatedOpenMenus[key] = false;
      }
      return {
        ...updatedOpenMenus,
        [id]: !prevOpenMenus[id],
      };
    });
  }, []);

  const onChangeContext = (row: any, item: DropdownData) => {
    setDropDownTableRow ? setDropDownTableRow(row.id) : null;
    setDropDownAction ? setDropDownAction(item?.value?.[0]) : null;
    setIsContextMenuOpen((prevOpenMenus) => ({
      ...prevOpenMenus,
      [row.id]: !prevOpenMenus[row.id],
    }));
  };

  const dropdownContent = useCallback(
    (row: any) => {
      const dataDropdown: { [key: string]: DropdownData[] } = {};
      dataDropdown[row.id] = dropDownData.map((item) => ({
        id: row.id,
        ...item,
      }));

      return (
        <Dropdown>
          <Dropdown.IconHeader
            icon={<HamburgerIcon />}
            handleOpen={() => openContextMenu(row.id)}
          ></Dropdown.IconHeader>
          <Dropdown.List
            className={'z-[9999]'}
            open={isContextMenuOpen[row.id]}
          >
            {dataDropdown[row.id].map((item) => {
              return (
                <Dropdown.ContextItem
                  key={item.label}
                  label={item.label}
                  icon={item.icon}
                  onChange={() => onChangeContext(row, item)}
                  color={item.color || ''}
                  hidden={
                    item.isHidden &&
                    item.isHidden(row, userRole, loggedInUserId)
                  }
                />
              );
            })}
          </Dropdown.List>
        </Dropdown>
      );
    },
    [isContextMenuOpen, openContextMenu, onChangeContext]
  );
  // dropdown stuff

  const getSelectedItem = (row: any) => {
    return allSelectedItems?.includes(row.id) || false;
  };

  const getCellValue = (column: Column<T>, row: any) => {
    if (column.key === 'tableDropdown') return;

    return Array.isArray(row[column.key])
      ? row[column.key][0]
      : row[column.key];
  };

  const getDefaultSelectedClass = (id: string): boolean => {
    return (
      defaultPreselectedItems.length > 0 && defaultPreselectedItems.includes(id)
    );
  };

  const getExpandedTableRow = (index: number) => {
    if (index >= 0 && index < expandedRows.length && hasExpandedItems) {
      return expandedRows[index];
    } else {
      return [];
    }
  };

  return (
    <>
      <div ref={containerRef} className={cn({ 'overflow-x-auto': isTablet })}>
        <table className="relative min-w-full">
          <thead
            style={{ top: isTablet ? 'auto' : tableHeaderStickyPosition }}
            className={
              isTablet || isGrayHeader
                ? 'bg-interfaceColor-20'
                : tableHeaderStickyPosition !== undefined
                  ? 'sticky z-10 bg-interfaceColor-20'
                  : ''
            }
          >
            <tr
              key={currentPage}
              className={`${isAtStickyPositions ? 'shadow-shadow-03' : ''} `}
            >
              {showCheckboxes && (
                <th className="h-12 px-4 py-[14px] text-left text-16 font-semibold leading-5 text-interfaceColor-100">
                  <Checkbox
                    defaultClassName={
                      selectedCountPerPage[currentPage] &&
                        selectedCountPerPage[currentPage] !==
                        totalItemsPerPage[currentPage]
                        ? 'ccCheckboxDefaultSelected'
                        : ''
                    }
                    checked={
                      selectedCountPerPage[currentPage] &&
                        selectedCountPerPage[currentPage] ===
                        totalItemsPerPage[currentPage]
                        ? true
                        : false
                    }
                    name={'all'}
                    onChange={() => handleSelect(null, true)}
                  />
                </th>
              )}
              {newlySelectedItems.length && showBulkAction ? (
                <th
                  colSpan={columns.length + 1}
                  className="h-12 px-4 py-[6px] text-left text-16 font-semibold leading-5 text-interfaceColor-100"
                >
                  <Button
                    variant="outlineLight"
                    onClick={handleBulkAction}
                    size="small"
                  >
                    {bulkContent ? bulkContent : 'Bulk Action'}
                  </Button>
                </th>
              ) : (
                columns.map(
                  (column, index) =>
                    !column.isMobileLabel && (
                      <th
                        key={`${column.displayName}-${index}`}
                        scope="col"
                        className={cn(
                          'text-left text-16 font-semibold leading-5 text-interfaceColor-100',
                          {
                            'sticky left-0 bg-interfaceColor-20':
                              index === 0 && isTablet && isFirstColumnSticky,
                          }
                        )}
                      >
                        <div className="min-h-12 h-auto px-4 py-[14px]">
                          {column.displayName}
                          <span className="flex text-14 font-normal">
                            {column.displayNameDesc}
                          </span>
                          <span
                            className={
                              isTablet
                                ? index === 0 && !isScrolledToRight
                                  ? styles.rightSpanWithBorder
                                  : styles.rightSpanWithShadowHeader
                                : ''
                            }
                          ></span>
                        </div>
                      </th>
                    )
                )
              )}
            </tr>
          </thead>
          <tbody className="h-full divide-y divide-interfaceColor-30 bg-white">
            {isLoading ? (
              <tr className="h-full">
                <td className="p-0" colSpan={columns.length + 1}>
                  <div className="my-8 flex h-full flex-col items-center justify-center">
                    <img className="h-14 w-14" src={loader} alt="Loading..." />
                    <span className="text-interfaceColor-90">Loading</span>
                  </div>
                </td>
              </tr>
            ) : (
              data.map((row, index) => (
                <Fragment key={index}>
                  <tr
                    key={index}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    className={cn(
                      `transition duration-150 ease-in-out`,
                      { 'hover:bg-interfaceColor-15': isHovered },
                      {
                        '!bg-interfaceColor-15': getDefaultSelectedClass(
                          row.id
                        ),
                      }
                    )}
                  >
                    {showCheckboxes && (
                      <td className="whitespace-nowrap px-4 py-[14px]">
                        <Checkbox
                          checked={getSelectedItem(row)}
                          onChange={() => handleSelect(row)}
                          name={`item-${index}`}
                        />
                      </td>
                    )}
                    {columns.map(
                      (column, columnIndex) =>
                        !column.isMobileLabel && (
                          <td
                            className={cn(`whitespace-nowrap`, {
                              'text-right': String(column.key) === 'actions',
                              'sticky left-0 bg-white':
                                columnIndex === 0 &&
                                isTablet &&
                                isFirstColumnSticky,
                              'transition duration-150 ease-in-out hover:bg-interfaceColor-15':
                                isHovered,
                            })}
                            key={columnIndex}
                          >
                            <div className="flex h-full w-full items-center px-4 py-[14px] md:min-h-[73px]">
                              <div className="text-16 tracking-[0.01em] text-interfaceColor-100">
                                {/* hub settings dropdown column */}
                                {String(column.key) === 'tableDropdown' &&
                                  userRole !== UserRoles.STANDARD && (
                                    <span className="inline-flex w-full justify-end">
                                      {dropdownContent(rawData[index])}
                                    </span>
                                  )}
                                <p className="line-clamp-3 lg:max-w-md xl:max-w-lg">
                                  {getCellValue(column, row)}
                                </p>
                              </div>
                              <span
                                className={
                                  isTablet
                                    ? columnIndex === 0 && !isScrolledToRight
                                      ? styles.rightSpanWithBorder
                                      : styles.rightSpanWithShadow
                                    : ''
                                }
                              ></span>
                            </div>
                          </td>
                        )
                    )}
                  </tr>

                  {expandedRowId === index &&
                    hasExpandedItems && !!getExpandedTableRow(index)?.eventTypes &&
                    getExpandedTableRow(index)?.eventTypes.map(
                      (expRow: {
                        eventCount: number,
                        eventType: string,
                        occuredAt: string
                      }, expIndex: any) => {
                        return (
                          <tr key={expIndex} className="border-none">
                            <td></td>
                            <td className="flex items-center justify-start gap-x-2 py-2 pl-8 align-middle">
                              <span className="flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="9"
                                  height="13"
                                  viewBox="0 0 9 13"
                                  fill="none"
                                >
                                  <path
                                    d="M1 0V8C1 10.2091 2.79086 12 5 12H9"
                                    stroke="#9D9FA5"
                                  />
                                </svg>
                              </span>
                              {expRow.eventType}
                            </td>
                            <td className="py-2  pl-4">
                              {expRow?.eventCount || ""}
                            </td>
                            <td className="py-2  pl-4 flex items-start justify-start flex-col gap-[20x]">
                              {expRow?.occuredAt ? <><span>{formatDate(expRow.occuredAt)}</span>
                                <CellBottomPart data={dayjs(expRow.occuredAt).format('HH:mm:ss')} />
                              </> : ""}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  {expandedRowId === index &&
                    hasExpandedItems && !!getExpandedTableRow(index)?.vendors &&
                    getExpandedTableRow(index)?.vendors.map(
                      (expRow: any, expIndex: any) => {
                        return (
                          <tr key={expIndex} className="border-none">
                            <td className="relative flex items-center justify-start gap-x-2 py-2 pl-12 align-middle">
                              <span className="absolute left-[32px] top-[10px]">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="9"
                                  height="13"
                                  viewBox="0 0 9 13"
                                  fill="none"
                                >
                                  <path
                                    d="M1 0V8C1 10.2091 2.79086 12 5 12H9"
                                    stroke="#9D9FA5"
                                  />
                                </svg>
                              </span>

                              {expRow.vendor}
                            </td>
                            <td className="py-2  pl-4">
                              {expRow?.adoptionRate?.toFixed(2)}%
                              <div className="flex flex-col">
                                <span className="text-14 tracking-wide text-interfaceColor-80">
                                  {expRow.usesUsersCount} - {expRow.usersCount}{' '}
                                  users
                                </span>
                              </div>
                            </td>
                            <td className="py-2 pl-4">
                              {isVideoFormat
                                ? formatSeconds(expRow.uses)
                                : formatNumberWithSuffix(expRow.uses)}
                            </td>
                          </tr>
                        );
                      }
                    )}
                </Fragment>
              ))
            )}
          </tbody>
        </table>
      </div>
      {!isLoading && (
        <div className="flex w-full justify-between border-t border-interfaceColor-20 p-5">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setPageNumber={setPageNumber}
          />
        </div>
      )}
    </>
  );
};
