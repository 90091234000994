import { Button, Input } from '@components/ui';
import { IPRow } from '@hooks/security/types';
import React, { useState, useEffect, useRef } from 'react';

type DynamicIPFormProps = {
  onSave: (data: IPRow[]) => void;
  onCancel: () => void;
};

const DynamicIPForm: React.FC<DynamicIPFormProps> = ({ onSave, onCancel }) => {
  const [rows, setRows] = useState<IPRow[]>([
    { location: '', ipAddress: '' },
    { location: '', ipAddress: '' },
    { location: '', ipAddress: '' },
  ]);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const lastRowRef = useRef<HTMLDivElement>(null);

  const isValidIPAddress = (ip: string) => {
    const ipPattern =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([1-9]|[12][0-9]|3[0-2]))?$/;
    return ipPattern.test(ip);
  };

  const handleInputChange = (
    index: number,
    field: keyof IPRow,
    value: string
  ) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);

    if (newRows.every((row) => row.location && row.ipAddress)) {
      setRows([...newRows, { location: '', ipAddress: '' }]);
    }

    const allFilledRowsValid = newRows
      .filter((row) => row.location || row.ipAddress)
      .every(
        (row) => row.location.length >= 3 && isValidIPAddress(row.ipAddress)
      );
    setIsSaveEnabled(allFilledRowsValid);
  };

  useEffect(() => {
    if (lastRowRef.current) {
      lastRowRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [rows]);

  const handleSave = () => {
    onSave(
      rows.filter(
        (row) => row.location.length >= 3 && isValidIPAddress(row.ipAddress)
      )
    );
  };

  const getLocationError = (location: string) =>
    location && location.length < 3
      ? 'Location must be at least 3 characters'
      : '';

  const getIpAddressError = (ipAddress: string) =>
    ipAddress && !isValidIPAddress(ipAddress) ? 'Invalid IP address' : '';

  return (
    <div className="flex h-full w-full flex-col justify-between">
      <div>
        <div className="mb-1 flex w-full gap-x-4">
          <p className="m-1 w-1/2 text-16 font-semibold text-interfaceColor-100">
            Location
          </p>
          <p className="m-1 w-1/2 text-16 font-semibold text-interfaceColor-100">
            IP address / range
          </p>
        </div>
        <div className="mb-2 max-h-[35vh] overflow-y-auto">
          {rows.map((row, index) => (
            <div
              key={index}
              className="mb-4 flex gap-x-4"
              ref={index === rows.length - 1 ? lastRowRef : null}
            >
              <Input
                name={'location' + index}
                placeholder="Office name"
                className="m-1 w-1/2"
                value={row.location}
                onChange={(e) =>
                  handleInputChange(index, 'location', e.target.value)
                }
                error={getLocationError(row.location)}
              />
              <Input
                name={'ipAddress' + index}
                placeholder="1.1.1.1/32"
                className="m-1 w-1/2"
                value={row.ipAddress}
                onChange={(e) =>
                  handleInputChange(index, 'ipAddress', e.target.value)
                }
                error={getIpAddressError(row.ipAddress)}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-2 lg:flex-row">
        <Button
          type="submit"
          size="medium"
          variant="primary"
          onClick={handleSave}
          disabled={!isSaveEnabled}
        >
          Save
        </Button>
        <Button size="medium" variant="outlineLight" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default DynamicIPForm;
