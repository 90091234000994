import { API_ENDPOINTS } from '@api/ApiEndpoints';
import { UseQueryResult, useMutation, useQuery } from 'react-query';
import {
  ExternalApplicationsFiltersResponse,
  ExternalAppsAccountLevelResponse,
  ExternalAppsMSDataResponse,
  ExternalAppsPreviewQueryOptions,
  ExternalAppsPublisherVerificationResponse,
  ExternalAppsTenantLevelResponse,
  ExternalTeamsAccessDetailsPreviewQueryOptions,
  ExternalTeamsAccessDetailsPreviewResponse,
  ExternalTeamsAccessPreviewQueryOptions,
  ExternalTeamsAccessPreviewResponse,
  ExternalTeamsAccessResponse,
  ExternalTenantwideAppPreviewResponse,
  ExternalUsersAccessResponse,
  FailedLoginAccountsFiltersResponse,
  FailedLoginAccountsPreviewQueryOptions,
  FailedLoginDataCountriesPreviewResponse,
  FailedLoginTypesPreviewResponse,
  FailedLoginsDataPreviewResponse,
  HelixData,
  LicensesUsagePreviewResponse,
  MailboxForwardingDataPrevieResponse,
  MailboxForwardingDataPreviewQueryOptions,
  MailboxesForwardingSnapshotResponse,
  MfaStatusPreviewResponse,
  MfaStatusSnapshotResponse,
  MsSecureScoreSnapshotResponse,
  NoLicencesPreviewQueryOptions,
  NoLicencesQueryOptions,
  NoLicensesPreviewResponse,
  OutdatedOSFiltersResponse,
  OutdatedOSAccountsPreviewQueryOptions,
  OutdatedOSAccountsPreviewResponse,
  OutdatedBrowserActivityResponse,
  OutdatedBrowserFiltersResponse,
  OutdatedBrowserSnapshotResponse,
  OutdatedBrowserUserTypesResponse,
  OutdatedBrowsersPreviewQueryOptions,
  OutdatedBrowsersPreviewResponse,
  SecureScoreActionQueryOptions,
  SecureScoreActionResponse,
  SecureScoreComparisonResponse,
  SecureScorePreviewQueryOptions,
  SecureScorePreviewResponse,
  SecureScoreTypesQueryOptions,
  SecureScoreTypesSnapshotResponse,
  SharedMailboxesPreviewQueryOptions,
  SuspiciousActivityAccountsPreviewResponse,
  SuspiciousActivityEventsFiltersResponse,
  SuspiciousActivityEventsPreviewQueryOptions,
  SuspiciousActivityEventsPreviewResponse,
  SuspiciousActivitySourceCountryResponse,
  SuspiciousActivityTilesQueryOptions,
  SuspiciousActivityTotalEventResponse,
  SuspiciousActivityTrendResponse,
  UseMfaStatusPreviewQueryOptions,
  UseMfaStatusSnapshotQueryOptions,
  UseSuspiciousActivityEventsQueryOptions,
  EventsData,
  MailboxDelegatesFilterResponse,
  MailboxDelegatesLeaderboardResponse,
  MailboxDelegatesPreviewResponse,
  MailboxDelegatesPreviewQueryOptions,
  OutdatedBrowserAccountsPreviewResponse,
  DelegateMailboxesPreviewResponse,
  CountriesFiltersResponse,
  ConfiguredTrunksPreviewResponse,
  ConfiguredTrunksPreviewQueryOptions,
  ConfiguredTrunksFilterResponse,
  OutdatedBrowsersQueryOptions,
  CallForwardingDestinationsSnapshotResponse,
  CallForwardingDestinationsPreviewResponse,
  CallQueuesPreviewQueryOptions,
  CallQueuePreviewResponse,
  CallQueuesFiltersResponse,
  EmptyCallQueueResponse,
  CallQueueResponse,
} from './types';
import {
  BasePreviewQueryOptions,
  TrendCompareAgainstResponse,
  UserPercentageResponse,
} from '@common/types';
import { useHttpClient } from '@hooks/utils/httpConfig';
import { getSearchQueryParams } from '@utils/index';

export function useMfaStatusSnapshotQuery({
  userType,
  locationList,
  groupList,
  licenseTypeList,
}: UseMfaStatusSnapshotQueryOptions = {}): UseQueryResult<MfaStatusSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchMfaStatusSnapshot =
    async (): Promise<MfaStatusSnapshotResponse> => {
      const params = new URLSearchParams();
      if (userType) {
        params.append('userType', userType);
      }

      const groupListParams = groupList
        ? groupList.map((group) => `GroupList=${group}`).join('&')
        : '';

      const locationListParams = locationList
        ? locationList.map((location) => `LocationList=${location}`).join('&')
        : '';

      const licenseTypeListParams = licenseTypeList
        ? licenseTypeList
          .map((licenseType) => `LicenseTypeList=${licenseType}`)
          .join('&')
        : '';

      return httpClient.get<MfaStatusSnapshotResponse>(
        `${API_ENDPOINTS.MFA_STATUS_SNAPSHOT
        }?${params.toString()}&${groupListParams}&${locationListParams}&${licenseTypeListParams}`
      );
    };

  return useQuery(
    ['mfaStatusSnapshot', userType, locationList, groupList, licenseTypeList],
    fetchMfaStatusSnapshot
  );
}

export function useMfaStatusPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  groupList,
  passwordExpiry,
  userType,
  licenseTypeList,
  locationList,
}: UseMfaStatusPreviewQueryOptions = {}): UseQueryResult<MfaStatusPreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<MfaStatusPreviewResponse> => {
    const baseParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.searchTerm = searchTerm;
    if (sortBy) baseParams.sortBy = sortBy;
    if (order) baseParams.order = order;
    if (passwordExpiry) baseParams.passwordExpiry = passwordExpiry;
    if (userType) baseParams.userType = userType;

    const groupListParams = groupList
      ? groupList.map((group) => `GroupList=${group}`).join('&')
      : '';

    const licenseTypeListParams = licenseTypeList
      ? licenseTypeList
        .map((licenseType) => `LicenseTypeList=${licenseType}`)
        .join('&')
      : '';

    const locationListParams = locationList
      ? locationList.map((location) => `LocationList=${location}`).join('&')
      : '';

    const endpoint = `${API_ENDPOINTS.MFA_STATUS_PREVIEW}?${new URLSearchParams(
      baseParams
    ).toString()}&${groupListParams}&${licenseTypeListParams}&${locationListParams}`;

    return httpClient.get<MfaStatusPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'mfaStatusPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      groupList,
      passwordExpiry,
      userType,
      licenseTypeList,
      locationList,
    ],
    fetchData
  );
}

export function useExternalTeamsAccessPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  externalTeamsCollaboration,
}: ExternalTeamsAccessPreviewQueryOptions = {}): UseQueryResult<ExternalTeamsAccessPreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<ExternalTeamsAccessPreviewResponse> => {
    const baseParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.searchTerm = searchTerm;
    if (sortBy) baseParams.sortBy = sortBy;
    if (order) baseParams.order = order;

    const collaborationListParams = externalTeamsCollaboration
      ? externalTeamsCollaboration
        .map((item) => `HasntCollaboratingDaysFilter=${item}`)
        .join('&')
      : '';

    const endpoint = `${API_ENDPOINTS.EXTERNAL_TEAMS_ACCESS_PREVIEW
      }?${new URLSearchParams(baseParams).toString()}&${collaborationListParams}`;

    return httpClient.get<ExternalTeamsAccessPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'externalTeamsAccessPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      externalTeamsCollaboration,
    ],
    fetchData
  );
}

export function useExternalTeamsAccessQuery(): UseQueryResult<ExternalTeamsAccessResponse> {
  const httpClient = useHttpClient();
  const fetchRooms = async (): Promise<ExternalTeamsAccessResponse> => {
    return httpClient.get<ExternalTeamsAccessResponse>(
      `${API_ENDPOINTS.EXTERNAL_USERS_ACCESS_SNAPSHOT}`
    );
  };

  return useQuery(['externalTeamsAccess'], fetchRooms);
}

export function useExternalUsersAccessQuery(): UseQueryResult<ExternalUsersAccessResponse> {
  const httpClient = useHttpClient();
  const fetchRooms = async (): Promise<ExternalUsersAccessResponse> => {
    return httpClient.get<ExternalUsersAccessResponse>(
      `${API_ENDPOINTS.EXTERNAL_TEAMS_ACCESS_SNAPSHOT}`
    );
  };

  return useQuery(['externalUsersAccess'], fetchRooms);
}

export function useExternalTeamsAccessDetailsPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  ids,
}: ExternalTeamsAccessDetailsPreviewQueryOptions = {}): UseQueryResult<ExternalTeamsAccessDetailsPreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData =
    async (): Promise<ExternalTeamsAccessDetailsPreviewResponse> => {
      const baseParams: Record<string, string> = {
        pageSize: pageSize.toString(),
        pageNumber: pageNumber.toString(),
        Days: '30',
      };

      if (searchTerm) baseParams.searchTerm = searchTerm;
      if (sortBy) baseParams.sortBy = sortBy;
      if (order) baseParams.order = order;

      const idsParams = ids ? ids.map((id) => `TeamIds=${id}`).join('&') : '';

      const endpoint = `${API_ENDPOINTS.EXTERNAL_TEAMS_ACCESS_DETAILS_PREVIEW
        }?${new URLSearchParams(baseParams).toString()}&${idsParams}`;

      return httpClient.get<ExternalTeamsAccessDetailsPreviewResponse>(
        endpoint
      );
    };

  return useQuery(
    [
      'externalTeamsAccessDetailsPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      ids,
    ],
    fetchData
  );
}

export function useNoLicencesSnapshotQuery({
  locationList,
  groupList,
}: NoLicencesQueryOptions = {}): UseQueryResult<UserPercentageResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<UserPercentageResponse> => {
    const baseParams: Record<string, string> = {};

    const groupListParams = groupList
      ? groupList.map((group) => `GroupList=${group}`).join('&')
      : '';

    const locationListParams = locationList
      ? locationList.map((location) => `LocationList=${location}`).join('&')
      : '';

    const endpoint = `${API_ENDPOINTS.SIGN_IN_ENABLED_SNAPSHOT
      }?${new URLSearchParams(
        baseParams
      ).toString()}&${groupListParams}&${locationListParams}`;

    return httpClient.get<UserPercentageResponse>(endpoint);
  };

  return useQuery(
    ['noLicencesSnapshot', { locationList, groupList }],
    fetchData
  );
}

export function useNoLicencesTrendQuery({
  locationList,
  groupList,
}: NoLicencesQueryOptions = {}): UseQueryResult<TrendCompareAgainstResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<TrendCompareAgainstResponse> => {
    const baseParams: Record<string, string> = {};

    const locationListParams = locationList
      ? locationList.map((location) => `LocationList=${location}`).join('&')
      : '';

    const groupListParams = groupList
      ? groupList.map((group) => `GroupList=${group}`).join('&')
      : '';

    const endpoint = `${API_ENDPOINTS.SIGN_IN_ENABLED_TREND
      }?${new URLSearchParams(
        baseParams
      ).toString()}&${groupListParams}&${locationListParams}`;

    return httpClient.get<TrendCompareAgainstResponse>(endpoint);
  };

  return useQuery(['noLicencesTrend', { locationList, groupList }], fetchData);
}

export function useNoLicencesPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  groupList,
  locationList,
}: NoLicencesPreviewQueryOptions = {}): UseQueryResult<NoLicensesPreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<NoLicensesPreviewResponse> => {
    const baseParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.searchTerm = searchTerm;
    if (sortBy) baseParams.sortBy = sortBy;
    if (order) baseParams.order = order;

    const groupListParams = groupList
      ? groupList.map((group) => `GroupList=${group}`).join('&')
      : '';

    const locationListParams = locationList
      ? locationList.map((location) => `LocationList=${location}`).join('&')
      : '';

    const endpoint = `${API_ENDPOINTS.SIGN_IN_ENABLED_PREVIEW
      }?${new URLSearchParams(
        baseParams
      ).toString()}&${groupListParams}&${locationListParams}`;

    return httpClient.get<NoLicensesPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'noLicencesPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      groupList,
      locationList,
    ],
    fetchData
  );
}

export function useSharedMailboxesPreviewQuery({
  searchTerm,
  sortBy,
  order,
  sharedMailboxStatus,
  pageSize = 20,
  pageNumber = 1,
}: SharedMailboxesPreviewQueryOptions = {}): UseQueryResult<LicensesUsagePreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<LicensesUsagePreviewResponse> => {
    const baseParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.searchTerm = searchTerm;
    if (sortBy) baseParams.sortBy = sortBy;
    if (order) baseParams.order = order;
    if (sharedMailboxStatus) baseParams.status = sharedMailboxStatus;

    const endpoint = `${API_ENDPOINTS.SHARED_MAILBOXES_PREVIEW
      }?${new URLSearchParams(baseParams).toString()}`;

    return httpClient.get<LicensesUsagePreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'noLicencesPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      sharedMailboxStatus,
    ],
    fetchData
  );
}

export function useSharedMailboxesTrendQuery({
  sharedMailboxStatus,
}: SharedMailboxesPreviewQueryOptions = {}): UseQueryResult<TrendCompareAgainstResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<TrendCompareAgainstResponse> => {
    const baseParams: Record<string, string> = {};

    if (sharedMailboxStatus) baseParams.status = sharedMailboxStatus;

    const endpoint = `${API_ENDPOINTS.SHARED_MAILBOXES_TREND}?${baseParams}`;

    return httpClient.get<TrendCompareAgainstResponse>(endpoint);
  };

  return useQuery(['sharedMailboxesTrend', sharedMailboxStatus], fetchData);
}

export function useSharedMailboxesSnapshotQuery({
  sharedMailboxStatus,
}: SharedMailboxesPreviewQueryOptions = {}): UseQueryResult<UserPercentageResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<UserPercentageResponse> => {
    const baseParams: Record<string, string> = {};

    if (sharedMailboxStatus) baseParams.status = sharedMailboxStatus;

    const endpoint = `${API_ENDPOINTS.SHARED_MAILBOXES_SNAPSHOT}?${baseParams}`;

    return httpClient.get<UserPercentageResponse>(endpoint);
  };

  return useQuery(['sharedMailboxesSnapshot', sharedMailboxStatus], fetchData);
}

export function useMsSecureScoreSnapshotQuery(): UseQueryResult<MsSecureScoreSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<MsSecureScoreSnapshotResponse> => {
    return httpClient.get<MsSecureScoreSnapshotResponse>(
      `${API_ENDPOINTS.MS_SECURE_SCORE_SNAPSHOT}`
    );
  };

  return useQuery(['msSecureScoreSnapshot'], fetchData);
}

export function useSecureScoreTypesSnapshotQuery({
  category,
}: SecureScoreTypesQueryOptions = {}): UseQueryResult<SecureScoreTypesSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<SecureScoreTypesSnapshotResponse> => {
    const statusParams = `Category=${category}`;

    return httpClient.get<SecureScoreTypesSnapshotResponse>(
      `${API_ENDPOINTS.MS_SECURE_SCORE_SNAPSHOT}?${statusParams}`
    );
  };

  return useQuery(['secureScoreTypeSnapshot', category], fetchData);
}

export function useSecureScoreSnapshotQuery(): UseQueryResult<SecureScoreTypesSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<SecureScoreTypesSnapshotResponse> => {
    return httpClient.get<SecureScoreTypesSnapshotResponse>(
      `${API_ENDPOINTS.MS_SECURE_SCORE}`
    );
  };

  return useQuery(['secureScoreSnapshot'], fetchData);
}

export function useSecureScoreTrendQuery(): UseQueryResult<TrendCompareAgainstResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<TrendCompareAgainstResponse> => {
    const endpoint = `${API_ENDPOINTS.SECURE_SCORE_TREND}`;

    return httpClient.get<TrendCompareAgainstResponse>(endpoint);
  };

  return useQuery(['secureScoreTrend'], fetchData);
}

export function useSecureScoreComparisonQuery(): UseQueryResult<SecureScoreComparisonResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<SecureScoreComparisonResponse> => {
    const endpoint = `${API_ENDPOINTS.SECURE_SCORE_COMPARISON}`;

    return httpClient.get<SecureScoreComparisonResponse>(endpoint);
  };

  return useQuery(['secureScoreComparison'], fetchData);
}

export function useSecureScorePreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  category,
  secureScoreStatus,
}: SecureScorePreviewQueryOptions = {}): UseQueryResult<SecureScorePreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<SecureScorePreviewResponse> => {
    const baseParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.searchTerm = searchTerm;
    if (sortBy) baseParams.sortBy = sortBy;
    if (order) baseParams.order = order;

    const categoryParams = category
      ? category.map((item) => `CategoryList=${item}`).join('&')
      : '';

    const statusParams = secureScoreStatus
      ? secureScoreStatus.map((item) => `IncludeCompleted=${item}`).join('&')
      : '';

    const endpoint = `${API_ENDPOINTS.SECURE_SCORE_PREVIEW
      }?${new URLSearchParams(
        baseParams
      ).toString()}&${categoryParams}&${statusParams}`;

    return httpClient.get<SecureScorePreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'secureScorePreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      category,
      secureScoreStatus,
    ],
    fetchData
  );
}

export function useSecureScoreActionQuery({
  actionId,
}: SecureScoreActionQueryOptions = {}): UseQueryResult<SecureScoreActionResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<SecureScoreActionResponse> => {
    return httpClient.get<SecureScoreActionResponse>(
      `${API_ENDPOINTS.MS_SECURE_SCORE_ACTION}/${actionId}`
    );
  };

  return useQuery(['secureScoreAction', actionId], fetchData);
}

export function useSuspiciousActivityTrendQuery({
  days,
  eventType,
}: SuspiciousActivityTilesQueryOptions = {}): UseQueryResult<SuspiciousActivityTrendResponse> {
  const httpClient = useHttpClient();

  const fetchData = async (): Promise<SuspiciousActivityTrendResponse> => {
    const params = getSearchQueryParams({
      days,
      eventType,
    });

    const endpoint = `${API_ENDPOINTS.SUSPICIOUS_ACTIVITY_TREND
      }?${params.toString()}`;
    return httpClient.get<SuspiciousActivityTrendResponse>(endpoint);
  };

  return useQuery(
    [
      'suspiciousActivityTrend',
      {
        days,
        eventType,
      },
    ],
    fetchData
  );
}

export function useSuspiciousActivityTotalEventQuery({
  days,
}: SuspiciousActivityTilesQueryOptions = {}): UseQueryResult<SuspiciousActivityTotalEventResponse> {
  const httpClient = useHttpClient();

  const fetchData = async (): Promise<SuspiciousActivityTotalEventResponse> => {
    const params = getSearchQueryParams({
      days,
    });

    const endpoint = `${API_ENDPOINTS.SUSPICIOUS_ACTIVITY_TOTAL_EVENT
      }?${params.toString()}`;
    return httpClient.get<SuspiciousActivityTotalEventResponse>(endpoint);
  };

  return useQuery(
    [
      'suspiciousActivityTotalEvent',
      {
        days,
      },
    ],
    fetchData
  );
}

export function useSuspiciousActivitySourceCountryQuery({
  days,
}: SuspiciousActivityTilesQueryOptions = {}): UseQueryResult<SuspiciousActivitySourceCountryResponse> {
  const httpClient = useHttpClient();

  const fetchData =
    async (): Promise<SuspiciousActivitySourceCountryResponse> => {
      const params = getSearchQueryParams({
        days,
      });

      const endpoint = `${API_ENDPOINTS.SUSPICIOUS_ACTIVITY_SOURCE_COUNTRY
        }?${params.toString()}`;
      return httpClient.get<SuspiciousActivitySourceCountryResponse>(endpoint);
    };

  return useQuery(
    [
      'suspiciousActivitySourceCountry',
      {
        days,
      },
    ],
    fetchData
  );
}

export function useSuspiciousActivityEventsPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  days,
  eventTypes,
  ipTypes,
  countries,
}: SuspiciousActivityEventsPreviewQueryOptions = {}): UseQueryResult<SuspiciousActivityEventsPreviewResponse> {
  const httpClient = useHttpClient();

  const fetchData =
    async (): Promise<SuspiciousActivityEventsPreviewResponse> => {
      const params = getSearchQueryParams({
        days,
        searchTerm,
        sortBy,
        order,
        pageSize,
        pageNumber,
        eventTypes,
        ipTypes,
        countries,
      });

      const endpoint = `${API_ENDPOINTS.SUSPICIOUS_ACTIVITY_EVENTS_PAGE
        }?${params.toString()}`;
      return httpClient.get<SuspiciousActivityEventsPreviewResponse>(endpoint);
    };

  return useQuery(
    [
      'suspiciousActivityEvents',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      days,
      eventTypes,
      ipTypes,
      countries,
    ],
    fetchData
  );
}

export function useSuspiciousActivityEventsFiltersQuery(days?: string) {
  const httpClient = useHttpClient();
  const fetchLicensesFilters =
    async (): Promise<SuspiciousActivityEventsFiltersResponse> => {
      const params = getSearchQueryParams({
        days,
      });
      return httpClient.get<SuspiciousActivityEventsFiltersResponse>(
        `${API_ENDPOINTS.SUSPICIOUS_ACTIVITY_EVENTS_FILTER}?${params.toString()}`
      );
    };

  return useQuery(['suspiciousActivityEventsFilters', days], fetchLicensesFilters);
}

export function useSuspiciousActivityAccountsPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  days,
  eventTypes,
}: SuspiciousActivityEventsPreviewQueryOptions = {}): UseQueryResult<SuspiciousActivityAccountsPreviewResponse> {
  const httpClient = useHttpClient();

  const fetchData =
    async (): Promise<SuspiciousActivityAccountsPreviewResponse> => {
      const params = getSearchQueryParams({
        days,
        searchTerm,
        sortBy,
        order,
        pageSize,
        pageNumber,
        eventTypes,
      });

      const endpoint = `${API_ENDPOINTS.SUSPICIOUS_ACTIVITY_ACCOUNTS_PAGE
        }?${params.toString()}`;
      return httpClient.get<SuspiciousActivityAccountsPreviewResponse>(
        endpoint
      );
    };

  return useQuery(
    [
      'suspiciousActivityAccounts',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      days,
      eventTypes,
    ],
    fetchData
  );
}

export function useSuspiciousActivityAccountsFiltersQuery(days: string) {
  const httpClient = useHttpClient();
  const fetchLicensesFilters =
    async (): Promise<SuspiciousActivityEventsFiltersResponse> => {
      const params = getSearchQueryParams({
        days,
      });
      return httpClient.get<SuspiciousActivityEventsFiltersResponse>(
        `${API_ENDPOINTS.SUSPICIOUS_ACTIVITY_ACCOUNTS_FILTER}?${params.toString()}`
      );
    };

  return useQuery(['suspiciousActivityAccountFilters', days], fetchLicensesFilters);
}

export function useExternalAppsFiltersQuery() {
  const httpClient = useHttpClient();
  const fetchFilters =
    async (): Promise<ExternalApplicationsFiltersResponse> => {
      return httpClient.get<ExternalApplicationsFiltersResponse>(
        `${API_ENDPOINTS.EXTERNAL_APPLICATIONS_FILTER}`
      );
    };

  return useQuery(['externalAppsFilters'], fetchFilters);
}

export function useExternalAppsMSDataQuery(): UseQueryResult<ExternalAppsMSDataResponse> {
  const httpClient = useHttpClient();

  const fetchData = async (): Promise<ExternalAppsMSDataResponse> => {
    return httpClient.get<ExternalAppsMSDataResponse>(
      API_ENDPOINTS.EXTERNAL_APPLICATIONS_ACTIVITY
    );
  };

  return useQuery(['externalAppsMSData'], fetchData);
}

export function useExternalAppsAccountLevel(): UseQueryResult<ExternalAppsAccountLevelResponse> {
  const httpClient = useHttpClient();

  const fetchData = async (): Promise<ExternalAppsAccountLevelResponse> => {
    return httpClient.get<ExternalAppsAccountLevelResponse>(
      API_ENDPOINTS.EXTERNAL_APPLICATIONS_ACCOUNT_LEVEL
    );
  };

  return useQuery(['externalAppsAccountLevel'], fetchData);
}

export function useExternalAppsTenantLevel(): UseQueryResult<ExternalAppsTenantLevelResponse> {
  const httpClient = useHttpClient();

  const fetchData = async (): Promise<ExternalAppsTenantLevelResponse> => {
    return httpClient.get<ExternalAppsTenantLevelResponse>(
      API_ENDPOINTS.EXTERNAL_APPLICATIONS_TENANT_WIDE
    );
  };

  return useQuery(['externalAppsTenantLevel'], fetchData);
}

export function useExternalAppsPublisherVerification(): UseQueryResult<ExternalAppsPublisherVerificationResponse> {
  const httpClient = useHttpClient();

  const fetchData =
    async (): Promise<ExternalAppsPublisherVerificationResponse> => {
      return httpClient.get<ExternalAppsPublisherVerificationResponse>(
        API_ENDPOINTS.EXTERNAL_APPLICATIONS_PUBLISHER_VERIFICATION
      );
    };

  return useQuery(['externalAppsPublisherVerification'], fetchData);
}

export function useExternalAppsPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  publisherVerification,
  permissionLevels,
  locationList,
  groupList,
}: ExternalAppsPreviewQueryOptions = {}): UseQueryResult<ExternalTeamsAccessPreviewResponse> {
  const httpClient = useHttpClient();

  const fetchData = async (): Promise<ExternalTeamsAccessPreviewResponse> => {
    const params = getSearchQueryParams({
      searchTerm,
      sortBy,
      order,
      pageSize,
      pageNumber,
      publisherVerification,
      permissionLevels,
      LocationList: locationList,
      GroupList: groupList,
    });

    const endpoint = `${API_ENDPOINTS.EXTERNAL_APPLICATIONS_PAGE
      }?${params.toString()}`;
    return httpClient.get<ExternalTeamsAccessPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'externalAppsPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      publisherVerification,
      permissionLevels,
      locationList,
      groupList,
    ],
    fetchData
  );
}

export function useTenantwideAppsPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  publisherVerification,
  permissionLevels,
}: ExternalAppsPreviewQueryOptions = {}): UseQueryResult<ExternalTenantwideAppPreviewResponse> {
  const httpClient = useHttpClient();

  const fetchData = async (): Promise<ExternalTenantwideAppPreviewResponse> => {
    const params = getSearchQueryParams({
      searchTerm,
      sortBy,
      order,
      pageSize,
      pageNumber,
      publisherVerification,
      permissionLevels,
    });

    const endpoint = `${API_ENDPOINTS.EXTERNAL_APPLICATIONS_TENANT_WIDE_PAGE
      }?${params.toString()}`;

    return httpClient.get<ExternalTenantwideAppPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'tenantwideAppsPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      publisherVerification,
      permissionLevels,
    ],
    fetchData
  );
}

export function useFailedLoginDataCountriesPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  days,
}: BasePreviewQueryOptions = {}): UseQueryResult<FailedLoginDataCountriesPreviewResponse> {
  const httpClient = useHttpClient();

  const fetchData =
    async (): Promise<FailedLoginDataCountriesPreviewResponse> => {
      const params = getSearchQueryParams({
        searchTerm,
        sortBy,
        order,
        pageSize,
        pageNumber,
        days,
      });

      const endpoint = `${API_ENDPOINTS.FAILED_LOGIN_DATA_COUNTRIES_PREVIEW
        }?${params.toString()}`;
      return httpClient.get<FailedLoginDataCountriesPreviewResponse>(endpoint);
    };

  return useQuery(
    [
      'failedLoginDataPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      days,
    ],
    fetchData
  );
}

export function useFailedLoginTypesPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  days,
}: BasePreviewQueryOptions = {}): UseQueryResult<FailedLoginTypesPreviewResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<FailedLoginTypesPreviewResponse> => {
    const params = getSearchQueryParams({
      searchTerm,
      sortBy,
      order,
      pageSize,
      pageNumber,
      days,
    });
    const endpoint = `${API_ENDPOINTS.FAILED_LOGIN_TYPES_PREVIEW
      }?${params.toString()}`;
    return httpClient.get<FailedLoginTypesPreviewResponse>(endpoint);
  };
  return useQuery(
    [
      'failedLoginTypesPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      days,
    ],
    fetchData
  );
}

export function useFailedLoginAccountsPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  days,
  status,
  mfaStatus,
  countries,
  locationList,
  groupList,
}: FailedLoginAccountsPreviewQueryOptions = {}): UseQueryResult<FailedLoginsDataPreviewResponse> {
  const httpClient = useHttpClient();

  const fetchData = async (): Promise<FailedLoginsDataPreviewResponse> => {
    const params = getSearchQueryParams({
      searchTerm,
      sortBy,
      order,
      pageSize,
      pageNumber,
      days,
      status,
      mfaStatus,
      countries,
      LocationList: locationList,
      GroupList: groupList,
    });
    const endpoint = `${API_ENDPOINTS.FAILED_LOGIN_ACCOUNTS_PREVIEW
      }?${params.toString()}`;
    return httpClient.get<FailedLoginsDataPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'failedLoginAccountsDataPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      days,
      status,
      mfaStatus,
      countries,
      locationList,
      groupList,
    ],
    fetchData
  );
}

export function useFailedLoginAccountsFiltersQuery(days: string) {
  const httpClient = useHttpClient();
  const fetchFilters =
    async (): Promise<FailedLoginAccountsFiltersResponse> => {
      const params = getSearchQueryParams({ days });
      const endpoint = `${API_ENDPOINTS.FAILED_LOGIN_ACCOUNTS_FILTER
        }?${params.toString()}`;
      return httpClient.get<FailedLoginAccountsFiltersResponse>(endpoint);
    };

  return useQuery(['failedLoginAccountsFilters', days], fetchFilters);
}

export const useHelixIntegrationMutation = () => {
  const usersClient = useHttpClient();
  return useMutation((data: HelixData) =>
    usersClient.post(`${API_ENDPOINTS.SERVICE_INTEGRATIONS_HELIX}`, {
      ...data,
    })
  );
};

export function useMailboxesForwardingRulesSnapshotQuery(): UseQueryResult<MailboxesForwardingSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<MailboxesForwardingSnapshotResponse> => {
    const endpoint = `${API_ENDPOINTS.MAILBOX_FORWARDING_RULE_STATUS}`;

    return httpClient.get<MailboxesForwardingSnapshotResponse>(endpoint);
  };

  return useQuery(['mailboxesForwardingRulesSnapshot'], fetchData);
}

export function useMailboxesForwardingAccountTypeSnapshotQuery(): UseQueryResult<MailboxesForwardingSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<MailboxesForwardingSnapshotResponse> => {
    const endpoint = `${API_ENDPOINTS.MAILBOX_FORWARDING_ACCOUNT_TYPE}`;

    return httpClient.get<MailboxesForwardingSnapshotResponse>(endpoint);
  };

  return useQuery(['mailboxesForwardingAccountTypeSnapshot'], fetchData);
}

export function useMailboxForwardingDataPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  accountType,
  locationList,
  groupList,
}: MailboxForwardingDataPreviewQueryOptions = {}): UseQueryResult<MailboxForwardingDataPrevieResponse> {
  const httpClient = useHttpClient();

  const fetchData = async (): Promise<MailboxForwardingDataPrevieResponse> => {
    const params = getSearchQueryParams({
      searchTerm,
      sortBy,
      order,
      pageSize,
      pageNumber,
      accountType,
      LocationList: locationList,
      GroupList: groupList,
    });
    const endpoint = `${API_ENDPOINTS.MAILBOX_FORWARDING_PREVIEW
      }?${params.toString()}`;
    return httpClient.get<MailboxForwardingDataPrevieResponse>(endpoint);
  };

  return useQuery(
    [
      'mailboxForwardingDataPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      accountType,
      locationList,
      groupList,
    ],
    fetchData
  );
}

export function useCallForwardingDestinationsPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
}: BasePreviewQueryOptions = {}): UseQueryResult<CallForwardingDestinationsPreviewResponse> {
  const httpClient = useHttpClient();

  const fetchData = async (): Promise<CallForwardingDestinationsPreviewResponse> => {
    const params = getSearchQueryParams({
      searchTerm,
      sortBy,
      order,
      pageSize,
      pageNumber,
    });
    const endpoint = `${API_ENDPOINTS.CALL_FORWARDING_DESTINATIONS_PREVIEW
      }?${params.toString()}`;
    return httpClient.get<CallForwardingDestinationsPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'callForwardingDestinationsPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
    ],
    fetchData
  );
}

export function useOutdatedBrowserFilters(days: string) {
  const httpClient = useHttpClient();
  const fetchFilters = async (): Promise<OutdatedBrowserFiltersResponse> => {
    const params = getSearchQueryParams({ days });
    const endpoint = `${API_ENDPOINTS.OUTDATED_BROWSERS_FILTERS
      }?${params.toString()}`;
    return httpClient.get<OutdatedBrowserFiltersResponse>(endpoint);
  };

  return useQuery(['outdatedBrowserFilters', days], fetchFilters);
}

export function useOutdatedBrowsersActivity({
  browserType,
  accountType,
  days,
  groupList,
  locationList
}: OutdatedBrowsersQueryOptions = {}): UseQueryResult<OutdatedBrowserActivityResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<OutdatedBrowserActivityResponse> => {
    const params = getSearchQueryParams({
      days,
      browserType,
      accountType,
      groupList,
      locationList
    });
    const endpoint = `${API_ENDPOINTS.OUTDATED_BROWSERS_ACTIVITY
      }?${params.toString()}`;
    return httpClient.get<OutdatedBrowserActivityResponse>(endpoint);
  };

  return useQuery(
    ['outdatedBrowsersActivity', { accountType, browserType, days, groupList, locationList }],
    fetchData
  );
}

export function useOutdatedBrowserSnapshot({
  accountType, browserType,
  days, groupList, locationList
}: OutdatedBrowsersQueryOptions) {
  const httpClient = useHttpClient();
  const fetchFilters = async (): Promise<OutdatedBrowserSnapshotResponse> => {
    const params = getSearchQueryParams({
      days,
      browserType,
      accountType,
      groupList,
      locationList
    });
    const endpoint = `${API_ENDPOINTS.OUTDATED_BROWSERS_SNAPSHOT
      }?${params.toString()}`;
    return httpClient.get<OutdatedBrowserSnapshotResponse>(endpoint);
  };

  return useQuery(['outdatedBrowserSnapshots', { accountType, browserType, days, groupList, locationList }], fetchFilters);
}

export function useOutdatedBrowsersUserTypes({
  accountType, browserType,
  days, groupList, locationList
}: OutdatedBrowsersQueryOptions) {
  const httpClient = useHttpClient();
  const fetchFilters = async (): Promise<OutdatedBrowserUserTypesResponse> => {
    const params = getSearchQueryParams({
      days,
      browserType,
      accountType,
      groupList,
      locationList
    });
    const endpoint = `${API_ENDPOINTS.OUTDATED_BROWSERS_USER_TYPES
      }?${params.toString()}`;
    return httpClient.get<OutdatedBrowserUserTypesResponse>(endpoint);
  };

  return useQuery(['outdatedBrowsersUserTypes', { accountType, browserType, days, groupList, locationList }], fetchFilters);
}

export function useOutdatedBrowserAccountsPreviewQuery({
  searchTerm,
  sortBy,
  order,
  days,
  pageSize = 20,
  pageNumber = 1,
  accountType,
  browserType,
  locationList,
  groupList,
}: OutdatedBrowsersPreviewQueryOptions = {}): UseQueryResult<OutdatedBrowserAccountsPreviewResponse> {
  const httpClient = useHttpClient();

  const fetchData =
    async (): Promise<OutdatedBrowserAccountsPreviewResponse> => {
      const params = getSearchQueryParams({
        searchTerm,
        days,
        sortBy,
        order,
        pageSize,
        pageNumber,
        accountType,
        browserType,
        LocationList: locationList,
        GroupList: groupList,
      });
      const endpoint = `${API_ENDPOINTS.OUTDATED_BROWSER_ACCOUNTS_PREVIEW
        }?${params.toString()}`;
      return httpClient.get<OutdatedBrowserAccountsPreviewResponse>(endpoint);
    };

  return useQuery(
    [
      'outdatedBrowserAccountsPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      days,
      accountType,
      locationList,
      groupList,
      browserType,
    ],
    fetchData
  );
}

export function useOutdatedBrowsersPreviewQuery({
  searchTerm,
  sortBy,
  order,
  days,
  pageSize = 20,
  pageNumber = 1,
  accountType,
  browserType,
  locationList,
  groupList,
}: OutdatedBrowsersPreviewQueryOptions = {}): UseQueryResult<OutdatedBrowsersPreviewResponse> {
  const httpClient = useHttpClient();

  const fetchData = async (): Promise<OutdatedBrowsersPreviewResponse> => {
    const params = getSearchQueryParams({
      searchTerm,
      days,
      sortBy,
      order,
      pageSize,
      pageNumber,
      accountType,
      browserType,
      LocationList: locationList,
      GroupList: groupList,
    });
    const endpoint = `${API_ENDPOINTS.OUTDATED_BROWSERS_PREVIEW
      }?${params.toString()}`;
    return httpClient.get<OutdatedBrowsersPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'outdatedBrowsersPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      days,
      accountType,
      locationList,
      groupList,
      browserType,
    ],
    fetchData
  );
}

export function useOutdatedOSFiltersQuery(days: string) {
  const httpClient = useHttpClient();
  const fetchFilters = async (): Promise<OutdatedOSFiltersResponse> => {
    const params = getSearchQueryParams({ days });
    const endpoint = `${API_ENDPOINTS.OUTDATED_OS_FILTER}?${params.toString()}`;
    return httpClient.get<OutdatedOSFiltersResponse>(endpoint);
  };

  return useQuery(['outdatedOSFilters', days], fetchFilters);
}

export function useOutdatedOSAccountsPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  days,
  accountType,
  OSType,
  locationList,
  groupList,
}: OutdatedOSAccountsPreviewQueryOptions = {}): UseQueryResult<OutdatedOSAccountsPreviewResponse> {
  const httpClient = useHttpClient();

  const fetchData = async (): Promise<OutdatedOSAccountsPreviewResponse> => {
    const params = getSearchQueryParams({
      searchTerm,
      sortBy,
      order,
      pageSize,
      pageNumber,
      days,
      OSType,
      accountType,
      LocationList: locationList,
      GroupList: groupList,
    });
    const endpoint = `${API_ENDPOINTS.OUTDATED_OS_ACCOUNTS_PREVIEW
      }?${params.toString()}`;
    return httpClient.get<OutdatedOSAccountsPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'outdatedOSAccountsDataPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      days,
      OSType,
      accountType,
      locationList,
      groupList,
    ],
    fetchData
  );
}

export function useOutdatedOSDataPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  days,
  accountType,
  OSType,
  locationList,
  groupList,
}: OutdatedOSAccountsPreviewQueryOptions = {}): UseQueryResult<OutdatedOSAccountsPreviewResponse> {
  const httpClient = useHttpClient();

  const fetchData = async (): Promise<OutdatedOSAccountsPreviewResponse> => {
    const params = getSearchQueryParams({
      searchTerm,
      sortBy,
      order,
      pageSize,
      pageNumber,
      days,
      OSType,
      accountType,
      LocationList: locationList,
      GroupList: groupList,
    });
    const endpoint = `${API_ENDPOINTS.OUTDATED_OS_DATA_PREVIEW
      }?${params.toString()}`;
    return httpClient.get<OutdatedOSAccountsPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'outdatedOSDataPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      days,
      OSType,
      accountType,
      locationList,
      groupList,
    ],
    fetchData
  );
}

export function useFailedLoginAttempts({
  days,
}: UseSuspiciousActivityEventsQueryOptions = {}): UseQueryResult<EventsData[]> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<EventsData[]> => {
    const params = new URLSearchParams();

    if (days) {
      params.append('Days', days);
    }

    return httpClient.get<EventsData[]>(
      `${API_ENDPOINTS.FAILED_LOGIN_DATA_ATTEMPTS_MAP}?${params.toString()}`
    );
  };

  return useQuery(['failedLoginAttemptsMap', days], fetchSnapshot);
}

export function useSuspiciousActivityEvents({
  days,
}: UseSuspiciousActivityEventsQueryOptions = {}): UseQueryResult<EventsData[]> {
  const httpClient = useHttpClient();
  const fetchSnapshot = async (): Promise<EventsData[]> => {
    const params = new URLSearchParams();

    if (days) {
      params.append('Days', days);
    }

    return httpClient.get<EventsData[]>(
      `${API_ENDPOINTS.SUSPICIOUS_ACTIVITY_COUNTRIES}?${params.toString()}`
    );
  };

  return useQuery(['suspiciousActivityEvents', days], fetchSnapshot);
}

export function useDelegatedMailboxLeaderboardQuery({
  days,
}: {
  days?: string;
}): UseQueryResult<MailboxDelegatesLeaderboardResponse> {
  const httpClient = useHttpClient();
  const params = getSearchQueryParams({ days });
  const fetchRooms = async (): Promise<MailboxDelegatesLeaderboardResponse> => {
    return httpClient.get<MailboxDelegatesLeaderboardResponse>(
      `${API_ENDPOINTS.DELEGATED_MAILBOX_LEADERBOARD}?${params.toString()}`
    );
  };

  return useQuery(['delegatedMailboxLeaderboard', days], fetchRooms);
}

export function useDelegatedMailboxFilterQuery({
  days,
}: {
  days?: string;
}): UseQueryResult<MailboxDelegatesFilterResponse> {
  const httpClient = useHttpClient();
  const params = getSearchQueryParams({ days });
  const fetchData = async (): Promise<MailboxDelegatesFilterResponse> => {
    return httpClient.get<MailboxDelegatesFilterResponse>(
      `${API_ENDPOINTS.DELEGATES_FILTER}?${params.toString()}`
    );
  };

  return useQuery(['delegatedMailboxFilter', days], fetchData);
}

export function useDelegatedMailboxDataSnapshotQuery({
  days,
}: {
  days?: string;
}): UseQueryResult<ExternalTeamsAccessResponse> {
  const httpClient = useHttpClient();
  const params = getSearchQueryParams({ days });
  const fetchData = async (): Promise<ExternalTeamsAccessResponse> => {
    const endpoint = `${API_ENDPOINTS.DELEGATED_MAILBOX_SNAPSHOT
      }?${params.toString()}`;
    return httpClient.get<ExternalTeamsAccessResponse>(endpoint);
  };

  return useQuery(['delegatedMailboxDataSnapshot', days], fetchData);
}

export function useDelegatedMailboxAccountsSnapshotQuery({
  days,
}: {
  days?: string;
}): UseQueryResult<ExternalTeamsAccessResponse> {
  const httpClient = useHttpClient();
  const params = getSearchQueryParams({ days });
  const fetchData = async (): Promise<ExternalTeamsAccessResponse> => {
    return httpClient.get<ExternalTeamsAccessResponse>(
      `${API_ENDPOINTS.DELEGATED_MAILBOX_ACCOUNTS_SNAPSHOT
      }?${params.toString()}`
    );
  };

  return useQuery(['delegatedMailboxAccountsSnapshot', days], fetchData);
}

export function useDelegatesDataPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  days,
  mailboxOwners,
  locationList,
  groupList,
}: MailboxDelegatesPreviewQueryOptions = {}): UseQueryResult<MailboxDelegatesPreviewResponse> {
  const httpClient = useHttpClient();

  const fetchData = async (): Promise<MailboxDelegatesPreviewResponse> => {
    const params = getSearchQueryParams({
      searchTerm,
      sortBy,
      order,
      pageSize,
      pageNumber,
      days,
      MailboxOwners: mailboxOwners,
      LocationList: locationList,
      GroupList: groupList,
    });
    const endpoint = `${API_ENDPOINTS.DELEGATES_PREVIEW
      }?${params.toString()}`;
    return httpClient.get<MailboxDelegatesPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'mailboxDelegatesDataPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      days,
      mailboxOwners,
      locationList,
      groupList,
    ],
    fetchData
  );
}

export function useDelegatedMailboxDataPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  days,
}: MailboxDelegatesPreviewQueryOptions = {}): UseQueryResult<DelegateMailboxesPreviewResponse> {
  const httpClient = useHttpClient();

  const fetchData = async (): Promise<DelegateMailboxesPreviewResponse> => {
    const params = getSearchQueryParams({
      searchTerm,
      sortBy,
      order,
      pageSize,
      pageNumber,
      days,
    });
    const endpoint = `${API_ENDPOINTS.DELEGATED_MAILBOX_PAGE
      }?${params.toString()}`;
    return httpClient.get<DelegateMailboxesPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'delegatedMailboxDataPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      days,
    ],
    fetchData
  );
}

export function useCountriesFiltersQuery() {
  const httpClient = useHttpClient();
  const fetchCountriesFilters =
    async (): Promise<CountriesFiltersResponse> => {
      return httpClient.get<CountriesFiltersResponse>(
        `${API_ENDPOINTS.COUNTRIES_FILTER}`
      );
    };

  return useQuery(['countriesFilters'], fetchCountriesFilters);
}

export function useCallForwardingDataSnapshotQuery(): UseQueryResult<CallForwardingDestinationsSnapshotResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<CallForwardingDestinationsSnapshotResponse> => {
    const endpoint = `${API_ENDPOINTS.CALL_FORWARDING_DESTINATIONS_SNAPSHOT}`;
    return httpClient.get<CallForwardingDestinationsSnapshotResponse>(endpoint);
  };

  return useQuery(['callForwardingDestinationsSnapshot'], fetchData);
}

export function useCallForwardingDestinationsFiltersQuery() {
  const httpClient = useHttpClient();
  const fetchFilters =
    async (): Promise<FailedLoginAccountsFiltersResponse> => {
      const endpoint = `${API_ENDPOINTS.CALL_FORWARDING_DESTINATIONS_FILTER}`;
      return httpClient.get<FailedLoginAccountsFiltersResponse>(endpoint);
    };
  return useQuery(['callForwardingDestinationsFilters'], fetchFilters);
}

export function useCallQueuesPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  Modes,
}: CallQueuesPreviewQueryOptions = {}): UseQueryResult<CallQueuePreviewResponse> {
  const httpClient = useHttpClient();

  const fetchData = async (): Promise<CallQueuePreviewResponse> => {
    const params = getSearchQueryParams({
      searchTerm,
      sortBy,
      order,
      pageSize,
      pageNumber,
      Modes
    });
    const endpoint = `${API_ENDPOINTS.CALL_QUEUES_PREVIEW
      }?${params.toString()}`;
    return httpClient.get<CallQueuePreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'callQueuesDataPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      Modes
    ],
    fetchData
  );
}

export function useCallQueuesFiltersQuery() {
  const httpClient = useHttpClient();
  const fetchCallQueuesFilters =
    async (): Promise<CallQueuesFiltersResponse> => {
      return httpClient.get<CallQueuesFiltersResponse>(
        `${API_ENDPOINTS.CALL_QUEUES_FILTER}`
      );
    };

  return useQuery(['callQueuesFilters'], fetchCallQueuesFilters);
}

export function useCallQueueSnapshotQuery(): UseQueryResult<CallQueueResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<CallQueueResponse> => {
    return httpClient.get<CallQueueResponse>(
      API_ENDPOINTS.CALL_QUEUES_SNAPSHOT
    );
  };

  return useQuery(['callQueueSnapshot'], fetchData);
}

export function useEmptyCallQueueSnapshotQuery(): UseQueryResult<EmptyCallQueueResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<EmptyCallQueueResponse> => {
    return httpClient.get<EmptyCallQueueResponse>(
      API_ENDPOINTS.EMPTY_CALL_QUEUES_SNAPSHOT
    );
  };

  return useQuery(['configuredCallQueueSnapshot'], fetchData);
}

export function useConfiguredTrunksPreviewQuery({
  searchTerm,
  sortBy,
  order,
  pageSize = 20,
  pageNumber = 1,
  TrunkNameList,
  TrunkTypeList,
}: ConfiguredTrunksPreviewQueryOptions = {}): UseQueryResult<ConfiguredTrunksPreviewResponse> {
  const httpClient = useHttpClient();

  const fetchData = async (): Promise<ConfiguredTrunksPreviewResponse> => {
    const params = getSearchQueryParams({
      searchTerm,
      sortBy,
      order,
      pageSize,
      pageNumber,
      TrunkNameList,
      TrunkTypeList,
    });
    const endpoint = `${API_ENDPOINTS.GENERATED_TRUNKS_PAGE
      }?${params.toString()}`;
    return httpClient.get<ConfiguredTrunksPreviewResponse>(endpoint);
  };

  return useQuery(
    [
      'configuredTrunksDataPreview',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      TrunkNameList,
      TrunkTypeList,
    ],
    fetchData
  );
}

export function useConfiguredTrunksFilterQuery(): UseQueryResult<ConfiguredTrunksFilterResponse> {
  const httpClient = useHttpClient();
  const fetchData = async (): Promise<ConfiguredTrunksFilterResponse> => {
    return httpClient.get<ConfiguredTrunksFilterResponse>(
      `${API_ENDPOINTS.GENERATED_TRUNKS_FILTERS}`
    );
  };

  return useQuery(['configuredTrunksFilter'], fetchData);
}
