import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import {
  useAddDashboardTile,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useSecureScoreSnapshotQuery } from '@hooks/security';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';

export const MicrosoftSecureScore: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.MicrosoftSecureScore,
    ''
  );
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const isOnDashboardPage = !!id;
  // data hook
  const { data: secureScoreData, isLoading: isSecureScoreLoading } =
    useSecureScoreSnapshotQuery();

  return (
    <NumberTile
      dropdownMenuItemClasses={'!w-[300px]'}
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      key={id}
      sortData={undefined}
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      headerTitle={'Microsoft Secure Score'}
      isLoading={isSecureScoreLoading}
      data={
        secureScoreData
          ? [
              `${secureScoreData.scorePercentage.toFixed(1) || 0}%`,
              secureScoreData.changePercentage || 0,
            ]
          : []
      }
      percentageLabel=""
      buttonLabel="Secure Score data"
      linkPath={ANALYTICS_NAVIGATION_MAP['secureScore']}
      handleButtonClick={handleButtonClick()}
    />
  );
};
