import { Card } from '@components/ui';
import styles from './styles.module.css';

export type TextTileProps = {
  headerTitle?: string;
  headerImage?: string;
  classNames?: string;
  children: React.ReactElement;
};

export const TextTile: React.FC<TextTileProps> = ({
  headerTitle,
  classNames,
  children,
  headerImage,
}) => {
  return (
    <Card classNames={`${classNames}`}>
      <Card.Header classNames={styles.cardHeader}>
        <div className={styles.cardHeaderContainer}>
          {headerImage && (
            <img src={headerImage} alt="text tile image" className="h-[36px]" />
          )}
          {headerTitle && <span>{headerTitle}</span>}
        </div>
      </Card.Header>

      <Card.Content classNames={styles.cardContent}>{children}</Card.Content>
    </Card>
  );
};
