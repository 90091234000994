import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { useMessagesSentSnapshotQuery } from '@hooks/adoption';
import { useAddDashboardTile, useRemoveDashboardTile } from '@hooks/dashboard';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { formatNumberWithSuffix } from '@utils/index';
import { useState } from 'react';

type Params = {
  days: string;
};

export const TotalMessagesSent: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.TotalMessagesSent,
    parameters
  );
  const isOnDashboardPage = !!id;
  const {
    data: messagesSentDataSnapshot,
    isLoading: isMessagesSentSnapshotLoading,
  } = useMessagesSentSnapshotQuery(parameters);

  return (
    parameters && (
      <NumberTile
        dropdownMenuItemClasses={'!w-[300px]'}
        classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
        key={id}
        sortData={[]}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        headerTitle={'Total messages sent'}
        isLoading={isMessagesSentSnapshotLoading}
        data={
          messagesSentDataSnapshot
            ? [formatNumberWithSuffix(messagesSentDataSnapshot.value)]
            : []
        }
      />
    )
  );
};
