import {
  DEFAULT_PAGE_SIZE,
  LAST_DAYS_SORT_BY,
  ORG_GROUPS_ACTIVITY_USER_SORT_BY,
  ORG_GROUPS_ACTIVITY_USER_TABLE_COLUMNS,
} from '@common/constants';
import { OrderBy } from '@common/types';
import { OverlayPage, TableWrapper } from '@components/partials';
import { Dropdown } from '@components/ui/Dropdown';
import { useOrgGroupActivityUserPreviewQuery } from '@hooks/adoption';
import { PeopleSortBy } from '@hooks/people/types';
import { useIsDesktop, useIsMobile } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';
import { formatDate, useTableData } from '@utils/index';
import React, { useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import styles from './styles.module.css';
import { useCreateCellValue } from './utils';
import { OrgGroupActivityUserSnapshot } from '@pages/Dashboard/tiles/OrgGroupActivityUserSnapshot';
import { OrgGroupActivityUserMeetingsSnapshot } from '@pages/Dashboard/tiles/OrgGroupActivityUserMeetingsSnapshot';
import { OrgGroupActivityUserMessagesSnapshot } from '@pages/Dashboard/tiles/OrgGroupActivityUserMessagesSnapshot';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { RecommendationCard } from '@pages/Dashboard/tiles/helpers/RecommendationCard';
import useDownloadCsv from '@hooks/utils/export';
import { API_ENDPOINTS } from '@api/ApiEndpoints';

const pageSize = DEFAULT_PAGE_SIZE;
const floatingSortDropdownData = LAST_DAYS_SORT_BY;

const OrgGroupsActivityUserPage: React.FC = () => {
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const redirectPath = useNavSourceRedirect('/insights-and-analytics/adoption');
  const [search, setSearch] = useState('');
  const { pageNumber, setPageNumber } = usePagination();
  const [days, setDays] = useState('30');
  const [daysSelectedLabel, setDaysSelectedLabel] = useState(
    floatingSortDropdownData[1].label || ''
  );
  const [daysSelectedIndex, setDaysSelectedIndex] = useState(1);
  const [isDaysDropdownOpen, setIsDaysDropdownOpen] = useState(false);

  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
  });

  // table
  const query = useOrgGroupActivityUserPreviewQuery({
    days,
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
  });

  const getTableData = useTableData(
    query,
    ORG_GROUPS_ACTIVITY_USER_TABLE_COLUMNS,
    useCreateCellValue
  );

  const handleIsDaysDropdownOpen = () => {
    setIsDaysDropdownOpen(!isDaysDropdownOpen);
  };

  const handleDropdownDaysOnChange = (value: string[], index: number) => {
    setDays(value[0]);
    setDaysSelectedIndex(index);
    setDaysSelectedLabel(floatingSortDropdownData[index].label);
    query.refetch();
  };

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.ORG_GROUPS_ACTIVITY_USER_PREVIEW}/export`,
    params: {
      days,
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
    },
    filename: `Clevr360_Microsoft_Teams:_activity/user_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Microsoft Teams: activity/user`}
    >
      <>
        {!isMobile && (
          <div className="absolute left-[40px] top-[113px]">
            <Dropdown>
              <Dropdown.TextHeader
                classNames="!bg-interfaceColor-5 !border !border-interfaceColor-100 rounded-full"
                label={daysSelectedLabel}
                handleOpen={handleIsDaysDropdownOpen}
              ></Dropdown.TextHeader>
              <Dropdown.List
                className="!z-[999] text-16"
                open={isDaysDropdownOpen}
                align="left"
              >
                {floatingSortDropdownData.map((item, index) => {
                  return (
                    <Dropdown.DefaultItem
                      key={index}
                      item={item}
                      currentIndex={index}
                      selectedIndex={daysSelectedIndex}
                      onChange={() =>
                        handleDropdownDaysOnChange(item.value, index)
                      }
                    ></Dropdown.DefaultItem>
                  );
                })}
              </Dropdown.List>
            </Dropdown>
          </div>
        )}
        <div className={styles.pageGrid}>
          {isDesktop && (
            <>
              <OrgGroupActivityUserSnapshot
                classNames="col-span-6 md:col-span-2 xl:col-span-1"
                params={JSON.stringify({ days, dataType: 'allactivity' })}
              />

              <OrgGroupActivityUserMessagesSnapshot
                classNames="col-span-6 md:col-span-2 xl:col-span-1"
                params={JSON.stringify({ days })}
              />

              <OrgGroupActivityUserMeetingsSnapshot
                classNames="col-span-6 md:col-span-2 xl:col-span-1"
                params={JSON.stringify({ days })}
              />

              <RecommendationCard
                insightDescription={'Microsoft Teams: activity/user'}
              />
            </>
          )}
        </div>
        <div className="mt-2">
          <TableWrapper
            search={search}
            setSearch={setSearch}
            searchPlaceholder={'Search by team name'}
            columns={ORG_GROUPS_ACTIVITY_USER_TABLE_COLUMNS}
            data={getTableData()}
            sortData={ORG_GROUPS_ACTIVITY_USER_SORT_BY}
            searchKey="search"
            query={query}
            refetchQuery={query.refetch}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setPageNumber={setPageNumber}
            isCollapsable={true}
            sliceColumns={1}
            floatingFilterButton={false}
            hasFilterBanner={false}
            isLicensePage={true}
            searchCountStatsLabel="organisational teams"
            floatingFilterButtonLeft={!isMobile ? '190px' : ''}
            customTotalCount={true}
            isDownloadAvailable={true}
            handleDownload={handleDownloadClick}
          />
        </div>
      </>
    </OverlayPage>
  );
};

export default OrgGroupsActivityUserPage;
