import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useFailedMSLoginAccountsSnapshotQuery } from '@hooks/failedLoginData';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { useEffect, useState } from 'react';

type Params = {
  days: string;
  mfaStatus: string;
};

export const FailedLoginAccounts: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
  isSnapshot,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.FailedLoginAccounts,
    parameters
  );
  const isOnDashboardPage = !!id;
  // data hook
  const {
    data: failedLoginAccounts,
    isLoading: isFailedLoginAccountsLoading,
    refetch,
  } = useFailedMSLoginAccountsSnapshotQuery(parameters);

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All MFA Status',
      value: 'all',
      action: () => handleSortDropdownChange('all'),
    },
    {
      id: '2',
      label: 'Enabled',
      value: 'Enabled',
      action: () => handleSortDropdownChange('Enabled'),
    },
    {
      id: '2',
      label: 'Enforced',
      value: 'Enforced',
      action: () => handleSortDropdownChange('Enforced'),
    },
    {
      id: '3',
      label: 'Disabled',
      value: 'Disabled',
      action: () => handleSortDropdownChange('Disabled'),
    },
  ];

  const compareMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleDropdownChange('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleDropdownChange('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleDropdownChange('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleDropdownChange('90'),
    },
  ];

  const handleDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const handleSortDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      mfaStatus: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  useEffect(() => {
    const updatedParams = JSON.parse(params);
    setParameters({
      days: updatedParams.days,
      mfaStatus: updatedParams.mfaStatus
    });
  }, [params]);

  return (
    parameters && (
      <NumberTile
        dropdownMenuItemClasses={'!w-[300px]'}
        initialSelectedItem={sortMenuItems.find(
          (item) => item.value === parameters.mfaStatus
        )}
        initialCompareSelectedItem={compareMenuItems.find(
          (item) => item.value === parameters.days
        )}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        classNames={
          classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
        }
        sortData={!isSnapshot ? sortMenuItems : []}
        compareData={!isSnapshot ? compareMenuItems : undefined}
        headerTitle={'Failed MS logins'}
        isLoading={isFailedLoginAccountsLoading}
        data={failedLoginAccounts ? Object.values(failedLoginAccounts) : []}
        buttonLabel={!isSnapshot ? 'Failed login data' : ''}
        linkPath={ANALYTICS_NAVIGATION_MAP['failedLoginAccounts']}
        handleButtonClick={handleButtonClick()}
        percentageLabel=""
        bottomLabel={`Of ${failedLoginAccounts?.failedMsLogins} total login attempts`}
        isArrowColorReversed={true}
      />
    )
  );
};
