import { EventsData } from '@hooks/security/types';
import { useMemo } from 'react';

export type LegendRange = {
  min: number;
  max: number;
  color: string;
};

const useTransformData = (responseData: EventsData[]) => {
  const data = useMemo(() => {
    return responseData
      ? responseData.reduce((acc, item) => {
          acc[item.countryName] = item.numberOfFailedLogins;
          return acc;
        }, {} as { [key: string]: number })
      : null;
  }, [responseData]);

  const getLegendRanges = (
    data: { [key: string]: number } | null
  ): LegendRange[] => {
    if (!data || data.length === 0) {
      return [];
    }
    const values = Object.values(data);
    const max = Math.max(...values);
    const step = Math.ceil(max / 5);

    const ret =  [
      { min: 4 * step + 1, max: Math.max(max, 5 * step + 1), color: '#d62229' },
      { min: 3 * step + 1, max: 4 * step, color: '#ec5e40' },
      { min: 2 * step + 1, max: 3 * step, color: '#fc954b' },
      { min: 1 * step + 1, max: 2 * step, color: '#fab95e' },
      { min: 1, max: step, color: '#ffd465' },
      { min: 0, max: 0, color: '#ffffff' },
    ];
    
    return ret.filter(x=>x.min <= max);
  };

  const legendRanges = useMemo(() => getLegendRanges(data), [data]);

  return { data, legendRanges };
};

export default useTransformData;
