import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useLicencesUsageTrendQuery } from '@hooks/licenses';
import { useState } from 'react';

import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import {
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { getTrendData, getTrendLabels } from '@utils/index';

type Params = {
  days: string;
  serviceType: string;
  vendorList: string[];
  licenseTypeList: string[];
};

export const ServiceUsageTrendOverTimeSnapshot: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.ServiceUsageTrendOverTimeSnapshot,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: licencesUsageTrendData,
    isLoading: isLicencesUsageTrendDataLoading,
    refetch,
  } = useLicencesUsageTrendQuery(parameters);

  const labels = getTrendLabels(
    licencesUsageTrendData?.trend || [],
    +parameters.days
  );

  const compareAgainstTrendData = getTrendData(
    licencesUsageTrendData?.compareAgainst || [],
    +parameters.days
  );

  const trendData = getTrendData(
    licencesUsageTrendData?.trend || [],
    +parameters.days
  );

  const handleDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      serviceType: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Messaging',
      value: 'messaging',
      action: () => handleDropdown('messaging'),
    },
    {
      id: '2',
      label: 'Email',
      value: 'email',
      action: () => handleDropdown('email'),
    },
    {
      id: '3',
      label: 'Video calls',
      value: 'videocalls',
      action: () => handleDropdown('videocalls'),
    },
    {
      id: '4',
      label: 'Phone calls',
      value: 'phonecalls',
      action: () => handleDropdown('phonecalls'),
    },
    {
      id: '5',
      label: 'File storage',
      value: 'filestorage',
      action: () => handleDropdown('filestorage'),
    },
  ];

  return (
    parameters && (
      <LineChartTile
        classNames={'col-span-6 md:col-span-6 xl:col-span-4'}
        sortData={sortMenuItems}
        initialSelectedItem={sortMenuItems.find(
          (item) => item.value === parameters.serviceType
        )}
        showCompareDropdown={false}
        legendLabels={['Microsoft']}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        tooltipLabel={['Microsoft']}
        dropdownMenuItemClasses={'!w-[300px]'}
        headerTitle={'Service usage: Trend over time Snapshot'}
        isLoading={isLicencesUsageTrendDataLoading}
        labels={labels}
        data={[trendData, compareAgainstTrendData]}
        compareLabel=""
        buttonLabel=""
        dataSetTypes={[LineChartTypes.Dots]}
        dataSetColors={['#00CF6C']}
        showDateRange={true}
      />
    )
  );
};
