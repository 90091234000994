import cn from 'classnames';
import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  cloneElement,
  isValidElement,
  Children,
  useState,
} from 'react';
import { Modal } from '../Modal';

import styles from './styles.module.css';
import { ArrowDownIcon, CheckCircleIcon } from '@components/ui/Icons';
import { useIsMobile } from '@hooks/utils/isMobile';
import { UserRoles } from '@hooks/users/types';
import useAuthStore from '@store/authStore';
import { ORDER_BY_VALUES } from '@common/constants';
export const TextHeaderContext =
  createContext<React.RefObject<HTMLDivElement> | null>(null);

const isSelected = (currentIndex: number, selectedIndex: number | number[]) => {
  if (Array.isArray(selectedIndex)) {
    return selectedIndex.includes(currentIndex);
  } else {
    return currentIndex === selectedIndex;
  }
};
interface BaseProps {
  children: ReactNode;
  className?: string;
}

export type DropdownData = {
  id?: string;
  label: string;
  value?: string[];
  icon?: React.ReactElement;
  onChange?: () => void;
  color?: string;
  disabled?: boolean;
  isHidden?: (row: any, role?: UserRoles, localAccountId?: string) => boolean;
  classNames?: string;
};

interface DropdownModalProps {
  handleModalOpen: () => void;
  handleModalClose: () => void;
  handleModalOnChange: (value: string[], index: number, id: string) => void;
  isModalOpen: boolean;
  headerValueMobile?: React.ReactElement;
  selectedIndex?: number | number[];
  data: DropdownData[];
  rowData?: any;
  classNames?: string;
  hasCheck?: boolean;
}

interface DropdownProps<T extends BaseDropdownItem> extends BaseProps {
  Header?: FC<BaseProps>;
  List?: FC<DropdownListProps>;
  Item?: FC<BaseProps>;
  TextHeader?: FC<TextHeaderProps>;
  IconHeader?: FC<IconHeaderProps>;
  DefaultItem?: FC<DefaultItemProps<T>>;
  ContextItem?: FC<ContextItemProps>;
  dropdownModalProps?: DropdownModalProps;
  open?: boolean;
}

interface DropdownListProps extends BaseProps {
  open?: boolean;
  align?: 'right' | 'left';
  closeDropdown?: () => void;
}

type TextHeaderElement = React.ReactElement<TextHeaderProps, typeof TextHeader>;
type ListElement = React.ReactElement<DropdownListProps, typeof List>;
type IconHeaderElement = React.ReactElement<IconHeaderProps, typeof IconHeader>;
type ContextItemElement = React.ReactElement<
  ContextItemProps,
  typeof ContextItem
>;

export const Dropdown: FC<DropdownProps<BaseDropdownItem>> & {
  Header: FC<BaseProps>;
  List: FC<DropdownListProps>;
  Item: FC<BaseProps>;
  TextHeader: FC<TextHeaderProps>;
  IconHeader: FC<IconHeaderProps>;
  DefaultItem: <T extends BaseDropdownItem>(
    props: DefaultItemProps<T>
  ) => JSX.Element;
  ContextItem: FC<ContextItemProps>;
} = ({ children, className, dropdownModalProps, open, ...rest }) => {
  const isMobile = useIsMobile();
  const classes = cn('relative', className);
  const textHeaderRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (open) setIsOpen(open);
  }, [open, setIsOpen]);

  const modifiedChildren = Children.map(children, (child) => {
    if (isValidElement(child)) {
      if (child.type === TextHeader) {
        const textHeaderChild = child as TextHeaderElement;
        return cloneElement(textHeaderChild, {
          handleOpen: toggleDropdown,
        });
      } else if (child.type === IconHeader) {
        const iconHeaderChild = child as IconHeaderElement;
        return cloneElement(iconHeaderChild, {
          handleOpen: toggleDropdown,
        });
      } else if (child.type === ContextItem) {
        const contextItemChild = child as ContextItemElement;
        return cloneElement(contextItemChild, {
          closeDropdown,
        });
      } else if (child.type === DefaultItem) {
        const defaultItemChild = child as React.ReactElement<
          DefaultItemProps<BaseDropdownItem>
        >;
        return cloneElement(defaultItemChild, {
          closeDropdown,
        });
      } else if (child.type === List) {
        const listChild = child as ListElement;
        return cloneElement(listChild, {
          open: isOpen,
          closeDropdown,
        });
      }
    }
    return child;
  });

  return (
    <div className={classes} {...rest}>
      {isMobile && dropdownModalProps ? (
        <DropdownModal {...dropdownModalProps} />
      ) : (
        <TextHeaderContext.Provider value={textHeaderRef}>
          {modifiedChildren}
        </TextHeaderContext.Provider>
      )}
    </div>
  );
};

const DropdownModal: FC<DropdownModalProps> = ({
  handleModalOpen,
  handleModalClose,
  handleModalOnChange,
  isModalOpen,
  headerValueMobile,
  selectedIndex,
  classNames,
  hasCheck = true,
  data,
  rowData,
}) => {
  const classes = cn(styles.dropdownModalButton, classNames);

  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;
  const userFullName = useAuthStore.getState().accounts[0].name || '';
  data.map((dataItem) => {
    if (dataItem.disabled && dataItem.icon) {
      dataItem.icon = cloneElement(dataItem.icon, {
        color: 'text-interfaceColor-30',
      });
      return dataItem;
    }
  });

  return (
    <>
      <div className={!classNames ? styles.dropdownModalButtonWrapper : ''}>
        <button
          onClick={handleModalOpen}
          className={classes}
          data-testid="header-btn"
        >
          {headerValueMobile}
        </button>
      </div>
      <Modal
        isOpen={isModalOpen}
        position="bottom"
        size="small"
        emptyContent={true}
        showCloseBtn={false}
        onClose={handleModalClose}
        contentClassName={'bg-white'}
      >
        <ul>
          {data.map((item, index) =>
            item.isHidden &&
            item.isHidden(rowData, userRole, userFullName) ? null : (
              <li
                key={item.label}
                className={`${styles.dropdownModalListItem} 
              ${
                item.color
                  ? item.color
                  : item.disabled
                  ? 'text-interfaceColor-30'
                  : ''
              } 
              ${item.disabled ? 'pointer-events-none' : ''}
              ${
                ORDER_BY_VALUES.includes(item?.value?.[0] ?? '')
                  ? 'bg-interfaceColor-10'
                  : ''
              }`}
                onClick={() =>
                  handleModalOnChange(
                    item.value as string[],
                    index,
                    item?.id ?? ''
                  )
                }
              >
                <span className="flex flex-row items-center">
                  {item.icon && <span className="mr-2">{item.icon}</span>}

                  {item.label}
                </span>
                {hasCheck &&
                  isSelected(index, selectedIndex ? selectedIndex : 0) && (
                    <CheckCircleIcon
                      color="text-primaryBrandColor-150"
                      classNames="h-6 w-6 p-1"
                    />
                  )}
              </li>
            )
          )}
        </ul>
      </Modal>
    </>
  );
};

const Header: FC<BaseProps> = ({ children, className, ...rest }) => {
  const classes = cn('inline-block', className);

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

const List: FC<DropdownListProps> = ({
  children,
  open = false,
  align = 'right',
  className,
  closeDropdown,
  ...rest
}) => {
  const [position, setPosition] = useState<'top' | 'bottom'>('bottom');
  const listRef = useRef<HTMLUListElement>(null);
  const [initialBottom, setInitialBottom] = useState<number | null>(null);
  const textHeaderRef = useContext(TextHeaderContext);
  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (!listRef?.current?.contains(event.target as Node) && closeDropdown) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [closeDropdown, listRef]);

  useEffect(() => {
    if (listRef.current) {
      const { bottom } = listRef.current.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;

      if (open && initialBottom === null) {
        setInitialBottom(bottom);
      }

      if (initialBottom !== null && initialBottom > windowHeight) {
        setPosition('top');
      } else {
        setPosition('bottom');
      }
    }
  }, [open, initialBottom]);

  const classes = cn(
    styles.listWrapper,
    align === 'right' ? 'right-0' : 'left-0',
    position === 'top'
      ? '!top-[unset] !bottom-[calc(100%+18px)]'
      : '!bottom-[unset] !top-[calc(100%+18px)]', // Adjust positioning based on 'top' or 'bottom'
    className
  );

  return open ? (
    <ul className={classes} ref={listRef} {...rest}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            ...child.props,
            closeDropdown,
          });
        }
        return child;
      })}
    </ul>
  ) : null;
};

const Item: FC<BaseProps> = ({ children, className, ...rest }) => {
  const classes = cn('', className);

  return (
    <li className={classes} {...rest}>
      {children}
    </li>
  );
};

type TextHeaderProps = {
  label: string;
  handleOpen: () => void;
  classNames?: string;
};

const TextHeader: FC<TextHeaderProps> = ({ label, handleOpen, classNames }) => {
  const classes = cn(classNames, styles.textHeaderWrapper);
  const textHeaderRef = useContext(TextHeaderContext);

  return (
    <div ref={textHeaderRef} className={classes}>
      <button onClick={handleOpen} className={styles.textHeaderButton}>
        {label} <ArrowDownIcon classNames={styles.textHeaderIcon} />
      </button>
    </div>
  );
};

type IconHeaderProps = {
  icon: React.ReactElement;
  handleOpen: () => void;
  classNames?: string;
};

const IconHeader: FC<IconHeaderProps> = ({ icon, handleOpen, classNames }) => {
  const classes = cn(classNames, styles.iconHeaderButton);
  const textHeaderRef = useContext(TextHeaderContext);

  return (
    <div ref={textHeaderRef}>
      <button onClick={handleOpen} className={classes}>
        {icon}
      </button>
    </div>
  );
};

export type BaseDropdownItem = {
  id?: number;
  label: string;
  value: unknown;
};

type DefaultItemProps<T extends BaseDropdownItem> = {
  item: T;
  currentIndex: number;
  selectedIndex: number | number[];
  onChange: (value: unknown, index: number) => void;
  closeDropdown?: () => void;
  isMultiSelectItem?: boolean;
};

const DefaultItem = <T extends BaseDropdownItem>({
  item,
  currentIndex,
  selectedIndex,
  onChange,
  closeDropdown,
  isMultiSelectItem = false,
}: DefaultItemProps<T>): JSX.Element => {
  return (
    <li
      onClick={() => {
        onChange(item.value, currentIndex);
        closeDropdown && closeDropdown();
      }}
      className={cn(
        styles.defaultItemWrapper,
        isMultiSelectItem ? 'bg-interfaceColor-10' : ''
      )}
    >
      <span>{item.label}</span>
      {isSelected(currentIndex, selectedIndex) ? (
        <CheckCircleIcon
          color="text-primaryBrandColor-150"
          classNames={styles.defaultItemIcon}
        />
      ) : null}
    </li>
  );
};

type ContextItemProps = {
  label: string;
  icon?: React.ReactElement;
  onChange: () => void;
  color?: string;
  disabled?: boolean;
  hidden?: boolean;
  classNames?: string;
  closeDropdown?: () => void;
};

const ContextItem: FC<ContextItemProps> = ({
  label,
  icon,
  onChange,
  color,
  disabled,
  hidden,
  classNames,
  closeDropdown,
}) => {
  const IconWithColor = icon
    ? cloneElement(icon, {
        color:
          disabled && !color
            ? 'text-interfaceColor-70'
            : color
            ? color
            : 'text-interfaceColor-100',
      })
    : undefined;

  return hidden ? null : (
    <li
      key={label}
      onClick={() => {
        if (!disabled) {
          onChange();
          closeDropdown && closeDropdown();
        }
      }}
      className={`${styles.contextItemWrapper} ${
        !disabled ? styles.contextItemHovered : ''
      } ${classNames ? classNames : ''}`}
    >
      {IconWithColor && (
        <div className={styles.contextItemIconWrapper}>{IconWithColor}</div>
      )}
      <span
        className={`${styles.contextItemLabel}
          ${disabled ? styles.contextItemDisabled : ''}
          ${color ? color : ''} `}
      >
        {label}
      </span>
    </li>
  );
};

// base
Dropdown.Header = Header;
Dropdown.List = List;
Dropdown.Item = Item;

// features
Dropdown.TextHeader = TextHeader;
Dropdown.IconHeader = IconHeader;
Dropdown.DefaultItem = DefaultItem;
Dropdown.ContextItem = ContextItem;
