import { OverlayPage } from '@components/partials';

import React from 'react';
import parse from 'html-react-parser';

import { useParams } from 'react-router';
import { useSecureScoreActionQuery } from '@hooks/security';
import { Card } from '@components/ui/Card';
import { Loader } from '@components/ui';

import './styles.css';

const MsSecureScoreActionPage: React.FC = () => {
  const { actionId } = useParams<{ actionId: string }>();

  const { data, isError, isLoading } = useSecureScoreActionQuery({
    actionId,
  });

  if (isLoading || isError) {
    return <Loader />;
  }

  const renderProgressBar = () => {
    if (data) {
      if (data?.on === true) {
        return (
          <div className="mt-2 flex h-2 w-full rounded bg-primaryBrandColor-100 "></div>
        );
      } else if (data?.on === false) {
        return <div className="mt-2 flex h-2 w-full rounded  bg-white"></div>;
      } else if (data.count !== null && data.total !== null) {
        const percentage = (data.count / data.total) * 100;
        return (
          <div className="mt-2 flex h-2 w-full rounded bg-white">
            <div
              className="h-full rounded bg-primaryBrandColor-100"
              style={{ width: `${percentage}%` }}
            ></div>
          </div>
        );
      } else {
        return null;
      }
    }
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      headerTitle={data?.title || ''}
    >
      <div className="details-ms-page flex w-full flex-col">
        <div className="grid h-screen grid-cols-5  gap-x-5 gap-y-5 md:gap-x-10">
          <div className="col-span-5 md:col-span-3">
            <div className="flex flex-col gap-y-4">
              <Card classNames="!w-auto !h-auto">
                <Card.Header>
                  <span className="text-28 text-interfaceColor-100">
                    Description
                  </span>
                </Card.Header>
                <Card.Content classNames="w-full">
                  <div className="flex flex-col gap-x-4 md:flex-row">
                    <div>
                      <span className="mr-1 text-16 font-semibold text-interfaceColor-100">
                        Score impact:
                      </span>
                      {data?.scoreImpact.toFixed(2)}%
                    </div>

                    <div>
                      <span className="mr-1 text-16 font-semibold  text-interfaceColor-100">
                        Category:
                      </span>
                      {data?.category}
                    </div>
                    <div>
                      <span className="mr-1 text-16 font-semibold text-interfaceColor-100">
                        Product:
                      </span>
                      {data?.service}
                    </div>
                  </div>
                  <div className="mt-3">{parse(data?.description || '')}</div>
                </Card.Content>
              </Card>
              {data?.remediation && (
                <Card classNames="!w-auto !h-auto">
                  <Card.Header>
                    <span className="text-28 text-interfaceColor-100">
                      Next steps
                    </span>
                  </Card.Header>
                  <Card.Content classNames="w-full">
                    <div className="mt-3">{parse(data?.remediation || '')}</div>
                  </Card.Content>
                </Card>
              )}
            </div>
          </div>

          <div className="col-span-5 md:col-span-2">
            <div className="flex flex-col gap-y-8">
              {data?.implementationStatus && (
                <Card classNames="!w-auto !bg-interfaceColor-20">
                  <Card.Header>
                    <span className="text-20 text-interfaceColor-100">
                      Implementation status
                    </span>
                  </Card.Header>
                  <Card.Content classNames="w-full">
                    {/* Here we need that logic */}
                    {renderProgressBar()}

                    <div className="mt-2">
                      {parse(data?.implementationStatus || '')}
                    </div>
                  </Card.Content>
                </Card>
              )}

              {data?.threats.length ? (
                <Card classNames="!w-auto !bg-interfaceColor-20">
                  <Card.Header>
                    <span className="text-20 text-interfaceColor-100">
                      Threats mitigated by this action
                    </span>
                  </Card.Header>
                  <Card.Content classNames="w-full">
                    <div className="mt-2 flex flex-row flex-wrap gap-x-4 gap-y-2">
                      {data?.threats.map((item) => {
                        return (
                          <span
                            key={item}
                            className="rounded-full border border-infoColor-30 bg-infoColor-10 px-3 py-[6px] text-infoColor-200"
                          >
                            {item}
                          </span>
                        );
                      })}
                    </div>
                  </Card.Content>
                </Card>
              ) : null}
              {data?.remediationImpact &&
                data?.remediationImpact !== 'Unknown' && (
                  <Card classNames="!w-auto !bg-interfaceColor-20">
                    <Card.Header>
                      <span className="text-20 text-interfaceColor-100">
                        Remediation impact
                      </span>
                    </Card.Header>
                    <Card.Content classNames="w-full">
                      <div className="mt-2">{data?.remediationImpact}</div>
                    </Card.Content>
                  </Card>
                )}
            </div>
          </div>
        </div>
      </div>
    </OverlayPage>
  );
};

export default MsSecureScoreActionPage;
