import { TilesProps } from '@common/types';
import { useOutdatedBrowsersUserTypes } from '@hooks/security';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useEffect, useState } from 'react';

type Params = {
  days: string;
  groupList?: string[];
  browserType?: string;
  accountType?: string;
  locationList?: string[];
};

export const OutdatedBrowserUserType: React.FC<TilesProps> = ({
  params,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const { data, isLoading, refetch } = useOutdatedBrowsersUserTypes(parameters);

  useEffect(() => {
    setParameters(JSON.parse(params));

    return () => {
      refetch();
    };
  }, [params, refetch]);

  return (
    <DoughnutChartTile
      classNames={classNames ? classNames : 'col-span-3 lg:col-auto'}
      contextData={[]}
      sortData={[]}
      headerTitle={'Users with outdated browsers'}
      dropdownMenuItemClasses={'!w-[300px]'}
      isLoading={isLoading}
      data={
        data ? [data.snapshot.Internal ?? 0, data?.snapshot.External ?? 0] : []
      }
      dataLabels={[`Internal users`, `External users`]}
      buttonLabel={''}
    />
  );
};
