import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { useAddDashboardTile, useRemoveDashboardTile } from '@hooks/dashboard';
import { useLicencesUsageCallTimeQuery } from '@hooks/licenses';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { calculateTotalSeconds } from '@utils/index';

export const ServiceUsageAvgTimeSpentOnCalls: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const { data: licencesCallTimeData, isLoading: isLicencesCallTimeLoading } =
    useLicencesUsageCallTimeQuery(JSON.parse(params));
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.ServiceUsageAvgTimeSpentOnCalls,
    ''
  );
  const isOnDashboardPage = !!id;

  return (
    <DoughnutChartTile
      dropdownMenuItemClasses={'!w-[300px]'}
      classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      headerTitle={'Service usage: Avg time spent on calls / person'}
      isLoading={isLicencesCallTimeLoading}
      data={
        licencesCallTimeData
          ? [
              calculateTotalSeconds(licencesCallTimeData.phoneCalls),
              calculateTotalSeconds(licencesCallTimeData.videoCalls),
            ]
          : []
      }
      dataLabels={['Phone calls', 'Video calls']}
      dataLabelValues={
        licencesCallTimeData
          ? [licencesCallTimeData.phoneCalls, licencesCallTimeData.videoCalls]
          : []
      }
    />
  );
};
