import {
  ANALYTICS_NAVIGATION_MAP,
  DashboardTiles,
  LICENSES_EXPIRING_BAR_LABELS,
} from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useLicencesExpiringSnapshotQuery } from '@hooks/licenses';
import { HorizontalBarChartTile } from '@pages/InsightsAndAnalytics/tiles/HorizontalChartTile';
import { useEffect, useState } from 'react';

type Params = {
  vendorList: string[];
};

export const LicensesExpiringSoon: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const [isLabelChartLoading, setisLabelChartLoading] = useState(true);
  const [licensesExpiringBarLabels, setLicensesExpiringBarLabels] = useState<
    string[]
  >([]);
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.LicensesExpiringSoon,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: licenseExpiringData,
    isLoading,
    refetch,
  } = useLicencesExpiringSnapshotQuery(parameters);

  const handleDropdown = (value: string[]) => {
    setParameters({
      vendorList: value,
    });

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify({
          vendorList: value,
        }),
        tileId: id,
      });

      refetch();
    }
  };

  useEffect(() => {
    setisLabelChartLoading(true);
    if (licenseExpiringData) {
      setLicensesExpiringBarLabels(
        LICENSES_EXPIRING_BAR_LABELS.map((label, index) => {
          return `${label} ${licenseExpiringData?.expirations[index].count}`;
        })
      );
      setisLabelChartLoading(false);
    }
  }, [licenseExpiringData]);

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All Vendors',
      value: 'All',
      action: () => handleDropdown(['Microsoft', 'RingCentral']),
    },
    {
      id: '2',
      label: 'Microsoft',
      value: 'Microsoft',
      action: () => handleDropdown(['Microsoft']),
    },
    {
      id: '3',
      label: 'RingCentral',
      value: 'RingCentral',
      action: () => handleDropdown(['RingCentral']),
    },
  ];

  return (
    parameters && (
      <HorizontalBarChartTile
        classNames={classNames ? classNames : 'col-span-6 xl:col-span-4'}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        sortData={sortMenuItems}
        dropdownMenuItemClasses={'!w-[300px]'}
        headerTitle={'Licenses expiring soon'}
        isLoading={isLoading || isLabelChartLoading}
        data={licenseExpiringData?.expirations.map((item) => item.count) || []}
        initialSelectedItem={sortMenuItems.find(
          (item) =>
            item.value ===
            (parameters.vendorList.length > 1
              ? 'All'
              : parameters.vendorList[0])
        )}
        dataLabels={licensesExpiringBarLabels}
        barBackgroundColors={[
          '#00CF6C',
          '#25A8D1',
          '#B65AD7',
          '#FFB800',
          '#54b534',
        ]}
        buttonLabel={'License expiry data'}
        linkPath={ANALYTICS_NAVIGATION_MAP['expiring']}
        handleButtonClick={handleButtonClick()}
      />
    )
  );
};
