import { DEFAULT_CHART_COLORS } from '@common/constants';
import { Card } from '@components/ui';
import LineChart from '@components/ui/Charts/LineChart';
import { DropdownMenu, MenuItem } from '@components/ui/DropdownMenu';
import { HamburgerIcon } from '@components/ui/Icons';
import { Loader } from '@components/ui/Loader';
import { checkIfDataSetIsEmpty, shortenYearInDate } from '@utils/index';
import { ChartData } from 'chart.js';
import styles from './styles.module.css';
import { TileFooter } from '../partials/TileFooter';

export enum LineChartTypes {
  Line = 'line',
  Dots = 'dots',
}

export enum LineChartTickFormat {
  Date = 0,
  Number = 1,
  Percentage = 2,
}

export enum LineChartTooltipFormat {
  Storage = 0,
}

const getFirstLabel = (labels: string[]) => {
  return labels.find((l) => l !== '');
};

export type LineChartTileProps = {
  sortData: MenuItem[];
  compareData?: MenuItem[];
  contextData?: MenuItem[];
  headerTitle: string;
  isLoading: boolean;
  data: (number | null)[][];
  buttonLabel?: string;
  handleButtonClick?: () => void;
  classNames?: string;
  labels?: string[];
  tooltipLabel?: string | string[];
  legendLabels?: string[];
  compareLabel?: string;
  dataSetTypes?: string[];
  dataSetColors?: string[];
  showCompareDropdown?: boolean;
  showAgainstDropdown?: boolean;
  dropdownMenuItemClasses?: string;
  initialSelectedItem?: MenuItem;
  initialCompareSelectedItem?: MenuItem;
  animation?: boolean;
  showYTicks?: boolean;
  showXTicks?: boolean;
  chartHeight?: number;
  isDotted?: boolean;
  xTickFormat?: LineChartTickFormat;
  yTickFormat?: LineChartTickFormat;
  adjustedPosition?: React.CSSProperties;
  tooltipLabelFormat?: LineChartTooltipFormat;
  dataSetLabel?: string;
  dropdownSortMenuItemClasses?: string;
  hasPositiveValues?: boolean;
  isPrintPreview?: boolean;
  showDateRange?: boolean;
  linkPath?: string;
};

export const LineChartTile: React.FC<LineChartTileProps> = ({
  sortData,
  compareData,
  headerTitle,
  isLoading = false,
  data = [],
  contextData,
  buttonLabel,
  handleButtonClick,
  classNames,
  labels,
  tooltipLabel = 'dataset',
  compareLabel = 'compared to',
  dataSetTypes = [LineChartTypes.Line, LineChartTypes.Dots],
  dataSetColors = [],
  legendLabels = [],
  showCompareDropdown = true,
  showAgainstDropdown = true,
  dropdownMenuItemClasses,
  dropdownSortMenuItemClasses,
  initialSelectedItem,
  initialCompareSelectedItem,
  animation = true,
  showYTicks = false,
  showXTicks = false,
  chartHeight = 88,
  isDotted = false,
  xTickFormat,
  yTickFormat,
  adjustedPosition,
  tooltipLabelFormat,
  dataSetLabel,
  hasPositiveValues = false,
  isPrintPreview = false,
  showDateRange = false,
  linkPath,
}) => {
  if (dataSetColors.length === 0) {
    dataSetColors = DEFAULT_CHART_COLORS;
  }

  const chartData: ChartData<'line'> = {
    labels: labels,
    datasets: data.map((dataItem, index) => ({
      label: Array.isArray(tooltipLabel) ? tooltipLabel[index] : tooltipLabel,
      data: dataItem as number[],
      backgroundColor: dataSetColors[index],
      borderColor: dataSetColors[index],
      borderWidth: 2,
      pointRadius: dataSetTypes[index] === LineChartTypes.Line ? 0 : undefined,
      pointBorderWidth:
        dataSetTypes[index] === LineChartTypes.Dots ? 0 : undefined,
    })),
  };

  return (
    <Card classNames={`!w-auto ${classNames}`}>
      <Card.Header classNames={styles.cardHeader}>
        <div className={styles.cardHeaderContainer}>
          <span>{headerTitle}</span>
          {contextData?.length ? (
            <DropdownMenu
              items={contextData}
              buttonProps={{ icon: <HamburgerIcon></HamburgerIcon> }}
              dropdownMenuClasses={dropdownMenuItemClasses}
            ></DropdownMenu>
          ) : null}
        </div>
        <div className={styles.headerWrapper}>
          {showAgainstDropdown && showCompareDropdown && (
            <div className={styles.dropdownWrapper}>
              {showAgainstDropdown && sortData.length > 0 && (
                <DropdownMenu
                  dropdownMenuClasses={dropdownSortMenuItemClasses}
                  adjustedPosition={adjustedPosition}
                  items={sortData}
                  showSelectedItem={true}
                  buttonProps={{
                    label: initialSelectedItem?.label || sortData[0].label,
                  }}
                  initialSelectedItem={initialSelectedItem || sortData[0]}
                ></DropdownMenu>
              )}
              {compareLabel && (
                <span className={styles.compareLabel}>{compareLabel}</span>
              )}
              {showCompareDropdown && compareData && (
                <DropdownMenu
                  items={compareData}
                  hasComparison={true}
                  showSelectedItem={true}
                  buttonProps={{
                    label:
                      initialCompareSelectedItem?.label || sortData[0].label,
                  }}
                  dropdownMenuClasses={'!w-[220px]'}
                  initialSelectedItem={
                    initialCompareSelectedItem || sortData[0]
                  }
                ></DropdownMenu>
              )}
            </div>
          )}

          {showDateRange && labels && labels?.length >= 2 && (
            <div className={styles.dateRange}>
              Date range: {shortenYearInDate(getFirstLabel(labels) || '')} -{' '}
              {shortenYearInDate(labels[labels.length - 1])}
            </div>
          )}
        </div>
      </Card.Header>

      <Card.Content classNames={styles.cardContent}>
        {isLoading ? (
          <div className="flex w-full justify-center">
            <Loader />
          </div>
        ) : (
          <div className={styles.cardDetails}>
            <div className="w-full bg-interfaceColor-5 p-2">
              {checkIfDataSetIsEmpty(data) ? (
                <LineChart
                  hasPositiveValues={hasPositiveValues}
                  isDotted={isDotted}
                  showYTicks={showYTicks}
                  showXTicks={showXTicks}
                  animation={animation}
                  height={chartHeight}
                  data={chartData}
                  xTickFormat={xTickFormat}
                  yTickFormat={yTickFormat}
                  tooltipLabelFormat={tooltipLabelFormat}
                  dataSetLabel={dataSetLabel}
                />
              ) : (
                <div className="my-3 flex flex-col items-center justify-center">
                  <h1 className="mb-2 rounded-full bg-interfaceColor-20 px-4 py-1 text-20 text-interfaceColor-100">
                    Insufficient data
                  </h1>
                  <p className="max-w-sm text-center text-interfaceColor-80">
                    Keep using Clevr360 to see how your
                    <span className="mx-1">{headerTitle}</span>
                    have changed over time
                  </p>
                </div>
              )}
            </div>
            {legendLabels && checkIfDataSetIsEmpty(data) && (
              <div className={styles.chartLegendWrapper}>
                {legendLabels.map((label, index) => (
                  <div key={`index${index}`} className={styles.chartLegendItem}>
                    <span
                      className={styles.chartLegendDot}
                      style={{ background: dataSetColors[index] }}
                    ></span>
                    <span className={isPrintPreview ? 'text-xs' : 'text-12'}>
                      {label}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </Card.Content>
      <TileFooter
        linkPath={linkPath}
        buttonLabel={buttonLabel}
        handleButtonClick={handleButtonClick}
      />
    </Card>
  );
};
