import { OverlayPage } from '@components/partials/OverlayPage';
import {
  useLicencesExpiredSnapshotBarChartQuery,
  useLicencesExpiredSnapshotQuery,
  useLicencesFiltersQuery,
  useLicensesExpiredPreviewQuery,
} from '@hooks/licenses';
import React, { useEffect, useState } from 'react';

import {
  AUTO_RENEW,
  DEFAULT_PAGE_SIZE,
  DashboardTiles,
  LICENSES_EXPIRED_SORT_BY,
  LICENSES_EXPIRED_TABLE_COLUMNS,
  RENEWAL_PERIOD,
  VENDORS,
} from '@common/constants';

import { OrderBy } from '@common/types';
import { TableWrapper } from '@components/partials';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { MenuItem } from '@components/ui/DropdownMenu';
import { DashboardIcon } from '@components/ui/Icons';
import { useDashboardTileSaveMutation } from '@hooks/dashboard';
import {
  LicensesExpiringSortBy,
  LicensesFilterData,
  LicensesFiltersResponse,
} from '@hooks/licenses/types';
import { useIsDesktop } from '@hooks/utils';
import { usePagination } from '@hooks/utils/pagination';
import {
  formatDate,
  getFilterValues,
  getFirstMatchedFilterValue,
  useTableData,
} from '@utils/index';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import styles from './styles.module.css';
import { useConnectedServicesQuery } from '@hooks/connectedServices';
import { AvailableServices } from '@pages/HubSettings/ConnectedServices';
import { createCellValue } from './utils';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { HorizontalBarChartTile } from '@pages/InsightsAndAnalytics/tiles/HorizontalChartTile';
import { TextTile } from '@pages/InsightsAndAnalytics/tiles/TextTile';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { RecommendationCard } from '@pages/Dashboard/tiles/helpers/RecommendationCard';
import useDownloadCsv from '@hooks/utils/export';
import { API_ENDPOINTS } from '@api/ApiEndpoints';

const pageSize = DEFAULT_PAGE_SIZE;

const ExpiredLicensePage: React.FC = () => {
  const { pageNumber, setPageNumber } = usePagination();
  const [search, setSearch] = useState('');
  const isDesktop = useIsDesktop();
  const [isLabelChartLoading, setisLabelChartLoading] = useState(true);
  const [licensesExpiringBarLabels, setLicensesExpiringBarLabels] = useState<
    string[]
  >([]);
  const redirectPath = useNavSourceRedirect(
    '/insights-and-analytics/utilisation'
  );
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
    filter: withDefault(StringParam, ''),
  });

  const { data: filterData } = useLicencesFiltersQuery(
    'false',
    queryParams.filter.includes('freeLicenses')
  );

  const { data: connectedServicesData } = useConnectedServicesQuery();

  const query = useLicensesExpiredPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as LicensesExpiringSortBy,
    order: queryParams.order as OrderBy,
    vendorList: getFilterValues(queryParams, VENDORS),
    subscriptionNameList: getFilterValues(
      queryParams,
      (filterData?.licenseType?.map((item) => item.value) || []).filter(
        (item): item is string => item !== undefined
      )
    ),
    includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
    autoRenew: getFirstMatchedFilterValue(queryParams, AUTO_RENEW),
    renewalPeriod: getFirstMatchedFilterValue(queryParams, RENEWAL_PERIOD),
  });
  const getTableData = useTableData(
    query,
    LICENSES_EXPIRED_TABLE_COLUMNS,
    createCellValue
  );

  const propertyLabels: Record<string, string> = {
    vendor: 'Vendor',
    licensetype: 'License Type',
  };

  const getPropertyLabel = (property: string): string => {
    return propertyLabels[property.toLowerCase()] || property;
  };

  const transformedFilterData: FilterData[] = [];
  if (filterData) {
    Object.keys(filterData).forEach((property) => {
      const filterDataArray: FilterData = {
        label: getPropertyLabel(property),
        name: property.toLowerCase(),
        options: [],
        singleSelect: !!(property === 'vendor'),
      };

      const propertyData =
        filterData[property as keyof LicensesFiltersResponse];

      if ('value' in propertyData) {
        const item = propertyData as LicensesFilterData;

        const option = {
          label: item.value || item.vendor,
          value: item.value || item.vendor,
          count: item.count,
        };

        filterDataArray.options.push(option);
      } else {
        const items = propertyData as unknown as LicensesFilterData[];

        items.forEach((item) => {
          const option = {
            label: item.value || item.vendor,
            value: item.value || item.vendor,
          };

          filterDataArray.options.push(option);
        });
      }

      transformedFilterData.push(filterDataArray);
    });

    transformedFilterData.push(
      {
        label: 'Expiry status',
        name: 'expiryStatus',
        options: [
          { label: 'Active', value: 'expiryStatusActive', disabled: true },
          { label: 'Expired', value: 'expiryStatusExpired', disabled: true },
          { label: 'Disabled', value: 'expiryStatusDisabled', disabled: true },
        ],
      }
      // {
      //   label: 'Auto renew status',
      //   name: 'autoRenew',
      //   singleSelect: true,
      //   options: [
      //     { label: 'Active', value: 'Active' },
      //     { label: 'Inactive', value: 'Inactive' },
      //   ],
      // },
      // {
      //   label: 'Renewal period',
      //   name: 'renewalPeriod',
      //   singleSelect: true,
      //   options: [
      //     { label: 'Annual', value: 'Annual' },
      //     { label: 'Monthly', value: 'Monthly' },
      //   ],
      // }
    );

    transformedFilterData.push({
      label: 'Free licences',
      name: 'freeLicenses',
      singleSelect: true,
      options: [{ label: 'Show free licenses', value: 'freeLicenses' }],
    });
  }

  const {
    data: licencesExpiredSnapshotBarChartData,
    isLoading: isLicencesExpiredSnapshotBarChartLoading,
  } = useLicencesExpiredSnapshotBarChartQuery({
    vendorList: getFilterValues(queryParams, VENDORS),
    subscriptionNameList: getFilterValues(
      queryParams,
      (filterData?.licenseType?.map((item) => item.value) || []).filter(
        (item): item is string => item !== undefined
      )
    ),
    includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
    autoRenew: getFirstMatchedFilterValue(queryParams, AUTO_RENEW),
    renewalPeriod: getFirstMatchedFilterValue(queryParams, RENEWAL_PERIOD),
  });

  useEffect(() => {
    setisLabelChartLoading(true);
    if (licencesExpiredSnapshotBarChartData) {
      setLicensesExpiringBarLabels(
        Object.keys(licencesExpiredSnapshotBarChartData.barChartSnapshot)
      );

      setisLabelChartLoading(false);
    }
  }, [licencesExpiredSnapshotBarChartData]);

  const {
    data: licencesExpiredDataSnapshot,
    isLoading: isLicencesExpiredSnapshotLoading,
  } = useLicencesExpiredSnapshotQuery({
    vendorList: getFilterValues(queryParams, VENDORS),
    subscriptionNameList: getFilterValues(
      queryParams,
      (filterData?.licenseType?.map((item) => item.value) || []).filter(
        (item): item is string => item !== undefined
      )
    ),
    includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
    autoRenew: getFirstMatchedFilterValue(queryParams, AUTO_RENEW),
    renewalPeriod: getFirstMatchedFilterValue(queryParams, RENEWAL_PERIOD),
  });

  const dashboardSaveMutation = useDashboardTileSaveMutation();

  const handleItemAction = (type: DashboardTiles) => () => {
    const commonParameters = {
      vendorList: getFilterValues(queryParams, VENDORS),
      subscriptionNameList: getFilterValues(
        queryParams,
        (filterData?.licenseType?.map((item) => item.value) || []).filter(
          (item): item is string => item !== undefined
        )
      ),
      includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
      autoRenew: getFirstMatchedFilterValue(queryParams, AUTO_RENEW),
      renewalPeriod: getFirstMatchedFilterValue(queryParams, RENEWAL_PERIOD),
    };

    const getParametersData = () => {
      switch (type) {
        case DashboardTiles.ExpiredAndDisabledLicensesSnapshot:
        case DashboardTiles.ExpiredAndDisabledLicensesServiceType:
          return commonParameters;
        default:
          return {};
      }
    };

    const parametersData = getParametersData();

    dashboardSaveMutation.mutate({
      parameters: JSON.stringify(parametersData),
      tileType: type,
    });
  };

  const showLinkForService = (service: number) => {
    return (
      connectedServicesData &&
      connectedServicesData.some((obj) => obj.vendor === service)
    );
  };

  const [shouldDownload, setShouldDownload] = useState(false);
  const { refetch: downloadCsv } = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.LICENSES_EXPIRED_PREVIEW}/export`,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as LicensesExpiringSortBy,
      order: queryParams.order as OrderBy,
      vendorList: getFilterValues(queryParams, VENDORS),
      subscriptionNameList: getFilterValues(
        queryParams,
        (filterData?.licenseType?.map((item) => item.value) || []).filter(
          (item): item is string => item !== undefined
        )
      ),
      includeFreeLicenses: queryParams.filter.includes('freeLicenses'),
      autoRenew: getFirstMatchedFilterValue(queryParams, AUTO_RENEW),
      renewalPeriod: getFirstMatchedFilterValue(queryParams, RENEWAL_PERIOD),
    },
    filename: `Clevr360_Expired_licenses_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Expired licenses`}
    >
      <div className={styles.pageGrid}>
        {isDesktop && (
          <>
            <NumberTile
              classNames={'col-span-3 lg:col-auto'}
              contextData={[]}
              headerTitle={'Expired & disabled licenses: Snapshot'}
              isLoading={isLicencesExpiredSnapshotLoading}
              data={
                licencesExpiredDataSnapshot
                  ? Object.values(licencesExpiredDataSnapshot)
                  : []
              }
            />
            <HorizontalBarChartTile
              classNames={'col-span-3 lg:col-span-2'}
              headerTitle={'Expired & disabled licenses: Service type'}
              contextData={[]}
              isLoading={
                isLicencesExpiredSnapshotBarChartLoading || isLabelChartLoading
              }
              height={160}
              barPercentage={
                Object.keys(licencesExpiredSnapshotBarChartData ?? {}).length <
                4
                  ? 0.18
                  : 0.25
              }
              data={[
                ...Object.values(
                  licencesExpiredSnapshotBarChartData?.barChartSnapshot ?? {}
                ),
              ]}
              dataLabels={[...licensesExpiringBarLabels]}
              barBackgroundColors={[
                '#00CF6C',
                '#25A8D1',
                '#B65AD7',
                '#FFB800',
                '#FF9ED3',
              ]}
            />
            {connectedServicesData && connectedServicesData?.length > 0 ? (
              <TextTile
                classNames={styles.textTile}
                headerTitle={'Recommendations'}
              >
                <div className={styles.textTileContainer}>
                  {showLinkForService(AvailableServices.RINGCENTRAL) && (
                    <div className={styles.textTileItem}>
                      <p className="font-bold">RingCentral</p>
                      <Link
                        className="underline"
                        target="_blank"
                        rel="noopener noreferrer"
                        to="https://support.ringcentral.com/article-v2/Managing-licenses-in-RingCentral-account.html?brand=RC_US&product=RingCentral_MVP&language=en_US"
                      >
                        View and manage your RingCentral licenses
                      </Link>
                    </div>
                  )}
                  {showLinkForService(AvailableServices.MICROSOFT) && (
                    <div className={styles.textTileItem}>
                      <p className="font-bold">Microsoft</p>
                      <Link
                        className="underline"
                        target="_blank"
                        rel="noopener noreferrer"
                        to="https://learn.microsoft.com/en-us/windows-365/business/assign-unassign-license"
                      >
                        More information on expired licenses
                      </Link>
                    </div>
                  )}
                </div>
              </TextTile>
            ) : (
              <RecommendationCard insightDescription={'expired licenses'} />
            )}
          </>
        )}
      </div>

      <TableWrapper
        search={search}
        setSearch={setSearch}
        searchPlaceholder={'Search by subscription'}
        columns={LICENSES_EXPIRED_TABLE_COLUMNS}
        data={getTableData()}
        sortData={LICENSES_EXPIRED_SORT_BY.filter(
          (item) => item.label !== 'Expiry date'
        )}
        filterData={transformedFilterData}
        searchKey="search"
        query={query}
        refetchQuery={query.refetch}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        setPageNumber={setPageNumber}
        isCollapsable={true}
        sliceColumns={2}
        floatingFilterButton={true}
        hasFilterBanner={false}
        isLicensePage={true}
        searchCountStatsLabel="subscriptions"
        filterHeaderText="Filter expired licenses"
        preselectedFilter={['expiryStatusExpired', 'expiryStatusDisabled']}
        isDownloadAvailable={true}
        handleDownload={handleDownloadClick}
      />
    </OverlayPage>
  );
};

export default ExpiredLicensePage;
