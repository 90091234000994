import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import useAuthStore from '@store/authStore';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, silentRequest } from './msalConfig';
import { Variant } from '@components/ui/Toast';

const msalInstance = new PublicClientApplication(msalConfig);

export class HttpClient {
  private client: AxiosInstance;
  private showToast: (message: string, variant: Variant) => void;

  private isToastDisplayed = false;

  constructor(
    baseURL: string,
    showToast: (message: string, variant: Variant) => void
  ) {
    const resolvedBaseURL = window.apiEndpoint || baseURL;

    this.client = axios.create({
      baseURL: resolvedBaseURL,
      timeout: 30000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    this.showToast = showToast;

    this.setupInterceptors();
  }

  private async addTokenToHeaders(
    config: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> {
    const token = useAuthStore.getState().tokenWithScope;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }

  private async refreshToken(): Promise<string | null> {
    try {
      const account = msalInstance.getAllAccounts()[0];
      const response = await msalInstance.acquireTokenSilent({
        ...silentRequest,
        account: account,
      });
      useAuthStore.getState().setToken(response.accessToken);
      return response.accessToken;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return null;
    }
  }

  private async setupInterceptors(): Promise<void> {
    this.client.interceptors.request.use(
      async (
        config: InternalAxiosRequestConfig
      ): Promise<InternalAxiosRequestConfig> => {
        const updatedConfig = await this.addTokenToHeaders(config);
        return updatedConfig;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );

    await msalInstance.initialize();

    this.client.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: AxiosError) => {
        const originalRequest = error.config as any;

        if (error.response?.status == 401 && !originalRequest?._retry) {
          originalRequest._retry = true;

          const newToken = await this.refreshToken();
          if (newToken) {
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            return this.client(originalRequest);
          } else {
            return await msalInstance.loginRedirect(silentRequest);
          }
        }

        if (
          (error.response?.status === 500 || error.response?.status === 404) &&
          !this.isToastDisplayed
        ) {
          this.isToastDisplayed = true;
          this.showToast(
            'Something went wrong, and we can’t show you the content you asked for right now. Please try again later.',
            Variant.Error
          );
        }

        return Promise.reject(error);
      }
    );
  }

  async get<T>(url: string, params?: unknown): Promise<T> {
    const response = await this.client.get<T>(url, { params });
    return response.data;
  }

  async post<T>(
    url: string,
    data: unknown,
    options?: AxiosRequestConfig
  ): Promise<T> {
    const response = await this.client.post<T>(url, data, options);
    return response.data;
  }

  async put<T>(url: string, data: unknown): Promise<T> {
    const response = await this.client.put<T>(url, data);
    return response.data;
  }

  async delete<T>(url: string): Promise<T> {
    const response = await this.client.delete<T>(url);
    return response.data;
  }

  async patch<T>(url: string, data: unknown): Promise<T> {
    const response = await this.client.patch<T>(url, data);
    return response.data;
  }
}
