import { TilesProps } from '@common/types';
import { useDelegatedMailboxAccountsSnapshotQuery } from '@hooks/security';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { useEffect, useState } from 'react';

type Params = {
    days: string;
};

export const DelegatedMailboxAccounts: React.FC<TilesProps> = ({
    params,
    classNames,
}) => {
    const [parameters, setParameters] = useState<Params>(JSON.parse(params));
    // data hook
    const { data, isLoading, refetch } = useDelegatedMailboxAccountsSnapshotQuery(parameters);

    useEffect(() => {
        const updatedParams = JSON.parse(params);
        setParameters({
            days: updatedParams.days,
        });
        refetch();
    }, [params]);

    return (
        <NumberTile
            dropdownMenuItemClasses={'!w-[300px]'}
            sortData={[]}
            classNames={
                classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
            }
            compareData={undefined}
            headerTitle={'Accounts using delegation to access mailboxes'}
            isLoading={isLoading}
            data={data ? Object.values(data) : []}
            percentageLabel=""
        />
    );
};
