import { CellBottomPart, CellLeftStatusPart } from '@components/ui/Table/utils';
import { formatDate } from '@utils/index';

export const createCellValue = (
  property: string,
  data: any,
  index: number,
  isMobile: boolean
) => {
  const value = data[index][property];

  switch (property) {
    case 'status':
      return (
        <>
          <CellLeftStatusPart status={value} />
          <span>{value}</span>
        </>
      );
    case 'emailsReceived':
      return (
        <>
          <span>{value}</span>
          <div className="flex flex-col">
            <span className="text-14 tracking-wide text-interfaceColor-80">
              {`Last: ${
                formatDate(new Date().toString()) ===
                formatDate(data[index]['lastEmailReceived'])
                  ? 'Today'
                  : formatDate(data[index]['lastEmailReceived'])
              }`}
            </span>
          </div>
        </>
      );
    case 'emailsSent':
      return (
        <>
          <span>{value}</span>
          <div className="flex flex-col">
            <span className="text-14 tracking-wide text-interfaceColor-80">
              {`Last: ${
                formatDate(new Date().toString()) ===
                formatDate(data[index]['lastEmailSent'])
                  ? 'Today'
                  : formatDate(data[index]['lastEmailSent'])
              }`}
            </span>
          </div>
        </>
      );
    case 'name':
      return (
        <>
          <span>{value}</span>
          {!isMobile && <CellBottomPart data={data[index]['emailAddress']} />}
        </>
      );
  }
};
