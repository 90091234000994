import * as React from 'react';
import { IconProps } from './types';

export const SearchIcon: React.FC<IconProps> = ({
  size = 4,
  color = 'text-interfaceColor-100',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      fill="none"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M7.766 13.96a6.194 6.194 0 1 0 0-12.389 6.194 6.194 0 0 0 0 12.389ZM16.428 16.428l-4.285-4.285"
      />
    </svg>
  );
};
