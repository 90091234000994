import { API_ENDPOINTS } from '@api/ApiEndpoints';
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query';
import {
  ServiceIntegrationMSData,
  ServiceIntegrationRCData,
  ServiceIntegrationUCData,
  ServiceIntegrationReconnectMSData,
  ServiceIntegrationReconnectRCData,
  ServiceIntergrationResponse,
} from './types';
import { useHttpClient } from '@hooks/utils/httpConfig';

export function useConnectedServicesQuery(): UseQueryResult<
  ServiceIntergrationResponse[]
> {
  const connectedServicesClient = useHttpClient();
  const fetchConnectedServices = async (): Promise<
    ServiceIntergrationResponse[]
  > => {
    return connectedServicesClient.get<ServiceIntergrationResponse[]>(
      API_ENDPOINTS.SERVICE_INTEGRATIONS
    );
  };

  return useQuery(['connectedServices'], fetchConnectedServices);
}

export const useServiceIntegrationMS = (): UseMutationResult<
  ServiceIntergrationResponse,
  unknown,
  ServiceIntegrationMSData
> => {
  const connectedServicesClient = useHttpClient();
  return useMutation((data: ServiceIntegrationMSData) =>
    connectedServicesClient.post<ServiceIntergrationResponse>(
      `${API_ENDPOINTS.SERVICE_INTERGRATION_MS}`,
      {
        tenantId: data.tenantId,
      }
    )
  );
};

export const useServiceIntegrationReconnectMS = (): UseMutationResult<
  ServiceIntergrationResponse,
  unknown,
  ServiceIntegrationReconnectMSData
> => {
  const connectedServicesClient = useHttpClient();
  return useMutation((data: ServiceIntegrationReconnectMSData) =>
    connectedServicesClient.patch<ServiceIntergrationResponse>(
      `${API_ENDPOINTS.SERVICE_INTEGRATIONS}/${data.connectionId}/reconnect/microsoft`,
      {
        tenantId: data.tenantId,
      }
    )
  );
};

export const useServiceIntegrationReconnectHelix = (): UseMutationResult<
  ServiceIntergrationResponse,
  unknown,
  ServiceIntegrationReconnectMSData
> => {
  const connectedServicesClient = useHttpClient();
  return useMutation((data: ServiceIntegrationReconnectMSData) =>
    connectedServicesClient.patch<ServiceIntergrationResponse>(
      `${API_ENDPOINTS.SERVICE_INTEGRATIONS}/${data.connectionId}/reconnect/helix`,
      {
        tenantId: data.tenantId,
      }
    )
  );
};

export const useServiceIntegrationReconnectRC = (): UseMutationResult<
  ServiceIntergrationResponse,
  unknown,
  ServiceIntegrationReconnectRCData
> => {
  const connectedServicesClient = useHttpClient();
  return useMutation((data: ServiceIntegrationReconnectRCData) =>
    connectedServicesClient.patch<ServiceIntergrationResponse>(
      `${API_ENDPOINTS.SERVICE_INTEGRATIONS}/${data.connectionId}/reconnect/ringcentral`,
      {
        jwt: data.jwt,
      }
    )
  );
};

export const useServiceIntegrationRC = (): UseMutationResult<
  ServiceIntergrationResponse,
  unknown,
  ServiceIntegrationRCData
> => {
  const connectedServicesClient = useHttpClient();
  return useMutation((data: ServiceIntegrationRCData) =>
    connectedServicesClient.post<ServiceIntergrationResponse>(
      `${API_ENDPOINTS.SERVICE_INTERGRATION_RC}`,
      {
        jwt: data.jwt,
      }
    )
  );
};

export const useServiceIntegrationUC = (): UseMutationResult<
  ServiceIntergrationResponse,
  unknown,
  ServiceIntegrationUCData
> => {
  const connectedServicesClient = useHttpClient();
  return useMutation((data: ServiceIntegrationUCData) =>
    connectedServicesClient.post<ServiceIntergrationResponse>(
      `${API_ENDPOINTS.SERVICE_INTERGRATION_UC}`,
      {
        vendor: data.vendor
      }
    )
  );
};


export const useDeactivateService = () => {
  const connectedServicesClient = useHttpClient();
  return useMutation((connectionId: string) =>
    connectedServicesClient.patch(
      `${API_ENDPOINTS.SERVICE_INTERGRATION_STATUS}/${connectionId}/deactivate`,
      null
    )
  );
};

export const useReactivateService = () => {
  const connectedServicesClient = useHttpClient();
  return useMutation((connectionId: string) =>
    connectedServicesClient.patch(
      `${API_ENDPOINTS.SERVICE_INTERGRATION_STATUS}/${connectionId}/reactivate`,
      null
    )
  );
};

export const useCancelServiceUC = () => {
  const connectedServicesClient = useHttpClient();
  return useMutation((connectionId: string) =>
    connectedServicesClient.patch(
      `${API_ENDPOINTS.SERVICE_INTERGRATION_STATUS}/${connectionId}/cancel`,
      null
    )
  );
};
