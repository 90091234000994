import * as React from 'react';
import { IconProps } from './types';

export const LogoutIcon: React.FC<IconProps> = ({
    size = 5,
    color = 'text-interfaceColor-40',
    strokeWidth = 1.2,
    classNames = '',
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
            fill="none"
        >
            <g
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={strokeWidth}
                clipPath="url(#a)"
            >
                <path d="M9.38 10.333v1.905a.953.953 0 0 1-.952.953H1.762a.952.952 0 0 1-.953-.953V1.762A.952.952 0 0 1 1.762.81h6.666a.952.952 0 0 1 .953.952v1.905M6.524 7h6.666M11.285 5.095 13.19 7l-1.905 1.905" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M.333.333h13.333v13.333H.333z" />
                </clipPath>
            </defs>
        </svg>
    );
};
