import * as React from 'react';
import { IconProps } from './types';

export const MinusIcon: React.FC<IconProps> = ({
  size = 4,
  color = 'text-interfaceColor-100',
  strokeWidth = 1.14286,
  classNames = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      fill="none"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M11 8H5M8 15.429A7.429 7.429 0 1 0 8 .572a7.429 7.429 0 0 0 0 14.857Z"
      />
    </svg>
  );
};
