import { ArrowLeftIcon, ArrowRightIcon } from '@components/ui/Icons';

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  setPageNumber: (pageNumber: number) => void;
};

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  setPageNumber,
}) => {
  const btnBaseClasses =
    'flex h-8 w-8 items-center justify-center rounded-full';
  const btnEnabledClasses = 'cursor-pointer bg-interfaceColor-90';
  const btnDisabledClasses = 'cursor-not-allowed bg-interfaceColor-30';

  const handleNextPage = () => {
    setPageNumber(currentPage + 1);
  };

  const handlePrevPage = () => {
    setPageNumber(Math.max(currentPage - 1, 1));
  };

  return (
    <>
      <div>
        Page {currentPage} of {totalPages}
      </div>
      <div className="flex space-x-2">
        <button
          data-testid="prevbtn"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className={`${btnBaseClasses} ${
            currentPage === 1 ? btnDisabledClasses : btnEnabledClasses
          }`}
        >
          <ArrowLeftIcon
            size="2"
            classNames={`${
              currentPage === 1 ? 'text-interfaceColor-60' : 'text-white'
            }`}
          />
        </button>

        <button
          data-testid="nextbtn"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`${btnBaseClasses} ${
            currentPage === totalPages ? btnDisabledClasses : btnEnabledClasses
          }`}
        >
          <ArrowRightIcon
            size="2"
            classNames={`${
              currentPage === 1 ? 'text-interfaceColor-60' : 'text-white'
            }`}
          />
        </button>
      </div>
    </>
  );
};
