import useTableStore from '@store/tableStore';
import { CheckCircleIcon } from '../Icons/CheckCircleIcon';
import { WarningCircleIcon } from '../Icons/WarningCircleIcon';
import { ArrowDownIcon, ArrowUpIcon } from '../Icons';

type CellBottomPartProps = {
  data: string;
};

export const CellBottomPart: React.FC<CellBottomPartProps> = ({ data }) => {
  return (
    <span className="flex flex-col">
      <span className="text-14 tracking-wide text-interfaceColor-80">
        {data}
      </span>
    </span>
  );
};

type CellBottomGroupPartProps = {
  count: number;
};

export const CellBottomGroupPart: React.FC<CellBottomGroupPartProps> = ({
  count,
}) => {
  return (
    <div className="flex flex-row items-center">
      <WarningCircleIcon
        color="text-warningColor-150"
        classNames="h-4 w-4 mr-1"
      ></WarningCircleIcon>
      <span className="text-14 text-interfaceColor-80">{`+ ${count} More`}</span>
    </div>
  );
};

type CellActionProps = {
  index: number;
  isVideoFormat: boolean;
};

export const CellAction: React.FC<CellActionProps> = ({
  index,
  isVideoFormat,
}) => {
  const { setExpandedRowId, expandedRowId } = useTableStore();
  return (
    <span
      onClick={() => setExpandedRowId(index, isVideoFormat)}
      className="ml-2 flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-interfaceColor-20 text-14 leading-5"
    >
      {expandedRowId == index ? (
        <ArrowUpIcon
          color="text-interfaceColor-150"
          classNames="mt-[6px] ml-[2px]"
          size="5"
        />
      ) : (
        <ArrowDownIcon
          color="text-interfaceColor-150"
          size="5"
          classNames="mt-[4px] ml-[2px]"
        />
      )}
    </span>
  );
};

type CellLeftStatusPartProps = {
  status: string;
};

// status with green/red dot
export const CellLeftStatusPart: React.FC<CellLeftStatusPartProps> = ({
  status,
}) => {
  return (
    status && (
      <span
        className={`status -mt-[3px] align-middle status-${status.toLowerCase()}`}
      ></span>
    )
  );
};

type CellLeftStatusWithIconPartProps = {
  status: string;
};

// status with icon
export const CellLeftStatusWithIconPart: React.FC<
  CellLeftStatusWithIconPartProps
> = ({ status }) => {
  return (
    <>
      {status ? (
        <CheckCircleIcon
          color={'text-successColor-100'}
          classNames="h-4 w-4 mr-2 align-middle inline -mt-[3px]"
        />
      ) : (
        <WarningCircleIcon
          color={'text-warningColor-150'}
          classNames="h-4 w-4 mr-2 align-middle inline -mt-[3px]"
        />
      )}
    </>
  );
};
