import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import {
  useOutdatedBrowserFilters,
  useOutdatedBrowsersActivity,
} from '@hooks/security';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { useEffect, useState } from 'react';

type Params = {
  days: string;
  groupList?: string[];
  browserType?: string;
  accountType?: string;
  locationList?: string[];
};

export const OutdatedBrowserActivity: React.FC<TilesProps> = ({
  params,
  id,
  isSnapshot,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.OutdatedBrowserActivity,
    parameters
  );
  const isOnDashboardPage = !!id;

  const { data, isLoading, refetch } = useOutdatedBrowsersActivity(parameters);

  const handleDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      browserType: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });
    }
  };

  const handleCompareDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      accountType: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });
    }
  };
  const { data: filterData } = useOutdatedBrowserFilters('30');
  const browsers = filterData ? filterData?.browsers : [];

  useEffect(() => {
    setParameters(JSON.parse(params));

    return () => {
      refetch();
    };
  }, [params, refetch]);

  const browsersMenuItems: MenuItem[] = [
    {
      id: 'allBrowsers',
      label: 'All browsers',
      value: '',
      action: () => handleDropdown(''),
    },
    ...browsers.map((browser: string, index: number) => ({
      id: `${index}`,
      label: browser,
      value: browser,
      action: () => handleDropdown(browser),
    })),
  ];

  const compareMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All users',
      value: '',
      action: () => handleCompareDropdown(''),
    },
    {
      id: '2',
      label: 'External',
      value: 'External',
      action: () => handleCompareDropdown('External'),
    },
    {
      id: '3',
      label: 'Internal',
      value: 'Internal',
      action: () => handleCompareDropdown('Internal'),
    },
  ];

  return (
    parameters && (
      <NumberTile
        initialSelectedItem={browsersMenuItems.find(
          (item) => item.value === parameters.browserType
        )}
        initialCompareSelectedItem={compareMenuItems.find(
          (item) => item.value === parameters.accountType
        )}
        contextData={
          !isSnapshot ? 
            (isOnDashboardPage ? removeMenuItem : addMenuItem) : []
        }
        classNames={
          classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
        }
        compareData={!isSnapshot ? compareMenuItems : undefined}
        sortData={!isSnapshot ? browsersMenuItems : undefined}
        headerTitle={'Logins: outdated browsers'}
        dropdownMenuItemClasses={'!w-[300px]'}
        isLoading={isLoading}
        data={data ? Object.values(data) : []}
        bottomLabel="Total logins by outdated browsers"
        buttonLabel={!isSnapshot ? 'Outdated browser data' : ''}
        linkPath={ANALYTICS_NAVIGATION_MAP['outdatedBrowsers']}
        handleButtonClick={!isSnapshot ? handleButtonClick() : undefined}
        percentageLabel=""
      />
    )
  );
};
