import { Card } from '@components/ui';
import { Loader } from '@components/ui/Loader';
import { HamburgerIcon } from '@components/ui/Icons';
import styles from './styles.module.css';
import HorizontalBarChart, {
  DatasetType,
} from '@components/ui/Charts/HorizontalBarChart';
import { DropdownMenu, MenuItem } from '@components/ui/DropdownMenu';

import { TileFooter } from '../partials/TileFooter';

export type HorizontalBarChartTileProps = {
  sortData?: MenuItem[];
  contextData: MenuItem[];
  headerTitle: string;
  isLoading: boolean;
  data: number[];
  dataLabels: string[];
  buttonLabel?: string;
  handleButtonClick?: () => void;
  classNames?: string;
  barBackgroundColors: string[];
  height?: number;
  barPercentage?: number;
  selectedDropdownItemIndex?: number;
  dropdownMenuItemClasses?: string;
  initialSelectedItem?: MenuItem;
  linkPath?: string;
};

export const HorizontalBarChartTile: React.FC<HorizontalBarChartTileProps> = ({
  sortData,
  headerTitle,
  isLoading = false,
  data = [0, 0],
  dataLabels,
  contextData,
  buttonLabel,
  handleButtonClick,
  classNames,
  barBackgroundColors,
  height,
  barPercentage = 0.25,
  dropdownMenuItemClasses,
  initialSelectedItem,
  linkPath,
}) => {
  const chartLabels = dataLabels;

  const chartData: DatasetType[] = [
    {
      data: data,
      backgroundColor: barBackgroundColors,
      ...{
        borderWidth: 0,
        borderRadius: Number.MAX_VALUE,
        borderSkipped: false,
        barPercentage: barPercentage,
        categoryPercentage: 1,
      },
    },
  ];

  return (
    <Card classNames={`!w-auto ${classNames}`}>
      <Card.Header classNames={styles.cardHeader}>
        <div className={styles.cardHeaderContainer}>
          <span className="mb-2">{headerTitle}</span>

          {contextData.length ? (
            <DropdownMenu
              items={contextData}
              buttonProps={{ icon: <HamburgerIcon></HamburgerIcon> }}
              dropdownMenuClasses={dropdownMenuItemClasses}
            ></DropdownMenu>
          ) : null}
        </div>
        {sortData && (
          <DropdownMenu
            items={sortData}
            showSelectedItem={true}
            buttonProps={{
              label: initialSelectedItem
                ? initialSelectedItem.label
                : sortData[0].label,
            }}
            initialSelectedItem={initialSelectedItem || sortData[0]}
          ></DropdownMenu>
        )}
      </Card.Header>

      <Card.Content classNames={styles.cardContent}>
        {isLoading ? (
          <div className="flex w-full justify-center">
            <Loader />
          </div>
        ) : (
          <div className={styles.cardDetails}>
            <div className={styles.chartContainer}>
              <div className="w-full">
                {data.length > 0 ? (
                  <HorizontalBarChart
                    classNames="bg-interfaceColor-5 px-4"
                    labels={chartLabels}
                    height={height}
                    datasets={chartData}
                  />
                ) : (
                  <div className="my-3 flex flex-col items-center justify-center">
                    <h1 className="mb-2 rounded-full bg-interfaceColor-20 px-4 py-1 text-20 text-interfaceColor-100">
                      Insufficient data
                    </h1>
                    <p className="max-w-sm text-center text-interfaceColor-80">
                      Keep using Clevr360 to see how your
                      <span className="mx-1">{headerTitle}</span>
                      have changed over time
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Card.Content>
      <TileFooter
        linkPath={linkPath}
        buttonLabel={buttonLabel}
        handleButtonClick={handleButtonClick}
      />
    </Card>
  );
};
