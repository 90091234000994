import { Button, Modal } from '@components/ui';
import { Variant } from '@components/ui/Toast';
import { useLockUser, useUnlockUser } from '@hooks/users';
import { User } from '@hooks/users/types';
import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import useUIStore from '@store/uiStore';
import { useState } from 'react';

export type LockUnlockUserModalProps = {
  user: User | undefined;
  isModalOpen: boolean;
  handleModalClose: () => void;
  handleSuccess: () => void;
};

export const LockUnlockUserModal: React.FC<LockUnlockUserModalProps> = ({
  user,
  isModalOpen,
  handleModalClose,
  handleSuccess,
}) => {
  const { addToast } = useUIStore();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const [sendResetLink, setSendResetLink] = useState(false);

  const { mutateAsync: lockUserAsync, isLoading: isLockLoading } = useLockUser(
    user?.id
  );
  const { mutateAsync: unlockUserAsync, isLoading: isUnlockLoading } =
    useUnlockUser(user?.id);
  const isEnabled = user?.isEnabled === true;

  const handleSave = async () => {
    try {
      await (isEnabled ? lockUserAsync() : unlockUserAsync());
      addToast({
        variant: Variant.Success,
        message: `${
          isEnabled
            ? `We've locked ${user?.fullName}'s account and sent them a password reset link.`
            : `We've unlocked ${user?.fullName}'s account`
        }`,
        closeable: true,
      });
      handleSuccess();
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: 'Something went wrong, and your changes haven`t been saved.',
        closeable: true,
      });
    }
  };

  return (
    <Modal
      headerTxt={`${isEnabled ? 'Lock' : 'Unlock'} ${user?.fullName}'s account`}
      modalClassName={'h-full mt-0'}
      isOpen={isModalOpen}
      size={isTablet || isMobile ? 'small' : 'medium'}
      position={isMobile ? 'bottom' : 'default'}
      showCloseBtn={true}
      onClose={handleModalClose}
      actionButtons={
        <div className="flex flex-col gap-2 lg:flex-row">
          <Button
            type="submit"
            variant={isEnabled ? 'danger' : 'primary'}
            disabled={isLockLoading || isUnlockLoading}
            onClick={handleSave}
          >
            {isEnabled ? 'Lock account' : 'Unlock account'}
          </Button>
          <Button variant="outlineLight" onClick={handleModalClose}>
            {isEnabled ? 'Cancel: keep account unlocked' : 'Cancel'}
          </Button>
        </div>
      }
      contentClassName="flex !flex-row !items-start !justify-start bg-white"
    >
      <div>
        {isEnabled && (
          <>
            <p className="mt-5 text-16  leading-6 text-interfaceColor-100">
              Locking {user?.fullName}`s account will prevent them from
              accessing the Hub until their account is unlocked. Their saved
              data and settings won`t be removed.
            </p>
            {/* <Checkbox
              checked={sendResetLink}
              className="mb-5 mt-2"
              label={`Send ${user?.fullName} a password reset link, and unlock their account when they update their password.`}
              name="sendResetLink"
              onChange={() => setSendResetLink(!sendResetLink)}
            /> */}
          </>
        )}
        {!isEnabled && (
          <>
            <p className="mb-5 mt-5 text-16  leading-6 text-interfaceColor-100">
              {user?.fullName}`s account was locked because there were multiple
              failed login attempts. To keep the Hub safe, they`ll need to
              create a new password before they can log in.
            </p>
            {/* <Banner
              variant="warning"
              className="mb-5"
              message={`We’ll send ${user?.fullName} a password reset email. Make sure their email account hasn’t been compromised before continuing.`}
            /> */}
          </>
        )}
      </div>
    </Modal>
  );
};
