import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { useAddDashboardTile, useRemoveDashboardTile } from '@hooks/dashboard';
import { useLicencesActivityQuery } from '@hooks/licenses';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';

export const InactiveLicensesSnapshot: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const { data: licencesActivityData, isLoading: isLicencesActivityLoading } =
    useLicencesActivityQuery(JSON.parse(params));
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.InactiveLicensesSnapshot,
    ''
  );
  const isOnDashboardPage = !!id;

  return (
    <DoughnutChartTile
      classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      headerTitle={'Inactive licenses: Snapshot'}
      dropdownMenuItemClasses={'!w-[300px]'}
      isLoading={isLicencesActivityLoading}
      data={licencesActivityData ? Object.values(licencesActivityData) : []}
      dataLabels={['Inactive licenses', 'Active licenses']}
    />
  );
};
