import { QueryClient, QueryClientProvider } from 'react-query';

import ToastContainer from './components/ui/helpers/ToastContainer';
import { RouteApp } from './routes/Routes';
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { CustomNavigationClient } from '@utils/NavigationClient';
import { useNavigate } from 'react-router';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <RouteApp />
      </QueryClientProvider>
    </MsalProvider>
  );
}

export default App;
