import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { useAddDashboardTile, useRemoveDashboardTile } from '@hooks/dashboard';
import { useLicencesExpiredSnapshotQuery } from '@hooks/licenses';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';

export const ExpiredAndDisabledLicensesSnapshot: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const {
    data: licencesExpiredDataSnapshot,
    isLoading: isLicencesExpiredSnapshotLoading,
  } = useLicencesExpiredSnapshotQuery(JSON.parse(params));
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.ExpiredAndDisabledLicensesSnapshot,
    ''
  );
  const isOnDashboardPage = !!id;

  return (
    <NumberTile
      classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      dropdownMenuItemClasses={'!w-[300px]'}
      headerTitle={'Expired & disabled licenses: Snapshot'}
      isLoading={isLicencesExpiredSnapshotLoading}
      data={
        licencesExpiredDataSnapshot
          ? Object.values(licencesExpiredDataSnapshot)
          : []
      }
    />
  );
};
