import * as React from 'react';
import { IconProps } from './types';

export const HubSettingsUserIcon: React.FC<IconProps> = ({
  color = 'text-primaryBrandColor-100',
  classNames = '',
  size = 4,
}) => {
  return (
    <svg
      className={`stroke-current w-${size} h-${size} ${color} ${classNames}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_1557_8160)">
        <path
          d="M12.9027 5.74805H2.47603C1.84422 5.74805 1.33203 6.26023 1.33203 6.89205V17.3187C1.33203 17.9505 1.84422 18.4627 2.47603 18.4627H12.9027C13.5345 18.4627 14.0467 17.9505 14.0467 17.3187V6.89205C14.0467 6.26023 13.5345 5.74805 12.9027 5.74805Z"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.95117 5.72986V2.68068C5.95117 2.0491 6.46316 1.53711 7.09473 1.53711H17.522C18.1536 1.53711 18.6656 2.0491 18.6656 2.68068V13.108C18.6656 13.7395 18.1536 14.2515 17.522 14.2515H14.0581"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.35156 10.8879C5.35156 12.1792 6.39836 13.226 7.68966 13.226C8.0161 13.226 8.32691 13.1591 8.60915 13.0382C9.44331 12.6811 10.0277 11.8527 10.0277 10.8879C10.0277 9.5966 8.98094 8.5498 7.68966 8.5498C6.39836 8.5498 5.35156 9.5966 5.35156 10.8879Z"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.7321 18.4632C11.5564 16.3844 9.81349 14.752 7.68927 14.752C5.56506 14.752 3.82212 16.3844 3.64648 18.4632"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
