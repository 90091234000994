import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useTotalActivityLocationsSnapshotQuery } from '@hooks/productivity';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { useState } from 'react';

type Params = {
  activity: string;
};

export const TotalActivityLocations: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.TotalActivityLocations,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: totalActivityPeopleDataSnapshot,
    isLoading: isTotalActivityPeopleSnapshotLoading,
    refetch,
  } = useTotalActivityLocationsSnapshotQuery(parameters);

  const handleDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      activity: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const totalActivityMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All activities',
      value: 'allactivities',
      action: () => handleDropdown('allactivities'),
    },
    {
      id: '2',
      label: 'Emails',
      value: 'emails',
      action: () => handleDropdown('emails'),
    },
    {
      id: '3',
      label: 'Messages',
      value: 'messages',
      action: () => handleDropdown('messages'),
    },
    {
      id: '4',
      label: 'Calls',
      value: 'calls',
      action: () => handleDropdown('calls'),
    },

    {
      id: '5',
      label: 'Meetings',
      value: 'meetings',
      action: () => handleDropdown('meetings'),
    },
  ];

  return (
    parameters && (
      <NumberTile
        initialSelectedItem={totalActivityMenuItems.find(
          (item) => item.value === parameters.activity
        )}
        dropdownMenuItemClasses={'!w-[300px]'}
        classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
        key={id}
        sortData={totalActivityMenuItems}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        headerTitle={'Total activity - Locations'}
        isLoading={isTotalActivityPeopleSnapshotLoading}
        data={
          totalActivityPeopleDataSnapshot
            ? Object.values(totalActivityPeopleDataSnapshot)
            : []
        }
        percentageLabel=""
        bottomLabel="Emails, messages, calls and meetings"
      />
    )
  );
};
