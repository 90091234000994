import { API_ENDPOINTS } from '@api/ApiEndpoints';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query';

import { PersonsResponse, UsePersonsQueryOptions } from '../people/types';
import {
  CreateGroupData,
  CreateGroupResponse,
  DeleteGroupResponse,
  GroupForm,
  GroupsResponse,
  MoveGroupResponse,
  UpdateGroupMembersData,
  UpdateGroupMembersResponse,
  UpsertGroupViewersData,
  UseCreateGroupOptions,
  UseUpdateGroupMembersOptions,
  UseUpdateGroupOptions,
  UseUpsertGroupViewersOptions,
} from './types';
import { useHttpClient } from '@hooks/utils/httpConfig';

export function useGroupsQuery(): UseQueryResult<GroupsResponse[]> {
  const groupsClient = useHttpClient();
  const fetchGroups = async (): Promise<GroupsResponse[]> => {
    return groupsClient.get<GroupsResponse[]>(API_ENDPOINTS.GROUPS);
  };

  return useQuery(['groups'], fetchGroups);
}

export function useGroupsFilterQuery(): UseQueryResult<GroupsResponse[]> {
  const groupsClient = useHttpClient();
  const fetchGroupsFilter = async (): Promise<GroupsResponse[]> => {
    return groupsClient.get<GroupsResponse[]>(API_ENDPOINTS.GROUPS_FILTER);
  };

  return useQuery(['groupsFilter'], fetchGroupsFilter);
}

export function useMembersQuery({
  searchTerm,
  sortBy,
  order,
  personStatus,
  pageSize = 20,
  pageNumber = 1,
  groupId,
}: UsePersonsQueryOptions = {}): UseQueryResult<PersonsResponse> {
  const groupsClient = useHttpClient();
  const fetchMembers = async (): Promise<PersonsResponse> => {
    const params = {
      pageSize: pageSize,
      pageNumber: pageNumber,
      searchTerm: searchTerm,
      sortBy: sortBy,
      order: order,
      status: personStatus,
      groupId: groupId,
    };
    return groupsClient.get<PersonsResponse>(
      `${API_ENDPOINTS.GROUPS}/${groupId}/members`,
      params
    );
  };

  return useQuery(
    [
      'groupMembers',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      personStatus,
    ],
    fetchMembers
  );
}

export const useUpdateGroupMembers = (
  groupId: string | undefined,
  options?: UseUpdateGroupMembersOptions
): UseMutationResult<
  UpdateGroupMembersResponse,
  unknown,
  UpdateGroupMembersData
> => {
  const groupsClient = useHttpClient();
  return useMutation(
    (data: UpdateGroupMembersData) =>
      groupsClient.patch<UpdateGroupMembersResponse>(
        `${API_ENDPOINTS.GROUPS}/${groupId}/members`,
        data
      ),
    options
  );
};

export const useDeleteGroup = (): UseMutationResult<
  DeleteGroupResponse,
  unknown,
  string,
  unknown
> => {
  const groupsClient = useHttpClient();
  return useMutation((groupId: string) =>
    groupsClient.delete<DeleteGroupResponse>(
      `${API_ENDPOINTS.GROUPS}/${groupId}`
    )
  );
};

export const useUpdateGroup = (
  groupId: string | undefined,
  options?: UseUpdateGroupOptions
): UseMutationResult<unknown, unknown, GroupForm> => {
  const groupsClient = useHttpClient();
  return useMutation(
    (data: GroupForm) =>
      groupsClient.patch(`${API_ENDPOINTS.GROUPS}/${groupId}/name`, data),
    options
  );
};

export const useCreateGroup = (
  parentId: string | undefined,
  options?: UseCreateGroupOptions
): UseMutationResult<CreateGroupResponse, unknown, CreateGroupData> => {
  const groupsClient = useHttpClient();
  return useMutation(
    (data: CreateGroupData) =>
      groupsClient.post<CreateGroupResponse>(
        parentId
          ? `${API_ENDPOINTS.GROUPS}/${parentId}`
          : `${API_ENDPOINTS.GROUPS}`,
        data
      ),
    options
  );
};

export const useMoveGroup = (
  selectedGroupId: string | undefined,
  parentGroupId: string | undefined
): UseMutationResult<MoveGroupResponse> => {
  const groupsClient = useHttpClient();
  return useMutation(() =>
    groupsClient.patch<MoveGroupResponse>(
      `${API_ENDPOINTS.GROUPS}/${selectedGroupId}/parent?parentGroupId=${parentGroupId}`,
      null
    )
  );
};

export const useUpsertGroupViewers = (
  groupId: string | undefined,
  options?: UseUpsertGroupViewersOptions
): UseMutationResult<CreateGroupResponse, unknown, UpsertGroupViewersData> => {
  const groupsClient = useHttpClient();
  return useMutation(
    (data: UpsertGroupViewersData) =>
      groupsClient.post<any>(
        `${API_ENDPOINTS.GROUPS}/${groupId}/group-viewers`,
        data
      ),
    options
  );
};
