import { useAddDashboardTile, useRemoveDashboardTile } from '@hooks/dashboard';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import { useEffect, useState } from 'react';
import { DashboardTiles } from '@common/constants';
import { useOrgGroupActivityUserSnapshotMeetingsQuery } from '@hooks/adoption';
import { TilesProps } from '@common/types';
import { formatNumberWithSuffix } from '@utils/index';

type Params = {
  days: string;
};

export const OrgGroupActivityUserMeetingsSnapshot: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));

  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.OrgGroupActivityUserMeetingsSnapshot,
    parameters
  );

  const isOnDashboardPage = !!id;

  const { data, isLoading, refetch } =
    useOrgGroupActivityUserSnapshotMeetingsQuery(parameters);

  useEffect(() => {
    setParameters(JSON.parse(params));

    return () => {
      refetch();
    };
  }, [params, refetch]);

  return (
    parameters && (
      <NumberTile
        dropdownMenuItemClasses={'!w-[300px]'}
        classNames={
          classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
        }
        key={id}
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        headerTitle={'Meetings / user: All teams'}
        isLoading={isLoading}
        data={
          data
            ? Object.values([
                (formatNumberWithSuffix(data.value) as unknown as number) || 0,
              ])
            : []
        }
        percentageLabel=""
      />
    )
  );
};
