import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { useAddDashboardTile, useRemoveDashboardTile } from '@hooks/dashboard';
import { useSecureScoreTrendQuery } from '@hooks/security';
import {
  LineChartTickFormat,
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { getTrendData, getTrendLabels } from '@utils/index';

export const SecureScoreChangesOverTime: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.SecureScoreChangesOverTime,
    ''
  );
  const isOnDashboardPage = !!id;
  const trendDataDefaultDays = 30;

  // data hook
  const {
    data: secureScoreChangesTrendData,
    isLoading: isSecureScoreChangesTrendDataLoading,
  } = useSecureScoreTrendQuery();

  // format trend data
  let formattedTrend: { date: string; value: number | null }[] = [];

  if (
    secureScoreChangesTrendData?.trend &&
    secureScoreChangesTrendData?.trend.length > 0
  ) {
    formattedTrend = secureScoreChangesTrendData.trend.map((trendItem) => ({
      ...trendItem,
      value: trendItem?.value ? parseFloat(trendItem.value.toFixed(1)) : null,
    }));
  }

  const labels = getTrendLabels(formattedTrend, trendDataDefaultDays);

  const trendData = getTrendData(formattedTrend, trendDataDefaultDays);

  return (
    <LineChartTile
      classNames={classNames ? classNames : 'col-span-3 lg:row-start-1'}
      sortData={[]}
      tooltipLabel={'secure score'}
      showCompareDropdown={false}
      showAgainstDropdown={false}
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      headerTitle={'Secure Score: Changes over time'}
      isLoading={isSecureScoreChangesTrendDataLoading}
      labels={labels}
      data={[trendData]}
      compareLabel=""
      dropdownMenuItemClasses={'!w-[300px]'}
      dataSetTypes={[LineChartTypes.Dots]}
      dataSetColors={['#00CF6C']}
      showYTicks={isOnDashboardPage ? false : true}
      showXTicks={isOnDashboardPage ? false : true}
      isDotted={isOnDashboardPage ? false : true}
      chartHeight={150}
      xTickFormat={LineChartTickFormat.Date}
      yTickFormat={LineChartTickFormat.Percentage}
    />
  );
};
