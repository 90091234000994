import { CellBottomPart } from '@components/ui/Table/utils';
import { SuspiciousActivityEvents } from '@hooks/security/types';
import { UserRoles, Permissions } from '@hooks/users/types';
import useAuthStore from '@store/authStore';
import { formatDate, hasPermission } from '@utils/index';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { CircleRightArrow } from '@components/ui/Icons';

export const createCellValue = (
  property: keyof SuspiciousActivityEvents,
  data: SuspiciousActivityEvents[],
  index: number,
  isMobile: boolean
) => {
  const value = data[index][property];

  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;

  const seePeopleDetailsHasPermission = hasPermission(
    userRole,
    Permissions.SUPERADMIN_ADMIN
  );

  switch (property) {
    case 'dateTime':
      return (
        <>
          <span>{formatDate(value)}</span>
          <CellBottomPart data={dayjs(value).format('HH:mm:ss')} />
        </>
      );
    case 'fullName':
      return (
        <>
          <span className="flex items-center">
            {value}{' '}
            {seePeopleDetailsHasPermission && (
              <Link
                className="ml-2 mt-[3px] cursor-pointer"
                to={`/accounts/accounts/account/${data[index]['accountId']}`}
              >
                <CircleRightArrow size="4" />
              </Link>
            )}
          </span>
          {!isMobile && <CellBottomPart data={data[index]['emailAddress']} />}
        </>
      );
    case 'ipAddress':
      return (
        <>
          <span>{value}</span>
          <CellBottomPart data={data[index]['ipType']} />
        </>
      );
  }
};
