import * as React from 'react';
import { IconProps } from './types';

export const UsersMoveIcon: React.FC<IconProps> = ({
  size = 4,
  color = 'text-interfaceColor-100',
  classNames = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`stroke-current w-${size} h-${size} ${color} ${classNames}`}
      viewBox="0 0 18 18"
      fill="none"
    >
      <g id="3d-move">
        <path
          d="M5.78571 10.9286V7.07143C5.78571 6.36135 6.36135 5.78571 7.07142 5.78571H10.9286C11.6386 5.78571 12.2143 6.36135 12.2143 7.07143V10.9286C12.2143 11.6386 11.6386 12.2143 10.9286 12.2143H7.07142C6.36135 12.2143 5.78571 11.6386 5.78571 10.9286Z"
          strokeWidth="1.14"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 0.642857V5.78571"
          strokeWidth="1.14"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.3572 9H12.2143"
          strokeWidth="1.14"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.07141 2.57143L8.99998 0.642857L10.9286 2.57143"
          strokeWidth="1.14"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.4286 7.07143L17.3571 9L15.4286 10.9286"
          strokeWidth="1.14"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 17.3571V12.2143"
          strokeWidth="1.14"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.642853 9H5.78571"
          strokeWidth="1.14"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.9286 15.4286L8.99998 17.3571L7.07141 15.4286"
          strokeWidth="1.14"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.57142 10.9286L0.642853 9L2.57142 7.07143"
          strokeWidth="1.14"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
