import * as React from 'react';
import { IconProps } from './types';

export const SubgroupIcon: React.FC<IconProps> = ({
  size = 5,
  color = 'text-interfaceColor-100',
  strokeWidth = 1.14,
  classNames = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
    >
      <g id="Frame" clipPath="url(#clip0_1356_17980)">
        <g id="hierarchy-14--node-organization-links-structure-link-nodes-network-hierarchy">
          <path
            id="Vector 2255"
            d="M4.82031 7.0731V1.93025C4.82031 1.22016 5.39595 0.644531 6.10603 0.644531H11.8917C12.6018 0.644531 13.1775 1.22016 13.1775 1.93025V7.0731C13.1775 7.78318 12.6018 8.35882 11.8917 8.35882H6.10603C5.39595 8.35882 4.82031 7.78318 4.82031 7.0731Z"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector 2256"
            d="M9 8.35938V13.5022"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector 2257"
            d="M2.57031 13.5011V12.2154C2.57031 11.5053 3.14595 10.9297 3.85603 10.9297H14.1417C14.8518 10.9297 15.4275 11.5053 15.4275 12.2154V13.5011"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector 2258"
            d="M0.642578 16.0714V14.7857C0.642578 14.0756 1.21821 13.5 1.92829 13.5H3.21401C3.92408 13.5 4.49972 14.0756 4.49972 14.7857V16.0714C4.49972 16.7815 3.92408 17.3571 3.21401 17.3571H1.92829C1.21821 17.3571 0.642578 16.7815 0.642578 16.0714Z"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector 2259"
            d="M7.07031 16.0714V14.7857C7.07031 14.0756 7.64595 13.5 8.35603 13.5H9.64174C10.3518 13.5 10.9275 14.0756 10.9275 14.7857V16.0714C10.9275 16.7815 10.3518 17.3571 9.64174 17.3571H8.35603C7.64595 17.3571 7.07031 16.7815 7.07031 16.0714Z"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector 2260"
            d="M13.5 16.0714V14.7857C13.5 14.0756 14.0756 13.5 14.7857 13.5H16.0714C16.7815 13.5 17.3571 14.0756 17.3571 14.7857V16.0714C17.3571 16.7815 16.7815 17.3571 16.0714 17.3571H14.7857C14.0756 17.3571 13.5 16.7815 13.5 16.0714Z"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1356_17980">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
