import { OverlayPage } from '@components/partials';
import { SectionNav } from '@components/ui/SectionNav';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import useUIStore from '@store/uiStore';
import { useIsDesktop } from '@hooks/utils';
import { Link, useNavigate } from 'react-router-dom';
import useAuthStore from '@store/authStore';
import { useCheckTermsConditions } from '@hooks/users';
import { Banner, Checkbox } from '@components/ui';
import { UserRoles } from '@hooks/users/types';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AuthError, InteractionStatus } from '@azure/msal-browser';
import { silentRequest } from '@api/msalConfig';
import { Variant } from '@components/ui/Toast';
import classNames from 'classnames';

const sectionNavItems = [
  { id: '1', label: 'Introduction' },
  { id: '2', label: 'Acceptance of Terms' },
  { id: '3', label: 'User Responsibilities' },
  { id: '4', label: 'Usage Restrictions' },

  { id: '5', label: 'Intellectual Property' },

  { id: '6', label: 'Data Privacy / Confidentiality' },

  { id: '7', label: 'Termination' },
  {
    id: '8',
    label: 'Changes to Terms',
  },
  { id: '9', label: 'Liability' },
  {
    id: '10',
    label: 'Governing Law and Jurisdiction',
  },
];

export const TermsAndConditionsPage: React.FC = () => {
  const { instance, inProgress } = useMsal();
  const isDesktop = useIsDesktop();
  const { isOverlayFixed, setIsOverlayFixed, addToast } = useUIStore();
  const isAuthenticated = useIsAuthenticated();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const {
    isTermsAndCondtitionsGranted,
    role,
    setIsAuthenticated,
    setToken,
    setUserRole,
    setHasSelectedProfile,
    selectedProfile,
  } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (isOverlayFixed) {
      setIsOverlayFixed(false);
    }

    return () => {
      if (!isOverlayFixed) {
        setIsOverlayFixed(true);
      }
    };
  }, []);

  const termsAndConditionsMutation = useCheckTermsConditions();

  const handleLogout = async () => {
    setIsAuthenticated(false);
    setToken(null);
    setUserRole(null);
    setHasSelectedProfile(null);

    try {
      if (isAuthenticated && inProgress === InteractionStatus.None) {
        await instance.acquireTokenSilent(silentRequest);
      }
    } catch (error) {
      console.error('Silent token acquisition failed:', error);
    }

    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: window.redirectUrl || 'http://localhost:3000',
      });
    } catch (error: unknown) {
      if (error instanceof AuthError && error.errorMessage) {
        addToast({ variant: Variant.Error, message: error.errorMessage });
      } else {
        addToast({
          variant: Variant.Error,
          message: 'An error occurred during logout.',
        });
      }
    }
  };

  const handleOnSave = () => {
    if (role === UserRoles.SUPERADMIN) {
      termsAndConditionsMutation.mutateAsync().then(() => {
        navigate('/dashboard');
      });
    } else {
      handleLogout();
    }
  };

  return (
    <OverlayPage
      headerTitle="Terms & conditions"
      headerText="Effective as of August 17th 2023"
      isFooterVisible={
        !isTermsAndCondtitionsGranted && selectedProfile !== null
      }
      hideCloseOverlay={
        !isTermsAndCondtitionsGranted && selectedProfile !== null
      }
      hasScroll={true}
      handleOnClose={() => {
        if (!isTermsAndCondtitionsGranted && selectedProfile !== null) {
          handleLogout();
        }
      }}
      footerCancelBtnLabel={'Logout'}
      isCancelBtnVisible={
        !isTermsAndCondtitionsGranted && role === UserRoles.SUPERADMIN
      }
      handleOnSave={handleOnSave}
      footerSaveBtnLabel={role === UserRoles.SUPERADMIN ? 'Continue' : 'Logout'}
      isDisabled={!acceptedTerms && role === UserRoles.SUPERADMIN}
      footerText={
        !isTermsAndCondtitionsGranted && role === UserRoles.SUPERADMIN ? (
          <Checkbox
            labelClassName="!text-[#FEFEFE]"
            checked={acceptedTerms}
            onChange={() => setAcceptedTerms(!acceptedTerms)}
            name="terms"
            label="I’ve read the terms & conditions and I’m happy to proceed"
          />
        ) : null
      }
    >
      <div className={styles.wrapper}>
        <div
          className={classNames(
            'lg:pt- fixed h-[calc(100%-160px)] w-[calc(100%-8px)] overflow-y-auto pt-[6px] md:w-[calc(100%-48px)] lg:mr-16 lg:w-[300px]',
            {
              'h-[calc(100%-260px)]': !isTermsAndCondtitionsGranted,
            }
          )}
        >
          <SectionNav
            items={sectionNavItems}
            scrollOffset={isDesktop ? -140 : -200}
          />
        </div>
        <div className={styles.sectionsWrapper}>
          <section id="section1">
            <div>
              {role === UserRoles.SUPERADMIN ? (
                <Banner
                  message={
                    'Before you get started with Clevr360, read our terms and conditions. Check the box if you’re happy to proceed.'
                  }
                  variant="info"
                  className="mb-0 mt-0"
                />
              ) : (
                <Banner
                  message={
                    ' Before you start using Clevr360, your organisation needs to accept our terms and conditions. The person who’s in charge of your Clevr360 account will do this when they log into Clevr360 for the first time.'
                  }
                  variant="info"
                  className="mb-6 mt-4"
                />
              )}
            </div>
            <h1 className={styles.h1}>Introduction</h1>
            <p className={styles.paragraph}>Welcome to CloudClevr Clevr360.</p>
            <p className={styles.paragraph}>
              The objective of these CloudClevr Clevr360 Terms and Conditions
              document is to establish the rules and guidelines that super
              admins (and authorised users) must adhere to when accessing and
              using the CloudClevr Clevr360 software platform(‘Clevr360’). These
              Terms and Conditions form a legally binding agreement between
              CloudClevr Holdings Limited (‘CloudClevr or ‘us’ or ‘we’) the
              entity which you, the super admin, represents.
            </p>
            <p className={styles.paragraph}>
              References to ‘you’ herein means you and the entity which you
              represent.
            </p>
          </section>
          <section id="section2">
            <h1 className={styles.h1}>Acceptance of Terms</h1>
            <p className={styles.paragraph}>
              By accessing and using Clevr360 provided by CloudClevr you agree
              to comply with and be bound by these terms and conditions. If you
              do not agree with any part of these terms, please do not use
              Clevr360. If you continue to use Clevr360 you will be deemed to
              have accepted these Terms and Conditions.
            </p>
            <p className={styles.paragraph}>
              You represent and warrant that you have the right and authority to
              act on behalf of and bind such entity and yourself. Any acts or
              omissions caused by you shall render the entity liable to
              CloudClevr under these Terms and Conditions.
            </p>
          </section>
          <section id="section3">
            <h1 className={styles.h1}>User Responsibilities</h1>
            <p className={styles.paragraph}>
              You are responsible for any authorised users who you add to the
              account within your entity to access to or use Clevr360. In
              certain cases, you may wish other authorised users to set up
              individual accounts to use Clevr360. You are responsible for
              ensuring that any such authorised users who you give access to and
              use CloudClevr are aware of, understand and comply with these
              Terms and Conditions.
            </p>
            <p className={styles.paragraph}>
              You must ensure that you and any such authorised users keep
              passwords confidential, do not share them and deal with them in a
              secured manner. If you suspect unauthorised use of your account,
              please contact
              <a className="ml-1" href="mailto:support@cloudclevr.com">
                support@cloudclevr.com
              </a>
            </p>
          </section>
          <section id="section4">
            <h1 className={styles.h1}>Usage Restrictions</h1>
            <p className={styles.paragraph}>
              You may not use Clevr360 for any unlawful purpose or in any way
              that could damage, disable, or impair the functionality of
              Clevr360.
            </p>
            <p className={styles.paragraph}>
              Unauthorised access to Clevr360 is strictly prohibited.
            </p>
            <p className={styles.paragraph}>
              You must take your own precautions to ensure that the process
              which you employ for accessing Clevr360 does not expose you to the
              risk of viruses, malicious computer code or other forms of
              interference which may damage your own computer system.
            </p>
            <p className={styles.paragraph}>
              CloudClevr does not accept responsibility for any interference or
              damage to your own computer system which arises in connection with
              your use of Clevr360.
            </p>
            <p className={styles.paragraph}>
              You must use Clevr360 in a lawful manner and you shall not cause
              or knowingly allow others to cause any nuisance, annoyance, or
              inconvenience whether to us or our other customers who use
              Clevr360. Furthermore, you shall not use Clevr360 to transmit or
              post any material which is fraudulent, hateful, sexually, or
              racially or ethnically or otherwise objectionable, defamatory,
              offensive, obscene, or menacing.
            </p>
          </section>
          <section id="section5">
            <h1 className={styles.h1}>Intellectual Property</h1>
            <p className={styles.paragraph}>
              All intellectual property rights related to and in Clevr360
              (including the software), including but not limited to trademarks,
              copyrights, and patents, are owned by CloudClevr or other third
              parties. You are granted a limited, non-exclusive,
              non-transferable license to use Clevr360 (including the software)
              for its intended purpose. Nothing in these Terms and Conditions
              grants you a right or licence to use any trademark, design right
              or copyright or any other intellectual property owned or
              controlled by CloudClevr or any other third party except as
              expressly provided in these Terms and Conditions.
            </p>
          </section>
          <section id="section6">
            <h1 className={styles.h1}>Data Privacy / Confidentiality</h1>
            <p className={styles.paragraph}>
              CloudClevr is committed to protecting your privacy. By using
              Clevr360, you consent to the collection, processing, and storage
              of your data as outlined in our Privacy Policy, which is
              incorporated by reference into these Terms and Conditions and is
              set out Privacy. <Link to="/privacy">Policy Link</Link>. Unless we
              agree otherwise in writing, you are provided with access to
              Clevr360 for your use only. Without limiting the foregoing, you
              may not, without our written permission, sell, share, give away or
              copy any information obtained from Clevr360 to any third party
              other than for the intended purpose for which CloudClevr provides
              such information. These obligations of confidentiality do not
              apply to any information which is already in the public domain,
              other than through a breach by you of this obligation, or which is
              required to be disclosed by law or a regulatory body.
            </p>
          </section>
          <section id="section7">
            <h1 className={styles.h1}>Termination</h1>
            <p className={styles.paragraph}>
              Your access to Clevr360 may be terminated and/or suspended at any
              time by us without notice for any reason whatsoever with no
              liability to you. These Terms and Conditions will nevertheless
              survive any such termination.
            </p>
          </section>
          <section id="section8">
            <h1 className={styles.h1}>Changes to Terms</h1>
            <p className={styles.paragraph}>
              CloudClevr reserves the right from time to time to modify these
              Terms and Conditions and such modifications shall become effective
              immediately upon posting such modified Terms and Conditions. Any
              use by you of Clevr360 after the posting of such modified Terms
              and Conditions will be deemed accepted by you of such modified
              Terms and Conditions.
            </p>
            <p className={styles.paragraph}>
              CloudClevr may, at any time, on notice to you, charge for use of
              Clevr360.
            </p>
          </section>
          <section id="section9">
            <h1 className={styles.h1}>Liability</h1>
            <p className={styles.paragraph}>
              We do not attempt or purport to exclude liability arising under
              statute if, and to the extent, such liability cannot be lawfully
              excluded. In particular, nothing in these Terms and Conditions
              purports to limit or exclude any liability for fraud or fraudulent
              misrepresentation or exclude or limit liability for death or
              personal injury caused by a person&apos;s negligence.
            </p>
            <p className={styles.paragraph}>
              Neither we nor any of our affiliates accept responsibility for any
              loss or damage, however caused (including through negligence),
              which any person may directly or indirectly suffer in connection
              with or arising from your use of Clevr360 or your use of or
              reliance on information contained on or accessed through the
              software portal. To the extent permitted by law, any condition or
              warranty which would otherwise be implied into these Terms and
              Conditions is hereby excluded. You acknowledge that Clevr360 may
              be hosted by a third party and, without limiting the terms of this
              disclaimer, that neither we nor our affiliates are responsible for
              the act or omission of any third party.
            </p>
            <p className={styles.paragraph}>
              In no event shall we be liable to you for any direct or indirect,
              special, or consequential damages, including without limitation to
              damages resulting from loss of profits, subscriptions,
              expenditures, contracts, or data arising out of the inability to
              use Clevr360, the misuse of the password or other identification
              data.
            </p>
            <p className={styles.paragraph}>
              You acknowledge that Clevr360 is provided via the internet and
              that we have no control over and/or the security of the internet.
              We endeavour to ensure that Clevr360 will be free of bugs and
              secure, but we cannot guarantee it will operate fault free or at
              all or that there will be no computer viruses and we do not assume
              any liability for timely, error-free, and uninterrupted access to
              the internet. We are not liable for hardware damage, loss of or
              corruption of data or downtime.
            </p>
            <p className={styles.paragraph}>
              All information including reports and summaries, without
              limitation (‘Information’), is created from information provided
              by third parties with whom you may or may not contract. The
              Information is provided as-is and CloudClevr gives no warranties
              or guarantees of any kind, whether expressed or implied, that such
              Information is error-free and accurate. CloudClevr is not
              providing you with legal, accounting, or other professional
              advice. You should contact your own professionals and/or check the
              Information so provided to ensure that it is accurate and
              error-free before you rely on it. CloudClevr is not a back-up
              service. You are solely responsible for securing and backing up
              your content stored on CloudClevr. We do not warrant that any
              level of back-up will be available in respect of any content on
              CloudClevr. In the event of any loss or damage to your data, your
              sole and exclusive remedy shall be for us to use reasonable
              commercial endeavours to restore the lost or damaged data.
            </p>
            <p className={styles.paragraph}>
              You agree that the limitations and exclusions set out in these
              Terms and Conditions are reasonable having regard to the relevant
              circumstances and the use you are permitted to make of Clevr360.
            </p>
          </section>
          <section id="section10" className="mb-[600px]">
            <h1 className={styles.h1}>Governing Law and Jurisdiction</h1>
            <p className={styles.paragraph}>
              These terms and conditions are governed by and construed in
              accordance with the laws of England and Wales. Any legal actions
              or proceedings relating to your use of Clevr360 shall be brought
              before the competent courts of the England and Wales.
            </p>
            <p className={styles.paragraph}>
              <b>
                By using Clevr360, you acknowledge that you have read,
                understood, and agree to these Terms and Conditions.
              </b>
            </p>
          </section>
        </div>
      </div>
    </OverlayPage>
  );
};
