import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import { useTeamsAndGroupActivitySnapshot } from '@hooks/adoption';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';

import { useState } from 'react';

type Params = {
  days: string;
  dataType: string;
};

export const TeamsAndGroupsActivitySnapshot: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.TeamsAndGroupsActivitySnapshot,
    parameters
  );
  const isOnDashboardPage = !!id;

  const { data, isLoading } = useTeamsAndGroupActivitySnapshot(parameters);

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All activity',
      value: 'allactivity',
      action: () => handleSortDropdownChange('allactivity'),
    },
    {
      id: '2',
      label: 'Created',
      value: 'created',
      action: () => handleSortDropdownChange('created'),
    },
    {
      id: '1',
      label: 'Deleted',
      value: 'deleted',
      action: () => handleSortDropdownChange('deleted'),
    },
    {
      id: '1',
      label: 'Archived',
      value: 'archived',
      action: () => handleSortDropdownChange('archived'),
    },
  ];

  const handleCompareDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      days: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });
    }
  };

  const compareMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 7 days',
      value: '7',
      action: () => handleCompareDropdown('7'),
    },
    {
      id: '2',
      label: 'Last 30 days',
      value: '30',
      action: () => handleCompareDropdown('30'),
    },
    {
      id: '3',
      label: 'Last 60 days',
      value: '60',
      action: () => handleCompareDropdown('60'),
    },
    {
      id: '4',
      label: 'Last 90 days',
      value: '90',
      action: () => handleCompareDropdown('90'),
    },
  ];

  const handleSortDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      dataType: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });
    }
  };

  return (
    <NumberTile
      dropdownMenuItemClasses={'!w-[300px]'}
      initialSelectedItem={sortMenuItems.find(
        (item) => item.value === parameters.dataType
      )}
      initialCompareSelectedItem={compareMenuItems.find(
        (item) => item.value === parameters.days
      )}
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      sortData={sortMenuItems}
      compareData={compareMenuItems}
      headerTitle={'Teams & groups activity'}
      isLoading={isLoading}
      data={data ? Object.values(data) : []}
      buttonLabel={'Teams & groups data'}
      linkPath={ANALYTICS_NAVIGATION_MAP['teamsGroupsActivity']}
      handleButtonClick={handleButtonClick()}
      percentageLabel=""
      bottomLabel={
        parameters.dataType === 'allactivity'
          ? 'Teams created, archived or deleted'
          : `Teams ${parameters.dataType}`
      }
    />
  );
};
