import useUIStore from '@store/uiStore';
import { useEffect } from 'react';

export const useStickyElements = () => {
  const { setIsAtStickyPositions, headerHeight } = useUIStore();

  useEffect(() => {
    const onScroll = () => {
      const stickyEl = document
        .querySelector('#sticky-table-actions')
        ?.getBoundingClientRect();
      stickyEl?.top == 16
        ? setIsAtStickyPositions(true)
        : setIsAtStickyPositions(false);
    };
    document.querySelector('#content')?.addEventListener('scroll', onScroll);

    const onScrollMobile = () => {
      const stickyEl = document
        .querySelector('#sticky-table-actions')
        ?.getBoundingClientRect();

      stickyEl?.top == headerHeight
        ? setIsAtStickyPositions(true)
        : setIsAtStickyPositions(false);
    };

    window.addEventListener('scroll', onScrollMobile);

    return () => {
      document
        .querySelector('#content')
        ?.removeEventListener('scroll', onScroll);
      window.removeEventListener('scroll', onScrollMobile);
      setIsAtStickyPositions(false);
    };
  }, [headerHeight, setIsAtStickyPositions]);
};
