export const sizeClasses = {
  '2': 'w-2 h-2',
  '3': 'w-3 h-3',
  '4': 'w-4 h-4',
  '5': 'w-5 h-5',
  '6': 'w-6 h-6',
  '7': 'w-7 h-7',
  '8': 'w-8 h-8',
  '9': 'w-9 h-9',
  '10': 'w-10 h-10',
};

export { AddPeopleIcon } from './AddPeopleIcon';
export { AddUserIcon } from './AddUserIcon';
export { ArrowDownIcon } from './ArrowDownIcon';
export { ArrowLeftIcon } from './ArrowLeftIcon';
export { ArrowRightIcon } from './ArrowRightIcon';
export { ArrowLongRightIcon } from './ArrowLongRightIcon';
export { ArrowUpIcon } from './ArrowUpIcon';
export { CheckCircleIcon } from './CheckCircleIcon';
export { CalendarIcon } from './CalendarIcon';
export { CloseCircleIcon } from './CloseCircleIcon';
export { CloseIcon } from './CloseIcon';
export { DeleteIcon } from './DeleteIcon';
export { DownloadIcon } from './DownloadIcon';
export { EditIcon } from './EditIcon';
export { EyeIcon } from './EyeIcon';
export { HamburgerIcon } from './HamburgerIcon';
export { LocationIcon } from './LocationIcon';
export { LogoutIcon } from './LogoutIcon';
export { MoveIcon } from './MoveIcon';
export { OnwardIcon } from './OnwardIcon';
export { PlusIcon } from './PlusIcon';
export { MinusIcon } from './MinusIcon';
export { SearchIcon } from './SearchIcon';
export { StructureIcon } from './StructureIcon';
export { SubgroupIcon } from './SubgroupIcon';
export { TimesCircleIcon } from './TimesCircleIcon';
export { UserIcon } from './UserIcon';
export { WarningCircleIcon } from './WarningCircleIcon';
export { WarningIcon } from './WarningIcon';
export { DashboardIcon } from './DashboardIcon';
export { LineChartIcon } from './LineChartIcon';
export { LineChartIconUp } from './LineChartIconUp';
export { LightInfoIcon } from './LightInfoIcon';
export { FilterIcon } from './FilterIcon';
export { HubSettingsUserIcon } from './HubSettingsUserIcon';
export { LightningIcon } from './LightningIcon';
export { LocationPinIcon } from './LocationPinIcon';
export { BellIcon } from './BellIcon';
export { ReconnectIcon } from './ReconnectIcon';
export { DeactivateIcon } from './DeactivateIcon';
export { ResetPasswordIcon } from './ResetPasswordIcon';
export { LockIcon } from './LockIcon';
export { CloudClevrLogoIcon } from './CloudClevrLogoIcon';
export { UsersMoveIcon } from './UsersMoveIcon';
export { UnlockIcon } from './UnlockIcon';
export { ClipboardIcon } from './ClipboardIcon';
export { EmailIcon } from './EmailIcon';
export { PhoneIcon } from './PhoneIcon';
export { CircleRightArrow } from './CircleRightArrow';
export { UsersLocationMoveIcon } from './UserLocationMove';
export { LongArrowLeftIcon } from './LongArrowLeftIcon';
export { SandClockIcon } from './SandClockIcon';
