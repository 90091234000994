import { DropdownData } from '@components/ui/Dropdown';
import {
  AddUserIcon,
  DeleteIcon,
  EditIcon,
  LockIcon,
  UnlockIcon,
  ReconnectIcon,
  DeactivateIcon,
} from '@components/ui/Icons';
import { UserRoles } from '@hooks/users/types';

export const HUB_USERS_DROPDOWN_ACTIONS: DropdownData[] = [
  {
    label: 'Edit user details',
    icon: <AddUserIcon size="5" />,
    value: ['editUserDetails'],
  },
  {
    label: 'Edit access level',
    icon: <EditIcon size="5" />,
    value: ['editAccessLevel'],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isHidden: (row: any, role?: UserRoles, _localAccountId?: string) => {
      return role === UserRoles.ADMIN && row['role'] === UserRoles.SUPERADMIN;
    },
  },
  // {
  //   label: 'Reset password',
  //   icon: <ResetPasswordIcon size="5" color="text-interfaceColor-100" />,
  //   value: ['resetPassword'],
  // },
  {
    label: 'Lock account',
    icon: <LockIcon size="5" color="text-interfaceColor-100" />,
    value: ['lockAccount'],
    isHidden: (row: any, role?: UserRoles, localAccountId?: string) => {
      return (
        row['isEnabled'] === false ||
        row['id'] === localAccountId ||
        (role === UserRoles.ADMIN && row['role'] === UserRoles.SUPERADMIN)
      );
    },
  },
  {
    label: 'Unlock account',
    icon: <UnlockIcon size="5" color="text-interfaceColor-100" />,
    value: ['lockAccount'],
    isHidden: (row: any) => {
      return row['isEnabled'] === true;
    },
  },
  {
    label: 'Remove user',
    icon: <DeleteIcon size="5" color="text-errorColor-150" />,
    value: ['removeUser'],
    color: 'text-errorColor-150',
    isHidden: (row: any, role?: UserRoles, localAccountId?: string) => {
      return (
        role === UserRoles.STANDARD ||
        row['id'] === localAccountId ||
        (role === UserRoles.ADMIN && row['role'] === UserRoles.SUPERADMIN)
      );
    },
  },
];

export const CONNECTED_SERVICES: DropdownData[] = [
  {
    label: 'Reconnect service',
    icon: <ReconnectIcon classNames="mr-1" size="4" />,
    value: ['reconnect'],
    isHidden: (row: any) => {
      return row && (row['status'] === 0 || row['status'] === 2 || row['status'] === 5);
    },
  },
  {
    label: 'Deactivate service',
    icon: <DeactivateIcon classNames="mr-1" size="4" />,
    value: ['deactivate'],
    isHidden: (row: any) => {
      return row && (row['status'] === 1 || row['status'] === 5);
    },
  },
  {
    label: 'Reactivate service',
    icon: <ReconnectIcon classNames="mr-1" size="4" />,
    value: ['reactivate'],
    isHidden: (row: any) => {
      return row && (row['status'] === 1 || row['status'] === 0 || row['status'] === 5);
    },
  },
  {
    label: 'Cancel connection',
    icon: <DeactivateIcon classNames="mr-1" size="4" />,
    value: ['cancel'],
    isHidden: (row: any) => {
      return row && (row['status'] !== 5);
    },
  },
];
