import { Button, Checkbox, Modal } from '@components/ui';
import { Variant } from '@components/ui/Toast';
import { useDeleteUser } from '@hooks/users';
import { User } from '@hooks/users/types';
import { useIsMobile } from '@hooks/utils/isMobile';
import { useIsTablet } from '@hooks/utils/isTablet';
import useUIStore from '@store/uiStore';
import { useState } from 'react';

export type ResetPasswordModalProps = {
  user: User | undefined;
  isModalOpen: boolean;
  handleModalClose: () => void;
  handleSuccess: () => void;
};

export const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  user,
  isModalOpen,
  handleModalClose,
  handleSuccess,
}) => {
  const { addToast } = useUIStore();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const [sendResetLink, setSendResetLink] = useState(false);

  // const { mutateAsync, isLoading } = useDeleteUser(user?.id);

  const handleSendLink = async () => {
    try {
      // await mutateAsync();
      // addToast({
      //   variant: 'success',
      //   message: `We’ve sent ${user?.fullName} a password reset link`,
      //   closeable: true,
      // });
      // handleSuccess();
    } catch (error) {
      addToast({
        variant: Variant.Error,
        message: 'Something went wrong, and your changes haven’t been saved.',
        closeable: true,
      });
    }
  };

  return (
    <Modal
      headerTxt={`Reset ${user?.fullName}’s password`}
      modalClassName={'h-full mt-0'}
      isOpen={isModalOpen}
      size={isTablet || isMobile ? 'small' : 'medium'}
      position={isMobile ? 'bottom' : 'default'}
      showCloseBtn={true}
      onClose={handleModalClose}
      actionButtons={
        <div className="flex flex-col gap-2 lg:flex-row">
          <Button variant="primary" disabled={true} onClick={handleSendLink}>
            Send reset link
          </Button>
          <Button variant="outlineLight" onClick={handleModalClose}>
            Cancel
          </Button>
        </div>
      }
      contentClassName="flex !flex-row !items-start !justify-start bg-white"
    >
      <div>
        <p>
          We’ll email a password reset link to firstname.lastname@email.com. For
          security reasons, the link will expire after 72 hours.
        </p>
        <Checkbox
          checked={sendResetLink}
          className="mb-5 mt-3"
          label={`Lock this account until ${user?.fullName} changes their password.`}
          name="sendResetLink"
          onChange={() => setSendResetLink(!sendResetLink)}
        />
      </div>
    </Modal>
  );
};
