import * as React from 'react';
import { IconProps } from './types';

export const LockIcon: React.FC<IconProps> = ({
  color = 'text-primaryBrandColor-100',
  classNames = '',
  size = 4,
}) => {
  return (
    <svg
      className={`stroke-current w-${size} h-${size} ${color} ${classNames}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 16"
      fill="none"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.143}
        d="M11.57 6.287H2.429c-.631 0-1.143.512-1.143 1.143v6.857c0 .631.512 1.143 1.143 1.143h9.143c.631 0 1.143-.512 1.143-1.143V7.43c0-.631-.512-1.143-1.143-1.143ZM11 6.287V4.572a4 4 0 1 0-8 0v1.715"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.143}
        d="M7 11.43a.571.571 0 1 0-.001-1.143.571.571 0 0 0 0 1.143Z"
      />
    </svg>
  );
};
