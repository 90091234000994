import * as React from 'react';
import { IconProps } from './types';

export const DownloadIcon: React.FC<IconProps> = ({
  size = 4,
  color = 'text-interfaceColor-100',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
    >
      <g clipPath="url(#clip0_1_702)">
        <path
          d="M8 15.3942V8.53708"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.71428 13.1085L7.99999 15.3942L10.2857 13.1085"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.47954 11.0706C3.51402 11.1354 2.56231 10.814 1.83374 10.1771C1.10518 9.54025 0.659457 8.64003 0.594629 7.67453C0.529802 6.70901 0.851179 5.75728 1.48806 5.02872C1.80342 4.66798 2.18673 4.37287 2.61614 4.16026C3.04553 3.94766 3.51261 3.82171 3.99067 3.78961C4.0403 3.7907 4.08918 3.77757 4.13158 3.75176C4.17397 3.72595 4.20808 3.68856 4.22991 3.64399C4.5597 2.68815 5.20722 1.87425 6.06447 1.33803C6.92171 0.801814 7.93689 0.575686 8.94069 0.697358C9.94448 0.81903 10.8762 1.28114 11.5806 2.00665C12.2848 2.73215 12.7192 3.67717 12.8111 4.68413C12.8187 4.73714 12.8414 4.78687 12.8765 4.82741C12.9114 4.86795 12.9574 4.89763 13.0087 4.91296C13.7519 5.08807 14.4051 5.52963 14.8447 6.15405C15.2842 6.77846 15.4795 7.54237 15.3937 8.30113C15.3079 9.05989 14.9467 9.76082 14.3787 10.2712C13.8107 10.7816 13.0753 11.066 12.3118 11.0706"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};
