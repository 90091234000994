import { silentRequest } from '@api/msalConfig';
import closeIcon from '@assets/images/close_icon.png';
import logo from '@assets/svg/logo_invert.svg';
import { AuthError, InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { DEFAULT_ANIMATION_DURATION, SIDEBAR_LINKS } from '@common/constants';
import { Avatar, Button } from '@components/ui';
import { LogoutIcon } from '@components/ui/Icons';
import useAuthStore from '@store/authStore';
import useUIStore from '@store/uiStore';
import React, { useRef, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import './styles.css';
import { useCurrentUserQuery } from '@hooks/users';
import { Variant } from '@components/ui/Toast';
import { useIsDesktop, useIsTablet } from '@hooks/utils';
import { TransitionState } from '@components/ui/Overlay';

export const Sidebar: React.FC = () => {
  const { instance } = useMsal();
  const authStore = useAuthStore();
  const isAuthenticated = useIsAuthenticated();
  const nodeRef = useRef(null);
  const { addToast, setSidebar } = useUIStore();
  const location = useLocation();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const userData = useCurrentUserQuery();

  const [, setIsClosedClicked] = useState(false);
  const isSidebarOpen = useUIStore((state) => state.isSidebarOpen);
  const toggleSidebar = useUIStore((state) => state.toggleSidebar);
  const { inProgress } = useMsal();

  const handleSidebarClose = () => {
    setIsClosedClicked(true);
    toggleSidebar();
  };

  const handleLinkClick = () => {
    setSidebar(false);
  };

  const isLinkActive = (to: string) => {
    const { pathname } = location;
    const nestedRoutes = ['accounts', 'insights-and-analytics', 'settings'];
    let isActive = false;

    if (pathname === to) {
      isActive = true;
    } else {
      nestedRoutes.forEach((route) => {
        if (pathname.includes(route) && to.includes(route)) {
          isActive = true;
        }
      });
    }

    const styles = {
      active:
        'block border-b-2 border-primaryBrandColor-100 pb-1 pl-0 pr-0 pt-1 text-interfaceColor-20 transition-colors duration-100 hover:cursor-pointer md:pb-3 md:pl-0 md:pr-0 md:pt-3',
      inactive:
        'block border-b-2 border-transparent pb-1 pl-0 pr-0 pt-1 text-interfaceColor-40 transition-colors duration-100 hover:cursor-pointer hover:border-interfaceColor-20 hover:text-interfaceColor-20 md:pb-3 md:pl-0 md:pr-0 md:pt-3',
    };

    return isActive ? styles.active : styles.inactive;
  };

  const handleLogout = async () => {
    authStore.setIsAuthenticated(false);
    authStore.setToken(null);
    authStore.setUserRole(null);
    authStore.setHasSelectedProfile(null);

    try {
      if (isAuthenticated && inProgress === InteractionStatus.None) {
        await instance.acquireTokenSilent(silentRequest);
      }
    } catch (error) {
      console.error('Silent token acquisition failed:', error);
    }

    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: window.redirectUrl || 'http://localhost:3000',
      });
    } catch (error: unknown) {
      if (error instanceof AuthError && error.errorMessage) {
        addToast({ variant: Variant.Error, message: error.errorMessage });
      } else {
        addToast({
          variant: Variant.Error,
          message: 'An error occurred during logout.',
        });
      }
    }
  };

  const defaultStyle = {
    transition: `transform 300ms ease-in-out`,
    transform: 'translateX(101%)',
  };

  const transitionStyles: Record<TransitionState, React.CSSProperties> = {
    entering: { transform: 'translateX(101%)' },
    entered: { transform: 'translateX(0)' },
    exiting: { transform: 'translateX(101%)' },
    exited: { transform: 'translateX(101%)' },
    unmounted: {},
  };

  return (
    <>
      {isDesktop && !isTablet ? (
        <div className="lg:p-4">
          <nav
            ref={nodeRef}
            className={`left-{16px} top-{16px} fixed fixed z-30 z-30 flex flex h-screen w-full min-w-full flex-col flex-col bg-interfaceColor-100 bg-interfaceColor-100 p-0 p-0 transition-transform duration-300 ease-out md:h-[calc(100vh-32px)] md:w-[312px] md:min-w-[280px] lg:w-[276px] lg:rounded lg:rounded`}
          >
            <div className="flex h-[76px] justify-between p-5 md:h-[92px] md:p-6 lg:pb-8 lg:pl-6 lg:pr-6 lg:pt-8">
              <img
                className="w-[158px] gap-[8px] lg:w-[151px]"
                src={logo}
                alt="CloudClevr Logo"
              />
              <div className="h-[36px] w-[36px] lg:hidden">
                <button onClick={handleSidebarClose}>
                  <img src={closeIcon} alt="Close Sidebar" />
                </button>
              </div>
            </div>

            <div className="pb-5 pl-5 pr-5 pt-0 md:pb-6 md:pl-6 md:pr-6 md:pt-0">
              <ul className="flex flex-col items-start text-20 leading-7 text-interfaceColor-40">
                {SIDEBAR_LINKS.map(({ to, label }) => (
                  <li className="mt-2 md:w-full lg:w-[212px]" key={to}>
                    <NavLink
                      onClick={handleLinkClick}
                      to={to}
                      className={isLinkActive(to)}
                    >
                      {label}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-auto h-[96px] p-6">
              <div className="flex gap-[8px]">
                <Avatar fullName={userData?.data?.fullName} />
                <div className="flex flex-col justify-start">
                  <Link
                    to="/my-profile/details"
                    className="font-semibold text-interfaceColor-40"
                  >
                    {userData?.data?.fullName}
                  </Link>
                  <Button
                    className="ml-0 justify-start self-start !p-0 text-interfaceColor-40 !shadow-none"
                    variant={'white'}
                    onClick={handleLogout}
                    type="button"
                    size="extraSmall"
                  >
                    <div className="flex">
                      <LogoutIcon classNames="h-2 w-2" />
                      <span className="leading-3">Log out</span>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </nav>
        </div>
      ) : (
        <Transition
          in={isSidebarOpen}
          timeout={DEFAULT_ANIMATION_DURATION}
          unmountOnExit
          classNames={{
            enter: 'sidebar-enter',
            enterActive: 'sidebar-enter-active',
            exit: 'sidebar-exit',
            exitActive: 'sidebar-exit-active',
          }}
          nodeRef={nodeRef}
          appear
        >
          {(state) => (
            <nav
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
              ref={nodeRef}
              className={`fixed z-30 flex flex-col bg-interfaceColor-100 p-0 ${
                isTablet
                  ? 'right-0 top-0 h-screen w-[312px]'
                  : 'left-{16px} top-{16px} fixed z-30 flex h-screen w-full min-w-full flex-col bg-interfaceColor-100 p-0 md:h-[calc(100vh-32px)] md:w-[312px] md:min-w-[280px] lg:w-[276px] lg:rounded'
              } ${
                isTablet && isSidebarOpen
                  ? 'translate-x-0 transform'
                  : 'transform'
              } transition-transform duration-300 ease-out lg:rounded`}
            >
              <div className="flex h-[76px] justify-between p-5 md:h-[92px] md:p-6 lg:pl-6 lg:pr-6 lg:pt-8">
                <img
                  className="w-[158px] gap-[8px] lg:w-[151px]"
                  src={logo}
                  alt="CloudClevr Logo"
                />
                <div className="h-[36px] w-[36px]">
                  <button onClick={handleSidebarClose}>
                    <img src={closeIcon} alt="Close Sidebar" />
                  </button>
                </div>
              </div>

              <div className="pb-5 pl-5 pr-5 pt-0 md:pb-6 md:pl-6 md:pr-6 md:pt-0">
                <ul className="flex flex-col items-start text-20 leading-7 text-interfaceColor-40">
                  {SIDEBAR_LINKS.map(({ to, label }) => (
                    <li className="mt-2 md:w-full lg:w-[212px]" key={to}>
                      <NavLink
                        onClick={handleLinkClick}
                        to={to}
                        className={isLinkActive(to)}
                      >
                        {label}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>

              {/* ToDo: Roadmap link */}
              {/* 
        <div className="flex w-full flex-col bg-interfaceColor-90 px-[24px] py-[20px]">
          <span className="block text-20 text-primaryBrandColor-100">
            See the future of CloudClevr
          </span>
          <span className="block text-16 text-interfaceColor-15">
            Check out our open roadmap and add your ideas
          </span>
          <Button
            variant="default"
            type="button"
            className="justify-between text-14"
          >
            <div className="flex ">
              <span className="text-18">See the roadmap</span>
              <ArrowRightIcon
                color={'text-interfaceColor-100'}
                size="3"
                classNames="ml-2 mt-[2px]"
              />
            </div>
          </Button>
        </div> */}

              <div className="mt-auto h-[96px] p-6">
                <div className="flex gap-[8px]">
                  <Avatar fullName={userData?.data?.fullName} />
                  <div>
                    <p className="font-semibold text-interfaceColor-40">
                      {userData?.data?.fullName}
                    </p>
                    <Button
                      className="!p-0 text-interfaceColor-40 !shadow-none"
                      variant={'white'}
                      onClick={handleLogout}
                      type="button"
                      size="extraSmall"
                    >
                      <div className="flex">
                        <LogoutIcon classNames="h-2 w-2" />
                        <span className="leading-3">Log out</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            </nav>
          )}
        </Transition>
      )}
    </>
  );
};
