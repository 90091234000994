import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { ToastProps as Toast } from '../components/ui/Toast';

export enum NavSource {
  DashboardTile = 1,
  DashboardNotification = 2,
  NotificationPage = 3,
}

type ExternalTeam = {
  email: string;
  ids: string[];
};

type GroupData = {
  name: string | undefined;
  parentId: string | undefined;
  hubUsersIds?: string[] | undefined;
};

type UIStore = {
  isSidebarOpen: boolean;
  showLocationTooltip: boolean;
  showGroupTooltip: boolean;
  toasts: Toast[];
  unreadNotificationsCount: number;
  isOverlayFixed: boolean;
  overlayHeaderHeight: number;
  headerHeight: number;
  isAtStickyPositions: boolean;
  navSource: NavSource | null;
  externalTeamsDetails: ExternalTeam;
  groupData: GroupData;
  termsAcknowledged: boolean;
  navigationTilePathOptions: number | null;
};

type UIStoreActions = {
  toggleSidebar: () => void;
  setSidebar: (value: boolean) => void;
  setShowLocationTooltip: (value: boolean) => void;
  setShowGroupTooltip: (value: boolean) => void;
  addToast: (toast: Toast) => void;
  cleanAllToasts: () => void;
  setUnreadNotificationCount: (value: number) => void;
  setIsOverlayFixed: (value: boolean) => void;
  setOverlayHeaderHeight: (value: number) => void;
  setHeaderHeight: (value: number) => void;
  setIsAtStickyPositions: (value: boolean) => void;
  setNavSource: (value: NavSource | null) => void;
  setExternalTeamsDetails: (value: ExternalTeam) => void;
  setGroupData: (value: GroupData) => void;
  setTermsAcknowledged: (value: boolean) => void;
  setNavigationTilePathOptions: (value: number | null) => void;
};

const useUIStore = create(
  devtools(
    persist<UIStore & UIStoreActions>(
      (set) => ({
        isSidebarOpen: false,
        showLocationTooltip: true,
        showGroupTooltip: true,
        unreadNotificationsCount: 0,
        toasts: [],
        isOverlayFixed: true,
        overlayHeaderHeight: 0,
        headerHeight: 0,
        isAtStickyPositions: false,
        termsAcknowledged: false,
        navSource: null,
        navigationTilePathOptions: null,
        externalTeamsDetails: { email: '', ids: [] },
        groupData: {
          name: undefined,
          parentId: undefined,
          hubUsersIds: undefined,
        },
        toggleSidebar: () => {
          const { isSidebarOpen } = useUIStore.getState();
          set(() => ({ isSidebarOpen: !isSidebarOpen }));
        },
        setSidebar: (value: boolean) => {
          set(() => ({ isSidebarOpen: value }));
        },
        setIsOverlayFixed: (value: boolean) => {
          set(() => ({ isOverlayFixed: value }));
        },
        setOverlayHeaderHeight: (value: number) => {
          set(() => ({ overlayHeaderHeight: value }));
        },
        setTermsAcknowledged: (value) =>
          set(() => ({
            termsAcknowledged: value,
          })),
        setHeaderHeight: (value: number) => {
          set(() => ({ headerHeight: value }));
        },
        setGroupData: (value: GroupData) => {
          set(() => ({ groupData: { ...value } }));
        },
        setIsAtStickyPositions: (value: boolean) => {
          set(() => ({ isAtStickyPositions: value }));
        },
        setNavSource: (value: NavSource | null) => {
          set(() => ({ navSource: value }));
        },
        setNavigationTilePathOptions: (value) => {
          set(() => ({ navigationTilePathOptions: value }));
        },
        setExternalTeamsDetails: (value: ExternalTeam) => {
          set(() => ({ externalTeamsDetails: value }));
        },
        setUnreadNotificationCount: (value: number) => {
          set(() => ({ unreadNotificationsCount: value }));
        },
        setShowLocationTooltip: (value: boolean) => {
          set(() => ({ showLocationTooltip: value }));
        },
        setShowGroupTooltip: (value: boolean) => {
          set(() => ({ showGroupTooltip: value }));
        },
        cleanAllToasts: () => {
          set(() => ({ toasts: [] }));
        },
        addToast: (toast) =>
          set((state) => ({
            toasts: [...state.toasts, { ...toast }],
          })),
      }),
      { name: 'ui' }
    ),
    { enabled: false }
  )
);

export default useUIStore;
