import * as React from 'react';
import { IconProps } from './types';

export const MoveIcon: React.FC<IconProps> = ({
  size = 5,
  color = 'text-interfaceColor-100',
  strokeWidth = 1.14,
  classNames = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      fill="none"
    >
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        clipPath="url(#a)"
      >
        <path d="M5.785 10.928V7.071c0-.71.576-1.286 1.286-1.286h3.857c.71 0 1.286.576 1.286 1.286v3.857c0 .71-.576 1.286-1.286 1.286H7.071c-.71 0-1.286-.576-1.286-1.286ZM9 .643v5.142M17.358 9h-5.143M7.07 2.571 9 .643l1.928 1.928M15.428 7.07 17.356 9l-1.928 1.928M9 17.358v-5.143M.643 9h5.142M10.928 15.428l-1.93 1.928-1.928-1.928M2.571 10.928.643 8.998 2.57 7.07" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
