import React, { useState } from 'react';
import Tab, { Tab as TabType } from '../../../../components/ui/Tab';
import { useIsDesktop, useIsMobile } from '@hooks/utils';
import { OverlayPage } from '@components/partials';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { Dropdown } from '@components/ui/Dropdown';
import { LAST_DAYS_SORT_BY } from '@common/constants';
import styles from './styles.module.css';
import { useQueryParams } from 'use-query-params';
import { DelegatedMailboxData } from '@pages/Dashboard/tiles/DelegatedMailboxData';
import { DelegatedMailboxAccounts } from './tiles/DelegatedMailboxAccounts';
import { Leaderboard } from './tiles/Leaderboard';

const floatingSortDropdownData = LAST_DAYS_SORT_BY;

const DelegatedMailboxLayout: React.FC = () => {
    const isMobile = useIsMobile();
    const redirectPath = useNavSourceRedirect(
        '/insights-and-analytics/assurance'
    );
    const [days, setDays] = useState('30');
    const [daysSelectedLabel, setDaysSelectedLabel] = useState(
        floatingSortDropdownData[1].label || ''
    );
    const [daysSelectedIndex, setDaysSelectedIndex] = useState(1);
    const [isDaysDropdownOpen, setIsDaysDropdownOpen] = useState(false);
    const isDesktop = useIsDesktop();
    const [queryParams, setQueryParams] = useQueryParams();

    const tabs: TabType[] = [
        {
            path: '/insights-and-analytics/assurance/delegated-mailbox-data/delegates',
            label: 'Delegates',
        },
        {
            path: '/insights-and-analytics/assurance/delegated-mailbox-data/mailboxes',
            label: 'Mailboxes',
        },
    ];

    const handleIsDaysDropdownOpen = () => {
        setIsDaysDropdownOpen(!isDaysDropdownOpen);
    };

    const handleDropdownDaysOnChange = (value: string[], index: number) => {
        setDays(value[0]);
        setDaysSelectedIndex(index);
        setDaysSelectedLabel(floatingSortDropdownData[index].label);
        setQueryParams(
            { days: value[0] || undefined },
            'pushIn'
        );
    };

    return (
        <OverlayPage
            isFooterVisible={false}
            contentClassNames={''}
            path={redirectPath}
            headerTitle={`Mailboxes accessed by delegates`}
        >
            {!isMobile && (
                <div className="absolute left-[40px] top-[100px]">
                    <Dropdown>
                        <Dropdown.TextHeader
                            classNames="!bg-interfaceColor-5 !border !border-interfaceColor-100 rounded-full"
                            label={daysSelectedLabel}
                            handleOpen={handleIsDaysDropdownOpen}
                        ></Dropdown.TextHeader>
                        <Dropdown.List
                            className="!z-[999] text-16"
                            open={isDaysDropdownOpen}
                            align="left"
                        >
                            {floatingSortDropdownData.map((item, index) => {
                                return (
                                    <Dropdown.DefaultItem
                                        key={index}
                                        item={item}
                                        currentIndex={index}
                                        selectedIndex={daysSelectedIndex}
                                        onChange={() =>
                                            handleDropdownDaysOnChange(item.value, index)
                                        }
                                    ></Dropdown.DefaultItem>
                                );
                            })}
                        </Dropdown.List>
                    </Dropdown>
                </div>
            )}
            <div className={styles.pageGrid}>
                {isDesktop && (
                    <>
                        <DelegatedMailboxData
                            isSnapshot={true}
                            classNames="col-span-1"
                            params={JSON.stringify({ days })}
                        />
                        <Leaderboard params={JSON.stringify({ days })} />
                        <DelegatedMailboxAccounts classNames="col-span-1"
                            params={JSON.stringify({ days })} />
                    </>
                )}
            </div>
            <div className="mt-[5px]">
                <Tab queryParams={{ days }} tabs={tabs} className={isMobile ? 'mb-[100px]' : 'mb-[0px]'} />
            </div>
        </OverlayPage>
    );
};

export default DelegatedMailboxLayout;
