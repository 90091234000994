import React from 'react';
import { CloseIcon } from '../Icons';
import styles from './styles.module.css';
import { truncateString } from '@utils/index';

type SelectProps = {
    onClick: () => void;
    classNames?: string;
    label: string
};
const MAX_CHAR_SIZE = 5;

export const SelectedItem: React.FC<SelectProps> = ({
    onClick,
    classNames,
    label
}) => {

    return (
        <div className={styles.selectedItemWhenClosed} >
            <p className="w-[58px] h-[32px]" >
                {truncateString(label, MAX_CHAR_SIZE)}
            </p>
            <button onClick={onClick}>
                <CloseIcon classNames="h-3 w-3" />
            </button>
        </div>

    );
};
