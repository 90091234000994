import * as React from 'react';
import { IconProps } from './types';

export const EditIcon: React.FC<IconProps> = ({
  size = 5,
  color = 'text-interfaceColor-100',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      fill="none"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="m6.715 14.989-5.143 1.44 1.44-5.143 9.417-9.372a1.143 1.143 0 0 1 1.635 0l2.023 2.034a1.143 1.143 0 0 1 0 1.623L6.715 14.99Z"
      />
    </svg>
  );
};
