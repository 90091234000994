import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import { useMsTeamsHealthSnapshotQuery } from '@hooks/adoption';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useState } from 'react';

type Params = {
  days: string;
  dataType: string;
};

export const MsTeamsHealth: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.MsTeamsHealth,
    parameters
  );
  const isOnDashboardPage = !!id;
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const {
    data: msTeamsHealthData,
    isLoading: isMsHealthLoading,
    refetch,
  } = useMsTeamsHealthSnapshotQuery(parameters);

  const handleMsTeamsHealthDropdown = (value: string) => {
    const newParameters = {
      ...parameters,
      dataType: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  const sortMenuItems: MenuItem[] = [
    {
      id: '2',
      label: 'Membership',
      value: 'membership',
      action: () => handleMsTeamsHealthDropdown('membership'),
    },
    {
      id: '3',
      label: 'Owner',
      value: 'owner',
      action: () => handleMsTeamsHealthDropdown('owner'),
    },
  ];

  return (
    <DoughnutChartTile
      classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      sortData={sortMenuItems}
      initialSelectedItem={sortMenuItems.find(
        (item) => item.value === parameters.dataType
      )}
      headerTitle={'MS Teams health'}
      dropdownMenuItemClasses={'!w-[300px]'}
      isLoading={isMsHealthLoading}
      data={
        msTeamsHealthData
          ? [msTeamsHealthData?.teamsWith, msTeamsHealthData?.teamsWithout]
          : []
      }
      dataLabels={[
        `${
          msTeamsHealthData && msTeamsHealthData?.teamsWithout
            ? msTeamsHealthData.teamsWithout
            : 0
        } teams have no  ${
          parameters.dataType === 'owner' ? 'owners' : 'members'
        }`,
        `${
          msTeamsHealthData && msTeamsHealthData?.teamsWith
            ? msTeamsHealthData.teamsWith
            : 0
        } teams have ${parameters.dataType === 'owner' ? `owners` : 'members'}`,
      ]}
      buttonLabel={'MS Teams health data'}
      linkPath={ANALYTICS_NAVIGATION_MAP['msTeamsHealth']}
      handleButtonClick={handleButtonClick()}
      showBracketLabelValue={false}
    />
  );
};
