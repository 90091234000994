import * as React from 'react';
import { IconProps } from './types';

export const OnwardIcon: React.FC<IconProps> = ({
  color = 'text-primaryColor-100',
  classNames = '',
  size = 4,
}) => {
  return (
    <svg
      viewBox="0 0 16 16"
      className={`stroke-current w-${size} h-${size} ${color} ${classNames}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
        d="m6.048 4.14 2.857 2.858-2.857 2.857"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
        d="M7 13.19A6.19 6.19 0 1 0 7 .809a6.19 6.19 0 0 0 0 12.38Z"
      />
    </svg>
  );
};
