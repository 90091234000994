import {
  CellBottomGroupPart,
  CellBottomPart,
} from '@components/ui/Table/utils';
import { formatDate } from '@utils/index';

export const createCellValue = (
  property: string,
  data: any,
  index: number,
  isMobile: boolean
) => {
  const value = data[index][property];

  switch (property) {
    case 'fullName':
      return (
        <div className="flex flex-col">
          <span>{data[index]['fullName']}</span>
          <div className="block">
            <CellBottomPart data={data[index]['emailAddress']} />
          </div>
        </div>
      );
    case 'groups':
      return (
        <>
          <span>{value?.[0] ?? 'Unassigned'}</span>
          {Array.isArray(value) && value.length - 1 > 0 && (
            <CellBottomGroupPart count={value.length - 1} />
          )}
        </>
      );
    case 'locationName':
      return (
        <>
          <span>{value ?? 'Unassigned'}</span>
        </>
      );
    case 'emailsSent':
      return (
        <>
          <span>{value}</span>
          {Number(value) !== 0 && data[index]['emailsSentLastUsed'] && (
            <CellBottomPart
              data={`Last: ${formatDate(data[index]['emailsSentLastUsed'])}`}
            />
          )}
        </>
      );
    case 'messagesSent':
      return (
        <>
          <span>{value}</span>
          {Number(value) !== 0 && data[index]['messagesSentLastUsed'] && (
            <CellBottomPart
              data={`Last: ${formatDate(data[index]['messagesSentLastUsed'])}`}
            />
          )}
        </>
      );
    case 'callsMade':
      return (
        <>
          <span>{value}</span>
          {Number(value) !== 0 && data[index]['callsMadeLastUsed'] && (
            <CellBottomPart
              data={`Last: ${formatDate(data[index]['callsMadeLastUsed'])}`}
            />
          )}
        </>
      );
    case 'meetingsJoined':
      return (
        <>
          <span>{value}</span>
          {Number(value) !== 0 && data[index]['meetingsJoinedLastUsed'] && (
            <CellBottomPart
              data={`Last: ${formatDate(
                data[index]['meetingsJoinedLastUsed']
              )}`}
            />
          )}
        </>
      );
  }
};
