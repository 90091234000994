import { ANALYTICS_NAVIGATION_MAP, DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useHandleTileBtnNavigate,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { useMfaStatusSnapshotQuery } from '@hooks/security';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { useState } from 'react';

type Params = {
  userType: string;
};

export const MicrosoftMFAStatus: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));
  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { handleButtonClick } = useHandleTileBtnNavigate(id);
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.MicrosoftMFAStatus,
    parameters
  );
  const isOnDashboardPage = !!id;

  const {
    data: mfaStatusData,
    isLoading: isMfaStatusLoading,
    refetch,
  } = useMfaStatusSnapshotQuery(parameters);

  const handleDropdown = (value: string) => {
    setParameters({
      userType: value === 'All' ? '' : value,
    });

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify({
          userType: value === 'All' ? '' : value,
        }),
        tileId: id,
      });

      refetch();
    }
  };

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All users',
      value: 'All',
      action: () => handleDropdown('All'),
    },
    {
      id: '2',
      label: 'Admin users',
      value: 'Admin',
      action: () => handleDropdown('Admin'),
    },
    {
      id: '3',
      label: 'Non-admin users',
      value: 'Standard',
      action: () => handleDropdown('Standard'),
    },
  ];

  return (
    parameters && (
      <DoughnutChartTile
        contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
        initialSelectedItem={sortMenuItems.find(
          (item) => item.value === parameters.userType
        )}
        classNames={'col-span-6 md:col-span-3 xl:col-span-2'}
        dropdownMenuItemClasses={'!w-[300px]'}
        sortData={sortMenuItems}
        headerTitle={'MFA status'}
        isLoading={isMfaStatusLoading}
        data={mfaStatusData ? Object.values(mfaStatusData) : []}
        dataLabels={['Users without MFA', 'Users with MFA']}
        buttonLabel={'MFA data'}
        linkPath={ANALYTICS_NAVIGATION_MAP['mfaStatus']}
        handleButtonClick={handleButtonClick()}
        showBracketLabelValue={true}
      />
    )
  );
};
