import { API_ENDPOINTS } from '@api/ApiEndpoints';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query';
import { PersonsResponse, UsePersonsQueryOptions } from '../people/types';
import {
  CreateLocationData,
  CreateLocationResponse,
  DeleteLocationResponse,
  Location,
  LocationForm,
  UpdateLocationMembersData,
  UpdateLocationMembersResponse,
  UseCreateLocationOptions,
  UseUpdateLocationMembersOptions,
  UseUpdateLocationOptions,
} from './types';
import { useHttpClient } from '@hooks/utils/httpConfig';

export function useLocationsQuery(): UseQueryResult<Location[]> {
  const locationsClient = useHttpClient();
  const fetchLocations = async (): Promise<Location[]> => {
    return locationsClient.get<Location[]>(API_ENDPOINTS.LOCATIONS);
  };

  return useQuery(['locations'], fetchLocations);
}

export function useMembersQuery({
  searchTerm,
  sortBy,
  order,
  personStatus,
  pageSize = 20,
  pageNumber = 1,
  locationId,
}: UsePersonsQueryOptions = {}): UseQueryResult<PersonsResponse> {
  const locationsClient = useHttpClient();
  const fetchMembers = async (): Promise<PersonsResponse> => {
    const params = {
      pageSize: pageSize,
      pageNumber: pageNumber,
      searchTerm: searchTerm,
      sortBy: sortBy,
      order: order,
      status: personStatus,
      locationId: locationId,
    };
    return locationsClient.get<PersonsResponse>(
      `${API_ENDPOINTS.LOCATIONS}/${locationId}/members`,
      params
    );
  };

  return useQuery(
    [
      'locationMembers',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      personStatus,
    ],
    fetchMembers
  );
}

export const useUpdateLocationMembers = (
  locationId: string | undefined,
  options?: UseUpdateLocationMembersOptions
): UseMutationResult<
  UpdateLocationMembersResponse,
  unknown,
  UpdateLocationMembersData
> => {
  const locationsClient = useHttpClient();
  return useMutation(
    (data: UpdateLocationMembersData) =>
      locationsClient.patch<UpdateLocationMembersResponse>(
        `${API_ENDPOINTS.LOCATIONS}/${locationId}/members`,
        data
      ),
    options
  );
};

export const useDeleteLocation = (): UseMutationResult<
  DeleteLocationResponse,
  unknown,
  string,
  unknown
> => {
  const locationsClient = useHttpClient();
  return useMutation((locationId: string) =>
    locationsClient.delete<DeleteLocationResponse>(
      `${API_ENDPOINTS.LOCATIONS}/${locationId}`
    )
  );
};

export const useUpdateLocation = (
  locationId: string | undefined,
  options?: UseUpdateLocationOptions
): UseMutationResult<unknown, unknown, LocationForm> => {
  const locationsClient = useHttpClient();
  return useMutation(
    (data: LocationForm) =>
      locationsClient.patch(`${API_ENDPOINTS.LOCATIONS}/${locationId}`, data),
    options
  );
};

export const useCreateLocation = (
  options?: UseCreateLocationOptions
): UseMutationResult<CreateLocationResponse, unknown, CreateLocationData> => {
  const locationsClient = useHttpClient();
  return useMutation(
    (data: CreateLocationData) =>
      locationsClient.post<CreateLocationResponse>(
        `${API_ENDPOINTS.LOCATIONS}`,
        data
      ),
    options
  );
};
