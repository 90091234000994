import { OutdatedBrowsersData } from '@hooks/security/types';

export const createCellValue = (
    property: keyof OutdatedBrowsersData,
    data: OutdatedBrowsersData[],
    index: number,
) => {
    const value = data[index][property];

    switch (property) {
        case 'fullVersionName':
            return (
                <>
                    <span>{value ?? ''}</span>
                </>
            );
        default:
            return (
                <>
                    <span>{value || 0}</span>
                </>
            );
    }
};
