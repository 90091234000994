import * as React from 'react';
import { IconProps } from './types';

export const LineChartIcon: React.FC<IconProps> = ({
  size = '5',
  color = 'text-successColor-100',
  strokeWidth = 1.2,
  classNames = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`w-${size} h-${size} stroke-current ${color} ${classNames}`}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.8574 12.0001H15.4289V7.42871"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M15.4294 12L8.97227 5.54286C8.86545 5.43816 8.72184 5.37951 8.57227 5.37951C8.42269 5.37951 8.27908 5.43816 8.17227 5.54286L5.54369 8.17143C5.43688 8.27613 5.29327 8.33478 5.14369 8.33478C4.99412 8.33478 4.85051 8.27613 4.74369 8.17143L0.572266 4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
