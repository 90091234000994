import { IOption } from '@common/types';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { OutdatedBrowserFiltersResponse } from '@hooks/security/types';
import { Location } from '@hooks/locations/types';
import { GroupsResponse } from '@hooks/groups/types';
import { ExtendedOption } from '@common/types';

export const addSelectedDataToFilter = (
  data: OutdatedBrowserFiltersResponse,
): FilterData[] => {
  const filterData: FilterData[] = [];
  Object.keys(data).forEach((key) => {
    let filterOptions: IOption[] = [];

    filterOptions = data.browsers?.map((item) => ({
      label: item,
      value: item,
    }));
    if (filterOptions.length)
      filterData.push({
        label: 'Browsers',
        name: key,
        singleSelect: false,
        options: filterOptions,
      });
  });
  return filterData;
};

export const addLocationsToFilter = (locationsData: Location[], filterOptions: IOption[],
): { locations: FilterData, ids: string[] } => {
  const locationIds: string[] = [];
  locationsData?.forEach((location: Location) => {
    if (filterOptions.filter(option => option.value !== location.id)) {
      filterOptions.push({ label: location.name, value: location.id });
      locationIds.push(location.id);
    }
  });

  return {
    locations: {
      label: 'Location',
      name: 'location',
      singleSelect: false,
      options: filterOptions,
    }, ids: locationIds
  }
}

export const addGroupsToFilter = (groupsData: GroupsResponse[], filterOptions: ExtendedOption[]): FilterData => {
  groupsData?.forEach((group: GroupsResponse) => {
    filterOptions.push({
      name: group.name,
      value: group.id,
      parentId: group.parentId,
      subGroups: group.subGroups,
      id: group.id,
      label: group.name,
    });
  });

  return {
    label: 'Groups',
    name: 'groups',
    singleSelect: false,
    options: filterOptions,
  };
}

export const getGroupOrLocationFilterAsQueryParam = (filter: string | undefined, ids: string[]
) => {
  return filter ? filter.split(',').filter((item) => ids.includes(item)) : []
}


