import * as React from 'react';

type HamburgerIconProps = {
  size?: string;
  color?: string;
  classNames?: string;
}

export const HamburgerIcon: React.FC<HamburgerIconProps> = ({
  size = 4,
  color = 'text-interfaceColor-100',
  classNames = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`w-${size} h-${size} fill-current ${color} ${classNames}`}
      fill="none"
    >
      <path
        d="M8 4A1.714 1.714 0 1 0 8 .573a1.714 1.714 0 0 0 0 3.429ZM8 9.716a1.714 1.714 0 1 0 0-3.429 1.714 1.714 0 0 0 0 3.429ZM8 15.429A1.714 1.714 0 1 0 8 12a1.714 1.714 0 0 0 0 3.429Z"
      />
    </svg>
  );
};
