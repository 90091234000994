import { NoResults } from '@components/partials/NoResults';
import { Banner, Button, Card, Loader, Tooltip } from '@components/ui';

import {
  AddUserIcon,
  ArrowRightIcon,
  DeleteIcon,
  EditIcon,
  HamburgerIcon,
  LocationIcon,
  PlusIcon,
  UserIcon,
} from '@components/ui/Icons';
import { useLocationsQuery } from '@hooks/locations';
import { Location } from '@hooks/locations/types';
import { useUnassignedLocationsInfoQuery } from '@hooks/people';
import { useIsMobile } from '@hooks/utils/isMobile';
import useTableStore from '@store/tableStore';
import useUIStore from '@store/uiStore';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { DeleteLocationModal } from './Modals/DeleteLocationModal';
import styles from './styles.module.css';
import { CreateEditLocationModal } from './Modals/CreateEditLocationModal';
import { useQueryClient } from 'react-query';
import { hasPermission } from '@utils/index';
import { UserRoles, Permissions } from '@hooks/users/types';
import useAuthStore from '@store/authStore';
import { DropdownMenu, MenuItem } from '@components/ui/DropdownMenu';

const LocationPage: React.FC = () => {
  const locationQuery = useLocationsQuery();
  const unassignedLocationQuery = useUnassignedLocationsInfoQuery();
  const navigate = useNavigate();
  const { showLocationTooltip, setShowLocationTooltip } = useUIStore();
  const isMobile = useIsMobile();
  const { reset } = useTableStore();

  const [unassignedBannerVisible, setUnassignedBannerVisible] = useState(true);
  const [isCreateEditLocationModalOpen, setIsCreateEditLocationModalOpen] =
    useState(false);
  const [isDeleteLocationModalOpen, setIsDeleteLocationModalOpen] =
    useState(false);
  const [locationForModals, setLocationForModals] = useState<
    Location | undefined
  >(undefined);

  const queryClient = useQueryClient();
  const userRole = useAuthStore.getState().role || UserRoles.STANDARD;

  const createLocationBtnHasPermission = hasPermission(
    userRole,
    Permissions.ADMINS_SUPPORTUSER
  );
  const dropdownBtnHasPermission = hasPermission(
    userRole,
    Permissions.ADMINS_SUPPORTUSER
  );

  useEffect(() => {
    if (locationQuery.data?.length === 1) {
      setShowLocationTooltip(true);
    } else {
      setShowLocationTooltip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationQuery.data, setShowLocationTooltip]);

  const onCreateFirstLocation = () => {
    setLocationForModals({
      id: '',
      name: '',
      address: '',
      memberCount: 0,
      createdAt: '',
    });
    setIsCreateEditLocationModalOpen(true);
  };

  const createFirstLocationBtn = () => {
    if (createLocationBtnHasPermission) {
      return (
        <Button
          variant="primary"
          className="mt-6"
          onClick={onCreateFirstLocation}
        >
          <div className="flex items-center justify-center">
            <p className="px-2">Create your first location</p>
          </div>
        </Button>
      );
    }
  };

  const dropdownContent = useCallback(
    (location: Location) => {
      const LocationMenuItems: MenuItem[] = [
        {
          id: '1',
          label: 'Add / remove people',
          icon: <AddUserIcon size="5" />,
          action: () => {
            reset();
            reset();
            navigate(
              `/accounts/locations/${location.id}/manage-people`,
              {
                state: { location },
              }
            );
          },
        },
        {
          id: '2',
          label: 'Edit location',
          icon: <EditIcon />,
          action: () => {
            setIsCreateEditLocationModalOpen(true);
            setLocationForModals(location);
          },
        },
        {
          id: '6',
          classNames: 'text-errorColor-150',
          label: 'Delete location',
          icon: (
            <DeleteIcon color="text-errorColor-150" classNames="h-[1rem]" />
          ),
          action: () => {
            setIsDeleteLocationModalOpen(true);
            setLocationForModals(location);
          },
        },
      ];

      return (
        <div
          className="absolute -top-1 right-4 top-2 md:top-7 justify-between"
          onMouseEnter={() => setShowLocationTooltip(false)}
        >
          <DropdownMenu
            items={LocationMenuItems}
            closeOnClickOutside={true}
            dropdownMenuClasses={'!w-[260px]'}
            buttonProps={{ icon: <HamburgerIcon></HamburgerIcon> }}
          ></DropdownMenu>
        </div>
      );
    },
    [navigate, reset]
  );

  const dropdownContentWithTooltip = (location: Location) => {
    return (
      <Tooltip
        content="Click this icon to edit, delete or assign more people to this location"
        align="right"
        isOpen={true}
      >
        {dropdownContent(location)}
      </Tooltip>
    );
  };

  if (locationQuery.isLoading) {
    return <Loader />;
  }

  const haveResults =
    !locationQuery.isLoading &&
    !locationQuery.isError &&
    !!locationQuery?.data?.length;
  const unassignedCount = unassignedLocationQuery.data?.unassignedPersons;

  return (
    <>
      {unassignedBannerVisible && !!unassignedCount && haveResults && (
        <div className={'w-full pb-6 md:w-[534px] lg:w-[704px]'}>
          <Banner
            title={`${unassignedCount} ${
              unassignedCount > 1 ? 'people don’t' : 'person doesn’t'
            } have a location`}
            message="Add people to locations to enable better insights, analytics and reporting."
            closeable={true}
            onClose={() => setUnassignedBannerVisible(false)}
            actionButtons={
              <Button
                size="medium"
                variant="dark"
                className="ml-5 mt-2"
                onClick={() =>
                  navigate('/accounts/locations/unassigned-people')
                }
              >
                View unassigned people
                <ArrowRightIcon
                  classNames="ml-2"
                  size="3"
                  color="text-primaryBrandColor-150"
                />
              </Button>
            }
          />
        </div>
      )}

      {haveResults && (
        <>
          {locationQuery.data?.map((location) => (
            <Card
              classNames={`bg-white relative mb-6 inline inline-block `}
              key={location.id}
            >
              <Card.Header classNames="text-20 leading-7">
                <p className={styles.locationName}>{location.name}</p>
              </Card.Header>

              {dropdownBtnHasPermission && (
                <Card.Content classNames={styles.locationCardContent}>
                  {locationQuery.data?.length === 1 && showLocationTooltip
                    ? dropdownContentWithTooltip(location)
                    : dropdownContent(location)}
                </Card.Content>
              )}

              <Card.Footer classNames="mt-1">
                <div className="flex flex-col md:flex-row">
                  <div className="order-2 md:order-1">
                    <Button
                      disabled={location.memberCount === 0}
                      variant="default"
                      size="extraSmall"
                      className={`mr-1 mt-1 flex items-center ${
                        location.memberCount === 0 ? 'cursor-not-allowed' : ''
                      }`}
                      onClick={() =>
                        navigate(
                          `/accounts/locations/${location.id}/members`
                        )
                      }
                    >
                      <UserIcon classNames="w-4 h-4" /> {location.memberCount}
                      {!isMobile
                        ? location.memberCount === 1
                          ? ' member'
                          : ' members'
                        : ''}
                    </Button>
                  </div>
                  <div className={styles.locationCardAddressWrapper}>
                    <LocationIcon classNames="mr-1" />
                    <p className={styles.locationFullAddress}>
                      {`${location.address}`}
                    </p>
                  </div>
                </div>
              </Card.Footer>
            </Card>
          ))}

          {createLocationBtnHasPermission && (
            <Button
              variant="primary"
              onClick={onCreateFirstLocation}
              className="w-full md:w-auto"
            >
              <div className="flex items-center justify-center">
                <PlusIcon /> <p className="px-2">Add a location</p>
              </div>
            </Button>
          )}
        </>
      )}

      {!haveResults && (
        <NoResults
          headerTxt="You haven't created any locations yet."
          contentTxt="Create locations and assign people to them to get better reports and insights."
          actionButtons={createFirstLocationBtn()}
        />
      )}

      <CreateEditLocationModal
        location={locationForModals}
        locationsData={locationQuery.data || []}
        isModalOpen={isCreateEditLocationModalOpen}
        handleModalClose={() => {
          setIsCreateEditLocationModalOpen(false);
          setLocationForModals(undefined);
        }}
        handleSuccess={() => {
          setIsCreateEditLocationModalOpen(false);
          queryClient.invalidateQueries('locations');
        }}
      />

      <DeleteLocationModal
        location={locationForModals}
        isModalOpen={isDeleteLocationModalOpen}
        handleModalClose={() => {
          setIsDeleteLocationModalOpen(false);
        }}
        handleSuccess={() => {
          setIsDeleteLocationModalOpen(false);
          queryClient.invalidateQueries('locations');
        }}
      />
    </>
  );
};

export default LocationPage;
