import { IOption } from "@common/types";
import { FilterData } from "@components/partials/TableWrapper/parts/Filter";
import { capitalizeFirstLetter } from "@utils/index";

export const mapFilterDisplayNames = (label: string) => {
    if (label === "trunkName") return "Trunk group";
    if (label === "trunkType") return "Type";
    return label?.replace(/(?<!^)([A-Z])/g, ' $1');
}

export const addSelectedDataToFilter = (data: any, filterData: FilterData[], mapLabels?: (key: string) => string) => {
    Object.keys(data).reverse().forEach((key) => {
        let filterOptions: IOption[] = [];
        if (Array.isArray(data[key])) {
            filterOptions = (
                data[key] as { [type: string]: string }[]
            )?.map((item) => ({
                label: item.type,
                value: item.type,
            }));
        }
        const name = mapLabels && mapLabels(key) || mapFilterDisplayNames(key);
        filterData.push({
            label: capitalizeFirstLetter(name),
            name: key,
            singleSelect: false,
            options: filterOptions,
        });
    });
}

export const isValueIncludedInFilter = (value: string, filter: 
    { [type: string]: string }[] | undefined): boolean => {
    if (!filter) return false;
    const values = filter.map((currentValue) => currentValue.type);
    return values?.includes(value)
}
