import { DashboardTiles } from '@common/constants';
import { TilesProps } from '@common/types';
import { MenuItem } from '@components/ui/DropdownMenu';
import { useOrgGroupActivityUserTopTeamsSnapshotQuery } from '@hooks/adoption';
import {
  useAddDashboardTile,
  useDashboardUpdateTileParams,
  useRemoveDashboardTile,
} from '@hooks/dashboard';
import { TableTile } from '@pages/InsightsAndAnalytics/tiles/TableTile';
import { useEffect, useState } from 'react';

type Params = {
  days: string;
  dataType: string;
};

export const OrgGroupActivityUserSnapshot: React.FC<TilesProps> = ({
  params,
  id,
  classNames,
}) => {
  const [parameters, setParameters] = useState<Params>(JSON.parse(params));

  const useDashboardUpdateTileParamsMutation = useDashboardUpdateTileParams();
  const { removeMenuItem } = useRemoveDashboardTile(id);
  const { addMenuItem } = useAddDashboardTile(
    DashboardTiles.OrgGroupActivityUserSnapshot,
    parameters
  );
  const isOnDashboardPage = !!id;
  // data hook
  const {
    data: orgGroupActivityUserTopTeamsData,
    isLoading,
    refetch,
  } = useOrgGroupActivityUserTopTeamsSnapshotQuery(parameters);

  useEffect(() => {
    const updatedParams = JSON.parse(params);

    setParameters({ days: updatedParams.days, dataType: parameters.dataType });

    return () => {
      refetch();
    };
  }, [parameters.dataType, params, refetch]);

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'All activity',
      value: 'allactivity',
      action: () => handleDropdownChange('allactivity'),
    },
    {
      id: '2',
      label: 'Messages',
      value: 'messages',
      action: () => handleDropdownChange('messages'),
    },
    {
      id: '3',
      label: 'Meetings',
      value: 'meetings',
      action: () => handleDropdownChange('meetings'),
    },
  ];

  const handleDropdownChange = (value: string) => {
    const newParameters = {
      ...parameters,
      dataType: value,
    };

    setParameters(newParameters);

    if (isOnDashboardPage) {
      useDashboardUpdateTileParamsMutation.mutate({
        params: JSON.stringify(newParameters),
        tileId: id,
      });

      refetch();
    }
  };

  return (
    <TableTile
      isLgSize={true}
      classNames={
        classNames ? classNames : 'col-span-6 md:col-span-3 xl:col-span-2'
      }
      contextData={isOnDashboardPage ? removeMenuItem : addMenuItem}
      sortData={sortMenuItems}
      columnOrder={['teamName', 'activityPerUser']}
      initialSelectedItem={sortMenuItems.find(
        (item) => item.value === parameters.dataType
      )}
      headerTitle={'Top teams: activity / user'}
      dropdownMenuItemClasses={'!w-[300px]'}
      isLoading={isLoading}
      data={
        orgGroupActivityUserTopTeamsData
          ? orgGroupActivityUserTopTeamsData?.activity
          : []
      }
      fractionDigits={0}
    />
  );
};
